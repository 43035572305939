import React, { Component, PropTypes } from 'react';

import * as _ from 'lodash';

class SearchExpression extends Component {
	constructor(props, context) {
	    super(props, context);

	    	this.state = {hovered: false};

	  }
	
	hovered(flag){
		this.setState({hovered: flag});
	}
	
	render(){
		var removeElement = "";
		
		if(this.state.hovered){
			removeElement = <span className="glyphicon glyphicon-remove" aria-hidden="true" onClick={this.props.removeElement}></span>;
		}
		
		return (<span className="searchExpression" onMouseEnter={()=>this.hovered(true)} onMouseLeave={()=>this.hovered(false)}>{this.props.children} {removeElement}</span>);
	}
}


export default class Filter extends Component {
	constructor(props, context) {
	    super(props, context);

	    	this.state = {open: false};

	  }
	
	handleOpen(){
		this.setState({open: !this.state.open});
	}
	
	removeElement(filterKey){
		this.props.removeFilter(filterKey);
	}
	
	render(){
		var filters = "";
		var showing = "";
		
		if(this.props.filters == undefined || Object.keys(this.props.filters).length <= 0){
			showing = "All";
		} else {
			showing = Object.keys(this.props.filters).map((filter, index)=>{
				return <SearchExpression key={index} removeElement={()=>this.removeElement(filter)} filterKey={filter}>{this.props.filters[filter]}</SearchExpression>;
			});
		}
		
		if(this.state.open){				
			filters = <div className="filter_body-filters col-lg-12 col-md-12 col-sm-12 col-xs-12">
						{this.props.children}
						</div>;
		}
			
		return (<div className="filter_body col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<div className="filter_body-currentFilters">Showing {showing}</div>
				<div className="filter_body-show" onClick={()=>this.handleOpen()}>Change Filters<span className={this.state.open? "glyphicon glyphicon-chevron-up" : "glyphicon glyphicon-chevron-down"} /></div>
				{filters}
              </div>);
		
	}
}