import React, { useEffect, useRef, useState } from "react";

import RenderMeSoftly from "@opidcore/components/Content/RenderMeSoftly";
import RenderMeSoftlyEditor from "@opidcore/components/Content/RenderMeSoftlyEditor";

export default function TestingDashboard({}) { 
    const uid = 'dashboard-finance';
    const [dashboardStructure, setDashboardStructure] = useState([]);
    const editor = useRef(null);

    useEffect(()=>{
        APP.central.Stuff.everythingIWant(uid).then((r)=>{
            setDashboardStructure(r.result.structure);
        });
    }, [uid]);

    const [editingMode, setEditingMode] = useState('view');
    // updateContentStructure={this.updateContentStructure} editTempStructure={this.editTempStructure} tempStructure={this.state.tempContentStructure}

    const updateContentStructure = (a,b,c)=>{
        //debugger;
    }

    return <div>       
        <RenderMeSoftlyEditor ref={editor} onEdit={(mode)=>setEditingMode(mode)} central={APP.central} updateContentStructure={updateContentStructure}/>

        <RenderMeSoftly editor={editor} mode={editingMode} iAmGod={true} structure={dashboardStructure} root={uid}/>
    </div>
};
