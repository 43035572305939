import React, { Component } from 'react';
import templated from './Templated.jsx';
import mustache from 'mustache';

class Chunk extends Component {
	constructor(props){
		super(props);
		
		this.state = {};
	}
	
	componentDidMount(){
	    let text = this.props.children;
	    if(text != undefined && text.trim() == "..."){
	        //text = " ";
	    }
		this.refs.me.innerText = text;
		if (this.refs.me.innerText.length == 0){
			this.setState({className: "empty"});
		}else{
			this.setState({className: null});
		}
	}
	
	render(){
		return <span className={"wtf " + this.state.className + " " + this.props.className} ref="me" onBlur={this.props.updated} contentEditable={true}></span>
	}
}

class ScopeVar extends Component {
	constructor(props){
		super(props);
		
		this.state = {};
	}
	
	scopeKeys(hash, current){
		var hashPath = current || "";
		if (hashPath.length > 0){
			hashPath += ".";
		}
		
		var rets = [];
		
		var options = _.each( hash, (v,k)=>{
			if (typeof v == "object"){
				var children = this.scopeKeys(v, (hashPath ? hashPath : "") + k);
				_.each(children, (c)=>{rets.push(c) });
			}
			rets.push([(hashPath ? hashPath : "") + k, v]);
		});
		
		return rets;
	}
	
	startEditing(e){
		const stateChange = {editing: true};
		stateChange.selectWidth = e.currentTarget.offsetWidth;
		stateChange.selectHeight = e.currentTarget.offsetHeight;

		this.setState(stateChange);	
	}
	
	handleChanged(e){
		const selected = e.target.value;
		this.props.updated(selected);
		this.setState({editing: false});
	}
	
	render(){
		
		var currentValue = this.props.hash[ this.props.name ];
		if (this.props.name.indexOf(".") > 0){
			var chunks = this.props.name.split(".");
			if (this.props.hash[ chunks[0] ]){
				currentValue = this.props.hash[ chunks[0] ][ chunks[1] ];
			}else{
				currentValue = "? " + this.props.name  + " ?";
			}
			
		}
		if (this.state.editing){
			const scopeKeys = this.scopeKeys(this.props.hash);
			scopeKeys.push( [this.props.name, this.props.name] );
			var opts = scopeKeys.map( (e)=>{
				if (typeof e[1] != "object")
				return <option value={e[0]} key={e[0]}>{e[1]} ({e[0]})</option>
			});
			return <select className={"inline-scope-select"} onChange={(e)=>this.handleChanged(e)} defaultValue={this.props.name} style={{width: this.state.selectWidth, height: this.state.selectHeight}}>{opts}</select>;
		}
		
		return <span onClick={(e)=>this.startEditing(e)} className="inline-scope-variable" title={" i am actually a variable " + this.props.name}>{currentValue}</span>
	}
}

export default class  TemplatedEditor extends Component {
	constructor(props){
		super(props);
		
		this.state = {};
		
		if(props.emptyOK){
		    this.state.editing = true;
		}
	}
	
	static getDerivedStateFromProps(props, state){		 
	    let content = props.content;
	    if(!content){
	        content = " ";
	    }
	    
		var parsed = "";
		
		try {
		    parsed = mustache.parse(content);
		} catch(error) {
		    console.log(error);
		    debugger
		}

		if (parsed != state.parsed){
			return {parsed: parsed};
		}
		
		return null;
	}
	
	something(children,idx,newval,idy){
		var ret = _.map( children, (p, i)=>{
			var newIdy = (idy != undefined  ? idy + "." : "") + i;
			var changeThis = newIdy == idx;
			console.log(newIdy + " is " + p[0]);

			if (p[0] == "text"){
				if (changeThis){
					return newval;
				}else{
					return p[1];
				}
			}else if (p[0] == "name"){
				if (changeThis){
					return "{{"+ newval + "}}"; 
				}
				return "{{"+ p[1] + "}}";
			}else if (p[0] == "#"){
				return "{{#"+ p[1] + "}}" + this.something(p[4],idx,newval,newIdy) + "{{/" + p[1] + "}}";
			}else{
				debugger;
				
			}
		}).join("");
		
		return ret;
	}
	
	handleUpdate(e,idx){
		var text = "?";

		if (e.currentTarget){
			text =  e.currentTarget.innerText;
		}else{
			text = e;
		}
		
		var newTemplate = this.something(this.state.parsed, idx, text);
		
		if (this.props.update){
			this.props.update(newTemplate);
		}else{
			console.log("hey you should give me an update function so I know how to save myself");
			console.log(newTemplate);
		}
	}

	eachParsed(it, idy, props){
		var idx = 0;

		var children = [];
		_.map( it, (chunk)=>{
			var currentId = (idy != undefined  ? idy + "." : "") + idx;
			
			if (chunk[0] == "text"){
				children.push( <Chunk key={chunk[1]} {...props} updated={(e,a)=>this.handleUpdate(e, currentId)}>{chunk[1]}</Chunk> );
			}else if (chunk[0] == "name"){
				children.push( <ScopeVar key={chunk[1]} hash={this.hash} name={chunk[1]} updated={(e,a)=>this.handleUpdate(e, currentId)}/> );
			}else if (chunk[0] == "#"){
				var className = "default" + chunk[1].substr(0,1).toUpperCase() + chunk[1].substr(1);
				children.push(this.eachParsed(chunk[4], currentId, {className: className, key: chunk[1]}));
			}
			
			idx++;
		} );
				
		return children;
	}
	
	render(){	
		this.hash = this.props.hash; 
		let content = this.props.content;
		
		if (this.state.editing != true && !this.props.emptyOK){
			return <span className="wit" onClick={()=>this.setState({editing: true})} dangerouslySetInnerHTML={{__html: mustache.render(content, this.props.hash)}}></span>;
		}
		
		var children = this.eachParsed(this.state.parsed);
		
		return children; 
	}
}