import React from 'react';
//import { Tabular, TabularColumn } from '@opidcore/components/Tabular';
import FakeRouted from '@opidcore/components/Content/FakeRouted.jsx';
import { useData } from '@opidcore/hooks/WTF';
import ViewView from '../../../js/view.jsx';
import Button from '../../../../OpidCore/public/js/components/Button.js';

export default function WasteMaterialList(props){
    const auditId = parseInt( props.match.params.id );
    const [data, dataSet] = useData("waste_audit");
    
    const relatedForms = APP.getState("related_forms");
    
    let materialList = [];
    let streamList = [];
    if(relatedForms && relatedForms.forms){
        materialList = _.filter(relatedForms.forms, (a)=>{ return a.string_value == auditId && a.uid == "audit_materials_profile"});
        streamList = _.filter(relatedForms.forms, (a)=>{ return a.string_value == auditId && a.uid == "stream_used"});
    }

    const handleClick = (clickType) => {
        if(clickType.indexOf("copyStandardMaterials") > -1){
            WASTE_AUDIT.copyStandardMaterials("audit_materials_profile", undefined);
        } else if(clickType.indexOf("copyStandardStreams") > -1){
            WASTE_AUDIT.copyStandardStreams("stream_used", undefined);
        } else if(clickType.indexOf("copyMissingStreams") > -1 ){
            WASTE_AUDIT.copyMissingStreams("stream_used", undefined);
        } else if(clickType.indexOf("copyMissingMaterials") > -1 ){
            WASTE_AUDIT.copyMissingMaterials("audit_materials_profile", undefined);
        } else {
            APP.alert("Missing Click Type");
        }
    }

    return <div key="audit_materials" id="audit_materials">
        <FakeRouted fakeParams={{id: streamList.length > 0? streamList[0].id : 0}} formUid={"stream_used"} render={(props) => <ViewView {...props}  viewDefinition={props.viewDefinition} /> } />
        <div className="stream-used-actions">
            <Button className="copy btn btn-default" onClick={()=>handleClick("copyStandardStreams")}>Copy Standard Streams</Button>
            <Button className="copy btn btn-default" onClick={()=>handleClick("copyMissingStreams")}>Copy Missing Streams</Button>
        </div>
        <FakeRouted fakeParams={{id: materialList.length > 0? materialList[0].id : 0}} formUid={"audit_materials_profile"} render={(props) => <ViewView {...props}  viewDefinition={props.viewDefinition} /> } />
        <div className="audit-material-profile-actions">
            <Button className="copy btn btn-default" onClick={()=>handleClick("copyStandardMaterials")}>Copy Standard Materials</Button>
            <Button className="copy btn btn-default" onClick={()=>handleClick("copyMissingMaterials")}>Copy Missing Materials</Button>
        </div>
    </div>;
}