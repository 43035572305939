import React, { useEffect }  from 'react';
import PropTypes from 'prop-types';

export default function FlexBreak(props) {
  return <div className={"fieldBreak " + props.className}/>;
}

FlexBreak.propTypes = {
  componentName: PropTypes.string
}

FlexBreak.defaultProps = {
  componentName: "FlexBreak"
}