import React, { Component, useEffect } from 'react';
//import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as CentralActions from '../../../OpidCore/public/js/actions/CentralActions';

import { Icon} from '../../../OpidCore/public/js/components';

import * as _ from 'lodash';
//import moment from 'moment';

import RenderMeSoftly  from '../../../OpidCore/public/js/components/Content/RenderMeSoftly.jsx';
import RenderMeSoftlyEditor  from '../../../OpidCore/public/js/components/Content/RenderMeSoftlyEditor.jsx';

function _makeOverride(props){
	    let id = null;
	    if(props.overrideChunkId){
	        id = props.overrideChunkId;
	    } else {
	        id = props.struct.theId;
	    }
	    
	    if (window._overriding == null){
	    	window._overriding = [];
	    }
	    
	    if (window._overriding.indexOf(id) >= 0){
	    	console.log("we are already trying to override");
	    	return;
	    }
	    
	    window._overriding.push(id);
	    
	    
	    if(id != null){
	        return props.central.CustomContent.createOverride(id, props.overrideIdentifier, props.struct.__new ? id : "").then((r)=>{
	            let found = false;
	            var newStructure = props.contentStructure.map( (s)=>{
	                    if (s.theId == id){
	                        found = true;
	                        s.__saveToId = r.result.uid;
	                        //s.options.content = s.options.content + " buzzah ";
	                    }
	                    return s;
	                }                   
	            );
	            
	            if(!found && props.overrideChunkId){
	                let newContent = r.result;
	                newContent.__saveToId = newContent.theId;
	                newContent.cid = props.overrideChunkId;
	                newContent.theId = props.overrideChunkId;
	                newStructure.push(newContent);
	            }
	            
	            if(props.editor){
	                props.editor.updateContentStructure(newStructure);
	            } else {
	                props.updateContentStructure(newStructure);
	            }
	            
	            return newStructure;
	        });
	    }		
}

class OverrideThing extends Component {
	
	removeOverride(){
let id = null;
	    
	    if(this.props.overrideChunkId){
	        id = this.props.overrideChunkId;
	    } else {
	        id = this.props.struct.theId;
	    }
	    
	    if(id != null){
	        this.props.central.CustomContent.removeOverride(id, this.props.overrideIdentifier, this.props.struct.__saveToId).then((r)=>{
	            let found = false;
	            APP.alert('gone!');
	            var newStructure = this.props.contentStructure.map( (s)=>{
	                    if (s.theId == id){
	                        found = true;
	                        s.__saveToId = r.result.uid;
	                        //s.options.content = s.options.content + " buzzah ";
	                    }
	                    return s;
	                }                   
	            );
	            
	            if(!found && this.props.overrideChunkId){
	                let newContent = r.result;
	                newContent.__saveToId = newContent.theId;
	                newContent.cid = this.props.overrideChunkId;
	                newContent.theId = this.props.overrideChunkId;
	                newStructure.push(newContent);
	            }
	            
	            if(this.props.editor){
	                this.props.editor.updateContentStructure(newStructure);
	            } else {
	                this.props.updateContentStructure(newStructure);
	            }
	            
	        });
	    }	
	}
	
	makeOverride(){
		return _makeOverride(this.props);
	}
	
	oldMakeOverride(){
	    let id = null;
	    
	    if(this.props.overrideChunkId){
	        id = this.props.overrideChunkId;
	    } else {
	        id = this.props.struct.theId;
	    }
	    
	    if(id != null){
	        this.props.central.CustomContent.createOverride(id, this.props.overrideIdentifier).then((r)=>{
	            let found = false;
	            var newStructure = this.props.contentStructure.map( (s)=>{
	                    if (s.theId == id){
	                        found = true;
	                        s.__saveToId = r.result.uid;
	                        //s.options.content = s.options.content + " buzzah ";
	                    }
	                    return s;
	                }                   
	            );
	            
	            if(!found && this.props.overrideChunkId){
	                let newContent = r.result;
	                newContent.__saveToId = newContent.theId;
	                newContent.cid = this.props.overrideChunkId;
	                newContent.theId = this.props.overrideChunkId;
	                newStructure.push(newContent);
	            }
	            
	            if(this.props.editor){
	                this.props.editor.updateContentStructure(newStructure);
	            } else {
	                this.props.updateContentStructure(newStructure);
	            }
	            
	        });
	    }	
	}
	
	render(){
		
		if (this.props.struct && this.props.struct.__saveToId > ""){
			return <>
			<button type="button" className="btn btn-default btn-override" onClick={()=>this.removeOverride()}>remove override</button>
			</>;
		}
		
		return <>
		<button type="button" className="btn btn-default btn-override" onClick={()=>this.makeOverride()}>make override</button>
		{ false && this.props.report_element_types ? " TODO make a select for : " + this.props.report_element_types : "" }
		</>;
	}
}

class ContentView extends Component {
    constructor(props, context) {
        super(props, context);
        this.updateContentStructure = _.debounce(this._updateContentStructure,
        		100);
        this.state = {
                loginError: undefined,
                pubSubs: {},
                loading: true        
        };
        this.updateContentStructure = this.updateContentStructure.bind(this);
        
        
        
    }
    
    componentDidMount(){
    	this.componentDidUpdate({match: {params: {}}});
    	//this.fetchContent();    	
    }
    
    componentDidUpdate(prevProps) {
    	const importantParams = ['cid', 'name', 'id'];
    	var fetchRequired = false;
    	
    	_.each(importantParams, (s)=>{
    		if (this.props.match.params[s] != prevProps.match.params[s]){
    			fetchRequired = true;
    		}	
    	});
    	
    	if (fetchRequired) {
    		const defaultScope = {params: {}};
    		
    		if (this.props.match.params.name){
    			defaultScope._url = this.props.match.url.replace('/content/','');
    			
    			defaultScope.params[this.props.match.params.name] = this.props.match.params.id;
    		}
    		const stateUpdate = {cid: this.props.match.params.cid, scope: defaultScope};
    		this.setState(stateUpdate, ()=>{
        		this.fetchContent();
    		});  		
    	}
    	
    	const viewDefinition = {
                id: 1084,
                form: "waste_audit",
                tab: "Audit Setup"
            };
            
            
            if (APP.NDT && APP.NDT.tabs[viewDefinition.tab] == undefined){
            	if (this.refs.editor && this.refs.editor.editSetup){
            		this.refs.editor.editSetup(viewDefinition);
            	}
            }
    }
    
    fetchContent() {
    	const cid = this.state.cid;
    	
        this.props.central.Stuff.everythingIWant(cid).then((d) => {
        	
        	var overrideId = "audit-" + this.state.scope.params.audit;
        	if (this.state.scope.params.audit != null){
            	this.setState({overrideId: overrideId});
            	var result = d.result;

        		APP.central.CustomContent.findOverrides(overrideId).then(r=>{ 
        			
        			var newStuff = [];
        			var missedOverrides = {}

        			_.each(r.result, (override)=>{ 
        			    _.forEach( override.content, (oc)=>{
        			        if (oc.theId == override.new_cid || oc.uid == override.new_cid){
        			            oc.__saveToId = override.new_cid;
                            }
        			        missedOverrides[oc.theId] = override;
        			    });
        			    
        			    const existing = result.structure.map(r=>r.uid);
        			    
        				result.structure = _.compact( _.compact(result.structure).map( (s)=>{
        					if (s.theId == override.replaces){

        						//s.__saveToId = override.new_cid;
        					    let found = false;

        						_.each( override.content, (content)=>{ 
        						    delete missedOverrides[content.theId];
        						    
        							if (content.theId == override.new_cid || content.uid == override.new_cid){
        								content.__saveToId = override.new_cid;
        								content.uid = s.uid;
        								content.cid = s.cid;
        								content.theId = s.theId;
        								found = true;        								
        							}
        							
        							if (existing.indexOf(content.uid) == -1){
        								content.__saveToId = content.uid;
        							}

        							newStuff.push( content );	
        						});


        						if (found){
        							return null;
        						}
        						//s.options.content = s.options.content + " buzzah ";
        					}
        					return s;
        				}					
        				));
        			} );
        			
        			_.forEach(missedOverrides, (override, key)=>{
        			    let content = _.find(override.content, {theId: key});
        			    content.__saveToId = override.new_cid;
                        content.uid = override.replaces;
                        //content.cid = override.replaces;
                        //content.theId = override.replaces;
    			        result.structure.push(content);
        			});
        			
        			//push the new stuff onto the structure
        			_.each(newStuff, (n)=>{
        				result.structure.push(n);
        			});
        			
        			this.postContentForFetch(result);
        			
        		});     		
        	}else{
        		this.postContentForFetch(d.result);	
        	}
        	
            
          });
        
//    	fetch("/api/v1/contentStuff/testing?cid=" + this.state.cid).then((r)=>r.json()).then((d)=>{
//    		this.setState({contentStructure: Array.from(d.result), tempContentStructure: Array.from(d.result)});
//    	});
    }
    
    postContentForFetch(result){
    	var rootCid = this.state.cid;
    	var theRoot = _.first(result.structure.filter(s=>s.cid == rootCid ));
    	if ( theRoot ){
    		var css = theRoot.options.pageCssClass;
    		if (css){
        		APP.instance.setState({"mainContainerClass": css})
    		}else{
        		APP.instance.setState({"mainContainerClass": "page-container"})
    		}
    	}
    	
    	const struct = _.compact(result.structure);
    

    	//this.props.NDT.loadStructure( this.state.contentStructure );
    	
    	if (APP.NDT && APP.NDT.root != rootCid){
    		APP.NDT.destroy();
    		//APP.NDT.loadStructure(result.structure);
    	}
    	
    	this.setState({
    		loading: false,
            contentStructure: struct, 
            tempContentStructure: struct,
            pubSubs: result.pubSubs
        }, ()=>{        	
        	this.forceUpdate();
        });
    	
    	setTimeout(()=>{
    		console.log("you suck and took more than 4 seconds");
    	}, 4000);
    }
    
    editTempStructure(struct) {
        this.setState({tempContentStructure: struct});
    }
    
    _updateContentStructure(struct) {
    	
    	console.log(" this makes cody sad ian...  please fix. ");
    	if (struct.length > APP.NDT.structure.length * 0.1){
    		
	        this.setState({contentStructure: struct}, ()=>{
	        	//
	        });
	        
	        
    	}
    }

    triggerQueueContent(editor,me,theId, options, action, children){
    	if (true){
    		if (me.__saveToId == undefined){
    			if (me.__new == true){
        			me.__saveToId == me.uid;
    			}
    			
    			const theProps = {...editor.props.extraActions.props};
    			theProps.struct = me;
    			
    			if (action == "DELETE"){
    				const parent = _.first(APP.NDT.structure.filter((r)=>r.children && r.children.indexOf(theId) >= 0));
    				if (parent != null){
    					theProps.struct = parent;
    				}else{
    					APP.alert("Can't delete this element.");
    				}
    			}
    			if (theProps.struct.__saveToId){
    				//editor.queueContent(theId, options, action, children);
    				return undefined;
    			}else{
	    			_makeOverride(theProps).then((a)=>{
	    				//do the queue again now that we have an override
	    				editor.queueContent(theId, options, action, children);
	    			});
    			}
    			
    			return true;
    		}
    	}    	
    }
    
    render(){  
    	
	    if ( window.USER && window.USER.pdf && this.state.contentStructure  ){
	    	return <RenderMeSoftly central="blah" key={this.state.cid} root={this.state.cid} structure={this.state.contentStructure} mode={"view"} pubSubs={this.state.pubSubs}/> 
	    }else if ( this.state.contentStructure ){
	    	var extraActions = <OverrideThing overrideIdentifier={this.state.overrideId} central={this.props.central} contentStructure={this.state.contentStructure} updateContentStructure={this.updateContentStructure} editor={this.refs.editor}/>
	    	
	    	
	    	let editTrigger = null;
	    	
	    	if (this.props.match.url.indexOf("contentInstance") >= 0){
	    		editTrigger = {triggerQueueContent: (editor,me,theId, options, action, children)=>this.triggerQueueContent(editor,me,theId, options, action, children) };
	    	}
	    	
			const openPDF = ()=>{
				debugger;
				this.refs.editor.viewAs("PDF");
			}
			const pdfButton = <button key="pdf" type="button" className="btn btn-default btn-pdf" onClick={openPDF}><Icon icon="file-pdf" color="white" size="2x"/> PDF</button>;
			
        	return <div id="userDashboard" className="container">
	    	
	    	
	    	{ editTrigger == null ? <h2 className="edit-master">Editing Master Template</h2> : <h2 className="edit-audit">Editing Audit Template {pdfButton} </h2> }
	    	

        	<RenderMeSoftlyEditor {...editTrigger} extraActions={extraActions} onEdit={(mode)=>{this.setState({"editMode": mode})}} ref="editor" updateContentStructure={this.updateContentStructure} editTempStructure={this.editTempStructure} tempStructure={this.state.tempContentStructure} central={this.props.central}>
        	</ RenderMeSoftlyEditor>
        	<RenderMeSoftly iAmGod={true} scope={{...this.state.scope}} editor={this.refs.editor} central="blah" pdfMode={this.props.pdfMode} key={this.state.cid} root={this.state.cid} structure={this.state.contentStructure} mode={this.state.editMode} pubSubs={this.state.pubSubs}/>
        	
        	<div style={{"clear": "both"}}></div>
        			
		            
		            
        	</div>
        				    	
	    }
	    
        return <div id="contentView" className="text-center container">
            {this.state.loading ? <h2 className="loading">Loading...</h2> :
            	<h2 className="thinking">thinking!</h2>
            	}
            
            
        </div>
    }
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        ...CentralActions.modifyProps(dispatch),  
        actions: bindActionCreators({...CentralActions}, dispatch),    
    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContentView);

export function RMSWrapper({children}){

	useEffect( ()=>{
		document.body.classList.add("printing");
	}, []);	

	return <div>
	<div className="printing"></div>
	<div className="rms-page">{children}</div>
	</div>;
}