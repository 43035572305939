import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Icon } from '.';
import Button from './Button';

export function PageNumber({pageNumber, activePage, onClick}){
    return <div className={"page_number " + (pageNumber == activePage? "activePage" : "")} onClick={onClick}>
        <span className="element ">
            {pageNumber}
        </span>
    </div>
}

export default function Pagination(props){
    const [pages, setPages] = useState([]);
    
    useEffect(()=>{
        if(props.dataSet.paginationInfo != undefined){
            const tempPages = _.map(new Array(props.dataSet.paginationInfo.totalPages), (emptyElement, index)=>{
                const pageNumber = index + 1;
                return <PageNumber key={"page" + pageNumber} pageNumber={pageNumber} activePage={props.dataSet.paginationInfo.currentPage} onClick={()=>props.dataSet.setPage(pageNumber)}/>;
            })
            
            setPages(tempPages);
        }
    }, [props.dataSet.paginationInfo]);
    
    let subArrayOfPages = pages;
    
    if(props.dataSet.paginationInfo != undefined){
        let pageRangeLower = props.dataSet.paginationInfo.currentPage - 4;
        let pageRangeUpper = props.dataSet.paginationInfo.currentPage + 4;
        
        if(pageRangeLower < 0){
            pageRangeLower = 0;
        }
        
        if(pageRangeUpper >= props.dataSet.paginationInfo.totalPages){
            pageRangeUpper = props.dataSet.paginationInfo.totalPages;
        }
        
        const firstPage = pageRangeLower > 0 ? pages[0] : null;
        const lastPage = pages.length > 0 ? pages[pages.length-1] : null;

        subArrayOfPages = pages.slice(pageRangeLower, pageRangeUpper);
        if (subArrayOfPages.indexOf(firstPage) == -1 && firstPage != null){
            if (pageRangeLower > 1){
                subArrayOfPages.unshift(<span key={"spanTo" + (pageRangeLower-1)}>...</span>);
            }
            subArrayOfPages.unshift(firstPage);
        }

        if (subArrayOfPages.indexOf(lastPage) == -1){
            subArrayOfPages.push(<span key={"spanTo" + (pageRangeUpper+1)}>...</span>);
            subArrayOfPages.push(lastPage);
        }
    }   
    
    return pages && pages.length > 1 ? (
      <div id="pagination">
        <Icon icon="chevron-left" onClick={() => props.dataSet.previousPage()} />
        {subArrayOfPages}
        <Icon icon="chevron-right" onClick={() => props.dataSet.nextPage()} />
      </div>
    ) : null;
}