import React, { Component, useState}  from 'react';
import { Link } from 'react-router-dom';
import { useNavBar, useData } from '@opidcore/hooks/WTF'; 

import { TabularDataSet, Selection, TabularColumn } from '@opidcore/components/Tabular';
import ReferencedThing from '@opidcore/components/ReferencedThing';
import Icon from '@opidcore/components/Icon';

export default function Reports(props){
	const nav = useNavBar("Reports", [], {menu: ["reports"]}); 
	
	const [selection, setSelection] = useState([]);
	
	const [dataA, dataSetA] = useData("waste_audit;reports", {stage: ['Report Finalization', 'Report Verification']});

	const open = (url, audit, pdf)=>{
		const title = "Waste Audit #" + audit.id + ": " + audit.client_name;
		const filename = "waste_audit_" + audit.id + ".pdf";
		
		nav.update("Report - " + title);
		if (pdf == "pdf"){
			APP.showLoading("Loading, please wait", ()=>{
				return APP.central.Stuff.generatePDF(url, filename);
			});
		}else{
			APP.redirect( url );
		}
				
	};

    return <div>
    	<h2>Reports</h2>
        
    	<TabularDataSet dataSet={dataSetA} noData="There are no audits in Report Finalization or Verification stage.">
    		<TabularColumn data={(row)=><Selection selection={selection} setter={setSelection} item={row} label={(row)=>row.client_name} />} title="...">
    		</TabularColumn>
        	<TabularColumn data={(row)=><span>{row.client_name}<ReferencedThing data={row} field={"id"} /></span>} title="Client Name"/>
    		<TabularColumn data={(row)=>row.waste_auditor} title="Waste Auditor"/>
    		<TabularColumn data={(row)=>row.stage} title="Stage"/>
    			
        	<TabularColumn data={(row)=>{ return <Link className="nice" onClick={()=>open("/contentInstance/" + (row.report_template || "audit-template") + "/audit/" + row.id, row)}> <Icon icon="file-chart-pie" size="2x"/> View Report </Link> }}/>
        	
        	<TabularColumn data={(row)=>{ return <a className="nice" onClick={()=>open((row.report_template || "audit-template") + "/audit/" + row.id, row, "pdf")}> <Icon icon="file-pdf" size="2x"/> Download PDF </a> }}/>

    	</TabularDataSet>
    	
    	
    </div>;
    
    
    
}
