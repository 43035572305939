import React, {Component} from 'react'
import {Tabular, TabularColumn} from '@opidcore/components/Tabular';
import * as _ from 'lodash';
import Modal from '../../../../public/js/components/Modal.js';

class Content extends Component {
	constructor(props) {
		super(props);
		this.filterByType = this.filterByType.bind(this);
		this.createNewContainer = this.createNewContainer.bind(this);
		this.saveNewComponent = this.saveNewComponent.bind(this);
		this.search = this.search.bind(this);
		
		this.state = {searchTerm: ""}
	}
	
	createNewContainer() {
		this.setState({creatingContent: true});
	}
	
	filterByType(e) {
		var val = e.target.value
		var that = this;
		if (val != 'All') {
			var result = _.filter(that.state.components, function(datum) {return datum.component == val});
				
			that.setState({filteredComponents: result});
		} 
		else {
			that.setState({filteredComponents: that.state.components});
		}
	}
	
	saveNewComponent() {
		var cid = document.getElementById("CidInput").value;
		var type = document.getElementById("TypeInput").value;
		var component = document.getElementById("ComponentInput").value;
		var uid = document.getElementById("UidInput").value;
		
		var upload = {uid: uid, cid: cid, type: type, component: component};
		
		if (window.APP.central) {
			window.APP.central.ContentStuff.editComponent(upload).then(r=>{
				window.location.hash = "content/" + (r.result.cid || r.result.cid);
			});
		}
		
		this.modal.closeAction();
	}
	
	search(e) {
		var searchTerm = e.target.value;
		
		this.setState({searchTerm: searchTerm});
	}
	
	componentWillMount() {
		//I need to figure out a way to get all the stuff from content_stuff here.
		var that = this;
		
		//maybe this will work?
		
		if (window.APP.central) {
			window.APP.central.ContentStuff.listAll().then(function(res) {that.setState({components: res.result, filteredComponents: res.result})});
		}	
	}
	
	render() {
		//this can tell the server to make a new body1, body2, body7. this is a big list of all the components in the database pretty much.
		//make something that shows everything, or one that shows all containers, filter by the type at the top.
		//add new takes a cid from the user and the button will make a component/thing with that cd.
		//also should be able to make a new container and add elements to it WITHOUT going to the server first.
		//since they can be represented with json it should be pretty easy.
		//need a tool for searching within the page
		var table = null;
		
		var that = this;
		
		if (this.state && this.state.components) {
			table = <Tabular data={this.state.filteredComponents}>
							<TabularColumn data={(row) => <span className={(row.cid.indexOf(that.state.searchTerm) > -1 || JSON.stringify(row.options).indexOf(that.state.searchTerm) > -1) && that.state.searchTerm != "" ? "highlight" : ""}>{row.component == "Container" && row.cid ? <a href={"/content/" + row.cid}>{row.cid}</a> : row.cid}</span>} title="Cid" />
							<TabularColumn data={(row) => row.component} title="Component" />
							<TabularColumn data={(row) => row.theId} title="TheId" />
						</Tabular>;
						
						//<TabularColumn data={(row) => JSON.stringify(row.options)} title="options" />
		}
		
		if (this.state && this.state.creatingContent) {
			var view = <div>
				<h4>UID:</h4>
				<input type="text" id="UidInput"/>
				<h4>CID:</h4>
				<input type="text" id="CidInput"/>
				<h4>Type:</h4>
				<input type="text" id="TypeInput" />
				<h4>Component:</h4>
				<input type="text" id="ComponentInput" />
				<br/>
				<button onClick={this.saveNewComponent}>Save</button>
			</div>
			
			
			if (this.modal == null) {
				this.modal = new Modal();
			}
			
			this.modal.openModal(view);
		}
		
		var search = <div><label>Search:</label><input type="text" onChange={this.search} /></div>
		
		return <div>
					{search}
					<select onChange={(e) => this.filterByType(e)}>
						<option value="All">All</option>
						<option value="Container">Container</option>
						<option value="Loop">Loop</option>
					</select>
					
					{table}
					
					<button onClick={this.createNewContainer}>Add New Container</button>
					<section id="modal"></section>
				</div>
	}
}

export default Content;