import React, { useState, Fragment, useRef } from "react";
//import { Data } from "@opidcore/Data.js";
import { Link } from "react-router-dom";
import { useData, useNavBar } from "@opidcore/hooks/WTF";
import { AccessControl, Bound,  Button, InputText, InputToggleSwitch } from "@opidcore/components";
import { NiceStaffAvatar } from "./Notes";
import { File } from "../components";
//import StaffEdit from "./Admin/StaffEdit";
import _ from "lodash";

export default function Settings(props) {
  const links = [
    <Link to={"/ui/settings"}>My Preferences</Link>,
    <Link to={"/ui/admin"}>Admin</Link>,
    <AccessControl group="Sysadmin">
      <Link to={"/forms"}>Configuration</Link>
    </AccessControl>,
  ];

  useNavBar("Settings", links, { menu: ["settings"] });

  const user = APP.getWebSession();
  const [staff, staffDS] = useData("staff", user != null ? user.userId : null);

  const uploadPic = (file) => {
    APP.central.Staff.setAvatar(file).then((r) => {
      debugger;
    });
  };

  return (
    <div>
      <h3>My Profile</h3>

      <NiceStaffAvatar staff={staff} />
      <File onUpload={(file) => uploadPic(file)} label="Change Profile Pic" />

      <ChangePassword staff={staff} />
    </div>
  );
}

function ChangePassword(props) {
  const staff = props.staff;
  if (staff == null || !staff.__type) {
    return null;
  }
  const passwordsRef = useRef({ pass1: null, pass2: null });
  const passShow = useRef({ passShowStatus: false });
  const [passShowState, setPassShowState] = useState("password");

  const tryChangePass = () => {
    if (_.isEmpty(passwordsRef.current.pass1) || _.isEmpty(passwordsRef.current.pass2)) {
      APP.alert("Both password fields must be filled.");
    } else {
      if (passwordsRef.current.pass1 == passwordsRef.current.pass2) {
        const staffCopy = staff;
        staffCopy.passCrypt = passwordsRef.current.pass1;
        if(Util.doCheckPass(staffCopy) == false){
          APP.alert("Follow password rules to save.");
          return;
        }

        const changes = { passCrypt: passwordsRef.current.pass1 };
        APP.central.Staff.saveChanges(staff.id, changes).then((r) => {
          if (r.status == "ok") {
            APP.alert("Password successfully changed.");
          } else {
            APP.alert("Something went wrong");
          }
        });
      } else {
        APP.alert("Passwords did not match.");
      }
    }
  };

  const updateShowPass = () => {
    setPassShowState(passShow.current.passShowStatus ? "text" : "password");
  };

  return (
    <Fragment>
      <h4>Change Password</h4>
      <p>Must be over 8 characters and contain at least one of each: capital letter, lowercase letter, number, and special character</p>
      <div className="userSettingsPasswordForm">
        <div>
          <Bound to={passwordsRef.current}>
            <InputText field="pass1" name="New Password" inputType={passShowState} />
          </Bound>
          <Bound to={passShow.current} onChange={updateShowPass}>
            <InputToggleSwitch field="passShowStatus" className="inline" icon="eye" />
          </Bound>
        </div>

        <Bound to={passwordsRef.current}>
          <InputText field="pass2" name="Confirm New Password" inputType="password" />
        </Bound>
      </div>
      <Button onClick={tryChangePass}>Change Password</Button>
    </Fragment>
  );
}
