import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FlexBreak, Tabular, TabularColumn } from "@opidcore/components";
import Icon from "@opidcore/components/Icon";
import { useData, useNavBar } from "@opidcore/hooks/WTF";

import _ from "lodash";
import { NiceRelativeTime } from "../Nice";

export default function BillingDashboard(props) {
  const navBar = useNavBar("Dashboard", [], { menu: ["dashboard"] });
  const [clientData, setClientData] = useState([]);
  const totalClients = useRef(0);
  const convertedClientsString = useRef("");
  const leaderboard = useRef([]);
  const [allTasks, allTasksDataSet] = useData("task;Conversions", { taskType: 16 });

  useEffect(() => {
    APP.central.Customer.listConversionInfo().then((r) => {
      const res = r.result;

      totalClients.current = res.length;
      let tempConverted = 0;
      let tempLeaderboard = [];
      const noAnalystName = "No Billing Analyst";
      tempLeaderboard[noAnalystName] = 0;
      _.forEach(res, (ci) => {
        if (ci.converted) {
          tempConverted += 1;
          if (ci.billingAnalyst) {
            const fullName = ci.billingAnalyst.fullName;
            if (tempLeaderboard[fullName]) {
              tempLeaderboard[fullName] += 1;
            } else {
              tempLeaderboard[fullName] = 1;
            }
          } else {
            tempLeaderboard[noAnalystName] += 1;
          }
        }
      });
      convertedClientsString.current = tempConverted + " / " + totalClients.current;

      //testing
      /*
      tempLeaderboard["TESTMAN1"] = 100;
      tempLeaderboard["TESTMAN2"] = 12;
      tempLeaderboard["TESTMAN3"] = 13;
      tempLeaderboard["TESTMAN4"] = 40;
      */

      leaderboard.current = tempLeaderboard;

      setClientData(r.result);
    });
  }, []);

  return (
    <div key="dashboard">
      <div className="page-block">
        <div className="flex">
          <div className="flex">
            <LeaderboardThing leaderboard={leaderboard.current} />
          </div>

          <div>
            <h2>Converted Clients</h2>
            <div>
              <div className="flex">
                <Icon icon={"user"} fType={"fal"} size="2x" />
                {convertedClientsString.current}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-block">
        <Tabular data={clientData} className="sticky">
          <TabularColumn
            title="ID"
            data={(row) => (
              <Link className="plain_link" to={"/ui/clients/" + row.friendlyId}>
                {row.friendlyId}
              </Link>
            )}
          />
          <TabularColumn
            title="Name"
            data={(row) => (
              <Link className="plain_link" to={"/ui/clients/" + row.friendlyId}>
                {row.name}
              </Link>
            )}
          />
          <TabularColumn title="Target Conversion Date" data={(row) => row.targetConversionDate} />
          <TabularColumn title="Total Sites" data={(row) => row.totalSites} />
          {/*<TabularColumn title="Total Converted Sites" data={(row) => row.totalSitesConverted} />*/}
          <TabularColumn title="Total Services" data={(row) => row.totalServices} />
          <TabularColumn title="Total Converted Services" data={(row) => row.totalServicesConverted} />
          <TabularColumn title="Invoices Generated" data={(row) => row.invoiceGenerated} />
          <TabularColumn title="Billing System" data={(row) => (row.billingSystem ? row.billingSystem : "-")} />
          <TabularColumn title="Billing Analyst" data={(row) => (row.billingAnalyst ? row.billingAnalyst.fullName : "-")} />
          <TabularColumn title="Assigned To" data={(row) => (row.assignedTo ? row.assignedTo.fullName : "-")} />
          <TabularColumn title="Stage" data={(row) => (row.stage ? row.stage : "-")} />

          <TabularColumn
            title="Converted"
            data={(row) => (row.converted ? <Icon icon="check" size="2x" color="green" /> : <Icon icon="times" size="2x" color="red" />)}
          />
        </Tabular>
      </div>
    </div>
  );
}

const DashStatBlock = ({ value, color, icon, label, fType }) => {
  const statBlockStyle = {
    flex: "1",
    width: "auto",
    display: "flex",
    flexDirection: "column",
    //justifyContent: "center",
    //alignItems: "center",
    fontSize: "12pt",
  };
  const statValueStyle = {
    fontSize: "1em",
    position: "relative",
    top: "-2px",
    color: color,
  };

  return (
    <div style={statBlockStyle}>
      <div className="stat">
        <Icon icon={icon} fType={fType || "fal"} color={color} size="2x" />
        <span className="stat-label">{label}</span>{" "}
      </div>
      <div style={statValueStyle}> {value}</div>
    </div>
  );
};

const LeaderboardThing = ({ leaderboard }) => {
  //const DOLLARSPERCONVERSION = 5;

  let lbArray = _.orderBy(
    _.map(Object.keys(leaderboard), (key) => {
      return { val: leaderboard[key], key: key };
    }),
    "val",
    "desc"
  );

  return (
    <Fragment>
      <div>
        <h2>Billing Analysts</h2>
        {_.map(lbArray, (entry, i) => {
          const displayVal = "Conversions: " + entry.val; // + ", Bonus Pay: $" + entry.val * DOLLARSPERCONVERSION;
          return (
            <Fragment key={i}>
              <div className="flex">
                <h2>{i + 1}</h2>
                <DashStatBlock value={displayVal} icon="user" label={entry.key} color={undefined} fType={undefined} />
              </div>
              <FlexBreak />
            </Fragment>
          );
        })}
      </div>
    </Fragment>
  );
};

const ConversionTaskThing = ({ data }) => {
  return (
    <div>
      <Tabular data={data}>
        <TabularColumn title="Title" data={(row) => <span>{row.subject}</span>} />
        <TabularColumn title="Type" data={(row) => <span>{row.taskTypeObject.name}</span>} />
        <TabularColumn title="Status" data={(row) => <span>{row.status}</span>} />
        <TabularColumn
          title="Subject"
          data={(row) => {
            row.relatedTable + ": " + row.related.name;
          }}
        />
        <TabularColumn title="Created" data={(row) => <NiceRelativeTime date={row.created} />} />
        <TabularColumn title="Created By" data={(row) => <span>{row.createdBy}</span>} />
      </Tabular>
    </div>
  );
};

const TaskThing = ({tasks}) => {

  let count = null;
  if(tasks) {
    count = tasks.length;
  }

  return (
    <div className="fa-2x">
      <span className="fa-layers fa-fw">
        <Icon fType="fal" icon="ticket-alt" size="2x" type="span" />
        {count ? (
          <span className="fa-layers-counter" style={count > 0 ? { background: "Tomato" } : { background: "Gray" }}>
            <strong>{count > 0 ? count : null}</strong>
          </span>
        ) : null}
      </span>
    </div>
  );
};
