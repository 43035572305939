import { combineReducers } from 'redux';
import coreData from './coreData';
import webSocket from './webSocket';

import central from './central';

const rootReducer = combineReducers({
  central,
  coreData,
  webSocket
});

export default rootReducer;

