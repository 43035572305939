import React, {  useState, useEffect, useRef }  from 'react';
// import Icon from '@opidcore/components/Icon';
// import Searchable from '@opidcore/components/Searchable';
// import ActionBar from '@opidcore/components/ActionBar';
// import { Link } from 'react-router-dom';
// import { Tabular, TabularColumn } from '@opidcore/components/Tabular';
import { Bound, Button, Loading, InputSelect  } from '@opidcore/components';
import {  useNavBar, useAppState } from '@opidcore/hooks/WTF';
//import StaffSelect from '../../components/StaffSelect';


export default function XeroStatus(props){
	useNavBar("Xero Status", []);
	const [status, setStatus] = useState(null);
	
	const refreshStatus = useRef(()=>{ 
		setStatus(null);
		
		setTimeout( ()=>{ 
			APP.central.Xero.status().then( (r)=>{
				setTimeout( ()=>setStatus(r.result), 750 );
			});
		}, 500 );
	}).current;
	
	
	const myForm = useRef({});
	
	const xeroRefresh = useAppState('xeroRefresh', 0, refreshStatus );

	
	useEffect( ()=>{	
		refreshStatus();
	}, []);
	

	if (status == null){
		return <Loading message="Checking Xero Connection"/>;
	}
	
	const openXeroAuth = ()=>{ 
		window.open( '/xero/loginResult', '_new', "width=400,height=650" );
	};
	const changeCompany = ()=>{
		APP.central.Xero.setTenant( myForm.current.tenant ).then( (r)=>{
			refreshStatus();
		});
	}
	return <div>	
		<h2>Xero Connection {status.state}</h2>
		
		Current Company: {status.company} ({status.tenant})<br/>	
		
		{ status.details ? <p className="error">{status.details}</p> : null }<br/>
	
			
		<div>
			<Button onClick={openXeroAuth}>Authenticate with Xero</Button>
		</div>
		
		<div>
			<Bound to={myForm.current}>
				<InputSelect field="tenant" name="Change Company To" options={status.tenants}/>
				<Button onClick={changeCompany}>Change Company</Button>
			</Bound>
		</div>
	</div>;
}

