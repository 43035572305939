import React,{ Component } from 'react';
//import  OpidSession  from '../../OpidApplication'; 
import CustomButton from './CustomButton';
import RenderMeSoftly from '../RenderMeSoftly.jsx';

export default class CustomizationBlock extends Component {
	static contextType = React.createContext({});
	constructor(props) {
		super(props);
		this.state = {...this.context};
		this.state.dynamicChildren = []; 
		this.state.buttons = [];
	}
	
	
	//in the future, this will come from the network somehow, but for now its just going to come from ExtendedApp.
	getStructure() {
		var that = this;
//		that.setState(function(state, props) {return {dynamicChildren: state.dynamicChildren.concat(_.find(props.children, function(r){return r.props.root == that.context.items[i]}))}});

		//make buttons?
		
		var buttons = [];
		
		for (var i = 0; this.context.items && i < this.context.items.length; i++) {
			buttons.push(<CustomButton {...this.context.items[i]} />)
		}
		
		this.setState({buttons: buttons});
		
		var rmsChildren = [];
		
		for (var i = 0; this.context.rmsChildren && i < this.context.rmsChildren.length; i++) {
			// @ts-ignore
			rmsChildren.push(<RenderMeSoftly {...this.context.rmsChildren[i]} />)
		}
	}
	
	componentWillMount() {
//		for (var i = 0; i < this.context.items.length; i++) {
			this.getStructure();	
//		}

	}
	
//	componentDidUpdate() {
//		this.getStructure();
//	}
	
	render() {
		//need logic here for dealing with various properties, i'm thinking the json will have
		//a 'side', a width or height depending on the side, and some defition of the buttons or items that will be inside it.
		
		//so we're not going to use RMS, we're going to have a more limited menu that just shows buttons?
		
		var children = [];
		
		var that = this;
		
		var myStyle = '{"backgroundColor": "white", "position": "fixed", "' +  that.context.position + '": "0px", "width": "' +  that.context.width + '", "height": "' +  that.context.height + '"}';
		
		myStyle = JSON.parse(myStyle); 

		children = this.state.buttons;		
		
		var toRender = <div style={myStyle}>{children}</div>;
		
		return null;
	}
	
}