import React, { Component }  from 'react';
import Kanban from '@opidcore/components/Kanban.jsx';
import { Data } from '@opidcore/Data.js';

function InnerThingThatIsPlaceHolder(props){
    return <div className="waste_kanban-card_content">
        <h4>{props.data.client_name_informal}</h4>
        <div className="date">Scheduled: {props.data.scheduled_date}</div>
        <div className="owner">Owner: {props.data.owner}</div>
        <small className="id">ID: {(props.data.friendlyId? props.data.friendlyId : props.data.id)}</small>
    </div>;
}

export default class WasteKanban extends Component {
    constructor(props, context){
        super(props, context);
        
        this.state = {
                data: new Data(), 
                isLoading: true
        };
    }
    
    componentDidMount(){
        //needs to pull datasets some other way. Data will always have a person (user) and status
        APP.central.Stuff.everythingIWant("cid_V0FTVEUhAAAANd6nBYrwrXi1lnVP").then((d)=>{
            let realStructureBecauseIds = d.result.structure[0];
            realStructureBecauseIds.cid = realStructureBecauseIds.theId;
            APP.central.Stuff.flower(realStructureBecauseIds).then((f)=>{
                this.setState({data: new Data(f.result), isLoading: false});
            })
            
        });
    }
    
    render(){
        const status = ["Pre-Audit Setup", "Data Entry", "Data Verification", "Report Finalization", "Report Verification", "Sent To Client"];
        
        let headingFunction = function(dataset){ //placeholder function
            let sumOfAudits = 0;
            _.forEach(dataset, (entry)=>{
                if(entry.id != undefined && typeof entry.id == "number"){
                    sumOfAudits += 1;
                }
            })
            
            return "# of Audit: " + sumOfAudits;
        };
        
        const kanbanProps = {
                data: this.state.data,
                isLoading: this.state.isLoading,
                status: status,
                cardContent: InnerThingThatIsPlaceHolder,
                headingFunction: headingFunction
        }
        
        return <div className="waste_kanban">
            <Kanban {...kanbanProps}/>
        </div>
    }
}