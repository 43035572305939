import React, { Fragment, useContext, useState } from "react";
import { Tabular, TabularColumn} from "@opidcore/components/Tabular";
import { Bound, Button, DatePicker, InputSelect, InputText, Loading } from "@opidcore/components";
import _ from "lodash";
import { CurrentModal } from "@opidcore/components/OpidApplication";
import moment from "moment";

export default function SkipModal({data}) {
  const [loading, setLoading] = useState(false);
  const currentModal = useContext(CurrentModal);
  const skipDate = { query_period: data.query_period ? data.query_period : "" };
  const services = _.map(_.split(data.services_friendly, ","), (service, i) => {
    const thing = {
      id: _.split(data.service_ids, ",")[i],
      friendlyId: service,
      description: _.split(data.services_descriptions, ",")[i],
      skipReason: "",
    };
    return thing;
  });

  const skipReasonOpts = {
    'No on call service completed': 'No on call service completed', 
    'Service suspension': 'Service suspension', 
    'Other': 'Other'
  };

  const getSkipDate = () => {
    const date = moment(skipDate.query_period, "YYYY-MM-DD");
    return date.subtract(1, "months").format("YYYY-MM");
  }


  const doSkip = () => {
    setLoading(true);
    const skipServices = _.cloneDeep(services);

    //if the skip reason is Other, change the skipReason to the skipReasonOther, if skipReasonOther is empty, set it to null
    _.forEach(skipServices, (service) => {
      if (service.skipReason == "Other") {
        if (_.isEmpty(service.skipReasonOther)) {
          service.skipReason = null;
        } else {
          service.skipReason = service.skipReasonOther;
        }
      }
    });

    APP.central.Service.skipServices(skipServices, data.query_period ? data.query_period : getSkipDate()).then((r) => {
      if (r.result == undefined || r.result.length <= 0) {
        APP.alert("No services were skipped.");
        APP.instance.closeModal(currentModal != undefined && currentModal.id != undefined ? currentModal.id : undefined);
        return;
      }
      APP.alert(r.result);
      APP.instance.closeModal(currentModal != undefined && currentModal.id != undefined ? currentModal.id : undefined);
    });
  };

  return (
    <div>
      <div className="modal-body">
        {loading ? (
          <Loading />
        ) : (
          <Fragment>
            <Button onClick={() => doSkip()} disabled={loading} title="Skip all services with a filled 'Skip Reason'">
              Skip Services
            </Button>
            {data.query_period ? null : (<Bound to={skipDate}><DatePicker field="query_period" title="Date to skip until, only year and month selection will apply." /></Bound>)}
          </Fragment>
        )}

        <Tabular data={services}>
          <TabularColumn title="Service" data={(row) => row.friendlyId} />
          <TabularColumn title="Description" data={(row) => row.description} />
          <TabularColumn
            title="Skip Reason"
            data={(row) => (
              <Bound to={row}>
                <InputSelect field="skipReason" options={skipReasonOpts} showEmpty={true} />
                {row.skipReason == "Other" ? <InputText field="skipReasonOther" /> : null}
              </Bound>
            )}
          />
        </Tabular>
      </div>
    </div>
  );
}
