import React, { useEffect, useRef, useState } from "react";
import ActionBar from "@opidcore/components/ActionBar";
import Searchable from "@opidcore/components/Searchable";
import { Bound, Icon, InputToggleSwitch, Loading } from "@opidcore/components";
import _ from "lodash";

function PreviewElement(props) {
  return (
    <div className={"preview_element " + (props.active ? "active" : "")} onClick={props.clickEvent}>
      {props.children}
    </div>
  );
}

export default function PreviewList(props) {
  const [activePreview, setActivePreview] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [refreshList, setRefreshList] = useState(false);

  const histShow = useRef({ histShowStatus: false });

  const loadingPreview = (
    <PreviewElement key="loading">
      <Loading />
    </PreviewElement>
  );
  const [previews, setPreviews] = useState(loadingPreview);

  useEffect(() => {
    props.dataSet.callback((a, b, c) => {
      setActivePreview(a[0]);
      setActiveIndex(0);
      setRefreshList(true);
    });

    doSetPreviews();
  }, [props.dataSet]);

  useEffect(() => {
    doSetPreviews();
  }, [activeIndex]);

  useEffect(() => {
    if(refreshList) {
      setRefreshList(false);
      doSetPreviews();
    }
  }, [refreshList]);

  const activeUpdater = (d, index) => {

    setActiveIndex(index);
    setActivePreview(d);
  };

  const doSetPreviews = () => {
    if (props.data.length > 0) {
      if (activeIndex == null) {
        activeUpdater(props.data[0], 0);
      }

      let newData = props.data;
      if (props.showHistoricalToggle) {
        const field = props.historicalField ? props.historicalField : "historical";
        newData = _.filter(props.data, (dataRow) => {
          return dataRow[field] == histShow.current.histShowStatus;
        });
      }
      const newPreviews = _.map(newData, (dataRow, index) => {
        return (
          <PreviewElement key={index} clickEvent={() => activeUpdater(dataRow, index)} active={activeIndex == index ? true : false}>
            {props.children[0](dataRow)}
          </PreviewElement>
        );
      });
      setPreviews(newPreviews);
    } else {
      const newPreviews = <PreviewElement key="no-entries">No Entries Found</PreviewElement>;
      setPreviews(newPreviews);
    }
  };

  let title = undefined;
  if (props.title) {
    title = (
      <div className="titleContainer">
        <h4 className="title">{props.title}</h4>
        {props.showHistoricalToggle ? (
          <Bound to={histShow.current} onChange={doSetPreviews}>
            <InputToggleSwitch
              field="histShowStatus"
              className="inline"
              icon="eye"
              title={histShow.current.histShowStatus == undefined || histShow.current.histShowStatus == false ? "Historical Not Shown" : "Historical Shown"}
            />
          </Bound>
        ) : null}
        {props.topAdd ? (
          <div className="action_button" onClick={(e) => props.topAdd(e)} title={props.addTitle || "Add New"}>
            <Icon icon="plus" size="2x" />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  //To-Do: figure out why search searches more than the given data
  return (
    <div key="preview_list" className="preview_list">
      <div className="previews">
        <div className="preview_search">
          <ActionBar title="Actions">
            <Searchable ds={props.dataSet} searchLocal={true} />
          </ActionBar>
        </div>
        {title}

        <div className="preview_items">
          {previews}

          <PreviewElement key="add-row">
          </PreviewElement>
        </div>
      </div>
      <div className="details" key={"details_" + activeIndex}>
        {props.children[1](activePreview)}
      </div>
    </div>
  );
}
