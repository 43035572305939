import React, { useState }  from 'react';
// import { Data } from '@opidcore/Data.js';
// import { Link } from 'react-router-dom';
// import Icon from '@opidcore/components/Icon.jsx';
// import BarGraph from '../BarGraph.jsx';
import {useNavBar, useRelatedForms, addRelatedForms } from '@opidcore/hooks/WTF';
import FakeRouted from '@opidcore/components/Content/FakeRouted.jsx';
import ViewView from '../../../js/view.jsx';
import PickOne from '@opidcore/components/PickOne.jsx';

export default function NewWasteAudit(props){
	const [auditId, setAuditId] = useState(0);
	const nav = useNavBar("New Audit", []);  
	
	const onSave = (thing, that)=>{
		APP.alert("Audit #" + thing.result.id + " was created");
		APP.setState("redirect", "/ui/audit/" + thing.result.id);
	}
	
	
    return <>
    	<h2>Let's make a new audit</h2>
  
    	
    	<PickOne hideButtons={true}>
	    	<div key="copy">
	    		<button className="btn">Copy an Existing Audit</button>
	    		<div>I guess you want to copy</div>
	    		<h2>Have fun</h2>
	    	</div>
	    	<div key="new">
	    		<button className="btn">Start with a Blank</button>
	            <FakeRouted fakeParams={{id: 0}} formUid={"waste_audit"} render={(props) => <ViewView {...props}  afterSave={onSave} viewDefinition={props.viewDefinition} /> } />
	        </div>
    	</PickOne>

    </>;
}
