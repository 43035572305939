import React, { useEffect, useState } from "react";
import File, { FileItem } from "../components/File"; 
import LoadingProgress from "@opidcore/components/LoadingProgress"; 


/* 
  This is the main page for the Excel Bulk Importer
  It will allow the user to upload an excel file and then process it
  The user will be able to see the status of the file processing
  The user will be able to see the results of the file processing
*/
const ExcelBulkImporter = ({}) => {

  const TEMPLATE_STORAGE_IDENTIFIER = "529A9FC246F9779520626DACBBE2C739";

  const [currentFile, setCurrentFile] = useState(null);
  const [uploadMessage, setUploadMessage] = useState(null);

  const [file, setFile] = useState(null);
  
  //const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    APP.central.File.findByStorageIdentifier(TEMPLATE_STORAGE_IDENTIFIER).then((list)=>{
      if (list.result != null){
        setFile(list.result);
      }
    });
  }, []);

  useEffect(() => {
    if (currentFile) {

      APP.showLoading({message: "Importing...", children: <LoadingProgress /> }, ()=>{
        APP.central.ExcelBulkImporter.processFile(currentFile.id).then((processFile) => {          
          APP.socket.send(JSON.stringify(processFile));
          //setUploadMessage("Processing Complete");
        });
      });
    }
  }, [currentFile]);

  const uploaded = (file) => {
    //setLoaded(false);
    setUploadMessage("Processing " + file.filename);
    setCurrentFile(file);
  };

  return (
    <div>
      <h1>Excel Bulk Importer</h1>
      <File onUpload={uploaded} useExisting={true} label={"Drop Bulk Import File Here"} info={uploadMessage} bigDrop={true} />
      {file ? <FileItem file={file} key={file.filename}/> : null}
    </div>
  );
};

export default ExcelBulkImporter;
