import React from 'react';
import RenderMeSoftly  from './Content/RenderMeSoftly.jsx';

/** import BoundComponent from './BoundComponent';
import { DataContext } from './Bound';
import RenderMeSoftlyEditor  from './Content/RenderMeSoftlyEditor.jsx';
**/

export default function StaticRMS(props){
  
	/**
		  this.setState({ theId: me }, ()=>{
		        APP.central.Stuff.everythingIWant(me).then((d) => {
		        	if (d.result.structure.length == 0){
		        		  this.setState({theId: null}, ()=>{
		        			  this.doComponentStuff();
		        		  });
		        	}
		            this.setState({
		                contentStructure: d.result.structure
		            }, ()=>{this.loading = false});
		        });
		  });
	**/
	
	return <div>
		<h2>Hey</h2>
	</div>;
	
}
