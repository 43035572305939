import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Tabular, TabularColumn } from "@opidcore/components";
import ActionBar from "@opidcore/components/ActionBar";
import Icon from "@opidcore/components/Icon";
import { useNavBar } from "@opidcore/hooks/WTF";

import { APP } from "../../../../js";
import { newCustomer } from "../../Workflow.jsx";
import FullTextSearchable from "../../components/FullTextSearchable.jsx";
import ClientTree from "./ClientTree";

export default function ListClients(props) {
  const nav = useNavBar("Clients", [], { menu: ["clients"] });
  //const [data, dataSet] = useData("customer", { paginate: 50, orderBy: "id desc" });
  const [clients, setClients] = useState([]);
  const [fetchClients, setFetchClients] = useState(false);

  const openClientTree = (e, client) => {
    e.stopPropagation();
    APP.instance.createModal(<ClientTree client={client} />, { modal_name: "Client Tree" });
  };

  const handleClick = (row,e) => {
    if (e.defaultPrevented == false){
      APP.redirect("/ui/clients/" + row.friendly_id);
    }
  };

  const excelExport = () => {
    APP.alert("This feature needs to be reimplemented");
    // TODO MUST BE REIMPLEMENTED because we changed the way we bring in the data
    /*
    APP.central.Customer.getDefaultExportLayout().then((r) => {
      APP.instance.createModal(<ExcelExportModal defaultLayout={r.result} dataSet={dataSet} model={"Customer"}/>, { modal_name: "Excel Export" });
    });
    */
  };

  return (
    <div className="full-size">
      <ActionBar title="Actions">
        <FullTextSearchable 
          model={"Customer"} 
          method={"reasonableList"} 
          setData={setClients} 
          doFetchData={fetchClients} 
          setDoFetchData={setFetchClients} 
          maxRows={-1}
          filterOptions={{
            columns: [
              { column: "active", lookup: "clientStatus", default: "true", heading: "Active" },
              { column: "ram_name", heading: "Account Manager" },
              { column: "ba_name", heading: "Billing Team Member" }, 
              { column: "umbrella", heading: "Umbrella" },
              { column: "billing_system", heading: "Billing System" },
              { column: "accounting_group", heading: "Accounting Group" },
              { column: "billing_city", heading: "City" },
              { column: "billing_province", heading: "Province" },
              { column: "client_type_name", heading: "Client Type" },
              { column: "compensation_type_name", heading: "Compensation Type" },
              { column: "environmental_industry", heading: "Environmental Industry" },
            ]
          }} 
        />
        <Icon icon="file-excel" size="2x" title="Open export modal" onClick={() => excelExport()} />
        <a className="btn btn-new" style={{cursor: 'pointer'}} onClick={() => newCustomer()}>
          {" "}
          <Icon color="white" icon="plus" /> New Client
        </a>
      </ActionBar>


      {clients.length == 0 ? "Search to show results." : null}

      <Tabular data={clients} onClick={handleClick} pageSize={20} paginationLocation={"both"}>
        <TabularColumn
          style={{ width: "4em" }}
          data={(row) => (
            <Link className="plain_link" to={"/ui/clients/" + row.friendly_id}>
              {row.friendly_id}
            </Link>
          )}
          title="#"
          sortCol="friendlyId"
        />
        <TabularColumn
          data={(row) => (
            <Link className="plain_link" to={"/ui/clients/" + row.friendly_id}>
              {row.name}
            </Link>
          )}
          title="Client Name"
          sortCol="name"
        />
        <TabularColumn data={(row) => row.billing_address_1} title="Address" sortCol="billingAddress1" />
        <TabularColumn data={(row) => row.billing_city} title="City" sortCol="billingCity" />
        <TabularColumn style={{ width: "6em" }} data={(row) => row.billing_province} title="Province" sortCol="billingProvince" />
        <TabularColumn data={(row) => row.legal_name} title="Legal Name" sortCol="legalName" />
        <TabularColumn
          data={(row) => {
            return row.parent_customer ? row.parent_customer : row.umbrella;
          }}
          title="Client Group"
          sortCol="umbrella"
        />
        <TabularColumn data={(row) => row.billing_system} title="Billing System" />
        <TabularColumn
          style={{ width: "8em" }}
          data={(row) => {
            return <Status status={row.active ? "Active" : "Inactive"} />;
          }}
          title="Status"
          sortCol="active"
        />
        <TabularColumn
          style={{ width: "2em" }}
          data={(row) => {
            return (
              <Link to={"/ui/clients/" + row.friendly_id}>
                {" "}
                <Icon icon="edit" size="2x" />{" "}
              </Link>
            );
          }}
        />
        <TabularColumn
          style={{ width: "2em" }}
          data={(row) => {
            return (
              <div className="plain_link" onClick={(e) => openClientTree(e, row)} title="Client Tree">
                <Icon icon="sitemap" size="2x" />
              </div>
            );
          }}
        />
      </Tabular>
    </div>
  );
}

function Status({ status }) {
  let className = "status-unknown";
  let icon = "dot-circle";
  const currentStatus = status || "";

  if (["active"].indexOf(currentStatus.toLowerCase()) >= 0) {
    className = "status-active";
  }

  if (["inactive"].indexOf(currentStatus.toLowerCase()) >= 0) {
    className = "status-inactive";
    icon = "circle";
  }

  return (
    <div className={"status " + className}>
      <Icon icon={icon} />
      <strong>{status}</strong>
    </div>
  );
}
