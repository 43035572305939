import React, { Component } from 'react';

//import { connect } from 'react-redux';
//import { bindActionCreators } from 'redux';

import { InputText, InputSelect, InputCheckbox, TextArea, DatePicker, Button, Bound, InputMultiSelect, InputMultiPeopleSearch, ToolTip } from '../../../OpidCore/public/js/components';

//import * as CentralActions from '../../../OpidCore/public/js/actions/CentralActions';

//import SaveToolbar from '../components/SaveToolbar.jsx';

import * as _ from 'lodash';
//import moment from 'moment';

class FieldEditor extends Component {
    constructor(props){
        super(props);
        this.state = {...props.field};
    }
    

    
    whatUpdate(a,b,c){
        console.log(a);
        this.props.update(this.props.field,b,c);
        
        if (this.state.fieldUid == "new"){
            
            var dataField = null;
            for(var x of this.props.dataDefinitions){
                if(x.id == c){
                    dataField = x;
                }
            }
            
            if(dataField != null && dataField.defaultUid == 'field_name'){
                this.setState({fieldUid : dataField.fieldName});
            }
            else if(dataField != null  && dataField.defaultUid == 'label'){
                this.setState({fieldUid : this.state.label});
            }       
            
        }
        
    }
    
    handleChangeOptions(e){
        
    }
    
    handleBlurOptions(e){
        try{
            if (e.currentTarget.value > ""){
                var json = JSON.parse(e.currentTarget.value);
                var newOption = JSON.stringify(json);
                
                this.refs["my-bound"]._myMagic.magicalSet("fieldOptions", json);
            }
            this.setState({"optionsError": null});
        }catch(e){
            
            this.setState({"optionsError": e.message, lastError: e});
        }
    }
    
    handleDelete(){
        this.props.update("DELETE", this.state)
    }
    
    
     static getDerivedStateFromProps(props, state){
            if (true){
            
                if (props.field.id == state.id){
                    var newState = {...props.field, ...state};
                    return newState;
                }else{
                    var newState = {...props.field};
                    return newState;    
                }
                
            }
            return null;
        }    
    
     save(){
         this.props.onFieldDefinitionChanged(this.state);
     }
         
     
        render(){
            var dataOptions = {};
            var fieldDef = this.props.dataDefinitions;

            fieldDef.map((k,v)=>{
                dataOptions[k.id] = k.fieldName + " (" + k.fieldType + ")";
            }); 
            
            var error = null;

            if (this.state.optionsError){
                error = <div className="error">{this.state.optionsError}</div>
            }
            var uidEditor = null;
            var f = this.props.field;
            f = f == undefined ? f : f;

            uidEditor = <InputText className="field col-md-3" name="UID" field="fieldUid" />;
            var button = null;
            if(this.props.button != undefined && this.props.button == "apply"){
                button = <div className="field-actions">
                    <a onClick={()=>this.save()}>Apply Changes</a>
                 </div>;
            }else{
                button = <div className="field-actions">
                            <a onClick={()=>this.handleDelete()}>delete field</a>
                         </div>;
            }
            
            return <div className="field-inner">
                <Bound to={this} ref="my-bound">
                    <h3>{this.props.field.fieldUid}</h3>
                    <InputSelect options={this.props.sections} className="field col-md-3" name="Section" field="section" update={(a,b,c)=>this.whatUpdate(a,b,c)} sortByLabel = {true} showEmpty="true" />

                    <InputSelect options={dataOptions} className="field col-md-3" name="Data" field="field" showEmpty="true" update={(a,b,c)=>this.whatUpdate(a,b,c)} sortByLabel = {true} />
                            
                    <InputText className="field col-md-3" name="Label" field="label"/>
                    
                    {uidEditor}
                    
                    <h4>Options:</h4>
                    <textarea ref="options" onBlur={(e)=>this.handleBlurOptions(e)} onChange={(e)=>this.handleChangeOptions(e)} defaultValue={JSON.stringify(this.state.fieldOptions)} />
                    {error}
                    
                    {button}
                    
                </Bound>
            </div>
        }
}

export default FieldEditor;