import React, { Fragment, useEffect, useRef, useState } from "react";
import { Bound, Button, DatePicker, FlexBreak, Icon, InputCheckbox, InputSelect, InputText, InputToggleSwitch, Loading, SaveToolbar } from "@opidcore/components";
import { NiceBox, NiceBoxContainer } from "./NiceBox";
import { NiceCustomer, NiceSite } from "../UI/Nice";
import { ClientSiteServiceSelector } from "./ClientSiteSelector";
import File from "../components/File";
import _, { set } from "lodash";

// pass the case object in as a prop
// if the case object has a grease trap case, shows the grease trap case
export default function SupportGreaseTrap({ bound, saving }) {
  const caseId = bound.magicalGet("id", null);
  const [loading, setLoading] = useState(false);

  const [greaseTrapCase, setGreaseTrapCase] = useState(null);

  // Fetch Grease Trap Case
  useEffect(() => {
    setLoading(true);
    APP.central.GreaseTrapCase.fetchForCase(caseId).then((fetchForCase) => {
      setLoading(false);
      if (fetchForCase.result == null) {
        return;
      }
      setGreaseTrapCase(fetchForCase.result);
    });
  }, [caseId]);

  return (
    <div>
      <hr />
      {loading ? <Loading /> : null}
      {greaseTrapCase ? <EditGreaseTrapInfo greaseTrapCase={greaseTrapCase} setGreaseTrapCase={setGreaseTrapCase} saving={saving} /> : null}
      <hr />
    </div>
  );
}

const EditGreaseTrapInfo = ({ greaseTrapCase, setGreaseTrapCase, saving }) => {
  const [greaseTrapCaseState, setGreaseTrapCaseState] = useState(greaseTrapCase);
  const showEmailRef = useRef({ value: false });
  const [showEmail, setShowEmail] = useState(false);

  const [services, setServices] = useState([]);
  const [servicesOpts, setServicesOpts] = useState({});

  const [loading, setLoading] = useState(false);

  // Fetch Services for the Site
  useEffect(() => {
    APP.central.Service.fetchServicesForSite(greaseTrapCase.siteId).then((fetchServicesForSite) => {
      setServices(fetchServicesForSite.result);
    });
  }, [greaseTrapCaseState.siteId]);

  // set options for services
  useEffect(() => {
    const servicesOpts = _.fromPairs(_.map(services, (service) => [service.id, service.longDescription]));
    setServicesOpts(servicesOpts);
  }, [services]);

  useEffect(() => {
    if (saving) {
      doSaveGreaseTrapCase();
    }
  }, [saving]);

  // save changes to grease trap case
  const doSaveGreaseTrapCase = () => {
    setLoading(true);
    return APP.central.GreaseTrapCase.saveChanges(greaseTrapCaseState.id, greaseTrapCaseState).then((saveChanges) => {
      if (saveChanges.result) {
        setGreaseTrapCase(saveChanges.result);
      }
      setLoading(false);
      return saveChanges;
    });
  };

  // set client, site, (not service) from client site service selector
  const clientSiteServiceSetter = (selectedService, selectedSite, selectedClient) => {
    setGreaseTrapCaseState({
      ...greaseTrapCaseState,
      clientId: selectedClient.id,
      siteId: selectedSite.id,
    });
  };

  // open client site service selector
  const openClientSiteSelect = () => {
    APP.instance.createModal(
      <ClientSiteServiceSelector
        serviceCheckable={true}
        // @ts-ignore
        callback={(selectedService, selectedSite, selectedClient) => clientSiteServiceSetter(selectedService, selectedSite, selectedClient)}
      />,
      { modal_name: "Client, Site, Service Select" }
    );
    //APP.instance.createModal(<FastClientSiteServiceSelector serviceCheckable={true} callback={(selectedService, selectedSite, selectedClient) => clientSiteServiceSetter(selectedService, selectedSite, selectedClient)} />, { modal_name: "Client, Site, Service Select" });
  };

  // set show email
  const doSetShowEmail = (val) => {
    setShowEmail(val);
  };

  // send notification email
  const sendNotificationEmail = () => {
    doSaveGreaseTrapCase().then((doSaveGreaseTrapCaseResult) => {
      if (doSaveGreaseTrapCaseResult.result && doSaveGreaseTrapCaseResult.result.id) {
        APP.central.GreaseTrapCase.findGreaseTrapContactsForSite(doSaveGreaseTrapCaseResult.result.siteId).then((findGreaseTrapContactsForSite) => {
          if (findGreaseTrapContactsForSite.result && findGreaseTrapContactsForSite.result.length > 0) {
            const contactsString = _.map(findGreaseTrapContactsForSite.result, (contact) => {
              return contact.email;
            });
            APP.confirm("Send " + findGreaseTrapContactsForSite.result.length + " notification email(s)? Recipients: " + _.join(contactsString, ", "), (res) => {
              if (res == true) {
                APP.central.GreaseTrapCase.sendNotificationEmail(doSaveGreaseTrapCaseResult.result.id).then((sendNotificationEmail) => {
                  if (sendNotificationEmail.result) {
                    setGreaseTrapCaseState({
                      ...doSaveGreaseTrapCaseResult.result,
                      expectedDateClientNotified: true,
                    });
                  }
                  APP.alert(sendNotificationEmail.result ? sendNotificationEmail.result : "Email Failed");
                });
              }
            });
          } else {
            APP.alert("No grease trap contacts found for this site. No email will be sent.");
          }
        });
      } else {
        APP.alert("Error encountered while saving grease trap case. Please try again.");
      }
    });
  };

  // send work order email
  const sendWorkOrderEmail = () => {
    //open modal for file drop
    doSaveGreaseTrapCase().then((doSaveGreaseTrapCaseResult) => {
      if (doSaveGreaseTrapCaseResult.result && doSaveGreaseTrapCaseResult.result.id) {
        APP.instance.createModal(<WorkOrderEmailModal greaseTrapCase={doSaveGreaseTrapCaseResult.result} />, { modal_name: "Work Order Email", className: "task-note-modal" });
      } else {
        APP.alert("Error encountered while saving grease trap case. Please try again.");
      }
    });
  };

  // add a grease trap info
  const addGreaseTrapInfo = () => {
    setGreaseTrapCaseState({
      ...greaseTrapCaseState,
      greaseTrapInfo: [
        ...greaseTrapCaseState.greaseTrapInfo,
        {
          id: 0,
          greaseTrapCase: greaseTrapCaseState.id,
          waterLevel: 0,
          solidsLevel: 0,
          fogLevel: 0,
          repairRequired: false,
          __type: "GreaseTrapInfo",
        },
      ],
    });
  };

  const deleteGreaseTrapInfo = (gti, index) => {
    if (gti.id && gti.id > 0) {
      APP.central.GreaseTrapCase.deleteGreaseTrapInfo(gti.id).then((deleteGreaseTrapInfo) => {
        if (deleteGreaseTrapInfo.result == "OK") {
          const newGreaseTrapInfo = _.filter(greaseTrapCaseState.greaseTrapInfo, (greaseTrapInfo) => {
            return greaseTrapInfo.id != gti.id;
          });
          setGreaseTrapCaseState({
            ...greaseTrapCaseState,
            greaseTrapInfo: newGreaseTrapInfo,
          });
        } else {
          APP.alert("Error Deleting Grease Trap Info");
        }
      });
    } else {
      // not saved yet, remove based on index
      const newGreaseTrapInfo = _.filter(greaseTrapCaseState.greaseTrapInfo, (greaseTrapInfo, i) => {
        return i != index;
      });
      setGreaseTrapCaseState({
        ...greaseTrapCaseState,
        greaseTrapInfo: newGreaseTrapInfo,
      });
    }
  };

  return (
    <Fragment>
      <div className="greaseTrapCaseContainer">
        <NiceBoxContainer>
          <h2>Grease Trap Information</h2>
          <FlexBreak />
          <Bound to={greaseTrapCaseState}>
            {greaseTrapCaseState.clientId && greaseTrapCaseState.siteId ? null : (
              <NiceBox label={""}>
                <Button style={{ margin: "5px" }} onClick={() => openClientSiteSelect()}>
                  Client Site Selector
                </Button>
              </NiceBox>
            )}
            <NiceBox label="Account Name">
              <div className={"field"}>{greaseTrapCaseState.clientId ? <NiceCustomer>{greaseTrapCaseState.clientId}</NiceCustomer> : <span> Please Use Service Selector. </span>}</div>
            </NiceBox>

            <NiceBox label="Site Name">
              <div className={"field"}>{greaseTrapCaseState.siteId ? <NiceSite>{greaseTrapCaseState.siteId}</NiceSite> : <span> Please Use Service Selector. </span>}</div>
            </NiceBox>

            <NiceBox label="Ticket Origin">
              <InputSelect field="origin" options={["Manual", "Email"]} />
            </NiceBox>

            <NiceBox label="Work Order #">
              <InputText field="workOrder" />
            </NiceBox>

            <NiceBox label="Expected Date of Service">
              <DatePicker field="expectedServiceDate" />
            </NiceBox>

            <NiceBox label="Completion Date">
              <DatePicker field="completionDate" />
            </NiceBox>

            <NiceBox label="Client Notified of Expected Timing?">
              <InputCheckbox field="expectedDateClientNotified" />
            </NiceBox>

            <NiceBox label="Work Order Communicated to Client?">
              <InputCheckbox field="workOrderClientNotified" />
            </NiceBox>
          </Bound>
          {greaseTrapCaseState.importEmail ? (
            <div style={{ width: "100%" }}>
              <NiceBox label="Email">
                <Bound to={showEmailRef.current}>
                  <InputToggleSwitch field="value" onChange={(val) => doSetShowEmail(val)} name={showEmailRef.current.value == undefined || showEmailRef.current.value == false ? "Source Email Not Shown " : "Source Email Shown "} />
                </Bound>
                {showEmail ? <div dangerouslySetInnerHTML={{ __html: greaseTrapCaseState.importEmail }} className="ticketCommentBody" /> : null}
              </NiceBox>
            </div>
          ) : null}
        </NiceBoxContainer>
        <div style={{ height: "10px" }}></div>
        <NiceBoxContainer>
          <Bound to={greaseTrapCaseState}>
            {greaseTrapCaseState.greaseTrapInfo ? (
              <Fragment>
                {_.map(greaseTrapCaseState.greaseTrapInfo, (gti, index) => {
                  return (
                    <div className="greaseTrapInfoContainer">
                      <Bound to={gti}>
                        <NiceBox label="Service">
                          <InputSelect field="service" options={servicesOpts} showEmpty={true} />
                        </NiceBox>

                        <NiceBox label="Water Level">
                          <InputText field="waterLevel" />
                        </NiceBox>

                        <NiceBox label="Solids Level">
                          <InputText field="solidsLevel" />
                        </NiceBox>

                        <NiceBox label="FOG Level">
                          <InputText field="fogLevel" />
                        </NiceBox>

                        <NiceBox label="Repair Recommended/Required?">
                          <InputCheckbox field="repairRequired" />
                        </NiceBox>
                        <div style={{ alignSelf: "center" }}>
                          <Icon icon="trash" size="2x" onClick={() => deleteGreaseTrapInfo(gti, index)} type="span" className="clickable-icon" />
                        </div>
                      </Bound>
                    </div>
                  );
                })}
              </Fragment>
            ) : null}
            <div className="action_button" style={{ width: "100%", marginTop: "5px", marginBottom: "15px" }} onClick={() => addGreaseTrapInfo()} title={"Add Grease Trap Info"}>
              <Icon icon="plus" size="2x" />
            </div>
          </Bound>
        </NiceBoxContainer>

        {loading ? (
          <Loading />
        ) : (
          <SaveToolbar handleSave={doSaveGreaseTrapCase} saveVerb={"Save Grease Trap Info"} saveButtonStyle={{ alignItems: "center", alignSelf: "center", height: "74px" }}>
            <Button onClick={() => sendNotificationEmail()} type={"btn"} className={"btn-primary flexed"} style={{ alignItems: "center", alignSelf: "center", height: "74px" }}>
              Send Notification Email
            </Button>

            <Button onClick={() => sendWorkOrderEmail()} type={"btn"} className={"btn-primary flexed"} style={{ alignItems: "center", alignSelf: "center", height: "74px" }}>
              Send Work Order
            </Button>
          </SaveToolbar>
        )}
      </div>
    </Fragment>
  );
};

const WorkOrderEmailModal = ({ greaseTrapCase }) => {
  const [greaseTrapCaseState, setGreaseTrapCaseState] = useState(greaseTrapCase);
  const [currentFile, setCurrentFile] = useState(null);

  const uploaded = (file) => {
    setCurrentFile(file);
  };

  // send work order email
  const sendEmail = () => {
    APP.central.GreaseTrapCase.findGreaseTrapContactsForSite(greaseTrapCaseState.siteId).then((findGreaseTrapContactsForSite) => {
      if (findGreaseTrapContactsForSite.result && findGreaseTrapContactsForSite.result.length > 0) {
        const contactsString = _.map(findGreaseTrapContactsForSite.result, (contact) => {
          return contact.email;
        });
        APP.confirm("Send " + findGreaseTrapContactsForSite.result.length + " work order email(s)? Recipients: " + _.join(contactsString, ", "), (res) => {
          if (res == true) {
            APP.central.GreaseTrapCase.sendWorkOrderEmail(greaseTrapCaseState.id, currentFile.id).then((sendWorkOrderEmail) => {
              APP.alert(sendWorkOrderEmail.result ? sendWorkOrderEmail.result : "Email Failed");
            });
          }
        });
      } else {
        APP.alert("No grease trap contacts found for this site. No email will be sent.");
      }
    });
  };

  return (
    <div>
      <File onUpload={uploaded} label={"Drop Work Order File Here"} />
      {currentFile ? (
        <Button onClick={() => sendEmail()} type={"btn"} className={"btn-primary flexed"} style={{ alignItems: "center", alignSelf: "center", height: "74px" }}>
          Send Work Order
        </Button>
      ) : null}
    </div>
  );
};
