import React, { useState, useRef, Fragment } from 'react';
import { PageHeading, ActionBar, Bound, TabularDataSet, TabularColumn, Kanban,  Searchable,  Loading, LinkButton, Button, Selection } from '@opidcore/components';
//import MultiOptionToggle from "../../components/MultiOptionToggle";
import { useData } from "@opidcore/hooks/WTF";
import { Icon } from '../../../../../OpidCore/public/js/components';
import { Link } from 'react-router-dom';
import _ from 'lodash';
//import PeriodSelect from '../../components/PeriodSelect';
import moment from 'moment';
import { Util } from '@opidcore/Util';
import CesarFilter from '../../components/CesarFilter';
import { FileItem } from '../../components/File';
import LoadingProgress from "@opidcore/components/LoadingProgress";
import { StaffSelect } from '../../components';
import LogsBySubject from '../Data/View/LogsBySubject.jsx';
import AutomationLog from './AutomationLog';

const ServiceChunk = ({ service }) => {
    if (service == undefined) {
        return null;
    }

    const editService = () => {
        APP.instance.createModal("ui/services/edit/" + service.id, { modal_name: service.shortDescription });
    }

    return <span className="service-chunk" key={"service-" + service.id}>
        {service.shortDescription} 
        <Icon title="Edit" icon="pencil" onClick={editService} />
    </span>
}

const SiteChunk = ({site}) =>{
    if(site == undefined){
        return null;
    }

    const editSite = () => {
        APP.instance.createModal("ui/sites/edit/" + site.id, { modal_name: site.name });
    }

    return <span className="site-chunk" key={"site-" + site.id}>
        {site.name}
        <Icon title="Edit" icon="pencil" onClick={editSite} />
    </span>
}

const SiteChunkSimple = ({siteId, siteName}) =>{
    if(siteId == undefined || siteName == undefined){
        return null;
    }

    const editSite = () => {
        APP.instance.createModal("ui/sites/edit/" + siteId, { modal_name: siteName });
    }

    return <span className="site-chunk" key={"site-" + siteId}>
        {siteName}
        <Icon title="Edit" icon="pencil" onClick={editSite} />
    </span>
}
const FileChunk = ({apId, externalID, row}) =>{
    let file = <>No File?</>;

    if (row.storage_identifier){
        file = <FileItem
            file={{
            filename: row.filename,
            storageIdentifier: row.storage_identifier,
            }}
            text={ externalID + " " + row.filename}
        />
    }
    return <>{file}</>
}

const APChunk = ({apId, externalID, row}) =>{
    let link = "/ui/apinvoice/" + apId;
    if (row.large_ap){
        //link += "/" + row.vendor_site_ids;
    }

    return <span className="ap-chunk" key={"ap-" + apId}>
        <LinkButton target={link} title={ "AP  #" + apId } className="simple">
            #{apId} <Icon title="Edit" icon="pencil"/>
        </LinkButton>
    </span>;
}


const ARChunk = ({arId, row}) =>{
    let chunk = null;



    if(arId == undefined || arId <= 0){
        chunk = <span>-</span>;
    }else{
        chunk = <span className="ap-chunk" key={"ap-" + arId}>
            <LinkButton target={"/ui/arinvoice/" + arId} title={ "AR  #" + arId } className="simple">
                #{arId} <Icon title="Edit" icon="pencil"/>
            </LinkButton>
        </span>;
    }

    return <>{chunk}</>

}

const TotalChunk = ({total, mandatoryPrice, status, service}) =>{
    if(service == undefined){
        return null;
    }

    return <span className="total-chunk" key={"total-service-" + service.id}>
        <span className="total">${Util.roundNice(total == undefined ? 0 : total, 2)}</span>
        <span className="total-missing">{status == "missing" && mandatoryPrice != 0 ? "$" + Util.roundNice(mandatoryPrice, 2) : ""}</span>
    </span>
}

const NicePriority = ({children}) =>{
    return <span className="nice-priority" key="nice-priority">
        Priority: {children}
    </span>
}


const kanbanCardContent = ({ data }) => {
    return <div key="ap-status-kanban-card" className="ap-status-kanban-card waste_kanban-card_content">
        <div className="xero-link">
            {data.xero_uuid? <Icon icon="sync" title="Synced to Xero" colour="#00b300"/> : ""}
        </div>
        <div className="ap-id">
            <FileChunk apId={data.ap_id} externalID={data.external_invoice_number} row={data} />
        </div>
        <div className="total">
           <TotalChunk total={data.total} mandatoryPrice={data.mandatory_price} status={data.status} service={data.Service} />
        </div>
        <div className="vendor">
            <Icon title="Vendor" icon="house" />
            {data.Vendor ? data.Vendor.name : ""}
        </div>
        <div className="site">
            <Icon title="Site Location" icon="location" />
            <SiteChunk site={data.Site} />
        </div>
        <div className="service">
            <Icon title="Service" icon="trash" />
            <ServiceChunk service={data.Service} />
        </div>
        <div className="invoice-preparer">
            Prepared By: {data.invoice_preparer}
        </div>
        <div className="client-priority">
            <NicePriority>{data.client_billing_priority}</NicePriority>
        </div>        
        <div className="related-invoices flex">
            { data.ap_id > 0 ? <Link to={"/ui/snowman/ap/" + data.ap_id + "?status=" + data.status}>AP Import Details</Link> : null }
            { data.ap_id > 0 ? <Link to={"/ui/apinvoice/" + data.ap_id }>AP #{data.ap_id}</Link> : null }
            { data.ar_id > 0 ? <Link to={"/ui/arinvoice/" + data.ar_id }>AR #{data.ar_id}</Link> : null }
        </div>
    </div>
}

const KanbanView = ({ dataSet, columnTotals, statuses }) => {


    const headingFunction = (columnData, columnStatus) => {
        if (columnTotals[columnStatus] == undefined) {
            return "Total: $" + Util.roundNice(0, 2);
        }

        return "Total: $" + Util.roundNice(columnTotals[columnStatus], 2);
    };

    const kanbanProps = {
        data: dataSet,
        isLoading: dataSet.isLoading,
        status: statuses,
        cardContent: kanbanCardContent,
        headingFunction: headingFunction,
        sortBy: (r)=>{ return r.client_billing_priority_order},
        showActions: false
    }

    return <div key="kanban-view" className="kanban-view">
        <Kanban {...kanbanProps} />
    </div>;
}

const APTaskList = ({ }) => {
    const [showKanban, setShowKanban] = useState(true);
    const [columnTotals, setColumnTotals] = useState({});
    const [refreshLoading, setRefreshLoading] = useState(false);
    const [vendors, setVendors] = useState([]);
    const [data, dataSet] = useData("ap-task-list", APP.central.APInvoice.taskList);
    const allStatuses = [{ key: "missing", label: "Missing Vendor Invoice", order: 1 }, { key: "unmatched", label: "Missing Client Match", order: 2 }, { key: "match", label: "Matched", order: 3 }];
    const [statuses, setStatuses] = useState([]);
    const [processStatus, setProcessStatus] = useState()
    const boundRef = useRef({ showKanban: true, vendor: undefined, period: moment().format("YYYY-MM"), activeCols: allStatuses });
    const [selection, setSelection] = useState([]);
    const canManage = APP.getWebSession().access("Manage AP Tasks");

    const grandTotal = Util.roundNice(_.sum(_.values(columnTotals)), 2);

    const loaded = data.length > 0;
    
    const checkMail = ()=>{
        APP.showLoading({message: "Importing Mail", children: <LoadingProgress /> }, ()=>{
            APP.central.TestBulkImport.readMessages().then((r)=>{
              APP.socket.send(JSON.stringify(r));
            });
          })        
    }

    const office365Check = ()=>{
        window.open("/api/v1/testbulkimport/office365Auth", "_new", "width=350,height=650");
    }

    function assignToStaff() {
        

        
        const apIds = selection.map((s)=>{
                if (s.large_ap){
                    return "" + s.ap_id + "-" + s.site_id;
                }
                            
                return "" + s.ap_id
            }
        );
        
        APP.central.APInvoice.assignAPsTo(parseInt(boundRef.current.staff), apIds.join(",")).then( (r)=>{
            dataSet.fetch();
            setSelection([]);
        });
    }

    const deleteAllSelected = () => {
        const ids = selection.map((row) => row.ap_id).join(",");
    
        APP.central.APInvoice.findSameSourceARs(ids).then((r) => {
            let ARIDs = "";
            if (r.result && r.result.length > 0) {
            ARIDs =
                " This will also delete ARs: " +
                _.join(_.sortBy(_.uniq(
                    _.map(r.result, (ar) => {
                        return ar.id;
                    })
                    )
                ),", "
                );
            }
            APP.confirm("Are you sure you want to delete these?" + ARIDs, (res) => {
            if (res == true) {
                APP.central.APInvoice.deleteAPandARs(ids).then((r) => {
                setSelection([]);
                dataSet.fetch();
                });
            }
            });
        });
    };

    const openLogs = ()=>{
		//APP.instance.createModal(<LogsBySubject subject="Invoice Automation" />, {modal_name: "Invoice Automation Logs"});
        APP.instance.createModal(<AutomationLog />)
	}

    const refreshDataSet = ()=>{
        setRefreshLoading(true);
        dataSet.fetch();
        setTimeout(()=>{setRefreshLoading(false)},1000)        
    }

    return <div key="ap-status-list" className="ap-status-list">
        <PageHeading>AP Tasks</PageHeading>
       
        <ActionBar title="Actions">
            <div>
                <Searchable ds={dataSet} />
                <CesarFilter
                  columns={[
                      { column: "vendor_name", heading:"Vendor" }, 
                      { column: "site_name", heading:"Site" }, 
                      { column: "customer_name", heading:"Client" },  
                      { column: "invoice_preparer" }, 
                      { column: "status" }, 
                      { column: "ap_type" }, 
                      { column: "client_billing_priority" } 
                  ]}
                  dataSet={dataSet}   
                />                   
               
                <Button onClick={openLogs} style={{ marginRight:"10px"}}>Automation Logs</Button>
                <Button onClick={()=>{refreshDataSet() }} style={{ marginRight:"10px"}} >Refresh</Button>               
                <Button onClick={()=>{ office365Check() }} style={{ marginRight:"10px"}}>Office365 Check</Button>
                <Button onClick={()=>{ checkMail() }}>Process Mailbox</Button>{refreshLoading?<div style={{marginTop:"10px"}}><Loading/></div>:null} 
               
            </div>
          



            <Bound to={boundRef.current}>
                <div className="filters">
                    {/**<div className="ARInvoice-InputMultiCheckbox">
                        <InputMultiCheckbox field="activeCols" optionsCollection={allStatuses} optionKey={"key"}>
                            {(row) => <div className="tile-item">{row.label}</div>}
                        </InputMultiCheckbox>
                    </div>**/}
                </div>

                 { selection.length > 0 && canManage ? <>
                    <div>
                        <StaffSelect what="staff" field="staff" name="Staff" /> <Button onClick={()=>{ assignToStaff() }}>Assign</Button>

                        <Button onClick={deleteAllSelected}>Delete</Button>
                    </div>
                 </> : null }
            </Bound>
        </ActionBar>
        
        {loaded ? <TabularDataSet dataSet={dataSet} sticky={true}>
            <TabularColumn data={(row) =><Selection selection={selection} data={data} setter={setSelection} item={row} label={(row) => ""} />} title=""/>
            <TabularColumn data={(row) => <FileChunk apId={row.ap_id} row={row} externalID={row.external_invoice_number} />} title="Invoice #" />
            <TabularColumn data={(row) => row.vendor_name != undefined ? <div><span className="vendor-label">{row.vendor_name}</span></div> : ""} title="Vendor" />
            <TabularColumn data={(row) => row.date_received} title="Date Received" />
            <TabularColumn data={(row) => moment(row.time_created).from()} title="Time Received" sortCol="time_created"/>
            <TabularColumn data={(row) => row.invoice_date} title="Invoice Date" />
            <TabularColumn data={(row) => row.billing_period} title="Billing Period" />
            <TabularColumn data={(row) => row.customer_name } title="Client" />
            <TabularColumn data={(row) => row.site_name} title="Site" />
            {/* <TabularColumn data={(row) => <SiteChunkSimple siteId={row.site_id} siteName={row.site_name} />} title="Site" /> */}
            <TabularColumn data={(row) => row.status} title="Status" />
            <TabularColumn data={(row) => row.ap_type} title="Type" />
            <TabularColumn data={(row) => row.invoice_preparer} title="Assigned To" />            
            <TabularColumn data={(row) => row.error_count} title="Issues" />            
            <TabularColumn data={(row) => {
                    return (
                    <Link onClick={(e)=>e.stopPropagation()} to={"/ui/snowman/payables/" + row.ap_id}>
                        <Icon icon="line-columns" size="2x" title="Activity Matching" />
                    </Link>
                    );
                }}
            />
            <TabularColumn data={(row) => <APChunk apId={row.ap_id} row={row} externalID={row.external_invoice_number} />} title="AP" />
            <TabularColumn data={(row) => _.map(row.ar_invoice_ids, (arId)=><ARChunk key={arId} arId={arId} row={row} />)} title="AR" />
            <TabularColumn data={(row) => <APNoteEditor apId={row.ap_id} initialNote={row.internal_notes} />} title="Notes" />             
        </TabularDataSet> : "" }

    </div>
}

export default APTaskList;

const APNoteEditor = ({ apId, initialNote }) => {
  const [note, setNote] = useState(initialNote ? initialNote : "");
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const saveNote = () => {
    setLoading(true);
    APP.central.APInvoice.saveAPInternalNote(apId, note).then((r) => {
      setLoading(false);
      toggleEdit();
    });
  };

  const toggleEdit = () => {
    setEditing(!editing);
  }

  return editing ? 
    <div className='field'>
      <textarea value={note} rows={3} onChange={e=>setNote(e.target.value)} className="form-control" />

      {loading ? <Loading /> : <Button
        onClick={() => {
          saveNote();
        }}
      >
        Save
      </Button>} 
      
      <Icon title="Edit" icon="pencil" onClick={toggleEdit} />

    </div> 
    : 
    <Fragment>
      {note}
      <Icon title="Edit" icon="pencil" onClick={toggleEdit} />
    </Fragment>

};

