
import React, { Fragment, useContext, useEffect, useRef, useState } from "react"
import { Button, Icon } from "@opidcore/components";
import { DataContext } from "@opidcore/components/Bound";
export default function FormulaEditor(props) {
    const bound = useContext(DataContext);
    const calculatorBound = bound.getBound("calculator")._ourMagic
    const [selectionResult, setSelectionResult] = useState(undefined)
    const [result, setResult] = useState(undefined)
    const [readOnly, setReadOnly] = useState(false)
    const [calcCount, setCalcCount] = useState(0)
    const [functions, setFunctions] = useState([])
    const [fields, setFields] = useState([])
    const calculatorInput = useRef()

    const testCalculator = () => {
        const line = bound.to;
        console.log(line)

        APP.central.Calculator.testLineItemCalculator(line).then((r) => {
            if (r.result != null) {             
                setResult(r.result)
                setCalcCount(calcCount + 1)
                setFields(r.result.fields)
                setFunctions(r.result.functions)
            }
        });
    };

    const testSelection = (selected) => {
        const line = bound.to
        line.calculator.selectedFormula = selected // This causes the selected section to become the formula, need a new field in the calculator       

        APP.central.Calculator.testSelectionCalculator(line).then((r) => {
            if (r.result != null) {                
                setSelectionResult(r.result)

            }
        })

    }

    const appendFormula = (thing) => {
        let currentFormula = calculatorBound.magicalGet("formula", "")
        currentFormula += thing
        calculatorBound.magicalSet("formula", currentFormula)
        calculatorInput.current.value = currentFormula
        testCalculator()
    }

    useEffect(() => {
        const formula = calculatorBound.magicalGet("formula", props.defaultFormula);
        
        calculatorBound.magicalState("id", (a, b, c) => {
            if (calcCount == 0) testCalculator()
        })

        calculatorBound.magicalState("formula", (value) => {
            if (calculatorInput.current){
                if(calculatorInput.current.value == ""){
                    calculatorInput.current.value = value;
                }
            }
        })

        testCalculator();
    }, [bound]);

    const readFormula = (e) => {
        const currentFormula = e.currentTarget.value
        calculatorBound.magicalSet("formula", currentFormula)
        testCalculator()
    }

    const GenerateFields = () => {
        let fieldArray = []        
        fields.forEach((element) => {
            fieldArray.push(<Button onClick={() => appendFormula(element.field)}>{element.label}</Button>)
        })
        return <Fragment>{fieldArray}</Fragment>
    }

    const GenerateFunctions = () => {
        let functionArray = []       
        functions.forEach((element) => {
            functionArray.push(<Button onClick={() => appendFormula(element.function)}>{element.label}</Button>)
        })
        return <Fragment>{functionArray}</Fragment>
    }

    //<InputText readOnly={readOnly} type="textarea" field="calculator.formula" name="Formula" onMouseUp={() => window.getSelection().toString().length > 0 ? testSelection(window.getSelection().toString()) : null} />
    return <div className="line-item-calculator-formula">
        <textarea ref={calculatorInput} onKeyUp={readFormula} onMouseUp={() => window.getSelection().toString().length > 0 ? testSelection(window.getSelection().toString()) : null}></textarea>
        {result == undefined ? <div>Press the calculator button to check the formula.</div> : (result.result == null ? <div>Error.</div> : <div> <span className="equals">=</span><span className="result">{result.result}</span></div>)}
        <Icon icon="calculator" onClick={testCalculator} />
        {selectionResult == undefined ? <div>Select part of the formula.</div> : (selectionResult.result == null ? <div>Error.</div> : <div>Current selection evaluates to {selectionResult.result}.</div>)}
        <div className="calculator-buttons">
            <Button onClick={() => appendFormula("+")}>+</Button>
            <Button onClick={() => appendFormula("-")}>-</Button>
            <Button onClick={() => appendFormula("*")}>×</Button>
            <Button onClick={() => appendFormula("/")}>÷</Button>
            <Button onClick={() => appendFormula(">")}>&gt;</Button>
            <Button onClick={() => appendFormula(">=")}>≥</Button>
            <Button onClick={() => appendFormula("<")}>&lt;</Button>
            <Button onClick={() => appendFormula("<=")}>≤</Button>
            <GenerateFields />
            <GenerateFunctions />
        </div>
    </div>
}