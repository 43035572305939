import React from 'react';
import _ from 'lodash';

/* Example of Groups
const groups = [new Group("client", (autoCompleteResult) => "Client " + autoCompleteResult.basis.fullEntity.Customer.friendlyId, ClientHeader),
    new Group("site", (autoCompleteResult) => "Site " + (autoCompleteResult == undefined? "No Site" : autoCompleteResult.basis.friendlyId), SiteHeader),
    new Group("service", (autoCompleteResult) => "Service " + autoCompleteResult.basis.fullEntity.service, ServiceHeader)
]; 
*/

class Group extends Map {
    constructor(key, labeller, component){
        super();
        this.key = key;
        this.labeller = labeller;
        this.component = component;
    }
}

const GroupBreakdown = ({groups = [], data = [], currentSelection = [], level = 0, defaultProps = {}}) => {
    if(groups.length <= level){
        return null;
    }

    const mappedChildren = _.map(_.groupBy(data, groups[level].labeller), (groupData, label) => {
        const Header = groups[level].component;
        return <span key={label} >
            <div className="grouped-item" style={{ marginLeft: 3 * level + "em" }}>
                <Header label={label} groupData={groupData} currentSelection={currentSelection} {...defaultProps}/>
            </div>
            <GroupBreakdown groups={groups} data={groupData} currentSelection={currentSelection} level={level + 1} defaultProps={defaultProps}/>
        </span>;
    });

    return <div key="group-breakdown">
        {mappedChildren}
    </div>
}

export { Group };
export default GroupBreakdown;