import React, { useEffect, useState } from 'react';

import { Bound, BoundCommit, BoundMagic, InputAutoComplete, InputCheckbox, InputSelect, InputText, SaveToolbar, useMagic } from '@opidcore/components';
import _ from 'lodash';

const EditClientContactList = ({data = {id: 0}}) => {
    const boundMagic = useMagic(data);
    const [contactOptions, setContactOptions] = useState({data: [], loading: false, clientId: 0});
    const [siteData, setSiteData] = useState({data: [], loading: false, clientId: 0});
    const [umbrellaOptions, setUmbrellaOptions] = useState({data: {}, loading: false, clientId: 0});
    const [sitePortalSettingOptions, setSitePortalSettingOptions] = useState([]); 
    const [showSiteFilters, setShowSiteFilters] = useState(false);
    const clientField = "customer", contactField = "customerContact", siteField = "site", umbrellaField = "umbrella";
    
    useEffect(()=>{
        if(data.id == 0){
            APP.central.CustomerContact.createContactList(0).then((r)=>{
                boundMagic.replaceTo(r.result);
            });
        }
    }, []);

    useEffect(()=>{
        boundMagic.magicalState(clientField, (val)=>{
            let client = val;
            if(client == ""){
                client = null;
            }

            if(client != undefined){
                setContactOptions({data: [], loading: true, clientId: client.id});
                setSiteData({data: [], loading: true, clientId: client.id});
                setUmbrellaOptions({data: {}, loading: true, clientId: client.id});
            } else {
                setContactOptions({data: [], loading: false, clientId: null});
                setSiteData({data: [], loading: false, clientId: null});
                setUmbrellaOptions({data: {}, loading: false, clientId: null});
            }
        });
    }, [boundMagic]);

    useEffect(()=>{
        if(contactOptions.loading == true){
            APP.central.CustomerContact.fetchForClient(contactOptions.clientId).then((r)=>{
                setContactOptions({data: r.result, loading: false, clientId: contactOptions.clientId});
            });
        }
    }, [contactOptions.loading]);

    useEffect(()=>{
        if(umbrellaOptions.loading == true){
            APP.central.Customer.fetchSiteUmbrellas(umbrellaOptions.clientId).then((r)=>{
                setUmbrellaOptions({data: r.result, loading: false, clientId: contactOptions.clientId});
            });
        }
    }, [umbrellaOptions.loading]);

    useEffect(()=>{
        if(siteData.loading == true){
            APP.central.Site.fetchSitePortalSettingsForCustomerAndContactList(siteData.clientId, data.id).then((r)=>{
                setSiteData({data: r.result, loading: false, clientId: contactOptions.clientId});
            });
        }
    }, [siteData.loading]);

    useEffect(()=>{
        if(siteData.loading == false && siteData.clientId > 0){
            updateSiteSelectOptions();
        }
    }, [siteData.data]);

    useEffect(()=>{
        boundMagic.magicalSet("visibleSites", sitePortalSettingOptions);
    }, [sitePortalSettingOptions]);

    const contactDisplayValue = (option)=>{
        let val = option.firstName + " " + option.lastName;

        return val;
    }

    const onChangeClient = (autoCompleteOption) => {
        boundMagic.magicalSet(clientField, autoCompleteOption.basis);
    };

    const updateSiteSelectOptions = () => {
        const filters = boundMagic.magicalGet("siteFilters", {});

        const newSitePortalSettings = [];
        let didFilter = false;
        
        _.forEach(siteData.data, (s)=>{ 
            let match = true;

            _.forEach(filters, (filterValue, filterKey)=>{
                didFilter = true;
                match = match && ((s.siteTags != undefined && s.siteTags[filterKey] == filterValue) || filterValue == "" || filterKey == "_saveFull");
            });

            if(match == true){
                newSitePortalSettings.push(s);
            }
        });

        const currentSite = boundMagic.magicalGet(siteField, null);

        if(currentSite != null){
            const currentSiteInOptions = _.filter(newSitePortalSettings, (sps)=> sps.site.id == currentSite.id).length > 0;
            if(didFilter && currentSiteInOptions == false){
                boundMagic.magicalSet(siteField, null);
            }
        }
        
        setSitePortalSettingOptions(newSitePortalSettings);
    }

    const doSave = () => {
        const changes = boundMagic.getAllDeltas();
        const siteSettingChanges = [];
        const contactListId = boundMagic.magicalGet("id");

        _.forEach(APP.registeredBoundMagics, (bMagic, key)=>{
            if(key.indexOf("contactList-sitePortalSetting-" + contactListId) > -1){
                let d = bMagic.getAllDeltas();
                
                if(d != undefined && d.length > 0){
                    siteSettingChanges.push(d[0]);                    
                }
            }
        });
        
        APP.central.CustomerContact.saveContactList(boundMagic.magicalGet("id", 0), _.union(changes, siteSettingChanges));
    }

    return <div className="edit-client-contact-list">
        <h3>Edit Client Contact List</h3>
        <BoundCommit to={boundMagic.to} commit={doSave}>
            <InputAutoComplete field={clientField} name="Client" central={APP.central.Customer.autocomplete} preFetchAll={true} onSelect={(val)=>onChangeClient(val)} store="object" disabled={boundMagic.magicalGet("id") == ""}>
                {(option) => <div className="customer_option">{option.displayValue}</div>}
            </InputAutoComplete>
            <InputSelect 
                field={siteField} 
                name="Site" 
                labelTag={_.keys(umbrellaOptions.data).length <= 0 ? null : <label style={{color: "blue"}} onClick={()=>setShowSiteFilters(!showSiteFilters)}>Advanced Filters</label>} 
                optionsCollection={_.map(sitePortalSettingOptions, (sps)=>{ 
                    return {...sps.site, ...{name: sps.siteName}};
                })}
                fieldKey="id" 
                display={"name"} 
                store="object"
                labelChildren={<Umbrellas umbrellas={umbrellaOptions.data} updateSiteSelectOptions={()=>updateSiteSelectOptions()} show={showSiteFilters}/>}
            />
            <InputSelect field={contactField} name="Contact" optionsCollection={contactOptions.data} fieldKey="id" display={contactDisplayValue} store="object" disabled={boundMagic.magicalGet("id") == ""}/>
            <InputCheckbox field="requestServiceOnAllSites" name="Request Service On All Sites" />
            <InputCheckbox field="requestServiceOnContactSites" name="Request Service On Contact Sites" />
            <SaveToolbar handleSave={doSave} />
            <VisibleSites visibleSites={sitePortalSettingOptions} contactListId={boundMagic.magicalGet("id")}/>
        </BoundCommit>
    </div>;
}

const Umbrellas = ({umbrellas = {}, updateSiteSelectOptions, show = false}) => {
    const [selectors, setSelectors] = useState([]);

    useEffect(()=>{
        const newSelectors = [];
        _.forEach(umbrellas, (values, key) => {
            newSelectors.push(<InputSelect key={key} field={"siteFilters." + key} name={key} options={values} onChange={()=>updateSiteSelectOptions()}/>);
        });

        setSelectors(newSelectors);
    }, [umbrellas]);

    return <>        
        {show? selectors : null}
    </>;
}

const VisibleSites = ({visibleSites = [], contactListId}) => {
    //const parentMagic = useContext(BoundDataContext);

    return <div className="field">
        <label>Portal Sites</label>
        {_.map(visibleSites, (s, index)=>{
            const newMagic = BoundMagic.create({}, null, null, null, {boundId: "contactList-sitePortalSetting-" + contactListId + "|" + s.id + "|" + index});
            
            if(s.id == 0){
                _.forEach(s, (v, k)=>{
                    newMagic.magicalSet(k, v);
                });
            } else {
                newMagic.replaceTo(s);
            }

            return <VisibileSiteRow key={s.id + "|" + index} magic={newMagic}/>
        })}
    </div>;
}

const VisibileSiteRow = ({key, magic}) => {
    const [disableServiceRequests, setDisableServiceRequests] = useState(false);

    useEffect(()=>{
        magic.magicalState("visibleOnPortal", (val)=>{
            if(val == false){
                setDisableServiceRequests(true);
            } else {
                setDisableServiceRequests(false);
            }
        })
    }, [magic]);

    useEffect(()=>{
        if(disableServiceRequests){
            magic.magicalSet("allowServiceRequests", false);
        }
    }, [disableServiceRequests]);

    /* if(_.keys(magic.to).length <= 0){
        return;
    } */

    return <Bound to={magic.to} key={key}>
        <div style={{display: "flex", flexDirection: "row", marginBottom: 20, gap: 40}} >
            <InputText field="siteName" readOnly={true} style={{width: 200, display: "block"}}/>
            <InputCheckbox field="visibleOnPortal" name="Portal Visible" defaultValue={true}/>
            <InputCheckbox field="allowServiceRequests" name="Allow Service Requests" defaultValue={true} disabled={disableServiceRequests}/>
        </div>
    </Bound>;
}

export default EditClientContactList;