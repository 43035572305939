import React, { Fragment, useEffect, useState } from "react";
import _ from "lodash";

export default function MapFromMarker(props) {
  //takes a MapMarker as prop, needs a mapLat and MapLong
  const marker = props.mapMarker;

  const [mapSrc, setMapSrc] = useState(null);

  const refreshMapLocation = () => {
    if (marker != null && marker.mapLong) {
      const zoom = 5;
      const zoom2 = 100000;
      const long = [(parseFloat(marker.mapLong) * (zoom2 - zoom)) / zoom2, (parseFloat(marker.mapLong) * (zoom2 + zoom)) / zoom2];
      const lat = [(parseFloat(marker.mapLat) * (zoom2 - zoom)) / zoom2, (parseFloat(marker.mapLat) * (zoom2 + zoom)) / zoom2];
      const layer = "cyclemap"; //opnvkarte
      let addr =
        "https://www.openstreetmap.org/export/embed.html?bbox=" +
        long[0] +
        "%2C" +
        lat[0] +
        "%2C" +
        long[1] +
        "%2C" +
        lat[1] +
        "&layer=" +
        layer +
        "&marker=" +
        marker.mapLat +
        "%2C" +
        marker.mapLong;
      setMapSrc(addr);
    }
  };

  useEffect(() => {
    refreshMapLocation();
  });

  return (
    <Fragment>
      <div style={{ width: "100%", height: "100%" }}>
        {mapSrc ? (
          <iframe width="100%" height="100%" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src={mapSrc}></iframe>
        ) : (
          <p>Save address from edit screen to display map</p>
        )}
      </div>
    </Fragment>
  );
}
