import React, { useState, useEffect }  from 'react';
//import { Data } from '@opidcore/Data.js';
//import { Link } from 'react-router-dom';
import { useData, useNavBar } from '@opidcore/hooks/WTF';
import Bound from '@opidcore/components/Bound';
import {InputText} from '@opidcore/components';

function Resizable(props){
	const TagName = props.tagName;
	
	const [initialWidth, setInitialWidth] = useState(0);
	const [moveListener, setMoveListener] = useState();
	const [upListener, setUpListener] = useState();
	
	const cleanup = ()=>{
		window.removeEventListener("mousemove", moveListener);
		window.removeEventListener("mouseup", upListener);
		//console.log("I cleaned up ", moveListener, upListener);
	};
	
	useEffect( ()=>{
		return ()=>{
			cleanup();
		}
	}, []);
	
	const move = (e)=>{
		//console.log("moving", e);
	}
	
	const up = (e)=>{
		//console.log("up", e);
		cleanup();
	} 
			
	const down = (e)=>{
		setInitialWidth(e.currentTarget.parentElement.offsetWidth);
		setMoveListener( window.addEventListener("mousemove", (e)=>move(e)) );
		setUpListener( window.addEventListener("mouseup", (e)=>up(e)) );
	}
	
	return <TagName {...props}> {props.children}  <span className="resize-right" onMouseDown={(e)=>down(e)}>|</span> </TagName>;
	
}

export function Grid(props){
	const [widths, setWidth] = useState([]);
	
	const styler = (f,v)=>{
		const style = {};
		
		const colNumber = props.columns.indexOf(f);
		
		if (widths[colNumber]){
			style.width = widths[colNumber] + "px";
		}else{
			style.width = "150px";
		}
		
		return style;
	}
	
	return <div  className="excelish">
		<div className="top row">
		<div className="row-num" style={styler("id",null)}>id</div>

		{props.columns.map( (f,i)=>{
			if (f != "id"){
				const title = typeof f == "object" ? f.title : f;
				return <Resizable tagName="div" className="topa" style={styler(f,null)}>{String.fromCharCode(65 + i)} {title}</Resizable>
			}
		})}
		</div>
		{props.data.map( (row)=>{ 
			return <Bound to={row} key={row.id} tagName="div" className="row">
					<div className="row-num" style={styler("id",null)}>{row.id}</div>
			{props.columns.map( (f)=>{
				if (f != "id"){
					
					const field = typeof f == "object" ? f.col : f;
					//eventually be smart and f can be an object with options?
					return <InputText style={styler(f,null)} key={row.id + "-" + f} field={field}/>
				}
			})}			
			</Bound>
		})}
	</div>;
}

export default function AdminEditTable(props){
	const tableName = props.match.params.what;
	useNavBar("Edit Table " + tableName, []);
	
	const [table, tableDs] = useData(tableName);

	const fields = _.without(_.keys( table[0] ), "_$dbName","savedinStorage","__savedInStorage","__type","__is_shallow");
	
    return <div>
    	<Grid data={table} columns={fields}>
    	
    	</Grid>
    	
    </div>;
}
