import React, { Component, PropTypes } from 'react';

export default class ToolTip extends Component {
	
	static propTypes = {
	};

	constructor(props, context) {
		super(props, context);
	
	    this.state = {};
	    
	}
	  
	render(){	  
		return (<div className="customtooltip customtooltip-active">
	        <span className="customtooltiptext customtooltiptext-bottom">
	        	{this.props.children}
	        </span>
        </div>);
		
	}
}