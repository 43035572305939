import React, { useState, useEffect, useContext }  from 'react';
import Icon from '@opidcore/components/Icon.jsx';

export default function PickOne(props){
	const [selected, setSelected] = useState(props.initialValue);
	
	let content = [];
	let buttons = [];
	
	_.each(props.children, (c)=>{
		const id = c.key
		if (id == selected){
			content = c.props.children.slice(1);
		}
		
		buttons.push( React.cloneElement(c.props.children[0] , {onClick: ()=>setSelected(id) }) );
	});
	
	return <div>
		
		<div className="options">
		{props.hideButtons && selected ? React.cloneElement(buttons[0] , {onClick: ()=>setSelected(null), children: <Icon icon="arrow-left" size="1x" color="white"/>}) : buttons }
		</div>
		
		<div className={"selection"}>
			{content}
		</div>
		
	</div>
	
	
}