import React, { useState, useEffect, useContext, useRef }  from 'react';
//import { Data } from '@opidcore/Data.js';
import { Grid, GridItem, Bound, InputMultiCheckbox, Loading, InputSelect } from '@opidcore/components';
import { DataContext } from '@opidcore/components/Bound';
import { GridContext, GridHeading } from '@opidcore/components/Grid';
import File from '../../components/File';
import _ from 'lodash';
import { Link, useParams } from 'react-router-dom';
import { ActionBar, Button } from '../../../../../OpidCore/public/js/components';
import { NiceCurrency, NiceDate } from '../Nice';
import { CurrentEditingCrazyContext } from '../Contract/EditLines';
import { useData } from '@opidcore/hooks/WTF';

const ColumnSelector = ({columns, setColumns}) =>{
    const gridContext = useContext(GridContext);
    let tempData = useRef({activeColumns: columns});

    return <div key="column-selector" className="column-selector">
        <Bound to={tempData.current} onChange={()=>setColumns(tempData.current.activeColumns)}>
            <InputMultiCheckbox field="activeColumns" optionsCollection={columns} optionKey="key">
                {(row) => <div className="tile-item">{row.label} ({row.colGroup})</div>}
            </InputMultiCheckbox>
        </Bound>
    </div>
}

export default function ARInvoice(props){
    const params = useParams();
    const queryArgs = _.fromPairs(_.map( props.location.search.replaceAll("?","").split('&'), (a)=>{ return a.split('=') }));

    const apInvoiceId = params != null ? params.id : 0;

    const [stuff, setStuff] = useState({rows: []});
    const [columns, setColumns] = useState([]);
    const [file, setFile] = useState({});
    const [visibleColumns, setVisibleColumns] = useState([]);
    const [recentPDFS, setRecentPDFS] = useState([]);
    const [gridCtx,setGridCtx] = useState(null);

    const [loaded, setLoaded] = useState(true);

    const [createdThings, setCreatedThings] = useState([]);
    
    useEffect( ()=>{
        if (apInvoiceId > 0){

            const _params = {};
            _params.status = queryArgs.status || "*";
            APP.central.Snowman.findLines(apInvoiceId, false, _params.status).then( (r)=>{
                setStuff( {rows: r.result.invoice.rows });
                setColumns(r.result.invoice.columns);
                setLoaded(true);
            });
        }

        APP.central.Snowman.recentFiles().then((r)=>{
            setRecentPDFS(r.result);
        });

    }, [apInvoiceId]);

    const uploaded = (file)=>{
        setLoaded(false);
        
        if ( file.filename.toLowerCase().indexOf("\.pdf") >= 0 ){
            useExistingFile(file.storageIdentifier);
        }else{
            APP.central.Snowman.importVendorInvoice(file).then((r)=>{
                setStuff( {rows: r.result.invoice.rows });
                setColumns(r.result.invoice.columns);
                setLoaded(true);
            });
        }
    };

    const create  = (file)=>{
        setLoaded(false);
        setCreatedThings(<h2>Processing</h2>);
        if (apInvoiceId > 0){
            APP.central.Snowman.findLines(apInvoiceId, true, "unmatched").then( (r)=>{
                setLoaded(true);
                setStuff( {rows: r.result.invoice.rows });
                setCreatedThings(<h2>Done!</h2>);
            });
        }else{
            APP.central.Snowman.importVendorInvoiceStep2().then( (r)=>{
                setLoaded(true);
                setStuff( {rows: r.result.invoice.rows });
                setCreatedThings(<h2>Done!</h2>);
            });
        }

        

/**
        APP.central.Snowman.createInvoices().then((r)=>{
            const rows = _.map( r.result, (thing)=>{
                const chunks = thing.split('|');
                return <li><Link to={chunks[1]}>{chunks[0]}</Link></li>;
            });
            setCreatedThings(<ul>{rows}</ul>);
            setLoaded(true);
        });  */
    };

    // <GridItemGroup title="Invoiced Details">
    

    const colGroups = {};

    const groupNames = _.compact( _.uniq( _.map( columns, "colGroup" ) ) );
    
    _.each( groupNames, (groupName)=>{
            const colDefs = _.sortBy( _.filter(columns, (c)=>c.colGroup == groupName), "index" );
            const cols = [];
            const showAll = _.size(visibleColumns) == 0;

            _.each(colDefs, (colDef)=>{
                    const showCol = showAll || _.find( visibleColumns, {key: colDef.key}) != null;

                    if (showCol){
                        cols.push(<GridItem title={colDef.key + " - " + groupName} key={colDef.key}>
                            <SimpleValue field={colDef.key}/>
                        </GridItem>);
                    }
            });
            colGroups[groupName] = cols;
        });

        const updateVisibleCols = (cols)=>{ 
                setVisibleColumns(cols);
                gridCtx.reset();
            };

            const vendorInvoiceColDefs = _.filter( visibleColumns, {colGroup: null});

            const vendorCols = _.map( vendorInvoiceColDefs, (colDef)=>{
                return <GridItem title={colDef.key} key={colDef.key}>
                            <SimpleValue field={colDef.key} format={colDef.type}/>
                        </GridItem>
            });

    //        <ColumnSelector columns={columns} setColumns={updateVisibleCols}/>

    		// Service #		Service Type	
         //   Container Size	 Start Date	Activity End Date			Reference	
          //  Ticket #	Activity Amount

    const createActivities = ()=>{
        APP.instance.createModal("ui/services/editor/0", {modal_name: "Create Activities"});
    };

    const useExistingFile = (md5)=>{
        APP.central.File.findByStorageIdentifier( md5 ).then( (r)=>{
            const newFile = r.result;
            setFile(newFile);
            APP.central.Import.processThePDF( newFile ).then( (s)=>{
                const uid = s.result.uid;

                APP.central.Snowman.importVendorInvoiceFromWhatever(uid).then((r)=>{
                    setStuff( {rows: r.result.invoice.rows });
                    setColumns(r.result.invoice.columns);
                    setLoaded(true);
                });
            });

        });
        
    };

    const openThis = (a,b,md5)=>{
        if (md5 > ""){
        useExistingFile(md5);
        }else{
            setStuff( {rows: [] });
            setFile({});
        }
    }
    
    return <div className={"tiny-table"}>
        <h2>Invoice Activity Interpretation</h2>
        <Bound to={stuff}>

            <ActionBar>
                <File onUpload={uploaded} /*storeIn="db"*/ label="Drop Invoice Here"/>

                <InputSelect onChange={openThis} optionsCollection={recentPDFS} fieldKey="storage_identifier" labelKey="filename"/>
                
                <Button onClick={create}>Process Vendor Invoice Lines</Button>
                <Button onClick={createActivities}>Create Service Activities</Button>
                <Button onClick={()=>APP.redirect("/ui/apinvoice/status_list")}>Billing Status</Button>
            </ActionBar>
            {file.filename ? <h3><Link to={"/ui/snowman/pdf/" + file.storageIdentifier} target="_blank">{file.filename}</Link></h3> : null}
            {createdThings}
            {loaded == false ?  <Loading /> : <Grid boundData="rows" registerGrid={setGridCtx}>  
            <GridHeading start={0} span={13}>
                    <h4>Vendor Invoice Lines</h4>
            </GridHeading>
            <GridHeading start={14} span={5}>
                    <h4>Client Information</h4>
            </GridHeading>
            <GridHeading start={19} span={10}>
                    <h4>Service Information</h4>
            </GridHeading>
                <GridItem title="Account #">
                        <SimpleValue field="Account #"/>
                </GridItem> 
                <GridItem title="Site #">
                        <SimpleValue field="Site #"/>
                </GridItem> 
                <GridItem title="Site Name">
                        <SimpleValue field="Site Name"/>
                </GridItem> 
                <GridItem title="Site City">
                        <SimpleValue field="Site City"/>
                </GridItem> 
                <GridItem title="Site Prov.">
                        <SimpleValue field="Site Prov."/>
                </GridItem>
                <GridItem title="Service #">
                        <SimpleValue field="Service #"/>
                </GridItem>
                <GridItem title="Service Quantity">
                        <SimpleValue field="Service Quantity"/>
                </GridItem>
                <GridItem title="Container Size">
                        <SimpleValue field="Container Size"/>
                </GridItem>
                <GridItem title="Activity Quantity">
                        <SimpleValue field="Activity Quantity"/>
                </GridItem>
                <GridItem title="Vendor Activity">
                     <SimpleValue field="Activity Description"/>
                </GridItem>
                <GridItem title="Activity Amount">
                        <SimpleValue field="Activity Amount" format="decimal"/>
                </GridItem>
                    <GridItem title="Unit">
                        <SimpleValue field="unitPricePer"/>
                    </GridItem>  
                <GridItem title="Vendor Activity Reference">
                     <SimpleValue field="Activity Reference"/>
                </GridItem>
                <Grid boundData="vendorContractLineMatch">
                    <GridItem title="Service Match #">
                        <ServiceMatch/>
                    </GridItem>
                </Grid>
                <Grid boundData="activityMatchItem">
                    <GridItem title="Activity Match #">
                        <ActivityMatch/>
                    </GridItem>
                </Grid>
                <Grid boundData="Client Information">
                    {colGroups["Client Information"]}
                </Grid>
                <Grid boundData="Service Information">
                    {colGroups["Service Information"]}
                </Grid>
                <Grid boundData="Quantities"  gridIdentifier="q">
                    {colGroups["Quantities"]}
                </Grid>
                <Grid boundData="Vendor Contract Expectations" gridIdentifier="vc">
                    {colGroups["Vendor Contract Expectations"]}
                </Grid>
                <Grid boundData="Client Billing"  gridIdentifier="cb">
                    {colGroups["Client Billing"]}
                </Grid>
                <Grid boundData="Client Baseline"  gridIdentifier="base">
                    {colGroups["Client Baseline"]}
                </Grid>
            </Grid> 
            }
        </Bound>
    </div>
}

export function SimpleValue({field, format=null}){
    const magic = useContext(DataContext);
    let value = null;
    try{
        value = magic.magicalGet(field);

        if (typeof value == "object" ){
            //debugger;
        }
    }catch(e){
        //debugger;
    }
    if (format == "decimal"){
       const numFormat = new Intl.NumberFormat('en-ca', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 4 });
       value = numFormat.format(value);
    }
    if (format == "date"){
        value = <NiceDate>{value}</NiceDate>
    }
    if (format == "currency"){
        value = <NiceCurrency>{value}</NiceCurrency>
    }
    return <span>{value}</span>;
}

export function ClientMatch({show}){
    const magic = useContext(DataContext);
    let value = magic.magicalGet("id");
    let site = magic.magicalGet("site");

    if (site){
        let value = site.friendlyId.replace(/-[0-9]$/,'');
       
        return <>{value}</>;
    }
    
    return "-";
}

export function SiteMatch({show}){
    const magic = useContext(DataContext);
    let value = magic.magicalGet("id");
    let site = magic.magicalGet("site");

    if (site){
        let value = site.friendlyId;
        if (show == 'small'){
            value = _.last(value.split('-'))
        }
        return <>{value}</>;
    }
    
    return <>{"?"}</>;
}

export function ServiceMatch({show, handleRefresh, file}){
    const magic = useContext(DataContext);
    let value = magic.magicalGet("id");
    let service = magic.magicalGet("service", null);

    const afterClose = (modal)=>{
        handleRefresh && handleRefresh();
    }

    const editService = ()=>{ 
        APP.instance.createModal("ui/services/edit/" + service.id, {modal_name: "Edit Service"}, {afterClose: (modal)=>afterClose(modal)});
    };

    if (value > 0 && service != null){
        let serviceId = service.friendlyId;
        if (show == 'small'){
            serviceId = _.last(serviceId.split('-'));
        }
        return <Link to="#" onClick={editService}>{serviceId}</Link>;
    }

    const addService = ()=>{ 
        const m = magic;
        const defaults = {};
        defaults.vendorAccountReference = m.parentMagic.magicalGet("Account #");
        defaults.vendorSiteReference = m.parentMagic.magicalGet("Site #");
        defaults.vendorServiceReference = m.parentMagic.magicalGet("Service #");
        
        //find the first vendor record to match
        const vendor = _.first(_.map(m.parentMagic.parentMagic.to.rows, 'vendor'));
        defaults.vendor = vendor;
        defaults.openSearch = true;
        defaults.referenceElement = <ReferenceThing magic={magic.getParentMagic()} file={file}/>;
       

        APP.instance.createModal("ui/services/editor/0", {modal_name: "Create Activities", ...defaults}, {afterClose: (modal)=>afterClose(modal)});
    };

    return <a href="#" onClick={addService}>add</a>;
}

export const ReferenceThing = ({ magic, file }) => {
  const context = useContext(CurrentEditingCrazyContext);
  const [showButton, setShowButton] = useState(false);
  const [vendorActivitiesData, vendorActivitiesDataSet] = useData("vendor_activity;ar-invoice");
  const vendorActivitiy = useRef([]);
  const lineItemBoundRef = useRef(null);
  const gridRef = useRef(null);

    const viewPDF = ()=>{
        APP.instance.createModal("ui/snowman/pdf/" + file.storageIdentifier, {modal_name: "PDF: " + file.filename});
    }

  const fillFromRef = () => {
    doCheckForValidRow();
    if (lineItemBoundRef.current) {
      lineItemBoundRef.current._ourMagic.magicalSet("unitPrice", magic.magicalGet("Activity Amount"));
      if (vendorActivitiy.current[0]) {
        lineItemBoundRef.current._ourMagic.magicalSet("vendorActivity", vendorActivitiy.current[0]);
        lineItemBoundRef.current._ourMagic.magicalSet("unitPricePer", vendorActivitiy.current[0].defaultUnits);
      }
    } else {
      APP.alert("No Valid Row Found, this shouldn't have happened, tell Cesar probably.");
    }
  };

  const checkForGrid = () => {
    const grid = context.current[Object.keys(context.current)[0]];
    const activitesData = vendorActivitiesDataSet.filter();
    if (activitesData.length > 0 && grid) {
      gridRef.current = grid;
      grid.sortedDataBoundsMagics[0].on("*", () => doCheckForValidRow());
      doCheckForValidRow();
    } else {
      clearTimeout(window.theTimeout);
      window.theTimeout = setTimeout(() => {
        setShowButton(false);
        checkForGrid();
      }, 500);
    }
  };

  const doCheckForValidRow = () => {
    const grid = context.current[Object.keys(context.current)[0]];
    findVendorActivity();
    let sawLines = 0;
    _.forEach(grid.sortedDataBoundsMagics[0].to.vendor_contract_line_items, (tempLineItemBound, i) => {
      if(tempLineItemBound.vendorActivity == null || _.isEqual(tempLineItemBound.vendorActivity.activity, vendorActivitiy.current[0].activity)) {
        setShowButton(true);
        clearTimeout(window.theTimeout);
        //lineItemBoundRef.current = tempLineItemBound; this one doesnt have an _ourMagic :/
        lineItemBoundRef.current = grid.sortedDataBoundsMagics[0].getBound("vendor_contract_line_items", i);
        return false;
      }
      sawLines++;
    })
    if(lineItemBoundRef.current == null && sawLines > 0) {
      setShowButton(false);
      checkForGrid();
    }
  }

  const findVendorActivity = () => {
    const activitesData = vendorActivitiesDataSet.filter();

    const newActivity = vendorActivitiesDataSet.filter(
      (r) => r.vendor && r.vendor.id == magic.magicalGet("vendor_id") && r.activity && r.activity == magic.magicalGet("Activity Description")
    );
    if (_.isEqual(newActivity, vendorActivitiy.current) == false) {
      vendorActivitiy.current = newActivity;
    }
  };

  useEffect(() => {
    checkForGrid();
  }, [])

  //change to table
  return (
    <div>
      <table>
        <tr>
          <th>Location</th>
          <th>Activity</th>
          <th>Site</th>
          <th>Service</th>
          <th>Description</th>
          <th>Container Size</th>
          <th>Activity Amount</th>
          {showButton ? <th> </th> : null}
        </tr>
        <tr>
          <td>
            {magic.magicalGet("Vendor Site Name")}, {magic.magicalGet("Vendor Site Address")}, {magic.magicalGet("Vendor Site City")},{" "}
            {magic.magicalGet("Vendor Site Province")}
          </td>
          <td>{magic.magicalGet("Activity Description")}</td>
          <td>{magic.magicalGet("Site #")}</td>
          <td>{magic.magicalGet("Service #")}</td>
          <td>{magic.magicalGet("Service Description")}</td>
          <td>{magic.magicalGet("Service Size")}</td>
          <td>{magic.magicalGet("Activity Amount")}</td>
          <td>
            {showButton ? <Button onClick={() => fillFromRef()}>Fill From Reference</Button> : null}
          </td>
          <td>
              { file && file.storageIdentifier ? <Button onClick={viewPDF}>View PDF</Button> : null }
          </td>
        </tr>
      </table>
    </div>
  );
};

export function ActivityMatch(props){
    const magic = useContext(DataContext);
    const [value, setValue] = useState(0);

    useEffect( ()=>{
        //this is checking against a lineItem (that hasn't been inserted yet)
        magic.magicalState("service", setValue);
    }, [magic]);

    const editService = ()=>{ 
        let vcl = magic.getParentMagic().to.vendorContractLineMatch;

        if (vcl == null){
            APP.alert("Please match site and service first");    
        }else{
            let service = vcl.service;
            const referenceTo = magic.getParentMagic();            
            APP.instance.createModal("ui/services/editor/" + service.id, {modal_name: "Edit Activities", referenceElement: <ReferenceThing file={props.file} magic={referenceTo}/>});
        }
        
    };

    if (value > 0){
        let thing = '✓';
        return <span onClick={editService} className="nice-green">{thing}</span>;
    }

    return <a href="#" onClick={editService} className="nice-red">X</a>;

    if (props.format == "decimal"){
       const numFormat = new Intl.NumberFormat('en-ca', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 4 });
       value = numFormat.format(value);
    }
}