import React, { Component } from 'react';
//import ReactDOM from 'react-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as CentralActions from '../../OpidCore/public/js/actions/CentralActions';

//import { InputText, InputSelect, DatePicker, Bound, GrowableList } from '../../OpidCore/public/js/components';

import BasicView from './Form/BasicView.jsx';
import RenderMeSoftly from '../../OpidCore/public/js/components/Content/RenderMeSoftly.jsx';
import SaveToolbar from './components/SaveToolbar.jsx';

import * as _ from 'lodash';
import moment from 'moment';
import { APP } from '.';

class Overview extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
                loginError: undefined,
                formReference:[],
                sectionShow: null
        };
        
        this.extraUpdates = {};
    }
    
    componentDidUpdate(prevProps){
        var newId = this.props.id || (this.props.match ? this.props.match.params.id : 0); 

   		if (this.viewID != newId && newId > 0){
   			this.getData(newId);
   		}
   	
    }
    newRecord(form){
        this.props.central.Stuff.createNewInstance(form).then((r)=>{
            this.getData(r.result.id);
        });
    }
    getFormId(uid){
        this.props.central.Stuff.fetchForm(uid).then((r)=>{
            this.newRecord(r.result.id);
        });
  
    }
    
    getData(theId){
        var ref = null;
    	console.log("so you want to edit " + theId);
    	if (theId == 0 || isNaN( parseInt(theId) ) ){
    	    this.setState({sectionShow: "ALL"});

    	    if(this.props.form){
    	        this.getFormId(this.props.form);
    	    } else if(this.props.viewDefinition && this.props.viewDefinition.formUid){
    	        this.getFormId(this.props.viewDefinition.formUid);
    	    } else {
    	        console.log("Well this isn't good. We don't have a form!");
    	    }    	    
    	}
    	
    	if (theId.indexOf && theId.indexOf(".") > 0){
    		var subId = theId.split(".")[1];
    		if(this.props.NDT.announcements[subId] != undefined){
    		    theId = this.props.NDT.announcements[subId];
    		} else {
    		    theId = parseInt(theId.split(".")[0]);
    		}
    		
    		console.log("and the sub id");
    	}
    	
    	this.viewID = theId;
    	var rms_children = [];
    	var structure = [];
		if (APP.fakeRoots == null){
			APP.fakeRoots = 1;
		}
		
    	const fakeRoot = this.state.fakeRoot ?  this.state.fakeRoot : 'fake-root-' + (APP.fakeRoots++);
		this.setState({fakeRoot: fakeRoot});
    	var rootStructure = {
            children: [],
            cid: fakeRoot,
            component: "Container",
            connectors: null,
            next: null,
            options: {},
            returns: null,
            theId: fakeRoot,
            type: "ReactThing",
            uid: fakeRoot
        };
        structure.push(rootStructure);
    	if(this.props.viewDefinition && this.props.viewDefinition.rmsChild > ""){
    	    rms_children = this.props.viewDefinition.rmsChild ? this.props.viewDefinition.rmsChild : "";
    	    const rms_children_array = rms_children.split(",").filter(function(el){ return el.trim().length > 0});
    	    _.each(rms_children_array,(r)=>{
    	        rootStructure.children.push( r.replace(/[" '\[\]]/gi,""));
    	        
    	        this.props.central.Stuff.everythingIWant(r.replace(/[" '\[\]]/gi, "")).then((s)=>{
    	        		
    	        		const structure = _.concat(this.state.rmsStructure||[rootStructure],s.result.structure); 
    	                this.setState({rmsStructure:structure});    	                
    	                
    	        });
    	    })
    	    
    	}
    	
    	
   	

    	// this.props.viewDefinition.rms_children ["asdf","ddd"]
    	
       return fetch("/api/v1/Stuff/testData?instanceId=" + theId).then((r)=>r.json()).then((j)=>{ this.setState({data: j.result}); return j;  });

    } 
    
    componentDidMount(){
        this.props.central.Stuff.listViews();
        this.props.central.Stuff.listForms();

        var role = this.props.login && this.props.login.result ? this.props.login.result.role : "not logged in";
        
        if (this.props.login){
            if (role == "admin"){
                this.setState({allowEdit:true});
            }
        }
        
        this.load();       
        window.APP.reacts.push( this );
    }
    
    load(){
    	this.viewID = this.props.id || (this.props.match ? this.props.match.params.id : 0)
        return this.getData(this.viewID);
    }
    
    thisHappened(a){

    }
    
    login(){
        //this.props.central.TimedTokens.loginWithJson(this.state);
    }
    
    handleSomethingHappened(){
    	var newState = {...this.state};
    	newState.data.production_line = "Buzzah";
    	
    	this.setState(newState);
    	
    }
    

    
    handleSave(callback){
    	if (this.__handleSave == null){
    		this.__handleSave = _.debounce( (c) => this._handleSave(c), 500 )
    	}
    	    	
    	return this.__handleSave(callback);
    }
    
    _handleSave(callback){
	
    	var stuff = {...this.state};
    	
    	_.each(this.extraUpdates, (v,k)=>{
    		console.log("Calling extra update " + k);
    		v();
    	});
    	
    	stuff.data = _.omit(stuff.data, _.map(_.keys(stuff.data), k=>k+"_rms")); //remove any _rms elements because they aren't form fields
    	
    	return this.props.central.Stuff.saveTestData(stuff).then((r)=>{
    	    console.log(r);
    		callback && callback();
    		
    		if (this.props.afterSave){
				const ret = this.props.afterSave(r, this);
				console.log("our afterSave says", ret);
			}
    		
    		if (this.props.location.search.indexOf("afterSave=back") >= 0){
    			window.history.back();
    		}
    		
    		if(this.props.match.params.id <= 0){
    		    APP.setHistory(this, this.props.match.path.replace(":id", r.result.id));
    		}
    		
    		console.log("Save was successful, reloading model");
    		this.load();
    	});
    }
    
    myUpdater(action, arg1, arg2){

    	if (action == "SAVE"){
    		return this.handleSave();
    	}
    	
    	if (action == "SET"){
    		var newState = {};
    		newState.data = {...this.state.data};
    		newState.data[arg1] = arg2;
    		
    		this.setState(newState);
    	}
    	
    	if (action == "ADD_ROW"){
    		var section = arg1;
    		
    	
    		var newState = {};
    		newState.data = {...this.state.data}
    		
    		if(newState.data[section] == undefined){
    		    newState.data[section] = [];
    		}
    		
    		var maxKey = _.max( _.map( newState.data[section], '_key' ) );
    		var nextKey = maxKey != undefined ? maxKey + 1 : 1;
    		var newRow = {_key: nextKey, ...arg2};

			var fields = _.filter(this.form.fields, (f)=>f.section == section)
			_.each(fields, (f)=>{
				if (f.fieldOptions && f.fieldOptions.default){
					if (f.fieldOptions.default == "now"){
						var now = moment().format("YYYY-MM-DD HH:mm:ss");
						newRow[f.fieldUid] = now;
					}
					
					if (f.fieldOptions.default == "initials"){
						newRow[f.fieldUid] = window.USER.initials;
					}
					
					if (f.fieldOptions.default == "fullname"){
						newRow[f.fieldUid] = window.USER.fullname;
					}
				}
			});
			
    		newState.data[section].push(newRow);
    		
    		this.setState(newState);
    			
    	}
    	
    	//remove a row in a one-to-many
    	if (action == "DELETE_ROW"){
    		var section = arg1;
    		var deletedRow = arg2;
    		
    		var newState = {};
    		newState.data = {...this.state.data};
    		
    		var _key = 1;
    		const rows = [];
    		_.each( newState.data[section], (origRow)=>{
    			const row = _.cloneDeep(origRow);
    			
    			if (row._key != deletedRow._key){
    			    row._key = _key++;
        			rows.push({...row});
    			}
    		});
    		
    		newState.data[section] = rows;
    		newState = _.cloneDeep(newState);
    		this.setState(newState);

    		
     	}
    	
    	//This comes from InputRMS only
    	if (action == "RMS"){
    		if (typeof arg2 == "function"){
    			this.extraUpdates[arg1] = arg2;
    		}
    	}
    	
    }

	static getEditableProps(currentThing) {
		
		var niceForms = _.map(window.APP.forms, function(datum) {return datum.uid});
		var dataSets = [];
		var sections = [];
		
		if (window.APP.NDT.datasets instanceof Object) {
			dataSets = Object.keys(window.APP.NDT.datasets);
		}
		
		sections = _.map(_.find(window.APP.forms, {uid: currentThing.options.form}).sections, "uid");
		
		return [
            {niceName: "Form", prop: "form", type: "text", options: niceForms},
			{niceName: "ID", prop: "id", type: "text", allowJS: true},
			{niceName: "Dataset", prop: "dataset", options: dataSets, },
			{niceName: "Form Style", prop: "viewType", options: ["normal", "compact"], },
			{niceName: "Section", prop: "section", type: "text", options: sections},
			{niceName: "Hide Title", prop: 'hideTitle', type: "text", options:["true", "false"]} 
        ];
	}
    
    render(){      
        
        if (this.props.formDefinitions.length > 0){
        	var formUid = "";
        	var viewMode = "";
        	var sectionShow = this.state.sectionShow == "ALL" ? null : this.props.section;
        	if(this.props.viewMode){
        	    viewMode = this.props.viewMode;
        	    APP.alert("how did I get here??");
        	    console.log(this);
        	}
        	
        	if(this.props.viewType){
        	    viewMode = this.props.viewType;
        	}
        	
        	if (this.props.match){
        		formUid = this.props.viewDefinition.formUid;
        		viewMode = this.props.view;
        	}else{
        		formUid = this.props.form;
        	}
        	
        	var resources = null;
        	
        	let customStyle = {};
        	
        	if ( this.state.rmsStructure != null){
        		let rmsProps = {
                        central: "blah",
                        key: '888',
                        root: this.state.fakeRoot,
                        structure: this.state.rmsStructure,
                        mode: "view", 
						iAmGod: true
                    };
        		resources = <RenderMeSoftly {...rmsProps} />
        	}        
        	
        	if(this.props.viewDefinition && this.props.viewDefinition.options){
        	    if(this.props.viewDefinition.options.rms_child_position){
        	        const position = this.props.viewDefinition.options.rms_child_position;
        	        
        	        if(typeof position == "number" || !isNaN(position)){
        	            customStyle.order = parseInt(position);
        	        } else if(typeof position == "string"){
        	            if(position == "first"){
        	                customStyle.order = -1;
        	            } else if(position == "last"){
        	                customStyle.order = 2;
        	            }
        	        }
        	    }
        	}

            this.form = _.find(this.props.formDefinitions, {uid: formUid});
            
			let custom = null;

			if (window._CUSTOM_FORM_ELEMENTS && window._CUSTOM_FORM_ELEMENTS[formUid]){
				const CustomElement = window._CUSTOM_FORM_ELEMENTS[formUid];
				custom = <CustomElement {...this.props}  formDefinition={this.form} data={this.state.data} />
			}

			
            
            return  <div className={"overview " + (sectionShow ? "" : "acontainer")}>
				{custom}
                <div className="resources" style={customStyle}>{resources}</div>
            	<div className="basicView">
                    <BasicView showSection={sectionShow} hideTitle={this.props.hideTitle} projectMode= "waste" viewType = {viewMode} formDefinition={this.form} myUpdater={(action, arg1, arg2)=>this.myUpdater(action, arg1, arg2)} data={this.state.data} central={this.props.central} allowEdit={false} didAllowEdit={true || this.state.allowEdit} />
                </div>
            	<SaveToolbar handleSave={(c)=>this.handleSave(c)} handleCancel={()=>this.load()}/>
           </div>
            
        }else{
        
        return <div id="login-body" className="text-center">
            <div className="container">
                <div className="plans">                
                </div>
            </div>
        </div>
        
        }
    }
}

function mapStateToProps(state) {
    return {
        viewDefinitions: state.central.viewDefinitions || {},
        formDefinitions: state.central.formDefinitions || {},
        login: state.central.login
    };
}

function mapDispatchToProps(dispatch) {
    return {
        ...CentralActions.modifyProps(dispatch),  
        actions: bindActionCreators({...CentralActions}, dispatch),    
    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Overview);