import React  from 'react';
import EditAPInvoice from './EditAPInvoice.jsx';
import APInvoiceList from './APInvoiceList.jsx';
import APStatusList from './APStatusList';
import APTaskList from './APTaskList';
import ShortPayExport from './ShortPayExport.jsx'
import APOverview from './APOverview.jsx';


// import { Link } from "react-router-dom";
// import Icon from '@opidcore/components/Icon';
import { MenuItem, SubMenu } from '@opidcore/components/Menu.jsx';

export default function initialize(permissions, options){
	if(permissions.w || true){
		window.APP.customRoutes.push({path: "/ui/apinvoice/list", component: APInvoiceList});
		window.APP.customRoutes.push({path: "/ui/apinvoice/status_list", component: APStatusList});
		window.APP.customRoutes.push({path: "/ui/apinvoice/tasks", component: APTaskList});
		window.APP.customRoutes.push({path: "/ui/apinvoice/:id/:sites", component: EditAPInvoice});		
		window.APP.customRoutes.push({path: "/ui/apinvoice/:id", component: EditAPInvoice});
		window.APP.customRoutes.push({path: "/ui/shortExport", component: ShortPayExport});
		window.APP.customRoutes.push({path: "/ui/apoverview", component: APOverview})
	}
			
	APP.addMenu( ()=>{
		const items = [];
		//items.push(<MenuItem key="ap" menuKey="ap" linkTo="/ui/apinvoice/list" icon="file-invoice-dollar" text="Payables"/>);			

    items.push(<SubMenu key="ap">
            <MenuItem key="ap" menuKey="ap" linkTo="/ui/apinvoice/list" icon="file-invoice-dollar" text="Payables"/>
            <MenuItem key="shortExport" menuKey="shortExport" className="nav-sub-menu" linkTo="/ui/shortExport" icon="file-excel" text="Short Pay Export" />
			<MenuItem key="tasks" menuKey="tasks" className="nav-sub-menu" linkTo="/ui/apinvoice/tasks" icon="file-pdf" text="Tasks" />
        </SubMenu>);

		return items;
	});
		
}