import React, { Component } from 'react';
//import { Link, browserHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as CentralActions from '../../../../OpidCore/public/js/actions/CentralActions';

//import * as InputTypes from '../../components/InputText';
//import { InputText } from '../../../../OpidCore/public/js/components';

import * as _ from 'lodash';

class StaffSummaryView extends Component {
	
    componentWillMount(){
        
    }
    
    handleClick(){
        if(this.props.match.params != undefined && this.props.match.params.id != undefined){
            hashHistory.push("/staff/edit/"+this.props.match.params.id);
        }
    }

    render () {
        const staff = this.props.staff
        console.log("props: ", this.props);
        
        if (staff == null && this.props.match.params.id != 0){
            this.props.central.User.list();   
            return (<div>loading...</div>);
        }
     
        var updateAction = this.props.central.updateModel;
     
        return (        
                <div className='container text-center'>
                    <h1>{staff.firstName} {staff.lastName}</h1>
                    <hr />  
                    <h4>{staff.email}</h4>
                    <div>
                        <a className="btn btn-success" onClick={ () => {this.handleClick()} }>Edit</a>
                    </div>
                    
                </div>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        staff: _.find(state.central.users, {id: parseInt(props.match.params.id)}),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        ...CentralActions.modifyProps(dispatch),  
        actions: bindActionCreators({...CentralActions}, dispatch),  
    };
}



export default connect(
        mapStateToProps,
        mapDispatchToProps
)(StaffSummaryView);