import React  from 'react';
import { useData } from "@opidcore/hooks/WTF";
import { TabularDataSet, TabularColumn, PageHeading} from "@opidcore/components";
import EditTax from './EditTax';
import { NicePercent } from '../../Nice.jsx';
import { APP } from '../../../../../js';

const ListTax = ({}) =>{
    const [tax, taxDataSet] = useData("taxes");

    const refreshTaxes = () => {
        taxDataSet.fetchRequired = true;
        taxDataSet.fetch();
    }

    const editTax = (row, e) => {
        e.stopPropagation();

        APP.instance.createModal( <EditTax taxModel={row}/>, {modal_name: "Tax: Edit " + (row == null? "New" : (row.name || row.id))}, {afterClose: refreshTaxes});

        return false;
    }

    const createTax = (e) =>{
        editTax(undefined, e);
    }

    return <div key="list-tax" className="list-tax">
        <PageHeading>Taxes</PageHeading>

        <div className="add-tax action_button" onClick={createTax}>+</div>

        <TabularDataSet dataSet={taxDataSet} onClick={editTax} defaultSort="id" defaultSortOrder="asc">
            <TabularColumn title="Id" data={(row) => row.id} />
            <TabularColumn title="Name" data={(row) => row.name} />
            <TabularColumn title="Code" data={(row) => row.code} />
            <TabularColumn title="Rate" data={(row) => <NicePercent places={3}>{row.rate}</NicePercent>} />
            <TabularColumn title="Xero AR" data={(row) => row.xeroCodeAR} />
            <TabularColumn title="Xero AP" data={(row) => row.xeroCodeAP} />
        </TabularDataSet>
    </div>
}

export default ListTax;