import React, { useEffect, useRef, useState } from 'react';
import { useData, useNavBar } from '@opidcore/hooks/WTF';
import {  TabularColumn, Selection, Bound, Button,ActionBar, MagicalState, useMagic, LinkButton } from "@opidcore/components";
//import { NiceCurrency, NicePercent } from '../../UI/Nice';
import moment from 'moment';
import _ from 'lodash';
import { Tabular } from '@opidcore/components';
import PeriodSelect from '../../components/PeriodSelect';

const AutoPay = ({}) => {    
    const nav = useNavBar("Subscriptions");
    const [billingPeriod, setBillingPeriod] = useState(null);
    const defaultPeriod = moment().format('YYYY-MM');

    const [data, dataSet] = useData("invoices", {method: "generateRioCan", billingPeriod: billingPeriod || defaultPeriod });
    const [byCustomer, setByCustomer] = useState([]);
    const bound = useMagic({billingPeriod: defaultPeriod});
	const [selection, setSelection] = useState([]);

    useEffect( ()=>{
        APP.setState("currentMenu", ["tools", "auto_pay_menu_key"]);    
    }, []);

    const selectAllForCustomer = (id)=>{
        console.log(selection)
        setSelection( _.filter(data, (d)=>d.customer.id == id && d.currentPeriodExtendedPrice == 0));
    };

    useEffect( ()=>{
        //[selection, data];
        
        const grouped = _.groupBy(data, (r)=>r.customer.id)
        const summaryData = _.map(_.values(grouped), (group)=>{
            return {
                customer: group[0].customer, 
                customerId: group[0].customer.id,
                totalItems: group.length,
                totalUninvoiced: _.sum(_.map( group, (d)=>d.currentPeriodExtendedPrice == 0 ? d.itemAfter.extendedPrice : 0)),
                totalInvoiced: _.sum(_.map( group, (d)=>d.currentPeriodExtendedPrice)),
            }
        });

        if (_.isEqual(summaryData,byCustomer) == false){
            setByCustomer( summaryData );
        }
    }, [data]);

    useEffect( ()=>{
        
    }, [selection]);
    
    const doGenerate = ()=>{
        const ids = _.uniq(selection.map( (s)=>s.source ));
        setSelection([])
        const toCreate = ids.join(",");

        APP.showLoading({message: `Generating Subscription Invoice${ids.length == 1 ? '' : 's'}`, children: `Generating ${ids.length} invoice${ids.length == 1 ? '' : 's'}.  These will process in the background.  You can minimize this dialog and view the invoices from the customer screens.` }, ()=>{
            APP.central.Invoices.generateRioCan(billingPeriod, true, toCreate ).then( (r)=>{
                dataSet.fetch();
            })
        });
        
    }

    return <div>


            <ActionBar title="Actions">

				<Bound to={bound}>
					<PeriodSelect field="period" />
                    <MagicalState to="period" setter={setBillingPeriod} />
				</Bound>
                {selection.length > 0 ? <Button onClick={()=>doGenerate()}>Create and Post Subscription ARs</Button> : <Button style={{backgroundColor: "#FF0000"}}onClick={() => {alert("No sites selected.")}}>No Sites Selected</Button>}
				
			</ActionBar>

        <h4>Summary</h4>
        <Tabular data={byCustomer}>
            <TabularColumn data={(row)=><LinkButton target={"/ui/clients/" + row.customer.friendlyId} title={"Client " + row.customer.friendlyId} modalName={"Client " + row.customer.friendlyId} className="plainLink">
					{row.customer.name}
              	</LinkButton>} title="Customer"/>
            <TabularColumn data={(row)=>row.totalItems} title="Items"/>
            <TabularColumn data={(row)=>row.totalInvoiced} title="Invoiced"/>
            <TabularColumn data={(row)=>row.totalUninvoiced} title="Uninvoiced"/>
            <TabularColumn data={(row)=><> <Button onClick={()=>selectAllForCustomer(row.customerId)}>Select</Button> </>} title="Actions"/>
        </Tabular>


        <h4>Details</h4>
        <Tabular data={data} defaultSort={(row)=>row.itemAfter.createdFrom}>
            <TabularColumn data={(row)=><><Selection selection={selection} setter={setSelection} item={row} label={(row)=>row.itemAfter.createdFrom} /> {row.itemAfter.createdFrom}</>} title="ID"></TabularColumn>
            <TabularColumn data={(row)=><LinkButton target={"/ui/clients/" + row.customer.friendlyId} title={"Client " + row.customer.friendlyId} modalName={"Client " + row.customer.friendlyId} className="plainLink">
					{row.customer.name}
              	</LinkButton>} title="Customer"/>
            <TabularColumn data={(row)=>row.site.name} title="Site"/>
            <TabularColumn data={(row)=>row.subscriptionPeriod} title="Subscription"/>
            <TabularColumn data={(row)=>row.subscriptionApplyTo} title="Apply To"/>

            <TabularColumn data={(row)=>row.itemAfter.extendedPrice} title="Price"/>
            <TabularColumn data={(row)=>row.billingPeriod} title="Billing Period"/>
            <TabularColumn data={(row)=>row.currentPeriodExtendedPrice} title="Current Total"/>
            <TabularColumn data={(row)=>row.previousPeriodExtendedPrice} title="Previous Total"/>
        </Tabular>
    </div>
}

export default AutoPay;