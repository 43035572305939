import React, { Component, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import * as _ from 'lodash';

import Modal from '../../../../../public/js/components/Modal.js';
import * as CentralActions from '../../actions/CentralActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';

import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)

import '../../../RenderMeSoftlyEditor.scss';
import Bound from '../Bound.js';
import InputText from '../InputText.js';
import InputSelect from '../InputSelect.js';

import Editor, {EditorPanelDock, EditorPanel} from './Editor.jsx';

import Changer from '../../Changer.js';
import RenderMeSoftly from './RenderMeSoftly.jsx';

function ComponentOption({name, icon, onClick}){
	return <div className="comp" onClick={onClick}><h4>{name}</h4> <FontAwesomeIcon icon={icon || "question"}/></div>
}

function Toggle(props){
	return <div className="toggle" onClick={props.onClick}>
		<FontAwesomeIcon size="2x" color={props.state ? "#000" :"#999"} icon={ props.state ? "toggle-on" : "toggle-off" }/> <span className="label">{props.children}</span>
	</div>
}

function EditButton(props){
	const [hovered, setHovered] = useState(false);
	
	const keepOpenABit = ()=>{
		setHovered(true);
		setTimeout( ()=>setHovered(false), 1000 );
	};
	
	//editButtonOpen
	return <div style={props.style} className={"editButton " + (hovered ? "editButtonOpen" : "")} onMouseOver={()=>setHovered(true)} onMouseOut={()=>keepOpenABit()}>
		{props.children}
	</div>
}


function EditorButtons({ NDT, inner, active }){
	const [buttonState, setButtonState ] = useState({});
	
	const mouseWatcher = (e,buttonState) => {
			const seconds = 1;
		
	    	var x = e.pageX; //ientX + e.scrollX;
	    	var y = e.pageY; //clientY + e.scrollY;
	    	
	    	var mouseOver = [];
	    	let showButtonsSince = window.showButtonsSince ? {...window.showButtonsSince} : {};
	    	const now = Math.floor(performance.now());

			const path = e.composedPath();

	    	var ids = [];
			for (var x = 0; x < path.length; x++){
				if (path[x] && path[x].getAttribute && path[x].getAttribute("data-uid")){				    
				    const id = path[x].getAttribute("data-uid");
					ids.push(id);
					showButtonsSince[id] = now;
				}
			}
			
			_.each(showButtonsSince, (v,k)=>{
			    if ((now - v ) < seconds * 1000){
			        ids.push(k);
			    } else{
			        delete showButtonsSince[k];
			    }
			} );
						
			if (ids.length > 0){
				if (! _.isEqual(ids, buttonState.showButtons) ){
					//this is bad and breaks any ability to edit the report 
					setButtonState( {"showButtons": _.uniq(ids)} );	
					window.showButtonsSince = showButtonsSince;
				}
			}		
    };
    
    
    useEffect(()=>{
    	window.addEventListener('mousemove', _.throttle((e)=>mouseWatcher(e,buttonState),100) )
    }, []);
	
    var editButtons = [];
	var that = this;
	
	var seenY = {}; 			
	var moveIfLoop = 0;
	
	_.forEach( buttonState.showButtons ? buttonState.showButtons.sort() : null , (cid)=>{
			var thing = NDT.find(cid);
			var me = NDT.getNode(cid);

			if(thing != undefined){
				let dom = null;
				
				try {
				    dom = NDT.nodes[thing.theId].element;
				} catch(ex){				
					
				}
				
				if (dom == null && me && me.element){
					dom = me.element;
				}
				
				if (dom){
					var loc = dom.getBoundingClientRect();
					var top = dom.offsetTop; //+ window.scrollY;
					var left = dom.offsetLeft; // + window.scrollX;
					
					var approxTop = Math.floor( top / 10 );
					if (seenY[approxTop]){
						moveIfLoop = ( -1 * (seenY[approxTop])) * 20;
						seenY[approxTop] += 1;
					}else{
						seenY[approxTop] = 1;
					}
					
					var key = cid;
					
					var label = (thing && thing.cid > "" ) ? thing.cid : "";
					label += " ";
					label += (thing && thing.component ) ? thing.component : "";

					editButtons.push(<EditButton key={"edit-" + key + "-" + editButtons.length} style={{position: "absolute", left: left + moveIfLoop, top: top}}>{inner(key,me,label)}</EditButton>);
				}
	        } else {
	           console.log("Couldn't find dom node for " + cid); 
	        }
	});
	
	if (active == false){
		return null;
	}
	
	return <div className="all-buttons">{editButtons}</div>;	
	
}

export function NewComponent(props){
	const NDT = props.NDT;
	const [isAdvanced, openAdvanced] = useState(false);
	
	const importantOnes = [];
	
	var opts = {}; //{"Heading": "Heading", "Paragraph": "Paragraph" };

	_.each(props.me.types, (t)=>{ 
		opts[t] = t;
	
		    const component = NDT.lookupComponent(t);
			let meta = null;
			
			try {
			    meta = component.metaInfo();
			}catch(e){
			    try {
	                meta = component.metaInfo({});
	            }catch(e){
	                
	            }
	        }
			
			if(meta != null && meta.showInEditorQuickAdd == true){
			    importantOnes.push(<ComponentOption  onClick={()=>props.me.saveNewChanges(props.to, {direction: props.direction||"after",new_type: t })} key={t} name={t} icon={meta.icon}/>);
	        }
		
	});
	
	const where = <InputSelect field="direction" name="Adding" options={["after", "before","around", "replace", "change-type", "first-child","last-child"]}/>;
	
	var myComponent = _.find(NDT.structure, function(datum) {return datum.cid == props.RMS_adding_structure || datum.theId == props.RMS_adding_structure });

	/** 
	if (myComponent.component == "Table" && ["first-child", "last-child"].indexOf(this.state.direction) > -1) {
		myComponent = this.NDT.lookupComponent(myComponent.component);
		
		var meta = myComponent.metaInfo();
		opts = meta.allowableChildren;
	} **/
	const myStuff = {direction: "after"};
	
	
	if (isAdvanced){
		return <div>
		<button onClick={()=>openAdvanced(false)}> <FontAwesomeIcon icon="long-arrow-alt-left"/> Return to Common Elements</button>
	        <h2>Add Element!</h2>
	        	<Bound to={myStuff}>
	        	<InputText field="new_cid" name="CID"/>
	        	{where}
	        	<InputSelect field="new_type" name="Type" options={opts} showEmpty={true}/>
	
	            </Bound>
	            <button id="modal_confirm" onClick={()=>props.me.saveNewChanges(props.to, myStuff)}>Add</button>
	    </div>;
	}else{
		return <div>
		
			<div className="scrollymagic">{importantOnes}</div>
			<div className="buttons">
				<button onClick={()=>openAdvanced(true)} className="button"><FontAwesomeIcon icon="cogs"/> Advanced</button>
				
				<button onClick={()=>NDT.closeModal(props.to)} className="button"><FontAwesomeIcon icon="times"/> Cancel</button>
			</div>
		</div>
		
		
	}
    
    	// this.modal.closeCallBack = ()=>{ this.setState({RMS_adding_structure: undefined}) };

}

export default class RenderMeSoftlyEditor extends Component {
    constructor(props) {
    	super(props);
        this.state = {tempContentStructure: this.props.tempStructure, RMS_edit: "view", hoverElements: []};
        this.NDT = null; //do something later.
        //window.editor = this;
        this.editors = [];
    }
    
    axxxcomponentDidUpdate(prevProps){
    	if(prevProps.showButtons && prevProps.showButtons.length > 0){
    		if (this.props.showButtons.length == 0){
    			this.setState({showingButtons: prevProps.showButtons});
    		}
    	}
    }
    
    setSelection(selected){
    	this.selection = selected;
    }
    
    mouseUp(e){
    	if (e.shiftKey){
    		var id = null; // this.state.hoverElements[0];

			const path = e.composedPath();
			for (var x = 0; x < path.length; x++){
				if (path[x].getAttribute && path[x].getAttribute("data-uid")){
					id = path[x].getAttribute("data-uid");
					break;
				}
			}
    		//var wtf = ReactDOM/
    		
    		if (e.toElement && (e.toElement.type == "textarea" ||e.toElement.type == "input")){
    			e.preventDefault();
    			return;
    		}   
    		
    		if (id){
				e.preventDefault();
				e.stopImmediatePropagation();
				e.stopPropagation();
    			//clear our selection - likley chrome only @ian
    			window.getSelection().empty();
    			this.startEditing(id);
    		}
    	}else{
			const path = e.composedPath();
    		for (var x = 0; x < path.length; x++){
				if (path[x].getAttribute && path[x].getAttribute("data-uid")){
					id = path[x].getAttribute("data-uid");
					console.log(x + ": " + id, APP.NDT.find(id) );
				}
			}
    	
    		const selection = window.getSelection();
    		if (selection.type == "Range"){
    			const range = selection.getRangeAt(0);
    			const selectedElements = [];
    			_.each( APP.NDT.nodes, (n,id)=>{ 
    				if (n.element != null && range.intersectsNode(n.element)){ 
    					if (range.commonAncestorContainer.contains( n.element ) ){
    						selectedElements.push( n );
    					}
    				}
    			});
    			
    			if (selectedElements.length == 0){
    				const single = APP.NDT.findNodeByDom( range.commonAncestorContainer, true);
    				if (single){
    					selectedElements.push( single );
    				}
    			}
    			
    			if (selectedElements.length > 0){
    				console.log("You have selected", selectedElements);
    				this.setSelection( selectedElements );
    			}
    		}else{
				this.setSelection( [] );
    		}
    		
    		
    		
    	}
    }
    
    
    
    pasteListener(e){
    	/**
    	 *   paste needs automaticaly guess at a new RMS structure based on clipboard contents (e.g. paragraphs, etc). 
    	 *   they can decide "inject it" into the rms at where it belongs. 
    	 *    
    	 **/
    	var html = e.clipboardData.getData("text/html");
    	const elements = [];
    	
    	if (html){
    		const dom = new DOMParser();
    		const paste = dom.parseFromString(html, "text/html");
    		
    		if (paste.body){
    			
    			
    			_.each(paste.body.children, 
    					(c)=>{ 
    						console.log( c.tagName, c.innerText );   
    						if (c.tagName == "P"){   
    							let className = "";
    							
    							var contents = c.innerText;  
    							contents = contents.replace(/[\n\r]+/g, ' '); 
    							if (contents.indexOf("\xFC") >= 0){
    								contents = contents.replace("\xFC","");
    								className = "checkMark";
    							}
    							contents = contents.trim();
    							console.log( "---" + contents )
    							
    							if (contents.length > 0){
    								const newElement = {type:"ReactThing", component: "Paragraph", options: {content: contents} };
    								if (className.length > 0){
    									newElement.options.cssClass = className;
    								}
    								
    								if (c.className.indexOf("HEADING") >= 0){
    									newElement.component = "Heading";
    								}
    								
    								elements.push( newElement );
    							}
    						}else if (c.tagName == "OL" || c.tagName == "UL" ){
    							const tag = c.tagName;
								const newElement = {type:"ReactThing", component: "List", options: {type: c.tagName.toLowerCase() } };
								newElement.children = _.map(c.children, lc=>{ return {content: lc.innerText } });
								
								elements.push( newElement );
    						}
    					}
    			);
    			
    			APP.NDT.getEditor().createElementsFromPaste( elements );

    		}
    				
    		//this was just proof of concept I guess.
    		const tables = paste.body.getElementsByTagName("table");
    		if (tables.length > 0 && false){
    		const table = tables[0];
    		_.each( table.getElementsByTagName("tr"), (tr)=>{
    			var cols = 0;
    			_.each( tr.children, (thOrTd)=>{
    				const colspan = parseInt( thOrTd.getAttribute("colspan") || 1 );
    				cols += colspan;
    				const text = thOrTd.innerText.replace(/(\r\n|\n|\r)/gm, "").replace(/  */g, " ");
    				console.log(colspan + " "+ text);
    			});
    			
    			if (cols > guessCols){
    				guessCols = cols;
    			}
    		});
    		console.log("I have  " + guessCols + " columns");
    	}
    		
    		
    	}else if (e.clipboardData.items.length == 1){
    	
    		if (e.clipboardData.items[0].type == "text/plain"){
    			e.clipboardData.items[0].getAsString((s)=>{
    				var parsed = false;
    				try{
	    				const items = JSON.parse(s);
	    				
	    				_.each(items, (item)=>{
	    					item.cid = "";
	    					elements.push( item );    					
	    				});
	    				
	    				APP.NDT.getEditor().createElementsFromPaste( elements );
	    				parsed = true;
    				}catch(e){
    					
    				}
    				
    				
    				if (parsed == false){
    					const lines = _.compact(s.split(/\r?\n/));
    					if (lines.length > 1){
						
							const pastedInto = document.activeElement.closest( "*[data-uid]" ).getAttribute("data-uid");
							console.log("looks like you pasted into " + pastedInto); 

    						if (APP.NDT.getEditor().selection && APP.NDT.getEditor().selection.length > 0 ){
    							const structEditing = APP.NDT.find( APP.NDT.getEditor().selection[0].rmsStruct.theId.split("$")[0] );
    							if (structEditing.component == "List"){
    								const newChildren = _.uniq(_.concat(structEditing.children,  _.map(lines, (l)=>_.fromPairs([["content", l]]))) );
    								
    								e.preventDefault();
    								
    								APP.NDT.getEditor().queueContent( structEditing.theId, {}, "UPDATE_CHILDREN", newChildren);
    							}    							
    						}
    					}
    				}
    				
    				
    			})
    		}else{
    		
	    		const imgFile = e.clipboardData.items[0].getAsFile();
	    		
	    		var reader = new FileReader();
	    	    reader.onload = function() {
	    	        //create an img DOM object
	    	        var img = new Image();
	    	        //reader.result is nothing but the Base64 representation
	    	        
	    	        try{
	    	        	const uid = document.body.getElementsByClassName("emptyImage")[0].closest("*[data-uid]").getAttribute("data-uid");
	    	        
	    	        	img.onload = function() {   	        			
	    	        				APP.NDT.getEditor().queueContent( uid, {rawSrc: img.src, width: img.width, height: img.height}); 
	    	        	}
	    	        }catch(e){
	    	        	//
	    	        }
	    	        
	    	        
	    	        img.src = reader.result;
	    	        
	    	        
	    	    };
	    	    
	    	    reader.readAsDataURL(imgFile);
    		}
    	}
    	
    	
    	
    
    
    }
    
    createElementsFromPaste(elements){
    	if (elements.length >= 0){
			APP.central.Stuff.generateIds("cid", elements.length + 1).then( (r)=>{
				const container = {type:"ReactThing", component: "Container", children: [], options: { } };

				const addToId = _.first(_.keys(APP.NDT.inlineModals));
				if (addToId){
					
	    			_.each(elements, (a)=>{
	    				a.uid = r.result.shift();
	    				a.theId = a.uid;
	    				a.__save_required = true;
	    				a.__new = true;
	    				container.children.push( a.theId );
	    			});
	    			
	    			if (elements.length > 1){
	    				APP.NDT.getEditor().saveNewChanges(addToId, {direction: "after", new_type: 'Container' }, container, elements );
	    			}else{
	    				APP.NDT.getEditor().saveNewChanges(addToId, {direction: "after", new_type: elements[0].component }, elements[0] );
	    			}
	    			//for some reason editor is out of sync.
	    			setTimeout( ()=>{ 
	    				APP.NDT.getEditor().setState({tempContentStructure: APP.NDT.structure})
	    			}, 1000);
	    			
				}
				
			});
		}
    }
    
    componentDidMount() {
    	this.scrollListener = ()=>{ this.refreshBlockLocations() };
    	this.mouseUpListener = (e)=>{ this.mouseUp(e) };
    	window.addEventListener('mouseup', this.mouseUpListener )
    	window.addEventListener('scroll', this.scrollListener);
    	
    	document.body.addEventListener('paste', this.pasteListener );
        
    	this.keyListener = (e) => {
    		if (e.keyCode == 120){ //F9 because F10 is what the browser uses for the debugger.
    			this.editPage();
    			this.refs.dock.setState({collapsed: this.refs.dock.state.collapsed == undefined? false : !this.refs.dock.state.collapsed});
    		}else if (e.keyCode == 27){ //escape
    			this.modal && this.modal.closeAction();
    		}else if (e.keyCode == 46){
    			if (this.selection && this.selection.length > 0 && document.activeElement.tagName == "BODY"){
	    			if (confirm('are you sure you want to delete these ' + this.selection.length + ' elements?')){
	    				this.selection.map( (s)=>this.queueContent(s.rmsStruct.theId, {}, "DELETE") );
	    			}
    			}
    		}else if (e.keyCode == 13){
    			const selected = this.pasteOnListItem(e);
    		}
    		
    	}
    	document.addEventListener("keyup", this.keyListener )
    	window.editor = this;
        
    }

	pasteOnListItem(e){
		try{
			const closest = document.activeElement.closest( "*[data-uid]" ).getAttribute("data-uid");
			var NDT = this.getNDT();
			var structEditing = NDT.find(closest);
			
			if (closest.indexOf("$") > 0){
				const [ uid, index ] = closest.split("$");
				const lines = document.activeElement.innerText.split(/\r?\n/);
				var listEditing = NDT.find(uid);

				const newChildren = _.cloneDeep( listEditing.children );
				newChildren[parseInt(index)].content = lines[0];

				const newItem = {...newChildren[parseInt(index)]};
				newItem.content = lines[1]||"no content";
				newChildren.splice(parseInt(index)+1,0,newItem);

				e.preventDefault();

				this.queueContent(listEditing.theId, {}, "UPDATE_CHILDREN", newChildren);

			}

							


		}catch(e){

		}

		return null;
	}
    
    componentWillUnmount(){
    	window.removeEventListener('scroll', this.scrollListener);
    	window.removeEventListener('keyup', this.keyListener);
    	console.log("we unmounted");
    }
    
    getNDT() {
    	if (this.NDT == null){
    		console.log("we were asked for NDT but we don't have one... ", this);
    		console.log("USING APP.NDT");
    		this.NDT = APP.NDT;
    	}
    	
    	return this.NDT;    	
    }
    
    refreshBlockLocations(singleid){
        var deleteItems = [];
        var positions = {};
        var changeNeeded = false;
              
       if (this.getNDT() == null){
    	   return;
       }
       _.each(this.getNDT().getNodes(), (node, key)=>{
               var dom = null;

               try{
                   dom = node.element;
               }catch(e){

               }

               if (dom == null){
                   deleteItems.push(key);
               }else if (dom.getBoundingClientRect){
            	   var myOffset = dom.offsetParent;
            	   //myOffset.getBoundingClientRect().left;
            	   //myOffset.getBoundingClientRect().top;
            	   
                   var left = (dom.getBoundingClientRect().left) + window.scrollX;
                   var top = (dom.getBoundingClientRect().top) + window.scrollY;
                   var height = dom.getBoundingClientRect().height;
                   var width = dom.getBoundingClientRect().width;

                   if (this.state.elementLocations == null){
                                        changeNeeded = true;   
                                       }
                   if (  this.state.elementLocations != null && this.state.elementLocations[key] == null){
                       changeNeeded = true;
                   }else if (this.state.elementLocations != null && 
                       ( this.state.elementLocations[key].left != left 
                       ||this.state.elementLocations[key].top != top 
                       )){
                       
                       changeNeeded = true;
                       }
                   positions[key] = {left: left, top: top, height: height, width: width, offset: myOffset ? myOffset.getBoundingClientRect() : null };
                     
                   //editButtons.push( <a href="#" style={{position: "absolute", left: left, top: top}}className="editButton">EDIT {key}</a>)
               }

          });

       window.position = positions;
       
			var myKeys = Object.keys(positions);
			for (var i = 0; i < myKeys.length; i++) {
				for (var j = i + 1; j < myKeys.length; j++) {
					if (positions[myKeys[i]].left == positions[myKeys[j]].left && positions[myKeys[i]].top == positions[myKeys[j]].top) {
						//disabled for now was it was making constant refreshes.  @michael should look at this at some point.
						//changeNeeded = true;
						//positions[myKeys[j]].top += 20;
					}
				}
			}
			
       		if (changeNeeded){
       			this.setState({elementLocations: positions});
       		}
			
       
           // we might need to purge these from the DOM
          // _.each(deleteItems, (k)=>{delete window.mountedStructures[k]});
   }
    
    editPage(){        
        this.setState({editingUID: null, RMS_edit: this.state.RMS_edit == "edit" ? "view" : "edit" }, ()=>{
        	this.props.onEdit( this.state.RMS_edit );
        });
		this.refreshBlockLocations();
    }
    
    viewAs(mediaType){
    	var path = window.location.pathname;

    	if (path.match(/\/(content|contentInstance)\//) != null){
    		path = path.replace(/\/(content|contentInstance)\//, "");
    	}
    	
    	APP.showLoading({message: "Generating PDF", children: "This will take about " +  (30 + Math.round(10 * Math.random()) ) + " seconds.  You can minimize this dialog and the document will load when it's ready." }, ()=>{
			return APP.central.Stuff.generatePDF(path, "preview.pdf");
		});
    	
    }
    
    closeEditor(editor){
    	_.remove(this.editors, (e)=>{ return e.id == editor.id });
    	this.forceUpdate();
    }

    startEditing(id, event, callback) {    
        if(event != undefined){
            //allows us to click the tabs within the edit panel (see Editor.jsx)
            event.stopPropagation();
        }
		window.editingUID = id;    
    	this.setState({editingUID: id});

    	var NDT = this.getNDT();
    	var rms = NDT.getNode(id);
    	
		var structToEdit = NDT.find(id);
		
		if(structToEdit == undefined){
		    if(rms.props.structure != undefined){
		        structToEdit = _.cloneDeep(rms.props.structure);
		    }else{
		    	structToEdit = rms.rmsStruct;
		    }
		}

		var defaultOptions = null;
		const myType = NDT.lookupComponent(structToEdit.component); 
		
		if (myType && myType.getEditableProps) {
			defaultOptions = NDT.lookupComponent(structToEdit.component).getEditableProps( structToEdit );
			// defaultOptions = this.messWithDefaultOptions( defaultOptions, structToEdit )
		}
		
		var tempOptions = _.cloneDeep(structToEdit.options);
		//var view = <Editor key={structToEdit.theId} struct={structToEdit} options={defaultOptions} bindTo={tempOptions} doDelete={()=>this.queueContent(structToEdit.theId, tempOptions, "DELETE")} save={(e,what)=>this.queueContent(structToEdit.theId, tempOptions, "SAVE", what)}/>;

		//save's rawOptions is to edit things that are not in editable props
		var editorDetails = {key: structToEdit.theId, struct: structToEdit, options: defaultOptions, bindTo: tempOptions,  
				doDelete: ()=>this.queueContent(structToEdit.theId, tempOptions, "DELETE"),
				save: (e,rawOptions)=>{
				    this.queueContent(structToEdit.theId, rawOptions ? rawOptions : tempOptions, "SAVE")
				    if (this.props.saveAction){
			            this.props.saveAction(structToEdit, rawOptions ? rawOptions : tempOptions);
			        }
			        
				},
				rename: (newCid)=>this.renameCid(structToEdit.theId, newCid),
				add: (a)=>this.addTo( structToEdit.theId, "after", NDT.getAllComponents()),
				NDT: NDT,
				onDragEnd: (e)=>this.handleDragDone(e),
				onDragStart: (e)=>this.handleDragBegan(e, rms),
				extraActions: this.props.extraActions
		};
		
		const metaInfo = rms && rms.props && rms.props.metaInfo ? rms.props.metaInfo : APP.NDT.createMeta(structToEdit);
		
		if ( metaInfo.canHaveChildren ){
			editorDetails.addChild = (a)=>this.addTo( structToEdit.theId, "last-child", metaInfo && metaInfo.allowableChildren ? metaInfo.allowableChildren : NDT.getAllComponents() );
		}
		
		const idx = _.map(this.editors, 'id').indexOf(structToEdit.theId);
		if (idx == -1){
			this.editors.push({id: structToEdit.theId, order: 1, struct: structToEdit.theId, editorProps: editorDetails, options: tempOptions});
		}else{
			const editor = this.editors.splice(idx, 1);
			this.editors.push(editor[0]);
		}
		
		this.refs.dock.setState({collapsed: false}, ()=>{
		    if(callback){
	            callback();
	        }
		});
		
		
    }
    
    addToRoot(){
    	const NDT = this.getNDT();
    	if (NDT){
    		return this.addTo( this.NDT.root, "last-child", NDT.getAllComponents());
    	}else{
    		alert("you appear to not be NDT, and didn't send me an add function?");
    	}
    }
    
    addTo(uid, direction, types){
    	if (types){
    		this.types = types;
    	}else{
        	const NDT = this.getNDT();

    		this.types = NDT.getAllComponents();
    	}
    	
    	this.setState({RMS_adding_structure: uid, direction: direction, cid: null});
    }
    
    cancelAddTo(){
    	this.setState({RMS_adding_structure: undefined});
    }
    
    moveElement( movingCid, position, toCid ){
        //let componentType = undefined;
    	var movingThing = this.getNDT().find(movingCid);
    	var toThing = this.getNDT().find(toCid);
    	
    	if (movingThing.theId > ""){
    		toCid = movingThing.theId;
    	}else{
    		toCid = movingThing.theId;
    	}
    		
    	
        const newStructure = _.map(this.NDT.structure, (row)=>{
			if (movingThing.theId > ""){
				if (row.children && row.children.indexOf( movingThing.theId )>= 0){
					row.children = _.without( row.children, movingThing.theId);
					row.__save_required = true;
				}
			}
			
			if (row.children && row.children.indexOf( movingThing.theId )>= 0){
				row.children = _.without( row.children, movingThing.theId);
				row.__save_required = true;
			}
              
              
               if(row.theId == toThing.theId){
            	   if (row.children == null){
            		   row.children = [];
            	   }
            	   
                   row.children.splice( position, 0, movingThing.theId );
                   //componentType = row.component;
                   row.__save_required = true;
               } 
            
            return row;
        });

        
        this.updateContentStructure( newStructure );
        
        this.setState({dragging: false });
        this.NDT.getEditor().forceUpdate();
    }
    
 saveNewChanges(root, what, defaultNewElement, additionalElements){
	 const origRoot = root;
	 window.APP.central.Stuff.generateId("cid").then((r)=>{
	   
        	   var uid = r.result;
                
              var newCid = what.new_cid;
              
              let theId = newCid != undefined && newCid.length > 0? newCid: uid;
              if (defaultNewElement && defaultNewElement.theId > "" && defaultNewElement.theId == defaultNewElement.uid){
            	  uid = defaultNewElement.uid;
              }
              
              const NDT = this.getNDT();
              var existingCids = _.map(NDT.structure, 'cid');
             
             if ( existingCids.indexOf(newCid) >= 0 && newCid > ''){
                 alert("This cid is already used, please give it a unique name");
                 return false;
             }
                   
             var shouldAdd = true;
             
             var type = what.new_type;

             if (type == "ColorSet"){
                 shouldAdd = false;
             }
             
             	// NDT.structure.push({cid: "testing", type:"ReactThing", component: "Heading", options: {content: "hello"}})
             var position = -1;
             
             //make the root the parent structure if we are not doing a child (this will match before, after, around, replace
             if (what.direction.indexOf("child") == -1){
            	 const myParent = NDT.structure.filter( (f)=>f.children && f.children.indexOf( root ) >= 0 );
            	 if ( myParent.length ){
            		 position = myParent[0].children.indexOf(root);
            		 root = myParent[0].theId;
            	 }
             }
             let theOneToQueue; 
                const newStructure = _.map(NDT.structure, (row)=>{
                  if(shouldAdd && row.theId == root){ 
                	  theOneToQueue = row;
                     if(row.children == undefined){
                         row.children = [];
                     }
                     if (position >= 0 && what.direction == "before"){
                    	 //fruits.splice(2, 0, "Lemon", "Kiwi");
                    	 row.children.splice(position, 0, theId)
                     }else if (position >= 0 && what.direction == "after"){
                    	 //fruits.splice(2, 0, "Lemon", "Kiwi");
                    	 row.children.splice(position + 1, 0, theId)
                     }else if (what.direction == "first-child"){
                    	 row.children.splice(0, 0, theId);
                     }else if (what.direction == "last-child"){
                    	 row.children.push(theId);
                     }else if (what.direction == "replace" || what.direction == "around"){
						//blame michael if this is crazy.
						if (what.direction == "replace") {
							this.crazyReplaceFunction(row, row);
						}
						else {
							const beforeIdx = row.children.indexOf(origRoot);
							row.children[ beforeIdx ] = theId;
						}
                     } else if(what.direction == "change-type"){
                         row.component = what.new_type;
                     } else{
                    	 row.children.push( theId );	 
                     }
                     row.__save_required = true;
                  } 
                   
                   return row;
               });
                       
               var newElement =  defaultNewElement ? defaultNewElement : { uid: "", cid: newCid, theId: newCid, type: "ReactThing", component: type, options: { } };
               newElement.__save_required = true;
               newElement. __new = true;
               
               newElement.theId = theId;
        	   newElement.uid = r.result;
        	   
               if (type == "Container" || what.direction == "around"){
            	   if (newElement.children == null){
                   		newElement.children = [];
               	   }
               }
               
               if (what.direction == "around"){
        		   newElement.children.push(origRoot);
        	   }
               
               const componentType = this.NDT.lookupComponent(type);
               var elementMeta = componentType.metaInfo ? componentType.metaInfo({}) : {};
               
               if(elementMeta.inlineChildren && elementMeta.inlineChildren.dimensions){
                   if(elementMeta.inlineChildren.dimensions == 2){
                	   	if (! newElement.options.rows){
                	   		newElement.options.rows = 1;
                	   		newElement.options.columns = 1;
                   		}
                   } else if(elementMeta.inlineChildren.dimensions == 1){
                       console.log("set something here or suffer!");
                       //newElement.options.rows = 1;
                   }
                   
                   this.getNDT().saveDimensionalChildren(newElement, null, null);
               }
            
               if (type == "DataSet"){
            	   newElement.type = "DataSet";
            	   newElement.component = "DataSet";
            	   newElement.options = {args: {}};
      
               }
           
        	   newStructure.push(newElement);
        	   _.each( additionalElements, (ne)=>{
        		   if (ne != null){
        			    if (this.props.triggerQueueContent ){
        		        	if (ne.__new){
        		        		ne.__saveToId = ne.uid;
        		        		ne.__save_required = true;
        		        	}
        			    }
        			   newStructure.push(ne);
        		   }
        	   });
            	   
				if (type == "Loop") {
					newElement.children = [];
					var metaInfo = this.NDT.createMeta(newElement, {});
					
					for (var i = 0; i < metaInfo.mandatoryChildren.length; i++) {
						var newElement1 =  {options: {cssClass: ""}, theId: theId + i, uid: r.result + i, cid: newCid + i, type: "ReactThing", component: metaInfo.mandatoryChildren[i], __save_required: true, children: [] };
						
						newStructure.push(newElement1);
						newElement.children.push(newCid + i);
					}
					
					
				}
				

				
					NDT.loadStructure(newStructure);
                   this.setState({tempContentStructure: newStructure, editingUID: uid, RMS_adding_structure: undefined, direction: undefined}, ()=>{
                       //this.updateContentStructure( newStructure ); 
                		//this seems like we don't need to do this, but it will make an override if needed;
	       				this.queueContent(newElement.theId, newElement.options, "SAVE");
	       				this.queueContent(theOneToQueue.theId, theOneToQueue.options, "UPDATE_CHILDREN", theOneToQueue.children);
						   


                   });
                   
                   this.startEditing( uid );
                   NDT.closeModal(origRoot);
                   	     
    		   
       });
       
       
       
       //window._App.reloadDashboard(newOrigWTF);
     
   }
	//michael's craziness
	//this is going to get replaced by a more generic update function.
	crazyReplaceFunction(newElement, keyValues) {
		var changer = new Changer(newElement, keyValues);
		this.updateStructure(changer);
	}
	
	updateStructure(changer) {
		var that = this;
		
		var newTempStructure = _.cloneDeep(this.state.tempContentStructure || this.NDT.structure);
		
		var cidToChange = null;
		
		var oldCid = null
		var newCid = null;
		
		var updateStructure = _.map(newTempStructure, (row)=>{
			oldCid = row.cid;
			newCid = row.cid;
			var changeResult = changer.changeARow( row );
			// changeResult { changed: false }
			// changeResult { changed: true, row: ...  }
			row = changeResult.row;
			if (changeResult.changed){
				row.__save_required = 1;
				newCid = row.cid;
			}
			
			if (oldCid != newCid) {
				cidToChange = oldCid;
			}
			
			return row;
        });
		
		if (cidToChange) {
			updateStructure = cidReplacer(cidToChange, newCid, updateStructure);
		}
	}
	
	//a cid replacer for the structure because i'm tired of rewriting it everywhere.
	cidReplacer(oldCid, newCid, structure) {
		var newStructure = _.cloneDeep(structure);
		
		for (var i = 0; i < structure.length; i++) {
			if (newStructure[i].cid == oldCid) {
				newStructure[i].cid = newCid;
			}
			
			if (newStructure[i].children) {
				for (var j = 0; j < newStructure[i].children.length; j++) {
					if (newStructure[i].children[j] == oldCid) {
						newStructure[i].children[j] = newCid;
					}
				}
			}
		}
		
		return newStructure;
	}
    
    renameCid(uid, newCid){
    	 var newTempStructure = _.cloneDeep(this.state.tempContentStructure || this.NDT.structure);
    	 var oldCid = newTempStructure.filter( s=>s.uid == uid)[0].cid;
    	 if (newTempStructure.filter((s)=>s.cid == newCid).length > 0){
    		 alert("this name is taken");
    		 return;
    	 }
    	 
    	 if (oldCid == ""){
    		 oldCid = null;
    	 }
    	 
    	 var changed = [];
    	 
    	 newTempStructure = newTempStructure.map((struct)=>{
    		if (struct.uid == uid){
    			struct.cid = newCid;
    			changed.push(struct.uid);
    		}
    		 
    		if (struct.children && struct.children.indexOf( uid ) >= 0){
    			struct.children[ struct.children.indexOf( uid ) ] = newCid;
				struct.__save_required = true;
				changed.push(struct.uid);
			}
    		
    	 	if (oldCid && struct.children && struct.children.indexOf( oldCid ) >= 0){
    	 		struct.children[ struct.children.indexOf( oldCid ) ] = newCid;
    	 		struct.__save_required = true;
    	 		changed.push(struct.uid);
	 		}
    	 
    		return struct; 
    	 });
    	 
    	 this.setState({tempContentStructure: newTempStructure, editingUID: null}, ()=>{
    		 this.NDT.loadStructure(newTempStructure);
    		 this.updateContentStructure(newTempStructure);

    		 
    		 _.each( changed, (theId)=>{
    			 console.log(theId + " was changed");
    		 });
    		 
    		 this.editors = _.filter(this.editors, (s)=>{return ! (s.struct == oldCid || s.struct == oldCid) });
    		 this.startEditing(newCid);
         	/** if (me && me.theId){
 	        	_.each( this.getNDT().getAllSoftlyMountedStructure(me.theId), (them)=>{
 	        	    them && them.forceUpdate();
 	        	});
         	}else{
         		console.log("not sure what we were supposed to update")
         	}
         	**/
         });
    	 
    }
 
    updateContentStructure(newTempStructure){
    	const viewer = this.NDT.getViewer();
		var sharedUpdateContentStructure = viewer && viewer.props && viewer.props.updateContentStructure;
		
		this.NDT.loadStructure(newTempStructure);
        this.setState({tempContentStructure: newTempStructure});
        
		if (sharedUpdateContentStructure){
			sharedUpdateContentStructure(newTempStructure);
    	}
		
    	this.props.updateContentStructure(newTempStructure);
    	
    }
    
    queueContent(theId, options, action, children) {
        var that = this;

        
        if (action == undefined){
        	action = "SAVE";
        }
        
//        var myHeaders = new Headers();
//        myHeaders.append("Content-Type", "application/json");
//
//        var raw = JSON.stringify({"body": {"cid": cid, "options": options}});
//
//        var requestOptions = {
//            method: 'POST',
//            headers: myHeaders,
//            body: raw,
//            redirect: 'follow'
//        };
//        
//        fetch("/api/v1/contentStuff/editComponent", requestOptions)
//            .catch(error => console.log('error', error));
        
        var me = _.find(that.state.tempContentStructure, {"theId": theId});
        

				
		let childrenAddr = null;
		//this is to find a referenced inline item (e.g. tables)
		if (me == null && theId.indexOf('$') > 0){
			console.log("looking for something with a $ here", theId);
			me = _.find(that.state.tempContentStructure, {theId: theId.split('$')[0]} );
			childrenAddr = theId.split('$')[1].split(':').map(i=>parseInt(i));
			if(me != undefined){
			    theId = me.theId;
			}
		}
		
		if (me == null){
			me = APP.NDT.find(theId);
		}
        if (this.props.triggerQueueContent ){
        	if (me.__new){
        		me.__saveToId = me.uid;
        	}
        	const ret = this.props.triggerQueueContent(this, me, theId, options, action, children);
        	
        	if (ret != null){
        		return ret;
        	}//otherwise we are ok to contiunue;
        }
		

        var newTempStructure = [];
		var index = null;
		
		newTempStructure = _.cloneDeep( that.state.tempContentStructure || that.getNDT().structure);
		index =  _.keys(_.pickBy(newTempStructure, {"theId": theId}))[0];
		// we used to only call the above if we were a "SAVE", I think we can always do it this way.  This was the else for future sleuthing.
		/** 
			newTempStructure = that.state.tempContentStructure.filter( (s)=>s.theId == theId );
			index = 0;
		} */
        
        if (index == undefined){
        	const it = APP.NDT.find(theId);
        	if (it){
        		newTempStructure.push(it);
        		index = _.keys(_.pickBy(newTempStructure, {"theId": theId}))[0];
        	}
        }

		const parentStructure = APP.NDT.findParent(theId);
		if (parentStructure != null){
			const parentIndex = _.keys(_.pickBy(newTempStructure, {"theId": parentStructure.theId}));
			if (parentIndex.length == 0){
				newTempStructure.push(parentStructure);
			}
		}
        
        if(action == "UPDATE_CHILDREN"){
            newTempStructure[index].children = children;
        }
        
		if (newTempStructure[index] == null){
			console.log("this is crappy, and shouldn't have happened");
			debugger;
		}

        const theType = APP.NDT.lookupComponent(newTempStructure[index].component);
        const meta = theType && theType.metaInfo ? theType.metaInfo(newTempStructure[index]) : {};
        
        if ( newTempStructure[index].component == "Variable") {
			delete newTempStructure[index].options["args"];
			var newOptions = {};
			
			var keys = Object.keys(options)
			
			for (var i = 0 ; i < keys.length; i++) {
				if (keys[i] != 'args') {
					newOptions[keys[i]] = options[keys[i]];
				}
			}
			
			if (newTempStructure[index].component == "Variable") {
				newOptions = JSON.parse(document.getElementById("uglyhack").value);
			}
			
			options = newOptions;

			if (newTempStructure[index].component != "Variable") {
				newTempStructure[index].options.args = {...newOptions};
			}
			else {
			    _.forEach(options, (val, key)=>{
	                newTempStructure[index].options[key] = val;
	            });
			}
		} else if (childrenAddr != null){
			if (childrenAddr.length == 2){
			    this.getNDT().saveDimensionalChildren(newTempStructure[index], childrenAddr, options);
			}else if (childrenAddr.length == 1){
				this.getNDT().saveDimensionalChildren(newTempStructure[index], childrenAddr, options);
			}else{
				alert('what are you referencing??');
			}
		} else {
		    _.forEach(options, (val, key)=>{
		        newTempStructure[index].options[key] = val;
		    });
		}
		
        newTempStructure[index].__save_required = true;

		
		
		if (this.state.unsavedChanges == null) {
			this.setState({unsavedChanges: theId + ": " + JSON.stringify(options) + "\n"});
		}
		else {
			this.setState({unsavedChanges: this.state.unsavedChanges + theId + ": " + JSON.stringify(options) + "\n"});
		}
		
		if (action == "DELETE"){

			newTempStructure = newTempStructure.map( s=>{
				var changed = false;
				
				
				if (s.children){
					var before = s.children.length;
					s.children = _.without( s.children, me.uid, me.cid, me.theId);
					if (s.children.length != before){
						changed = true;
					}
					
				}
				if ( [me.uid, me.cid, me.theId].indexOf(s.theId) >= 0 ){
					s.__DELETE = true;
					changed = true;
				}
				
				if (changed){
					s.__save_required = true;
				}
				
				return s;
			});
	        //updateContentStructure is the function that actually will 
		}
		
		this.updateContentStructure(newTempStructure);
        
        this.setState({tempContentStructure: newTempStructure, editingUID: null}, ()=>{
            if(theId){
                APP.NDT.refresh(theId);
            } else {
                APP.NDT.refresh();
            }
        });
        
        
        
        if (action == "INLINE" || (options != undefined && action == "UPDATE_CHILDREN")){
        	
        	if (this.editors.find(e=>e.id == me.theId)){
        		var e = this.editors.find(e=>e.id == me.theId);
        		//e.options = options;
        		
        		_.map(options, (v,k)=>{
        			e.editorProps.bindTo[k] = v;
        			//e.view.props.bindTo[k] = v;
        		});
        		
        		//need to figure out a way to force update this?
        		//$r.forceUpdate()
        		//this.refs["editor_" + me.theId].forceUpdate();
        	}
        }
        this.modal && this.modal.closeAction();
    }
    
    saveAllContent() { //gq
		if (this.props.saveAllContent){
			console.log("using overriden saveAllContent prop");
			return this.props.saveAllContent();
		}
        var that = this;
        this.setState({editingUID: false, unsavedChanges: null});
       
        var saves = [];
		const uidsSaved = []; 

        _.forEach(that.state.tempContentStructure, function(thing) {
        		if (thing.__save_required){
					const thisSaves = [];
					thisSaves.push(thing.uid);

        			if (thing.__saveToId){
        				thing = _.cloneDeep(thing);
						thisSaves.push(thing.uid);
        				thing.cid = (thing.theId == thing.uid || thing.cid == undefined || thing.cid.trim().length <= 0? undefined : thing.theId);
        				thing.uid = thing.__saveToId;        					
        			}
        			
        			saves.push( that.props.central.ContentStuff.editComponent(thing).then( (r)=>{	
						uidsSaved.push( thisSaves );
					}) );
        		}
        	});
        
        Promise.all(saves).then((r)=>{
			const allUids = _.flatten(uidsSaved);
			const updates = this.state.tempContentStructure.map( (s)=>{
				if (allUids.indexOf(s.uid) >= 0){
					const ret = _.cloneDeep(s);
					delete ret.__save_required;
					return ret;
				}
				return s;
			});

        	this.updateContentStructure(updates);
        });
        
        
        
    }
    
    
    handleDragOver(e){
        e.preventDefault();
    }
    
    handleDragDone(e){
    	this.NDT.dragging = false;
    	//this.NDT.getViewer().setDragging(false);
        this.getNDT().getEditor().forceUpdate();
        APP.NDT.refresh();
    }
    
    handleDragBegan(e, me){
    	this.NDT.dragging = true;
    	const viewer = this.NDT.getViewer();
		viewer && viewer.setTicky && viewer.setTicky(Math.random());
    	
        if(me.component == "TabularColumnBit"){
            e.dataTransfer.setData('text/plain', 'dragging:' + me._cid);           
            setTimeout(()=>window.mountedStructures[me.__parent_cid].setState({dragging: true}),100);
        } else {
            e.dataTransfer.setData('text/plain', 'dragging:' + me.props.root);
            e.dataTransfer.setDragImage(me.element, 0, 0);
           //setTimeout(()=>{this.setState({dragging: true}, ()=>{ this.getNDT().getViewer().forceUpdate()})},100);        
        }
    }
    
    
    handleEditClicked(id){
        
      // make sure it's an id and not a fake loop thing
      var _id = id.substring(0, id.lastIndexOf("-loop"));
      if(_id == undefined || _id.length == 0){
          _id = id;
      }

      this.startEditing(_id);
      
      return false;

    }

	messWithDefaultOptions(defaults, struct){
	    //if(struct.component != "ViewView"){    		
    		for (var i = 0; i < defaults.length; i++) {
    			if (defaults[i].prop == "section") {
    				defaults[i].options = _.map(_.filter(window.APP.forms, function(datum) {
    				    let valToCompare = datum.theId;
    				    if(valToCompare == undefined){
    				        if(datum.cid != undefined){
    				            valToCompare = datum.cid;
    				        } else if(datum.uid != undefined){
    				            valToCompare = datum.uid;
    				        }
    				    }
    				    return valToCompare == struct.options.form
    			    }), function(datum) {
    				    let val = datum.theId;
                        if(val == undefined){
                            if(datum.cid != undefined){
                                val = datum.cid;
                            } else if(datum.uid != undefined){
                                val = datum.uid;
                            }
                        }
    				    return val;
    			    });
    			}
    		}
	    //}
		return defaults;
	}
	
	
	
	componentDidUpdate(prevProps){
    	var myNDT = this.getNDT();

    	if (this.state.RMS_adding_structure){
    		console.log("so you want to add? " + this.state.RMS_adding_structure);
    		
    		let editingId = this.state.RMS_adding_structure;
    		if (editingId.indexOf("-loop-") > 0){
    			editingId = editingId.replace(/-loop-.*/,'');
    		}
    		myNDT.openInlineModal(editingId, <NewComponent to={editingId} direction={this.state.direction} NDT={myNDT} me={this}/>);
    	}
    	
	}
   
	sayHelloToNDT(_NDT){
		if (this.NDT == null){
			this.NDT = _NDT; 
		}
	}
	
	editSetup(viewDefinition){	  
	    /*const viewDefinition = {
            id: 1084,
            form: "waste_audit",
            tab: "Setup"
        };*/
	    this.getNDT().getTemporaryStructure("ViewView", viewDefinition).then((r)=>{
	         let viewStructure = r;
	         
	         let rmsProps = {
	             central: "blah",
	             key: viewStructure.uid,
	             root: viewStructure.uid,
	             structure: [viewStructure],
	             mode: "edit"
	         };
	         const rmsView = <RenderMeSoftly {...rmsProps} />;
	         const NDT = this.getNDT();
	         if(NDT.tabs[viewDefinition.tab] == undefined){
	             NDT.tabs[viewDefinition.tab] = {};
	         }
	         NDT.tabs[viewDefinition.tab][rmsView.props.root] = rmsView;
	         //this.refs.dock.setState({collapsed: false, activeTab: viewDefinition.tab});
	    });
	}
	
	whoAmI(e){
		debugger;
	}
	
	focusNext(theId, e){
	    //NOTE: Incomplete. Used for tabbing or arrow keying to the next rms component
	    const me = APP.NDT.getNode(theId);
	    let next = null;
	    let keyCode = e.keyCode;
	    if(keyCode == undefined){
	        keyCode = 9; //treat it as a tab
	    }
	    
	    if(e != undefined){
	        e.stopPropagation();
	        e.preventDefault();
	    }
	    
	    if(keyCode == 9){ //tab
	        
	        
	        next = me.element.nextSibling;

	        if(next == null){
	            const parentSibling = next.parentElement.nextSibling;
	            if(parentSibling != null){
	                if(parentSibling.firstChild == null){
	                    next = parentSibling;
	                } else {
	                    next = parentSibling.firstChild;
	                }    	                
	            } else {
	                //loop back to start maybe?
	                debugger
	            }
	        }
	    } else if(keyCode == 38){ //up array
	        
	    } else if(keyCode == 40){ //down array
            
        }
	    
	    if(next != null){
	        next.style.backgroundColor = "red";
	        
	        const callback = function(){
	            if(next.firstChild){
                    next.firstChild.focus();
                    next.firstChild.click();
	            } else {
	                next.focus();
	                next.click();
	            }
            }
	        
            this.startEditing(next.getAttribute("data-uid"), null, callback);
            
            
            
	    } else {
	        debugger
	    }
	}
   
    render() {
    	

        var editButton = null;
        var saveAllButton = null;
        var pdfButton = null;
		var displayUnsavedChanges = null;

        
   
		
		
		if (this.getNDT() != null && this.state.RMS_edit == "disabled-edit"){
        	var seenY = {}; 
        	
        	var NDT = this.getNDT();
        	
			_.each(NDT.getNodes(), (node, key) => {
				var me = NDT.getNode(key);

				var thing = me.rmsStruct;
				
				var label = (thing && thing.cid > "" ) ? thing.cid : "";
				label += " ";
				label += (thing && thing.component ) ? thing.component : "";
	
				var loc = (this.state.elementLocations) ? this.state.elementLocations[ key ] : null;
				
				var moveIfLoop = 0;
								
				if (loc){

					if (seenY[loc.top]){
						moveIfLoop = (seenY[loc.top]) * 20;
						seenY[loc.top] += 1;
					}else{
						seenY[loc.top] = 1;
					}
										
					var showButton = true;
					

					if (showButton){
						editButtons.push(<div key={"edit-" + key} style={{position: "absolute", left: loc.left + moveIfLoop, top: loc.top}} className="editButton">
						<span onClick={() => {return this.handleEditClicked(key)}}>
							<FontAwesomeIcon icon="edit"/>
							<span className="editKey">{label}</span></span> <span className="drag-handle" draggable={true} onDragEnd={(e)=>this.handleDragDone(e)} onDragStart={(e)=>this.handleDragBegan(e, me)}><FontAwesomeIcon icon="arrows-alt"/></span>
						</div>)
					}
				}
				
				else {
					var isDataSet = false
					for (var i = 0; i < me.props.structure.length; i++) {
						if (me.props.structure[i].type == "DataSet") {
							isDataSet = true;
						}
					}
					
					if (isDataSet) {
						editButtons.push(<div className="editButton">
					<span onClick={() => {return this.handleEditClicked(key)}}>
						<FontAwesomeIcon icon="edit"/>
						<span className="editKey">{key}</span></span> <span className="drag-handle" draggable={true} onDragEnd={(e)=>this.handleDragDone(e)} onDragStart={(e)=>this.handleDragBegan(e, me)}><FontAwesomeIcon icon="arrows-alt"/></span>
					</div>)
					}
				}
				
			});
			
        }
        
      
                /* if (this.props.mode == "edit"  && root && (children.length > 1 || root.children) ){
                    const editUI = this.props.mode == "edit" ? 
                          <div className="editing editButton editAddButton" onClick={()=>this.dummyFunction}><h1>EDITING</h1> <span className="editKey">{root.theId}</span></div> 
                    : null;
                          
                    return (<div>
                           {children}
                           {editUI}
                           {editButtons}
                      </div>);            
                }else{
                    if (children.length == 0){
                        // we were a dead end.  hopefully on purpose
                        return null; 
                    }
                    
                    if (this.props.includeEditLink == true){
                        //this.props.theId
                    //    children.push(   );
                    }            
                    
                    
                    return children;
                }*/


                /** if (editorPanels.length == 0){
                	crazyEditUI = null;
                }**/
                var that = this;
                
               
                
                var items = 0;
                
                if (this.state.tempContentStructure){
                	items = this.state.tempContentStructure.filter((f)=>{ return f.__save_required }).length;
                }

				if (this.props.editBadgeCount){
					items = this.props.editBadgeCount();
				}
                
                var badge = items > 0 ? <em className="badge">{items}</em> : null;
                
                var editorBody = <div className="mainButtons">
	                <div className="toggle"><Toggle key="edit" state={this.state.RMS_edit == "edit"}  onClick={()=>this.editPage()}>Edit Page</Toggle></div>
	                <button key="pdf" type="button" className="btn btn-default btn-pdf" onClick={()=>this.viewAs("PDF")}>PDF</button>
	                <button key="save" type="button" className="btn btn-default btn-save" onClick={()=>this.saveAllContent()}>COMMIT CHANGES {badge}</button>
                </div>;

        const buttonsInner = (key,me,label)=>{
        	return <span onClick={() => {return this.handleEditClicked(key)}} title="Edit Element">
    		<FontAwesomeIcon icon="edit"/>
    			<span key={"drag"} className="drag-handle" draggable={true} onDragEnd={(e)=>this.handleDragDone(e)} onDragStart={(e)=>this.handleDragBegan(e, me)} title="Drag To Move"><FontAwesomeIcon icon="arrows-alt"/> </span>
    			<span key={"edit"} className="editKey" onClick={(e)=>this.whoAmI(e, thing)}>&nbsp; {label} &nbsp;</span>
    			<span key={"add"} className="editKey addElementAfter" onClick={(e)=>this.addTo(me.rmsStruct.theId, "after")} title="Add Element"><FontAwesomeIcon icon="plus-square"/></span>
    			</span>;
        };
    		
        return <>
    	<div className="editor" className={"OuterEditor" + (this.state.dragging ? " AppIsDragging " : "") + (this.state.RMS_edit ? " AppIsEditing" : "")}>
    	<EditorButtons NDT={this.NDT} inner={buttonsInner} active={this.state.RMS_edit == "edit"}/>
    	<EditorPanelDock  onClose={()=>this.props.onClose && this.props.onClose()} add={()=>this.addToRoot()} NDT={this.NDT} ref="dock" onStartEditing={(id, e)=>this.startEditing(id, e)} closeEditor={(id)=>this.closeEditor(id)} structure={this.props.tempStructure} editors={this.editors} hoverElements={this.state.hoverElements}>
    	{editorBody}
    	</EditorPanelDock>
    	{this.props.children}
    	{editButton}
    	{pdfButton}
    	{saveAllButton}
    	{displayUnsavedChanges}
    	<section id="modal"></section>
    	</div>
    	</>
    }
}