import React, { Component } from 'react';
import MetaTalker from './MetaTalker.jsx';

export default class Variable extends MetaTalker {
    constructor(props){
        super(props);
        this.state = {val: null};
    }
    
    static xgetEditableProps() {
        return [
            {niceName: "Variable Name", prop:"name", type:"text", default: ""}
        ]
    }
    
    componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps.action != this.props.action || this.state.val == undefined){
            this.process(this.props.action);
        }
    }
    
    process(action){
        if (action == undefined){
            return;
        }
        
        let val = ""; //result of action
        if(action.set != undefined){
            val = action.set;
        } else if(action.increment){            
            if(this.props.scope == undefined){
                val = action.increment;
            } else {
                if(!isNaN(action.increment)){
                    val = parseFloat(this.props.scope[this.props.name]) + parseFloat(action.increment);
                } else {
                    val = this.props.scope[this.props.name] + action.increment
                }
            }
        } else if(action.js){
            val = eval(action.js);
        } else {
            console.log("incompatible action");
        }
        
        if (this.state.val != val){
            this.setState({val: val}, ()=>{
                this.props.announceScope(this.props.name, val)
            });
        }
    }
}