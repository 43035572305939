import React, { Component, useContext, useEffect, useRef, useState } from 'react';

import * as _ from 'lodash';




import Functioner from '../../Functioner.js';


import { ErrorHandler } from '..';
import NeilDegrasseTyson, { NDTContext } from './NeilDegrasseTyson.jsx';
import RMSPreview from './RMSPreview';
import templated from './Types/Templated.jsx';

class DropTarget extends Component {
    
    constructor(props) {        
        super(props);
        this.state = {hovering: false};
    }
    
    dragOver(e){
        e.preventDefault();
    }
    
    dragEnter(e){
        this.setState({hovering: true});
    }
    
    dragLeave(e){
        this.setState({hovering: false});
    }
    
    dropped(e){
        var draggingCommand = e.dataTransfer.getData("text");
        var chunks = draggingCommand.split(":");
        this.props.NDT.getEditor().moveElement( chunks[1], this.props.position, this.props.theId )
    }
    
    
    
    render(){
        
        return <span data-target={this.props.target} className={(this.state.hovering ? "active-drop-target" : "drop-target") + " " + this.props.className} onDragOver={(e)=>this.dragOver(e)}  onDragEnter={(e)=>this.dragEnter(e)}  onDragLeave={(e)=>this.dragLeave(e)}  onDrop={(e)=>this.dropped(e)}>
                <span className="hoverMessage">{this.props.children}</span>
            </span>  
    }
}

function announceScope(a,b,c,d){
	console.log("ok announceScope", a, b, c, d);
}

export function wrappedAndTracked(me, root, metaInfo){
    
	if (true){
		
	}
	const theRealProps = {...me.props};
	const extra = {};

	if (metaInfo.rendersChildren || theRealProps.rendersChildren == "yes"){
		extra["data-renders-children"] = true;
	}
	
	if (root.__saveToId){
		extra.className = "override-block";
		extra.title = root.__saveToId + " overriding " + root.theId;
		extra.onClick = ()=>{console.log(root.theId)};
	}
	
	if (metaInfo.renderAsPng){
		extra.className = "render-as-png";
	}
	
	if (theRealProps.className && theRealProps.className.indexOf("meta_talker") >= 0){
		extra.className = theRealProps.className;
	}
	
	return <div data-me={"ye"} data-uid={root.theId } ref={(node)=>APP.NDT.crazyRefer(node,theRealProps,false)} key={root.theId} {...extra}>
		{theRealProps.debug ? root.theId : null }{me}
	</div>
}

export default function RenderMeSoftly(props){
	let parentNDT = useContext(NDTContext);	
	const [ticky, setTicky] = useState(1);
	
	const refresh = ()=>{ setTicky(Math.random()) };
	
	const ourThis = {setTicky: setTicky, refresh: refresh, ticky: ticky, mode: props.mode};
	 
	var NDT = parentNDT;

	if (props.sharedEditor){
	    NDT = props.sharedEditor.NDT;
	}
	
	useEffect(()=>{
	    return ()=>{
    	    if(props.iAmGod){
    	        console.log("NDT was cleaned up by " + props.root);
    	        APP.NDT.cleanUp();
    	    }
	    }
	}, []);
	
	ourThis.scope = props.scope;
	let isMainNDT = false;

	if (NDT == undefined || (props.iAmGod && props.root != NDT.root)){
		ourThis.wtf = Math.random();
		if (props.NDT){
			console.log("we are using an NDT that existed already, I think we are ok.", props);
		}
		
		NDT = useRef(props.NDT ? props.NDT : new NeilDegrasseTyson(ourThis, props.root)).current;
		
		if(APP.NDT != null && APP.NDT.wtf != NDT.wtf){
		    APP.NDT.destroy(NDT);
		}

		NDT.me = ourThis;

		isMainNDT = true;
		// NDT = null; //this is actually required
	}else{
		//this is just to make the hooks match
		const weDontCare = useRef(NDT);
		NDT = parentNDT;
	}
	
	const crazyRefer = (node, _props, outerRoot) => {	 
	    let rmsStruct = {theId: _props.root};
	    if(! outerRoot){
	        rmsStruct = {...rmsStruct, ..._props.rootStructure};
	    }
	    
	    if(_props._loopIndex != undefined){
	    	rmsStruct.theOldId = rmsStruct.theId;
	        rmsStruct.theId = rmsStruct.theId + "-loop-" + _props._loopIndex;
	    }
	    
		if (outerRoot){
			NDT.notifyMounted(rmsStruct, {theRoot: true}, node, refresh);
		}else{
			NDT.notifyMounted(rmsStruct, _props, node, refresh);	
		}	    
	  }; 
	
	if (isMainNDT){
		if ( _.size(NDT.structure) == 0 ){
			NDT.loadStructure(props.structure);
		}else{
			if ( NDT.structure.indexOf(props.structure[0]) == -1 ){
				NDT.loadStructure(props.structure);
			}
		}
		//ref={crazyRefer} removed from RMS element because apparently it won't do anything (console warning)
	    const myProps = {...props};
	    delete myProps.iAmGod;
		NDT.crazyRefer = crazyRefer;

		if (props.root.indexOf("fake-root") == -1){
			APP.NDT = NDT;
		}	    

		return <div className="theouter" ref={(node)=>crazyRefer(node, props, true)}>
				<NDTContext.Provider value={NDT}>
					<RenderMeSoftly {...myProps} innerGuy={1} ticky={ticky} _path="/"/>
				</NDTContext.Provider>
			</div>;
	}

	let mainRoot = NDT.find( props.root, props.structure );
	
	if (props._path == "/" && (! props.datasets) && props.structure ){
		var datasets = props.structure.filter( (s)=>s.type == "DataSet" );
		
		console.log("we have datasets");
	}

	var editMode = false;
	var dragging = false;
	if (NDT.getEditor() != null){
		editMode = NDT.getEditor().state.RMS_edit == "edit";
		dragging = NDT.dragging;
	}
	
	 var roots = [mainRoot];
     if(window.theRealStructure == undefined){
         window.theRealStructure = [];
         window.seenStruct = {};
     }
             
     const children = [];

     while (roots.length > 0){
         var root = roots.shift();
         
         if (root == undefined){
         	continue;
         }
         
         if (root.next){
         	var theNext = NDT.find( root.next );
         	if (theNext){
         		roots.push( theNext );
         	}
         }
         
         var thisProps = {key: props.root};
         if (root.options){
             thisProps = {...thisProps, ...props, ...root.options};
         } else {
             thisProps = {...thisProps, ...props};
         }
         
         if (root._injected){
        	 thisProps.root = root.theId;
        	 thisProps.rootStructure = root;
        	 thisProps._injected = true;
         }
         
         delete thisProps.metaInfo;         
         
         let metaInfo = NDT.createMeta( root, thisProps.heirarchy, false );
         thisProps.metaInfo = metaInfo; 
         if(thisProps._loopIndex != undefined){
             metaInfo._loopIndex = thisProps._loopIndex;
         }
         const MyComponent = metaInfo.component;
         
         if(metaInfo && metaInfo.inContent == false){
             thisProps.key += "-" + metaInfo.component.name;
         }
                  
         if(root.options != undefined && root.options.isSection){
             thisProps.isSection = root.options.isSection;
         }
         
         thisProps.handleInlineChange = NDT.myHandleInlineChange(root);

         try{ //@cody should fix this
             let instanceOfMyComponent = new MyComponent(thisProps);               

         	if(instanceOfMyComponent != undefined){
         	    let myNext = undefined;
         	   
                 if(instanceOfMyComponent.injectNext){
                     myNext = instanceOfMyComponent.injectNext();
                 } else if(MyComponent.injextNext){
                     myNext = MyComponent.injextNext(thisProps); //static reference for functional components. e.g. PieChart
                 }
                     
                 if(myNext != undefined){
                	 myNext._injected = true;
                	 myNext.injectTo = (root.cid || root.uid); //this is the element that created the injection
                	 myNext.options = root.options;
                	 myNext._loopIndex = thisProps._loopIndex;
                     roots.unshift(myNext);
                     NDT.notifyInjected(myNext);
                 }
             }
         }catch(ex){
             
         }
         
         const theseChildren = [];
         
         var position = 0;
       
        
         
         _.forEach(root.children, (kids)=>{
             position++;
             const keySuffix = props._loopIndex >= 0 ? "-loop-" + props._loopIndex : "";
             let childProps = {};
             
             childProps.pdfMode = props.pdfMode;
               
             //sorcery for tables being an array, so everything is an array
             const allOfMyChildren = Array.isArray(kids) ? kids : [kids];
           
             _.forEach(allOfMyChildren , (child, i) =>{
            	 // cody's ith element of the children of table.
            	 
                 //let childScope = {...this.props.scope, ...this.NDT.whatIsMyScope(child, metaInfo)};
                 let rootStructure = null;
                 
                 //this is to check for ian's lists
                 
                 if (typeof child == "object" && (root.children && ! Array.isArray(root.children[0])) ){
                	 if (metaInfo.inlineChildren){
                	 	child = root.theId + "$" + (position - 1);
                	 	rootStructure = NDT.find( child );
                 	 }		
                 }
                 
                 let childScope = {};
                 if(typeof child != "object"){
                     childScope = NDT.whatIsMyScope(child, metaInfo, props.scope);
                 }
                 
                 
                 if ( typeof child == "object" ){
                	 rootStructure = NDT.find( child, null, metaInfo );
                 }else{
                     rootStructure = NDT.find( child );
                 }
                 
                 if(rootStructure == undefined){
                     const childNode = NDT.getNode(child);
                     if(childNode != undefined){
                         rootStructure = childNode.rmsStructure;
                     }                     
                 }

				if (rootStructure == undefined && typeof child == "string") {
					rootStructure = _.find(window.APP.NDT.structure, function(r) {return r.cid == child});
				}
                 const childMetaInfo = rootStructure != null ? NDT.createMeta( rootStructure, childProps.heirarchy, true ) : {};
                 //These are the RMS shells that contain all the children.
                 
                 if (thisProps.keepClassInPrint){
                	 childMetaInfo.wrapInCSS = thisProps.cssClass;
                 }
                 
                 childProps.mode = editMode ? "edit" : "view";
                 
                 let rmsStruct = {...root, metaInfo: childMetaInfo};      
                 const rmsProps = {
                 		rootStructure: rootStructure,
                 		metaInfo: childMetaInfo,
                 		NDT: NDT,
                 		_loopIndex: props._loopIndex,
                 		who: "9",
                 		scope: childScope,
                 		announceScope: NDT.myAnnounceScope(rmsStruct),  
                 		//mountedCallback: this.mountedCallback,
                 		key: props.root + "-" + child + keySuffix,
                 		root: child
                 };
                 
                 const finalProps = {...childProps, ...rmsProps, _path: props._path + "/" + root.theId};
     
                 finalProps.scope.b = "no";
                 //don't wrap things like tablecell's in a error handler.
                 if (childMetaInfo.livesDangerously || true){
                	 theseChildren.push(
                    		 	<RenderMeSoftly {...finalProps}/> 
                     );
                 }else{
	                 theseChildren.push(
	                		 <ErrorHandler path={finalProps._path + "/" + finalProps.root}>
	                		 	<RenderMeSoftly {...finalProps}/>
	                		 </ErrorHandler> 
	                 );
                 }
                 
                 var showDrop = false;
                 if (NDT.getEditor() != null){
                	 const editor = NDT.getEditor();
                	 if (editor.state.showButtons && editor.state.showButtons.indexOf(props.root) >= 0){
                		 showDrop = true;
                	 }
                 }
                 
                 if (editMode && dragging){ // && props.root != "audit-template"){
                	const theChild = APP.NDT.find(child);
                	let niceChildLabel = child;
                	if (theChild){
                		niceChildLabel = theChild.cid || theChild.component;
                		console.log("drag over", APP.NDT.getNode(child), theChild);
                	}
                	
                	 theseChildren.push( <DropTarget target={root.theId + "_" + child + "_drop_target"} key={root.theId + "_" + child + "_drop_target"} NDT={NDT} theId={props.root} position={position}><span> drop after {niceChildLabel} (in {root.component} {root.theId || root.cid || root.uid}) </span> </DropTarget> ); 
                 }
                 
                 /** if (this.amIDragging()){
                     theseChildren.push( <DropTarget NDT={this.NDT} theId={this.props.root} position={position}><span> drop after {child} (in {root.theId || root.cid || root.uid}) </span> </DropTarget> );
                 } **/
                 
             });
             
         });
             
         if (editMode && dragging){ // && props.root != "audit-template"){
        	 const child = "last child";
        	 theseChildren.push( <DropTarget className="lastChildDropTarget" target={root.theId + "_" + child + "_drop_target"} key={root.theId + "_" + child + "_drop_target"} NDT={NDT} theId={props.root} position={position}><span> last child of ({root.theId || root.cid || root.uid}) </span> </DropTarget> );
         }
         
         
         //see what's a functioner things
         const propDefs = {};
         try{
        	 if (metaInfo.component && metaInfo.component.getEditableProps){        	
        		 metaInfo.component.getEditableProps({}).map( d=>propDefs[d.prop] = d );
        	 }
     	 }catch(e){}
     	 
         _.each( _.keys(thisProps), (propKey)=>{
         	if (Functioner.looksLikeIt( thisProps[propKey], propKey, thisProps  )){
         		var func = new Functioner( thisProps[propKey] );
         		thisProps[propKey] = func.doEval(thisProps, null );
         	}else if (propDefs[propKey] && propDefs[propKey].templated ){
       			thisProps[propKey] = templated(thisProps[propKey], {props: thisProps});
         	}
         });
         
            
         
 	     if (metaInfo && metaInfo._loopIndex >= 0){
	 	   	 thisProps.suffix = "-loop-" + metaInfo._loopIndex;
 	     } 

         
         if (MyComponent){
             if(root.type == "MetaTalker"){
                 thisProps.className = (thisProps.className || "") + " meta_talker";
             }
                          
        	 thisProps.central = APP.central;
        	 if (metaInfo.isAlreadyInABlock){
        		 thisProps.existingBlockProps = {'data-uid': root.theId};
        	 }
        	 var me = React.createElement(MyComponent, thisProps, theseChildren && theseChildren.length > 0 ? theseChildren : null );  	     
    	     
    	     if (metaInfo && metaInfo.isAlreadyInABlock){
    	    	 //this hides table cells
    	    	 if (props.debug){
    	    		 children.push(<div key={root.theId} className={thisProps.className}> element {root.theId} {me} </div>);
    	    	 }else{
    	    	 	children.push(me);
    	     	 }
    	     }else{
    	    	 // by default we will wrap all elements in a div to let us know their uid    	    	 
    	    	    
    	    	 children.push(wrappedAndTracked(me, root, metaInfo));
    	     }
    	     
        }else if (root.type == "MetaResource"){
            //ndt add meta resource
            NDT.postResource(root.options.name, root);
          } else{
              console.log("I don't know " + props.root + "'s " + root.component)                      
             if (theseChildren && theseChildren.length > 0){
              console.log("should I return children?");
              return theseChildren;
             }
          }
         
         
          const inlineModal = NDT.getInlineModals( root ? root : null );
          
          //if(NDT.inlineModal &&  && root.theId == "cid_V0FTVEUhAAAABpn2oL2VTj0rSgOB"){
          if (inlineModal){
              children.push(<div className="inlineModal" key={"inline-modal-" + root.theId}>{inlineModal}</div>);
          }
         
         
     }
     
     if(props.mode == "preview"){
         return <RMSPreview>{children}</RMSPreview>;
     }         
     
     return children;
}

function RenderMeSoftlyInner(props){
	const NDT = useContext(NDTContext);
	return <div>{NDT}</div>
}

//