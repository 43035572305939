import React, { Component } from 'react';
import templated from '@opidcore/components/Content/Types/Templated.jsx';
import Icon from '@opidcore/components/Icon.jsx';

function SSPToggleSwitch(props){
    let content = undefined;
    if(props.val == "on"){
        content = <Icon icon="check" fType="fas" size="1x" />;
    }
    return <div className="source_separation_program_table-toggle" onClick={props.handleUpdate}>
        {content}
    </div>;
}


export default class SourceSeparationProgramTable extends Component {    
    static getEditableProps(){
        var datasets = Object.keys(window.APP.NDT.datasets);
        
        return [
            {niceName: "Data Set", prop: "dataset", type: "text", options: datasets},
            {niceName: "Title", prop: "title", type: "text", editInPlace: true, default: "New Table"},
            {niceName: "Title Size", prop: "titleSize", type: "text", options: ["h1","h2","h3","h4"], default: "h1"},
            {niceName: "Style", prop: "cssClass", type: "style", default : ""}
        ];
    }
    
    static metaInfo() {
		return {
			rendersChildren: true
		};
	}
    
    injectNext(){
        const struct = this.props.NDT.find(this.props.root);

        return {
            children: null,
            cid: struct.theId + "-table",
            component: "TableCounter",
            connections: [],
            connectors: null,
            next: null,
            options: null,
            returns: null,
            theId: struct.theId + "-table",
            type: "MetaTalker",
            uid: struct.uid + (this.props.NDT.structure.length + 1)
        };
    }
    
    render(){        
        let data = [];
        
        if(this.props.NDT.datasets[this.props.dataset]){
            data = _.sortBy(APP.NDT.datasets[this.props.dataset].rawData, ["stream", "category_summary"]);
        }
        
        if(data.length > 0){
            data = _.filter(data, function(dr){ dr.showCheck = dr.stream != "1123" || (  dr.source_separation_required && dr.source_separation_required.indexOf("Show") >= 0 ); return dr.showCheck; });
        }
        
        let tableRows = _.compact(_.map(data, (row, index)=>{
        	if (row.source_separation_required != "Hide"){
            return <tr key={index}>
                <td>{row.category_summary}</td>
                <td><SSPToggleSwitch val={row.source_separation_required && row.source_separation_required.toLowerCase() == "yes"? "on" : "off"}/></td>
                <td><SSPToggleSwitch val={row.showCheck ? "on" : "off"}/></td>
           </tr>;
        	}
        }));

        let newThis = {...this};
        if(newThis.props.scope.table_number){
            newThis.props.scope.table_number = "Table " + newThis.props.scope.table_number + ". ";
        }
        const title = templated(this.props.title || "", newThis, (title)=>{this.props.handleInlineChange({content: title})});
        const TitleTagName = this.props.titleSize || "h2";
        
        return <div key="source_separation_program_table" className="source_separation_program_table">
            {this.props.title > "" ? <TitleTagName className="table_heading">{title}</TitleTagName> : null}
            <table>
                <thead>
                    <tr>
                        <th>Divertable Material Categories</th>
                        <th>Is Source Separation Required By Ontario Regulation 103/94?</th>
                        <th>Is The Material Currently Source Separated</th>
                    </tr>
                </thead>
                <tbody>
                        {tableRows}
                </tbody>
            </table>
        </div>;
    }
}