import React, { useRef } from "react";
import { useData, useNavBar } from "@opidcore/hooks/WTF";
import { Icon, TabularDataSet, TabularColumn, Button, Pagination, Searchable, ActionBar, BoundCommit, InputText, UnlockToEdit } from "@opidcore/components";
import * as _ from "lodash";

export default function EquipmentSizeEdit(props) { 
  useNavBar("Edit Equipment Size", []);

  const [data, dataSet] = useData("equipmentsize", { paginate: 100 });

  const editEquipmentSize = (equipmentSize) => {
    APP.instance.createModal(<EditEquipmentSize equipmentSize={equipmentSize} />);
  };

  const newEquipmentSize = () => {
    APP.instance.createModal(<EditEquipmentSize equipmentSize={{ __new: true, __dataUID: dataSet.dataUID }} />);
  };

  return (
    <div className="full-size">
      <ActionBar title="Actions">
        <Searchable ds={dataSet} />
        <a className="btn btn-new" onClick={() => newEquipmentSize()}>
          New Equipment Size
        </a>
      </ActionBar>

      <div>
        <Pagination dataSet={dataSet} />
        <Button onClick={() => dataSet.download("xlsx")}>excel</Button>
      </div>

      {dataSet.loading ? "loading" : ""}

      <TabularDataSet dataSet={dataSet} defaultSort="id" defaultSortOrder="asc">
        <TabularColumn data={(row) => row.id} title="Equipment Size #" />
        <TabularColumn data={(row) => row.name} title="Name" />
        <TabularColumn data={(row) => row.type} title="Type" />
        <TabularColumn data={(row) => _.round(row.volume, 2)} title="Volume m^3" />
        <TabularColumn data={(row) => _.round(row.volumeInYards, 2)} title="Volume cubic yards" />
        <TabularColumn
          data={(row) => {
            return <Icon icon="edit" onClick={() => editEquipmentSize(row)} size="2x" />;
          }}
        />
      </TabularDataSet>
    </div>
  );
}

function EditEquipmentSize(props) {
  const equipmentSizeRef = useRef({ ...props.equipmentSize });

  return (
    <div>
      <BoundCommit
        initialValue={equipmentSizeRef.current}
        forceSaveProps={["equipmentsize"]}
        commit={APP.central.EquipmentSize.saveChanges}
        className="basicForm"
        permissions={APP.getWebSession().permissions("Controlled Data Lists")}
      >
        <UnlockToEdit>
          <InputText field="id" name="#" />
        </UnlockToEdit>
        <InputText field="name" name="Name" />
        <InputText field="type" name="Type" />
        <InputText field="volumeInYards" name="Volume cubic yards" />
        <InputText />
      </BoundCommit>
    </div>
  );
}
