import React from 'react';
import { useData } from '@opidcore/hooks/WTF';
import { TabularColumn, TabularDataSet } from '@opidcore/components';

const InvoiceHistory = ({service}) =>{
	const [invoiceHistoryData, invoiceHistoryDataSet] = useData("invoice-history-" + service.id, APP.central.Invoices.fetchHistory, {serviceId: service.id});

	return <div key="invoice-history" className="invoice-history">
		<h4>Invoice History: {service.friendlyId}</h4>
		<TabularDataSet dataSet={invoiceHistoryDataSet} defaultSort="date" defaultSortOrder="desc">
			<TabularColumn data={(row)=>row.date} title="Date"/>
			<TabularColumn data={(row)=>row.activity} title="Activity"/>
			<TabularColumn data={(row)=>row.after_description} title="Description"/>
			<TabularColumn data={(row)=>row.date_from} title="From"/>
			<TabularColumn data={(row)=>row.date_to} title="To"/>
			<TabularColumn data={(row)=>row.after_quantity} title="Quantity"/>
			<TabularColumn data={(row)=>row.unit_price} title="Unit Price"/>
			<TabularColumn data={(row)=>row.after_extended_price} title="Price"/>
			<TabularColumn data={(row)=>row.external_id} title="External ID"/>
		</TabularDataSet>
	</div>
}

export default InvoiceHistory;