import React, { Component, useState } from 'react';
import * as _ from 'lodash';
import templated from '@opidcore/components/Content/Types/Templated.jsx';
import moment from 'moment';

function TemplatedAssumed(props){
	return <span>{templated(props.content, props.row)}</span>
}

export default function MOETable_D3(props){
	const [givenData, setGivenData] = useState();
	
	const ds = APP.NDT.getData("audit-materials-used"); //(w)=>setGivenData(w));
	
    const data = [];   

    
    return <div className="moe-v-outer">
    <button onClick={ ()=>setGivenData( APP.NDT.getData("audit-materials-used") ) }>refresh</button>
    <h4>III. Plans to Reduce, Reuse and Recycle Waste</h4>
    
    

    <table key="moe_table" className="compact moe-table moe-v">
        <thead>
            <tr>
                <th colSpan="2">
                	For each category of waste described in Part V of “Report of a Waste Audit” (on which this plan is based), explain what your plans are to reduce, reuse, and recycle the waste, including: 1) how the waste will be source separated at the establishment, and 2) the programs to reduce, reuse, and recycle all source separated waste.
                </th>
            </tr>
      
        </thead>
        <tbody>
                { _.map(ds.rawData, (detail)=>{
                   return <tr key={detail.id}> 
	                   <td>{detail.moe_form}</td>
	                   <td><TemplatedAssumed row={detail} col="wtf" content="
	                   Reduce: No plans
	                   Reuse: No plans
	                   Recycle: Mixed recycling stream
	                   Customers and employees will be encouraged to use recycling bins to source separate {{material_type_nice_name}}. Bins have been provided in public spaces for customers, as well as in offices and staff areas for employees."/>
	                   </td>
                   </tr>
                }) }
        </tbody>
    </table>
    <p>...</p>
    </div>;
}

MOETable_D3.metaInfo = ({})=>{
		return {
			rendersChildren: true,
			showInEditorQuickAdd: false
		};
	};
    