import React, { useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useData } from '@opidcore/hooks/WTF';
import { BoundDataContext, InputSelect } from '@opidcore/components';
import * as _ from 'lodash';

/**     const ;
	const autoBilling = ;
	const invoiceType = ;
	const reportType = ;
 **/

const whatWeWillGetFromServer = [
	{ name: "clientStatus", options: { active: "Active", inactive: "Inactive" } },
	{ name: "autoBillSetting", options: { wait: "Wait for BA Review" } },
	{ name: "reportType", options: { separate: "Separate", combined: "Combined" } },
	{ name: "invoiceType", options: { separate: "Separate", combined: "Combined" } },
	{ name: "invoiceType", options: { separate: "Separate", combined: "Combined" } },
	{ name: "vendorRegion", options: ["National", "Ontario", "Alberta"] },
	{ name: "vendorType", options: ["Hauler", "Supplier", "Misc"] },

	{ name: "clientGroup", options: ["BGO", "Some Group"] },
	{ name: "accountingGroup", options: ["WSC"] },
	{ name: "industry", options: ["Retail", "Manufacturing", "Office", "Residential", "Restaurant"] },
	{ name: "industryDensity", options: ["Light", "Medium", "Heavy"] },
	{ name: "activity", options: ["Lift", "Admin Fee", "Haul", "Disposal"] },
	{ name: "aclResourceType", options: ["UI", "DB"] },

	{ name: "lineStatusAP", options: ["?", "OK"] },
	{ name: "lineStatusAR", options: ["?", "OK"] },
	{ name: "unitPricePer", options: ["month", "stop", "surcharge"] },
	{ name: "term", options: ["6", "12", "18", "24", "30", "36"] },

	{ name: "clientType", options: ["Managed", "Consulting"] },
	{ name: "priceCalculation", options: ["Client Savings %", "WSC Savings %", "Markup %", "---", "Shared Savings", "Flat Fee"] },
	{ name: "compensationType", options: ["Cost +", "Flat Fee", "Blended Savings"] },


	{ name: "changesToService", options: ["Yes", "No"] }




	/** {name: "", options: },
	{name: "", options: },**/

];

function Lookup(props) {
	const [list, listDS] = useData(props.what);
	let label = "name";

	const options = _.fromPairs(list.map((r) => [r.id, r.name]));

	return <div>
		{props.children(options)}
	</div>
}


export default function LookupInputSelect(props) {
	const bound = useContext(BoundDataContext);
	const what = props.what.split(":")[0] + ";lookup";
	let field = props.what.indexOf(":") > 0 ? props.what.split(":")[1] : props.field;

	//if it's a path use just the last bit;
	if (field.indexOf(".") > 0) {
		field = _.last(field.split("."));
	}

	let fetch = props.fetch;
	if (fetch != undefined && typeof fetch == "function" && (props.bound == undefined || props.bound == false)) { //props.bound is only because we have no way of knowing the fetch is a central request...
		fetch = props.fetch(bound);
	}

	const [list, listDS] = fetch ? useData(what, fetch, props.fetchOptions ? props.fetchOptions : undefined) : useData(what, { paginate: false });
	const [lookups, lookupsDS] = useData("lookup", { method: "fetchOptions" });

	let label = "name";

	let display = "name";
	if (props.display != undefined) {
		display = props.display;
	}

	let onChange = undefined;

	let options = {};
	let optionsCollection = null;
	let defaultV = props.defaultValue;

	if (props.what.indexOf("lookup") >= 0) {
		//local quick lookups

		let fieldLookups = [{}];
		let paramFiltered = false;

		if (props.parameter1 || props.parameter2) {
			paramFiltered = true;
			fieldLookups[0].name = field;
			fieldLookups[0].options = _.fromPairs(_.filter(list, (i) => {
				if (props.parameter1 && props.parameter2) {
					return i.name == field && i.parameter1 == props.parameter1 && i.parameter2 == props.parameter2;
				} else if (props.parameter1) {
					return i.name == field && i.parameter1 == props.parameter1;
				} else {
					return i.name == field && i.parameter2 == props.parameter2;
				}
			}).map((t) => [t.key, t.label]))
		}

		if (!paramFiltered) {
			fieldLookups = _.filter(lookups, (l) => {
				if (l.name != undefined && l.name.toLowerCase) {
					return l.name.toLowerCase() == field.toLowerCase()
				} else if (l.name == undefined) {
					console.log("why is lookup name undefined for ", props.what, " for field ", props.field);
				}

				return l.name == field;
			});
		}

		if (fieldLookups.length == 1) {
			options = fieldLookups[0].options;

			if (props.filter != undefined) {
				_.forEach(options, (optionValue, optionKey) => {
					if (props.filter({ key: optionKey, value: optionValue }) == false) {
						delete options[optionKey];
					}
				});
			}
		} else if (fieldLookups.length > 1) {
			console.log("Mistakes were made. fieldLookups should be 1 element. It has ", fieldLookups);
		}

		if (props.defaultValue != undefined && typeof props.defaultValue == "function") {
			defaultV = props.defaultValue(fieldLookups);
		}
	} else {
		//useData's list from the server
		const listItems = props.filter ? _.filter(list, props.filter) : list;
		if (props.store == "object") {
			optionsCollection = listItems;
		} else {
			options = _.fromPairs(listItems.map((r) => {
				let toDisplay = "";
				if (typeof display == "function") {
					toDisplay = display(r);
				} else {
					toDisplay = r[display];
				}

				let storeValue = r.id;

				if(props.store != undefined){
					storeValue = r[props.store];
				}

				return [storeValue, toDisplay];
			}))
		};

		if (props.defaultValue != undefined && typeof props.defaultValue == "function") {
			defaultV = props.defaultValue(listItems);
		}
	}

	if (props.store == "object") {
		onChange = (obj, field, value, context) => {
			let id = value;
			if (typeof id == "object") {
				id = id.id;
			}

			let val = "";

			if (id > 0 && list != undefined && list.length > 0) {
				val = list.find((r) => r.id == id);
			} else if (id == "") {
				val = undefined;
			}

			if (val != "") {
				if (context == undefined) {
					if (bound != undefined) {
						bound.magicalSet(field, val);
					} else {
						console.log("no context found so we cannot set fields magically");
					}
				} else {
					context.magicalSet(field, val);
				}
			}

			if (props.onChange) {
				props.onChange(bound.to, field, value, context);
			}

			return false;
		}
	} else if (props.onChange) {
		onChange = props.onChange;
		/** (obj, field, value, context)=>{
			const dataHash = {
					field: field,
					value: value,
					context: context,
					list: list
			};
			
			props.onChange(dataHash);
		}**/
	}

	return <InputSelect {...props} options={options} optionsCollection={optionsCollection} onChange={onChange} defaultValue={defaultV} />;
}

LookupInputSelect.propTypes = {
	componentName: PropTypes.string,
	componentType: PropTypes.string,
	/** Dataset to use for populating the select options.  It will be automatically fetched with useData. */
	what: PropTypes.string,
	/** display can be a string (showing that property), or a function (that will be passed the row). */
	display: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.func
	]),
	/** function used to limit the options */
	filter: PropTypes.func,
	field: PropTypes.string,
	name: PropTypes.string,
	fetch: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.func
	]),
	disabled: PropTypes.bool,
	showEmpty: PropTypes.bool,
	store: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
	title: PropTypes.string,
	fetchOptions: PropTypes.object,
	bound: PropTypes.bool,
	defaultValue: PropTypes.any,
	forcedDefault: PropTypes.bool,
	onChange: PropTypes.func,
	required: PropTypes.bool,
	parameter1: PropTypes.string,
	parameter2: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.any,
	labelExtra: PropTypes.any,
	appendable: PropTypes.bool,
	parent: PropTypes.string,
	emptyIsNull: PropTypes.bool
}

LookupInputSelect.defaultProps = {
	componentName: "LookupInputSelect",
	componentType: "BoundComponent",
	store: null,
	appendable: false
}