import React, { useState } from "react";
//import Searchable from "@opidcore/components/Searchable";
import ActionBar from "@opidcore/components/ActionBar";
import { 
  TabularColumn,  
  Icon,  
  Button, 
  AccessControl,
  Tabular,
} from "@opidcore/components";
import { useNavBar } from "@opidcore/hooks/WTF";
//import ExcelExportModal from "../../components/ExcelExportModal";
import EditClientContact from "./EditClientContact";
import FullTextSearchable from "../../components/FullTextSearchable";

export default function ClientContactList(props) {
  useNavBar("Client Contacts", []);
  //const [data, dataSet] = useData("customercontact", { paginate: 50 });
  const [clientContacts, setClientContacts] = useState([]);
  const [fetchClientContacts, setFetchClientContacts] = useState(false);

  const refreshContacts = () => {
    //dataSet.fetch();
    setFetchClientContacts(true);
  };

  const excelExport = () => {
    APP.alert("This feature needs to be reimplemented");
    // TODO MUST BE REIMPLEMENTED because we changed the way we bring in the data
    /*
    APP.central.CustomerContact.getDefaultExportLayout().then((r) => {
      APP.instance.createModal(<ExcelExportModal defaultLayout={r.result} dataSet={dataSet} model={"ClientContact"} />, { modal_name: "Excel Export" });
    });
    */
  };

  const handleClick = (row, e) => {
    const customerId = row.customer && row.customer.friendlyId ? row.customer.friendlyId : row.customerId;
    APP.redirect("/ui/clients/" + customerId);
  };

  const editContact = (e, contact) => {
    e.stopPropagation();
    APP.instance.createModal(
      <EditClientContact contactId={contact.id} />,
      { modal_name: "Edit Contact:" + contact.id },
      {
        afterClose: () => refreshContacts(),
      }
    );
  };

  const newContact = () => {
    APP.instance.createModal(
      <EditClientContact contactId={0} />,
      { modal_name: "New Contact" },
      {
        afterClose: () => refreshContacts(),
      }
    );
  };

  return (
    <div className="full-size">
      <ActionBar title="Actions">
        {/*<Searchable ds={dataSet} />*/}
        <FullTextSearchable model={"CustomerContact"} method={"reasonableList"} setData={setClientContacts} doFetchData={fetchClientContacts} setDoFetchData={setFetchClientContacts} />
        {/*<Pagination dataSet={dataSet} />*/}
        <AccessControl resource="Client Contact Exports">
          <Icon icon="file-excel" size="2x" title="Open export modal" onClick={() => excelExport()} />
        </AccessControl>
        <Button onClick={() => newContact()}><Icon color="white" icon="plus" /> {"\u00a0"}New Client Contact</Button>
      </ActionBar>

      {/*dataSet.loading ? <Loading /> : ""*/}
      {clientContacts.length == 0 ? "Search to show results." : null}

      {/*<TabularDataSet dataSet={dataSet} onClick={handleClick}>*/}
      <Tabular data={clientContacts} onClick={handleClick} pageSize={20} paginationLocation={"both"}>
        <TabularColumn
          data={(row) =>
            row.customer ? (
              <div>
                {row.customer_friendly_id}
                <br />
                {row.customer_name}
              </div>
            ) : (
              row.customer_id
            )
          }
          title="Client"
          sortCol="customer"
        />
        <TabularColumn
          data={(row) =>
            row.is_primary ? (
              <b>{"(Primary)"}<br/>{(row.last_name ? row.last_name : "-") + ", " + (row.first_name ? row.first_name : "-")}</b>
            ) : (
              (row.last_name ? row.last_name : "-") + ", " + (row.first_name ? row.first_name : "-")
            )
          }
          title="Name"
          sortCol="last_name"
        />
        <TabularColumn data={(row) => (row.is_primary ? <b>{row.title}</b> : row.title)} title="Title" sortCol="title" />
        <TabularColumn data={(row) => row.roles_string} title="Roles" sortCol="rolesString" />
        <TabularColumn data={(row) => row.email} title="Email" sortCol="email" />
        <TabularColumn data={(row) => row.phone} title="Phone" />
        <TabularColumn
          data={(row) => {
            return <Icon icon="edit" onClick={(e) => editContact(e, row)} size="1x" />;
          }}
          title="Edit in Modal"
        />
      </Tabular>
    </div>
  );
}
