import React, { Component } from 'react';

import {InputSelect} from '../../../components';

export default class SelectContainer extends Component{
    constructor(props) {
        super(props);
        
        if (props.NDT.whatIs(props.publishAs) == null){
            const params = SelectContainer.windowParams();
            const valueFromParam = params[ props.publishAs.toLowerCase() ];

        	this.props.NDT.announce(this.props.publishAs, valueFromParam);
        }
        
        this.state = {
            value:  props.NDT.whatIs(props.publishAs)
        };
    }
    
    static windowParams(){
    	var chunks = window.location.search.split('?');
        var readableParams = {};
        
        if (chunks.length > 1){
            var params = chunks[1].split("&");
            
            for(var x=0; x<params.length; x++){
                var theSplit = params[x].split('=');
                readableParams[theSplit[0]] = theSplit[1];
            }        
        }
        
        return readableParams;
    }
    
    static getEditableProps() {
    	
		var datasets = Object.keys(window.APP.NDT.datasets);
        return [
            {niceName: "Label", prop:"label", type:"text", default: ""},
            {niceName: "Data Set", prop: "dataset", type: "text", options: datasets},
            {niceName: "Announcement Name", prop:"publishAs", type:"text", default: ""},
        ]
    }
    
    update(e){
        this.setState({value: e.currentTarget.value}, ()=>{
            this.props.NDT.announce(this.props.publishAs, this.state.value);
        });

		if (APP.customize.listeners["SelectContainer"]){
            	var callback = APP.customize.listeners["SelectContainer"][this.props.root];
				callback && callback(this);
            }      
    }
    
    render(){
        const data = this.props.NDT.getData(this.props.dataset, this);
        let options = [];
        
        if(data != undefined){
            options = _.map(data.rawData, (row)=>{
                return <option key={row.id} value={row.id}>{row[this.props.label || "id"]}</option>;
            });
        }

        return <div className="audit_selector">
            <select onChange={(e)=>this.update(e)} value={this.state.value}>
                <option key={null} val=""></option>
                {options}
            </select>
        </div>;
    }
}