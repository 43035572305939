import React, { Component, useCallback } from 'react';
import ReactDOM from 'react-dom';

class Empty extends Component {
	
	render(){
		return <div>{this.props.children}</div>;
	}
}

export default class Loop extends Component{
    constructor(props) {
        super(props);
        
        this.state = {};
        
    }
    
    static injectScopeToChildren(children, props){        
        
    	return React.Children.map(children,  (child)=>{
    		var thisProps = {...child.props};
    		thisProps = {...thisProps, ...props};
    		const meta = {...thisProps.metaInfo};
    		meta._loopIndex = props._loopIndex;
    		
			const newScope = APP.NDT.whatIsMyScope(thisProps.root, meta);
			
			thisProps.scope ={...thisProps.scope, ...newScope}; 

			const res = React.cloneElement( child, thisProps, [] );
			const uid = thisProps.root + "-loop-" + thisProps._loopIndex;
			
			return res;
		});
    	
    }
    
  	static getEditableProps(){
        
        return [
            {niceName: "Data Set", prop: "dataset", type: "dataset", allowJS:true},
            {niceName: "Sort By", prop: "sortby", type: "dataset", allowJS:true},
            {niceName: "Class Name", prop: "className", type: "text"},
            {niceName: "Variable Name", prop: "as", type: "text"}
        ]
        
    }

	static metaInfo() {
		var mandatoryChildren = ["Container"];
		
		return {mandatoryChildren: mandatoryChildren, canHaveChildren: true, showInEditorQuickAdd: true};
	}

    render(){
    	let data = [];
    	let hashedData = null;
    	
    	if(typeof this.props.dataset == "string"){
    	    data = this.props.NDT.getData(this.props.dataset).filter();
    	} else if(Array.isArray(this.props.dataset)){
    	    data = this.props.dataset;
    	} else if (_.keys(this.props.dataset).length > 0){
    		hashedData = _.cloneDeep(this.props.dataset);
    		data = _.keys(this.props.dataset);
    	}else{
    	    console.log("We seem to be directing focus here. There was once a debugger in this spot.");
    	}
    	
    	let keys = _.keys(data);
    	
    	if (this.props.sortby){
    		//we have to do the weird xxx thing until @ian becomes smarter
    		data = data.map( this.props.sortby ).sort().map( (r)=>r.replace(/.*xxx /g,""));    		
    	}
    	
    	var style = {};
    	
    	var children = _.map( data, (r,x)=>{
    				var props = {};
    				props.scope = {...this.props.scope};
    				if (this.props.as && this.props.as.indexOf("|") > 0){
    					const [keyName, valName] = this.props.as.split('|');
    					props.scope[keyName] = r;
    					props.scope[valName] = hashedData[r];
    				}else{
    					props.scope[this.props.as || "row"] = r;
    				}
    	
    				props.scope.a = "yes";
    	    		props._loopIndex = x;
    	    		
    	    		props.key = "loop-" + props._loopIndex;

    	    		return Loop.injectScopeToChildren( this.props.children, props);
    		});
    	
    	if (this.props.className){
    		return <div className={this.props.className}>{children}</div>;
    	}else{
    		return <>{children}</>;
    	}
    }
}