import React, { Component } from 'react';
import BoundComponent from '../../BoundComponent';
import { DataContext } from '../../Bound';
import { Icon, Loading } from '@opidcore/components';

export default class FileBrowser extends BoundComponent { 

    
    static contextType = DataContext;
    
    constructor(props,context) {
        super(props,context);
        
        this.state = {files:[], changeMode:false, uploadBlock:false,hideBtns:true, uploadInProgress: false};
    }
    
    getFiles(dataDef){
        APP.central.StaticFile.getFiles(dataDef,this.props.fileForm).then((d)=>{
            this.setState({files:d})
        });
    }
    
    
    handleClickFile(e,f){
        this.context.magicalSet(this.props.field, f);
        this.setState({changeMode:false});
    }

    handleDelete(e, f){
        debugger;
    }
    
    _handleFileChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {           
          this.setState({
            file: file,
            filePreviewUrl: reader.result
          });
        }

        reader.readAsDataURL(file)
      }
    
    _handleSubmit(e) {
        e.preventDefault();
        let file = this.state.file;
        let filename = file.name.split(".");
        
        const fileData = {
            fileType: filename[1],
            contents: this.state.filePreviewUrl,
            lastModified: file.lastModified,
            instance_key: this.props.wtf ? this.props.wtf.id : null,
            allowToBrowse: this.props.allowToBrowse,
            form: this.props.fileForm,
            field: this.props.field
        };

        this.setState({uploadInProgress: true}, ()=>{
            APP.central.StaticFile.upload(fileData).then(r=>{
                this.context.magicalSet(this.props.field, r.result); 

                this.setState({uploadInProgress: false}, ()=>{
                    APP.instance.openConfirmDialog("Upload successful.");
                });
            });
            
            this.getFiles();
        });
      }
    
    onClickChange(){
        this.getFiles(this.props.field ? this.props.field : "files");
        this.state.changeMode ? this.setState({changeMode:false}) : this.setState({changeMode:true});
    }
    
    onClickUpload(){
        if(this.props.allowToBrowse){
            this.getFiles(this.props.field ? this.props.field : "files");
        }
        this.state.uploadBlock ? this.setState({uploadBlock:false}) : this.setState({uploadBlock:true});
    }
    
    hideBtns(){
        this.setState({hideBtns: !this.state.hideBtns});
    }
    
    render(){
        let currentOpidFile = this.context.magicalGet(this.props.field, null);

        let currentFile = null;
        
        let changeBtn = null;
        let uploadBtn = null;
        let fileUpload = this.state.uploadBlock || currentOpidFile == undefined? (
                <form onSubmit={(e)=>this._handleSubmit(e)}>
                    <input className="fileInput form-control" type="file" accept={this.props.type == "image" ? "image/*;capture=camera" : "*" } onChange={(e)=>this._handleFileChange(e)} />
                    <button className="submitButton btn btn-success" type="submit" onClick={(e)=>this._handleSubmit(e)}>Upload</button>
                </form>
        ) : null;
        
        
        if(currentOpidFile){
            currentFile = <img src={currentOpidFile.url} width="30%" height="30%" onClick={this.props.allowToBrowse ? ()=>this.hideBtns() : ()=>this.onClickUpload()} />;
        }        
        
        if(!this.state.hideBtns){
            changeBtn = this.props.allowToBrowse ? <button onClick={()=>this.onClickChange()} >Change</button> : null;
            uploadBtn = this.props.allowToBrowse ? <button onClick={()=>this.onClickUpload()} >Upload</button> : null;
        }
        
        let files = [];
        if(this.state.changeMode){
            _.forEach(this.state.files,(f)=>{
                let fileUrl = f.url;
                files.push(fileUrl != null ? (<FileChunk f={f} fileUrl={fileUrl} handleClickFile={this.handleClickFile} handleDelete={this.handleDelete}/>) : "file not found");
            });
        }

        let loadingStatus = null;
        if(this.state.uploadInProgress){
            loadingStatus = <Loading />;
        }
        
        return (
                <div> 
                    {currentFile}
                    {changeBtn}
                    {uploadBtn}
                    {loadingStatus}
                    <div>
                    {files}
                    </div>
                    {fileUpload}
               </div>
                
        )
    }
}

const FileChunk = ({fileUrl, handleClickFile, handleDelete, f}) =>{
    return <span key={f.id}>
        <img src={fileUrl} onClick={(e)=>handleClickFile(e,f)} alt="image not found" width="20%" height="20%" />
        <Icon icon="trash" onClick={(e)=>handleDelete(f)} />
    </span>
}