import React from 'react';

export const CustomizeContext = React.createContext(
		  {"error_no_data": "Read the documentation on how to use opidcore's customize content!"}
		);

export class Something {
    constructor(){
        this.listeners = {};
        
        this._forms = {};
        this._customComponents = {};
        
    }
    
    on(namespace, what, doWhat, immediate){
        if (this.listeners[namespace] == null){
            this.listeners[namespace] = {};
        }

        if(this.listeners[namespace][what] == undefined){
            this.listeners[namespace][what] = [];
        }
        
        this.listeners[namespace][what].push(doWhat);   
        
        if (immediate && namespace == "BoundMagics"){
            if (APP.registeredBoundMagics[what]){
                doWhat(APP.registeredBoundMagics[what]);
            }
        }
    }
    
    get(namespace, what){
        if (this.listeners[namespace]){
            return this.listeners[namespace][what];    
        }
        
        
    }
    
    getForm(form){
    	return this._forms[form];
    }
    
    saveAll(){
    	var promises = _.map(this._forms, (form,uid)=>{
    		return form.formActions.save();
    	});
    	//eventually we should received promises we can check status on    	
    	
    }
    
    customSectionComponent(namespace, section,component){
        this._customComponents[ namespace + ":" + section ] = { namespace: namespace, section:section, generateComponents: component };
    }

    getCustomSectionComponent(namespace, section){
        return this._customComponents[ namespace + ":" + section ];
    }

    closingForm(form, uid, instance){    	
    	
    	delete this._forms[uid];
    }
    
    setCurrentForm(form, uid, instance){
        this.form = {...form};
        this.currentForm = uid;
        
        if (instance){       	
        	
        	this._forms[uid] = {};
        	this._forms[uid].formActions = instance._generateActions();
        }
        
        this._forms[uid].form = {...form};
    }
    
    
    
}