import React, { Component, PropTypes } from 'react';
import BoundComponent from './BoundComponent';
import Bound from './Bound';
import InputMultiSearch from './InputMultiSearch';
import * as _ from 'lodash';

import * as CentralActions from '../actions/CentralActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class InputMultiPeopleSearch extends InputMultiSearch {
	labelFormatter(row){
		if(row.lastName == undefined){
			return row.firstName || (row.name != null ? "Group: " + row.name : "");
		}
        if(row != undefined){
        	return row.lastName + ", " + row.firstName;
        } else {
        	return "";
        }
    }
	
	keyFormatter(row){
        if ( row.employeeId ){
        	return "E" + row.employeeId;
        }else{
        	return "G" + row.id;
        }
    }

	search(term){
		this.props.central.Employee.search(term);
		if(this.props.includeGroups == true){
			this.props.central.ActiveDirectory.search(term);
		}
	}
	
	componentWillReceiveProps(nextProps){
		
		let nextState = {... this.state};
		
		if( nextProps.obj.state[nextProps.field] != null ){
			nextState.selectedOptions = nextProps.obj.state[nextProps.field];
		}
		
		/** if(nextProps.groupResults != undefined){
			nextProps.groupResults.map(function(group){
					var fakeEmployee = new Object();
					fakeEmployee.employeeId = group.id * -1;
					fakeEmployee.firstName = group.name;
					fakeEmployee.email = group.email;
					
					nextProps.results.push(fakeEmployee);
			});
		}**/
		
		const groups = [];
		
		
		
		nextState.remainingOptions = groups.concat(nextProps.groupResults).concat(_.filter(nextProps.results, (option) => {
			let found = true;
			
			for(var i=0; i<nextState.selectedOptions.length; i++){
				if(nextState.selectedOptions[i].employeeId == option.employeeId){
					found = false;
					break;
				}
			}
			
			return found;
		}));
		
		this.setState(nextState);
	}
}

function mapStateToProps(state) {
    return {
    	results: state.central.employeeSearch || [],
    	employees: state.central.employees,
    	groupResults: state.central.ADgroups || []
    };
}

function mapDispatchToProps(dispatch) {
	return {
		...CentralActions.modifyProps(dispatch),  
		actions: bindActionCreators({...CentralActions}, dispatch),
	};
}
      
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InputMultiPeopleSearch);