import React from "react";
import { Link } from "react-router-dom";
import { useNavBar } from "@opidcore/hooks/WTF";
import { Selection, Tabular, TabularColumn } from "@opidcore/components/Tabular";
import ActionBar from "@opidcore/components/ActionBar";
import FullTextSearchable from "../../components/FullTextSearchable";
import Icon from "@opidcore/components/Icon";
// import CesarFilter from "../../components/CesarFilter";
import { useState } from "react";
import { Button } from "@opidcore/components";
import _ from "lodash";
import SimplePDFPreview from "../../components/SimplePDFPreview";

export default function ListAPInvoice(props) {
  useNavBar("AP Invoices", [], { menu: ["ap"] });

  const [apInvoices, setApInvoices] = useState([]);
  const [fetchAPInvoices, setFetchAPInvoices] = useState(false);

  //const [data, dataSet] = useData("apinvoice;all_apinvoices", {method: "reasonableList", search: searchQuery, paginate: 50});
  const [selection, setSelection] = useState([]);

  const doDelete = (row, e) => {
    e.stopPropagation();

    APP.central.APInvoice.findSameSourceARs(row.id).then((r) => {
      let ARIDs = "";
      if (r.result && r.result.length > 0) {
        ARIDs =
          " This will also delete ARs: " +
          _.join(
            _.sortBy(
              _.uniq(
                _.map(r.result, (ar) => {
                  return ar.id;
                })
              )
            ),
            ", "
          );
      }
      APP.confirm("Are you sure?" + ARIDs, (res) => {
        if (res == true) {
          APP.central.APInvoice.deleteAPandARs(row.id).then((r) => {
            setSelection([]);
            //remove the row from the screen
            //dataSet.filter((a)=>_.indexOf([row.id], a.id) == -1, true);
            setFetchAPInvoices(true);
          });
        }
      });
    });
  };

  const handleClick = (row, e) => {
    if (e.defaultPrevented == false) {
      APP.redirect("/ui/apinvoice/" + row.id);
    }
  };

  const deleteAllSelected = () => {
    const ids = selection.map((row) => row.id).join(",");

    APP.central.APInvoice.findSameSourceARs(ids).then((r) => {
      let ARIDs = "";
      if (r.result && r.result.length > 0) {
        ARIDs =
          " This will also delete ARs: " +
          _.join(
            _.sortBy(
              _.uniq(
                _.map(r.result, (ar) => {
                  return ar.id;
                })
              )
            ),
            ", "
          );
      }
      APP.confirm("Are you sure you want to delete these?" + ARIDs, (res) => {
        if (res == true) {
          APP.central.APInvoice.deleteAPandARs(ids).then((r) => {
            setSelection([]);
            //dataSet.fetch();
            setFetchAPInvoices(true);
          });
        }
      });
    });
  };

  const openPDFPreview = (row, e) => {
    e.stopPropagation();
    APP.central.APInvoice.downloadVendorInvoice(row.id).then((r) => {
      if (r.result != undefined) {
        const file = r.result;
        APP.instance.createModal(<SimplePDFPreview file={file} />, { modal_name: "PDF:" + file.filename });
      } else {
        APP.alert("No Vendor Invoice found.");
      }
    });
  };

  return (
    <div key="list_vendors">
      <ActionBar title="Actions">
        <FullTextSearchable
          model={"APInvoice"}
          method={"reasonableList"}
          setData={setApInvoices}
          doFetchData={fetchAPInvoices}
          setDoFetchData={setFetchAPInvoices}
          filterOptions={{
            columns: [
              { column: "vendor_name", heading: "Vendor" },
              { column: "umbrella" },
              { column: "date_received" },
              { column: "status", lookup: "AP Invoice Status" },
              { column: "xero_status" },
            ],
          }}
        />
        {/*<Pagination dataSet={dataSet} />*/}
        <Link to={"/ui/snowman/payables"}>Payables Process</Link>
        <Link to={"/ui/apinvoice/status_list"}>Billing Status</Link>
        {selection.length > 0 ? <Button onClick={() => deleteAllSelected()}>Delete {selection.length} APs</Button> : null}
      </ActionBar>

      {/*<CesarFilter
        columns={[
          { column: "vendor_name", heading: "Vendor" }, 
          { column: "umbrella" }, 
          { column: "date_received" }, 
          { column: "status", lookup: "AP Invoice Status"},
          { column: "xero_status"}
        ]}
        dataSet={dataSet}
      />*/}

      {/*dataSet.loading ? "loading" : ""*/}
      {apInvoices.length == 0 ? "Search to show results." : null}

      {
        <Tabular data={apInvoices} onClick={handleClick} pageSize={20} paginationLocation={"both"}>
          <TabularColumn
            data={(row) => (
              <>
                {" "}
                <Selection selection={selection} data={apInvoices} setter={setSelection} item={row} label={(row) => ""} /> {row.external_invoice_number}{" "}
              </>
            )}
            title="Inv #"
            sortCol="externalInvoiceNumber"
          />
          <TabularColumn data={(row) => row.vendor_name} title="Vendor" />
          <TabularColumn data={(row) => row.external_account_number} title="Account Number" />
          <TabularColumn data={(row) => row.vendor_site_name} title="Vendor Site" />
          <TabularColumn
            data={(row) =>
              row.customer_names != undefined ? (
                <Truncated splitOn="|" maxCharacterLength={20}>
                  {row.customer_names}
                </Truncated>
              ) : null
            }
            title="Waste Metric Customer(s)"
          />
          <TabularColumn
            data={(row) =>
              row.site_names != undefined ? (
                <Truncated splitOn="|" maxCharacterLength={20}>
                  {row.site_names}
                </Truncated>
              ) : null
            }
            title="Waste Metric Site(s)"
          />
          <TabularColumn data={(row) => row.invoice_date} title="Inv. Date" sortCol="invoice_date" />
          <TabularColumn data={(row) => row.date_received} title="Received" sortCol="date_received" />
          <TabularColumn data={(row) => row.service_period} title="Service Period" sortCol="service_period" />
          <TabularColumn data={(row) => row.billing_period} title="Billing Period" sortCol="billing_period" />
          <TabularColumn data={(row) => row.total} title="Total" sortCol="total" />
          <TabularColumn data={(row) => row.status} title="Status" sortCol="status" />
          <TabularColumn data={(row) => row.xero_status} title="Xero Status" sortCol="xero_status" />
          <TabularColumn
            data={(row) => {
              return <Icon className="action" icon="file-invoice-dollar" size="2x" onClick={(e) => openPDFPreview(row, e)} />;
            }}
          />
          <TabularColumn
            data={(row) => {
              return (
                <Link onClick={(e) => e.stopPropagation()} to={"/ui/snowman/payables/" + row.id}>
                  <Icon icon="line-columns" size="2x" title="Activity Matching" />
                </Link>
              );
            }}
          />
          <TabularColumn
            data={(row) => {
              return (
                <Link onClick={(e) => e.stopPropagation()} to={"/ui/apinvoice/" + row.id}>
                  <Icon icon="pencil" size="2x" title="Edit AP Invoice" />
                </Link>
              );
            }}
          />
          <TabularColumn
            data={(row) => {
              return <Icon onClick={(e) => doDelete(row, e)} icon="trash" size="2x" />;
            }}
          />
        </Tabular>
      }

      {/*<TabularDataSet dataSet={dataSet} onClick={handleClick} defaultSort="id" defaultSortOrder="asc" >
        <TabularColumn data={(row) => <> <Selection selection={selection} data={data} setter={setSelection} item={row} label={(row)=>""} /> {row.external_invoice_number} </> } title="Inv #" sortCol="externalInvoiceNumber" />
        <TabularColumn data={(row) => row.vendor_name} title="Vendor" />
        <TabularColumn data={(row) => row.external_account_number} title="Account Number" />
        <TabularColumn data={(row) => row.vendor_site_name} title="Vendor Site" />
        <TabularColumn data={(row) => row.customer_names != undefined? <Truncated splitOn="|" maxCharacterLength={20}>{row.customer_names}</Truncated> : null} title="Waste Metric Customer(s)" />
        <TabularColumn data={(row) => row.site_names != undefined? <Truncated splitOn="|" maxCharacterLength={20}>{row.site_names}</Truncated> : null} title="Waste Metric Site(s)" />
        <TabularColumn data={(row) => row.invoice_date} title="Inv. Date" sortCol="invoice_date" />
        <TabularColumn data={(row) => row.date_received} title="Received" sortCol="date_received"/>
        <TabularColumn data={(row) => row.service_period} title="Service Period" sortCol="service_period"/>
        <TabularColumn data={(row) => row.billing_period} title="Billing Period" sortCol="billing_period"/>
        <TabularColumn data={(row) => row.total} title="Total" sortCol="total"/>
        <TabularColumn data={(row) => row.status} title="Status" sortCol="status"/>
        <TabularColumn data={(row) => row.xero_status} title="Xero Status" sortCol="xero_status"/>
        <TabularColumn 
          data={(row) => {
            return (
              <Icon className="action" icon="file-invoice-dollar" size="2x" onClick={(e) => openPDFPreview(row, e)}/>
            );
          }}
        />
        <TabularColumn
          data={(row) => {
            return (
              <Link onClick={(e)=>e.stopPropagation()} to={"/ui/snowman/payables/" + row.id}>
                <Icon icon="line-columns" size="2x" title="Activity Matching" />
              </Link>
            );
          }}
        />
        <TabularColumn
          data={(row) => {
            return (
              <Link onClick={(e)=>e.stopPropagation()} to={"/ui/apinvoice/" + row.id}>
                <Icon icon="pencil" size="2x" title="Edit AP Invoice" />
              </Link>
            );
          }}
        />
      <TabularColumn
          data={(row) => {
            return (
                <Icon onClick={(e)=>doDelete(row,e)} icon="trash" size="2x" />
            );
          }}
        />
        </TabularDataSet>*/}
    </div>
  );
}

export const Truncated = ({ children, splitOn = ",", maxCharacterLength = 10 }) => {
  const splitStrings = children == null ? [] : children.split(splitOn);
  const resArray = [];
  let currentLength = 0;
  _.forEach(splitStrings, (s) => {
    if (currentLength < maxCharacterLength) {
      currentLength += s.length;
      resArray.push(s);
    }
  });

  let res = resArray.join(", ");
  let title = null;
  if (resArray.length < splitStrings.length) {
    res += "...";
    title = splitStrings.join(", ");
  }

  return <span title={title}>{res}</span>;
};
