import React, { Component, PropTypes } from 'react';

export default class VerticalLabel extends Component {
	
	static propTypes = {
	};

	constructor(props, context) {
		super(props, context);
	
	    this.state = {};
	    
	}
	  
	render(){
		let height = this.props.height;
		
		var lines = [];
		
		if (this.props.headerText2 != null && this.props.headerText2.indexOf("|") > 0){
			var x = 0;
			var chunks = this.props.headerText2.split("|");
			
			lines.push(<text key="1" y="30" textAnchor="middle" fontWeight="normal" fontFamily="'Arial', sans-serif" fontSize="12" transform={"rotate(-90, 100, 100)"} className={this.props.className}>
		  	<tspan x="50" dy="1.2em">{chunks[0]}</tspan>
		  </text>);
			
			lines.push(<text key="2" y="30" textAnchor="middle" fontWeight="normal" fontFamily="'Arial', sans-serif" fontSize="12" transform={"rotate(-90, 100, 100)"} className={this.props.className}>
		  	<tspan x="50" dy="2.4em">{chunks[1]}</tspan>
		  </text>);
		}else{
		  lines.push(<text key="1" y="30" textAnchor="middle" fontWeight="normal" fontFamily="'Arial', sans-serif" fontSize="12" transform={"rotate(-90, 100, 100)"} className={this.props.className}>
		  	<tspan x="50" dy="1.2em">{this.props.headerText2}</tspan>
		  </text>);
		}
		
	  
		return (
			<svg width="60" height={height} viewBox={"0 0 60 " + height} id="vertical_label" preserveAspectRatio="xMidYMid">
			  <text y="30" key="0" textAnchor="middle" fontFamily="'Arial', sans-serif" fontSize="14" transform={"rotate(-90, 100, 100)"} className={this.props.className}>
			  	<tspan x="50" dy="0em">{this.props.headerText1}</tspan>
			  </text>
			  {lines}
			</svg>
		);
	}
}