import React, {Component} from 'react';
import { Data } from '../../../Data.js';
import * as _ from 'lodash';

import Functioner from '../../../Functioner.js';
 
class DataSet extends Component {
	constructor(props) {
		super(props);
		//debugger;
		this.refresh = _.debounce(this._refresh, 2000);
	}
	
	componentDidUpdate(prevProps) {
		if (this.myData != null && ! _.isEqual(this.myData.args, this.props.args)  ){
			this.myData._refresh = true;			
			this.createFetch();
			this.myData.fetch(); 

		}
		this._refresh();
	}
	
	createFetch(){	

		this.myData.args = this.props.args;
		var thing = this.props.rootStructure;
		var that = this;
		
		this.myData.fetch = function(){
			
    		if (thing.theId == "dv-test1"){
    			// this should be banished until we figure it out. TODO @ian
    			var args = { cid: thing.theId, params: that.props.scope.params};
    			
    			APP.central.Playground.flowerBouqet(args).then(j=>{
    				
    				if(j != undefined){
                		this.reset(j.result.rows);
                	} else {
                		this.reset();
            		}
    				
					return this;
					
    			}).then(res=>{
					that.props.NDT.postData(thing.theId, res, that);
    			});
    			
    		}else{
    		    let thingOptions = thing._options;
    		    if(thingOptions == undefined){
    		        thingOptions = thing.options;
    		    }
    			const params = {...that.props.scope.params, ...thingOptions.args};
    			// TODO @jack/@ian this is where we would send the new "filterset/datafilter" filters to the server, probably need to move createFetch into NDT.
    				
				if (_.values(params).indexOf("cheese") >= 0){
					
					setTimeout(()=>that.refreshArgs(), 1000);
					return;
				}
    			
			APP.central.Stuff.flower({cid: thing.theId, json: thing, announcements: that.props.NDT.announcements, params: params}).then(j=>{
        		if(j != undefined){
            		this.reset(j.result);
            	} else {
            		this.reset();
        		}
				return this;
			}).then(res=>{
				if (thing){
					that.props.NDT.postData(thing.theId, res, that);
				}
				if (that.props.args && that.props.args.query == "single"){
					let singleRow = res.origData[0];
					//@TODO @IAN this is not good but not sure what we are doing with it long term.
					if (window.WASTE_AUDIT && window.WASTE_AUDIT.prepSingle){
						singleRow = window.WASTE_AUDIT.prepSingle(singleRow);
					}
					that.props.announceScope(that.props.args.form,singleRow);
				}
			});
    		}
		};
		
	}
	refreshArgs(){
		this.myData._refresh = true;
		this._refresh();
	}
	_refresh(){
		if(Data && (this.myData == null || this.myData._refresh) ) {
			if (this.myData){
				delete this.myData._refresh;
			}
			

			var thing = this.props.NDT.find(this.props.root);
			if (thing == null){//it's not in structure because we are moving between pages I think.
				return;
			}
			
			this.myData = this.props.NDT.getData( this.props.root );
			var that = this;

			//should do this for the top level too many, or pull it outsomehwere more generic @ian todo
			if (thing.options && thing.options.args){
				thing._options = {};
				thing._options.args = {...thing.options.args}; 
				_.each( _.keys(thing._options.args), (propKey)=>{
	            	if (Functioner.looksLikeIt( thing._options.args[propKey] )){
	            		var func = new Functioner( thing._options.args[propKey] );
	            		thing._options.args[propKey] = func.doEval(that.props);
	            	}
	            }); 
			}
			
        	this.createFetch();
        	
    		this.myData.fetch(); 

		}   

	}
	
	static metaInfo(thing) {
		return {inContent: false, category: "data", icon: "database", preview: (r)=>{ return "x rows" } };
	}
	
	static getEditableProps(thing) {

		var myId = window.editingUID;
		
		var myStructure = _.find(window.APP.NDT.structure, function(datum) {return datum.cid == myId});

		
		//instead of hardcoding the queries/sections, get them from the backend later.
		//window.APP.central.Stuff.datasetHints(myStructure);
		return [
			{niceName: "Form", prop: "args.form", type: "text", options: _.map(window.APP.forms, function(datum) {return datum.uid})},
			{niceName: "Query", prop: "args.query", type: "text", options: ["list_instances", "find_related", "testData", "custom_sql"]},
			{niceName: "Custom SQL File", prop: "args.sql", type: "text", default: ""},
			{niceName: "Where", prop: "args.where", type: "text", default: "", allowJS: true},
			{niceName: "Search Value (TODO show only for find_related)", prop: "args.search_value", type: "text", default: "", allowJS: true},

			
			{niceName: "Section", prop: "args.section", type: "text", options: (current)=>{
				
				var form = _.find(window.APP.forms, f=>f.uid == current.args.form);
				var sections = form != null ? form.sections.map( s=>s.uid ) : [];		
				return sections;
			} }
		];
	}
	
    render(){
        return <></>;
    }
}

export default DataSet;