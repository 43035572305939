import React from 'react';
//import templated from '@opidcore/components/Content/Types/Templated.jsx';

function TemplatedAssumed(props){
    //return <span>{templated(props.content, props.row)}</span>;
	return <span>{props.content}</span>;
}

function roundMe(x){
	if (typeof x == "string"){
		if (x.indexOf("%") >= 0){
			return x;
		}
		x = parseFloat(x);
	}
	const value = x != null && isNaN(x) == false ? x.toFixed(2) : (0).toFixed(2);
    const chunks = value.split('.');

    if (parseInt(chunks[0]) == 0){
       return '-';
    }

    return parseInt(chunks[0]).toLocaleString() + '.' + (chunks[1] || '00');

}

function guessStream( row ){
	const stream = _.first(APP.NDT.datasets['ds-streams'].filter({"id": row.stream}));
	let guess_stream = stream.name.toLowerCase() || "Mixed recycling";
	guess_stream  = guess_stream[0].toUpperCase() + guess_stream.substring(1);
	
	if (stream.final_destination == "Landfill"){
		return guess_stream;
	}
	
	if (guess_stream.indexOf("stream") >= 0){
		return guess_stream;
	}
	
	return guess_stream + " stream";

}

export default function MOEChunk(props){
    let content = "Table Not Defined " + props.table + " " + props.column;
    let material_name = props.row.material_name;
    
    if (material_name == null){
    	material_name = props.row.category_summary
    }
    
    if (material_name == null){
    	return "";
    }
    
    if(props.table == "Management of Waste"){
        if(props.column == "3"){
            if(props.row.waste_type == "Recyclable Material"){
                content = "The uncontaminated portion is source separated for diversion and is placed in a bin for recycling.";
            } else {
                content = "Not applicable.";
            }
        } else if(props.column == "2"){
            if(props.row.waste_type == "Recyclable Material"){
                content = "Any contaminated portion is handled as mixed waste and is placed in a bin for disposal.";
            } else {
                content = "This material is handled as mixed waste and is placed in a bin for disposal.";
            }
        } else {
            content = "Missing";
        }
    }
    
    
    if (props.table == "How is waste produced"){
    	content = "Waste is generated when materials are brought to the site by customers and employees; when materials are purchased by tenants for daily operations; and from the packaging associated with the purchasing of these materials.";
    	//content += props.row.waste_type + " "; 
    	
    	if (props.row.waste_type == "Organic Material" || props.row.waste_type == "Liquid Waste" ){
    		content = "Waste is generated when materials are brought to the site by employees and when materials are purchased for daily operations.";
    	}
    	
    	if (props.row.material_name != undefined && (props.row.material_name.indexOf("Paper Towel") >= 0 || props.row.material_name.indexOf("Masks") >= 0 || props.row.material_name.indexOf("PPE") >= 0 )){
    		content = "Waste is generated when materials are purchased for daily operations.";
    	}
    	//debugger;
    }
    
    if (props.table == "Plan To"){
    	
    	if (material_name.match(/.*([A-Z][A-Z]|\#).*/) == null ){
    		material_name = material_name.toLowerCase();
    	}
    	var stream = guessStream( props.row );
    	if (stream && stream.name == "Landfill"){
    		content = "No plans to reduce, reuse, or recycle " + material_name;
    	}else{
    		const bstream = _.first(APP.NDT.datasets['ds-streams'].filter({"id": props.row.stream}));
    		const bin_guess = bstream.name.toLowerCase();
    		
    		content = <>
        	Reduce: No plans<br/>
        	Reuse: No plans<br/>
        	Recycle: {stream} stream<br/>
        	Customers and employees will be encouraged to use {bin_guess} bins to source separate {material_name}. Bins have been provided in public spaces for customers, as well as in offices and staff areas for employees.
        	</>;
    	}
    	
    	
    	
    }
    
    if (props.table == "estimate"){
    	content = props.column;
    	
    	if (props.column == 1){
    		content = APP.NDT.announcements.materialTotalGeneratedAnnual ? roundMe(APP.NDT.announcements.materialTotalGeneratedAnnual[ props.row.material_name ]) : null;
    	}
    	
    	if (props.column == 2){
        	content = guessStream(props.row);
    	}
    	if (props.column == 3){
    		content = "";
    	}
    	if (props.column == 4){
    		content = "0.00";
    	}
    	
    	if (props.column >= 5){
	    	let generated = APP.NDT.announcements.materialsGeneratedAndDiverted ? APP.NDT.announcements.materialsGeneratedAndDiverted[ props.row.material_name ] : null;
			if (generated == null){
				generated = {"Not Diverted": 0};
				generated[props.row.material_name] = 0;
			}
			content = "-";
		   	if (props.column == 5){
    			content = roundMe(generated[props.row.material_name]);
	    	}
	    	
	    	if (props.column == 6){
	    		content = "N/A";
	    		
	    		if ( props.row.moe_estimated_waste_method > "" && props.row.moe_estimated_waste_method != "Percent" ){
	    			content = props.row.moe_estimated_waste_method;
	    		}else{
	    			content = "0.00%";
	    			
	    			if (_.sum( _.values( generated ) ) > 0){
	    				content = roundMe(100 * generated[props.row.material_name] / _.sum( _.values( generated ) )) + "%";
	    			}
	    		}	    		
	    	}
    	}
    	
    }
    
    const templatedContent = <TemplatedAssumed row={props.row} content={content}/>
    
    return <div key="moe_chunk" className="moe_chunk">
        {templatedContent}
    </div>
}