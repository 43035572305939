import React, { useState, useEffect, useRef, useContext } from 'react';
import BoundComponent from './BoundComponent';
import { DataContext } from './Bound';
import { useBoundEffect } from '@opidcore/hooks/WTF';
import RenderMeSoftly  from './Content/RenderMeSoftly.jsx';

function saveToServer(){
    var saves = [];

    _.forEach(APP.NDT.structure, function(thing) {
            if (thing.__save_required){
                saves.push( APP.central.ContentStuff.editComponent(thing) );
            }
        });
    
    Promise.all(saves).then((r)=>{
        alert("Saved!!!!");
        //this.props.updateContentStructure(this.state.tempContentStructure);
    });

}

function defaultStructure(id){
    return [{
        children: null,
        cid: null,
        component: "Container",
        connections: [],
        connectors: null,
        next: null,
        options: {},
        returns: null,
        theId: id,
        type: "ReactThing",
        uid: id,
        __save_required: true
    }];
}

export default function InputRMS(props){
    const bound = useContext(DataContext);
   
    useBoundEffect(bound, ()=>{
        console.log("bound effect has", bound.to && bound.to.__modified, bound);    
        
        let theBoundId = bound.magicalGet(props.fieldUid, null);
        
        if(theBoundId === null){
            const afterFetch = (r)=>{
                bound.magicalSet(props.fieldUid, r.result);
                bound.magicalSet(props.field, defaultStructure(r.result))
            }
            APP.NDT.generateId(afterFetch);
        }
    });
    
    const theId = bound.magicalGet(props.fieldUid, null);
    
    if(theId == null){
        return <span>Loading</span>;
    }
    
    let structure = bound.magicalGet(props.field, defaultStructure(theId));
    if(_.size(structure) == 0){
        structure = defaultStructure(theId);
    }
    
    const updateContentStructure = (struct)=>{
        debugger; //I never get called
        props.update("RMS",props.field,()=>{ saveToServer() });
        
    };
    
    let rmsProps = {
        central: "blah",
        key: theId,
        root: theId,
        structure: structure,
        updateContentStructure: (s)=>{updateContentStructure(s)},
        mode: "edit",
        iAmGod: true
    };
    
    return <div className={"field " +  (props.className || "col-xs-12 col-md-12")} data-uid={theId}>
        <RenderMeSoftly {...rmsProps} />
    </div>;
}