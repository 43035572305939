import React, { Component } from 'react';
import MetaTalker from './MetaTalker.jsx';

export default class DiagramCounter extends MetaTalker {
    constructor(props){
        super(props);
        this.state = {val: null};
    }
    
    componentDidUpdate(prevProps, prevState, snapshot){
        if (this.state.val == undefined){
            this.process();
        }
    }
    
    static getEditableProps(){
        return [
            {niceName: "Reference Name", prop: "reference", type: "text"}
        ]        
    }

    process(){
        let theRealId = this.getTheRealId();
        let scopeType = "diagrams";

        let val = APP.NDT.scope[scopeType][theRealId];
        
        if(val == undefined){
            let myNumber = this.findMyNumber(APP.NDT.injected[theRealId], scopeType);
            val = {value: myNumber, node: APP.NDT.getNode(theRealId), backdoor: this.backdoor, injectedTo: APP.NDT.injected[theRealId]};
        }

        if (this.state.val != val.value){
            this.props.announceScope(theRealId, val, scopeType);
            
            this.setState({val: val.value});
        }
    }
}