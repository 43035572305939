import React, { useEffect, useState } from "react";

export function StaffBadge({ staff, size = 2 }) {
  let src = staff && staff.avatar ? staff.avatar : "api/v1/staff/avatar?id=0";
  let name = staff && staff.fullName ? staff.fullName : "-";

  return (
    <div className="staff-badge">
      <img src={"/" + src} style={{ width: size + "em", borderRadius: size/2 + "em" }} />
      <div className="name">{name}</div>
    </div>
  );
}
