import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default class BoundComponent extends Component {

	   static defaultProps = {
		      obj: {  default: true, state: {}, required: false }
	   };
	
	   
	xxxcomponentWillReceiveProps(nextProps) {
		if (nextProps.obj instanceof Component && nextProps.obj.state != null){
			this.setState({
				value: nextProps.obj.state[nextProps.field]
			});
		} else if ( nextProps.obj[nextProps.field] != undefined){
			this.setState({
				value: nextProps.obj[nextProps.field]
			});
		} else if ( nextProps.obj.hasOwnProperty(nextProps.field) ){
			this.setState({
				value: nextProps.obj[nextProps.field]
			});
		} else{
			if(nextProps.field == undefined){
				console.log("BoundComponent.js:  No field defined.");
			} else {
				console.log("Boundcomponent could not save the field. Perhaps the "+nextProps.field+" attribute is undefined."); 
			}
		}
	}

	/* static getDerivedStateFromProps(props, state){
		let value = null;
		if (props.obj instanceof Component && props.obj.state != null){
			value = props.obj.state[props.field];
		} else if ( props.obj[props.field] != undefined){
			value = props.obj[props.field];
		} else if ( props.obj.hasOwnProperty(props.field) ){
			value = props.obj[props.field];
		} else{
			if(props.field == undefined){
				console.log("The world just ended inside boundcomponent. No field defined.");
			} else {
				console.log("Boundcomponent could not save the field. Perhaps the "+props.field+" attribute is undefined.");
			}
		}

		return {
			value: value
		}
	} */
	
	renderLabelChildren(){
		if (this.props.children){
			return <span className="labelActions">{this.props.children}</span>
		}
	}
	
	renderLabelTags(){
		if(this.props.labelTag){
			return this.props.labelTag;
		}

		if (this.props.required){
			const value = this.context.magicalGet( this.props.field );
			const hasValue = value != null && value != "";
			this.context.setRequiredField(this.props.field, value);

			if (hasValue){
				if (this.state.hasError != false){
					this.setState({hasError: false});
				}
			}else{
				if (this.state.hasError == undefined || this.state.hasError == false){
					this.setState({hasError: true});
				}
				return <em>Required Field</em>;
			}

			return null;
		}
	}

}
