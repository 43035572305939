import React, { Component, useState, useEffect, useContext }  from 'react';

export default function FakeRouted(props){
    let newProps = {match: {params: {...props.fakeParams} }};
    let [res, setRes] = useState("Loading...");
    let [loading, setLoading] = useState(true);

    useEffect(()=>{
        if(props.formUid && loading){
            newProps.viewDefinition = _.find(window.APP.views, {formUid: props.formUid});
            
            if(newProps.viewDefinition){
                setRes(props.render(newProps));
                setLoading(false);
            } else {
                APP.central.Stuff.fetchView(props.formUid).then(r=>{
                    newProps.viewDefinition = r.result;
                    setRes(props.render(newProps));
                    setLoading(false);
                });
            }
        }
    });
    
    return <div className="loading" key={"faled_route_loading_" + props.formUid}>{res}</div>;
}