import React, { useRef } from "react";
import { useData, useNavBar } from "@opidcore/hooks/WTF";
import { Icon, TabularDataSet, TabularColumn, Button, Pagination, Searchable, ActionBar, BoundCommit, InputText, UnlockToEdit, useMagic } from "@opidcore/components";
import * as _ from "lodash";
import { NicePercent } from "../Nice";

export default function JointVentureEdit(props) {
  useNavBar("Edit Joint Venture", []);

  const [data, dataSet] = useData("compensationJointVenture", { paginate: 100 }); 

  const edit = (jv) => {
    APP.instance.createModal(<EditJointVenture row={jv} />);
  };

  const newRow = () => {
    APP.instance.createModal(<EditJointVenture row={{ __new: true, __dataUID: dataSet.dataUID, __type: 'CompensationJointVenture' }} />);
  };

  return (
    <div className="full-size">
      <ActionBar title="Actions">
        <Searchable ds={dataSet} />
        <a className="btn btn-new" onClick={() => newRow()}>
          New Joint Venture
        </a>
      </ActionBar>

      <div>
        <Pagination dataSet={dataSet} />
        <Button onClick={() => dataSet.download("xlsx")}>excel</Button>
      </div>

      {dataSet.loading ? "loading" : ""}

      <TabularDataSet dataSet={dataSet} defaultSort="id" defaultSortOrder="asc">
        <TabularColumn data={(row) => row.name} title="Name" />
        <TabularColumn data={(row) => <NicePercent>{row.savingsPercentShared}</NicePercent>} title="Savings" />
        <TabularColumn data={(row) => <NicePercent>{row.revenuePercentShared}</NicePercent>} title="Revenue" />
        <TabularColumn data={(row) => <NicePercent>{row.marginPercentShared}</NicePercent>} title="Margin" />
        <TabularColumn
          data={(row) => {
            return <Icon icon="edit" onClick={() => edit(row)} size="2x" />;
          }}
        />
      </TabularDataSet>
    </div>
  );
}

function EditJointVenture(props) {
  const magic = useMagic({ ...props.row });

  return (
    <div>
      <BoundCommit
        initialValue={magic.to}
        commit={(id,changes,to,bound)=>APP.central.Bound.saveChanges(bound.getAllDeltas())}
        className="basicForm"
      >
        <UnlockToEdit>
          <InputText field="id" name="#" />
        </UnlockToEdit>
        <InputText field="name" name="Name" />
        <InputText field="savingsPercentShared" name="Savings Percent Shared"/>
        <InputText field="revenuePercentShared" name="Revenue Percent Shared"/>
        <InputText field="marginPercentShared" name="Margin Percent Shared"/>
      </BoundCommit>
    </div>
  );
}
