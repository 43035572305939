import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, InputText, Pagination, Searchable, Tabular, TabularColumn, TabularDataSet } from '@opidcore/components';
import Bound from '@opidcore/components/Bound';
import BoundCommit from '@opidcore/components/BoundCommit';
import { useData, useNavBar } from '@opidcore/hooks/WTF';

import PeriodSelect from "../../components/PeriodSelect";
import ViewPanel from '../../components/ViewLayouts';

import LookupStatusSelect from '@opidcore/components/LookupStatusSelect'; 
import moment from 'moment';
import CesarFilter from '../../components/CesarFilter';

export default function SelectSustainability(props) {
    const nav = useNavBar("Sustainability Reporting", []);
    useEffect(() => {
        const links = []; //<Link to={{ pathname: "/ui/sustainability/list", state: {} }}>Select</Link>, <Link to={{ pathname: "/ui/sustainability/overview", state: { test: "Hello world" } }}>Overview</Link>, <Link to="/ui/sustainability/details">Details</Link>]
        nav.update("Sustainability Reporting", links);
    })
    const [clientId, setClientId] = useState(0);

    const [scope, scopeSet] = useState(0);
    // Scope used to set umbrella (1), client (2), or site (3) 

    const [customer, customerDataSet] = useData("customer", clientId);
    const [currentPeriod, setCurrentPeriod] = useState(moment().subtract(1, "months").format("YYYY-MM"));
    const [currentPeriodTitle, setCurrentPeriodTitle] = useState("");
    //const [data, setData] = useState([]); //APP.central.Sustainability.fetchStatus
    const [data, dataSet] = useData("sustainabilitystatus;status", { method: "fetchStatus", period: currentPeriod, paginate: 50 });

    const stateRef = useRef({period: currentPeriod});
    const [showSites, setShowSites] = useState(false);

    const [clientReady, clientReadySet] = useState(false);
    const [siteReady, siteReadySet] = useState(false);

    const [targetSite, targetSiteSet] = useState([]);

    useEffect(() => {
        setCurrentPeriodTitle(moment(currentPeriod + "-01").format('MMMM YYYY'));
    }, [currentPeriod]);

    useEffect(() => {
        //debugger;
    }, [stateRef, stateRef.client, stateRef.period]);

    var targetStatus = [];
    var d = new Date();

    const updateClient = (them, magic, key) => {
        if (key == "client") {
            setClientId(parseInt(stateRef.current.client.id));
            APP.redirect("/ui/sustainabilityedit/" + parseInt(stateRef.current.client.id) + "/" + moment(currentPeriod).add(1, 'months').format("YYYY-MM"))
        }

        if (key == 'period') {
            setCurrentPeriod(magic.magicalGet("period"));
        }
    }

    const loadSites = () => {
        clientReadySet(false);
    }

    const downloadFile = (file) => {
        window.open("/file/download/" + file.storageIdentifier + "/" + file.filename)
    }

    const Checkbox = ({
        type = "checkbox",
        name, checked = false,
        onChange, id = "unassigned" }) => {
        return (
            <input type={type} name={name} checked={checked} onChange={onChange} id={id} />
        );
    };

    const CheckboxList = (props) => {
        const [checkedItems, setCheckedItems] = useState([]);

        if (props.siteList == null) {
            return null;
        }

        const handleChange = event => {
            setCheckedItems({
                ...checkedItems,
                [event.target.name]: event.target.checked
            });
        };

        const checkboxes = [];
        for (var i = 0; i < props.siteList.length; i++) {
            checkboxes.push({
                name: props.siteList[i].friendlyId,
                key: "checkBox" + i,
                label: props.siteList[i].name,
                id: props.siteList[i].id
            })
        }

        function SelectSites() {
            var tempSites = [];
            for (const item in checkedItems) {
                if (checkedItems[item]) {
                    tempSites.push(parseInt(item.valueOf()))
                }
            }
            targetSiteSet(tempSites);
            siteReadySet(true);
        }

        return (
            <div>
                <ViewPanel>
                    {checkboxes.map(item => (
                        <div>
                            <label key={item.key}>
                                {item.label}
                                <Checkbox
                                    name={item.name}
                                    checked={checkedItems[item.name]}
                                    onChange={handleChange}
                                    id={item.id}
                                />
                            </label>
                        </div>
                    ))}
                    <button onClick={SelectSites}>Check Items</button>
                </ViewPanel>
            </div>
        );
    };

    function SiteSelection(props) {
        const customer = props.customer;

        return <div>
            {props.showSites ? <CheckboxList siteList={customer.siteList} /> : null}
            <ReportView customer={customer} />
        </div>
    }

    const updateStatus = (bound) => {
        const myBound = bound.bound.to
        APP.central.Sustainability.updateSustainabilityStatusDirect(myBound.sss_id, myBound.report_status).then((r) => {           
        })
    }

    const StatusCount = ({dataSet}) => {
        const [complete, setComplete] = useState(0);
        const [notSelected, setNotSelected] = useState(0);
        const [inProgress, setInProgress] = useState(0);
        const [skipped, setSkipped] = useState(0);
        const [dataReview, setDataReview] = useState(0);
        const [totalStatus, setTotalStatus] = useState(0);
        const [unknownStatus, setUnknownStatus] = useState(0);
        const [doneCounting, setDoneCounting] = useState(false);

        useEffect(() => {
            var complete2 = 0;
            var notSelected2 = 0;
            var inProgress2 = 0;
            var skipped2 = 0;
            var dataReview2 = 0;
            var totalStatus2 = 0;
            var unknownStatus2 = 0;
            setDoneCounting(false);   
            if (dataSet != null) {
                dataSet.origData.forEach((d) => {                   
                    if (d.report_status == "complete") complete2 += 1;
                    else if (d.report_status == "notSelected") notSelected2 += 1;
                    else if (d.report_status == "inProgress") inProgress2 += 1;
                    else if (d.report_status == "dataReview") dataReview2 += 1;
                    else if (d.report_status == "skipped") skipped2 += 1;
                    else unknownStatus2 += 0;
                    totalStatus2 += 1;
                })
            }
            debugger
            setComplete(complete2)
            setNotSelected(notSelected2)
            setInProgress(inProgress2)
            setSkipped(skipped2)
            setDataReview(dataReview2)
            setTotalStatus(totalStatus2)
            setUnknownStatus(unknownStatus2)
            setDoneCounting(true)
        }, [dataSet])

        if (doneCounting) return <div>
            {complete} data entry complete, {inProgress} data entry in progress, {dataReview} need review, {skipped} skipped, {notSelected} status not selected out of {totalStatus} total site sustainability statuses. 
            {unknownStatus > 0 ? unknownStatus + " sites with an unknown status." : null}
        </div>

        else return <div>
            ...
        </div>
    }

    /* <Bound to={row}>
    
    </Bound>}
    <Bound to={row}>
                <LookupStatusSelect field="report_status" />
            </Bound>}
    */
    return (
      <div>
        {/*<UmbrellaDisplay currentPeriod={currentPeriod}/>*/}

        <div className="period-progress">
          <ViewPanel>
            {" "}
            {/* Client and date selection */}
            {/* <InputText field="period" name="Sustainability Period" /> */}
            <h2>{currentPeriodTitle}</h2>
            {false ? <button onClick={loadSites}>Load Sites</button> : null}
          </ViewPanel>
        </div>

        <ViewPanel style={{ justifyContent: "space-between" }}>
          <Searchable ds={dataSet} />
          <div style={{ maxWidth: "20em" }}>
            <Bound to={stateRef.current} onChange={updateClient}>
              <PeriodSelect field="period" label="Period" />
            </Bound>
          </div>
        </ViewPanel>
        <ViewPanel>
          <SiteSelection customer={customer} showSites={showSites} />
        </ViewPanel>

        {/*<StatusCount dataSet={dataSet}/>*/}

        <CesarFilter
          columns={[
            { column: "customer_name" },
            { column: "site_name" },
            { column: "client_group" },
            { column: "sustainability_analyst_name" },
            { column: "billing_status" },
            { column: "report_status" }, 
          ]}
          dataSet={dataSet}
        /><br></br><br></br>

        <Pagination dataSet={dataSet} />
        <TabularDataSet dataSet={dataSet}>
          <TabularColumn data={(row) => row.customer_friendly_id} title="Client ID" />
          <TabularColumn data={(row) => row.customer_name} title="Client" />
          <TabularColumn data={(row) => row.site_name} title="Site" />
          <TabularColumn data={(row) => row.site_address} title="Address" />
          <TabularColumn data={(row) => row.client_group} title="Umbrella" />
          <TabularColumn data={(row) => row.sustainability_analyst_name} title="Sustainability Analyst" />
          <TabularColumn
            data={(row) =>
              dataSet.loading ? null : row.report_status ? (
                <Bound to={row}>
                  <LookupStatusSelect field="report_status" lookupName="sustainabilityStatus" disabled={false} onChange={(bound) => updateStatus(bound)} />
                </Bound>
              ) : (
                <div>n/a</div>
              )
            }
            title="Sustainability Status"
          />
          <TabularColumn data={(row) => row.contact} title="Contact" />
          <TabularColumn data={(row) => row.metric_tonnes_waste} title="Waste (MT)" />
          <TabularColumn data={(row) => row.metric_tonnes_diverted} title="Diverted (MT)" />
          <TabularColumn data={(row) => row.billing_status} title="Billing Status" />
          {/*(dataSet.loading ? null : <Bound to={row}><LookupStatusSelect field="billing_status" lookupName="Enviro Status" disabled={true} /></Bound>)} title="Billing Status"*/}
          <TabularColumn
            data={(row) => {
              if (row.file) {
                return <Icon icon="file-arrow-down" onClick={() => downloadFile(row.file)} />;
              } else {
                return null;
              }
            }}
          />
          <TabularColumn
            data={(row) => {
              return (
                <Fragment>
                  {"" /* this exists so trying to sort this column doesnt kill Tabular */}
                  <Link onClick={(e) => e.stopPropagation()} to={"/ui/sustainabilityedit/" + row.customer_id + "/" + row.service_date}>
                    <Icon icon="caret-right" size="2x" />
                  </Link>
                </Fragment>
              );
            }}
            title="Edit"
          />
        </TabularDataSet>

        {/*  <h3>Overview</h3>
        <div className="buttons">
            <Button onClick={() => setShowSites(showSites ? false : true)}>Select Sites</Button>
            <Button>Preview Report</Button>
            <Button>Edit Data</Button>
        </div> */}
      </div>
    );
}

export function StatusThingy(props) {
    const statuses = props.statuses;
    const [doneTo, setDoneTo] = useState(statuses.indexOf(props.status));

    return <div className="statuses">
        {statuses.map((s, i) => {
            return <div className={doneTo >= i ? "step done" : "step"} onClick={() => setDoneTo(i)}><span className="num">{i}</span> {s}</div>
        })}
    </div>
}

function ReportView({ customer }) {
    if (customer.siteList == null) {
        return null;
    }

    return <div>
        <h4>Sites</h4>
        <Tabular data={customer.siteList} noData="No sites.">
            <TabularColumn data={(row) => row.name} title="Site" />
            <TabularColumn data={(row) => { return <Link to={"/ui/sites/edit/" + row.friendlyId}> <Icon icon="edit" size="1x" /> </Link> }} />
            {/* <TabularColumn data={(row) => row.reportPeriod} title="Report Period" /> */}
            {/* <TabularColumn data={(row) => row.status} title="Status" /> */}
            <TabularColumn data={(row) => row.environmentalIndustry} title="Industry" />
            <TabularColumn data={(row) => row.environmentalDensity} title="Density" />
        </Tabular>

        <h4>Contacts</h4>
        <ContactList client={customer} />
        {/*<Tabular data={tabularContacts} noData="No contacts.">
            <TabularColumn data={(row) => (row.firstName + " " + row.lastName)} title="Name" />
            <TabularColumn data={(row) => row.email} title="Email Address" />
        </Tabular>
        <button onClick={}>New Contact</button>*/}
    </div>

}

function ContactList(props) {
    const [contactList, contactListDataset] = useData("contacts", APP.central.Customer.fetchContacts, { id: props.client ? props.client.id : 0 });

    const addContact = () => {
        APP.instance.createModal(<EditContact contact={{ __new: true, __dataUID: contactListDataset.dataUID, customer: props.client.id }} />, {
            onClose: () => refreshContacts(),
        });
    };

    return (
        <div className="full">
            <Tabular data={contactList}>
                <TabularColumn data={(row) => (row.firstName + " " + row.lastName)} title="Name" />
                <TabularColumn data={(row) => row.email} title="Email Address" />
                <TabularColumn data={(row) => {
                    return <Icon icon="edit" onClick={() => APP.instance.createModal(<EditContact contact={row} />)}></Icon>
                }} title="Edit Contact">
                </TabularColumn>
                <TabularColumn data={(row) => {
                    return <Icon icon="repeat" title="Add Subscription" onClick={() => APP.instance.createModal(<EditSubscriptions contact={row} />)} />
                }} title="Report Subscriptions">
                </TabularColumn>
            </Tabular>

            <Button onClick={addContact}>Add Contact</Button>
        </div>
    );
}

function EditContact(props) {
    return (
        <div>
            <BoundCommit initialValue={props.contact} forceSaveProps={["customer"]} commit={APP.central.Customer.saveContact} className="basicForm">
                <InputText field="firstName" name="First Name" />
                <InputText field="lastName" name="Last Name" />
                <InputText field="title" name="Title" />
                <InputText field="role" name="Role" />
                <InputText field="email" name="Email" />
                <InputText field="phone" name="Phone Number" />
                <InputText field="cellPhone" name="Mobile Number" />
                <InputText field="addressLine1" name="Address Line 1" />
                <InputText field="addressLine2" name="Address Line 2" />
                <InputText field="city" name="City" />
                <InputText field="province" name="Province" />
                <InputText field="postalCode" name="Postal Code" />
            </BoundCommit>
        </div>
    );
}

function EditSubscriptions(props) {  
    return (
        <div>
            Invoices? {props.contact.receivesInvoices}
            Cost Savings? {props.contact.recievesCostSavings} 
            Sustainability? {props.contact.recievesSustainability}
        </div>
    )
}
