import React, { useEffect, useState, useContext} from 'react';
import { Link } from 'react-router-dom';
import { useData} from '@opidcore/hooks/WTF';
import Util from '@opidcore/Util';
//import Icon from '@opidcore/components/Icon';
import moment from 'moment';
import { DataContext } from '@opidcore/components/Bound';
import { Loading } from '@opidcore/components';

export function NiceNumber(props) {
	let num = props.children;
	if (isNaN(num)) {
		num = 0;
	}
	const places = _.clamp(_.size((num + "").split('.')[1]), props.places != undefined ? props.places : 2, props.maxPlaces != undefined ? props.maxPlaces : 2);

	return <span className="nice-number">{Util.roundNice(num, places)}</span>
}

export function NicePercent(props) {
	return <span className="nice-number">{Util.roundNice(props.children, props.places != undefined ? props.places : 2)}%</span>
}

export function NiceCurrency(props) {
	if (props != undefined && props.children != undefined) {
		let num = props.children.toString().replace(",", ""); //Commas in larger numbers cause number to be read as NaN
		if (Number.isNaN(num)) {
			num = 0;
		}
		const [rounded, setRounded] = useState(Util.roundNice(num, 2));

		useEffect(() => {
			let defaultRound = 2;
			if (props.roundedPlaces != undefined) {
				defaultRound = props.roundedPlaces;
			}
			let newNum = props.children.toString().replace(",", "");;
			if (Number.isNaN(newNum)) {
				newNum = 0;
			}
			setRounded(Util.roundNice(newNum, defaultRound));
		}, [props.children]);

		let val = "$" + rounded;
		if (parseFloat(rounded.replace(",", "")) == 0) {
			val = "-";
		}

		return <span {...props} className="nice-number">{val}</span>
	} else return null
}


export function NiceDate(props) {
	const date = moment(props.children);
	let value = date.format("YYYY-MM-DD");
	let time = date.format("hh:mm a");

  const showTime = props.showTime ? props.showTime : false;

	if (props.children == null) {
		value = "-";
		time = "No Date Set";
	}

	if (value == "Invalid date") {
		value = "";
	}

	return <span className="nice-date" title={time}>{value}{showTime ? ", " + time : null}</span>
}

export function NiceRelativeTime(props) {
  const mDate = moment(props.date);

  return <span className="nice-relative-time" title={mDate.fromNow()}>{mDate.format("MMM. DD, yyyy hh:mm")}</span>
}


export function BoundNiceRelativeTime(props) {
  const parent = useContext(DataContext);
  const date = parent.magicalGet(props.field);

  const mDate = moment(date);
  if (date == undefined || date == null || date.length == 0) {
    return null;
  } else {
    return <span className="nice-relative-time" title={mDate.fromNow()}>{mDate.format("MMM. DD, yyyy hh:mm")}</span>
  }
}

export function NicePerson(props){
  const person = props.children;
  
  if (person){
    return <span className="nice-person">{person.fullName}</span>;
  }else{
    return <span className="nice-person">{"Nobody"}</span>;
  } 
}

export function NiceMaterial(props) {
	const [materials, mds] = useData('material');
	//const mat = "test";
	const material = materials.find((a) => a.id == props.children);
	return <span className="nice-material">{material ? material.name : ""}</span>
}


export function NiceEquipmentSize(props) {
	const [equipmentSizeData, equipmentSizeDataSet] = useData('equipmentsize');
	//const mat = "test";
	const equipmentSize = equipmentSizeData.find((a) => a.id == props.children);
	return <span className="nice-equipment-size">{equipmentSize ? equipmentSize.name : ""}</span>
}


export function NiceEquipmentType(props) {
	const [equipmentTypeData, equipmentTypeDataSet] = useData('equipmenttype');
	//const mat = "test";
	const equipmentType = equipmentTypeData.find((a) => a.id == props.children);
	return <span className="nice-equipment-typ">{equipmentType ? equipmentType.name : ""}</span>
}

export function NiceServiceSchedule(props) {
	const [serviceData, serviceDataSet] = useData('service_schedule');

  if(props.children && typeof props.children == "object" && props.children.id) {
	  const monthlyRate = props.children.monthlyRate != null ? " (" + props.children.monthlyRate + ")" : "";
	  return <span className="nice-service-schedule">{props.children.frequency + monthlyRate}</span>
  }

	const service = serviceData.find((a) => a.id == props.children);
	const monthlyRate = service != undefined && service != null && service.monthlyRate != null ? " (" + service.monthlyRate + ")" : "";
	return <span className="nice-service-schedule">{service ? service.frequency + monthlyRate : ""}</span>
}

export function NiceService(props) {
  if(props.children && typeof props.children == "number") {
    const [serviceData, serviceDataSet] = useData("service;"+props.children, props.children);

    if (serviceDataSet.loading) {
      return <span className="nice-service"><Loading size="1x"/></span>;
    }

    return <span className="nice-service">{serviceData ? serviceData.description : ""}</span>;
  }

  return <span className="nice-service">{props.children}</span>;
}

export function NiceSite(props) {
	if(props.children && typeof props.children == "number") {
    const [siteData, siteDataSet] = useData("site;"+props.children, props.children);

    if (siteDataSet.loading) {
      return <span className="nice-site"><Loading size="1x"/></span>;
    }

    return <span className="nice-site">{siteData ? siteData.name : ""}</span>;
  }

  return <span className="nice-site">{props.children}</span>;
}

export function NiceCustomer(props) {
  if(props.children && typeof props.children == "number") {
    const [customerData, customerDataSet] = useData("customer;"+props.children, props.children);

    if (customerDataSet.loading) {
      return <span className="nice-customer"><Loading size="1x"/></span>;
    }

    return <span className="nice-customer">{customerData ? customerData.name : ""}</span>;
  }

  return <span className="nice-customer">{props.children}</span>;
}

export function NiceBaseEntity(props) {
	const thing = props.thing;
	let formatter = (r) => {
		return r.name;
	}
	if (window.APP.baseEntities[thing.__type]) {
		formatter = window.APP.baseEntities[thing.__type].formatter || formatter;
	}
	return <span className="nice-thing">{formatter(thing)}</span>;
}

export function NiceBaseEntityClassName(props) {
	const thing = props.thing;
	let formatter = (r) => {
		return r.__type ? Util.deCamelCase(r.__type) : "singluarName not set in \public\waste\js\index.js";
	}
	if (window.APP.baseEntities[thing.__type]) {
    formatter = (r) => {
      return window.APP.baseEntities[thing.__type].singluarName;
    }
	}
	return <span className="nice-thing-title">{formatter(thing)}</span>;
}

export function BoundNiceKgm3ToKgy3(props) {
	const ctx = useContext(DataContext)
	const convertedThing = Util.roundNice((ctx.magicalGet(props.target) / 1.30795), 2)
	return <span className="nice-kgm3-to-kgy3">{convertedThing} kg/yd<sup>3</sup></span>
}

export function NiceNumberWithUnits(props) {
var convertedValue = Number(Util.roundNice(props.value, props.places))
	return <span className="nice-number-with-unit">{convertedValue} {props.unit}<sup>{props.sup}</sup></span>
}

export function BoundNiceNumberWithUnits(props) {
	const ctx = useContext(DataContext)
	const convertedValue = Util.roundNice(ctx.magicalGet(props.target), props.places)
	return <span className="nice-number-with-unit">{convertedValue} {props.unit}<sup>{props.sup}</sup></span>
}

export function NiceVendor(props) {
	const [vendorData, vendorDataSet] = useData("vendor");

  if (vendorDataSet.loading) {
		return <span className="nice-vendor"><Loading size="1x"/></span>;
	}

	const vendor = vendorData.find((a) => a.id == props.children);
	return <span className="nice-vendor">{vendor ? vendor.name : ""}</span>;
}


export function NiceVendorContract(props) {
	const [vendorContractData, vendorContractDataSet] = useData("vendor_contract;id:" + props.children, props.children);

  if (vendorContractDataSet.loading) {
		return <span className="nice-vendor-contract"><Loading size="1x"/></span>;
	}

	return <span className="nice-vendor-contract">{vendorContractData ? vendorContractData.niceName : ""}</span>;
}

export function NiceClientContract(props) {
	const [clientContractData, clientContractDataSet] = useData("client_contract;id:" + props.children, props.children);

  if (clientContractDataSet.loading) {
		return <span className="nice-client-contract"><Loading size="1x"/></span>;
	}

	return <span className="nice-client-contract">{clientContractData ? clientContractData.niceName : ""}</span>;
}

export function NiceAPInvoice(props) {
	const [apInvoiceData, apInvoiceDataSet] = useData("apinvoice;id:" + props.children, props.children);

  if (apInvoiceDataSet.loading) {
		return <span className="nice-ap-invoice"><Loading size="1x"/></span>;
	}
  
	return <span className="nice-ap-invoice">{apInvoiceData ? apInvoiceData.externalInvoiceNumber : ""}</span>;
}

export function NiceARInvoice(props) {
	const [arInvoiceData, arInvoiceDataSet] = useData("invoices;id:" + props.children, props.children);

  if (arInvoiceDataSet.loading) {
		return <span className="nice-ar-invoice"><Loading size="1x"/></span>;
	}
  
	return <span className="nice-ar-invoice">{arInvoiceData ? arInvoiceData.id : ""}</span>;
}

export function NiceSiteSustainabilityStatus(props) {
  const [siteSustainabilityStatusData, siteSustainabilityStatusDataSet] = useData("siteSustainabilityStatus;id:" + props.children, props.children);

  if (siteSustainabilityStatusDataSet.loading) {
    return <span className="nice-site-sustainability-status"><Loading size="1x" /></span>
  }

  if (
    props.link &&
    siteSustainabilityStatusData &&
    siteSustainabilityStatusData.site &&
    siteSustainabilityStatusData.site.customer &&
    siteSustainabilityStatusData.reportPeriod
  ) {
    return (
      <Link
        to={"/ui/sustainabilityedit/" + siteSustainabilityStatusData.site.customer + "/" + siteSustainabilityStatusData.reportPeriod}
        className="plain_link"
      >
        <span>
          <b>{"Site Sustainability Status: "}</b>
          <span className="nice-site-sustainability-status">
            {siteSustainabilityStatusData && siteSustainabilityStatusData.site
              ? siteSustainabilityStatusData.site.customerName + ": " + siteSustainabilityStatusData.reportPeriod
              : ""}
          </span>
        </span>
      </Link>
    );
  }

  return (
    <span className="nice-site-sustainability-status">
      {siteSustainabilityStatusData && siteSustainabilityStatusData.site
        ? siteSustainabilityStatusData.site.customerName + ": " + siteSustainabilityStatusData.reportPeriod
        : ""}
    </span>
  );
}


