import React from 'react';
import ExportSustainability from '../Reports/ExportSustainability.jsx';
import DetailsSustainability from "./DetailsSustainability.jsx";
import EditSustainability from './EditSustainability.jsx';
import EditSustainabilityNew from './EditSustainabilityNew.jsx';
import OverviewSustainability from "./OverviewSustainability.jsx";
import PortalSustainability from './PortalSustainability.jsx';
import SelectSustainability from './SelectSustainability.jsx';

import { MenuItem, SubMenu } from '@opidcore/components/Menu.jsx';

import SelectSustainability2 from './SelectSustainability2.jsx';

export default function initialize(permissions, options) {
	window.APP.customRoutes.push({ path: "/ui/sustainability/list", component: SelectSustainability2 })
	window.APP.customRoutes.push({ path: "/ui/sustainability/listfull", component: SelectSustainability })
	window.APP.customRoutes.push({ path: "/ui/sustainability/overview", component: OverviewSustainability })
	window.APP.customRoutes.push({ path: "/ui/sustainability/details", component: DetailsSustainability })
	window.APP.customRoutes.push({ path: "/ui/sustainability/:id", component: EditSustainability });
	window.APP.customRoutes.push({ path: "/ui/sustainabilitylist", component: EditSustainabilityNew })
	window.APP.customRoutes.push({ path: "/ui/sustainabilityedit/:id/:period", component: EditSustainabilityNew })
	window.APP.customRoutes.push({ path: "/ui/sustainabilityeditlimit/:id/:period/:limit", component: EditSustainabilityNew })
	window.APP.customRoutes.push({ path: "/ui/sustainabilityportal", component: PortalSustainability })
	window.APP.customRoutes.push({ path: "/ui/sustainabilityExport", component: ExportSustainability })
	

	APP.addMenu(() => {
		const items = [];

		items.push(<SubMenu key="sustainability">
			<MenuItem menuKey="sustainability" linkTo="/ui/sustainability/list" icon="weight-hanging" text="Sustainability" />
			<MenuItem menuKey="sustainabilitymysites" linkTo="/ui/sustainability/listfull" className="nav-sub-menu" icon="weight-hanging" text="Full List" />
			<MenuItem menuKey="sustainabilitymysites" linkTo="/ui/sustainabilityportal" className="nav-sub-menu" icon="user-tag" text="My Sites" />
			<MenuItem menuKey="sustainabilitymysites" linkTo="/ui/sustainabilityExport" className="nav-sub-menu" icon="user-tag" text="Export" />
		</SubMenu>);

		return items;
	});

}