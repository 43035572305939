import React, { useEffect, useRef, useState } from 'react';
import { useData } from '@opidcore/hooks/WTF';
import { TabularDataSet, TabularColumn, Selection, PageHeading, DatePicker, Bound, Button, Icon, Loading } from "@opidcore/components";
import { NiceCurrency, NicePercent } from '../../UI/Nice';
import moment from 'moment';
import _ from 'lodash';
import CesarFilter from '../../components/CesarFilter';
import PriceIncreaseEdit from './PriceIncreaseEdit';

const defaultApplyText = "Apply Increases";

const PriceIncreaseOverview = ({contractType = "ClientContract"}) => {
    APP.setState("currentMenu", ["tools", "price_overview_menu_key"]);
    const filters = useRef({dateFrom: moment().subtract(6, "months").format("YYYY-MM-DD"), dateTo: moment().format("YYYY-MM-DD")});
    const [priceIncreaseData, priceIncreaseDataset] = useData("price-increases", APP.central.ContractLineIncrease.listIncreases, filters.current);
    const [selection, setSelection] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [applyButtonText, setApplyButtonText] = useState(defaultApplyText);
    const [fileToUpload, setFileToUpload] = useState(null);
    const uploadRef = useRef(null);
    const [uploadActive, setUploadActive] = useState(false);

    useEffect(()=>{
        APP.getState("handleSocket", (socketResult)=>{           
            if(socketResult.task == "error"){
                APP.alert(socketResult.status, "Error");
            } else {
                APP.alert("Successfully applied price increases. Updated " + (socketResult.data.length) + " lines and created " + (socketResult.data.length / 2) + " new lines.");
            }
            
            filter();
            setApplyButtonText(defaultApplyText);
            setSelection([]);
            setAllSelected(false);
        });
    }, []);

    useEffect(()=>{
        if(allSelected == true){
            setSelection(priceIncreaseData);
        } else if(selection.length >= priceIncreaseData.length){
            setSelection([]); //everything was checked when we unchecked select all so uncheck all
        }
    }, [allSelected]);

    useEffect(()=>{
        if(applyButtonText != defaultApplyText){
            APP.central.ContractLineIncrease.runLineUpdates(selection).then((r)=>{
                APP.socket.send(JSON.stringify(r));
            });
        }
    }, [applyButtonText])

    const filter = () => {
        if(filters.current.dateFrom == undefined || filters.current.dateTo == undefined){
            APP.alert("Missing field");
            return;
        }

        priceIncreaseDataset.setQueryArgs(filters.current);
        priceIncreaseDataset.fetch();
    }

    const selectAll = (e) => {
        e.stopPropagation();
        if(selection.length >= priceIncreaseData.length){
            setAllSelected(false); //unselect everything
        } else {
            setAllSelected(true); //select everything
        }
    }

    const _setSelection = (value) => {
        if(value.length < priceIncreaseData.length){
            setAllSelected(false); //unchecked something so make sure select all is unchecked
        }
        setSelection(value);
    }

    const runUpdate = () => {
        setApplyButtonText(<Loading size="1x" colour="white" style={{display: "inline-block"}}/>);
    }

    const openService = (row) => {
        if(row.Service != undefined && row.Service.id != undefined && row.Service.id > 0){
            APP.instance.createModal("/ui/services/edit/" + row.Service.id,
                { modal_name: "Edit Service " + row.Service.friendly_id },
                {
                    afterClose: () => {
                        //debugger;
                    }
                }
            );
        } else {
            APP.alert("No service found");
        }
    }

    const changeFile = (e) => {
        setFileToUpload(e.currentTarget.files[0]);
    }

    const cancelUpload = () => {
        uploadRef.current.value = null;
        setFileToUpload(null);
        setUploadActive(false);
    }

    const convertFile = () => {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsDataURL(fileToUpload);
    
            reader.onload = () => resolve({file: reader.result});
            reader.onerror = (error) => reject(error);
        });
    };

    const doUpload = () => {
        if(uploadActive == false){
            setUploadActive(true);
            return;
        }
        
        if(fileToUpload == null){
            APP.alert("No File Selected");
            return;
        }

        convertFile().then((data)=>{
            APP.central.Reports.importPriceIncreases(data).then((r)=>{
                uploadRef.current.value = null;
                setFileToUpload(null);
                setUploadActive(false);
                filter();
            });
        });
    }

    const downloadFile = (fileId) => {
        APP.central.ContractLineIncrease.downloadExcel(fileId).then((r) => {
			window.open("/file/download/" + r.result.storageIdentifier + "/" + r.result.filename);
		});
    }

    const deletePriceIncrease = (row) => {
        APP.confirm("Continue?", "Deleting is permanent", (r)=>{
            if(r == true){
                APP.central.ContractLineIncrease.deletePriceIncrease(row.increase_id).then((r)=>{
                    priceIncreaseDataset.fetch();
                });
            }
        })
        
    }

    const addClasses = (row) => {
        let res = "";

        if(row.client_increase_value < 0 || row.vendor_increase_value < 0 || row.baseline_increase_value < 0){
            res += "negative-increase";
        }

        return res;
    }

    return <div className="price-increase-overview">
        <PageHeading size="h3">Price Increases</PageHeading>

        <div className="tools">
            <Bound to={filters.current} className="data-actions">
                <DatePickerComponent />
                <Button onClick={()=>filter()}>Search</Button>
                <Button className="apply" onClick={()=>runUpdate()}>{applyButtonText} ({selection.length})</Button>                
            </Bound>

            <div className="price-increase-export">
                <a href="/api/v1/reports/exportPriceIncrease" target="_new">Export Price Increase</a>
                {uploadActive == false ? null : <input type="file" onChange={changeFile} ref={uploadRef}></input>}
                <button onClick={doUpload}>{uploadActive == false? "Upload Price Increase" : "Submit"}</button>
                {uploadActive == true? <button onClick={cancelUpload}>Cancel</button> : null }
            </div>
        </div>

        <CesarFilter
            columns={[
                {column: "Customer"},
                {column: "Vendor"},
                {column: "Site"},
                {column: "related_type"},
                {column: "increase_status"}
            ]}
            dataSet={priceIncreaseDataset}
        />

        {priceIncreaseDataset.loading? <Loading /> : null}

        <TabularDataSet dataSet={priceIncreaseDataset} rowClass={(row)=>addClasses(row)}>
            <TabularColumn headingComponent={()=><span><input type="checkbox" onClick={selectAll} checked={allSelected}/>Client/Vendor</span>} data={(row, idx, previousRow)=>{
                return <span title={"price increase id# " + row.id}>
                    <Selection selection={selection} data={priceIncreaseData} setter={_setSelection} item={row} label={(row)=>""} />
                    {row.Customer != null? row.Customer.name : (row.Vendor? row.Vendor.name : "No Client/Vendor")}
                </span>;
            }} />
            <TabularColumn title="Contract" data={(row)=>row.contract_friendly_id}/>
            <TabularColumn title="Increase Applied To" data={(row)=>row.related_type} />
            <TabularColumn title="Effective" data={(row)=>row.effective_date} />
            <TabularColumn title="Site" data={(row)=>row.Site != null? row.Site.name : "No Site"} />
            <TabularColumn title="Service" data={(row)=>row.Service != null? row.Service.cleanDescription : "No Service"} />
            <TabularColumn title="Activity" data={(row)=>row.activity} />
            <TabularColumn title="Material" data={(row)=>row.material} />
            <TabularColumn title="Status" data={(row)=>row.increase_status} />
            <TabularColumn title="Client Increase Type" data={(row)=>row.client_increase_type} />
            <TabularColumn title="Client Increase" data={(row)=><IncreaseValue data={row} increaseValue={row.client_increase_value} increaseType={row.client_increase_type}/>} />            
            <TabularColumn title="Client Before" data={(row)=><NiceCurrency>{row.client_unit_price}</NiceCurrency>} />
            <TabularColumn title="Client After" data={(row)=><NiceCurrency>{row.client_new_unit_price}</NiceCurrency>} />
            <TabularColumn title="Client Change" data={(row)=><NiceCurrency>{row.client_unit_price_delta}</NiceCurrency>} />
            <TabularColumn title="Client Margin" data={(row)=>row.client_rolling_average} />

            <TabularColumn title="Vendor Increase Type" data={(row)=>row.vendor_increase_type} />
            <TabularColumn title="Vendor Increase" data={(row)=><IncreaseValue data={row} increaseValue={row.vendor_increase_value} increaseType={row.vendor_increase_type}/>} />            
            <TabularColumn title="Vendor Before" data={(row)=><NiceCurrency>{row.vendor_unit_price}</NiceCurrency>} />
            <TabularColumn title="Vendor After" data={(row)=><NiceCurrency>{row.vendor_new_unit_price}</NiceCurrency>} />
            <TabularColumn title="Vendor Change" data={(row)=><NiceCurrency>{row.vendor_unit_price_delta}</NiceCurrency>} />
            <TabularColumn title="Vendor Margin" data={(row)=>row.vendor_rolling_average} />

            <TabularColumn title="Baseline Increase Type" data={(row)=>row.baseline_increase_type} />
            <TabularColumn title="Baseline Increase" data={(row)=><IncreaseValue data={row} increaseValue={row.baseline_increase_value} increaseType={row.baseline_increase_type}/>} />            
            <TabularColumn title="Baseline Before" data={(row)=><NiceCurrency>{row.baseline_unit_price}</NiceCurrency>} />
            <TabularColumn title="Baseline After" data={(row)=><NiceCurrency>{row.baseline_new_unit_price}</NiceCurrency>} />
            <TabularColumn title="Baseline Change" data={(row)=><NiceCurrency>{row.baseline_unit_price_delta}</NiceCurrency>} />
            <TabularColumn title="Baseline Margin" data={(row)=>row.baseline_rolling_average} />
            
            <TabularColumn title="Excel" data={(row)=>row.attachment != undefined? <Icon icon="file-excel" onClick={() => downloadFile(row.attachment)} /> : null} />        
            <TabularColumn title="Edit Service" data={(row)=><Icon icon="edit" onClick={()=>openService(row)}/>} />
            <TabularColumn title="" data={(row)=><Icon icon="trash" title="Delete Price Increase" onClick={()=>deletePriceIncrease(row)}/>} />
        </TabularDataSet>
    </div>
}

const DatePickerComponent = ({}) =>{
    return <div className="date-picker-section">
        <DatePicker field="dateFrom" name="From" />
        <DatePicker field="dateTo" name="To" />
    </div>
}

const IncreaseValue = ({data, increaseValue, increaseType}) => {
    if(increaseValue == undefined){
        return null;
    }

    let element = null;
    if(increaseType == "percent"){
        element = <NicePercent>{increaseValue}</NicePercent>;
    } else {
        element = <NiceCurrency>{increaseValue}</NiceCurrency>;
    }

    const openEditor = () => {
        APP.instance.createModal(<PriceIncreaseEdit id={data.increase_id}/>, {modal_name: "Price Increase Edit"});
    }
    
    return <div className="increase-value link" onClick={()=>openEditor()} title="Edit">
        {element}
        {increaseValue < 0? <Icon icon="exclamation-triangle" colour="red" title="Negative Increase"/> : null}
    </div>
}

export default PriceIncreaseOverview;