import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { InputText, InputSelect, Bound} from '../../../OpidCore/public/js/components';

import * as CentralActions from '../../../OpidCore/public/js/actions/CentralActions';

import SaveToolbar from '../components/SaveToolbar.jsx';
import FieldEditor from '../Form/FieldEditor.jsx';
import JsonEditor from '../Form/JsonEditor.jsx';

import * as _ from 'lodash';
//import moment from 'moment';

class CoreEditor extends Component {
	static test = "B";

    constructor(props, context) {
        super(props, context);
        
        this.state = {
                __initialFetchCompleted: false,
                __fetching: false,
                id: 0
        };
    }
   
    static getDerivedStateFromProps(props, state){
    	if (state.__initialFetchCompleted == false && state.__fetching == false ){
    		var newState = {};
    		newState.__fetchRequired = true;
    		newState.__id = parseInt(props.match.params.id);
    		return newState;
    	}
    	return null;
    }    
    
    buzzah = () => {
    	return "hey";
    }
    
    componentDidUpdate(prevProps, prevState, snapshot){
        
    	if (this.state.__fetchRequired == true){
            this.getCentral().fetch(this.state.__id).then((res)=>{
            	this.setState(res.result);
            	this.setState({__loaded: true});
            });
    		this.setState({__fetchRequired: false, __fetching: true});
    	}
    }
    
    render(){
    	var inner = null;
    	if (this.state.__loaded)
    		inner = this.renderEditor();
    	
    	return <div>
    	{inner}
    	</div>;
    }
    
    handleSave(callback){
        if (this.__handleSave == null){
            this.__handleSave = _.debounce( (c) => this._handleSave(c), 500 )
        }
        
        return this.__handleSave(callback);
    }
    
    _handleSave(callback){
        var saveObj = this.getSaveState();
        return this.getCentral().save(saveObj).then((r)=>{
            console.log(r);
            callback && callback();
            this.setState({__fetchRequired:true});
        });
    }
    
   
    
    cancel(){
        window.history.back();
    }
 
   
}


class SectionEditor extends Component {
	constructor(props){
		super(props);
		this.state = {...props.section};
	}
	

	whatUpdate(a,b,c){
		console.log("what update");
		console.log(a);
		this.props.update(this.props.field,b,c)
	}
	
	handleChangeOptions(e){
		
	}

	handleDelete(){
		this.props.update("DELETE", this.state)
	}
	
	
	 static getDerivedStateFromProps(props, state){
			if (true){
			
				if (props.section.id == state.id){
					var newState = {...props.section, ...state};
					return newState;
				}else{
					var newState = {...props.section};
					return newState;	
				}
				
			}
			return null;
	    }    
	
		render(){
			
			var dataOptions = {};

			
			return <div className="section-inner">
				<Bound to={this} ref="my-bound">
					<h3>{this.props.section.uid}</h3>
            				
		            <InputText className="field col-md-3" name="Title" field="title"/>
			            <InputText className="field col-md-3" name="UID" field="uid"/>

				          <InputText className="field col-md-3" name="order" field="order"/>
				        	  
				          <InputSelect className="field col-md-3" name="Type" field="type" showEmpty={true} options={["", "one-to-many"]}/>
				          <JsonEditor field="options" me={this}/>
            		<div className="field-actions">
            			<a onClick={()=>this.handleDelete()}>delete section</a>
            		</div>
	            </Bound>
			</div>
		}
}

class Draggable extends Component {
	
	  onDragStart(e, index){
		    //this.draggedItem = this.state.items[index];
		    e.dataTransfer.effectAllowed = "move";
		    //e.dataTransfer.setData("text/html", e.target.parentNode);
		    e.dataTransfer.setData("text/plain", "moving:" + this.props.children.props.field.id);

		    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
		    
		    document.getElementsByTagName("footer")[0].className = "hidden";
		  }
		 
	  onDrop(ev){
		  ev.preventDefault();

		  var text = ev.dataTransfer.getData("text");
		  var chunks = text.split(":");
		  
		  this.props.handleDrop( {moveId: parseInt(chunks[1]), before: this.props.children.props.field.id} )
		  console.log(text);
	    this.draggedIdx = null;
		
	  }
	  
	   onDropSection(ev){

	          ev.preventDefault();

	          var text = ev.dataTransfer.getData("text");
	          var chunks = text.split(":");
	          
	          this.props.handleDropSections( {moveId: parseInt(chunks[1]), before: this.props.children.props.section.id} )
	          console.log(text);
	        this.draggedIdx = null;
	      }
	  
	  onDragEnd(ev){
		  ev.preventDefault();

		  var text = ev.dataTransfer.getData("text");
		  document.getElementsByTagName("footer")[0].className = ""
		  console.log(text);
	    this.draggedIdx = null;
	  } 
	  
	  onDragOver(ev){
		  //console.log(text);
		  ev.preventDefault();

		  return true;
	  }
	  
	render(){
		
		
		return <div className={this.props.className} onDragOver={(a) => this.onDragOver(a)} onDrop={(a)=>this.onDrop(a)}>
		
			<span className="drag-handle fa fa-arrows-v" draggable                
		onDragStart={(e,idx)=> this.onDragStart(e, idx)}
        onDragEnd={(e)=>this.onDragEnd(e)}
		></span>
			{this.props.children}
		</div>
	}
}

class FormEditor extends CoreEditor {
	static test = "A";
	constructor(props){
		super(props);
		
		props.central.Stuff.listDefinitions();
	}
	
	getSaveState(){
	    
    	var ret = {...this.state};
    	ret.fields = [];
    	_.each(this.refs, (k,v)=>{
    		if (k instanceof FieldEditor){
    			ret.fields.push(k.state);
    		}
    	});
    	
    	ret.sections = [];
    	_.each(this.refs, (k,v)=>{
    		if (k instanceof SectionEditor){
    			ret.sections.push(k.state);
    		}
    	});
    	
    	return ret;
    }
	
      
	getCentral(){
	    
		return this.props.central.Form;
		
		
	}
	

	handleFieldUpdate(a,b,c,d){
		
		console.log(c);
		if (a == "DELETE"){
			const fields = _.filter(this.state.fields, f=>f.id != b.id);			
			
			this.setState({fields: fields});
			
		}
		else{
		    var id = null;
		    if(a.id != undefined){
		        id = a.id;
		    }
		    else{
		        id = a;
		    }
    		var stateChange = {};
    		stateChange.fields = this.state.fields.map((f)=>{
    				if (f.id == id){
    					f[b] = c;
    		            if (f.fieldUid == "new"){
    		                
    		                
    		                var dataField = null;
    		                for(var x of this.props.dataDefinitions){
    		                    if(x.id == c){
    		                        dataField = x;
    		                    }
    		                }
    		                if(dataField != null && dataField.defaultUid == 'field_name'){
    		                    f["fieldUid"] = dataField.fieldName;
    		                }
    		                else if(dataField != null  && dataField.defaultUid == 'label'){
    		                    f["fieldUid"] = f.label;
    		                }       
    		                
    		            }
    				}
    				
    				return f;
    		});
    
    		this.setState(stateChange);
		}
		
	}
	
	handleSectionUpdate(a,b,c,d){
        
        console.log(c);
        if (a == "DELETE"){
            const sections = _.filter(this.state.sections, s=>s.id != b.id);            
            
            this.setState({sections: sections});
            
        }
 
    }
	
	
	handleDrop(drop){
		var fields = [];
		
		
		var order = 1;
		var movedField = _.find(this.state.fields, {id: drop.moveId});
		
		_.each(this.state.fields, (f)=>{
			
			if (f.id == drop.before){
				movedField.order = order;
				
				fields.push(movedField)
				order++;
			}
			
			if (f.id != movedField.id){				
				f.order = order;
				fields.push(f);
				order++;
			}
		});
		
		this.setState({fields: fields});
	}
	
	handleDropSection(drop){
        var sections = [];
        
        var order = 1;
        var movedSection = _.find(this.state.sections, {id: drop.moveId});
        
        _.each(this.state.sections, (s)=>{
            
            if (s.id == drop.before){
                movedSection.order = order;
                
                sections.push(movedSection)
                order++;
            }
            
            if (s.id != movedSection.id){             
                s.order = order;
                sections.push(s);
                order++;
            }
        });
        
        this.setState({sections: sections});
        
    }
	
	duplicateForm(){
		this.props.central.Form.duplicate( this.state.id ).then((newForm)=>{
		});
	}
	
	addField(){
		const fields = _.cloneDeep(this.state.fields);
		var sections = _.map(this.state.sections, 'uid');
		
		var nextOrder = _.max(_.map(fields, 'order')) + 1;
		
		fields.push({ section: sections[0], fieldUid:"new", label: "New Field", order: nextOrder })
		
		
		
		this.setState({fields: fields});
	}

	addSection(){
		const sections = _.cloneDeep(this.state.sections);
		var nextOrder = _.max(_.map(sections, 'order')) + 1;
		
		sections.push({ title: "New Section", uid: "section", label: "New Field", order: nextOrder })

		this.setState({sections: sections});
	}
	
	 renderEditor() {        
	       
	        const users = _.fromPairs(this.props.users.map((u) => [u.id, u.firstName]));
	        
	        const statuses = [ "vague", "planned", "started", "stuck", "completed", "signed off" ];
	        var sections = _.map(this.state.sections, 'uid');
	        
	        var fields = this.state.fields.map( (f)=>{
	        	
	        		return <div className="field-outer" key={f.uid}>	        		
	        			<Draggable className="draggable" handleDrop={(e)=>this.handleDrop(e)}>
	        				<FieldEditor ref={"field-" + ( f.id || (-100 * f.order) )} dataDefinitions={this.props.dataDefinitions} sections={sections} update={(a,b,c,d)=>this.handleFieldUpdate(a,b,c,d)} field={f}/>
	        			</Draggable>
	        			<div className="clear"/>
	        			</div>
	        });
	        
	        var sectionsEdit = this.state.sections.map( (s)=>{
	        	
        		return <div className="section-outer" key={s.uid}>	        		
        			<Draggable className="draggable" handleDrop={(e)=>this.handleDrop(e)}>
        				<SectionEditor ref={"section-" + ( s.id || (-100 * s.order) )} section={s} update={(a,b,c,d)=>this.handleSectionUpdate(a,b,c,d)} sections={sections}/>
        			</Draggable>
        			<div className="clear"/>
        			</div>
        });
	        
	        return (
	            <div id="plan" className="outer-container">
	                <div className="container" >
	                    <Bound to={this} disabled={this.state.disabled}>
	                     
	                        <div className="header1">
	                
	                
	                            <div className="row">
	                                <div className="col-md-6 col-sm-6 col-xs-12 col-md-push-3 col-sm-push-3">
	                                    <h2 className="middle">{this.state.title}</h2>
	                                </div>

	                            </div>

	                            <div className="row">
	                            			
	                                    <InputText className="field col-md-3" name="Title" field="title"/>
	                                    

	                                    <InputText className="field col-md-3" name="UID" field="uid"/>
										<InputSelect className="field col-md-3" name="Type" field="formType" options={["standard", "controlled"]}/>
										<InputText className="field col-md-3" name="Revision" showEmpty={true} field="revisionLevel"/>
											
										 <JsonEditor field="options" me={this}/>
	                            </div>

                                <div>
	                            	{sectionsEdit}
	                            	
		                            <button onClick={()=>this.addSection()}>Add Section</button>

	                            </div>
		                            
	                            <div>
	                            	{fields}
	                            </div>
	                            
	                            <button onClick={()=>this.addField()}>Add Field</button>
	                            
	                       </div>

	                    </Bound>   
	                            
	                                                        
	                      <SaveToolbar handleSave={(c)=>this.handleSave(c)}>
	                      	<button onClick={()=>this.duplicateForm()}>Make a Copy</button>
	                      </SaveToolbar>

	                </div>
	            </div>
	        );
	    }       
}


function mapStateToProps(state) {
    return {
        plan: state.central.plans || {},
        users: state.central.users || [],
        dataDefinitions: state.central.dataDefinitions || []
    };
}

function mapDispatchToProps(dispatch) {
    return {
        ...CentralActions.modifyProps(dispatch),  
        actions: bindActionCreators({...CentralActions}, dispatch),
    };
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormEditor);
