/**
 * Another clever approach of writing reducers:
 *
 * export default function(state = initialState, action) {
 *   const actions = {
 *      [ACTION_TYPE]: () => [action.payload.data, ...state]
 *   };
 *
 *   return (_.isFunction(actions[action.type])) ? actions[action.type]() : state
 * }
 */

import * as types from '../constants/ActionTypes';
import { assign } from 'lodash';

const initialState = {
};

export default function (state = initialState, action, arg1) {
  switch (action.type) {
      
    case types.PROCESS_SAVE_RESULT_FROM_SERVER:
    	
    		alert('server saved stuff, look at logs');
    		console.log(action);
    	
    	
    	return state;

    case types.PROCESS_DATA_FROM_SERVER: 
    	console.log('we should process stuff!', action);
    	if (state[action.dataType] != undefined){
    		//return {...state, employers: action.response };
    		var changes = {};
    		changes[action.dataType] = action.response;
    		
    		return assign({}, state, changes);
    	}
    	
    	return state;
    	
    case types.REQUEST_DATA_FROM_SERVER:
    	alert('buzzah! never called');
    	fetch("http://localhost:9000/api/v1/employer/list")
		  .then(response => { return response.json() } )
		  .then(response => { 
			  console.log("got it", response);
			  //this.props.actions.processDataFromServer("employers", response); 
		  } )
		  
    	return state;

    	
    case types.PROCESSING_DATA:
    	return {...state, _processingMessage: "Please wait..."};
    
    case types.PROCESSING_DATA_DONE:
    	return {...state, _processingMessage: "loaded!"};

    case types.CENTRAL_REFRESH_NEEDED:
    	return {...state, _processingMessage: "Refreshing... ", _processingRefreshView: action.view};
    
    case types.CENTRAL_REFRESH_DONE:
    	return {...state, _processingMessage: "Done!", _processingRefreshView: null};
    	
    case types.CENTRAL_REFRESH_CLEAR:
    	return {...state, _processingMessage: null, _processingRefreshView: null};


    default:
      return state;
  }
}
