import React from 'react';
import BoundComponent from './BoundComponent';
//Unused at the moment (Oct.27/20) started in EditCustomer, but will remove from there later.
export default function Multi(props){    
    let childArray = null;
    if(!Array.isArray(props.children)){
        childArray = [props.children];
    } else {
        childArray = props.children;
    }
    
    const children = _.map(props.data, (d)=>{
        return _.map(childArray, (child, key)=>{
            if(child.type != undefined && child.type.prototype instanceof BoundComponent){
                return React.cloneElement(child, {key: key, name: child.props.name(d), field: child.props.field + "." + key, update: props.update});
            }
            
            return React.cloneElement(child, {key: key});
        });
    })
    
    return <div key="multi_option">
        {props.label}
        {children}
    </div>
}