import React from 'react';
import { MenuItem, SubMenu } from '@opidcore/components/Menu.jsx';

import Dashboard from './Dashboard.jsx';
import AccountManagementDashboard from './AccountManagementDashboard.jsx';
import BillingDashboard from './BillingDashboard.jsx';
import SupportDashboard from './SupportDashboard.jsx';

import TestingDashboard from './TestingDashboard.jsx';

export default function initialize(permissions, options) {
  if (permissions.w || true) {
    window.APP.customRoutes.push({ path: "/ui/dashboard", component: Dashboard });
    window.APP.customRoutes.push({ path: "/ui/accountManagementDashboard", component: AccountManagementDashboard });
    window.APP.customRoutes.push({ path: "/ui/billingDashboard", component: BillingDashboard });
    window.APP.customRoutes.push({ path: "/ui/supportDashboard", component: SupportDashboard });
    window.APP.customRoutes.push({ path: "/ui/dashboard2", component: TestingDashboard });

  }

  APP.addMenu(() => {
    const items = [];

    items.push(
      <SubMenu key="dashboards">
        <MenuItem key="dashboards" menuKey="dashboards" linkTo="/ui/dashboard" icon="tachometer" text="Dashboards" />
        <MenuItem key="accountmanagementDash" menuKey="accountmanagementDash" className="nav-sub-menu" linkTo="/ui/accountManagementDashboard" icon="tachometer" text="Account Management" />
        
        {/*<MenuItem key="billingDash" menuKey="billingDash" className="nav-sub-menu" linkTo="/ui/billingDashboard" icon="file-invoice-dollar" text="Billing" />*/}
        {/*<MenuItem key="supportDash" menuKey="supportDash" className="nav-sub-menu" linkTo="/ui/supportDashboard" icon="file-invoice-dollar" text="Support" />*/}
        {/*<MenuItem key="testDash" menuKey="testDash" className="nav-sub-menu" linkTo="/ui/testingDashboard" icon="file-invoice-dollar" text="Test" />*/}

      </SubMenu>
    );

    return items;
  });

}