import React, { useEffect }  from 'react';
import Util from '@opidcore/Util.js';


export default function HeaderFooter(props) {
	
  return null;
}
HeaderFooter.getEditableProps = ({})=>{
  	        
        return [
            {niceName: "Show Logo", prop: "logo", options: ["","yes"], type: "text" },
            {niceName: "Top Right", prop: "topRight",  type: "text", templated: true },
            {niceName: "Bottom Left", prop: "bottomLeft",  type: "text", templated: true  },
            {niceName: "Bottom Right", prop: "bottomRight",  type: "text", templated: true  },
        ]
        
};

HeaderFooter.metaInfo = ({})=>{
	return {
		category: 'scripts',
		icon: 'file'
	};
};

