//import './AddFriendInput.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BoundComponent from './BoundComponent';

import * as _ from 'lodash';

export default class ListPicker extends BoundComponent {
	static propTypes = {
	    update: PropTypes.func,
	    property: PropTypes.string,

	};

	constructor(props, context) {
		super(props, context);
		
	    this.state = {
	    		options: props.optionsList
	    		
	    };
	}
	
	componentWillReceiveProps(nextProps){
		let nextState = {... this.state};
		
		nextState.options = nextProps.optionsList;		
		
		this.setState(nextState);
	}
	
	removeResult(result){	
		if(result != undefined){
			let newState = {...this.state};

			if(_.find(newState.options, result) != undefined ){
				const resultIndex = newState.options.findIndex(option => option[this.props.keyField] == result[this.props.keyField]);
				
				newState.options.splice(resultIndex, 1);
				
				this.setState(newState, ()=>{this.handleChange()});
			} else {
				//do nothing since option is not in the selectedOptions
			}
		}
	}
	
	handleChange(){
		this.props.update(this.props.obj, this.props.field, this.state.options);
	}
	  
	render(){
		let options = _.map(this.state.options, (option, index) => {			
			return <li key={index} className={"listOption selected" }>
				<span className="option" onClick={()=>this.removeResult(option)}>{option[this.props.labelField]}</span>
			</li>;
		});
		
		return (<div className={"listpickerBody " +  (this.props.className || "") }>
			<label>{this.props.name}</label>
			
			<div className="listWrapper">
				<ul>
					{options}
				</ul>
			</div>
          </div>);
		
	}
}
