import React, { useEffect}  from 'react';
import Icon from '@opidcore/components/Icon';
import Searchable from '@opidcore/components/Searchable';
import ActionBar from '@opidcore/components/ActionBar';
import { Link } from 'react-router-dom';
import { Tabular, TabularColumn } from '@opidcore/components/Tabular';
import { useData, useNavBar } from '@opidcore/hooks/WTF'; 
import { newAudit } from '../Workflow.jsx';

export default function WasteAuditList(props){
	useNavBar("Audits", [], {menu: ["audit"]}); 
	
	const [data, dataSet] = useData("waste_audit");
	
	useEffect(()=>{				
	}, []);
	
	 const onSubmit = () => {
		 	newAudit();
	 };
		  
    return <div className="full-size">   	
    
    	<ActionBar title="Actions">
    		<Searchable ds={dataSet} /> 

    		<a className="btn btn-new" onClick={()=>newAudit()}>New Audit</a>
    	</ActionBar>
    		
		{ APP.getWebSession().access('Waste Audit Templates') ? <Link to="/master_template/list">Manage Audit Templates</Link> : null }
		
    	{ dataSet.loading ? "loading" : ""}
    	<Tabular data={data}>
		<TabularColumn data={(row)=>row.id} title="Audit #"/>
    	<TabularColumn data={(row)=>row.client_name} title="Client Name"/>
	    <TabularColumn data={(row)=>row.site_name} title="Site"/>
		<TabularColumn data={(row)=>row.waste_auditor} title="Waste Auditor"/>
		<TabularColumn data={(row)=>row.owner} title="Assigned To"/>
		<TabularColumn data={(row)=>row.audit_date} title="Audit Date"/>
		<TabularColumn data={(row)=>row.stage} title="Stage"/>
		<TabularColumn data={(row)=>row.status} title="Status"/>
		<TabularColumn data={(row)=>row.next_deadline} title="Current Stage Deadline"/>
		<TabularColumn data={(row)=>row.coreLastEdit.person} title="Last Edited By"/>									
    	
    		<TabularColumn data={(row)=>{ return <div className="row-actions">
					<Link to={"/ui/audit/" + row.id} title="Edit Audit Details"> <Icon icon="pencil" size="2x"/> </Link>
					<Link to={"/contentInstance/" + (row.report_template || "on.audit-template") + "/audit/" + row.id} title="Edit Report"> <Icon icon="file-edit" size="2x"/> </Link>					
					<Link to={"/ui/audit/" + row.id + "/download"} title="View Report"> <Icon icon="file-pdf" size="2x"/> </Link>
					<Link to={"/ui/audit/" + row.id + "/history"} title="History"> <Icon icon="history" size="2x"/> </Link>
				</div>
			 }}/>
    	</Tabular>
    	
    	
    </div>;
}
