import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as CentralActions from '../actions/CentralActions';

class CentralForm extends Component {
	static propTypes = {
		id: PropTypes.number.isRequired
	};
	
	
	static contextTypes = {
		  store: PropTypes.object
	};
	
	
	static list = function(them, model){
		if (them.refs.form){
			return them.refs.form.wrappedInstance.isThat(model);
		}
		
		return;
	}
	
	constructor(props, context) {
	    super(props, context);
	    
		props.me.theProp = this;	
		this.store = context.store;
		window.___STORE = this.store;
	  }
	
	componentWillMount(){
		
		this.props.me.central = this.props.central;
		
        if(this.props.id > 0){
        	this.props.central[this.props.model].fetch(this.props.id).then((r)=>
        	{
        		this.props.me.setState(r.result);
        	});
        }
    }
	
	componentDidMount(){
		if (this.props.me.componentSeriouslyMounted != null){
			this.props.me.componentSeriouslyMounted();
		}
	}
	
	isThat(type){
		if (this.context.store.getState().central[type] == null){

			
			this.props.central[type].list().then((a)=>{					
				setTimeout(()=>{this.props.me.forceUpdate()}, 500);
			});
		}
		
		return this.context.store.getState().central[type];
	}
	
	render(){		
		return (
				<div>{this.props.children}</div>
				);
	}
}

function mapStateToProps(state) {
    return {
    	state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        ...CentralActions.modifyProps(dispatch),  
        actions: bindActionCreators({...CentralActions}, dispatch),    
    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
    /**,null
    ,  { withRef: true } **/
)(CentralForm);