import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as CentralActions from '../../OpidCore/public/js/actions/CentralActions';

//import { InputText, InputSelect, DatePicker, Bound, GrowableList } from '../../OpidCore/public/js/components';

import { Data } from '../../OpidCore/public/js/Data.js';
import { Tabular, TabularColumn } from '@opidcore/components/Tabular';

//import BasicFormView from './Form/BasicView.jsx';

import * as _ from 'lodash';
//import moment from 'moment';

class ListThing extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
                loginError: undefined
        };
    }
    
    componentWillReceiveProps(nextProps){
   	
   	
   	   	var form = null;
		if (this.form != form){
			this.form = form;
			this.loadData();
		}   	
    }
    
    componentWillMount(){
        this.props.central.Stuff.listViews();
      
   	    var form = null; 
   	    this.form = form;
   	    this.loadData();
        // fetch("/api/v1/Stuff/testData?instanceId=" + this.props.routeParams.id).then((r)=>r.json()).then((j)=>this.setState({data: j.result}))
    }
    
    
    loadData(){
    	if (this.props.centralPath != null){
	    	this.setState({ title: this.props.centralPath });
    		var myCentral = this.props.central[this.props.centralPath];
    		myCentral.list().then((r)=>{
	    		this.setState({data: r.result});
	    	});
    		
    		
    		
	    	
    	}
    }


    openRecord(id){
    	var location = this.props.editLink.replace(":id",id);
    	
    	this.props.history.push( location );
    }
    
    handleDelete(id){

        if (confirm('are you sure?')){
            if(this.props.editLink.indexOf("form") > 0){
                this.props.central.Stuff.deleteForm( id ).then((r)=>{
                    this.loadData();
                    APP.alert(r.result);
                });
            }
            else if(this.props.editLink.indexOf("dataview") > 0){
                this.props.central.Stuff.deleteView( id ).then((r)=>{
                    this.loadData();
                    APP.alert(r.result);
                });
            }
        }
    }
    
    
    render(){    

        var title = this.state.title;
        
           var data = new Data(this.state.data || []);
         
           var columns = [];
           
           if (this.state.data){
        	   var colNames = _.without(_.keys(this.state.data[0]), '__is_shallow','__type','savedinStorage');
        	   
        	   _.each(colNames, (colName)=>{
        		  
        		   	var val = this.state.data[0][colName];
        		   	
        		   	if(title == 'Definition'){
        		   	    if (! Array.isArray(val) && colName != 'options'){
                         columns.push( <TabularColumn key={colName}  data={(row)=>row[colName]} title={colName}/> );
        		   	    }
        		   	}
        		   	
        		   	if (! Array.isArray(val) && title != 'Definition'){
        		   		columns.push( <TabularColumn key={colName} data={(row)=>row[colName]} title={colName}/> );
        	   		}
        	    });
        	   
        	   columns.push( <TabularColumn data={(row)=><button onClick={()=>this.openRecord(row.id)}>edit</button>}
            	key="_edit"/> );
        	   
        	   columns.push( <TabularColumn data={(row)=><button onClick={()=>this.handleDelete(row.id)}>delete</button>}
               key="_edit"/> );
    		}
	
         	var table = <div>
         	<Tabular data={data.filter({}).reverse()} style={{minWidth: "80em"}}>
         		{columns}
         	</Tabular>
         	
         	</div>;
         	
           return <div className="container">
    				<h2>{title} <button onClick={()=>this.openRecord(0)}>new!</button></h2>       
           			{table}
	           </div>
            
    }
}

function mapStateToProps(state) {
    return {
        viewDefinitions: state.central.viewDefinitions || {},
        formDefinitions: state.central.formDefinitions || {},
    
    };
}

function mapDispatchToProps(dispatch) {
    return {
        ...CentralActions.modifyProps(dispatch),  
        actions: bindActionCreators({...CentralActions}, dispatch),    
    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ListThing));