import { InputText, InputSelect, InputCheckbox, TextArea, DatePicker, Calculated, Button, Bound, InputSelectFilter, InputMultiPeopleSearch, ToolTip, BoundComponent } from './components';

export default class Functioner {
    constructor( js ){
    		
    		js = js.replace(/\/\/.*/g, '/** i was a comment **/');
    		
    		js = js.replace(/\n/g," ").replace(/\r/g," ");
    		
            this.jsContents = js.replace(/\\?\\JS{(.*)\\\\?}/gi, function (x,a,b) {
                return a;
            });
            
    }
    
    getCode(){
        return this.jsContents;    
    }
    
    getWrapped(jsContents){
        return '\\JS{ ' + jsContents + ' \\}';
    }
    
    createModule(soure, NDT ){
    	try{
		    var createObj = new Function("return " + this.jsContents);
  		    var obj = createObj();  		
  		    obj.NDT = NDT;
  		    
  		    _.each( _.keys(obj), k=>{
  		    	if (typeof obj[k] == "function"){
  		    		obj["_" + k] = obj[k];
  		    		obj[k] = (a,b,c,d,e,f,g)=>{ 
  		    			var ret = undefined;
  		    			
	  		    		try{
	  		    			ret = obj["_" + k](a,b,c,d,e,f,g);
	  		    		}catch(e){ 
	  		    			console.log("Error in exception catch block", e )
	  		    			if (window.DEBUG){
	  		    				debugger;
	  		    			}
	  		    		};
  		    		
	  		    		return ret;
  		    		}
  		    	}
  		    });
  		  
  		    window[soure] = obj;
  		    
  		    if (obj.init){
  		    	obj.init(obj);
  		    }
    	}catch(e){
    		console.log("couldn't make a module", e);
    	}
    }
    
    static looksLikeIt(it, a, b){
    	const isJS = it  && it.indexOf && it.indexOf("\\JS") == 0;
    	
    	//this is temporary, and eventually will give false positives, TODO @ian remove me
    	if ( ( isJS == false ) && it && it.indexOf && (it.indexOf("NDT") >= 0 || it.indexOf("EVAL") >= 0)  ){
    		var likelyAProblem = true;
    		if (b && b.rootStructure.component == "Resource"){
    			likelyAProblem = false;
    		}
    		if (likelyAProblem){
    			window.DEBUG && console.log("this is old school and needs to be a js eval ", a, b);
    		}
    	}
    	
    	return isJS;
    }
    
    doEval(props,me){
        var myerror = "";
            try{
                var result = eval('(' + this.jsContents + ')');   
                
                return result;
            }catch(e){                
                   return "cheese";
            }
        
    }
    
    execute(arg1,arg2){
        var wtf = this.doEval();
        return wtf(arg1,arg2);
    }
}
