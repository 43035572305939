import React, { useContext, useEffect, useRef, useState } from 'react';
import { Bound, Button, Icon, InputSelect, Loading, Tabular, TabularColumn } from '@opidcore/components';
import { DataContext } from '@opidcore/components/Bound';
import { useData } from "@opidcore/hooks/WTF";
import _ from 'lodash';
import File from '../../components/File';

const MappingContext = React.createContext({maps: {}, setMaps: (newMaps) => {} });

const BoundValue = ({field, scope = {row: undefined}}) => {
	return <span key={"tabular_field_" + field}>{scope.row[field]}</span>;
}

const SpecialSelector = ( {fields = [], updater = (field, oldField)=>{ console.log("create an updater function") }, undo = (field) => { console.log("create an updater function") }} ) => {
    const {maps, setMaps} = useContext(MappingContext);
    const boundMagic = useContext(DataContext);
    const [myField, setMyField] = useState(undefined);

    const setField = (e) =>{
        const field = _.find(fields, {name: e.currentTarget.value});
        if(field == undefined){
            return;
        }

        maps[field.name + field.model] = field;
        setMaps(maps);
        if(myField != undefined){
            delete maps[myField.name + myField.model];
        }
        updater(field, myField);
        setMyField(field);
    }

    const unsetField = (field) =>{
        delete maps[field.name + field.model];
        setMaps(maps);
        undo(field);
        setMyField(undefined);
    }

    const exposedOptions = _.map(_.filter(_.sortBy(fields, "name"), (f) => {
        if (myField != undefined && myField.name == f.name && myField.model == f.model) {
            return false;
        }

        return true;
    }), (option) =>{
        return <option value={option.name}>{option.name}</option>;
    });

    let selectedField = null;
    if(myField != undefined){
        selectedField = <div><span>{myField.name}</span><Icon icon="times-circle" onClick={()=>unsetField(myField)}/></div>;
    }

    const model = boundMagic.magicalGet("tableName");

    return <div key="special_selector" className="special-selector">
        {selectedField}
        <label>{model}</label>
        <select onChange={setField} value={""}>
            <option value=""></option>
            {exposedOptions}
        </select>
    </div>
}

const ExtraHeadingRow = ({dataColumns = [], selectedModel = undefined}) =>{
    const [availableFields, setAvailableFields] = useState([]);

    useEffect(()=>{
        const fields = [];
        if(selectedModel != undefined){
            _.forEach(selectedModel.exposedFields, (f)=>{
                fields.push(f);
            });

            setAvailableFields(fields);
        }
    }, [selectedModel]);

    const filterFields = (field, oldField) =>{
        const newAvailableFields = [...availableFields];
        if(oldField != undefined && oldField.model == selectedModel.name){
            newAvailableFields.push(oldField);
        }
        setAvailableFields( _.filter(newAvailableFields, (f) =>{
            return !(f.name == field.name && f.model == field.model);
        }) );
    }

    const undo = (field) =>{
        if(selectedModel.name == field.model){
            setAvailableFields([...availableFields, ...[field]]);
        }
    }

    const mappings = _.map(dataColumns, (dC) =>{
        return <td key={dC.index}>
            <SpecialSelector fields={availableFields} updater={filterFields} undo={undo}/>
        </td>
    });

    return <tr key="extra_heading_row" className="extra-heading-row">
        {mappings}
    </tr>;
}

const TabularImport = ({data = [], columns = [], selectedModel = undefined}) => {
    const tabularColumns = _.map(columns, (col) => {
        return <TabularColumn title={col.key} key={col.key}>
            <BoundValue field={col.key}/>
        </TabularColumn>
    });

    return <div key="tabular_import" className="tabular-import">
        <label>Sample Results</label>
        <Tabular data={data} extraHeadingRow={<ExtraHeadingRow dataColumns={columns} selectedModel={selectedModel}/>}>
            {tabularColumns}
        </Tabular>
    </div>
}

const FileUploader = () =>{
    const boundMagic = useContext(DataContext);
    const file = boundMagic.magicalGet( "file" );
    const [loading, setLoading] = useState(false);

    const handleUpload = (f)=>{
		boundMagic.magicalSet( "file", f );
        setLoading(false);
	};

    const beforeUpload = () => {
        setLoading(true);
    }

    return <div key="file_uploader" className="file-uploader">
        <File onUpload={handleUpload} beforeUpload={beforeUpload}/>
        {file != undefined ? file.filename : ""}
        {loading == true? <Loading /> : undefined}
    </div>
}

const DataImporter = ({}) => {
    const mappingContext = useContext(MappingContext);
    const boundTo = useRef({model: undefined, tableName: undefined, file: undefined});
    const [models, modelsDataSet] = useData("importer_model_info", APP.central.Zoho.fetchModels);
    const [data, setData] = useState({columns: [], rows: []});
    const [selectedModel, setSelectedModel] = useState(undefined);

    const updateModel = (to, field, value, boundMagic) => {
        const model = _.find(models, (model) =>{
            return model.name == value;
        });

        boundMagic.magicalSet("model", model);
        setSelectedModel(model);
    }

    const loadData = (file)=>{
        let error = undefined;
        if(boundTo.current.file == undefined){ 
            error = "Missing file upload";
        }else if(boundTo.current.tableName == undefined){
            error = "No mapping model selected";
        }

        if(error != undefined){
            APP.instance.openConfirmDialog(error, "Failed To Load");
        } else {
            APP.central.Zoho.loadData(boundTo.current.file, "temp_" + boundTo.current.tableName).then(() =>{
                const query = "select * from temp_" + boundTo.current.tableName;
                APP.central.Zoho.testData(query).then( (r)=>{
                    const columns = r.result.columns;
                    const data = r.result.rows;
                    setData({columns: columns, rows: data});
                });
            });
        }
	}

    const doStuff = () =>{        
        debugger;
    }

    return <div key="data_importer" className="data-importer">
        <Bound to={boundTo.current}>
            <FileUploader />
            <InputSelect name="Mapping Model" field="tableName" showEmpty={true} options={_.map(models, (m)=>{ return {key: m.name, value: m.name} })} onChange={updateModel}/>
            <Button onClick={loadData}>Load</Button>

            <TabularImport data={data.rows} columns={data.columns} selectedModel={selectedModel}/>
            <Button onClick={doStuff}>Process</Button>
        </Bound>
    </div>
}

export default DataImporter;