
import React, { Component, useState, useEffect, useContext, useRef, Fragment }  from 'react';
import _ from 'lodash';
import {Util} from '../Util';
import Icon from './Icon';
import PropTypes from 'prop-types';

let __temp_last_click = null;

export function Selection(props){
    const [isChecked, setIsChecked] = useState(_.findIndex( props.selection, (s)=>_.isEqual(s,props.item)) >= 0);

    useEffect( ()=>{
        const checkedNow = _.findIndex( props.selection, (s)=>_.isEqual(s,props.item)) >= 0;
        if (checkedNow != isChecked){
            setIsChecked( checkedNow );
        }
    }, [props.selection]);

    const clicky = (e)=>{
        e.stopPropagation();

        const checked = e.currentTarget.checked;
        const newSelection = props.selection.slice();
        
        
        //const idx = newSelection.indexOf( props.item );
        const idx = _.findIndex( newSelection, (s)=>_.isEqual(s,props.item));

        if (checked == false && idx >= 0){
            newSelection.splice(idx, 1);
        }else if (checked == true  && idx == -1 ){
            if (e.shiftKey){
                let first = _.indexOf(props.data, __temp_last_click);
                let last = _.indexOf(props.data, props.item);
                if (first > last){
                    const lastLast = last;
                    last = first;
                    first = lastLast;
                }
                for (let x = first; x<=last; x++){
                    if (newSelection.indexOf(props.data[x]) == -1){
                        newSelection.push( props.data[x] );
                    }
                }
            }else{
                newSelection.push( props.item );
            }

            __temp_last_click = props.item;
        }
        
        props.setter(newSelection);
    };
    
    const stuff = {};
    if (isChecked){
        stuff.checked = true;
    }

    const TagName = props.tagName || "input";

    return <TagName type="checkbox" onClick={clicky} {...stuff}/>;
}

export class Tabular extends Component {
    constructor(props) {
        super(props);
        this.state = { selected: [], sortedData: null, loaded: props.loaded};
        if (props.defaultSort != undefined){
            if(typeof props.defaultSort == "function"){
                this.state.sort_by = props.defaultSort;
            } else {
                this.state.sort_by = (r)=>r[props.defaultSort];
            }
        }
            
        if (props.defaultSortOrder)
            this.state.currentSortOrder = props.defaultSortOrder;
   }

  
  onAuxClick(e,r) {
    if (this.props.onAuxClick){
      this.props.onAuxClick(r,e);
    }
  }
  
   
   onClick(e,r){
       //return false from the onclick to prevent our select logic from running.
       if (this.props.onClick){
           if ( this.props.onClick(r,e) == false ){
               return;
           }
       }
       
       var s = this.state.selected.slice();
       if (_.includes(s, r)){
           _.remove(s, r);
       }else{
           s.push(r);
       }
       const callback = this.props.onSelect ? ()=>this.props.onSelect(s) : null;
       this.setState({selected: s},callback)
   }
   
   static getDerivedStateFromProps(nextProps, nextState){
           var currentlySelected = _.map(nextState.selected, nextProps.selectKey);
       
           if (true && nextProps.data){
               
               var ret = {selected: nextProps.data.filter(a=>currentlySelected.indexOf(a[nextProps.selectKey])>=0)};
               
               if ( nextState.sortedData == null || nextState.sortedData.length !=  nextProps.data.length || nextProps.ticky ){
                   ret.sortedData = nextProps.data;
               }else if (_.difference(nextState.sortedData, nextProps.data).length > 0){
                    //this should stop ticky from being used, but might be slow?
                    ret.sortedData = nextProps.data;

                    let sortedDataSet = false;

                    //only sort by function if we haven't done a server sort... s
                    if (nextProps.dataSet != null && nextProps.dataSet._sort != null){
                        sortedDataSet = true;
                    }

                    if (typeof nextState.sort_by == "function" && sortedDataSet == false){
                        ret.sortedData = _.sortBy( ret.sortedData,  (r)=>nextState.sort_by(r) );
                    } 
               } 

               return ret;
               
              // return {selected: nextProps.data.filter(a=>currentlySelected.indexOf(a[nextProps.selectKey])>=0)};
           }
       
           return null;
   }

    componentDidMount(){
        this.updateSortedData();
        this.setState({pageSize: this.props.pageSize ? this.props.pageSize : null});

    }
   
   componentDidUpdate(prevProps){
       if(this.props.filter != undefined && this.props.filter != prevProps.filter || this.props.loadedCount != prevProps.loadedCount){
           this.updateSortedData();
       }
   }
   
   clickHeading(column, currentSort){
       
       var sortOrder = "asc";
       if (this.state.currentSort == column.props.title){
           sortOrder = this.state.currentSortOrder == "asc" ? "desc" : "asc";
       }
       this.setState({sort_by_column: column.props, sort_by: column.props.data, currentSort: column.props.title, currentSortOrder: sortOrder}, 
                    
                    ()=>{
           this.updateSortedData();
       });
   }
   
   updateSortedData(){
       if (this.props.onSort){
           const ret = this.props.onSort(this.state.sort_by_column, this.state.currentSortOrder);
           if (ret == false){
               return;
           }
       }
       var sortedData = this.props.data || [];
       if (this.state.sort_by){
           
          
           
          sortedData = _.sortBy(this.props.data, r=>{ 
                                                        try{
                                                            var el = this.state.sort_by(r);
                                                     
                                                            return this.textOf(el, this.state.sort_by_column);
                                                        }catch(exception){
                                                            console.log("exception occured in tabular's sort... ", exception);
                                                            return "";
                                                        }
                                                    });
           if (this.state.currentSortOrder == "desc"){
               sortedData = _.reverse(sortedData);
           }
       }

       this.props.afterSort && this.props.afterSort(sortedData);
                            
       this.setState({sortedData: sortedData});
   }
   
   textOf(el, colDef){
       var ourValue = el;
       
       if (el && typeof el == "string"){
        return el;
       }

       if (el && typeof el.type == "function"){
           try{
            el = el.type(el.props)
           }catch(ex){

           }
       }

       if(el && (typeof el == "object" && el.props)){
        if (typeof el.props.children == "object"){
               //ourValue = _.join(_.map(el.props.children, a=>this.textOf(a)), ' ');
               ourValue = _.join(_.map(_.flatten(_.map(el.props.children, a=>this.textOf(a))), (a) => a && a.children ? a.children : "" ), "")
           }else{
               ourValue = el.props.children;
           }
       }
       
       if (colDef && colDef.className && colDef.className.indexOf("numeric") >= 0){
               if (ourValue.replace){
                   ourValue = ourValue.replace(",", "");
               }
               ourValue = parseFloat(ourValue);
       }
           
       return ourValue;
   }
   
  setPage(p){
    let pageChunks = _.chunk(this.state.sortedData, this.state.pageSize);
    if(p < 0 || p >= pageChunks.length) {
      return false;
    }
    this.setState({page: p});
    return false;
  }

  showAllPages(){
    if(this.state.pageSize == 10000 && this.props.pageSize) {
      this.setState({pageSize: this.props.pageSize});
      this.updateSortedData();
      return false;
    }
    this.setState({pageSize: 10000});
    this.updateSortedData();
    return false;
  }
   
   render(){
        var columnDefinitions = _.compact(React.Children.map(this.props.children, (c) => {
          if(c != null && c.props) {
            return c;
          } else {
            return null;
          }
        }));
        var rows = [];
        var count = 0;
        
        //columnDefinitions = _.flatten(_.filter(columnDefinitions, (datum) => {return datum != null}));
        //this.props.rowGroupings = {column.theId + "" + rowVal: # of occurrences, ...}
        
        var headings = [];
        this.props.hideHeadings
          ? headings = []
          : (headings = _.map(columnDefinitions, (c) => {
              let decoration = null;
              if (this.state.currentSort == c.props.title && c.props.title != undefined && c.props.title.length > 0) {
                decoration = <Icon icon={this.state.currentSortOrder == "asc" ? "arrow-alt-down" : "arrow-alt-up"} key={"decoration" + count} />;
              }

              const HeadingComponent = c.props.headingComponent || Fragment;
              const headingProps = c.props.headingComponent ? { ...c.props } : {};

              return (
                <th
                  key={"heading" + count++}
                  onClick={() => this.clickHeading(c)}
                  className={
                    (c.props.className || "") +
                    " " +
                    (this.state.currentSort == c.props.title && c.props.title != undefined && c.props.title.length > 0
                      ? "sorted-" + this.state.currentSortOrder
                      : "")
                  }
                  style={c.props.style}
                >
                  <HeadingComponent {...headingProps}>{c.props.title}</HeadingComponent> {decoration}
                </th>
              );
            }));
       var that = this;
       var seenGroups = {};

       let visibleData = this.state.sortedData;
        let pages = [];
        if (this.state.pageSize){
            let pageChunks = _.chunk(visibleData, this.state.pageSize);
            if (true){
                const currentPageIdx = this.state.page || 0;
                visibleData = pageChunks[currentPageIdx];

                pages = pageChunks.map( (c,i)=>{
                    return <span key={i} className={"page-num" + (currentPageIdx == i ? " page-num-active" : "")} onClick={()=>this.setPage(i)}>{i+1}</span>;
                });

                //this was adapted from Pagination.jsx to make things pretty, 
                // if it breaks something tell cesar to fix it :(
                let subArrayOfPages = pages;
                let pageRangeLower = currentPageIdx - 4;
                let pageRangeUpper = currentPageIdx + 4;
                if(pageRangeLower < 0){
                    pageRangeLower = 0;
                }
                if(pageRangeUpper >= pageChunks.length){
                    pageRangeUpper = pageChunks.length;
                }
                const firstPage = pageRangeLower > 0 ? pages[0] : null;
                const lastPage = pages.length > 0 ? pages[pages.length-1] : null;
                subArrayOfPages = pages.slice(pageRangeLower, pageRangeUpper);
                if (subArrayOfPages.indexOf(firstPage) == -1 && firstPage != null){
                    if (pageRangeLower > 1){
                        subArrayOfPages.unshift(<span key={"spanTo" + (pageRangeLower-1)}>...</span>);
                    }
                    subArrayOfPages.unshift(firstPage);
                }
                if (subArrayOfPages.indexOf(lastPage) == -1){
                    subArrayOfPages.push(<span key={"spanTo" + (pageRangeUpper+1)}>...</span>);
                    subArrayOfPages.push(lastPage);
                }
                pages = subArrayOfPages;
                //^^^^

                pages.unshift(<Icon icon="chevron-double-left" onClick={()=>this.setPage(currentPageIdx-1)} key={"back"}/> );
                pages.push(<Icon icon="chevron-double-right" onClick={()=>this.setPage(currentPageIdx+1)} key={"next"}/>);
         
            }   
        }

       rows = _.map(visibleData, (row, idx)=>{ 
    	   if (this.props.dataModifier){
    		   row = this.props.dataModifier(row);
    	   }
           var cells = [];
           _.forEach(columnDefinitions, (c)=>{
               var tdValue = null;
               
               if (c.props.children){
                   if(Array.isArray(c.props.children)){
                       tdValue = c.props.children.map((child)=>{
                           var props = {}
                           props.scope = {...child.props.scope};
                           props.scope.row = {...row};
                           props.key = "clone" + count++;
                           return React.cloneElement( child, props );
                       });
                   } else {
                       var props = {};
                       props.scope = {...c.props.children.props.scope};
                       props.scope.row = {...row};
                       props.key = "clone" + count++;
                       tdValue = React.cloneElement( c.props.children, props );
                   }
                   
               } else {
                   try{
                    tdValue = c.props.data(row, idx, parseInt(idx) > 0? visibleData[parseInt(idx)-1]: null);                    
                   }catch(e){
                    tdValue = <span title="Error in Tabular">?</span>;
                   }
                   
               }
               
                if (typeof tdValue == "object" && ( ! Array.isArray(tdValue) )  && tdValue != null && (! React.isValidElement(tdValue)) ){
                   tdValue = "..."; //JSON.stringify(tdValue).substring(0,10) + "...";
               } 
               
               const keyLookup = c.props.title + "" + tdValue;
               
               if(tdValue != undefined && typeof tdValue == "string" && tdValue.trim().length > 0 && c.props.fieldType == "colour"){
                   tdValue = <span style={{backgroundColor: tdValue, paddingLeft: "10px", marginLeft: "5px"}}>&nbsp;</span>;
               }
               
               if(this.props.rowGroupings != undefined && this.props.rowGroupings[keyLookup] != undefined){
                   if(("" + tdValue).trim().length > 0 && seenGroups[keyLookup] == undefined){
                       //we haven't seen this value in this column yet so make a group of it
                       cells.push( <td rowSpan={this.props.rowGroupings[keyLookup]} className={"tabular-td " + (c.props.className || "") } style={c.props.style} key={"cell" + count++}>{tdValue}</td> )
                       seenGroups[keyLookup] = "seen";
                   }
               } else {
                   cells.push( <td className={"tabular-td " + (c.props.className || "") } style={c.props.style} key={"cell" + count++}>{tdValue}</td> );
               }
           });             
           
           const hash = this.props.hashWithPosition ?  Util.memoHash(row) + "-" + idx : Util.memoHash(row);
           //hash causes key issues with basic tabulars that have a select all option
           
           if (this.props.showRaw){
        	   cells.push( <td key="raw"><Icon icon="file-code" onClick={()=>console.log("Row source",  JSON.stringify(row))}/></td> );
           }
           
           let trClassName = "tabular-row";
           if (_.includes(that.state.selected, row)) {
               trClassName += " selected";
           }
           if (this.props.onClick != undefined) {
               trClassName += " clickable";
           }
            if (this.props.rowClass != undefined) {
              trClassName += " " + this.props.rowClass(row);
            }
           return <tr key={"row-" + idx + hash} className={trClassName} onClick={(e)=>that.onClick(e, row)} onAuxClick={(e)=>that.onAuxClick(e, row)} >{cells}</tr>
       });

       let classes = this.props.className + "";
       if(this.props.sticky){
            if(classes.indexOf("sticky") < 0){
                classes += " sticky";
            }
       }

       //returns true if no server side pagination and there are pages to show
      const showLocalPagination = () => {
        if(this.props.dataSet && this.props.dataSet.paginationInfo && this.props.dataSet.paginationInfo.totalPages == 1 && pages.length > 0) {
          return true;
        }
        if(!(this.props.dataSet && this.props.dataSet._paginate) && pages.length > 0) {
          return true;
        }
        return false;
      }

      const getShowAllToggle = () => {
        if(this.props.showAllToggle) {
          const icon = this.state.pageSize == 10000 ? "chevron-double-up" : "chevron-double-down";
          const title = this.state.pageSize == 10000 ? "Paginate" : "Show All Rows";
          return <Icon icon={icon} title={title} onClick={() => this.showAllPages()}/>;
        }
      }

      //if no server side pagination, there are pages to show, and the location is top or both
      const topPagination = () => {
        if(showLocalPagination() && (this.props.paginationLocation == "top" || this.props.paginationLocation == "both")) {
          return <div className="pagination"> {pages} {getShowAllToggle()} </div>;
        }
        return null;
      };

      //if no server side pagination, there are pages to show, and the location is bottom, both, or not specified
      const bottomPagination = () => {
        if(showLocalPagination() && (this.props.paginationLocation == "bottom" || this.props.paginationLocation == "both" || !this.props.paginationLocation)) {
          return <Fragment><div className="pagination">{pages}</div></Fragment>;
        }
        return null;
      };

       return (
         <>
           {topPagination()}

           <table style={this.props.style} className={"tabular " + classes}>
           {this.props.tableCaption ? <caption>{this.props.tableCaption}</caption> : null}
             <thead>
               {this.props.extraHeadingRow ? this.props.extraHeadingRow : null}
               <tr>{headings}</tr>
             </thead>
             <tbody>{rows}</tbody>
           </table>

           {bottomPagination()}
         </>
       );
   }
}

Tabular.propTypes = {
    sticky: PropTypes.bool
}

export class TabularColumn extends Component {
   render(){
       return <td></td>
   }
}

TabularColumn.propTypes = {
    style: PropTypes.object,
    data: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    sortCol: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
}

export function TabularDataSet(props){
    const loadedCount = useRef(-1);
    const [myData, setMyData] = useState([]);
    const [isSorted, setIsSorted] = useState(false);
    let myProps = {...props};

    const refresh = (data) => {
        if (props.dataSet && props.dataSet.loadedCount > loadedCount.current) {
            loadedCount.current = props.dataSet.loadedCount;
            setMyData(props.dataSet.filter(props.filter));
        }
    }
    
    useEffect(()=>{
        if(props.dataSet){
            if(_.filter(props.dataSet._callbacks, (cb) => cb.name == "refresh").length == 0) {
                props.dataSet.callback(refresh);
            } 
            if (loadedCount.current == -1){
                refresh();
            }
        }
    },[props.dataSet]);
    
    myProps.data = myData;
    myProps.loadedCount = loadedCount.current;
    myProps.onSort = (col, order)=>{
        const a = myData;

        if (col && col.sortCol){
            props.dataSet.sort(col.sortCol,order)
            setIsSorted(true);
            return false;
        }

        setIsSorted(false);
        return true;
    };

    if (isSorted){
        delete myProps.defaultSort;
        delete myProps.defaultSortOrder;
    }

    if(myProps.data.length > 0){
        return <Tabular {...myProps}/>;
    }
    
    if(myProps.data.length == 0 && myProps.loadedCount > 0){
    	return  <div className="loading">{props.noData || "No Data!"}</div>;
    }
    
    return <div className="loading">Please Wait</div>;
}

TabularDataSet.propTypes = {
    defaultSort: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.instanceOf(null)]),
    defaultSortOrder: PropTypes.string,
    dataSet: PropTypes.object,
    filter: PropTypes.func,
    noData: PropTypes.string,
    children: PropTypes.any,
    onClick: PropTypes.func,
    onAuxClick: PropTypes.func,
    hashWithPosition: PropTypes.bool,
    pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(null)]),
    paginationLocation: PropTypes.string,
    showAllToggle: PropTypes.bool,
    className: PropTypes.string,
    sticky: PropTypes.bool,
    rowClass: PropTypes.func,
    hideHeadings: PropTypes.bool, // hide the headings
    extraHeadingRow: PropTypes.any, // add an extra row of headings which can span multiple columns ex: <tr><th colSpan={2}>{stuff}</th></tr>
    tableCaption: PropTypes.string // add a caption to the table, prop will be redered as <table> <caption>{tableCaption}</caption> ... </table>
}

