import React, {Component} from 'react';
import { Tabular, TabularColumn } from '@opidcore/components/Tabular';
import * as _ from 'lodash';
//import * as CentralActions from '../../../OpidCore/public/js/actions/CentralActions';
//import Modal from './Modal.js';
//import EditInModal from './EditInModal.jsx';

class SimpleListView extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	componentWillMount() {
		this.props.central
	}
	
	
	render() {
		var useableKeys = null;
		
		var data = this.props.NDT.getData(this.props.dataset);
		data = data.filter();
		var tst = React.Children.count(this.props.children);
		
		if (data && (data.length > 0) && this.props.fields) {
			var dataKeys = Object.keys(data[0]);
					
			useableKeys = _.intersection(dataKeys, this.props.fields);
		}
		else if(data && (data.length > 0)){
		    useableKeys = Object.keys(data[0]);
		}
		
		var columns = _.map(useableKeys, function(datum) {return <TabularColumn key={datum} data={row => row[datum]} title={datum} />});
		

		//editor that worked:
		//	    columns.push(<TabularColumn data={(row)=>{ return <EditInModal rowData={row} data={this.props.children}/>}} title={""}/>);
		var count = 0;
		_.each(this.props.children, (child)=>{
		    columns.push( <TabularColumn key={"child" + count++} data={(row)=>{ return React.cloneElement(child, {rowData: row})}} title={"?"} /> )
		});		

		
		if (data  && (data.length > 0) && useableKeys && this.props.fields) {
				return <>
				
				<Tabular data={data}> 
					{columns}
				</Tabular>
				<section id="modal"></section>
				</>
		}
		
		else {
			return <h2>nil</h2>;
		}
	}
}

export default SimpleListView;