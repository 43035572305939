import React from 'react';
import { ActionBar, Bound, Button, DatePicker, InputText, Loading, Pagination, TabularColumn, TabularDataSet, useMagic } from "@opidcore/components";
import LoadingProgress from "@opidcore/components/LoadingProgress";
import { useData } from '@opidcore/hooks/WTF';
import _ from 'lodash';
import moment from 'moment';


const ExportARInvoices = ({}) => {    
    const bound = useMagic({dateFrom: moment().format("YYYY-MM-DD"), dateTo: moment().format("YYYY-MM-DD"), method: "exportRaw", paginate: 200});
    
    const [data, dataSet] = useData("reports", {method: "exportRaw"});
       
    const doGenerate = ()=>{
        const a = bound;
        const b = dataSet;
        
        dataSet.setQueryArgs({options: _.pick(bound.to, ['dateFrom', 'dateTo','customer','umbrella','status', 'method', 'paginate']) });
        dataSet.fetch();
    }

    const doExport = () => {
      APP.showLoading({ message: "Generating Report", children: <LoadingProgress /> }, () => {
        APP.central.Reports.dumpToExcel(dataSet.uid, "Raw Export").then((r) => {
          // I know this doesn't make sense but it posts back the running task so it know who to "tell" it's done.
          // download file
          APP.socket.send(JSON.stringify(r));
        });
      });
    };

    const columns = dataSet.columns ? dataSet.columns.map( (col)=><TabularColumn key={col.key} data={(row)=>row[col.key]} title={col.key}/> ) : [];
    
    return <div>


            <ActionBar title="Actions">

				
				
			</ActionBar>


            <h2>Export AR Invoices</h2>

            <Bound to={bound}>
                <DatePicker field="dateFrom" name="Date From"/>
                <DatePicker field="dateTo" name="Date To"/>

                <InputText field="customer" name="Customer"/>
                <InputText field="umbrella" name="Umbrella"/>

                <InputText field="status" name="Status"/>

                <Button onClick={()=>doGenerate()}>Refresh</Button>
                <Button onClick={()=>doExport()}>Export</Button>
                {dataSet.loading ? <Loading /> : ""}
            </Bound>

            <Pagination dataSet={dataSet}/>
            <TabularDataSet dataSet={dataSet} noData="Select a date range">
                {columns}
            </TabularDataSet>
    </div>
}

export default ExportARInvoices;