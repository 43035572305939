import React from 'react';
//import { Data } from '@opidcore/Data.js';
import { Link } from 'react-router-dom';
import Icon from '@opidcore/components/Icon.jsx';
//import BarGraph from '../BarGraph.jsx';
import { useData } from '@opidcore/hooks/WTF';
import { Tabular, TabularColumn } from '@opidcore/components/Tabular'; 

//import ViewView from '../../../js/view.jsx';

function DeleteButton(props){
    const handleDeleteRow = (row)=>{
        let confirmed = confirm("Deleting. Are you sure?");
        //()=>{ this.refs.editor.saveAllContent()
        if(confirmed){
        	APP.central.Stuff.deleteInstance(row.id).then((r)=>{
        		props.onDelete();
        	});
            //do something
            //props.myUpdater("DELETE_ROW", section, row); 
        }
    }
    
    return <span onClick={()=>handleDeleteRow(props.scope.row)}> <Icon icon="trash" size="2x"/> </span>;
}


export default function WasteAuditTenants(props){
	const [audit, auditDS] = useData("waste_audit", props.match.params.id);
	const auditId = parseInt( props.match.params.id );
	
	const [data, dataSet] = useData("tenant_profile", {"waste_audit_num": auditId});
	
	let noun = "Tenants";
	if (audit.tenant_noun){
		noun = audit.tenant_noun;
	}
	

	if (noun.lastIndexOf('s') == noun.length - 1){
		noun = noun.substring(0,noun.length-1);
	}

	const newTenant =  (auditId) => {
		APP.central.WasteAudit.addTenant( auditId ).then (r=>{
			const tenant = r.result;			
			APP.redirect("/view/tenant_profile/" + tenant.id + "?afterSave=back");	
		});		
	}

    return <div className="afull-size">   	
    	<a className="btn btn-default" onClick={()=>newTenant(auditId)}>New {noun}</a>
    
    	<Tabular data={data}  style={{width: "50%"}}>
    	<TabularColumn data={(row)=>row.tenant_name} title={noun + " Name"}/>
		<TabularColumn data={(row)=>row.tenant_num} title={noun + " #"}/>
		<TabularColumn data={(row)=>row.unit_num} title="Unit #"/>
				
   		<TabularColumn data={(row)=>{ return <Link to={"/view/tenant_profile/" + row.id + "?afterSave=back"}> <Icon icon="pencil" size="2x"/> </Link> }}/>
   		<TabularColumn><DeleteButton myUpdater={props.myUpdater} onDelete={()=>dataSet.fetch()}/></TabularColumn>
				
		</Tabular>		
    </div>;
}
