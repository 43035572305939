import React, { Component, useState, useEffect, useContext }  from 'react';
import ARInvoice from './ARInvoice.jsx';
import PDFPreview from './PDFPreview.jsx';
import PDFSetup from './PDFSetup.jsx'
import PayablesProcess from './PayablesProcess.jsx';

// 
// import { Link } from "react-router-dom";
// import Icon from '@opidcore/components/Icon';

export default function initialize(permissions, options){
	if(permissions.w || true){
		window.APP.customRoutes.push({path: "/ui/snowman/ap/:id", component: ARInvoice});
		window.APP.customRoutes.push({path: "/ui/snowman/ap", component: ARInvoice});
		window.APP.customRoutes.push({path: "/ui/snowman/payables/:id", component: PayablesProcess});
		window.APP.customRoutes.push({path: "/ui/snowman/payables", component: PayablesProcess});
		window.APP.customRoutes.push({path: "/ui/snowman/pdf/:md5", component: PDFPreview});
		window.APP.customRoutes.push({path: "/ui/snowman/pdfsetup/:workflow/:md5", component: PDFSetup})
	}
			
	/** APP.addMenu( ()=>{
		const items = [];
		items.push(<li key="payables"><Link to="/ui/apinvoice/list"><Icon icon="file-invoice-dollar"/> Payables</Link></li>);					

		
		return items;
	}); **/
		
}