import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import * as _ from 'lodash';

import { Tabular, TabularColumn } from '@opidcore/components/Tabular';

import Functioner from '../../Functioner.js';
import Bound from '../Bound.js';
import InputText from '../InputText.js';
import InputSelect from '../InputSelect.js';
import InputCheckbox from '../InputCheckbox.js';

import FileBrowser from './Types/FileBrowser.jsx';
import StyleBrowser from './Types/StyleBrowser.jsx';

import Icon from '@opidcore/components/Icon.jsx'

import { DataContext } from '../Bound';
import ArrayOf from '../../ArrayOf.js';


class JsAble extends Component {
	   
    static contextType = DataContext;
    
    constructor(props, ctx){
		super(props, ctx);
		
		var currentValue = this.context.magicalGet(props.field)
		//this.functioner = new Functioner();
		
		//if (this.function.isfunctionable(currentValue)){
		//}
		if(Functioner.looksLikeIt(currentValue)){
			var func = new Functioner(currentValue)
			this.state = {isJs: Functioner.looksLikeIt(currentValue), currentValue: func.getCode()};	
		}else{
			this.state = {isJs: false}
		}
		

	}
    
    makeJS(){
    	this.setState({isJs: true});
    }
    
    makePlain(){
    	this.context.magicalSet(this.props.field, null);
    	
    	this.setState({isJs: false});
    }
    
    evalJS(){
    	var code = this.refs.textarea.value;
    	var func = new Functioner(code);
    	
    	var theProps = this.props.NDT.getAllNodes( this.props.struct )[0].props;
    			
    	var ret = func.doEval(theProps); 
    	if (ret != "cheese"){
    		var saveCode = func.getWrapped( func.getCode() );
    		this.context.magicalSet(this.props.field, saveCode);
    	}
    	
    	this.setState({eval: JSON.stringify(ret)});
    }

	render(){
		if (this.state.isJs){
			let guessLabel = this.props.me.name || this.props.field;
			
			return <div className="field">
				<label>{guessLabel}</label>
				<textarea ref="textarea" defaultValue={this.state.currentValue}></textarea>
				<br/><button onClick={()=>this.evalJS()}>eval</button>
				<button onClick={()=>this.makePlain()}>remove js expression</button>
				
				<pre>{this.state.eval}</pre>
			</div>;		
			
		}
		return <div>  <button className="makeJs" onClick={()=>this.makeJS()}>make js</button> 
		{this.props.children}
		</div>
	}
}

class EditorPanel extends Component {
	
	render(){
		return <div className={"editorPanel " + this.props.className}><button className="close" onClick={()=>this.props.closeAction()}>X</button>
		{this.props.children}
		</div>
	}
}

export class EditorPanelDock extends Component {
	constructor(props){
		super(props);
		// maybe cody wants less than 30%
		this.state = {panelWidth: Math.floor(window.innerWidth * 0.30), collapsed: true, editorCount: 0, activeTab: null};
		
	
	}
	
	

    handleDragPanel(e, type){
		e.preventDefault();
		
    	if (type == "start"){
    		window.onmouseup = (e)=>this.handleDragPanel(e, "end");
    		window.onmousemove = (e)=>this.handleDragPanel(e, "drag");

    		document.body.className = "weare-ew-dragging";
    		this.startX = e.clientX;
    		this.origWidth = this.state.panelWidth;
    	}
    	
    	if (type == "drag"){
    		var increase = this.startX - e.clientX;
    		this.setState({panelWidth: this.origWidth + increase})
    	}
    	
    	if (type == "end"){
    		window.onmouseup = null;
    		window.onmousemove = null;
    		document.body.className = document.body.className.replace("weare-ew-dragging", "");
    	}
   
    }
	
    search(){
    	var a = this.refs.search;
    	var value = a.value.toLowerCase();
    	var results = _.map(this.props.structure, (s)=>{
    		var x = JSON.stringify(s).toLowerCase();
    		if (value.length > 0 && x.indexOf(value) >= 0){
    			var start = x.indexOf(value);    			
    			var preview = "..." + x.substring(start-10, start+30) + "...";

    			return {preview: preview, result: s};
    		}
    		
    	});
    	
    	this.setState({results: _.compact(results)});
    }
    
    componentDidUpdate(prevProps){
    	if (this.state.editorCount < this.props.editors.length && this.state.collapsed == true){
    		this.setState({collapsed: false, editorCount: this.props.editors.length});
    	}
    }
    
	add(){
		console.log("top level add button pressed");
		if (this.props.add){
			this.props.add();
		}
		setTimeout(()=>{
			try{
				document.getElementsByClassName("inlineModal")[0].scrollIntoViewIfNeeded();
			}catch(e){
				
			}
		}, 400)
	}
	
	clickSearchResult( id ){
		const node = this.props.NDT.getNode(id);
		const el = node != null ? node.element : null;
		
		if (el != null ){
			document.scrollingElement.scrollTo( 0, el.offsetTop - 10 )
		}
		
		this.setState({results: []}, ()=>{
		    this.props.onStartEditing(id);
		});
	}
	
	openTab(key){
		if (this.state.activeTab == key){
			this.setState({activeTab: null});
		}else{
	    	this.setState({activeTab: key});
		}
	}
	
	render(){
		
        var editorPanels = [];
        this.props.editors.map((editor)=>{
        	var TheEditorComponent = Editor;
        	if (editor.editorComponent){
        		TheEditorComponent = editor.editorComponent;
        	}
        	const closeAction = ()=>this.props.closeEditor(editor);
        	editorPanels.push(<EditorPanel key={editor.id} ref={"editor_" + editor.id} className={this.props.hoverElements.indexOf( editor.id) >= 0 ? "hovered" : ""} me={editor} closeAction={closeAction}><TheEditorComponent ref="editor" {...editor.editorProps}  closeAction={closeAction}/></EditorPanel>);
        });
       
        var resultsItems = [];
        var searchX = 0;
        if (this.state.results && this.state.results.length > 0){
        	_.each(this.state.results, (searchResult)=>{
        		const resultNode = APP.NDT.getNode(searchResult.result.theId);
        		const metaInfo = resultNode ? resultNode.props.metaInfo : null;
        		
        		if (metaInfo){
	        		const icon = metaInfo.icon || "question-circle";
	        		var preview = searchResult.preview;
	        		if (metaInfo.preview){
	        			if (typeof metaInfo.preview == "function"){
	        				try{
	        					preview = metaInfo.preview(resultNode);
	        				}catch(e){
	        					console.log("preview unavailable because", e);
	        					preview = "preview unavailable";
	        				}
	        			}
	        		}
	        		resultsItems.push(<li onClick={()=>this.clickSearchResult(searchResult.result.theId)} key={searchX++}><span className="type"><span title={searchResult.result.component + searchResult.result.cid ? " - " + searchResult.result.cid : ""}><Icon icon={icon}/></span></span> {searchResult.result.cid ? <span className="cid">{searchResult.result.cid}</span> : null} {preview} </li>);
        		}else{        			
	        		resultsItems.push(<li onClick={()=>this.clickSearchResult(searchResult.result.theId)} key={searchX++}><span className="type">{searchResult.result.component} (META NEEDED)</span> {searchResult.result.cid ? <span className="cid">{searchResult.result.cid}</span> : null} {searchResult.preview} </li>);
        		}
        	});
        }
        var results = resultsItems.length > 0 ? 
        		<ul className="editor-search">{resultsItems}</ul> : null;
        		
        const flowOrder = _.cloneDeep(this.props.hoverElements).reverse();
        var editorOuterStyle={width: this.state.panelWidth};
                
        const currentOuterStyle = {
                height: "50px",
                overflowY: "hidden",
                overflowX: "hidden",
                textOverflow: "ellipsis"
        };
        
        const openEditor = ()=>{
            APP.NDT.getEditor().editPage();
            this.setState({collapsed: false});
        }        
        
        if (this.state.collapsed){
        	
        	const btn = 
        	
        	APP.setState("navbar-buttons", <Icon icon="drafting-compass" type="span" onClick={openEditor}/>);  
        	
        	return <div className="OpenCrazyEditor" onClick={openEditor}><Icon icon="drafting-compass"/></div>;
        	
        }
        
        var tabContent = null;
        
        
        var tabs = [];
       
        
        var tabs =  _.map(this.props.NDT ? this.props.NDT.tabs : [], (children, key)=>{
        	var items = _.map(children, (c,k)=>{
        	    if( React.isValidElement(c) ){
        	        return <div key={k}>{c}</div>;
        	    } else {
        	        return <li key={k} onClick={(e)=>this.props.onStartEditing(k, e)}>{k}</li>
        	    }
        	    
            });
        	
        	if (this.state.activeTab == key){
        		tabContent = <ul className="items">{items}</ul>
        	}
        	
        	return <div key={key} className={"tab " + (this.state.activeTab == key? "activeTab" : "")} onClick={()=>this.openTab(key)}>
        		{key}        	
        	</div>;
        	
        });
        	
        
        return <div className="OuterCrazyEditor" style={editorOuterStyle}><div className="handle" onMouseDown={(e)=>this.handleDragPanel(e, "start")}/>
        			
        			<button className="close" onClick={()=>{this.setState({collapsed: true}); this.props.onClose && this.props.onClose() }}>X</button>
        			<div className="search-outer">
		        		<div className="search-box">
		        			<input ref="search" onChange={(e)=>this.search(e)}/>
		        			<button className="clear" onClick={()=>{this.setState({results: []})}}>clear</button>
	        			</div>
	        			{results}
        			</div>
        			
        			<div className="editorContent">
	        			<div className="currentOuter" style={currentOuterStyle}>{flowOrder.join( ' > ')}</div>
	        			
	        			
	        			
	        			{this.props.children}
	        			
	                	{editorPanels.reverse()}
	                	
	                	<button type="button" className="btn btn-default btn-add" onClick={()=>this.add()}>Create New Element</button>
	                	
	                	
                	</div>
                	
                <div className="tabContainer">
                	<div className="tabs">{tabs}</div>
                    <div key={"contents-" + this.state.activeTab} className="tabContents">
                    	{tabContent}
                    </div>
                </div>
                
                </div>;

                
	}
}


export default class Editor extends Component {
    
    constructor(props) {
        super(props);
        
        this.state = {};

        if (this.props.rootStructure && this.props.rootStructure.type == "DataSet"){
    		this.updateDS();
    	}
    }
    
    handleQueueChanges(e){
    	this.props.save(e,this.tempOptions);
    	
    	setTimeout(()=>{
    		this.updateDS();
    	}, 500);
    }
    
    updateDS(){
		var ds = this.props.NDT.getData(this.props.struct.cid);
		
		var cols = _.keys(ds.rawData[0])
		var columns = [];
		_.each(cols, (name)=>{
		    columns.push( <TabularColumn key={name} data={(row)=>{ return row[name] }} title={name} /> );
		});		
		var data = ds.filter();
		
		this.setState({columns: columns, data: data});

    }

	updateTable() {
		this.forceUpdate();
	}
    
	toggleShowExtraActions(){
	    this.setState({showExtraActions: this.state.showExtraActions? !this.state.showExtraActions : true});
	}
	
	render(){
		var structToEdit = this.props.struct;
		
		var defaultOptions = this.props.options;
		var uid = this.props.id; //structToEdit.uid;
		
		var that = this;
		var createdInputs = false;

		var inputs = _.map(defaultOptions, function(datum) {
			createdInputs = true;
			
			/**that.tempOptions = {};
			if (that.state.optionsValue) {
				that.tempOptions = JSON.parse(that.state.optionsValue);
			}**/
			
			var defaultStuff = null;
			
			if (false){
    			if (datum.options) {
    				defaultStuff = datum.options.toString();
    			}
    			else if (that.state.optionsValue && JSON.parse(that.state.optionsValue) && JSON.parse(that.state.optionsValue)[datum.prop]) {
    				defaultStuff = JSON.parse(that.state.optionsValue)[datum.prop];
    			}
			}
			
			if(defaultStuff === null && datum.defaultValue){
			    defaultStuff = datum.defaultValue;
			}
			
			var ComponentString = "InputText";
			
			
			if (datum.options instanceof Array) {
				ComponentString = "InputSelect"
			}
			
			if(datum.type == "fileBrowser"){
			    ComponentString = "InputFileSelect";
			}
			
	         if(datum.type == "style"){
	                ComponentString = "InputStyleSelect";
	            }
			
			if(datum.type == "checkbox"){
			    ComponentString = "InputCheckbox";
			}
			
			if(datum.type == "colourPicker"){
			    ComponentString = "InputColour"
			}
			
			var el = <div className="error">You forgot to put an editor in {datum.niceName} for {ComponentString}</div>;
			
			if (ComponentString == "InputText") {
				el = <div className={uid}><InputText inputType={datum.multiLine ? "textarea" : "input"} name={datum.niceName} field={datum.prop} defaultValue={defaultStuff}/></div>
			}
			else if (ComponentString == "InputSelect") {
				el = <div className={uid}><InputSelect name={datum.niceName} field={datum.prop} options={datum.options} showEmpty={true} defaultValue={defaultStuff}/></div>
			}
			else if (ComponentString == "InputFileSelect"){
			    el = <div className={uid}><FileBrowser data={datum} name={datum.niceName} field={datum.prop} fileForm="files" allowToBrowse={true} /></div>
			}
			else if (ComponentString == "InputCheckbox"){
			    el = <div className={uid}><InputCheckbox name={datum.niceName} field={datum.prop}/></div>
			}
			else if (ComponentString == "InputStyleSelect"){
	                el = <div className={uid}><StyleBrowser name={datum.niceName} field={datum.prop} styleSelector={datum.styleSelector} /></div>
	        } else if(ComponentString == "InputColour"){
	            el = <div className={uid}><InputText inputType="color" name={datum.niceName} field={datum.prop} value={defaultStuff}/></div>
	        }
			
			if(datum.multiValue){
			    el = <ArrayOf field={datum.prop} min={0} max={100} >{el}</ArrayOf>;
			    console.log("was it me?");
			}
			
			if (datum.allowJS){
				return <JsAble NDT={that.props.NDT} struct={structToEdit} me={datum} field={datum.prop} key={datum.prop}>{el}</JsAble>;
			}else{
				return <span key={datum.prop}>{el}</span>;
			}
			
			
		});
		
		var rawEditor = null;
		
		if (createdInputs == false && structToEdit && structToEdit.options){
			rawEditor = <textarea id="uglyhack" onChange={(e)=>{this.tempOptions = JSON.parse( e.target.value)}} defaultValue={JSON.stringify(structToEdit.options)}></textarea>;
		}
		
		var extra = null;
		
		if (structToEdit && structToEdit.type == "DataSet" && this.props.NDT){
			
			
			
			if (this.state.data  && (this.state.data.length > 0) ) {
					extra = <Tabular data={this.state.data}> 
						{this.state.columns}
					</Tabular>
			}
		}
		var title = structToEdit ? (structToEdit.cid) : "hey";
		var title2 = structToEdit ? <>{structToEdit.component}</> : "bah";
		
		var drag = <span className="drag-handle" draggable={true} onDragEnd={this.props.onDragEnd} onDragStart={this.props.onDragStart}><Icon icon="arrows-alt"/></span>
	
		var extraActions = null;
		if (this.props.extraActions){
			extraActions = React.cloneElement( this.props.extraActions, { obj: this.props.bindTo, struct: structToEdit, test: "i came from here" });
		}
		
		var buttons = [];
		
		if (this.props.rename){
			buttons.push( <button type="button" className="btn btn-default btn-rename" key="rename" onClick={(e)=>{var newId = prompt("new cid"); this.props.rename(newId); }}>Rename</button> );
		}
		
		if (this.props.add){
			buttons.push( <button type="button" className="btn btn-default btn-add" key="add" onClick={(e)=>{return this.props.add(); }}>Add</button> );
		}
		
		if (this.props.addChild){
			buttons.push( <button type="button" className="btn btn-default btn-add" key="add-child" onClick={(e)=>{return this.props.addChild(); }}>Add Child</button> );
		}
		
		
        var view = <div><h4>{title}</h4>
                <h3 title={structToEdit.uid}>{title2} {drag}</h3>
                
				<Bound to={this.props.bindTo}>
                	{inputs}
				</Bound>
				{rawEditor}
				<br/>
				<div className="editor-buttons">
					<button type="button" className="btn btn-default btn-save saveContent" onClick={(e)=>this.handleQueueChanges(e)}>Save</button>
					<span className="openExtra" onClick={()=>this.toggleShowExtraActions()}><Icon icon={"cog"} size="2x"/></span>
				</div>
				<div className="extra">
				    <span className={"extraActions " + (this.state.showExtraActions? "" : "hidden")}>
        				{buttons}
        			
        				<button type="button" className="btn btn-default deleteContent btn-delete" onClick={(e)=>{this.props.doDelete(e,this.tempOptions);this.props.closeAction()}}>Delete</button>
        			
        				{extra}
        				{extraActions}
    				</span>				
				</div>
				
				</div>;
				
				
				
				
				return view;
		}
		
}