import React, { useEffect, useState } from "react";
import { HoverElement } from "@opidcore/components";
import * as _ from "lodash";
import { APP } from "../../../js";
import { ContactList } from "../UI/Customer/EditClient";

export default function HoverContactList(props) {
  const client = props.client;
  const truncate = _.has(props, "truncate") ? props.truncate : true;

  /* This component calls fetchForClient just for the count of contacts, 
   but then the ContactList component calls it again to get the actual contacts.
   could be improved in the future to only call fetchForClient once.
  */

  //const [contactList, setContactList] = useState([]);
  const [contactMessage, setContactMessage] = useState("");

  useEffect(() => {
    APP.central.CustomerContact.fetchForClient({ clientId: client.id }).then((r) => {
      //setContactList(r.result);
      let message = "";

      if (r.result.length == 0 || r.result.length > 1) {
        message = r.result.length + " Contacts";
      } else {
        message = r.result.length + " Contact";
      }
      setContactMessage(message);
    });
  }, [client]);

  return (
    <HoverElement style={{}} content={contactMessage} modalUrl={"/ui/clients/" + client.friendlyId}>
      <ContactList client={client} truncate={truncate} />
    </HoverElement>
  );
}

