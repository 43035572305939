import React from "react";
import * as _ from "lodash";
import { InputText } from ".";

export default function PercentDisplay(props) {
  const decimalPlaces = props.places != undefined ? props.places : 2;

  let numFormat = new Intl.NumberFormat("en-ca", { style: "percent", minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces });

  const formatter = {
    parse(a, b) {
      a = parseFloat(a.replaceAll("D", ""));
      return a;
    },

    format(a, b) {
      a = numFormat.format(a);
      return a;
    },
  };

  return <InputText {...props} formatter={formatter} inputType="decimal" className={(props.className || "") + " decimal"} />;
}
