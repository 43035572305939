import React, { Component } from 'react';

export default class DataColumn extends Component{
    constructor(props) {
        super(props);
    }
    
    static getEditableProps(){      
        return [
            {niceName: "JSON", prop: "jsEval", type: "text", default: ""},
            {niceName: "Title", prop: "title", type: "text", default: "Insert Title"},
            {niceName: "Group By", prop: "groupBy", type: "text", default: ""},
			{niceName: "Style", prop: "cssClass", type: "text", default : ""},
            {niceName: "Width", prop: "width", type: "text", default : ""},
        ]
        
    }
    
    static metaInfo(){
  		return {canHaveChildren: true, icon: "table", livesDangerously: true}
  	}
    
    render(){
        return <div key={this.props.root} data-uid={this.props.rootStructure.uid}>Edit Column {this.props.title}</div>;
    }
}