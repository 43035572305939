import React, { Component } from 'react';
import BoundComponent from '../../BoundComponent';
import { DataContext } from '../../Bound';
import InputSelect from '../../InputSelect.js';
import InputText from '../../InputText.js';

export default class StyleBrowser extends BoundComponent { 
    
    constructor(props,context) {
        super(props,context);
        
        this.state = {manual: false};
    }
    
    static contextType = DataContext;

    
    toggleManual(){
    	this.setState({manual: !this.state.manual});
    	
    }
    

    render(){
        let styles = null;
        let styleArray = [];
        styles = "select styles here";
        let test = document.head.getElementsByTagName("style");
        let sty = _.each(test, (r)=>{
            styleArray.push(r.textContent);
        })
        let css = [];
        //@ian todo this needs to be revamped - doesn't grab some class names
        var cssPrefix = APP.NDT.announcements.cssStuff;
        _.each(styleArray,(s)=>{
            var rules = s.split('}')
            _.map(rules, (rule)=>{
                if (rule.length > 2){
                  var ruleParts = rule.split('{');
                  return ruleParts[0].split(',').map( (c)=>{ _.each(cssPrefix, (p)=>{
                      if(c.indexOf(p.replace(".","")) > 0){
                          let names = c.split(' ');
                          css.push(names[1].replace(".",""))}
                  })
                  });
                }
            }).join("");
        });
  
        const val = this.context.magicalGet(this.props.field);

        const editor = this.state.manual || (val > '' && css.indexOf(val) == -1) ?
        		<InputText name={this.props.name} field={this.props.field} /> 
        	: <InputSelect name={this.props.name} field={this.props.field} options={css} showEmpty={true} />;
        		
        return (
                <div> 
                    <div>
                {editor} <button className="more" onClick={()=>this.toggleManual()}>...</button>
                    </div>
               </div>
                
        )
    }
}