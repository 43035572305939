import React from 'react';
import { AccessControl } from '@opidcore/components';
import { MenuItem, SubMenu } from '@opidcore/components/Menu.jsx';
import AutoPay from '../Contract/AutoPay';
import CostAvoidance from '../Contract/CostAvoidance';
import PriceIncreaseLanding from '../Tools/PriceIncreaseLanding';
import PriceIncreaseFile from '../Tools/PriceIncreaseFile';
import ExcelBulkImporter from '../ExcelBulkImporter';
import PriceIncreaseImporter from '../Tools/PriceIncreaseImporter';

export default function initialize(permissions, options) {
  
  if (permissions.w) {
    window.APP.customRoutes.push({ path: "/ui/priceincrease/landing", component: PriceIncreaseLanding, key: "price_increase_landing" });
    window.APP.customRoutes.push({ path: "/ui/priceincrease/file/:id", component: PriceIncreaseFile, key: "price_increase_file" });
    window.APP.customRoutes.push({ path: "/ui/priceincrease/importer", component: PriceIncreaseImporter, key: "price_increase_importer" });
    
    window.APP.customRoutes.push({ path: "/ui/autopay", component: AutoPay, key: "auto_pay" });
    window.APP.customRoutes.push({ path: "/ui/costavoidance", component: CostAvoidance, key: "cost_avoidance" });
    window.APP.customRoutes.push({ path: "/ui/bulkimporter", component: ExcelBulkImporter, key: "bulk_importer" });

    APP.addMenu(() => {
      const items = [];

      items.push(<SubMenu key="tools">
        <MenuItem key="tools" menuKey="tools" linkTo="#" icon="wrench" text="Tools" />
        <MenuItem key="price_increase_landing" menuKey="price_increase_landing_menu_key" className="nav-sub-menu" linkTo="/ui/priceincrease/landing" icon="chart-line" text="Price Increases" />
        <MenuItem key="auto_pay" menuKey="auto_pay_menu_key" className="nav-sub-menu" linkTo="/ui/autopay" icon="repeat" text="Subscriptions" />
        <MenuItem key="cost_avoidance" menuKey="cost_avoidance_menu_key" className="nav-sub-menu" linkTo="/ui/costavoidance" icon="acorn" text="Cost Avoidance" />
        <AccessControl group="Sysadmin">
          <MenuItem key="bulk_importer" menuKey="bulk_importer_menu_key" className="nav-sub-menu" linkTo="/ui/bulkimporter" icon="fill" text="Bulk Excel Importer" />
        </AccessControl>
      </SubMenu>);

      return items;
    });
``
  }
}

 