import React, { Component, PropTypes } from 'react';

import * as _ from 'lodash';

export default class CoreForm extends Component {
	constructor(props, context) {
	    super(props, context);
	    
	  }
	
	shouldComponentUpdate(nextProps, nextState){        
        //we have bunch we are waiting for so let's wait it out.
        if ( window.FETCH_REMAINING >= 1){
            return false;
        }
        
        return true;
    }
	
	compareObjectToState(obj){
	
		let setState = false;
		let dirty = false;
        let stateKeyArray = Object.keys(this.state);
		
        if (obj != null){
	        Object.keys(obj).map( (a)=>{ 
	            if (a != "afterSave" && stateKeyArray.indexOf(a) <= -1){
	            	
	            		
	                setState = true;
	            }
	            //wont work since we dont would need to call it through bound?
	            /*if(setState == false && this.state[a] != obj[a]){
	            	dirty = true;
	            }*/
            });
        }
        
        /*if(dirty == true){
        	this.setState({__dirty: true});
        }*/
        
        return setState;
	}

	
	componentWillMount(){
		//alert('yes I will');
	}
	
	
}