import React, { Component } from 'react';

import templated from './Templated.jsx';


export default class Paragraph extends Component{
    constructor(props) {
        super(props);
        
        this.state = {};
    }
    
    static doSomething(){
    	return {"hey": 9};
    }

	static getEditableProps() {
		return [
			{niceName: "Content", prop:"content", type:"text", default: "", multiLine: true, editInPlace: true, allowJS:true},
			{niceName: "Style", prop: "cssClass", type: "style", default : ""}
			
		]
	}

	static metaInfo(thing) {
		return {icon: "paragraph", preview: (me)=>{ return _.truncate(me.element.innerText, 30) }, showInEditorQuickAdd: true };
	}
	
	handleInlineChange(content, idx){
		let lines = [];
		
		if (this.props.content){
			lines = this.props.content.split(/\r?\n/);
		}
		
		lines[idx] = content;		
		
		this.props.handleInlineChange({content: lines.join("\n") });
	}

	
    render(){
    	let content = undefined;
    	let className = this.props.cssClass;
    	
        if((this.props.content == undefined || this.props.content.length == 0) && this.props.emptyOk){
            content = "";
        } else if(this.props.content == undefined || this.props.content.length == 0) {
            content = "No Content";
            className += " empty";
        } else {
            content = this.props.content;
        }
        
        
    	const splitContent = content.split(/\r?\n/).map((sc, index)=>{ 
    	    const tempContent = templated(sc, this, (content)=>this.handleInlineChange(content,index));
    	    if(sc.length == 0){
    	    	return <p key={index} className={className} onClick={this.props.onClick} dangerouslySetInnerHTML={{__html: "\u00a0"}}></p>;
    	    }
    	    if (typeof tempContent == "string"){
    	    	return <p key={index} className={className} onClick={this.props.onClick} dangerouslySetInnerHTML={{__html: tempContent}}></p>;
    	    }else{
    	    	return <p key={index} className={className} onClick={this.props.onClick}>{tempContent}</p>;	
    	    }
	    });
    	
    	if (this.props.mode == "edit"){
    		return <div className={this.props.cssClass} onClick={this.props.onClick}>{splitContent}</div>	
    	}else{		
    		return <div>
    		    { splitContent }	
    		</div>;
    	}
    }
}