import React, { Component } from 'react';
//import ReactDOM from 'react-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as CentralActions from '../../OpidCore/public/js/actions/CentralActions';

//import { InputText, InputSelect, DatePicker, Bound, GrowableList } from '../../OpidCore/public/js/components';

import { Data} from '../../OpidCore/public/js/Data.js';
import { Tabular, TabularColumn } from '@opidcore/components/Tabular';

//import BasicFormView from './Form/BasicView.jsx';
import RenderMeSoftly from '../../OpidCore/public/js/components/Content/RenderMeSoftly.jsx';
import ReferencedThing from '../../OpidCore/public/js/components/ReferencedThing.jsx';

import * as _ from 'lodash';
//import moment from 'moment';

class ListView extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
                loginError: undefined,
                section: "header"
        };
    }
    
    componentDidUpdate(prevProps){
   	   	var form = _.find(this.props.formDefinitions, {uid: this.props.viewDefinition.formUid});
		if (this.form != form){
			this.form = form;
			this.loadData();
		}   	
    }
    
    componentDidMount(){
        this.props.central.Stuff.listViews();
        this.props.central.Stuff.listForms();        
    
        window.APP.reacts.push( this );
        
   	    var form = _.find(this.props.formDefinitions, {uid: this.props.viewDefinition.formUid});
   	    this.form = form;
   	    this.loadData();
   	    
       	 if (this.props.login){
             if (role == "admin"){
                 this.setState({allowEdit:true});
             }
         }
        // fetch("/api/v1/Stuff/testData?instanceId=" + this.props.routeParams.id).then((r)=>r.json()).then((j)=>this.setState({data: j.result}))
    }
    
    
    loadData(){
    	if (this.form != null){
    		//need to check here if the section is header or defined as the "top level" record 
    	    
    	    var rms_children = [];
            var structure = [];
            
            var rootStructure = {
                children: [],
                cid: "fake-root",
                component: "Container",
                connectors: null,
                next: null,
                options: {},
                returns: null,
                theId: "fake-root",
                type: "ReactThing",
                uid: "fake-root"
            };
            structure.push(rootStructure);
            if(this.props.viewDefinition && this.props.viewDefinition.rmsChild > ""){
                rms_children = this.props.viewDefinition.rmsChild ? this.props.viewDefinition.rmsChild : "";
                const rms_children_array = rms_children.split(",").filter(function(el){ return el.trim().length > 0});
                _.each(rms_children_array,(r)=>{
                    rootStructure.children.push( r.replace(/[" '\[\]]/gi,""));
                    var res = this.props.central.Stuff.everythingIWant(r.replace(/[" '\[\]]/gi, "")).then((s)=>{
                        _.forEach(s.result.structure, (struct)=>{
                            structure.push(struct);
                        });
                            
                    });
                });
            }
    	
    		this.setState({section: this.form.defaultSection, rmsStructure:structure});
    		
	    	this.props.central.Stuff.listFormInstances( this.form.id ).then((r)=>{
	    		this.setState({data: r.result});
	    	});
	    	
    	}
    }
    
    thisHappened(a){

    }
    
    login(){
        //this.props.central.TimedTokens.loginWithJson(this.state);
    }
    
    handleSomethingHappened(){
    	var newState = {...this.state};
    	newState.data.production_line = "Buzzah";
    	
    	this.setState(newState);
    	
    }
    
    handleSave(){
    	var stuff = {...this.state};
    	
    	this.props.central.Stuff.saveTestData(stuff).then((r)=>{
    		console.log(r);
    	});
    }
    
    myUpdater(action, arg1){
    	if (action == "ADD_ROW"){
    		var section = arg1;
    		
    	
    		var newState = {};
    		newState.data = {...this.state.data}
    		
    		var maxKey = _.max( _.map( newState.data[section], '_key' ) );
    		var nextKey = maxKey != undefined ? maxKey + 1 : 1;
    		
    		newState.data[section].push({_key: nextKey});
    		
    		this.setState(newState);
    			
    	}
    }
    
    openRecord(id){
    	const path = this.props.history.location.pathname;
    	this.props.history.push(path + "/" + id);
    }
    
    deleteRecord(id){
    	
    	if (confirm('are you sure?')){
    		this.props.central.Stuff.deleteInstance( id ).then((r)=>{
    			this.loadData();
    			APP.alert(r.result);
    		});
    	}
    }
    newRecord(){
    	        this.props.central.Stuff.createNewInstance( this.form.id ).then((r)=>{
    	        	this.openRecord( r.result.id );
    	        });
    	
    }
    
    componentWillUnmount(){
        console.log("NDT was cleaned up by list.jsx");
        APP.NDT.cleanUp();
    }
    
    render(){      
        
        
        if (this.form != null){
           var data = new Data(this.state.data || []);
         
         	var columns = [];
         	_.each(this.form.fields, (f)=>{
         		if (f.section == this.state.section){
         			var fieldUid = f.fieldUid.toLowerCase();
         			let fn = (row)=>{ return row[ fieldUid ] };
         			let referencedDataFn = null;
         			if(f.field != undefined && f.field.fieldType == "reference"){
         			   fn = (row)=>{ 
         			       if(row[ fieldUid + "_nice_name"] && row[ fieldUid + "_nice_name"].trim().length > 0){
         			          return <span><span className="mainValue">{row[fieldUid + "_nice_name"]}</span><ReferencedThing>{row[fieldUid]}</ReferencedThing></span>;
         			       } else {
         			           return row[ fieldUid ];
         			       }
     			       };
         			}

         			if(f.field != undefined && f.field.fieldType == "content_thing"){
         			   fn = (row)=>{
         			       const fieldRMS = row[fieldUid + "_rms"];
         			       
         			       if(fieldRMS != undefined && fieldRMS.structure.length > 0){
             			       let rmsProps = {
         			               central: "blaha",
         			               key: row[fieldUid + "_rms"].structure[0].theId,
         			               root: row[fieldUid + "_rms"].structure[0].theId,
         			               structure: row[fieldUid + "_rms"].structure,
         			               NDT: APP.NDT,
         			               mode: "preview",
         			               test: 123
             			       };
             			       
             			       return <RenderMeSoftly {...rmsProps} />;    
         			       } else {
         			           return null;
         			       }
                       };
         			}
         			columns.push(  <TabularColumn data={fn} title={f.label} key={f.fieldUid} fieldType={f.field? f.field.fieldType : undefined} />)
         		}
         	});
         	
 		    var ianHatesHisLifeAndHasChickenWingCodeInTheProject = true;
 		    
 		    if (ianHatesHisLifeAndHasChickenWingCodeInTheProject && this.form.id == 6){
 		    	if (true){
 		    		columns.push( <TabularColumn data={(row)=>row.line} title={'Line(s)'} key={'line'} /> );

 		    		columns.push( <TabularColumn data={(row)=>row.total_weight} title={'Total Weight'} key={'total_weight'} /> );
 		    	}
 		    }
         	
         	columns.push( <TabularColumn data={(row)=><button onClick={()=>this.openRecord(row.id)}>edit</button>}
         	key="_edit"/> );

         	columns.push( <TabularColumn data={(row)=><button onClick={()=>this.deleteRecord(row.id)}>delete</button>}
         	key="_delete"/> );
         	
         	var defaultSort = null;
         	if(this.props.viewDefinition.options && this.props.viewDefinition.options.defaultSort){
         	   defaultSort = this.props.viewDefinition.options.defaultSort;
         	}
         	
         	var table = <div>
         	<Tabular data={data.filter({}).reverse()} style={{minWidth: "80em"}} defaultSort={defaultSort}>
         		{columns}
         	</Tabular>
         	
         	</div>;
         	
         	var resources = null;
            
         	if(1==2){ //plans to be able to have rms_child usable for list (similar to how we use them in view.js)
                if ( this.state.rmsStructure != null){
                    let rmsProps = {
                            central: "blah",
                            key: '888',
                            root: 'fake-root',
                            structure: this.state.rmsStructure,
                            mode: "view"
                        };
                    
                    resources = <RenderMeSoftly {...rmsProps} />
                }
         	}
         	
           return <div className="container">

    				<h2>{this.form.title} <button onClick={()=>this.newRecord()}>new?</button></h2>       
           			{table}
    				{resources}
	           </div>
            
        }else{
        
        return <div id="login-body" className="text-center">
            <div className="container">                
                <div className="plans">
                
                </div>
            </div>
        </div>
        
        }
    }
}

function mapStateToProps(state) {
    return {
        viewDefinitions: state.central.viewDefinitions || {},
        formDefinitions: state.central.formDefinitions || {},
        
    
    };
}

function mapDispatchToProps(dispatch) {
    return {
        ...CentralActions.modifyProps(dispatch),  
        actions: bindActionCreators({...CentralActions}, dispatch),    
    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListView);