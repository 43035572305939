import React, { Component, useState, useEffect, useContext, useRef, useCallback, useLayoutEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Data } from '@opidcore/Data.js';
import { Link } from 'react-router-dom';
import { useData, useNavBar } from '@opidcore/hooks/WTF';
import Bound, { DataContext, BoundMagic } from '@opidcore/components/Bound';
import BoundComponent from '@opidcore/components/BoundComponent';
import BoundCommit from '@opidcore/components/BoundCommit';
import { InputText, Icon } from '@opidcore/components';
import { createGridContext, createTitles } from './GridFunctions';

import ReactUtils from '@opidcore/ReactUtils';
import _, { runInContext } from 'lodash';
import { faBreadLoaf } from '@fortawesome/pro-light-svg-icons';

export function GridRowAction(props) {
    let [showOptions, setShowOptions] = useState(false);

    let options = undefined;

    if (showOptions) {
        options = <div className="options" onMouseLeave={() => setShowOptions(false)}>
            {props.children}
        </div>;
    }

    return <div className="row_actions">
        <Icon icon="cogs" fType="fas" size="1x" onClick={() => setShowOptions(!showOptions)} type="span" />
        {options}
    </div>;
}

/** @prop onClick {func} called when the user clicks a cell */
export function GridCell(props) {
    const myContent = useRef();

    ///sad
    const events = {};
    if (props.onClick) {
        events.onClick = props.onClick;
    }

    if (true){
        events.onKeyDown = (e)=>{
            if(props.ctx.customKeyDown && props.ctx.customKeyDown(e,props.style.gridRow,props.style.gridColumn,props)){
                return;
            }
        }

        events.onKeyUp = (e,a,b)=>{
            const gridCtx = props.ctx;

            if (props.ctx.contentAt){
                let colMovement = 0;
                let rowMovement = 0;
                if (gridCtx.customKeyUp && gridCtx.customKeyUp(e,props.style.gridRow,props.style.gridColumn,props)){
                    //do nothing because they did!
                    return;
                }else if (e.key == 'ArrowLeft'){
                    colMovement = -1;
                } else if (e.key == 'ArrowRight'){
                    colMovement = 1;
                }else if (e.key == 'ArrowDown' || e.key == 'Enter'){
                    rowMovement = 1;
                }else if (e.key == 'ArrowUp'){
                    rowMovement = -1;
                }

                if (rowMovement == 0 && colMovement == 0){
                    return;
                }
                e.preventDefault();

                let keepLooking = true;
                while (keepLooking){
                    
                    const them = props.ctx.contentAt[(props.style.gridRow+rowMovement)+ "-" + (props.style.gridColumn-1 +colMovement) ];
                    if (them == null){
                        keepLooking = false;
                        break;
                    }
                    e.preventDefault();
                    e.stopPropagation();

                    //only jumps one for now.
                    if (them.getElementsByTagName("input").length > 0){
                        them.getElementsByTagName("input")[0].focus();                        
                        keepLooking = false;
                    }

                    //issues with leaving selects, as they have their own key up stuff.
                    if (them.getElementsByTagName("select").length > 0){
                        them.getElementsByTagName("select")[0].focus();
                        keepLooking = false;
                    }

                    // if we didn't find an input or select, look in the same direction
                    if (rowMovement != 0){
                        rowMovement += rowMovement > 0 ? 1 : -1;
                    }

                    if (colMovement != 0){
                        colMovement += colMovement > 0 ? 1 : -1;
                    }
                        
                }
                
            }
        }
    }

    useLayoutEffect(() => {
        const myLabel = _.first(_.map(myContent.current.getElementsByTagName("label"), l => l.innerText));
        if (props.ctx && myLabel) {
            props.ctx.setTitle(props.title, myLabel);
        }
        if (props.ctx){   
            if (props.ctx.contentAt == null){
                props.ctx.contentAt = {};
            }
            
            props.ctx.contentAt[props.style.gridRow + "-" + (props.style.gridColumn-1)] = myContent.current;
        }
    });

    return <div key={"grid_cell" + props.title} style={props.style} className={"grid-cell " + props.className + " " + (props.actions ? "grid-cell-with-actions" : "")} ref={myContent} {...events}>
        {props.children}
    </div>
}

function DraggingThing(props) {
    const [tempWidth, setTempWidth] = useState(0);
    const startingPoint = useRef(0);
    const me = useRef();

    useEffect(() => {
        startingPoint.current = me.current.parentElement.getBoundingClientRect().x;
    }, [me]);

    useEffect(() => {
        const move = (e) => {
            e.preventDefault();
            if (startingPoint.current == 0) {
                alert("What?"); //startingPoint.current = me.current.parentElement.getBoundingClientRect().x; 
            }

            setTempWidth(e.pageX - startingPoint.current);
        };

        const up = (e) => {
            const newWidth = e.pageX - startingPoint.current;
            if (props.onResize) {
                props.onResize(newWidth);
            } else {
                APP.alert("We resized, but nobody cared. " + newWidth);
            }
        };

        window.addEventListener("mousemove", move);
        window.addEventListener("mouseup", up);

        return () => {
            window.removeEventListener("mousemove", move);
            window.removeEventListener("mouseup", up);
        }
    }, []);

    return <div className="grid-resizing-line" style={{ display: (tempWidth > 0 ? "block" : "none"), left: tempWidth }} ref={me}></div>;
}

function Resizable(props) {
    const TagName = props.tagname;
    const [dragging, setDragging] = useState(false);

    const mouseDid = useRef({});



    const up = (move) => {
        return (e) => {
            setDragging(false);
            if (mouseDid.current.tempWidth >= 0) {
                if (props.onResize) {
                    props.onResize(mouseDid.current.tempWidth);
                } else {
                    APP.alert("We resized, but nobody cared. " + mouseDid.current.tempWidth);
                }
            }
            mouseDid.current.startX = 0;
            //cleanup();
        }
    };


    const down = (e) => {
        setDragging(true);
    }

    const done = (width) => {
        props.onResize(width);
        setDragging(false);
    }

    const _props = { ...props };
    delete _props["onResize"];

    return <TagName {..._props} className={(props.className ? props.className + " " : "") + "grid-ish"}>
        {props.children}
        <span className="resize-right" onMouseDown={down}></span>

        {dragging ? <DraggingThing onResize={done} /> : null}

    </TagName>;

}

export function GridItem(props) {
    return <div className="gridItem" key={props.field}>
        {props.children}
    </div>
}

GridItem.propTypes = {
    componentName: PropTypes.string,
    title: PropTypes.string,
    field: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    hideTitle: PropTypes.bool,
    boundData: PropTypes.string,
    /** test*/
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    justify: PropTypes.string
}

GridItem.defaultProps = {
    componentName: "GridItem",
    title: null,
    field: null,
    children: null,
    hideTitle: false,
    boundData: null,
    justify: "center"
}

export function GridItemGroup(props) {
    return <div className="gridItemGroup">
        {props.children}
    </div>
}

GridItemGroup.propTypes = {
    componentName: PropTypes.string
}

GridItemGroup.defaultProps = {
    componentName: "GridItemGroup"
}

function GridBody(props) {
    return <React.Fragment key={"grid-body" + props.boundData}>
        {props.data}
    </React.Fragment>;
}

export function GridHeading(props) {
    return <React.Fragment key="grid-heading">{props.children}</React.Fragment>;
}

GridHeading.propTypes = {
    componentName: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    start: PropTypes.number,
    span: PropTypes.number
}

GridHeading.defaultProps = {
    componentName: "GridHeading",
    children: null,
    start: null,
    span: null
}

function GridHeader(props) {
    const gridContext = props.ctx;
    const style = gridContext.styler(null, "header");

    const cols = gridContext.getTheColumns();

    let columnTitles = gridContext.getTheColumnNamesBlownUp();

    const extraTitles = [];
    let index = 0;

    const headingTitles = _.filter(props.children, (currentEl) => { return _.isEqual(currentEl && currentEl.props ? currentEl.props.componentName : "", "GridHeading") });

    _.forEach(_.sortBy(headingTitles, (heading) => { return heading.props.start }), (heading) => {
        const headerStyle = { "gridColumn": (heading.props.start + 1) + " / span " + heading.props.span, "gridRow": 1 };

        extraTitles.push(<div className="grid-heading" style={headerStyle} key={"heading" + heading.props.start + ":" + (heading.props.start + heading.props.span)}>
            {heading.props.children}
        </div>);
    });

    return <React.Fragment key="grid-header">
        {extraTitles}

        {_.map(columnTitles, (title, order) => {
            const obj = cols[title];
            let finalTitle = obj.title.indexOf("replaceme") > -1 ? "" : obj.title;
            if (obj.hideTitle == true) {
                finalTitle = "";
            }

            return <Resizable key={title + "" + obj.index} onResize={(w) => { gridContext.setWidth(obj, w) }} tagname="div" className="grid-heading-cell" style={{ gridColumn: (order + 1), gridRow: 2 }}>
                <span className="column-letter">{String.fromCharCode(65 + obj.index)}</span>
                <span className={"column-heading " + (obj.rowActions && obj.childIndex == 0 ? "column-heading-rowAction" : "")}>{finalTitle}</span>
            </Resizable>
        })}
    </React.Fragment>;
}

export const GridContext = React.createContext({grids: {}, refresh: undefined});

export default function Grid(props) {
    const gridContextStateRef = useRef(null);
    const [ticky, setTicky] = useState(0);
    const magic = useContext(DataContext);
    const [myData, _setMyData] = useState(null);
    const [gridBodyData, setGridBodyData] = useState(null);
    const [xray, setXray] = useState(false);
    const devMode = useRef(false);

    useEffect(() => {
        const ws = APP.getWebSession();
        if (ws != null && ws.email == ".....") {
            devMode.current = true;
        }

        //unmounting component
        return ()=>{

        }
    }, []);

    const outerGridContext = useContext(GridContext);
    const gridId = useRef(props.gridIdentifier? props.gridIdentifier : "noid" + _.keys(outerGridContext.grids).length);

    if (gridContextStateRef.current == null) {
        gridContextStateRef.current = createGridContext(gridContextStateRef, props, magic, (ctx, data) => {
            if (data != null) {
                _setMyData(data);
            }
        });

        outerGridContext.grids[gridId.current] = gridContextStateRef.current;
    }

    let ourContextState = gridContextStateRef.current;

    useEffect(() => {
        // we should be able to move this into our grid context too.
        createTitles(props, ourContextState, props.children, null);

        gridContextStateRef.current.gridProps = {...props};
        gridContextStateRef.current.refresh();
    }, [props, ourContextState]);


    if (outerGridContext.grids[gridId.current] != null && outerGridContext.grids[gridId.current].postGrid) {
        if (ourContextState.id == null) {
            ourContextState.id = props.gridIdentifier || props.boundData || "grid-rando-" + Math.floor(Math.random() * 10000);
        }

        outerGridContext.grids[gridId.current].postGrid(ourContextState.id, { props: props, ctx: ourContextState });
        if (props.registerGrid) {
            props.registerGrid(ourContextState);
        }

    }

    const setMyData = (data) => {
        let columns = ourContextState.getColumns(data);

        if (true) { // _.keys(columns).length <= 0;
            if (ourContextState != undefined && ourContextState.getAllColumns != undefined) {
                ourContextState.getAllColumns(columns); //initialize ctx.allColumns
            }
        }

        ourContextState.refresh(data);
        const theStuff = gridContextStateRef.current.fillGridHoles(myData, props.children, props.orderBy, props.gridIdentifier, xray, props.alternate);
        setGridBodyData(theStuff);
        return _setMyData(data);
    };

    useEffect(() => {
        let data = props.data;

        //if we weren't give data, but have bound data as for it through bound.  this was boundData
        if (data == undefined || data == null) {
            if (props.boundData) {
                data = magic.magicalState(props.boundData, setMyData);
            }
        }
        if (!Array.isArray(data)) {
            data = [data];
        }

        setMyData(data);
        ourContextState.refresh(data);


    }, [props.data, magic]);


    useEffect(() => {
        const theStuff = gridContextStateRef.current.fillGridHoles(myData, props.children, props.orderBy, props.gridIdentifier, xray);
        setGridBodyData(theStuff);
    }, [myData, xray]);

    if (myData === null) {
        return <></>;
    }

    let outerGridStyles = "";
    if (props.scroll) {
        outerGridStyles = "scrollable";
    }

    const refresh = (e) => {
        const data = magic.magicalGet(props.boundData);
        setMyData(data);
    }

    return <div className={"grid excelish " + outerGridStyles} style={{ ...ourContextState.styler(), ...props.myStyle }}>
        <div className="xray_mode" style={devMode.current == true  && false ? null : { display: "none" }}>
            Xray Mode?
            <select onChange={(e) => setXray(e.currentTarget.value)} className="xray_mode_selector">
                <option value={false}>No</option>
                <option value={true}>Yes</option>
            </select>
            <button onClick={refresh}>refresh</button>
        </div>
        <GridContext.Provider value={ourContextState}>
            <GridHeader {...props} data={myData} ctx={ourContextState} />
            <GridBody data={gridBodyData} ctx={ourContextState} />
        </GridContext.Provider>
    </div>;
}

Grid.propTypes = {
    componentName: PropTypes.string,
    /** data to populate the grid with (typically returned from a dataset.filter({...}) call. */
    data: PropTypes.arrayOf(PropTypes.object),
    /** the cell action is awesome and we have jddocs now!  */
    onCellAction: PropTypes.func,
    /** the 'text' the add row button will have and the optional 'add' function that is called in onCellAction. appendable: { text: "", add: null } */
    appendable: PropTypes.object,
    boundData: PropTypes.string,
    rowBasis: PropTypes.string,
    rowBasisGroup: PropTypes.string,
    gridIdentifier: PropTypes.string,
    rowActions: PropTypes.array,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    orderBy: PropTypes.array,
    orderByDirection: PropTypes.array,
    scroll: PropTypes.bool,
    registerGrid: PropTypes.func,
    defaultWidth: PropTypes.number,
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number,
    disableHoleActions: PropTypes.bool,
    visibleColumns: PropTypes.arrayOf(PropTypes.object),
    shouldDisplayRow: PropTypes.func,
    alternate: PropTypes.string
}

Grid.defaultProps = {
    componentName: "Grid",
    onCellAction: () => { console.log("No cell action defined. This is called when we do a row add or click an empty cell.") }
}