import React from "react";
import { Icon, Pagination } from "@opidcore/components";
import ActionBar from "@opidcore/components/ActionBar";
import Searchable from "@opidcore/components/Searchable";
import { Tabular, TabularColumn } from "@opidcore/components/Tabular";
import { useData } from "@opidcore/hooks/WTF";

import ExcelExportModal from "../../components/ExcelExportModal";

export default function ListServices(props) {
  //const [data, dataSet] = useData("service");
  const [data, dataSet] = useData("service", { method: "fetchServicesForExport", paginate: 50 });

  const excelExport = () => {
    APP.central.Service.getDefaultExportLayout().then((r) => {
      APP.instance.createModal(<ExcelExportModal defaultLayout={r.result} dataSet={dataSet} model={"Service"} />, { modal_name: "Excel Export" });
    });
  };

  return (
    <div key="list_services">
      <ActionBar title="Actions">
        <Searchable ds={dataSet} />
        <Pagination dataSet={dataSet} />
        <Icon icon="file-excel" size="2x" title="Open export modal" onClick={() => excelExport()} />
      </ActionBar>

      {dataSet.loading ? "loading" : ""}

      <Tabular data={data}>
        <TabularColumn data={(row) => row.friendlyId ? row.friendlyId : row.id} title="#" />
        <TabularColumn data={(row) => row.name} title="Name" />
      </Tabular>
    </div>
  );
}
