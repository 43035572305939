import React, { Component } from 'react';

export default class Container extends Component{
    constructor(props) {
        super(props);
        
        this.state = {};
    }
    
    componentDidMount(){
    	
    }
    
  	static getEditableProps(){
        
        return [
            {niceName: "Flex Size", prop: "flex", type: "int"},
			{niceName: "Flex Container?", prop: "flexOuter", type: "text", default : ""},
			{niceName: "CSS Class", prop: "cssClass", type: "text", default : ""},
			{niceName: "Keep CSS Class for Print", prop: "keepClassInPrint", type: "text", default : ""},
			{niceName: "CSS Layout Class", prop: "pageCssClass", type:"text", setting: "expert"},
			{niceName: "Keep as Block in PDF?", prop: "rendersChildren", type: "text"}
        ]
        
    }
  	
 	static metaInfo(){
  		return {canHaveChildren: true, icon: "square", showInEditorQuickAdd: true}
  	}

    render(){
    	
    	
    	var style = {};
    	var cssClass = "basic-container ";
    	if(this.props.cssClass){
    	    cssClass = cssClass + this.props.cssClass + " " + this.props.root;
    	}
    	else{
    	    cssClass = cssClass + this.props.root;
    	}
    	
    	if (this.props.flex){
    		style.flex = this.props.flex;
    	}
    	
    	if (this.props.flexOuter){
    		style.display = "flex";
    		//  flex-wrap: nowrap;
    	}
    	var empty = null;
    	if (_.flatten(this.props.children).length == 0){
    		cssClass += " empty-container";
    	}
    	
        return <div className={cssClass} style={style} {...this.props.existingBlockProps}>{empty}{this.props.children}</div>
    }
}