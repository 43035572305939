//import './AddFriendInput.scss';

import React, { Component, PropTypes } from 'react';
import Bound from './Bound';
import * as _ from 'lodash';

export default class GrowableTable extends Component {
	
  static propTypes = {
  };

  constructor(props, context) {
    super(props, context);

    this.state = {adding: false};
    
  }
  
  componentDidUpdate(prevProps, prevState){
	  
	  if(prevProps.list != undefined && this.props.list != undefined && prevProps.list.length != this.props.list.length || prevState.adding == true){
		  this.setState({adding: false});
	  }
  }
  
  handleClickedCancel(){
	  //debugger;
	  this.setState({adding: false});
  }
  
  handleClickedAddItem(){
	  this.setState({adding: true});
  }
  
  
  render() {
    var obj = this.props.to;
    
	return (<Bound to={this}>
				<table className="growableTable">
				{this.mapAllItems()}
				</table>
				
			</Bound>);
  }
  
  handleClickedCancel(){
	  this.setState({adding: false});
  }
  
  handleClickedAddItem(){
	  this.setState({adding: true});
  }
   
  mapAllItems(){
	  
	  const cancelOrAdd = 
  		  this.state.adding ?
  		  <button className="growableAction" key="cancel" onClick={()=>this.handleClickedCancel()}>Cancel</button> :
  		  <button className="growableAction" key="new" onClick={()=>this.handleClickedAddItem()}>{this.props.newLabel}</button>;
  		  
	  	  let rows = this.props.row.map( (row, idx)=>
	  			  	<tr key={idx}>
	  			  		<td>{row}</td>
	  			  		{this.props.columns.map( (col, idx) => <td key={idx}>{col}</td>)}
	  			  	</tr>
	  			  );
	  	  
	  	  
	  	  if (this.state.adding){
	  		  rows.push( <tr>
		  		<td></td>
		  		{this.props.columns.map( (col) => <td>{col}</td>)}
		  		</tr>);
	  		  
	  	  }
	  	
	  		const headers = this.props.headers.map( (item, idx)=>
			  	<th className ="problemTable" key={idx}>{item}</th>
			  );
	  	  return (<tbody>
	  	  			<tr>
	  	  				{headers}
	  	  			</tr>
	  	  			{rows}
	  	  		{cancelOrAdd}
	  	  	</tbody>);	  	  
	  }
  
  
  
  
}
