import React, { Component, PropTypes } from 'react';
import * as _ from 'lodash';

export default class TrafficLight extends Component {
  
	render(){	
		var content = ""
		var outerClass = "";
		
		const colors = [
		                { red: ["<=", 0] },
		                { yellow: ["<", 100] },
		                { green: [">=", 100] }
		               ];
		
		const value = this.props.value;
		
		const ldash = _;
		
		colors.map( (color) => {
			const theColor = Object.keys(color)[0];
			
			const arg = Object.values(color);
			if (arg[0].length == 2){
				
				if (arg[0][0] == "<="){
					if (value <= arg[0][1] && outerClass == ""){
						outerClass = theColor;
					}
				}
				
				
				if (arg[0][0] == "<"){
					if (value < arg[0][1] && outerClass == ""){
						outerClass = theColor;
					}
				}
				
				if (arg[0][0] == ">="){
					if (value >= arg[0][1] && outerClass == ""){
						outerClass = theColor;
					}
				}
			}
			
		});
		
		return (<span className="traffic_light">
					<span className={outerClass}>
						<span className="traffic_light-children">{this.props.children}</span>
					</span>
              </span>);
		
	}
}