import React, {  useState }  from 'react';
//import { Data } from '@opidcore/Data.js';
//import { Link } from 'react-router-dom';
import { useNavBar, useData } from '@opidcore/hooks/WTF';
import { TabularDataSet, TabularColumn, Selection } from '@opidcore/components/Tabular';
import Icon from '@opidcore/components/Icon';

import Calendar from '@opidcore/components/Content/Types/Calendar.jsx';


function SomeAudit(props){
	const unschedule = (e)=>{
		e.preventDefault();
		e.stopPropagation();
		APP.updateModel("waste_audit:" + props.scope.event.id, {status: "Unscheduled", audit_date: ""}).then(()=>{
				props.refresh();
		});
		return false;
	};
	
	
	return <div>{props.scope.event.client_name} <Icon icon="minus-circle" color="#c00" onClick={unschedule}/> </div>;
}


export default function Schedule(props){
	const navBar = useNavBar("Schedule", [], {menu: ["schedule"]});
	
	const [data, dataSet] = useData("waste_audit;all");

	const [selection, setSelection] = useState([]);
	
	const [dataA, dataSetA] = useData("waste_audit;pre", {stage: 'Pre-Audit Setup'});

	const _refresh = ()=>{		
		dataSetA.fetch();
		dataSet.fetch();	
	}
	
	const refresh = () => {
		setTimeout( _refresh(), 500 );
	}
	
	const dayClicked = (day, date)=>{
		const promises = [];

		_.each( selection, (s)=>{
			promises.push( APP.updateModel("waste_audit:" + s.id, {status: "Scheduled", audit_date: date}) );
		});

		if ( promises.length > 0){	
			Promise.all( promises ).then( refresh );
		}
	};
	
	
	debugger;
    return <div>
    	<h2>Schedule</h2>
    	<button onClick={refresh}>refresh</button>
    	
    	<div className="schedule">
        <div className="left">
        <h3>Unscheduled Audits</h3>
                
    	<TabularDataSet  dataSet={dataSetA} filter={ (row)=>row.status != "Scheduled" } noData={"Great Job! You have scheduled all the Audits."}>
    		<TabularColumn data={(row)=><Selection selection={selection} setter={setSelection} item={row} label={(row)=>row.client_name} />} title="...">
    		</TabularColumn>
	    	<TabularColumn data={(row)=>row.client_name} title="Client Name"/>
			<TabularColumn data={(row)=>row.id} title="Audit #"/>
			<TabularColumn data={(row)=>row.waste_auditor} title="Waste Auditor"/>
			<TabularColumn data={(row)=>row.status} title="Status"/>
		</TabularDataSet>
    	</div>
    	<div className="right">
    	<Calendar dataset={dataSet} dateVarName={"audit_date"} linkTo="/ui/audit/" onClickDay={dayClicked} title={selection.length > 0 ? ("Click a day to add " + selection.length + " item(s)") : null}>
    		<SomeAudit refresh={refresh}/>
    	</Calendar>
    	</div>
    	
    	</div>
    	
    </div>;
}
