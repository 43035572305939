import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "@opidcore/components";
import Icon from "@opidcore/components/Icon";
import _ from "lodash";


const ValueAddPrograms = ({disabled = false, site = undefined}) => {
    const boundMagic = useContext(DataContext);    
    const [valueAddPrograms, setValueAddPrograms] = useState(null);

    useEffect(()=>{ 
        if(site != undefined){
            setValueAddPrograms(site.valueAddPrograms);
        } else {
            boundMagic.magicalState("valueAddPrograms", (newPrograms) => {
                setValueAddPrograms(newPrograms);
            });
        }
    }, [boundMagic, site]);

    if(valueAddPrograms == "" || valueAddPrograms == null){
        return null;
    }

    const toggleProgram = (program) => {
        if(disabled == true){
            return;
        }

        const newPrograms = {...valueAddPrograms};
        newPrograms[program].value = !newPrograms[program].value;

        boundMagic.magicalSet("valueAddPrograms", newPrograms);
    };

    const programs = _.map(_.keys(valueAddPrograms), (key) => {
        return <div className="program" key={key} onClick={()=>toggleProgram(key)}>
            <Icon icon={key} fType="fac" color={valueAddPrograms[key].value == true ? "" : "#999999"} size="5x" />
            <h4>{valueAddPrograms[key].label}</h4>
        </div>
    });

    return <div className="value-add-programs">
        <h3>Value Add Programs</h3>
        <div className="programs">
            {programs}
        </div>
    </div>
}

export default ValueAddPrograms;