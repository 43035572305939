import React, { useContext, useEffect, useState } from "react";
import File, { FileItem } from "../../components/File";
import LoadingProgress from "@opidcore/components/LoadingProgress";
import { CurrentModal } from "@opidcore/components/OpidApplication";

/* 
  This is the Price Increase Importer
  It will allow the user to upload an excel file to be processed by the PI tool
  The user will be able to see the status of the file processing
  The user will be able to see the results of the file processing
*/
const PriceIncreaseImporter = ({}) => {
  const [currentFile, setCurrentFile] = useState(null);
  const [uploadMessage, setUploadMessage] = useState(null);

  const currentModal = useContext(CurrentModal);

  useEffect(() => {
    if (currentFile) {
      APP.showLoading({ message: "Importing", show: false, children: <LoadingProgress onFinishLoad={() => doCloseModal()} /> }, () => {
        APP.central.Reports.importPriceIncreases(currentFile.id).then((importPriceIncreases) => {
          APP.socket.send(JSON.stringify(importPriceIncreases));
        });
      });
    }
  }, [currentFile]);

  const doCloseModal = () => {
    APP.instance.closeModal(currentModal && currentModal.id ? currentModal.id : undefined)
    APP.redirect("/ui/priceincrease/file/" + currentFile.id);
  }

  const uploaded = (file) => {
    setUploadMessage("Processing " + file.filename);
    setCurrentFile(file);
  };

  return (
    <div>
      <h1>Price Increase Importer</h1>
      <File onUpload={uploaded} useExisting={true} label={"Drop Price Increase File Here"} info={uploadMessage} bigDrop={true} />
    </div>
  );
};

export default PriceIncreaseImporter;
