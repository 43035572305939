import React, { Component } from 'react';
import templated from './Templated.jsx';
import ReactDOM from 'react-dom';

export default class TableOfContentsRow extends Component {
    constructor(props){
        super(props);
        this.partOneRef = React.createRef();
        this.partThreeRef = React.createRef();
    }
    
    adventureForth(){    	
        const rmsElement = this.props.node? this.props.node : APP.NDT.getNode(this.props.elementId);
        if(rmsElement && rmsElement.element != undefined){
            window.scrollTo(0, (rmsElement.element.getBoundingClientRect().top) + window.scrollY); //window.scrollY
        } else {
            console.log("I hope we are an embedded structure: " + this.props.elementId);
        }
        
    }
    
    render(){
        const sectionSplit = _.filter(this.props.identificationNumber.split("."), (chunk)=>{ return !chunk.startsWith("0") });
        let sectionDepth = [];            
        for(var i=0; i<sectionSplit.length-1; i++){
            sectionDepth.push(<span className="toc_tab" key={"tabbed"+i}>&nbsp;</span>);
        }
        const depth = sectionSplit.length;
        
        const sectionNumberStyle = {};
        if(this.props.identificationNumber == undefined || this.props.identificationNumber.trim().length <= 0){
            sectionNumberStyle.width = "0px";
            sectionNumberStyle.marginRight = "0px";
        }
        
        const partOne = <span key="partOne" className={"toc_title " + ("depth_" + depth)}  ref={this.partOneRef}>
            {sectionDepth}
            <span className="sectionNumber" style={sectionNumberStyle}>{this.props.identificationNumber}</span>
            <span className="sectionTitle" onClick={()=>this.adventureForth()}>{this.props.title}</span>
        </span>; 
        const partTwo = <span className="toc_dots">{".".repeat(1000)}</span>;
        const partThree = <span key="partThree" className="toc_page" ref={this.partThreeRef}>{this.props.pageNumber}</span>;
        
        return <div className={"toc_row " + (sectionDepth.length <= 0? "bold" : "")  + (" row_depth_" + depth)}>
            {partOne}
            {partTwo}
            {partThree}
        </div>;
    }
}