import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import _ from 'lodash';
import { BoundDataContext, Icon } from '@opidcore/components';

const InputMultiCheckboxElement = ({option, handleClick, selected, children, clickBox = false, iconType, key})=>{
    if(children(option) == undefined){
        return null;
    }

    return <div key={key} className={"flex-item element " + (selected? "selected" : "")} onClick={clickBox ? null : ()=>handleClick(option)}>
        <Icon type={iconType} icon={selected ? "check-square" : "square"} onClick={clickBox ? ()=>handleClick(option) : null}/>  {children(option)}
    </div>
}

const DELIMITER = "|";

const InputMultiCheckbox = ({field, optionsCollection, optionKey, children, storeAs, grouped = false, clickBox = false, iconType = null, label = undefined, startSelected, onSelect = undefined})=>{
    const boundMagic = useContext(BoundDataContext);
    let [selected, setSelected] = useState([]);
    const [startSelectedRerender, setStartSelectedRerender] = useState(false)

    useEffect(()=>{
        let newSelected = boundMagic.magicalGet(field);
        if(newSelected == undefined){
            newSelected = [];
        } else if(storeAs == "string"){
            const options = newSelected.split(DELIMITER);
            newSelected = _.filter(optionsCollection, (o)=>{ 
                if(newSelected == "*"){
                    return true;
                }

                return options.indexOf(o[optionKey]) > -1;
            });
        }

        if(newSelected != selected){
            setSelected(newSelected);
        }
    }, [boundMagic, optionsCollection]);

    const handleClick = (option)=>{
        const key = option.id? "id" : optionKey;
        const foundIndex = _.findIndex(selected, (s)=>{ return s[key] == option[key]});
        const newSelected = [...selected];
        let isAdding = false;

        if(foundIndex >= 0){
            newSelected.splice(foundIndex, 1);
            isAdding = false;
        } else {
            newSelected.push(option);
            isAdding = true;
        }

        if(onSelect != undefined){
            onSelect(option, isAdding);
        }

        setBoundData(newSelected);
        setSelected(newSelected);
    };   

    const setBoundData = (data)=>{
        if(storeAs == "string"){
            let set = false;
            if(grouped == true){
                if(data.length == optionsCollection.length){
                    data = "*";
                    set = true;
                }
            }
            
            if(!set){
                data = _.map(data, (d)=>{ return d[optionKey] }).join(DELIMITER);
            }            
        }

        boundMagic.magicalSet(field, data);
    }

    const selectAll = ()=>{
        let newSelected = [];
        if(selected.length < optionsCollection.length){
            //select all
            newSelected = [...optionsCollection];
        }

        setBoundData(newSelected);
        setSelected(newSelected);
    };

    const checkboxes = _.map(optionsCollection, (option)=>{
        const key = option.id? "id" : optionKey;

        const isSelected = _.filter(selected, (s)=>{ return s[key] == option[key]}).length > 0;
        return <InputMultiCheckboxElement key={option[key]} option={option} handleClick={(option)=>handleClick(option)} selected={isSelected} iconType={iconType} clickBox={clickBox}>
            {(row)=>children(row, boundMagic)}
        </InputMultiCheckboxElement>;
    });

    if (startSelected == true && startSelectedRerender == false) {
        selectAll();
        setStartSelectedRerender(true)
    }

    return <div className="input-multi-checkbox-select field">
        {label != undefined? <label>{label}</label> : null}
        <div className="select-all">
            {checkboxes.length > 0? <button onClick={selectAll}>All</button> : null}
        </div>
        {checkboxes}
    </div>
}

InputMultiCheckbox.proptypes = {
    children: PropTypes.func,
    storeAs: PropTypes.oneOf(["string", "array"]),
    optionsCollection: PropTypes.arrayOf(PropTypes.object),
    optionKey: PropTypes.string,
    field: PropTypes.string,
    grouped: PropTypes.bool,
    clickBox: PropTypes.bool,
    iconType: PropTypes.string
}

InputMultiCheckbox.defaultProps = {
    storeAs: "array"
}

export default InputMultiCheckbox;