import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Pagination, TabularColumn, Tabular } from "@opidcore/components";
import ActionBar from "@opidcore/components/ActionBar";
import Icon from "@opidcore/components/Icon";
import Searchable from "@opidcore/components/Searchable";
import { useData, useNavBar } from "@opidcore/hooks/WTF";

import { AccessControl } from "../../../../../OpidCore/public/js/components";
import CesarFilter from "../../components/CesarFilter";
import ExcelExportModal from "../../components/ExcelExportModal";
import FullTextSearchable from "../../components/FullTextSearchable";

export default function ListVendors(props) {
  useNavBar("Vendors", [], { menu: ["vendors"] });


  const [vendors, setVendors] = useState([]);
  const [fetchVendors, setFetchVendors] = useState(false);



  const excelExport = () => {
    APP.central.Vendor.getDefaultExportLayout().then((r) => {
      APP.instance.createModal(<ExcelExportModal defaultLayout={r.result} dataSet={dataSet} model={"Vendor"} />, { modal_name: "Excel Export" });
    });
  };

  const handleClick = (row) =>{
    APP.redirect("/ui/vendors/edit/" + row.friendlyId);
  }

  const newVendor = ()=>{
    APP.setState("redirect", "/ui/vendors/edit/0");
  }

  return (
    <div key="list_vendors" className="full-size">
      <ActionBar title="Actions">
        <FullTextSearchable
            model={"Vendor"}
            method={"reasonableVendorList"}
            setData={setVendors}
            doFetchData={fetchVendors}
            setDoFetchData={setFetchVendors}
            maxRows={300}
            filterOptions={{
              columns: [
                { column: "name" },
                { column: "address" },
                { column: "city" },
                { column: "status"}
              ],
            }}
        />
        <Icon icon="file-excel" size="2x" title="Open export modal" onClick={() => excelExport()} />
        <div />

        <AccessControl resource="Controlled Data Lists">
          <a className="btn btn-new"  style={{cursor: 'pointer'}} onClick={() => newVendor()}>
            {" "}
            <Icon color="white" icon="plus" /> {"\u00a0"} New Vendor
          </a>
        </AccessControl>

      </ActionBar>
      <Tabular
        data={vendors}
        onClick={(row) => {
          APP.redirect("/ui/vendors/edit/" + row.id);
        }}       
        pageSize={100}
        paginationLocation={"both"}
      >
        <TabularColumn data={(row) =>  row.friendly_id} title="#" />
        <TabularColumn data={(row) => row.name} title="Name" />
        <TabularColumn data={(row) => row.address} title="Address" />
        <TabularColumn data={(row) => (row.city ? row.city + ", " + row.province : "")} title="City" />
        <TabularColumn data={(row) => row.status} title="Status" />
        <TabularColumn data={(row) => row.contracts} title="Contracts" />
        <TabularColumn
          data={(row) => {
            return (
              <Link onClick={(e)=>e.stopPropagation()} to={"/ui/vendors/edit/" + row.id}>
                <Icon icon="caret-right" size="2x" />
              </Link>
            );
          }}
        />
      </Tabular>      
    </div>
  );
}
