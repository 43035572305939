import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { DataContext } from './Bound';

const InputSchedulePicker = ({label, field}) =>{
    const bound = useContext(DataContext);
    
    return <div className="input-schedule-picker">
        {label? label: undefined}
    </div>;
}

InputSchedulePicker.propTypes = {
    field: PropTypes.string,
    label: PropTypes.string
}

InputSchedulePicker.defaultProps = {
    label: "TODO",
    field: undefined
}

export default InputSchedulePicker;