import React, { useState, useEffect, useRef } from 'react';
//import { Data } from '@opidcore/Data.js';
import {  Bound, Loading, Button,  InputText } from '@opidcore/components';
import { PageNumber } from '@opidcore/components/Pagination';
//import { DataContext } from '@opidcore/components/Bound';
//import { GridContext, GridHeading, GridItemGroup } from '@opidcore/components/Grid';
//import File from '../../components/File';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { InputSelect } from '../../../../../OpidCore/public/js/components';
// import { CompensationSelector } from '../../components/JasonBlocks'
// import RegionSelect from '../../../../../OpidCore/public/js/components/RegionSelect';
// import NotificationBell from '../../components/NotificationBell'
import { useData, useMagic } from '@opidcore/hooks/WTF';

export default function PDFSetup({ parameters }) {
    const params = parameters ? parameters : useParams();

    const [pdfInfo, setPdfInfo] = useState({ loading: true });
    const [pos, setPos] = useState({ x: 0, y: 0 });
    const [rect, setRect] = useState(null)
    const fileMD5 = params.md5;
    const worklowId = params.workflow;
    const rectRef = useRef(null);
    
    const [pageNum, setPageNum] = useState(params.pageNumber ? params.pageNumber : 0);

    const [ruleShape, setRuleShape] = useState("Point")
    const [ruleList, setRuleList] = useState(null)

    const [workflowStepOptions, setWorkflowStepOptions] = useState({});
    const editor = useMagic({});

   

    const refreshPDF = () => {
        APP.central.Import.pdfInfo2(fileMD5, pageNum).then((r) => {
            setPdfInfo(r.result);
        });
    };

    useEffect( ()=>{
        APP.central.WorkflowTesting.getOptions().then( (r)=>{
            setWorkflowStepOptions(r.result);
        });
    }, []);

    useEffect(() => {
        refreshPDF();
    }, [pageNum]);

    /** useEffect(() => {
        if (ruleList != null) {
            ruleList.forEach((rule) => { if (rule.div == null) drawThing(rule) })
        }
    }, [ruleList]) **/

    const drawThing = (rule) => {
        switch (rule.ruleType) {
            case "Box":
                let box = drawBox(rule.pageX1, rule.pageY1, rule.pageX2, rule.pageY2, rule.left, rule.bottom)
           
                document.body.appendChild(box)
                rule.div = true
              
                break
            case "Point":
                let point = drawPoint(rule.pageX1, rule.pageY1, rule.pageX2, rule.pageY2, rule.left, rule.bottom)
                document.body.appendChild(point)
                rule.div = true
           
                break
            case "Line":
                let line = drawLine(rule.pageX1, rule.pageY1, rule.pageX2, rule.pageY2, rule.left, rule.bottom)
                document.body.appendChild(line)
                rule.div = true
               
                break
            default:
          
                break
        }
    }

    function drawLine(x1, y1, x2, y2, leftOffset, topOffset) {     
        let a = x1 - x2,
            b = y1 - y2,
            length = Math.sqrt(a * a + b * b);

        let sx = (x1 + x2) / 2,
            sy = (y1 + y2) / 2;

        let x = sx - length / 2,
            y = sy;

        let roundX = x.toFixed(1)
        let roundY = y.toFixed(1)   
        var angle = Math.PI - Math.atan2(-b, a);
        let line = document.createElement("div")
        let lineStyle = 'border: 1px solid black; '
            + 'width: ' + length + 'px; '
            + 'height: 0px; '
            + '-moz-transform: rotate(' + angle + 'rad); '
            + '-webkit-transform: rotate(' + angle + 'rad); '
            + '-o-transform: rotate(' + angle + 'rad); '
            + '-ms-transform: rotate(' + angle + 'rad); '
            + 'position: absolute; '
            + 'top: ' + (roundY + 190) + 'px; '
            + 'left: ' + (roundX + 270) + 'px; '
            + 'pointer-events: none; '
        line.setAttribute("style", lineStyle)
        return line
    }

    function drawBox(x1, y1, x2, y2, leftOffset, topOffset) {
        let width = x2 - x1
        let height = y2 - y1

        let box = document.createElement("div")
        let boxStyle = 'border: 1px solid black; '
            + 'width: ' + width + 'px; '
            + 'height: ' + height + 'px; '
            + 'position: absolute; '
            + 'top: ' + (y1 + 190) + 'px; '
            + 'left: ' + (x1 + 270) + 'px; '
            + 'pointer-events: none; '
        box.setAttribute("style", boxStyle)
        return box
    }

    function drawPoint(x1, y1, x2, y2, leftOffset, topOffset) {
        let point = document.createElement("div")
        let pointStyle = 'border: 4px solid black; '
            + 'width: 1px; '
            + 'height: 1px; '
            + 'position: absolute; '
            + 'top: ' + (y1 + 190) + 'px; '
            + 'left: ' + (x1 + 270) + 'px; '
            + 'pointer-events: none; '
        point.setAttribute("style", pointStyle)
        return point
    }

    if (pdfInfo.loading) {
        return <Loading />;
    }

    const positionFromMouse = (e) => {
        const outer = e.target.getBoundingClientRect();
        const newPos = { x: e.pageX - outer.x - window.scrollX, y: e.pageY - outer.y - window.scrollY };
        newPos.pdfX = pdfInfo.width * newPos.x / outer.width;
        newPos.pdfY = pdfInfo.height * newPos.y / outer.height;
        return newPos;
    }

    const followMouse = (e) => {
        const newPos = positionFromMouse(e);
        setPos(newPos);
    };

    const appendOutline = (outline) => {
        if (ruleList == null) {
            setRuleList([outline])
        } else {
            setRuleList([...ruleList, outline])
        }
    }

    const mouseUp = (e) => {
        rectRef.current.end = positionFromMouse(e);

        const left = rectRef.current.start.pdfX < rectRef.current.end.pdfX ? rectRef.current.start.pdfX : rectRef.current.end.pdfX;
        const bottom = pdfInfo.height - (rectRef.current.start.pdfY > rectRef.current.end.pdfY ? rectRef.current.start.pdfY : rectRef.current.end.pdfY);

        const width = Math.abs(rectRef.current.start.pdfX - rectRef.current.end.pdfX);
        const height = Math.abs(rectRef.current.start.pdfY - rectRef.current.end.pdfY);

        const ruleOutline = {
            left: left,
            bottom: bottom,
            pageX1: rectRef.current.start.x,
            pageY1: rectRef.current.start.y,
            pageX2: rectRef.current.end.x,
            pageY2: rectRef.current.end.y,
            pdfX1: rectRef.current.start.pdfX,
            pdfY1: rectRef.current.start.pdfY,
            pdfX2: rectRef.current.end.pdfX,
            pdfY2: rectRef.current.end.pdfY,
            pageNumber: 1,
            ruleType: ruleShape,
            ruleNumber: (ruleList == null ? 1 : ruleList.length + 1),
            div: null
        }
        appendOutline(ruleOutline)
    };

    const mouseDown = (e) => {
        e.preventDefault();
        rectRef.current = { start: positionFromMouse(e) };
    };

    const pages = _.map(_.range(1, pdfInfo.pages + 1), (p) => {
        return <PageNumber pageNumber={p} activePage={pageNum + 1} onClick={() => setPageNum(p - 1)} />
    });

    function RuleSection(props) {
        let ruleSection = []
        if (ruleList == null) {
            return <div>
                No rules made.
            </div>
        } else {
            ruleList.forEach((rule) => {
                ruleSection.push(<span>
                    Page x1: {rule.pageX1}, Page y1: {rule.pageY1}
                    Page x2: {rule.pageX2}, Page y2: {rule.pageY2}
                    PDF x1: {rule.pdfX1}, PDF y1: {rule.pdfY1}
                    PDF x2: {rule.pdfX2}, PDF y2: {rule.pdfY2}
                </span>)
            })
        }
        return ruleSection
    }

    const add = (a,b,c)=>{
        const m = editor;
        debugger;
    }

    return <div className="pdf-preview">


        <div id="pagination">
            <span>Page:</span> {pages}
        </div>
        <div className="pdf-preview-outer">
            <img src={pdfInfo.image} className="preview" onMouseMove={followMouse} onMouseDown={mouseDown} onMouseUp={mouseUp} />
        </div>

        {JSON.stringify(ruleList)}
        <WorkflowEditor id={worklowId} workflowStepOptions={workflowStepOptions}/>
    </div>;
}

export function WorkflowEditor({id, workflowStepOptions, level=0}){
    const [workflow, ds] = useData('workflowautomation', id);

    const bound = useMagic(workflow);

    useEffect( ()=>{
        bound.replaceTo(workflow);
    }, [workflow]);

    const save = ()=>{
        APP.central.WorkflowTesting.saveAutomation(bound.to).then( (r)=>{
            debugger;
        });
        //APP.central.Bound.saveChanges(bound
    };

   
    return <div>
        <h3>Workflow {workflow.name}</h3>

        <WorkflowSteps level={level} bound={bound} workflowStepOptions={workflowStepOptions}/>

        <Button onClick={save}>Save</Button>
    </div>
}

export function WorkflowSteps({bound, workflowStepOptions,level}){
    const [steps, setSteps] = useState([]);
    const [stepCount, setStepCount] = useState(0);

    useEffect( ()=>{
        bound.magicalState('steps', (s)=>{
            setStepCount(s != null ? s.length : 0);
            setSteps(s)});
    }, [bound]);



    const stepViews = _.map( steps, (s,idx)=>{
        return <WorkflowStep level={level} key={100+idx+"-"+s.__type} idx={idx} workflowStepOptions={workflowStepOptions} step={bound.getBound('steps',idx)}/>
    });

    const add = ()=>{
        bound.magicalAppend('steps', {});
    }
    
    return <div style={{paddingLeft: (level*5) + "em"}}>
        <ol>
            {stepViews}
        </ol>
        <Button onClick={add}>Add Step</Button>
    </div>
}

export function WorkflowStep({workflowStepOptions, step, level, idx}){
        const [editing, setEditing] = useState(false);
        const [fields, setFields] = useState([]);
        const [showSteps, setShowSteps] = useState(false);
        const magic = useMagic(step);

        useEffect( ()=>{
            if (workflowStepOptions.stepTypes && workflowStepOptions.stepTypes.length > 0){
                magic.magicalState("__type", (a,b,c)=>{
                    const typeSelected = _.find(workflowStepOptions.stepTypes, {name: a});
                    if (typeSelected){
                        setFields( _.map(typeSelected.fields, (f)=>{
                            return <InputText field={f.name} name={f.name}/>
                        }));
                        if (typeSelected.hasSteps){
                            setShowSteps(true);
                        }
                    }
                });
            }   
        }, [magic, workflowStepOptions]);

        const save = ()=>{
            setEditing(false);
        }
        
        const handleDelete = ()=>{
            magic.getParentMagic().magicalSplice('steps', idx, true);
        }

        const handleMoveUp = ()=>{
            const currentSteps = magic.getParentMagic().magicalGet('steps');
            if (idx > 0){
                const other = currentSteps[idx-1];
                currentSteps[idx-1] = currentSteps[idx];
                currentSteps[idx] = other;
            }
            magic.clearGotBounds();
            magic.getParentMagic().magicalSet('steps', currentSteps);
        }

        const handleMoveDown = ()=>{
            const currentSteps = magic.getParentMagic().magicalGet('steps');
            if (idx < currentSteps.length-1){
                const other = currentSteps[idx+1];
                currentSteps[idx+1] = currentSteps[idx];
                currentSteps[idx] = other;
            }
            magic.clearGotBounds();
            magic.getParentMagic().magicalSet('steps', currentSteps);
        }

        let editor = null;
        if (editing){
            editor = <Bound to={step}>
            <InputSelect field="__type" optionsCollection={workflowStepOptions.stepTypes} fieldKey="name" name="Type"/>
            {fields}
            <Button onClick={save}>Apply</Button>
        </Bound>;
        }

    return <li>
        <h4>Type {step.__type}</h4>
    {editing ? editor : <> <Button onClick={()=>setEditing(true)}>edit</Button> </> }
        { showSteps ?  <WorkflowSteps level={level+1} bound={step._ourMagic} workflowStepOptions={workflowStepOptions}/> : null }

        <>
        <Button onClick={handleDelete}>Delete</Button>            
        <Button onClick={handleMoveUp}>Up</Button>            
        <Button onClick={handleMoveDown}>Down</Button>            
        </>
    </li>
}