import React, { useEffect, useState }  from 'react';
import Icon from './Icon';
import * as _ from 'lodash';

export default function Expandable(props) {
    let [open, setOpen] = useState(false || props.defaultOpen);
    useEffect(()=>{
        if(props.open != undefined){
            setOpen(props.open);
        }
    }, []);
    
    return <div className="expandable" style={props.style}>
        <div style={{display: open? "initial": "none"}}>
            {props.children}
        </div>
        { open ? null : props.minimizedContent }
        <Icon className="action" icon={open? "chevron-up" : "chevron-down"} onClick={()=>{ setOpen(!open) }}/>
    </div>
}