import React, { useState, useEffect }  from 'react';
import { Data } from '@opidcore/Data.js';
import { Link } from 'react-router-dom';
import Icon from '@opidcore/components/Icon.jsx';
import BarGraph from '../BarGraph.jsx';
import {useNavBar, addRelatedForms } from '@opidcore/hooks/WTF'; 
import ActionBar from '@opidcore/components/ActionBar';
import _ from 'lodash';

export default function WasteAudit(props){
	const auditId = parseInt( props.match.params.id );
	const nav = useNavBar("Loading Audit... ");  
	
	const [audit, setData] = useState({});
	const [stats, setStats] = useState({loading: true});
	const [dataSet, setDataSet] = useState( new Data({form: "waste_audit"}, (r)=>setData(r.length > 0 ? r[0] : {}) ) );
	
	const [related, setRelated] = useState(null);
	
	
	const getLinks = (related) =>{
		
		let realtedIds = {}; 
		if (related){
			realtedIds = _.fromPairs( _.map( _.groupBy(related, 'uid'), (g,l)=>[ l, _.map(g, 'id')[0] ] ) );
		}

		return [<Link to={"/ui/audit/" + auditId}>Summary</Link>,					
			<Link to={"/view/waste_audit/" + auditId}>Setup</Link>,
			<Link to={"/ui/audit/" + auditId + "/tenants"}>{audit.tenant_noun || "Tenants"}</Link>,
			<Link to={"/ui/audit/" + auditId + "/materials"}>Materials</Link>,
			<Link to={"/view/audit_collection/" + realtedIds.audit_collection }>Data</Link>,
			<Link to={"/view/audit_pictures/" + realtedIds.audit_pictures }>Photos</Link>,
			<Link to={"/view/recommendations/" + realtedIds.audit_recommendations}>Recommendations</Link>,
			<Link to={"/contentInstance/" + (audit.report_template || "on.audit-template") + "/audit/" + audit.id}>Report</Link>,
			<Link to={"/ui/audit/" + auditId + "/history"}>History</Link>			
			];
		
	}
	
	const makeTitle = (audit)=>{
	    return "Audit #" + audit.id + ": " + audit.client_name;
	}

	useEffect(()=>{
		dataSet.setQueryArgs({"single": auditId});
		dataSet.fetch();
		nav.update(null, getLinks(related));
	}, []);
	
	useEffect(()=>{
		if (_.keys(audit).length > 0){
			const title = makeTitle(audit);
			nav.update(title);
			
			WASTE_AUDIT.init( audit.id );
			
			WASTE_AUDIT.process().then((r)=>{
				setStats(r);
			});
			/** APP.central.WasteAudit.allData( audit.id ).then((r)=>{
				
			}); **/
		}
	}, [audit]);
	
	let relatedFormsTracker = APP.getState("related_forms");
	
	useEffect(()=> {
		APP.central.WasteAudit.prepAudit(auditId).then( (r)=>{
			APP.central.Stuff.findRelatedForms({field_def_name: "waste_audit", search_value: auditId}).then(
					(r)=>{
						setRelated(r.result);
						nav.update(makeTitle(audit), getLinks(r.result), {selectedItem: props.selectedItem ? props.selectedItem : 0});
						addRelatedForms(r.result);
					}				
			);	
		});
		
		
	
	}, [audit]);
	
	
	if (props.children){
		return props.children;
	}	
	
    return <>
    	<div className="page-block">
    	
    	<ActionBar title="Actions">
    		<h2>{audit.date} <span className="status">{audit.status || "No Status"}</span></h2>
		</ActionBar>
	
	
	    	{stats.loading ? "thinking about stats... " :""}
	    	
	    	<div className="metrics-container">
	    		<div className="metric">
	    			<span className="label"><Icon icon="chart-pie" size="3x" color="#666"/> Diversion Rate</span>
	    			<span className="value">{ Math.round(stats.diversionRate || 0) }%</span>    			
	    		</div>
	    		
	    		<div className="metric">
					<span className="label"><Icon icon="biohazard" size="3x" color="#666"/> Contaminated</span>
					<span className="value">{ (stats.totalContaminationAnnual / 1000 || 0).toFixed(2) }</span>
					<span className="units">Tonnes</span>
				</div>
				
				<div className="metric">
					<span className="label"><Icon icon="bullseye" size="3x" color="#666"/> Capture Rate</span>
					<span className="value">{ Math.round( stats.captureRate|| 0)  }%</span>    			
				</div>
	    	</div>
    	</div>
    	
    	<div className="page-block">
	    	<h3>Material Breakdown</h3>
	    	
	    	<div>
	    		<BarGraph width="90%" height={ ( 5 + _.size(stats.materialTotalGenerated) * 2 ) + "em"} plot={stats.materialTotalGenerated} hideTitle={true}/>
	    	</div>
    	</div>
    </>;
}


export function CustomSummary(props){
	const auditId = props.data ? props.data.id : 0;
	
	const deleteMe = (e)=>{
		APP.confirm("Are you sure you want to delete this Audit?", (res)=>{
			if(res == true){
				APP.central.WasteAudit.deleteAudit(auditId).then((r)=>{
					APP.alert(r.result);
					APP.setState("redirect", "/ui/audit");	
				});
			}
		});
	};

	return <div style={{order:100}} className="advancedbar">		
			
				<a className="btn btn-new" onClick={deleteMe}>Delete</a>	
			</div>
}


export function BlockThing(props){
	const auditId = props.data ? props.data.id : 0;

	// this looks stupid but it puts it into "tile" mode.
	return <div className="page-block" style={{display: "none"}}>		
			
	</div>
}

window._CUSTOM_FORM_ELEMENTS = { waste_audit: CustomSummary,
	audit_collection: BlockThing,
	audit_pictures: BlockThing
};
