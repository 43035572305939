import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as CentralActions from '../../../OpidCore/public/js/actions/CentralActions';

//import { InputText, InputSelect, DatePicker, Bound} from '../../../OpidCore/public/js/components';

import * as _ from 'lodash';
//import moment from 'moment';

//import RenderMeSoftly  from '../../../OpidCore/public/js/components/Content/RenderMeSoftly.jsx';


class AdminView extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
                loginError: undefined
        };
    }
   
    render(){  
    	var links = [];
    	
    	return   <div id="admin" className="text-center container">
    				<div style={{"clear": "both"}}></div>
		     
		            
		            <div className="row">
						{links}
						
						<Link className="btn btn-default" to={"/admin/user/list"}>Edit Users</Link>					
						<Link className="btn btn-default"  to={"/form/list"}>Add/Edit Forms</Link>
						<Link className="btn btn-default"  to={"/dataview/list"}>Add/Edit Views</Link>
						<Link className="btn btn-default"  to={"/definition/list"}>Add/Edit Field Definition</Link>   
		            </div>
		            
        	</div>
        				    	
	 
       
    }
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        ...CentralActions.modifyProps(dispatch),  
        actions: bindActionCreators({...CentralActions}, dispatch),    
    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminView);
