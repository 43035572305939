import React, { useEffect, useRef, useState } from 'react';
import { useChromeBlockProvider } from '@opidcore/hooks/WTF';
import _ from 'lodash';
import moment from 'moment';

import Util from '../../../../../OpidCore/public/js/Util';
import '../../../styles/pdf-reports.scss';


export const footnotes = (footnotesRef, line) => {
    const ret = [];

    if (line.before_footnotes){
        let existing = _.find(footnotesRef.current, (f)=>f.text == line.before_footnotes );
        if (existing == undefined){
            existing = { number: footnotesRef.current.length+1, text: line.before_footnotes};
            footnotesRef.current.push(existing);
        }
        if (ret.length > 0){
            ret.push(<sup>, </sup>);
        }
        ret.push(<sup>{existing.number}</sup>);
    }

    if (line.after_footnotes){
        let existing = _.find(footnotesRef.current, (f)=>f.text == line.after_footnotes );
        if (existing == undefined){
            existing = { number: footnotesRef.current.length+1, text: line.after_footnotes};
            footnotesRef.current.push(existing);
        }
        if (ret.length > 0){
            ret.push(<sup>, </sup>);
        }
        ret.push(<sup>{existing.number}</sup>);
        
    }

    return ret;
}


const ReportConsulting = ({reportTemplate, invoiceIds, showBaseline}) => {
    const [data, setData] = useState({details: {rows:[]}});
    const [groupedData, setGroupedData] = useState({});
    const chromeBlock = useChromeBlockProvider();
    const footnotesRef = useRef([]);
    
    let columnsWide = 7;
    let isDetailed = false;
    let showActivity = false;
    let showAllPricing = true;
    let showServiceDescription = false;

    if (reportTemplate.toLowerCase() == "detailed"){
        columnsWide = 11;
        isDetailed = true;
        showActivity = true;
    }
    
    if (reportTemplate == "service"){
        isDetailed = false;
        showAllPricing = false;
    }

    if (reportTemplate == "activity"){
        columnsWide = 5;
        showActivity = true;
        showAllPricing = false;
        showServiceDescription = true;
    }

    if (reportTemplate == "activityWithPrice"){
        columnsWide = 11;
        isDetailed = true;
        showAllPricing = true;
        showActivity = true;
    }

    useEffect(()=>{        
        APP.central.ARInvoice.findInvoiceData(invoiceIds, reportTemplate, showBaseline).then( (r)=>{
            setData(r.result);

            chromeBlock.doneLoading();
        });        
    }, [])

    useEffect( ()=>{
        if (data.details){
            const grouped = _.map( _.groupBy( data.details.rows, (d)=>d.site_name ), (lines,site_name)=>{
                return {title: site_name + ' - ' + lines[0].site_address, lines: _.sortBy(lines, (l)=>l.invoice_description + " " + l.service_short_description + " " + l.period_from) };
            });

            setGroupedData(_.sortBy(grouped, (r)=>r.title));
        }
    }, [data]);

    let totalSavings = 0;

    let totalNetSavings = 0;

    //make sure cleared if we rerender
    footnotesRef.current = [];

    const tables = _.map(groupedData, (site)=>{
        const rows = [];

        if (isDetailed){
            rows.push ( <tr>
                <td></td>
                <td></td>
                <td></td>
                <th colSpan={3}>Baseline</th>
                <th colSpan={3}>Current</th>
                <th> </th>
                <th> </th>
            </tr>
            );

            rows.push ( <tr>
                <td></td>
                <td></td>
                <td></td>
                <th>Quantity</th>
                <th>Price</th>
                <th>Amount</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Amount</th>
                <th>Savings</th>
                <td></td>
            </tr>
            );
        }else{
            rows.push ( <tr>
                <td></td>
                <td></td>
                <th>Baseline</th>
                <th>Current</th>
                <th>Savings</th>
            </tr>
            );
        }
        let lastShortDescription = "";
        _.forEach( site.lines, (line)=>{
            if (line.service_short_description != lastShortDescription && (isDetailed || showServiceDescription) ){
                rows.push ( <tr>
                    <td className="service" colSpan={columnsWide}>{line.service_short_description}</td>
                </tr>
                );
            }
            lastShortDescription = line.service_short_description;

            let dateToShow = line.period_from ? moment(line.period_from).format("MM-DD-YYYY") : "";
            if (line.period_from && line.period_to){
                const dateFrom = moment(line.period_from);
                const dateTo = moment(line.period_to);
                if (dateFrom.get('date') == 1 && dateTo.get('date') == dateTo.endOf('month').get('date') ){
                    dateToShow = dateFrom.format("MMM YYYY");
                }
            }

            let baselinePriceDisplayed = <DollarValue value={line.baseline_unit_price}/>;
            if (line.unit_price_per == "surcharge" || line.baseline_unit_price_per == "%"){
                baselinePriceDisplayed = <DecimalValue value={line.baseline_unit_price * 100} units={"%"}/>;
                dateToShow = "";
            }

            let priceDisplayed = <DollarValue value={line.unit_price}/>;
            if (line.unit_price_per == "surcharge" || line.unit_price_per == "%"){
                priceDisplayed = <DecimalValue value={line.unit_price * 100} units={"%"}/>;
                dateToShow = "";
            }
            
            if (isDetailed){
                if (line.extended_price != 0 || line.activity > ""){    
                    rows.push ( <tr>
                            <td>{dateToShow}</td>
                            <td>{line.activity}</td>
                            <td>{line.item_description}</td>
                            <td><DecimalValue value={line.baseline_quantity}/></td>
                            <td>{baselinePriceDisplayed}</td>
                            <td><DollarValue value={line.baseline_extended_price}/></td>
                            <td><DecimalValue value={line.quantity}/></td>
                            <td>{priceDisplayed}</td>
                            <td><DollarValue value={line.extended_price}/></td>
                            <td><DollarValue value={line.savings}/></td>
                            <td style={{whiteSpace: 'nowrap'}}>{footnotes(footnotesRef,line)}</td>
                        </tr>
                        );
                }
            }else{
                rows.push ( <tr>
                        <td>{showActivity ? line.activity : line.invoice_description }</td>
                        <td>{line.item_description}</td>
                        <td> </td>
                        <td><DollarValue value={line.baseline_extended_price}/></td>
                        <td><DollarValue value={line.extended_price}/></td>
                        <td><DollarValue value={line.savings}/></td>
                        <td style={{whiteSpace: 'nowrap'}}>{footnotes(footnotesRef,line)}</td>
                    </tr>
                    );
            }
        });

           
            
            let totalBaselineSite = _.sum( _.map(site.lines, (line)=>line.baseline_extended_price));
            let totalSite = _.sum( _.map(site.lines, (line)=>line.extended_price));
            let totalSavings = _.sum( _.map(site.lines, (line)=>line.savings));

            if (isDetailed){
                rows.push ( <tr className="total">
                    <th colSpan={3}>{"Site Totals"}</th>
                    

                    <td></td>
                    <td></td>
                    <td style={{width: "8em"}}><DollarValue value={totalBaselineSite}/></td>
                    <td></td>
                    <td></td>
                    <td style={{width: "8em"}}><DollarValue value={totalSite}/></td>
                    <td style={{width: "8em"}}><DollarValue value={totalSavings}/></td>
                    <td></td>
                </tr>
                );

            }else{
                rows.push ( <tr className="total">
                    <th>{"Site Totals"}</th>
                    { columnsWide > 2 ? <td colSpan={columnsWide-5}></td> : null }
                    <td style={{width: "8em"}}><DollarValue value={totalBaselineSite}/></td>
                    <td style={{width: "8em"}}><DollarValue value={totalSite}/></td>
                    <td style={{width: "8em"}}><DollarValue value={totalSavings}/></td>
                </tr>
                );
            }


            const siteSavings = _.sum(_.map(site.lines, l=>l.savings));
            totalSavings += siteSavings;

            const netDueSavings = _.sum(_.map(site.lines, l=>l.net_due_savings));
            totalNetSavings += netDueSavings;
            rows.push( <SubTotalRow extraSpans={isDetailed ? 3 : 1} columnsWide={columnsWide} label={"Net Due Savings"}><DollarValue value={netDueSavings}/></SubTotalRow> );

            /** let totalTax = 0;
            _.forEach( _.groupBy( site.lines, 'tax_name'), (lines, tax)=>{ 
                const thisTax = _.sum(_.map(lines, 'total_tax'));
                totalTax += thisTax;                
                rows.push( <SubTotalRow extraSpans={isDetailed ? 3 : 1}  columnsWide={columnsWide} label={tax}><DollarValue value={thisTax}/></SubTotalRow> );
            }); **/

           
            if (footnotesRef.current.length > 0){
                rows.push ( <tr className="total">
                <td colSpan={columnsWide} className="footnote">
                    {_.map(footnotesRef.current, (f)=><p>{f.number + ': ' + f.text}</p>)}
                </td>
                </tr>
                );  
            }

        return  <BlockTable title={site.title} columns={columnsWide}>
                {rows}
            </BlockTable>;
    } );


    const totalSite = _.sum( _.map(data.details.rows, 'extended_price') );

    const rows = [<tr>
        <td colSpan={columnsWide-1}>Total Net Savings Due</td>
        <td style={{width: "8em"}}><DollarValue value={totalNetSavings}/></td>
    </tr>];

    let totalTax = 0;
    _.forEach( data.taxes, (value,  taxName)=>{ 
        totalTax += value;
        if (taxName != null && taxName != "null"){
            rows.push( <tr>
                    <td colSpan={columnsWide-1}>{taxName}</td>
                    <td><DollarValue value={value}/></td>
            </tr> );
        }
    });


            rows.push ( <tr className="total">
            <th colSpan={columnsWide-1}>{"Invoice Total"}</th>
            <td style={{width: "8em"}}><DollarValue value={totalNetSavings + totalTax}/></td>
        </tr>
        );

    let invoiceTotals = <BlockTable title="Invoice Totals" columns={columnsWide}>
        {rows}
    </BlockTable>;

    const reportDate = data.details.rows.length > 0 ? moment(_.min(data.details.rows.map((r)=>r.period_from))) : null;

    return <div className={columnsWide > 10 ? "small-detailed-report" : "detailed-report"}>
        <div className="heading">
            <img src="/assets/waste/ws-logo.png" className="brand-logo"/>
            <div className="titles">
                <h1>Invoice Details</h1>
                <h2>{reportDate != null  ? reportDate.format('MMMM YYYY') : ""}</h2>
            </div>
        </div>

       {tables}

       {invoiceTotals}   
    </div>;
}


export const BlockTable = ({title="No Title", columns=1, children}) => {

    return <>
        <table className="block-table">
            <thead>
                <tr><th colSpan={columns} className="title">{title}</th></tr>
            </thead>
            <tbody>
                {children}
            </tbody>
        </table>
    </>;
}

const DollarValue = ({value})=>{
    return <div className="dollar-value"><span className="sign">$</span> <span>{Util.roundNice(value, 2)}</span></div>
}

const DecimalValue = ({value,units=null})=>{
    return <div className="decimal-value" style={units ? {textAlign: "right"} : {} }><span>{Util.roundNice(value, 2)}</span> { units ? <span>{units}</span> : null }</div>
}

const SubTotalRow = ({label, children, columnsWide, extraSpans = 0})=>{
    const labelSpan = columnsWide > 2 ? columnsWide-2 : 1;

    return <tr>
            {columnsWide > 2 ? <td colSpan={extraSpans || 1}></td> : null}
            <td colSpan={labelSpan - (extraSpans ? extraSpans - 1 : 0) - 1}>{label}</td>
            <td>{children}</td>
            <td></td>
    </tr>
};
    
export default ReportConsulting;
