import React, { useState, useRef, useEffect, Fragment } from "react";
import { Bound, Button, Icon, InputSelect, InputText, Tabular, TabularColumn, UnlockToEdit } from "@opidcore/components";
import _ from "lodash";
import LoadingProgress from "@opidcore/components/LoadingProgress";

export default function ExcelExportModal(props) {
  const defaultLayout = props.defaultLayout;
  const dataSet = props.dataSet;
  const model = props.model;

  const layout = useRef(defaultLayout);
  const options = useRef({ newColumn: "" });
  const loadedLayout = useRef({ loadedLayout: "", storageID: null });

  const [loadedLayoutOpts, setLoadedLayoutOpts] = useState();
  const [bound, setBound] = useState(null);
  const [currentColumns, setCurrentColumns] = useState([]);
  const [previewRows, setPreviewRows] = useState([]);
  const [colOptions, setColOptions] = useState([]);
  const [previewColumns, setPreviewColumns] = useState([]);

  if (dataSet.columns == null) {
    return <div>your dataSet didnt have columns or was not loaded.</div>;
  }

  //setup
  useEffect(() => {
    //for testing
    //window.cesarExportLayout = layout;
    //window.cesarExportDataSet = dataSet;

    //add column dropdown options
    setColOptions(
      dataSet.columns.map((col) => {
        return col.key;
      })
    );

    //populate layout filters for potential use?
    if (dataSet.queryArgs && dataSet.queryArgs.options) {
      _.forEach(dataSet.queryArgs.options, (val, key) => {
        let a = [];
        if (Array.isArray(val)) {
          _.forEach(val, (v) => {
            a.push(v);
          });
        } else {
          a.push(val);
        }
        layout.current.filters.push({ key: key, values: a });
      });
    }

    //populate Load Layout dropdown
    refreshLoadedLayouts();
  }, []);

  //refresh columns on bound change
  useEffect(() => {
    refreshColumns();
  }, [bound]);

  //sets columnEditors and previewColumns
  useEffect(() => {
    const newColumnEditors = currentColumns.map((col, i) => {
      const thisBound = bound;
      if (thisBound != null) {
        const boundCol = thisBound.getBound("columns", i);
        return (
          <Bound to={boundCol} key={col.key}>
            <div onKeyDown={checkForEnter}>
              <UnlockToEdit>
                <InputText field="key" name="Key" />
              </UnlockToEdit>
              <InputText field="label" name="Label" />

              <InputText field="formula" name="Advanced" update={() => updateKey(i, boundCol)} />
            </div>

            <Button
              onClick={() => {
                removeColumn(i);
              }}
            >
              Remove
            </Button>
            
            <div>
              <Icon
                className="action"
                icon={"arrow-left"}
                onClick={() => {
                  moveColumn("left", i);
                }}
              />
              <Icon
                className="action"
                icon={"arrow-right"}
                onClick={() => {
                  moveColumn("right", i);
                }}
              />
            </div>
          </Bound>
        );
      }
    });

    const something = currentColumns.map((col, i) => {
      const cesar = () => {
        return newColumnEditors[i] || "?";
      };
      const keyString = col.key;
      return <TabularColumn data={(row) => row[keyString]} title={col.label} headingComponent={cesar} key={i} />;
    });

    setPreviewColumns(something);
  }, [currentColumns]);

  //sets loadedLayoutOpts for Load Layout dropdown
  const refreshLoadedLayouts = () => {
    APP.central.File.getObjects("ExportLayout", model).then((r) => {
      setLoadedLayoutOpts(
        r.result.map((res) => {
          return res.fileTitle + " - " + res.storageIdentifier;
        })
      );
    });
  };

  //when "Export" is clicked
  const doExport = () => {
    APP.showLoading({ message: "Generating Export", children: <LoadingProgress /> }, () => {
      APP.central.Exporter.doExport(dataSet.uid, layout.current).then((r) => {
        APP.socket.send(JSON.stringify(r));
      });
    });
  };

  //when "Save Layout" is clicked
  const saveLayout = () => {
    APP.central.File.saveExportLayout(layout.current, "ExportLayout," + model, loadedLayout.current.storageID).then((r) => {      
      refreshLoadedLayouts();
    });
  };

  // when a layout is selected from the Load Layout dropdown
  const loadLayout = (a, b, c, d) => {
    if (d) {
      const stringArray = d.split("-");
      loadedLayout.current.storageID = stringArray[stringArray.length - 1].trim();

      APP.central.File.readExportLayout(loadedLayout.current.storageID).then((r) => {
        layout.current = r.result;
        bound.replaceTo(layout.current);
        refreshColumns();
      });
    } else {
      loadedLayout.current.storageID = null;
      layout.current = defaultLayout;
      refreshColumns();
    }
  };

  //bound init
  const init = (newBound, instance) => {
    if (bound == null) {
      setBound(newBound);
      newBound.on("columns", refreshColumns);
    }
  };

  //sets currentColumns and previewRows
  const refreshColumns = () => {
    //setCurrentColumns(_.cloneDeep(layout.current.columns));

    APP.central.Exporter.previewDataSet(dataSet.uid, layout.current, true).then((r) => {
      setPreviewRows(r.result.rows ? r.result.rows : []);
      setCurrentColumns(r.result.columns ? r.result.columns : []);
    });
  };

  //updates key when formula changed
  const updateKey = (i, boundCol) => {

    //bound.magicalAppend("columns", { key: options.current.newColumn, label: options.current.newColumn.toUpperCase() });
    const something = bound.getBound("columns", i);
    something.key = something.formula;
  };

  //refreshColumns on Enter press
  const checkForEnter = (e) => {
    if (e.key == "Enter") {
      refreshColumns();
    }
  };

  //move column
  const moveColumn = (direction, index) => {
    if (direction == "left" && index > 0) {
      layout.current.columns.splice(index - 1, 0, layout.current.columns.splice(index, 1)[0]);
    }
    if (direction == "right" && index < layout.current.columns.length - 1) {
      layout.current.columns.splice(index + 1, 0, layout.current.columns.splice(index, 1)[0]);
    }
    bound.clearGotBounds();
    refreshColumns();
  };

  //remove column
  const removeColumn = (index) => {
    layout.current.columns.splice(index, 1);
    bound.clearGotBounds();
    refreshColumns();
  };

  //add column
  const addColumn = () => {
    if (options.current.newColumn) {
      bound.magicalAppend("columns", { key: options.current.newColumn, label: options.current.newColumn.toUpperCase() });
    }
    bound.clearGotBounds();
    refreshColumns();
  };

  return (
    <Fragment>
      <div>
        <Bound to={layout.current} init={init}>
          <InputText field="title" name="title" />
        </Bound>
      </div>

      <div>
        <Button onClick={doExport}>Export</Button>

        <Bound to={options.current}>
          <InputSelect field="newColumn" name="New Column" options={colOptions} showEmpty={true} />
        </Bound>

        <Button onClick={addColumn}>Add Column</Button>

        <Bound to={loadedLayout.current} onChange={loadLayout}>
          <div>
            <InputSelect field="loadedLayout" name="Load Layout" options={loadedLayoutOpts} showEmpty={true} />
            <Button onClick={saveLayout}>Save Layout</Button>
          </div>
        </Bound>
      </div>

      <div>
        <Tabular data={previewRows} defaultSort="friendlyId" defaultSortOrder="asc" hashWithPosition={true}>
          {previewColumns}
        </Tabular>
      </div>
    </Fragment>
  );
}

/*
function FunkyDropdownThing(props) {
  const ds = props.dataSet;
  const options = useRef({ newColumn: "" });




  return (
    <Fragment>
      <Bound to={options.current}>
        <InputSelect field="newColumn" name="New Column" options={colOptions} showEmpty={true} />
      </Bound>
    </Fragment>
  );
}

*/
