import React, { Component } from 'react';


export const FilterContext = React.createContext(
        {"error_no_data": "Read the documentation on how to use opidcore's customize content!"}
      );


export default class FilterSet extends Component{
    constructor(props,context) {
        super(props,context);
        
        this.state = {
        };
        
        const formUid = props.scope.viewDefinition ? props.scope.viewDefinition.formUid : "default" ;
        
        this.filterContext = {status: "not_applied", 
                getData: ()=>{ if(this.props.NDT){ return this.props.NDT.getData(formUid, this) }},
                refresh: ()=>{ return this.props.NDT.theRefresh(formUid ) },
                applyDefault: this.props.applyDefault
        };
        
        
    }

    static getEditableProps (){
        return [
            {niceName: "Css Class", prop: "cssClass", type:"text", default:"", editInPlace: true},
            {niceName: "Apply By Default?", prop: "applyDefault", type:"checkbox"},

        ]
    }
    
    reset(){
    	debugger;
        this.filterContext.getData().reset();
        this.filterContext.refresh();
    }
    
    render(){   
        return <div className={this.props.cssClass}>
        	<FilterContext.Provider value={this.filterContext}>
        	{this.props.children}
        	</FilterContext.Provider>
        <button onClick={()=>this.reset()} >Reset</button>
     </div>

    }
}