//import './AddFriendInput.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BoundComponent from './BoundComponent';

import * as _ from 'lodash';

export default class InputSelectFilter extends BoundComponent {
	static propTypes = {
	    update: PropTypes.func,
	    property: PropTypes.string,
	};

	constructor(props, context) {
		super(props, context);
		
		var ourValue = null;
	
		if (props.obj instanceof Component && props.obj.state != null){
	    	ourValue = this.props.obj.state[props.field];
		 }

		this.state = {
	    		textValue: "",
	    		searching: false,
	    		value: ourValue,
	    };
	    
	    
	}
	
	labelFormatter(row){
        return row;
    }
	
	keyFormatter(row){
		if(row.id != undefined){
			return row.id;
		} else {
			return Math.random();
		}
    }
	
	getValue(row){
        return row.id;
    }
	
	getLabelForValue(id){
		return id + " needs a label for value";
	}
	
	search(){
		debugger;
	}
    
    handleChange(e){
    	if(e.target.value != undefined && e.target.value.trim().length <= 0){
    		this.setState({textValue: "", searching: false, value: undefined}, ()=>{
    			this.props.update(this.props.obj, this.props.field, undefined)
			});
    	} else {
    		this.setState({textValue: e.target.value, searching: true}, ()=>{ this.search(this.state.textValue); });
    	}
    }
    	
	componentWillReceiveProps(nextProps){
		  var nextState = {...this.state};
		  
		  if (this.state.doneSearching){
			  nextState.searching = false;
			  nextState.doneSearching = false;
		  
			  if(nextProps.obj.state != undefined && nextProps.obj.state[nextProps.field] != undefined){
			  //
				  nextState.value = nextProps.obj.state[nextProps.field];
			  }
		  }
		  
		  
		  if (nextProps.obj.state != null){
			  if (nextProps.obj.state[nextProps.field] != this.state.value){
				  nextState.value = nextProps.obj.state[nextProps.field];
			  }
		  }else{
			  if (nextProps.obj[nextProps.field] != this.state.value){
				  nextState.value = nextProps.obj[nextProps.field];
			  }
		  }
		  this.setState(nextState);
	}
    	
	selectResult(field, value){		
		if(this.props.field != undefined ){   	
	    	if (value == "..."){
	    		alert("maybe let us add!");	    		
	    	}
	    	if (this.props.update != null){
	    		this.setState({doneSearching: true, searching: false, value: this.getValue(value)}, ()=>{
	    			this.props.update(this.props.obj, this.props.field, this.getValue(value));	
	    		});
    		}else{
	    		console.log("InputSelectFilter.js: I didn't know how to handle this change");
	    	}
	    }
	}
	
	closeSearch(){
		if(this.props.update != null){
			this.setState({value: undefined, searching: false}, ()=>{
				this.props.update(this.props.obj, this.props.field, this.getValue(undefined));	
			});
		} else {
			//console.log("I don't know how to close myself");
		}
	}
    	  
	render(){		
		const key = "me";

		let searchOptions =  _.map( this.state.results, (option) =>{
				return <li key={key + "-" + this.keyFormatter(option)} onClick={()=>this.selectResult(key, option)} >{this.labelFormatter(option)}</li>;
			});
			
		let searchResults = "";
		
		if(this.state.searching && this.state.results && this.state.results.length > 0 ){
			searchResults = <ul>
				{searchOptions}
			</ul>;
		}

		const valueToShow = this.state.searching ? this.state.textValue : this.getLabelForValue(this.state.value);
		
		const label = this.props.name != undefined? <label>{this.props.name} {this.renderLabelTags()}</label> : "";
		
		return (<div className={"inputselectfilter_body field " +  (this.props.className || "col-xs-12 col-md-12") }>
			{label}
			
			<div className="searchBox">
				<div className="searchWrapper">
					<input
				        type="text"
				        className={"form-control" || this.props.className}
				        placeholder={this.props.placeHolder}
				        value={valueToShow}
						disabled={this.props.disabled? "disabled" : ""}
				        onChange={(e)=>this.handleChange(e)} />
					
					{ this.props.disabled? "" : <div className="glyphicon glyphicon-remove" onClick={()=>this.closeSearch()} /> }
				</div>
				
				<div className="searchResults">
				{searchResults}
				</div>
			</div>
          </div>);
		
	}
}

