import React, { useEffect, useState } from "react";
import { useData} from "@opidcore/hooks/WTF";
import { Button, Loading, Pagination, Searchable, Tabular, TabularColumn, TabularDataSet, Bound, InputSelect } from "@opidcore/components";
import PeriodSelect from "../../components/PeriodSelect";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";

export default function EmailQueue(props) {
  const [data, dataSet] = useData("outboundmessagequeue", { method: "getData", paginate: 10, orderBy: "dateCreated asc" });
  const [allMessages, setAllMessages] = useState(false);
  const [searchBound, setSearchBound] = useState({
    group: "ar@waste.solutions",
    periodFrom: moment().set("year", moment().get("year")).set("month", moment().get("month")).format("YYYY-MM"),
    periodTo: moment().set("year", moment().get("year")).set("month", moment().get("month")).format("YYYY-MM"),
  });
  /*const [data2, dataSet2] = useData("message", {
    method: "getData",
    startDate: searchBound.periodFrom,
    endDate: searchBound.periodTo,
    group: searchBound.group,
  });*/
  const [messageData, setMessageData] = useState([]);

  useEffect(() => {
    APP.central.Message.getData(searchBound.periodFrom, searchBound.periodTo, searchBound.group).then((r) => {
      setMessageData(r.result);
    });
  }, []);

  const refreshData = () => {
    APP.central.Message.getData(searchBound.periodFrom, searchBound.periodTo, searchBound.group).then((r) => {
      setMessageData(r.result);
    });
  };

  const retrySend = (msg) => {
    APP.central.OutboundMessageQueue.retrySend(msg.id).then((r) => {
      dataSet.fetch();
      refreshData();
    });
  };

  if (dataSet.loading)
    return (
      <div>
        <Loading />
      </div>
    );
  else if (!allMessages)
    return (
      <div>
        <Button onClick={() => setAllMessages(true)}>Show Messages</Button>
        <Searchable ds={dataSet} />
        <br />
        <Pagination dataSet={dataSet} />
        <TabularDataSet dataSet={dataSet} defaultSort="id" defaultSortOrder="desc">
          <TabularColumn title="ID" data={(row) => row.id} />
          <TabularColumn title="Created" data={(row) => row.dateCreated} />
          <TabularColumn title="Sent" data={(row) => row.dateSent} />
          <TabularColumn title="Status" data={(row) => row.status} />
          <TabularColumn title="Type" data={(row) => row.relatedTable} />
          <TabularColumn
            title="Item"
            data={(row) => {
              switch (row.relatedTable) {
                case "ARInvoice":
                  return <Link to={"/ui/arinvoice/" + row.relatedId}>{row.relatedId}</Link>;
                case "APInvoice":
                  return <Link to={"/ui/apinvoice/" + row.relatedId}>{row.relatedId}</Link>;
                case "SiteSustainabilityStatus":
                  return <Link to={"/ui/sustainabilityedit/" + row.related.site.customer + "/" + row.related.reportPeriod}>{row.relatedId}</Link>;
              }
            }}
          />
          <TabularColumn title="Sender" data={(row) => (row.sender == null ? null : row.sender.fullName)} />
          <TabularColumn title="Retry" data={(row) => (row.status == "failed" ? <Button onClick={() => retrySend(row)}>Retry</Button> : null)} />
        </TabularDataSet>
      </div>
    );
  else if (allMessages)
    return (
      <div>
        <Button onClick={() => setAllMessages(false)}>Show Pending</Button>
        <Bound to={searchBound} onChange={() => refreshData()}>
          <PeriodSelect field="periodFrom" name="Period From" />
          <PeriodSelect field="periodTo" name="Period To" />
          <InputSelect
            field="group"
            name="Email Group"
            options={["ap@waste.solutions", "ar@waste.solutions", "sustainability@waste.solutions"]}
            default="ar@waste.solutions"
          />
        </Bound>
        <Tabular data={messageData}>
          <TabularColumn title="ID" data={(row) => row.id} />
          <TabularColumn title="Composed" data={(row) => row.composedAt} />
          <TabularColumn title="Sent" data={(row) => row.sentAt} />
          <TabularColumn title="Retry" data={(row) => (row.status == "failed" ? <Button onClick={() => retrySend(row)}>Retry</Button> : null)} />
        </Tabular>
      </div>
    );
  else return <div></div>;
}
