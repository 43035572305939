import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useData, useNavBar } from "@opidcore/hooks/WTF";
import { RoutedDefaults } from "@opidcore/components/OpidApplication";
import {
  LookupInputSelect,
  InputSelect,
  TabularDataSet,
  TabularColumn,
  Button,
  Pagination,
  Searchable,
  ActionBar,
  BoundCommit,
  Bound,
  InputText,
  UnlockToEdit,  
  Icon,
} from "@opidcore/components";
import * as _ from "lodash";

export default function VendorActivities(props) {
  useNavBar("Vendor Activities", []);

  const [data, dataSet] = useData("vendor_activity");
  const filters = useRef({ vendor: -1 });
  const [vendName, setVendName] = useState("");
  const [filterBoundMagic, setFilterBoundMagic] = useState(null);

  const editVendorActivity = (vendorActivity) => {
    APP.instance.createModal(<EditVendorActivity vendorActivity={vendorActivity} />);
  };

  const newVendorActivity = () => {
    APP.instance.createModal(<EditVendorActivity vendorActivity={{ __new: true, __dataUID: dataSet.dataUID }} />, { vendor: filters.current.vendor });
  };

  const doFilter = (bound, boundMagic) => {
    APP.central.Vendor.fetch(boundMagic.to.vendor).then((r) => {  

      setVendName(r.result.name);
    });

    dataSet.reset();
  };

  const stealBound = (boundMagic,bound)=>{
    setFilterBoundMagic(boundMagic);
  }

  if ( _.size(_.uniq(_.map(dataSet.filter(), (r)=>r.vendor && r.vendor.id))) > 1 ){
    if (filterBoundMagic){
      dataSet.filter((r) => r.vendor && r.vendor.id == filterBoundMagic.to.vendor, true);
    }
  }

  return (
    <div className="full-size">
      <ActionBar title="Actions">
        <Searchable ds={dataSet} />
        <a className="btn btn-new" onClick={() => newVendorActivity()}>
          New Vendor Activity
        </a>
      </ActionBar>

      <div>
        <Pagination dataSet={dataSet} />
        <Button onClick={() => dataSet.download("xlsx")}>excel</Button>
        <Bound to={filters.current} onChange={doFilter} init={stealBound}>
          <LookupInputSelect field="vendor" what="vendor" name="Vendor" showEmpty={true} />
        </Bound>
      </div>

      {filters.current.vendor == -1 ? (
        "Select a Vendor"
      ) : (
        <TabularDataSet dataSet={dataSet} defaultSort="id" defaultSortOrder="asc" noData={"No activities have been setup for this vendor."}>
          <TabularColumn data={(row) => row.id} title="#" />
          <TabularColumn
            data={(row) => (
              <Link className="plain_link" to={"/ui/vendors/" + (row.vendor ? "edit/" + row.vendor.id : "list")}>
                {row.vendor ? vendName : "?"}
              </Link>
            )}
            title="Vendor"
          />
          <TabularColumn data={(row) => row.activity} title="Activity" />
          <TabularColumn data={(row) => row.defaultUnits} title="Units" />
          <TabularColumn data={(row) => row.defaultActivity} title="Default Activity" />
          <TabularColumn data={(row) => row.materialAutomation} title="Material" />
          <TabularColumn data={(row) => row.reviewRequired} title="Review" />
          <TabularColumn data={(row) => row.notes} title="Notes" />
          <TabularColumn
            data={(row) => {
              return <Icon icon="edit" onClick={() => editVendorActivity(row)} size="2x" />;
            }}
          />
        </TabularDataSet>
      )}
    </div>
  );
}

export function EditVendorActivity(props) {
  const routedDefaults = useContext(RoutedDefaults);
  const vendorActivityRef = useRef({ ...props.vendorActivity });

  useEffect(() => {
    if (routedDefaults != undefined && routedDefaults.vendor != undefined) {
      vendorActivityRef.current.vendor = routedDefaults.vendor;
    }
  }, []);

  return (
    <div>
      <BoundCommit
        initialValue={vendorActivityRef.current}
        forceSaveProps={["vendorActivity"]}
        commit={APP.central.VendorActivity.saveChanges}
        className="basicForm"
      >
        <UnlockToEdit>
          <InputText field="id" name="#" />
        </UnlockToEdit>
        <LookupInputSelect field="vendor" what="vendor" name="Vendor" showEmpty={true} />
        <InputText field="activity" name="Vendor Activity" />
        {/*<LookupInputSelect field="defaultUnits" what="lookup:ActivityUnits" name="Units" showEmpty={true} />*/}
        <LookupInputSelect what="lookup:unitPricePer" field="defaultUnits" name="Units" showEmpty={true} />
        {/* <LookupInputSelect field="defaultActivity" what="lookup:activity" name="WSC Activity" showEmpty={true} /> */}
        {/*<InputText field="materialAutomation" name="Material" />*/}
        <InputSelect field="reviewRequired" name="Review Required?" options={{ Yes: "Yes", No: "No" }} />
        <LookupInputSelect field="defaultTax" what="taxes" name="Default Tax" showEmpty={true} />
        <InputSelect field="importAction" name="Import Action" options={{  "": "Default", "service": "Assign Service #", "assume_service": "Guess Service" }} />
        <InputText field="servicePrefix" name="Service # Prefix" />
        <InputText field="notes" name="Notes" />
      </BoundCommit>
    </div>
  );
}
