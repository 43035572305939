import React, { useState, useRef, useEffect } from "react";
import { useData, useNavBar } from "@opidcore/hooks/WTF";
import {
  Icon,
  TabularDataSet,
  TabularColumn,
  Button,
  Pagination,
  Searchable,
  ActionBar,
  InputText,
  useMagic,
  Bound,
  InputSelect,
  Loading,
  LinkButton,
  FlexRow,
  Tabbed,
  InputAutoComplete
} from "@opidcore/components";

import * as _ from "lodash";
// import { debounce } from "lodash";
import ChangeLogs from '../ChangeLog/View';

export default function WorkflowList() {
  useNavBar("Edit Workflows", []);

  const [data, dataSet] = useData("workflowautomation", { paginate: 100 });

 

  const newWorkflow = () => {
    APP.central.WorkflowTesting.create().then( (r)=>{
      APP.redirect("/ui/admin/workflow/" + r.result.id)
    });
  };

  const executeWorkflow = (workflow) => {
    APP.central.WorkflowTesting.testExecuteWorkflow(workflow.id, workflow.steps.length).then((r) => {
      //debugger;
      alert(r.result.crappyLog ? _.join(r.result.crappyLog, ", ") : null);
    });
  };

  const deleteWorkflow = (workflow) => {
    //TODO usues WorkflowTesting
    if (confirm("are you sure you want to delete workflow #" + workflow.id + "-" + workflow.name + "?")) {
      APP.central.WorkflowTesting.deleteAutomation(workflow).then((r) => {       
        dataSet.fetch();
      });
    }
  };

  return (
    <div className="full-size">
      <ActionBar title="Actions">
        <Searchable ds={dataSet} />
        <a className="btn btn-new" onClick={() => newWorkflow()}>
          New Workflow
        </a>
      </ActionBar>

      <div>
        <Pagination dataSet={dataSet} />
      </div>

      {dataSet.loading ? "loading" : ""}

      <TabularDataSet dataSet={dataSet} defaultSort="id" defaultSortOrder="asc">
        <TabularColumn data={(row) => row.id} title="#" />
        <TabularColumn data={(row) => row.name} title="Name" />
        <TabularColumn data={(row) => row.type} title="Type" />
        {/*<TabularColumn data={(row) => row.steps} title="Steps" />*/}
        {/*<TabularColumn data={(row) => row.type} title="Type" />*/}
        <TabularColumn
          data={(row) => {
            return <LinkButton target={"/ui/admin/workflow/" + row.id}><Icon icon="edit" size="2x" /></LinkButton>;
          }}
          title="Edit"
        />
        <TabularColumn
          data={(row) => {
            return <Icon icon="trash-alt" onClick={() => deleteWorkflow(row)} size="2x" />;
          }}
          title="Delete"
        />
        <TabularColumn
          data={(row) => {
            return <Icon icon="play" onClick={() => executeWorkflow(row)} size="2x" />;
          }}
          title="Execute"
        />
      </TabularDataSet>
    </div>
  );
}

// --------------------------------------------------------------------------- EditWorkflow ---------------------------------------------------------------------------

export function WorkflowEdit(props) {
  const workflowId = props.match ? props.match.params.id : 0;
  const workflowRef = useRef({ ...props.workflow });
  
  const [workflowStepOptions, setWorkflowStepOptions] = useState({});
  const [workflowResult, setWorkflowResult] = useState(null);
  const [executing, setExecuting] = useState(false);
  const [testResult, setTestResult] = useState(null);

  const bound = useMagic(workflowRef.current);
  const inspectorBound = useMagic({});

  const updateBound = (newWorkflow) => {
    bound.replaceTo(newWorkflow);
  };

  const updateWorkflowRef = () => {
    workflowRef.current = bound.to;
  };

  useEffect(() => {
    updateWorkflowRef();
  }, [bound]);

  useEffect(() => {
    //TODO usues WorkflowTesting
    APP.central.WorkflowTesting.fetch(workflowId).then((r)=>{
      updateBound(r.result);
    });

    APP.central.WorkflowTesting.getOptions().then((r) => {
      setWorkflowStepOptions(r.result);
    });
  }, []);

  const save = () => {
    //TODO usues WorkflowTesting
    updateWorkflowRef();
    APP.central.WorkflowTesting.saveAutomation(bound.to).then((r) => {     
      workflowRef.current = r.result;
      updateBound(r.result);
    });
  };

  const executeWorkflow = () => {
   
    if (bound.to.steps.length > 0) {
      setExecuting(true);
      updateWorkflowRef();
      APP.central.WorkflowTesting.testExecuteWorkflow(workflowRef.current.id, workflowRef.current.steps.length, inspectorBound.to).then((r) => {
        setExecuting(false);   
        setWorkflowResult(r.result);
      });
    }
  };

  const reallyApply = (doAfter) => {
      return APP.central.WorkflowTesting.saveAutomation(bound.to).then((r) => {   
        bound.replaceTo(r.result);
        if(doAfter) {
          doAfter();
        }
      });
  }

  return (
    <FlexRow>
      <ChangeLogs model={bound}/>

      <div>
        <h3>Workflow {bound.name}</h3>
        <Bound to={bound}>
          <InputText field="name" name="Name" />
          <InputText field="type" name="Type" />
        </Bound>
        <WorkflowSteps level={0} bound={bound} workflowStepOptions={workflowStepOptions} apply={reallyApply} workflowResult={workflowResult} setTestResult={setTestResult}/>
        <Button onClick={save}>Save</Button>
        <Button onClick={executeWorkflow}>Execute</Button>
      </div>

      <Bound to={inspectorBound}>
        <div style={{minWidth: "30em"}}>
          <PDFOptions/>
          {workflowResult ? <WorkflowInspector workflowResult={workflowResult} workflow={bound} inspectorBound={inspectorBound} testResult={testResult} setTestResult={setTestResult}/> : null }
        </div>
      </Bound>
    </FlexRow>
  );
}


export function WorkflowInspector({workflowResult, workflow, inspectorBound, testResult, setTestResult}){
  const [details, setDetails] = useState("");
  const [matches, setMatches] = useState([]);
  const [regex, setRegex] = useState("");
  const [matchAll, setMatchAll] = useState([])
  const [testResultComponents, setTestResultComponents] = useState(null)
  const [matchTotal, setMatchTotal] = useState(0)
  const [regexBound, setRegexBound] = useState({})
  const [data, dataDs] = useData(workflowResult.uid, APP.central.WorkflowTesting.getData, {uid: workflowResult.uid} );

  const dumpDetails = ()=>{
    APP.central.WorkflowTesting.getDetails(workflowResult.uid, "raw").then((r)=>{
      setDetails(r.result);
    });
  }

  const dumpMatches = () => {
    APP.central.WorkflowTesting.getMatches(workflowResult.uid).then((r) => {
      var tempMatches = []
      for (const [key, value] of Object.entries(r.result.pairedMatches)) {
        tempMatches = [...tempMatches, <MatchComponent pattern={key} matches={value} />]
        //setMatches([...matches, <MatchComponent pattern={key} matches={value} />])
      }
      setMatches(tempMatches)
    })
  }

  const MatchComponent = (props) => {
    return <div>
      <table>
        <tbody>
          <tr>
            <th>RegEx</th>
            <th>Matches</th>
          </tr>
          {props.matches.map((match) => <tr>
            <td>{props.pattern}</td>
            <td>{match.join(" | ")}</td>
          </tr>
          )}
        </tbody>
      </table>
    </div>
  }
  
  const dumpRegex = () => {
    APP.central.WorkflowTesting.suggestRegex(regexBound).then((r) => {
      setRegex(r.result)
    })
  }

  const testRegex = () => {  
    APP.central.WorkflowTesting.testRegex(regexBound, inspectorBound.magicalGet("pdf.id")).then((r) => {
      setTestResult(r.result)
    })
  }

  useEffect(() => {
    if (testResult != null) {
      var matchList = []
      var matchTotalTemp = 0
      testResult.forEach((row) => {
        matchList.push(<MatchRow line={row.line} match={row.match} matchedSection={row.matchedSection} />)
        if (row.match) matchTotalTemp += 1
      })
      setTestResultComponents(matchList)
      setMatchTotal(matchTotalTemp)
    }
  }, [testResult])

  const MatchRow = ({line, match, matchedSection}) => {
    return <div style={{color: (match ? "green" : "red")}}>
      {line} {match && Array.isArray(matchedSection) ? "**MATCH FOUND: " + matchedSection.join(", ") : null}
    </div>
  }

  useEffect(()=>{
    const a = dataDs;
    const b = data;
    
    if (dataDs.queryArgs.uid != workflowResult.uid){
      dataDs.setQueryArgs({uid: workflowResult.uid})
      dataDs.fetch();
    }

  }, [workflowResult.uid]);


  const columns = dataDs.columns ? dataDs.columns.map( (col)=><TabularColumn key={col.key} data={(row)=>row[col.key]} title={col.key}/> ) : [];
  
  return <div>
    <h2>Inspector</h2>
    <Tabbed inline={true}>
        <div title="Data">
          <TabularDataSet dataSet={dataDs}>
            {columns}
          </TabularDataSet>
        </div>
        <div title="Raw Details">
          <Button onClick={dumpDetails}>Dump Raw</Button>
          <pre>
            {details}
          </pre>
        </div>
        <div title="Matches">
          <Button onClick={dumpMatches}>Dump Matches</Button>
          <pre>
            {matches}
          </pre>
        </div>
        <div title="RegEx">
          <Button onClick={dumpRegex}>Suggest RegEx</Button>
          <Bound to={regexBound}>
            <InputText field="text" />
          </Bound>
          <pre>
            {regex}
          </pre>
          <Button onClick={testRegex}>Test RegEx</Button>
          <Bound to={regexBound}>
            <InputText field="regex" />
          </Bound>
          <pre>
            {testResult != null ? matchTotal + " matches found in " + testResult.length + " total lines." : null}
            {testResultComponents}
          </pre>
        </div>
    </Tabbed>
  </div>
}

function PDFOptions({}){
  const [recentPDFS, setRecentPDFS] = useState([]);

  useEffect( ()=>{
    APP.central.Snowman.recentFiles().then((r)=>{
      setRecentPDFS(r.result);
    });
  }, []);  


  return <>
    {/*<InputSelect field="pdf" label="Test File" optionsCollection={recentPDFS} fieldKey="storage_identifier" labelKey="filename"/>*/}
    <InputAutoComplete central={APP.central.File.autocomplete} field="pdf">
      {(pdf) => <div className="fileAutoComplete">{pdf.displayValue}</div>}
    </InputAutoComplete>
  </>

}
/**   <div className="workflowLog">
        {executing ? <Loading /> : null}
        {workflowResult && workflowResult.crappyLog
          ? _.map(workflowResult.crappyLog, (log, i) => {
              return <div key={i + log}>{log}</div>;
            })
          : null}
      </div> */

// --------------------------------------------------------------------------- WorkflowSteps ---------------------------------------------------------------------------

export function WorkflowSteps({ bound, workflowStepOptions, level, apply, parentId, workflowResult, setTestResult }) {
  const [steps, setSteps] = useState([]);
  const [stepCount, setStepCount] = useState(0);
  
  //damn you ticky!
  const [ticky, setTicky] = useState(0);

  useEffect(() => {
    bound.magicalState("steps", (s) => {
      setStepCount(s != null ? s.length : 0);
      setSteps(s);
      setTicky(Math.random());
    });
  }, [bound]);

  const stepViews = _.map(steps, (s, idx) => {
    return (
      <WorkflowStep level={level} key={100 + idx + "-" + s.__type} idx={idx} workflowStepOptions={workflowStepOptions} step={bound.getBound("steps", idx)} apply={apply} parentId={parentId} workflowResult={workflowResult} setTestResult={setTestResult}/>
    );
  });

  const add = () => {
    bound.magicalAppend("steps", {});
  };

  return (
    <div style={{ paddingLeft: level * 5 + "em" }}>
      <strong style={{display: "none"}}>{ticky}</strong>
      <ol>{stepViews}</ol>
      <Button onClick={add}>Add Step</Button>
    </div>
  );
}

// --------------------------------------------------------------------------- WorkflowStep ---------------------------------------------------------------------------

export function WorkflowStep({ workflowStepOptions, step, level, idx, apply, parentId, workflowResult, setTestResult }) {
  const [editing, setEditing] = useState(false);
  const [fields, setFields] = useState([]);
  const [showSteps, setShowSteps] = useState(false);
  const magic = useMagic(step);
  const [executing, setExecuting] = useState(false);
  const workflowResultRef = useRef(null);
  const [stepType, setStepType] = useState(null);
  //const [tableTest, setTableTest] = useState(null);

  useEffect(() => {
    //fetchWorkflowResults();
    updateFields();
  }, [magic, workflowStepOptions]);

  const fetchWorkflowResults = () => {
    setExecuting(true);
    if (magic.parentMagic.to.id && magic.parentMagic.to.steps.length) {
      APP.central.WorkflowTesting.testExecuteWorkflow(magic.parentMagic.to.id, magic.parentMagic.to.steps.length).then((r) => {
        workflowResultRef.current = r.result;
        updateFields();     
        setExecuting(false);
      });
    } else {
      updateFields();
      setExecuting(false);
    }
  };

  const doSetFields = (fields, typeSelected) => {
    setFields(
      _.map(fields, (f, i) => {
        if (!_.startsWith(f.name, "_") && !_.startsWith(f.name, "stepType")) {
          if (!f.options) {
            return <InputText key={i + f.name} field={f.name} name={f.name} />;
          }
          let opts = [];
          _.forEach(f.options, (op, index) => {
            if (!_.startsWith(op, "_")) {
              opts[index] = { optionName: op };
            }
          });
          opts = _.filter(opts, _.size);
          //<InputSelect field="column" optionsCollection={workflowResultRef.current.state.data.columns} labelKey="label" fieldKey="key" name="Column" />
          return <InputSelect key={i + f.name} field={f.name} optionsCollection={opts} labelKey="optionName" fieldKey="optionName" name={f.name} />;
        }
      })
    );
    if (typeSelected.hasSteps) {
      setShowSteps(true);
    }
  };

  const updateFields = () => {
    if (workflowStepOptions.stepTypes && workflowStepOptions.stepTypes.length > 0) {
      magic.magicalState("__type", (a, b, c) => {
        const typeSelected = _.find(workflowStepOptions.stepTypes, { name: a });
        setStepType(typeSelected)
        if (typeSelected) {
          if (!workflowResultRef.current) {
            doSetFields(typeSelected.fields, typeSelected);
          } else {
            doSetFields(workflowResultRef.current.steps[idx]._stepType.fields, typeSelected);
          }
        }
      });
    }
  };

  const handleDelete = () => {
    magic.getParentMagic().magicalSplice("steps", idx, true);
  };

  const handleMoveUp = () => {
    const currentSteps = magic.getParentMagic().magicalGet("steps");
    if (idx > 0) {
      const other = currentSteps[idx - 1];
      currentSteps[idx - 1] = currentSteps[idx];
      currentSteps[idx] = other;
    }
    magic.getParentMagic().clearGotBounds();
    magic.getParentMagic().magicalSet("steps", currentSteps);
  };

  const handleMoveDown = () => {
    const currentSteps = magic.getParentMagic().magicalGet("steps");
    if (idx < currentSteps.length - 1) {
      const other = currentSteps[idx + 1];
      currentSteps[idx + 1] = currentSteps[idx];
      currentSteps[idx] = other;
    }
    magic.getParentMagic().clearGotBounds();
    magic.getParentMagic().magicalSet("steps", currentSteps);
  };

  const handleEdit = () => {
    setEditing(true)
    /*
    setExecuting(true);
    if (magic.parentMagic.to.id && magic.parentMagic.to.steps.length) {
      APP.central.WorkflowTesting.testExecuteWorkflow(magic.parentMagic.to.id, idx + 1).then((r) => {
        setExecuting(false);
        workflowResultRef.current = r.result;
        updateFields();
        console.log("Executed:", r.result);
        setEditing(true);
      });
    } else {
      setExecuting(false);
      setEditing(true);
    }
    */
  };

  const testRegex = () => {
    if (workflowResult == null) {
      alert("Execute workflow before testing.")
    } else {
      APP.central.WorkflowTesting.testRegexTable(workflowResult.uid, idx, parentId).then((r) => { // This will break with nested tables?
        setTestResult(r.result)
      })
    }
  }

  let editor = null;
  if (editing) {
    editor = (
      <Bound to={step}>
        <InputSelect field="__type" optionsCollection={workflowStepOptions.stepTypes} fieldKey="name" name="Type" />
        {fields}
        <Button onClick={() => {apply(setEditing(false))}}>Apply</Button>
        {(stepType != null) && (stepType.testRegex) ? <Button onClick={testRegex}>Test RegEx</Button> : null}
      </Bound>
    );
  }

  return (
    <li>
      <h4>Type {step.__type}</h4>
      {executing ? <Loading /> : null}
      {editing ? (
        editor
      ) : (
        <>
          {" "}
          <Button onClick={() => handleEdit()}>edit</Button>{" "}
        </>
      )}
      {showSteps ? <WorkflowSteps level={level + 1} bound={step._ourMagic} workflowStepOptions={workflowStepOptions} apply={apply} parentId={idx} workflowResult={workflowResult} setTestResult={setTestResult}/> : null}

      <>
        <Button onClick={handleDelete}>Delete</Button>
        <Button onClick={handleMoveUp}>Up</Button>
        <Button onClick={handleMoveDown}>Down</Button>
      </>
    </li>
  );
}
