import React, { useState } from "react";
import { AccessControl, Button, Icon, Tabular, TabularColumn } from "@opidcore/components";
import ActionBar from "@opidcore/components/ActionBar";
import { useNavBar } from "@opidcore/hooks/WTF";
import moment from "moment";

import { CreateNewVendorContract } from "../../components/ContractBrowser";
import FullTextSearchable from "../../components/FullTextSearchable";

export default function VendorContractList(props) {
  useNavBar("Active Vendor Contracts", []);
  const [vendorContracts, setVendorContracts] = useState([]);
  const [fetchVendorContracts, setFetchVendorContracts] = useState(false);

  const dateFormat = "YYYY-MM-DD";

  //TODO fix these
  const excelExport = () => {
    APP.central.VendorContract.getDefaultExportLayout().then((r) => {
      //APP.instance.createModal(<ExcelExportModal defaultLayout={r.result} dataSet={dataSet} model={"VendorContract"} />, { modal_name: "Excel Export" });
    });
  };

  const newVendorContract = () => {
    APP.instance.createModal(<CreateNewVendorContract serviceId={0}/>);
  };

  return (
    <div className="full-size">
      <ActionBar title="Actions">
        <div>
          <FullTextSearchable
            model={"VendorContract"}
            method={"reasonableList"}
            setData={setVendorContracts}
            doFetchData={fetchVendorContracts}
            setDoFetchData={setFetchVendorContracts}
            maxRows={300}
            filterOptions={{
              columns: [
                { column: "vendor_name" },
                { column: "expiry_status", lookup: "vendorContractExpiryStatus" },
                { column: "status", lookup: "vendorContractStatus", default: "active" },
              ],
            }}
          />
        </div>

        <AccessControl group="Management">
          <Icon icon="file-excel" size="2x" title="Open export modal" onClick={() => excelExport()} />
        </AccessControl>
        <Button onClick={() => newVendorContract()}><Icon color="white" icon="plus" /> {"\u00a0"}New Vendor Contract</Button>
      </ActionBar>  


      <Tabular
        data={vendorContracts}
        onClick={(row) => {
          APP.redirect("/ui/vendorcontract/edit/" + row.friendly_id);
        }}
        pageSize={100}
        paginationLocation={"both"}
      >
        <TabularColumn data={(row) => row.friendly_id} title="#" />
        <TabularColumn data={(row) => (row.vendor_name ? row.vendor_name : "-")} title="Vendor" />
        <TabularColumn data={(row) => row.clients} title="Clients" />
        <TabularColumn data={(row) => <span title={row.sites}>{row.site_count}</span>} title="Sites" />

        <TabularColumn data={(row) => (row.effective_from ? moment(row.effective_from).format(dateFormat) : "-")} title="Effective From" />
        <TabularColumn data={(row) => (row.effective_to ? moment(row.effective_to).format(dateFormat) : "-")} title="Effective To" />
        <TabularColumn data={(row) => row.contract_term} title="Contract Term" sortCol="contract_term" />

        <TabularColumn data={(row) => (row.internal_notes ? row.internal_notes : "-")} title="Internal Notes" />

        <TabularColumn data={(row) => row.expiry_status} title="Expiry Status" sortCol="expiry_status" />
        <TabularColumn data={(row) => row.status} title="Historical Status" sortCol="status" />
      </Tabular>
    </div>
  );
}
