import React, { useState, useEffect, useRef, useContext } from 'react';
import BoundComponent from './BoundComponent';
import { DataContext } from './Bound';

function handleKeyUp(e, canvas, state){
	if (e.key == "Delete"){
		canvas.remove( canvas.getActiveObject() );
	}
}

function handlePaste(e, canvas, state, updateState){
 
		if (state.isFocused == false){
			return;
		}
		
	  _.each(e.clipboardData.items, function(item){
		  if (item.kind == "file"){
			  var file = item.getAsFile();
		 
			  var reader  = new FileReader();
		  
			  //let canvas = that._sketch._fc;
			  
			  reader.onloadend = function () {
				    var img = new Image();
				    img.src = reader.result;
				    img.onload = function(a){ 				    	
					    var fImg = new fabric.Image(img, {});
				    	if (img.width > state.width || img.width > 0 && state.width == null){
				    		updateState({width: img.width, height: img.height });
				    	}
				    	canvas.add(fImg);
				    }
			  }
			  
			  reader.readAsDataURL(file);
		  }

	  });
 }

export default function DiagramEditor(props){
	const bound = useContext(DataContext);
	
	const ourData = JSON.parse( props.data || "{}" );
	
	const diagramState = useRef({isFocused: false, height: ourData ? ourData.height: 200, width: ourData ? ourData.width: 200});
	
	const updateState = (hash)=>{
		diagramState.current = {...diagramState.current, ...hash};
		
		if (fabricCanvas.current && diagramState.current.height != fabricCanvas.current.height && diagramState.current.width > 0){
			fabricCanvas.current.setWidth(diagramState.current.width);
			fabricCanvas.current.setHeight(diagramState.current.height);
			fabricCanvas.current.calcOffset();			
		}
	}
	
	const fabricSrc = 'https://cdnjs.cloudflare.com/ajax/libs/fabric.js/4.1.0/fabric.js';
	const [fabric, setFabric] = useState(window.fabric);
	const fabricCanvas = useRef();
	const canvasRef = useRef();
	
	const destroy = ()=>{
		console.log("goodbye!");
	};
	
	const refreshCanvas = ()=>{
		setTimeout(()=>{
			fabricCanvas.current.renderAll();
		}, 100);
	}
	
	const initialize = ()=>{
		window.document.addEventListener('paste', (e)=>handlePaste(e, fabricCanvas.current, diagramState.current, updateState));
		

		window.document.addEventListener('keyup', (e)=>handleKeyUp(e, fabricCanvas.current, diagramState.current, updateState));

		
		var wtf = bound.magicalGet(props.field);
		if (props.data){
			const ourData = JSON.parse( props.data );
			if (ourData.width){
				updateState({width: ourData.width, height: ourData.height});
			}		
					
			fabricCanvas.current.loadFromJSON( props.data );
			refreshCanvas();
		}
		
	}
	
	useEffect(()=>{	
		
		if (fabric == null){
	        const script = document.createElement("script");
	        script.src = fabricSrc;
	        script.async = true;
	        script.addEventListener("load", ()=>{
	        	setFabric(window.fabric);
	        });
	        
	        // Add script to document body
	        document.body.appendChild(script);
		}
		
		return ()=>{ destroy(); }
	}, []);

	useEffect( ()=>{
		if (fabric != null){
			const canvas = new fabric.Canvas(canvasRef.current);
			fabricCanvas.current = canvas;
			
			if (diagramState.current.width){
				fabricCanvas.current.setWidth(diagramState.current.width);
				fabricCanvas.current.setHeight(diagramState.current.height);
				fabricCanvas.current.calcOffset();			

			}
			
			
			initialize();
		}
	}, [fabric]);
	
	useEffect( ()=>{
		if (props.data != null){
			if (fabricCanvas.current != null){
				const ourData = JSON.parse( props.data );
				if (ourData.width){
					updateState({width: ourData.width, height: ourData.height});
				}
				//console.log("loaded in props effect ", fabricCanvas.current.width );			
				fabricCanvas.current.loadFromJSON( props.data );
				refreshCanvas();
				
			}
		}
	}, [props.data]);
	
	const save = (a)=>{ 	
		var me = fabricCanvas.current.toJSON(['width','height']);
		var me2 = fabricCanvas.current.toDataURL();		
		bound.magicalSet("jsonSource", me);
		bound.magicalSet("base64", me2);
		props.save(bound.to);
	}
	
	return <div onClick={()=>updateState({isFocused: true})} onMouseOut={()=>updateState({isFocused: false})}>
		<button onClick={save}>Save</button>
		<canvas ref={canvasRef} width={diagramState.current.width} height={diagramState.current.height}/>
	</div>;
}
/**
import {SketchField, Tools} from 'react-sketch';

import TextTool from './TextTool.js';

import ArrowTool from './ArrowTool.js';
import ComponentTool from './ComponentTool.js';

import ReactDOM from 'react-dom';
import _ from 'lodash';

class Components extends Component {
	render(){
		
		if (1 == 1){
			return null;
		}
		
		var style = {position: "relative", border: "1px solid black", height: this.props.height, width: this.props.width};

		return <div>
		
		<h2>here are things we should add:</h2>
		<div style={style}>
			{this.props.children}
		</div>
		</div>
		
	}
}

export default class DiagramEditor extends BoundComponent {
	
    
	  static propTypes = {
	  };
	  
	  constructor(props, context) {
		  super(props, context);
		  this.state = {
				height: this.props.height,
				width: this.props.width,
				tool: Tools.Select,
				lineColor: "#000000",
				fillColor: "transparent",
				shouldFill: false,
				lineWidth: 3,
				backgroundColor: "#FFFFFF",
				changed: false,
				stretched: true,
		        stretchedX: false,
		        stretchedY: false,
		        originX: 'left',
		        originY: 'top',
		        activeItem: "",
		        fontSize: "24"
			};
 
	  }
	  
	  removeObject(){
		  _.each(this._sketch._fc.getActiveObjects(), (a)=>{
				  this._sketch._fc.remove( a )
		  	}
		  );
          this._sketch._fc.renderAll();
	  }
	  
	  moveToBack(){
          if(this._sketch._fc.getActiveObject() != undefined){
              this._sketch._fc.sendToBack( this._sketch._fc.getActiveObject() );
          }
          
          if(this._sketch._fc.getActiveGroup() != undefined){
              this._sketch._fc.getActiveGroup().forEachObject(function(e){this._sketch._fc.sendToBack(e) })
          }
      }
	  
	  componentWillReceiveProps(nextProps){
	      // (nextProps.data != null && this.props.data == null){
	     if ( nextProps.data != null && this.state.changed == false ){ // } && nextProps.data.json){
	         var stuff = nextProps.data.json;
             if (typeof stuff != "object"){
                 stuff = JSON.parse(stuff);
             }
	          this._sketch.fromJSON( stuff );   
	      }
	  }
	
	  componentDidMount() {
	      console.log('did mount', this);
	      this._sketch._tools["select"].configureCanvas = function(){
	    	  let canvas = this._canvas;
	          canvas.isDrawingMode = false;
	          canvas.selection = true;
	          canvas.forEachObject((o) => {
	        	  if(o.reallyLocked == 0 || o.reallyLocked == undefined){
	        		  o.selectable = o.evented = true;
	        	  }
	          });
	      }
		  Tools.Text ="text";
		  this._sketch._tools[Tools.Text] = new TextTool(this, this._sketch._fc)
		  Tools.Arrow ="arrow";
		  this._sketch._tools[Tools.Arrow] = new ArrowTool(this, this._sketch._fc);
		  Tools.Component = "component";
		  this._sketch._tools[Tools.Component] = new ComponentTool(this, this._sketch._fc);

		  
		  
		  
		  if (this.props.data && this.props.data.json){
			  var json = this.props.data.json;
			  var that = this;
			  if (!json) return;
			    let canvas = this._sketch._fc;
			    setTimeout(() => {
			      canvas.loadFromJSON(json, () => {
			        canvas.renderAll();
			        
			        var maxWidth = 0;
			        var maxHeight = 0;
			        
			        _.each(canvas.getObjects(), o=>{
			        	if (maxWidth < o.aCoords.br.x){
			        		maxWidth = o.aCoords.br.x;
			        	}
			        	
			        	if (maxHeight < o.aCoords.br.y){
			        		maxHeight = o.aCoords.br.y;
			        	}
			        });
			        
			        var stateChange = {};
			        if (that.state.width < maxWidth){
			        	stateChange.width = maxWidth;
			        }
			        
			        if (that.state.height < maxHeight){
			        	stateChange.height = maxHeight;
			        }
			        
			        that.setState(stateChange);
			        
			      })
			    }, 100)
          }
		  this._sketch._backgroundColor("#FFFFFF");
		  
		  
		  var myNode = ReactDOM.findDOMNode(this);
		  myNode.tabIndex = 1000000;
		  myNode.addEventListener('keydown', (e)=>this.onKeyDown(e));
		  
		  this._sketch._fc.preserveObjectStacking = true;
		  this._sketch._fc.on("object:selected", (e)=>{
		    
		     if (e.target.stroke){
		         this.setState({lineColor: e.target.stroke, lineWidth: e.target.strokeWidth});
		     }
		     
		     if (e.target.fill){
	              this.setState({fillColor: e.target.fill, shouldFill: true});
		     }else{
	              this.setState({fillColor: "transparent", shouldFill: false});
		     }
		  });
		  
		  this._sketch._fc.on("object:modified", (e)=>{
			  	this.updateComponents();
		  });		  
		  
		  this.eventListener = document.addEventListener('paste', (e)=>this.handlePaste(e));
		}
	  
	  changeTool(e,type){
		this.setState({tool: type}); 
		this.setState({'changed': true});
	  }
	  textBoxAdded(){
		  this.setState({tool: Tools.Select}); 
	  }
	  
	  addArrow(){
		  this.setState({tool: Tools.Select}); 
	  }
	  
	  
	  updateComponents(){
		  window.wtf = this;
	  
		  var components = [];
		  
		  _.each(this._sketch._fc._objects, (o)=>{
			  if (o.type == "componentBlock"){
				  
				  var style = {};
				  style.position = "absolute";
				  style.top = o.top;
				  style.left = o.left;
				  components.push( React.cloneElement(o.componentContents, {key: o.__version, style: style}) );
			  }
		  });
		  
		  this.setState({components: components});
	  }

	  _clear() {
	        this._sketch.clear();
	        this._sketch.setBackgroundFromDataUrl('');
	        this.setState({
	            backgroundColor: "#FFFFFF"
	        })
	    }
	  
	  getAllSelected(){
		  
	      return this._sketch._fc.getActiveObjects();
	     
	  }
	  
	  changeLineColor(e){

          this.setState({
              lineColor: e.target.value
          });
          
         
	      _.map(this.getAllSelected(), function(selected){
	      		if (selected.type && selected.type == "textbox"){
	      			//do nothing for text boxes, as we just use the fill.
	      			return;
	      		}
    	  
    	        selected.set('stroke', e.target.value);
    	        selected.canvas.renderAll();
	      });

	  }
	  
	  changeFillColor(e){

          this.setState({
              fillColor: e.target.value
          });
          
         
          _.map(this.getAllSelected(), (selected) =>{
              if(!this.state.shouldFill){
                  selected.set('fill',"transparent");
              } else {
                  selected.set('fill',e.target.value);
              }
               
              selected.canvas.renderAll();
          });

      }
	  
	  updateTransparency(e){
	      const stateChange = {
              shouldFill: e.target.checked
          };
	      
	      if (stateChange.shouldFill == true && this.state.fillColor == "transparent"){
	          stateChange.fillColor = '#000000';
	      }
	      
	      this.setState(stateChange,
	      ()=>{
	          
	          _.map(this.getAllSelected(), (selected) =>{
	              if(!this.state.shouldFill){
	                  selected.set('fill', "transparent");
	              } else {
	                  selected.set('fill', this.state.fillColor);
	              }
	              
	              selected.canvas.renderAll();
	          });          
	          
	      }	              
	      );          
         
      
	  }
	  
	  changeLineWidth(e){
	      
	      _.map(this.getAllSelected(), function(selected){
	      
    	      if (selected.strokeWidth){
    	          selected.set('strokeWidth',parseInt(e.target.value));
    	          selected.canvas.renderAll();
    	          
    	      }
	      });
		  this.setState({
			  lineWidth: parseInt(e.target.value)
		  });
	  }
	  
	  changeBackgroundColor(e){
		  this.setState({
			  backgroundColor: e.target.value
		  })
	  }
	  
	  changeFontSize(e){
	       const selected = this._sketch._fc.getActiveObject();
	          
	      if (selected && selected.fontSize){
	          selected.fontSize = e.target.value;
	          selected.canvas.renderAll();
		  }		  
		  
		  this.setState({
			  fontSize: e.target.value
		  })
	  }
	  
	  handleImage(e) {
			var ctx = this._sketch._canvas.getContext('2d');
		  	let canvas = this._sketch._fc;
		    var reader  = new FileReader();
		    var file = e.target.files[0];
		    var img = new Image();
		    reader.onloadend = function () {
		        img.src = reader.result;
		        fabric.Image.fromURL(reader.result, (oImg) => {
		            let opts = {
		                left: 0, //Math.random() * (canvas.width - oImg.width * 0.5),
		                top: 0, //Math.random() * (canvas.height - oImg.height * 0.5),
		                scale: 1
		            };
		          
		            if ( oImg.width > canvas.width ){
		                opts.scale = canvas.width / oImg.width;
		            }
		            
		            oImg.scale(opts.scale);
		            oImg.set({
		                'left': opts.left,
		                'top': opts.top
		            })
		            canvas.add(oImg);
		        });
		       
		    }
		   
		   	reader.readAsDataURL(file);

		
	  }
	  
	  
	  onKeyDown(e){
       if (e != null && e.code == "Delete"){
           this.removeObject();
       }
	  }
	  
	  handleOnChange(e){
		  if(e != null && e.type == "mouseup" ){
			  this.setState({tool: Tools.Select, 'changed': true});
		  }
		  
	  }
	  
	  lock(){
	         if ( this._sketch._fc.getActiveGroup() == null ){
	         	 this._sketch._fc.getActiveObject().selectable = false;
	         	 this._sketch._fc.getActiveObject().reallyLocked = 1;
	            }else{
	            	this._sketch._fc.getActiveGroup().forEachObject( function(obj){
	            		object.selectable = false;
	            		object.reallyLocked = 1;
	            	});
	     	       
	            }
      	this._sketch._fc.deactivateAll().renderAll();

 
      }
	  
	  doResize(){
		  var newSize = prompt("New Size?", this.state.width + 'x' + this.state.height);
		  
		  var newSize = newSize.split('x');
		  
          this.setState({height: parseInt(newSize[1]), width: parseInt(newSize[0])});
	  }

      unlock(){

	      this._sketch._fc.forEachObject( function(obj){
	           	obj.reallyLocked = 0;
	      });
    
          this._sketch._fc.deactivateAll().renderAll();
          this.setState({tool: Tools.Select, 'changed': true});
    	  
      }
      
  handlePaste(e){
	 var that = this;
  
	  _.each(e.clipboardData.items, function(item){
		  if (item.kind == "file"){
			  var file = item.getAsFile();
		 
			  var reader  = new FileReader();
		  
			  let canvas = that._sketch._fc;
			  
			  reader.onloadend = function () {
				    var img = new Image();
				    img.src = reader.result;
				    img.onload = function(a){ 
				    	that.setState({height: img.height, width: img.width});
					    var fImg = new fabric.Image(img, {});
				    	canvas.add(fImg);
				    }
			  }
			  
			  reader.readAsDataURL(file);
		  }

	  });
  }

		  
  render() {
	  
		return (
				<div onPaste={(e)=>this.handlePaste(e)}>
					<div className="toolbar" >
					
                    <a onClick={(e) => this.changeTool(e,Tools.Select)} ><img  className={this.state.tool == "select" ? 'active' : ''} src="/assets/images/icon_select.png" /></a>
                    <a onClick={(e) => this.changeTool(e,Tools.Rectangle)} ><img  className={this.state.tool =="rectangle" ? 'active' : ''} src="/assets/images/icon_rectangle.png"/></a>
                    <a onClick={(e) => this.changeTool(e,Tools.Circle)} ><img  className={this.state.tool == "circle" ? 'active' : ''} src="/assets/images/icon_circle.png"/></a>
                    <a onClick={(e) => this.changeTool(e,Tools.Pencil)} ><img  className={this.state.tool == "pencil" ? 'active' : ''} src="/assets/images/icon_pencil.png"/></a>
                    <a onClick={(e) => this.changeTool(e,Tools.Line)} ><img  className={this.state.tool== "line" ? 'active' : ''} src="/assets/images/icon_line.png"/></a>
                    <a onClick={(e) => this.changeTool(e,Tools.Text)} ><img  className={this.state.tool =="text" ? 'active' : ''} src="/assets/images/icon_text.png"/></a>
                    <a onClick={(e) => this.changeTool(e,Tools.Arrow)} ><img  className={this.state.tool =="arrow" ? 'active' : ''} src="/assets/images/icon_arrow.png"/></a>
                    <a onClick={(e) => this.changeTool(e,Tools.Component)} ><img  className={this.state.tool =="component" ? 'active' : ''} src="/assets/images/icon_arrow.png"/></a>


                    <div className="block">
                    	<label>Outline</label>
                    	<input type="color" onChange={(e) => this.changeLineColor(e)} value={this.state.lineColor} />
                    </div>
                	<div className="block">
                        <label>Fill<input type="checkbox" className="toggleFill" onClick={(e)=>this.updateTransparency(e)} checked={this.state.shouldFill? "checked" : ""}/></label>
                        <input type="color" onChange={(e) => this.changeFillColor(e)} value={this.state.fillColor}/>
                    </div>
                      <div className="block">
                    <label>Stroke</label>
	                <select className="stroke" onChange={(e) => this.changeLineWidth(e)} value={this.state.lineWidth}>
	                    <option value="2">2px</option>
	                    <option value="5">5px</option>
	                    <option value="10">10px</option>
						<option value="15">15px</option>
						<option value="30">30px</option>
						<option value="50">50px</option>
	                </select>
	                </div>
                    <div className="block">
	                    <label>Font Size</label>
		                <select className="font" onChange={(e) => this.changeFontSize(e)} value={this.state.fontSize}>
		                    <option value="16">12</option>
		                    <option value="18">14</option>
		                    <option value="24">18</option>
							<option value="32">24</option>
							<option value="36">26</option>
							<option value="42">32</option>
		                </select>
	               </div>
	               <input type="file" onChange={(e) => this.handleImage(e)}/>
	               <button onClick={() => this._clear()} >Clear</button>
	               <button onClick={() => this.removeObject()} >Delete</button>
	               <button onClick={() => this.moveToBack()} >Move To Back</button>
	               <button onClick={() => this.lock()}>Lock</button>
	               <button onClick={() => this.unlock()}>Unlock</button>
	               <button onClick={() => this.doResize()}>Resize</button>

					</div>
					 <SketchField
					 	name='sketch'
                         className='canvas-area'
                         ref={(c) => this._sketch = c}
	                     height={this.state.height} 
					 	 width={this.state.width} 
	                     tool={this.state.tool} 
					 	 lineColor={this.state.lineColor}
	                     fillColor={this.state.shouldFill? this.state.fillColor : "transparent"}
	                     lineWidth={this.state.lineWidth}
					 	 backgroundColor={this.state.backgroundColor}
					  	 defaultDataType='url'
					 	 onChange={(e) =>this.handleOnChange(e)}
	                     onPaste={(e)=>this.handlePaste(e)}/>
	                 <Components
	                 	height={this.state.height} 
	                     width={this.state.width}>{this.state.components}</Components>
				</div>
	    );
  }
}**/