import React from 'react';
import { GridCell, GridItem, GridItemGroup, GridRowAction } from './Grid';
import Bound, { BoundMagic } from '@opidcore/components/Bound';
import Util from '@opidcore/Util';
import { Icon } from '@opidcore/components';
import _ from 'lodash';

export function createGridContext(gridContextStateRef, gridProps, magic, refreshGrid) {
    const context = new DefaultGridContext(magic, gridProps);
    context.gridContextStateRef = gridContextStateRef;
    context.refreshGrid = refreshGrid;
    context.gridProps = { ...gridProps };
    context.setAlternateProperty(gridProps.alternate);
    context.refresh();

    return context;
}

class DefaultGridContext {
    constructor(magic, gridProps) {
        this.titles = {};
        this.widths = [];
        this.rows = {};
        this.styleRows = [];
        this.refreshGrid = undefined;
        this.gridContextStateRef = undefined;
        this.boundMagic = magic ? magic : BoundMagic.create({});
        this.gridProps = { ...gridProps };
        this.holes = [];
        this.sortedData = [];
        this.sortedDataBoundsMagics = [];
        this.tempVar = undefined;
        this.alternateProperty = undefined;
        this.colour = "white";

        if (magic && magic.boundId == null){
            if(this.gridProps != undefined && this.gridProps.gridIdentifier != undefined){
                magic.boundId = this.gridProps.gridIdentifier;
            } else {
                console.log("GridFunctions.js: make a grid identifier and you'll be fine");              
            }
            
            if (magic.register){            
                magic.register();
            }else{
                console.log("GridFunctions.js no bound magic - how many times will we see this?");
            }
        }
    }

    resetAllBoundDeltas() {
        _.forEach(this.sortedDataBoundsMagics, (boundMagic) => {
            boundMagic.resetDelta();
        });
    }

    getAllBoundDeltas() {
        let ret = [];

        _.forEach(this.sortedDataBoundsMagics, (boundMagic) => {
            const currentDeltas = boundMagic.getAllDeltas();
            if (currentDeltas != undefined && currentDeltas.length > 0) {
                ret = _.union(ret, currentDeltas);
            }
        });

        // Not sure why this happened, but my bound is not included in the sorted bounds
        if (this.boundMagic != undefined && this.boundMagic.to != undefined && this.sortedDataBoundsMagics.indexOf( this.boundMagic ) == -1){
            const currentDeltas = this.boundMagic.getAllDeltas();
            if (currentDeltas != undefined && currentDeltas.length > 0) {
                ret = _.union(ret, currentDeltas);
            }
        }

        return ret;
    }

    styler = (obj, v) => {
        const style = {};
        let columnTitles = this.getTheColumnNamesBlownUp();

        const pxWidths = _.map(columnTitles, (k, i) => {

            //pull default width from width attribute of GridItem
            if (this.widths[i] == null && this.allColumns[k] && this.allColumns[k].defaultSize != null) {
                this.widths[i] = this.allColumns[k].defaultSize;
            }

            if (this.widths[i] != null) {
                return this.widths[i] + "px";
            }

            if (this.gridProps.defaultWidth) {
                return this.gridProps.defaultWidth + "px";
            }

            let minWidth = "max-content";
            if (this.gridProps.minWidth) {
                minWidth = this.gridProps.minWidth + "px";
            }

            let maxWidth = "max-content";
            if (this.gridProps.maxWidth) {
                maxWidth = this.gridProps.maxWidth + "px";
            }

            return "minmax(" + minWidth + "," + maxWidth + ")";
        });

        style.display = "grid";
        style.gridTemplateColumns = pxWidths.join(" ");

        return style;
    }

    setWidth = (obj, w) => {
        this.widths[obj.order] = w;
        this.refresh();
    }

    clear = ()=>{
        console.log("GridFunctions.js: dangerous clear");
        this.sortedDataBoundsMagics = [];
        this.sortedData = [];
    }

    refresh = (data) => {
        if (this.gridInBoundDataMode && _.size(data) == 0){
            this.sortedData = _.map( _.range(0,_.size(this.boundMagic.to[ this.gridBoundData ])), (idx)=>{
                return this.boundMagic.getBound( this.gridBoundData, idx );
            });
        }
        this.createGridHoles(data ? data : this.sortedData, this.gridProps.children, this.gridProps.orderBy, this.gridProps.gridIdentifier, this.gridProps.boundData, this.gridProps.orderByDirection);
        this.refreshGrid(this, data ? data : this.sortedData);
    }

    reset = () => {
        this.holes = [];
        this.titles = [];
        this.refresh();
    }

    setVisibleColumns = (cols) => {
        this.gridProps.visibleColumns = cols;
        this.reset();
    }

    getColumns = (data) => {
        const columns = _.filter(this.titles, (title)=>{
            if(this.gridProps.visibleColumns == undefined || this.gridProps.visibleColumns.length <= 0){
                return true;
            }

            const titleIndex = _.findIndex(this.gridProps.visibleColumns, (vc) =>{
                return vc.key == title.title;
            });
            if(titleIndex > -1){
                return true;
            }
            
            return false;
        });
        const pivots = _.groupBy(columns, (c) => c.pivot ? c.pivot.on : null);

        _.each(pivots, (pivotCols, pivotOn) => {
            if (pivotOn != "null") {
                if (data && data.length > 0) {
                    const pivotedValues = _.uniq(_.map(_.flatten(_.map(data, pivotCols[0].pivot.because)), pivotCols[0].pivot.pivoter));
                    _.each(pivotedValues, (pv, i) => {

                        const bodyCells = _.map(pivotCols, 'title').slice(1);
                        const headerCol = pivotCols[0].title;

                        columns["Pivot " + pv] = { pivotValue: pv, bodyCells: bodyCells, headerCol: headerCol, title: "I am a pivot " + pv, pivot: pivotCols[0].pivot };
                    });
                }
            }
        });

        return columns;
    }

    getTheColumns = () => {
        return this.getAllColumns(this.getColumns());
        //return this.allColumns;
    }

    getTheColumnNamesBlownUp = () => {
        let cols = this.getTheColumns();

        let columnTitles = _.flatten(_.map(cols, (o, t) => {
            if (o.bodyCells) {
                return o.bodyCells;
            }
            return t;
        }));

        return columnTitles;
    }

    howManyColumnsBefore = (gridOrder) => {
        return _.sum(_.map(_.filter(this.otherGrids, (grid, id) => grid.gridOrder < gridOrder), (grid, id) => _.keys(grid.ctx.titles).length));
    }


    getAllColumns = (existingColumns) => {
        let columns = { ...existingColumns };

        if (this.otherGrids != undefined && _.keys(this.otherGrids).length > 0) {
            _.forEach(this.otherGrids, (grid, gridKey) => {
                if (grid.ctx != undefined && gridKey != this.id) {
                    const tempColumns = _.clone(grid.ctx.getColumns(grid.props.data));

                    const previousColumns = this.howManyColumnsBefore(grid.gridOrder);
                    if (previousColumns > 0) {
                        _.each(tempColumns, (col) => {
                            if (col.origOrder == null) {
                                col.origOrder = col.order;
                            }
                            col.order = col.origOrder + previousColumns;
                        });
                    }
                    columns = { ...columns, ...tempColumns };
                }
            });
        }

        this.allColumns = columns;

        return columns;
    }


    getCell = (obj, toBeBound, adds) => {
        let actionButton = "";

        if (obj != null) {
            if (obj.appendableStuff) {
                const events = {
                    onClick: (e) => {
                        e.stopPropagation();
                        const whatWasClicked = this.findWhatIsAt(adds.row, adds.col);

                        let doCellAction = true;
                        //if we return false from add let us cancel the default cell action.

                        if (obj.appendableStuff.add) {
                            const addRet = obj.appendableStuff.add(e, whatWasClicked, this, "addButton");
                            if (addRet === false) {
                                doCellAction = false;
                            }
                        }


                        if (this.gridProps.onCellAction && doCellAction) {
                            this.gridProps.onCellAction(e, whatWasClicked, this, "addButton");
                        } else {
                            console.log("GridFucntions.js: We don't have a clicky");
                        }


                    }
                };
                actionButton = <button className="append field" {...events} key="actionButton">{obj.appendableStuff.text}</button>;
            }
        }

        let className = "";
        if (actionButton != "") {
            className = className + " actionable";
        }

        const whatWasClicked = this.findWhatIsAt(adds.row, adds.col, this);

        if (obj == null || obj.empty == true) {
            className = className + " clickable-cell field";

            let events = {
                onClick: (e) => {
                    e.stopPropagation();

                    if (this.gridProps.onCellAction) {
                        this.gridProps.onCellAction(e, whatWasClicked, this, "cellHole");
                    } else {
                        console.log("GridFunctions.js: We don't have a clicky");
                    }
                }
            };

            if(this.gridProps.disableHoleActions == true){
                if(className.indexOf("actionable") < 0){
                    events = undefined;
                    actionButton = "";
                }
                
                className = "";
            }

            return <GridCell key={adds.row + " x " + adds.col} style={{ gridColumn: (adds.col + 1), gridRow: (adds.row + 3) }} {...events} className={className}>{actionButton}</GridCell>;
        }

        let it = obj._el;

        if (it == null && obj.pivot && toBeBound) {
            it = obj.bodyCells.map((bc) => this.titles[bc]._el);
        }

        let rowButton = undefined;

        if (obj.rowActions && obj.childIndex == 0 && _.size(obj.rowActions) > 1) {
            const actions = _.map(obj.rowActions, (action, index) => {
                return <div className="option" key={"rowAction_" + index} onClick={(e) => action.action(e, toBeBound, whatWasClicked, this, action.text)}>{action.text}</div>;
            });

            rowButton = <GridRowAction>{actions}</GridRowAction>;
        }else if (obj.rowActions && obj.childIndex == 0 && _.size(obj.rowActions) == 1) {
            const action = obj.rowActions[0];

            rowButton = <Icon icon={action.icon || "toilet"} fType="fas" size="2x"  onClick={(e) => action.action(e, toBeBound, whatWasClicked, this, action.text)}/>;
        }

        

        if (it != null) {
            if (toBeBound) {
                it = <Bound to={toBeBound} key={obj.titleKey}>{it}</Bound>;
            }

            let style = { gridColumn: (adds.col + 1), gridRow: (adds.row + 3) };
            let groupStyle = {};
            if(obj.justifyContent){
                groupStyle.justifyContent = obj.justifyContent;
            }

            try{
                if (adds.row == 0) {
                    this.setTempVar(this.sortedDataBoundsMagics[adds.row].magicalGet(this.getAlternateProperty()));
                } else if (this.sortedDataBoundsMagics[adds.row].magicalGet(this.getAlternateProperty()) != this.getTempVar()) {
                    this.flipColour()
                    this.setTempVar(this.sortedDataBoundsMagics[adds.row].magicalGet(this.getAlternateProperty()));
                } else {
                    // console.log("Something bad probably happened?")
                } 
                if ((this.sortedDataBoundsMagics.length - 1 == adds.row) && (Object.keys(this.allColumns).length - 1 == adds.col)) {
                    //this.setColour("white") Why did I do this?
                }
            }catch(e){
                //gross blind catch all... 
            }

            if(this.gridProps.cellColourer) {
              this.setColour(this.gridProps.cellColourer(this.sortedDataBoundsMagics[adds.row]));
            }

            try{
                groupStyle.backgroundColor = this.getColour();
            }  catch(e){
                //gross blind catch all... 
            }

            const cell = <GridCell ctx={this} title={obj.titleKey} key={obj.titleKey + "" + adds.row + " x " + adds.col} gridRow={adds.row} gridCol={adds.col} style={style} className={className} actions={true}>
                <div className="grid-cell-group" style={groupStyle}>
                    {rowButton}{it}
                </div>
                {actionButton}
            </GridCell>;

            return cell;
        }

        if (actionButton != null){
            // this is an action button that's empty because we have no data.
            return <GridCell ctx={this} title={obj.titleKey} key={obj.titleKey + "" + adds.row + " x " + adds.col} style={{ gridColumn: (adds.col + 1), gridRow: (adds.row + 3) }} className={className} actions={true}>
                {actionButton}
            </GridCell>
        }

        return <div key={obj.titleKey + "r" + adds.row + "c" + adds.col} style={{ gridColumn: (adds.col + 1), gridRow: (adds.row + 3) }}>{obj.add ? "add " + adds.row + "X" + adds.col : ""} </div>
    }

    postGrid = (gridId, gridInfo) => {
        if (this.otherGrids == null) {
            this.otherGrids = {};
        }
        let updateNeeded = false;

        if (this.otherGrids[gridId] == undefined) {
            updateNeeded = true;
        } else if (this.otherGrids[gridId].props.data && this.otherGrids[gridId].props.data.length != gridInfo.props.data.length) {
            updateNeeded = true;
        }

        if (updateNeeded) {
            if (this.otherGrids[gridId] == undefined) {
                gridInfo.gridOrder = _.keys(this.otherGrids).length + 1;
            } else {
                gridInfo.gridOrder = this.otherGrids[gridId].gridOrder;
            }

            this.otherGrids[gridId] = gridInfo;
        }
    }

    fetchGrid = (id) => {
        return this.otherGrids[id];
    }

    setTitle = (gridTitle, title) => {
        if (title != undefined && this.titles[gridTitle] != undefined && this.titles[gridTitle].title.indexOf("replaceme") > -1) {
            this.titles[gridTitle].title = title;

            //this.refresh();
        }
    }

    removeSortedBoundMagic = (idx) => {

        /* const ourParent = this.sortedDataBoundsMagics[idx].getParentMagic();
        if(ourParent != undefined){
            ourParent.pushDelta({ id: this.sortedDataBoundsMagics[idx].to.id, delta: this.sortedDataBoundsMagics[idx].getDelta(), __deleted: true});
        } */
        

        delete this.sortedDataBoundsMagics[idx];
        this.sortedDataBoundsMagics = _.compact(this.sortedDataBoundsMagics);

        
    }

    removeData = (idx) =>{
        //untested and unused. Delete comment if ever used
        if(idx != undefined){
            delete this.sortedData[idx];

            if (this.sortedDataBoundsMagics != null){
                if(this.sortedDataBoundsMagics[idx] != undefined){
                    /* let delta = undefined;
                    if(this.sortedDataBoundsMagics[idx]._ourMagic != undefined){
                        delta = this.sortedDataBoundsMagics[idx]._ourMagic.getDelta();
                    } else if(this.sortedDataBoundsMagics[idx].getDelta != undefined){
                        delta = this.sortedDataBoundsMagics[idx].getDelta();
                    }
                    
                    this.sortedDataBoundsMagics[idx].pushDelta({ id: this.sortedDataBoundsMagics[idx].id, delta: delta, __deleted: true}); */
                    
                    this.removeSortedBoundMagic(idx);
                }
            }

            this.setData(_.compact(this.sortedData));
        }
    }

    removeSubGridData = (gridIdx, subGridIdx, basis) => {
        let updatedRow = this.sortedData[gridIdx];
        if (updatedRow != undefined) {
            if(basis == undefined){
                return;
            }
            if(!Array.isArray(basis)){
                basis = [basis];
            }

            const ourParent = this.sortedDataBoundsMagics[gridIdx];
            
            _.forEach(basis, (b)=>{
                if (updatedRow[b] != undefined) {    
                    delete updatedRow[b][subGridIdx];

                    const myBound = ourParent.getBound(b, subGridIdx);
                    ourParent.pushDelta({ id: myBound.id, delta: myBound._ourMagic.getDelta(), __deleted: true});

                    updatedRow[b] = _.compact(updatedRow[b]);
                }
            });

            this.sortedData[gridIdx] = updatedRow;
            this.data = [...this.sortedData];

            this.refresh();
        } 
    }

    setData = (data, idx) => {
        if (idx >= 0) {
            this.sortedData[idx] = data;
            this.data = [...this.sortedData];

            if (this.sortedDataBoundsMagics != null){
                if(this.sortedDataBoundsMagics[idx] != undefined){
                    this.sortedDataBoundsMagics[idx].replaceTo(data);
                }
                
            }
        } else {
            this.sortedData = data;
            this.data = [...this.sortedData];
        }
        
        this.refresh();
    }

    pushSubGridData = (data, idx, basis) => {
        const updatedRow = this.sortedData[idx];
        if (updatedRow != undefined) {
            if (updatedRow[basis] == undefined) {
                updatedRow[basis] = [];
            }

            updatedRow[basis].push(data);

            this.sortedData[idx] = updatedRow;
            this.data = [...this.sortedData];

            this.refresh();
        } else {
            console.log("GridFunctins.js pushSubGridData failed"); 
        }
    }

    getData = () => {
        return this.data;
    }

    findWhatIsAt = (rowIdx, colIdx) => {
        const ret = {};
        ret.cell = this.holes[rowIdx][colIdx];

        let _rowIdx = rowIdx;
        while (ret.colThing == null && _rowIdx >= 0) {
            if (this.holes[_rowIdx][colIdx] != null && this.holes[_rowIdx][colIdx].empty != true) {
                ret.colThing = this.holes[_rowIdx][colIdx];
            }
            _rowIdx--;
        }

        let _colIdx = colIdx;
        while (ret.rowThing == null && _colIdx >= 0) {
            if (this.holes[rowIdx][_colIdx] != null && this.holes[rowIdx][_colIdx].empty != true) {
                ret.rowThing = this.holes[rowIdx][_colIdx];
            }
            _colIdx--;
        }

        let likelyRowIndex = 0;
        if (ret.rowThing) {
            likelyRowIndex = ret.rowThing.gridDataRowIndex;
        } else if (ret.colThing) {
            likelyRowIndex = ret.colThing.gridDataRowIndex;
        }

        const currentCol = _.values(this.getColumns()).find((v) => v.order == colIdx)
        const boundAs = currentCol ? currentCol.boundData : null;
        const currentBasis = ret.rowThing ? ret.rowThing.basisInformation : null;

        return { details: ret, rowIndex: likelyRowIndex, row: this.sortedData[likelyRowIndex], boundAs: boundAs, currentBasis: currentBasis };
    }

    setTempVar = (val) => {
        this.tempVar = val;
    }

    getTempVar = () => {
        return this.tempVar;
    }

    setAlternateProperty = (val) => {
        this.alternateProperty = val;
    }

    getAlternateProperty = () => {
        return this.alternateProperty;
    }

    flipColour = () => {
        if (this.getColour() == "lightgray") {
            this.setColour("white");
        } else {
            this.setColour("lightgray");
        }
    }

    setColour = (val) => {
        this.colour = val;
    }

    getColour = () => {
        return this.colour;
    }

    createGridHoles = createGridHoles;
    fillGridHoles = fillGridHoles;
};

function isReactElement(child) {
    if (child != undefined) {
        if (Array.isArray(child)) {
            return true;
        }

        if (child.props != undefined) {
            return true;
        }
    }
    return false;
}

export function createTitles(gridProps, myGridContext, children, parentTitle, pvt, parentProps) {
    if (!Array.isArray(children)) {
        children = [children];
    }

    let assumedIndex = 0;

    return _.forEach(_.compact(children), (currentEl, badIndex) => {
        let foundElement = false;
        const isHeadingTitle = _.isEqual(currentEl.props.componentName, "GridHeading");

        if (!isHeadingTitle) {
            if (_.startsWith(currentEl.props.componentName, "Grid") == false && !currentEl.props.treatAsSubGrid) {
                // so this is if we have called recursively?
                if (parentTitle) {
                    currentEl = React.cloneElement(currentEl, { key: currentEl.props.field, title: parentTitle });

                    if (myGridContext.titles != undefined) {
                        myGridContext.titles[parentTitle].field = currentEl.props.field;
                        myGridContext.titles[parentTitle]._el = currentEl;
                        if (gridProps.rowActions) {
                            myGridContext.titles[parentTitle].rowActions = gridProps.rowActions
                        }
                        if (parentProps && parentProps.width) {
                            myGridContext.titles[parentTitle].defaultSize = Util.parseSize(parentProps.width);
                        }

                        if (gridProps.isSubGrid == true) {
                            if (myGridContext.titles[parentTitle].boundData == null) {
                                myGridContext.titles[parentTitle].boundData = gridProps.boundData;
                                myGridContext.titles[parentTitle].rowBasis = gridProps.rowBasis;
                                myGridContext.titles[parentTitle].rowBasisGroup = gridProps.rowBasisGroup;
                                myGridContext.titles[parentTitle].theirGridIdentifier = gridProps.gridIdentifier;
                            }
                        }
                        //myGridContext.titles[parentTitle].allProps = {...gridProps};
                        foundElement = true;
                        if (pvt != undefined) {
                            myGridContext.titles[parentTitle].pivot = pvt;
                        }

                        myGridContext.titles[parentTitle].isSubGrid = gridProps.isSubGrid ? gridProps.isSubGrid : false;

                        if (gridProps.appendable) {
                            myGridContext.titles[parentTitle].appendable = gridProps.appendable;
                        }
                    }
                } else {
                    const title = "title-replaceme-" + badIndex;
                    const child = React.cloneElement(currentEl, {});
                    currentEl = <GridItem title={title} needsTitle={true}>{child}</GridItem>;
                }
            }

            if (parentTitle && foundElement == false) {
                if (myGridContext.titles != undefined && myGridContext.titles[parentTitle]) {
                    //child of a griditem that didn't fit in and got orphaned
                    if (myGridContext.titles[parentTitle]._el == null) {
                        myGridContext.titles[parentTitle]._el = currentEl;
                        myGridContext.titles[parentTitle].mightNeedATitle = true;
                    }
                }
            }

            if (currentEl.props) {
                let myTitle = null;

                if (_.startsWith(currentEl.props.componentName, "GridItem") == true) {
                    const myColumnObjectBase = { index: assumedIndex++, childIndex: badIndex, order: _.keys(myGridContext.titles).length, pivot: undefined };
                    if (currentEl.props.boundData) {
                        myColumnObjectBase.boundData = currentEl.props.boundData;
                    }

                    if (currentEl.props.title) {
                        myTitle = currentEl.props.title;
                    }

                    if(currentEl.props.justify != undefined && currentEl.props.justify.trim().length > 0){
                        myColumnObjectBase.justifyContent = currentEl.props.justify;
                    }

                    if (currentEl.props.needsTitle) {
                        myColumnObjectBase.needsTitle = true;
                    }

                    if (currentEl.props.hideTitle) {
                        myColumnObjectBase.hideTitle = currentEl.props.hideTitle;
                    }

                    if (myTitle != undefined && myTitle.length > 0) {
                        myColumnObjectBase.title = myTitle;

                        myTitle = myTitle + "" + gridProps.gridIdentifier;

                        if (myGridContext.titles != undefined && myGridContext.titles[myTitle] == undefined) {
                            myColumnObjectBase.titleKey = myTitle;
                            myGridContext.titles[myTitle] = myColumnObjectBase;
                        }
                    }
                }

                if (isReactElement(currentEl.props.children)) {
                    let _gridProps = {...gridProps};
                    if (currentEl.props.componentName == "Grid") {                        
                        _gridProps = { ..._gridProps, ...currentEl.props };
                        _gridProps.isSubGrid = true;
                    }

                    let thePvt = pvt;
                    if (thePvt == undefined && currentEl.props.pivotOn != undefined) {
                        thePvt = { because: currentEl.props.pivotBecauseOf, pivoter: currentEl.props.pivoter, on: currentEl.props.pivotOn };
                    }

                    createTitles(_gridProps, myGridContext, currentEl.props.children, myTitle, thePvt, currentEl.props);
                }
            }
        }
    });
}

export function ChildWatcher(props) {
    return <div className="phantom" style={{ display: "none" }}>{props.children}</div>;
}

function createGridHoles(data, children, orderBy, gridIdentifier, boundData, orderByDirection) {    

    if (boundData){
        this.gridBoundData = boundData;
        this.gridInBoundDataMode = true;
    }

    this.holes = [];

    let columns = this.getTheColumns(data);
    

    const orderDirection = [];
    const orderByArray = [];

    let  functionOrderBy = false;

    _.forEach(orderBy, (oB, idx) =>{
        if (_.isFunction(oB)){
            functionOrderBy = true;
            orderByArray.push( oB );
        }else{
            const pieces = oB.split(" desc");

            if(pieces[1]  != undefined){
                orderDirection.push("desc");
            } else if(orderByDirection != undefined && orderByDirection[idx] != undefined){
                orderDirection.push(orderByDirection[idx]);
            } else {
                orderDirection.push("asc");
            }

            orderByArray.push(pieces[0]);
        }
    });

    this.orderBy = orderByArray;
    this.orderDirection = orderDirection;
    
    if (this.gridInBoundDataMode){
        this.sortedData = [];
        if (_.size(data) >= 0){

            if (functionOrderBy){
                this.sortedData = _.compact(_.sortBy(
                    _.map( _.range(0,_.size(this.boundMagic.to[ boundData ])), (idx)=>{
                        return this.boundMagic.getBound( boundData, idx );
                    }), 
                this.orderBy ));
            }else{
                this.sortedData = _.compact(_.orderBy(
                    _.map( _.range(0,_.size(this.boundMagic.to[ boundData ])), (idx)=>{
                        return this.boundMagic.getBound( boundData, idx );
                    }), 
                this.orderBy, orderDirection));
            }
        }
    }else{
        this.sortedData = _.compact(_.orderBy(data, this.orderBy, orderDirection));
    }

    if (this.sortedDataBoundsMagics == null) {
        this.sortedDataBoundsMagics = [];
    }

    this.sortedDataBoundsMagics = [];

    _.forEach(this.sortedData, (dataRow, gridDataRowIndex) => {
        const likelyIWillBe = { boundTo: "row", gridDataRowIndex: gridDataRowIndex };
        const rowR = [];
        const row = [];
        const appendingCells = [];

        const extenders = _.groupBy(columns, 'boundData');
        _.each(columns, (obj, title) => {
            const me = { ...likelyIWillBe, ...obj };
            row.push(me);

            if (me.appendable) {
                let canAppend = false;
                
                if (me.isSubGrid == false || true) {
                    //not sure why we wanted to append differently if we were a sub grid but for ap invoice lines we need only the last row of the subgrid
                    //main grid -> only append to last row
                    if (this.sortedData.length - 1 == gridDataRowIndex) {
                        canAppend = true;
                    }
                } else {
                    //sub grid -> append to every row
                    canAppend = true;
                }

                if (me.appendable.disabled){
                    canAppend = false;
                }

                if(me.appendable.column != undefined && me.appendable.column != me.order){
                    //allows us to specify the only column we want to add button in
                    canAppend = false;
                }

                if (canAppend && me.childIndex == 0) {
                    // this is a lie, and doesn't give us much better info, i think we need to integrated with the cell clicking.
                    if (me.appendable.text.trim().length > 0) {
                        appendingCells.push({ text: me.appendable.text, appendingIndex: me.order, add: me.appendable.add });
                    }
                }
            }
        });

        

        if (this.sortedDataBoundsMagics[gridDataRowIndex] == null) {
            
            if (this.gridInBoundDataMode){
                this.sortedDataBoundsMagics[gridDataRowIndex] = dataRow._ourMagic; //getBound(boundData, gridDataRowIndex);
            }else{
                if (dataRow._keyPath == null){
                    dataRow._keyPath = boundData;
                    dataRow._keyPathPos = gridDataRowIndex;
                }
                this.sortedDataBoundsMagics[gridDataRowIndex] = new BoundMagic(dataRow); //fake magic just for pulling data from dataRow
                this.sortedDataBoundsMagics[gridDataRowIndex].parentMagic = this.boundMagic;
            }
        } else {
            //debugger;    
        }

        const magic = this.sortedDataBoundsMagics[gridDataRowIndex];


        rowR.push(row);
        delete extenders["undefined"];



        //ext is the boundData prop and extDefs is a list of the columns.
        _.each(extenders, (extDefs, ext) => {
            if (extDefs && extDefs[0]) {
                const firstColOfSubGrid = extDefs[0];
            }
            _.each(extDefs, (extDef) => {
                //not sure if boundData is the best approach here.              }
                const removeIdx = _.findIndex(rowR[0], (r) => r && r.order == extDef.order && r.boundData != null);
                rowR[0][removeIdx] = { empty: true, cell: rowR[0][removeIdx] };
                //we might need to do something if we want these to be "Sticky" and make them continue in extended rows.
            });

            let dataWeWant = dataRow[ext];
            let contextWeWant = null;

            contextWeWant = this.otherGrids[extDefs[0].theirGridIdentifier];

            if (extDefs[0].boundData) {
                dataWeWant = magic.magicalGet(extDefs[0].boundData);
            }

            if (!_.isArray(dataWeWant)) {
                dataWeWant = [dataWeWant];
            }

            _.each(dataWeWant, (extendingRow, i) => {

                if (i >= rowR.length) {
                    rowR.push([]);
                }

                let has = 0;
                _.each(extDefs, (extDef) => {
                    const me = { ...extDef, boundDataIndex: i, gridDataRowIndex: gridDataRowIndex };
                    let expectedI = i;
                    if (me.rowBasis) {
                        const otherBasiseses = _.map(rowR, (r, i) => _.uniq(_.map(r, 'actualBasis'))[0]).length;
                        if (i < otherBasiseses) {
                            expectedI = otherBasiseses;
                        }
                        me.actualBasis = me.rowBasisGroup + "-" + extendingRow[me.rowBasis];                
                        me.basisInformation = [];
                        me.basisInformation.push(extendingRow[me.rowBasis])
                        if (me.rowBasisGroup) {
                            me.basisInformation.push(me.rowBasisGroup);
                        }

                        const newI = _.findIndex(_.map(rowR, (r, i) => [i, _.uniq(_.map(r, 'actualBasis'))]), (g) => g[1].indexOf(me.actualBasis) != -1);
                        if (newI >= 0) {
                            expectedI = newI;
                        }
                    }

                    if (expectedI >= rowR.length) {
                        rowR[expectedI] = [];
                    }
                    

                    if ((expectedI >= 0 || true) && (rowR[expectedI][extDef.order] == undefined || rowR[expectedI][extDef.order].empty)) {
                        me.expectedI = expectedI;
                        rowR[expectedI][extDef.order] = me;
                    } else {
                        //find the first empty slot
                        let searchIndex = 0;
                        while (searchIndex < rowR.length) {
                            if (rowR[searchIndex][extDef.order] == undefined || rowR[searchIndex][extDef.order].empty) {
                                expectedI = searchIndex;
                                break;
                            }

                            searchIndex++;
                        }
                        me.expectedI = expectedI;

                        if (rowR[expectedI][extDef.order] == undefined || rowR[expectedI][extDef.order].empty) {
                            rowR[expectedI][extDef.order] = me;
                        } else {
                            console.log("GridFunctions.js We done goofed and cannot create a hole here!");
                        }
                    }
                });
            });
        });

        if (this.gridProps.appendable) {
            _.forEach(appendingCells, (appendingCell) => {
                if (rowR[rowR.length - 1][appendingCell.appendingIndex] == undefined) {
                    rowR[rowR.length - 1][appendingCell.appendingIndex] = { empty: true };
                }
                rowR[rowR.length - 1][appendingCell.appendingIndex].appendableStuff = appendingCell;
            });
        }

        
        _.each(rowR, (r) => {
            this.holes.push(r);
        });
    });

    if (this.gridProps.appendable && this.sortedData.length == 0) {
        const r = [{ appendableStuff: { text: this.gridProps.appendable.text, appendingIndex: -1, add: this.gridProps.appendable.add }} ];
        this.holes.push(r);
    }
}

function fillGridHoles(data, children, orderBy, gridIdentifier, xray, alternate) {
    //fill holes - move higher in tree

    //it doesn't look like grid cares when we change the children
    if (false && !_.isEqual(this.gridProps.children, children)) {
        this.gridProps.children = children;
        debugger;        
        this.refresh();
        return;
    }

    let boundTo = null;

    const columnCount = _.keys(this.getTheColumns()).length;
    const gridRowGroups = {};

    _.forEach(this.holes, (holesRow, rowIdx) => {
        let gridIndex = 0;

        if (holesRow[0] && holesRow[0].gridDataRowIndex >= 0) {
            gridIndex = holesRow[0].gridDataRowIndex;
            boundTo = this.sortedData[gridIndex];
        }

        if (boundTo == null) {
            boundTo = this.sortedData[rowIdx];
        }

        const magic = this.sortedDataBoundsMagics[gridIndex];
        if (magic && magic.callback == null) {
            magic.callback = (a, diff) => {
                //this.sortedData[gridIndex] = a;
                if (false){
                    let newData = _.cloneDeep(this.sortedData);
                    newData[gridIndex] = a;
                    const thehole = holesRow[0];

                   
                    if (_.keys(diff).indexOf(thehole.rowBasis) > -1) { //Cody thinks this needs to change to make setstate actually update
                        
                        this.refresh(newData);
                    }
                }
            }
        }



        const cells = _.map(_.range(0, columnCount), (colIdx) => {
            const holesCell = holesRow[colIdx];

            let toBeBound = null;

            if (holesCell && holesCell.boundData && magic.magicalGet) {
                toBeBound = magic.getBound(holesCell.boundData, holesCell.boundDataIndex);
            }

            if (holesCell != null && holesCell.pivot) {
                const them = _.filter(boundTo[holesCell.pivot.because], (r) => holesCell.pivot.pivoter(r) == holesCell.pivotValue);
                if (them && them.length > 0) {
                    if (them.length == 1) {
                        toBeBound = them[0];
                    } else {
                        console.log("figure out multiple pivoted things");
                    }
                }
            }


            if (xray == true || xray == "true") {
                const thing = { ...holesCell }
                if (toBeBound) {
                    thing.toBeBound = { ...toBeBound };
                    delete thing.toBeBound["_ourMagic"];
                }
                thing._el = null;
                if (thing.cell) {
                    thing.cell._el = null;
                }


                let key = thing.titleKey || "col" + colIdx;

                return <div title={JSON.stringify(thing)} key={key}>{rowIdx + " x " + colIdx}</div>
            }

            if (holesCell != null && toBeBound == null && holesCell.theirGridIdentifier != undefined && gridIdentifier != undefined && this.otherGrids != undefined) {
                if (gridIdentifier != holesCell.theirGridIdentifier) {
                    if(this.otherGrids[holesCell.theirGridIdentifier] != undefined){
                        toBeBound = this.otherGrids[holesCell.theirGridIdentifier].props.data[holesCell.boundDataIndex];
                    } else {
                        console.log("GridFunctions.js: This might be a problem. Cell is not bound");
                    }                    
                }
            }

            if(this.sortedGridRowData == undefined){
                this.sortedGridRowData = {};
            }

            if(this.sortedGridRowData[rowIdx] == undefined){
                this.sortedGridRowData[rowIdx] = {};
            }

            if(toBeBound != undefined && holesCell != undefined){
                if(holesCell.boundData != undefined){
                    this.sortedGridRowData[rowIdx][holesCell.boundData] = toBeBound;
                } else if(holesCell.theirGridIdentifier != undefined){
                    this.sortedGridRowData[rowIdx][holesCell.theirGridIdentifier] = toBeBound;
                } else {
                    //this.wtf[rowIdx][gridIdentifier] = toBeBound;
                    debugger
                }
            }
            

            return this.getCell(holesCell, toBeBound, { row: rowIdx, col: colIdx });

        });

        if (gridRowGroups[gridIndex] == undefined) {
            gridRowGroups[gridIndex] = [];
        }

        if(_.isEmpty(this.sortedGridRowData[rowIdx])){
            this.sortedGridRowData[rowIdx] = boundTo;
        }

        gridRowGroups[gridIndex].push(
            <React.Fragment key={rowIdx}>
                <Bound to={boundTo}>
                    <div className="grid-row" style={{display: this.gridProps.shouldDisplayRow == undefined? "contents" : this.gridProps.shouldDisplayRow(boundTo) }}>
                        {cells}
                        {this.gridProps.watchChildren ? <ChildWatcher>{children}</ChildWatcher> : null}
                    </div>
                </Bound>
            </React.Fragment>
        );
    });

    const res = _.map(gridRowGroups, (rows, gridRowGroupIndex) => {
        
        return <React.Fragment key={gridRowGroupIndex}>{rows}</React.Fragment>;
    });

    return res;
}