import React, { Component, PropTypes } from 'react';
import nv from 'nvd3';
import * as d3 from "d3";

import * as _ from 'lodash';
import OpidGraphBase from './OpidGraphBase.jsx';

export default class PieChart extends OpidGraphBase {
     constructor(props){
        super(props);
        this.state = {renders: 0};
    }
    
    render(){      
        return <div className="hey"> 
            <div className="graph-contents" ref="graph-contents"><svg ref={svgNode => this.svgNode = svgNode}/></div>
        </div>
    }
    
    componentWillReceiveProps(nextProps){
        const sameData = _.isEqual(nextProps.plot,this.props.plot);
        if(!sameData){
            this.renderGraph(nextProps);
        }
    }    
    
  	static getEditableProps(){
  	    let options = [{key:"", value:""}, {key: "no", value:"No"}, {key: "yes", value: "Yes"}];
      
        return [
			{niceName: "Plot", prop: "plot", type: "text", allowJS:true },
			{niceName: "Height", prop: "height", type: "text", default : "10em", allowJS:true },
			{niceName: "Width", prop: "width", type: "text", default : "10em" },
			{niceName: "Margins", prop: "margins", type: "text", default : "" },
			{niceName: "Show Legend", prop: "showLegend", type: "checkbox", default : true },
			{niceName: "Show Labels", prop: "showLabels", type: "checkbox", default : true },
			{niceName: "Show Labels As", prop: "showLabelsAs", type: "text", options: ["key", "value", "percent","percent_only", "percent_2decimals", "percent_2decimals_only"] },
			{niceName: "Show As Donut", prop: "donut", type: "checkbox", default : true },
			{niceName: "Colour Set", prop: "colourProvider", type: "text", default : true, allowJS: true }
			
        ]
        
    }

 	static metaInfo(){
  		return {renderAsPng: true, icon: "chart-pie", preview: (me)=>{ return "?" } }
  		
  	}
  	
    componentDidMount(){
        this.renderGraph(this.props);
    }
    
    componentDidUpdate(prevProps, prevState, snapshot){
        var changed = _.isEqual(prevProps.plot,this.props.plot) == false;
    
        _.each(PieChart.getEditableProps(), (p)=>{
        	changed = changed || (prevProps[p.prop] != this.props[p.prop]);	
        });
        
        if( changed ){
            this.renderGraph(this.props);
        }
    }
    
    renderGraph(props){ 
        var datasets  = false;
        var iamData =    _.map(props.plot, function(a,b){ return {label: b, value: a} });
        var that = this;

        const colours = this.fetchColours(iamData);
   
          nv.addGraph({
        generate: function() {
         var chart = nv.models.pieChart()
         .color(colours)
          .x(function(d) { return d.label })
          .y(function(d) { return d.value })
          .showLabels(props.showLabels == false ? false : true)     //Display pie labels
          .labelThreshold(.05)  //Configure the minimum slice size for labels to show up
          .labelType(props.showLabelsAs || "percent") //Configure what type of data to show in the label. Can be "key", "value" or "percent"
          ;
         
         if (props.donut){
             chart.donut(true)          //Turn on Donut mode. Makes pie chart look tasty!
             chart.donutRatio(0.55)     //Configure how big you want the donut hole size to be.
         }

                   if (props.colors){
                    chart.color( props.colors )
                }

                if (props.donut == false){
                    chart.donut(false);
                }

                d3.select(that.svgNode)
                    .attr('width', props.width)
                    .attr('height', props.height)
                    .datum( iamData )
                    .transition()
                    .call(chart);

                if (props.showLegend != undefined){
                    chart.showLegend(props.showLegend);
                }else{
                    chart.showLegend(true);
                }
            
            chart.pie.dispatch.on("elementClick", function(e) {
                    var clicked = [e.data.label]
                    
                    var field = that.props.group1;
                    
                    that.props.handleClickThrough( field, clicked );
                   // alert(clicked.join(' '));                    
              });
            
            chart.legend.dispatch.on('legendClick', function(e,a){
                var field = that.props.origProps ? that.props.origProps.group1 : that.props.group1;

                var clicked = [ e.label ];
                that.props.handleClickThrough && that.props.handleClickThrough( field, clicked );
                    
                
                //that._moveCrazyLabels && that._moveCrazyLabels();
            });
            

            },

            callback: function(graph) {
                that.setState({renders: that.state.renders+1}, ()=>{that.forceUpdate()});
            }
        });
    }
}