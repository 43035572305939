import React, { useState, useEffect, useContext } from 'react';
import { BoundDataContext, Button, Icon } from '@opidcore/components';
import _ from 'lodash';

export default function BoundLinkButton({icon = null, field, target, text="", className="", title="", children=null, modalName = undefined}) {
    const bound = useContext(BoundDataContext)

    function openModal(e) {
        e.preventDefault()
        APP.instance.createModal(target + bound.magicalGet(field), {modal_name: modalName});
    }
    if (icon != null) return <a href={target + bound.magicalGet(field)} className={className} title={title} onClick={(e) => openModal(e)}><Icon onClick={() => null} icon={icon}/></a>
    return <a href={target + bound.magicalGet(field)} className={className} title={title} onClick={(e) => openModal(e)}>{text}{children}</a>
}