
import React, { Fragment, useContext, useState } from "react";
import { DataContext } from "@opidcore/components/Bound";
import { useEffect } from "react";

/*
ex use:
<Bound to={kanbanToggleRef.current} onChange={updateKanbanState}>
  <MultiOptionToggle field="kanbanShowStatus" name="kanbanToggle">
	<div data-toggle-value="false">List</div>
	<div data-toggle-value="true">Kanban</div>
  </MultiOptionToggle>
</Bound>

*/
export default function MultiOptionToggle(props) {
	const bound = useContext(DataContext);
	const [value, setValue] = useState(bound.magicalGet(props.field, false));

	useEffect(() => {
		const boundValue = trueish(bound.magicalGet(props.field, false));

		if (boundValue != value) {
			setValue(boundValue);
		}
	});

	let label = null;
	if ((props.hideLabel == undefined || props.hideLabel == false) && props.label != undefined) {
		if (props.label instanceof Function) {
			label = (
				<label>
					{props.label(value)}
					{props.glyphicon ? <span className={props.glyphicon} /> : ""}
				</label>
			);
		} else {
			label = (
				<label>
					{props.label}
					{props.glyphicon ? <span className={props.glyphicon} /> : ""}
				</label>
			);
		}
	}

	let radioButton = null;
	if (props.children) {
		radioButton = props.children
			.filter((child) => {
				let childPropKeys = Object.keys(child.props);
				if (childPropKeys.includes("data-toggle-value")) {
					return true;
				} else {
					return false;
				}
			})
			.map((child, index) => {
				const inputProps = {
					type: "radio",
					value: child.props["data-toggle-value"],
					name: props.name,
					id: child.props["data-toggle-value"] + "",
					key: child.props["data-toggle-value"] + ""
				};
				if (inputProps.value == value) {
					inputProps.defaultChecked = "checked";
				}
				return (
					<Fragment key={index}>
						<input {...inputProps} />
						<label htmlFor={child.props["data-toggle-value"] + ""}>{child.props.children}</label>
					</Fragment>
				);
			});
	}

	const toggleCheckbox = (e) => {
		let newValue = trueish(e.target.value);
		bound.magicalSet(props.field, newValue);
		setValue(newValue);
	};

	return (
		<div className="multiOptionToggle">
			<fieldset>
				<label>{label}</label>
				<div className="toggle" onChange={toggleCheckbox}>
					{radioButton}
				</div>
			</fieldset>
		</div>
	);
}

const trueish = (v)=>{
	if (v == "true" || v == true || v == 1) {
		return true;
  }
	if(v == "false" || v == false || v == 0) {
   return false;
  }
  return v;
}
