import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useData } from "@opidcore/hooks/WTF";
import { BoundDataContext } from "@opidcore/components";
import PropTypes from "prop-types";
import * as _ from "lodash";

/**
 * @example
 * <LookupStatusSelect lookupName="supportTaskStatus" field="status" />
 */
export default function LookupStatusSelect(props) {
  const bound = useContext(BoundDataContext);

  const lookupName = props.lookupName.split(":")[0];
  const fetchOptions = { ...{ name: lookupName }, ...props.fetchOptions };
  const field = props.field;
  const disabled = props.disabled;

  const [showStatusSelect, setShowStatusSelect] = useState(false);
  const [currentlySelected, setCurrentlySelected] = useState({
    id: null,
    name: null,
    key: null,
    label: null,
    parameter1: null,
    parameter2: null,
    colour: null,
    className: null,
  });
  const [lookups, setLookups] = useState([]);

  useEffect(() => {
    APP.central.Lookup.fetchForName(lookupName).then((r) => {
      setLookups(r.result);
    });
  }, [lookupName]);

  // toggles dropdown
  const toggleShowStatusSelect = () => {
    setShowStatusSelect(!showStatusSelect);
  };

  // sets bound field to selection and updates currentlySelected status, then hides menu
  const setSelection = (selection) => {
    bound.magicalSet(field, selection.key);
    setCurrentlySelected(selection);

    if (props.onChange) {
      props.onChange({
        boundTo: bound.to,
        field: field,
        selection: selection,
        bound: bound,
      });
    }

    toggleShowStatusSelect();
  };

  //If this starts to loop infintiely its because the useEffect is on lookups instead of lookupsDs.loading
  // set currentlySelected status to bound's field
  useEffect(() => {
    if (lookups.length > 0) {
      const tempSelected = _.filter(lookups, (lookup) => {
        return lookup.key == bound.to[field];
      });
      setCurrentlySelected(tempSelected[0] ? tempSelected[0] : currentlySelected);
    }
  }, [lookups]);

  return (
    <Fragment>
      <div className="lookupStatusSelect">
        <SingleStatusThing
          text={currentlySelected.label}
          colour={currentlySelected.colour}
          onClick={disabled ? null : toggleShowStatusSelect}
          className={currentlySelected.className}
          disabled={disabled}
        />
        {showStatusSelect ? <StatusDropDown lookups={lookups} selectCallback={setSelection} showEmpty={props.showEmpty} /> : null}
      </div>
    </Fragment>
  );
}

const StatusDropDown = ({ lookups, selectCallback, showEmpty = false }) => {
  const dropDown = _.map(lookups, (lookup) => {
    return <SingleStatusThing text={lookup.label} colour={lookup.colour} key={lookup.id} onClick={() => selectCallback(lookup)} className={lookup.className} />;
  });

  if (showEmpty) {
    dropDown.push(
      <SingleStatusThing
        key={-1}
        onClick={() => selectCallback({ id: null, name: null, key: null, label: null, parameter1: null, parameter2: null, colour: null })}
        className={null}
      />
    );
  }

  let singleWidth = 112;
  _.forEach(lookups, (lookup) => {
    if (lookup.className) {
      const element = document.querySelector("." + lookup.className);
      if (element && getComputedStyle(element)) {
        const theWidth = _.toNumber(getComputedStyle(element).width.match(/\d+/)[0]);
        if(_.isNumber(theWidth)) {
          singleWidth = theWidth;
        }
      }
    }
  });

  const colCount = Math.ceil(dropDown.length / 6);
  const width = 10 + (5*colCount) + (colCount * singleWidth);
  const height = 43 * dropDown.length;

  return (
    <div className="selectMenu" style={{ width: width + "px", height: height + "px" }}>
      {dropDown}
    </div>
  );
};

const SingleStatusThing = ({ colour = "#808080", text = "Not Selected", className = "", onClick, disabled = false }) => {
  if (!colour) {
    colour = "#808080";
  }
  if (!text) {
    text = "Not Selected";
  }

  return (
    <div className={"singleStatus " + className} style={{ backgroundColor: colour }} onClick={onClick} disabled={disabled}>
      <span>{text}</span>
    </div>
  );
};

LookupStatusSelect.propTypes = {
  /**
   * - the name of the lookup to fetch.
   */
  lookupName: PropTypes.string,
  /**
   * - any fetch options for filtering purposes. Can be Null.
   */
  fetchOptions: PropTypes.any,
  /**
   * - the field in bound to update.
   */
  field: PropTypes.string,
  /**
   * - disables clicking and changing the status.
   */
  disabled: PropTypes.bool,
  /**
   * - shows empty ("Not Selected") status.
   */
   showEmpty: PropTypes.bool,
};
