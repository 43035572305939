import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { InputText, Bound } from '../../../OpidCore/public/js/components';

import * as CentralActions from '../../../OpidCore/public/js/actions/CentralActions';

import SaveToolbar from '../components/SaveToolbar.jsx';

import * as _ from 'lodash';
//import moment from 'moment';

class CoreEditor extends Component {
	static test = "B";

    constructor(props, context) {
        super(props, context);
        
        this.state = {
                __initialFetchCompleted: false,
                __fetching: false,
                id: 0
        };
    }
   
    static getDerivedStateFromProps(props, state){
    	if (state.__initialFetchCompleted == false && state.__fetching == false ){
    		var newState = {};
    		newState.__fetchRequired = true;
    		newState.__id = parseInt(props.match.params.id);
    		return newState;
    	}
    	return null;
    }    
    
    buzzah = () => {
    	return "hey";
    }
    
    handleSave(callback){
        if (this.__handleSave == null){
            this.__handleSave = _.debounce( (c) => this._handleSave(c), 500 )
        }
        
        return this.__handleSave(callback);
    }
    
    _handleSave(callback){
        var saveObj = this.getSaveState();

        return this.getCentral().save(saveObj).then((r)=>{
            console.log(r);
            callback && callback();
            this.setState({__fetchRequired:true});
        });
    }
    
    componentDidUpdate(prevProps, prevState, snapshot){
    	if (this.state.__fetchRequired == true){
            this.getCentral().fetch(this.state.__id).then((res)=>{
            	this.setState(res.result);
            	this.setState({__loaded: true});
            });
    		this.setState({__fetchRequired: false, __fetching: true});
    	}
    }
    
    render(){
    	var inner = null;
    	
    	if (this.state.__loaded)
    		inner = this.renderEditor();
    	
    	return <div>
    	{inner}
    	</div>;
    }
    
    getSaveState(){
    	return {...this.state};
    }
    
    save(){
        let saveObj = this.getSaveState();
        
        this.getCentral().save(saveObj).then((r)=>{
        });
        
        //alert('saved');
        //window.history.back();
    }
    
    cancel(){
        window.history.back();
    }
 
   
}



class EditDataViewView extends CoreEditor {
	static test = "A";
	constructor(props){
		super(props);
		
		props.central.Stuff.listDefinitions();
	}
	
	getSaveState(){
    	var ret = {...this.state};
    	
  
    	
    	return ret;
    }
	
      
	getCentral(){
		return this.props.central.View;
	}
	
	handleFieldUpdate(a,b,c,d){
		
		console.log(c,a);
		
	}
	
		
	 renderEditor() {        
	       
	        return (
	            <div id="plan" className="outer-container">
	                <div className="container" >
	                    <Bound to={this} disabled={this.state.disabled}>
	                     
	                        <div className="header1">
	                
	                
	                            <div className="row">
	                                <div className="col-md-6 col-sm-6 col-xs-12 col-md-push-3 col-sm-push-3">
	                                    <h2 className="middle">{this.state.title}</h2>
	                                </div>

	                            </div>

	                            <div className="row">
	                            			
	                                    <InputText className="field col-md-3" name="Title" field="title"/>
	                                    <InputText className="field col-md-3" name="Form UID" field="formUid"/>
		                                <InputText className="field col-md-3" name="Link UID" field="uid"/>
			                            <InputText className="field col-md-3" name="View" field="view"/>
	                                    <InputText className="field col-md-3" name="RMS Child" field="rmsChild"/>
	                                    	
	                            </div>

                             
	                       </div>

	                    </Bound>   
	                            
	                                                        
	                      <SaveToolbar handleSave={(e)=>this.handleSave(e)}>
	                      </SaveToolbar>

	                </div>
	            </div>
	        );
	    }       
}


function mapStateToProps(state) {
    return {
        plan: state.central.plans || {},
        users: state.central.users || [],
        dataDefinitions: state.central.dataDefinitions || []
    };
}

function mapDispatchToProps(dispatch) {
    return {
        ...CentralActions.modifyProps(dispatch),  
        actions: bindActionCreators({...CentralActions}, dispatch),
    };
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditDataViewView);
