import React, { Component} from 'react';
import ReactDOM from 'react-dom';
export default class Modal extends Component {

    closeAction(){
    	
        ReactDOM.unmountComponentAtNode(document.getElementById('modal'));
        document.getElementById('modal').style.display = "none";
        this.closeCallBack && this.closeCallBack();
    }
      
    openModal(view){
    	
        const modalElement = <div className="modal_body" id="modal_body">
        {view}
        <span id="modal_close" className="glyphicon glyphicon-remove-circle" onClick={()=>this.closeAction()} />
        </div>;
        
        if (document.getElementById('modal') == null){
        	var el = document.createElement("div");
        	el.id = "modal";
        	window.document.getElementsByTagName("body")[0].appendChild( el );
        }
        
        ReactDOM.render(modalElement, document.getElementById('modal'));
        document.getElementById('modal').style.display = "block";
    }

	  

}