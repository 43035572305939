import React from 'react';
import * as _ from 'lodash';

export default function HomePage(props) {
    
	    
        return <div id="userDashboard" className="text-center container">
            <h2>Welcome</h2>
            
        </div>
    
}
