import React, { useEffect, useState } from 'react';
import { FlexBreak, InputText, TagPicker } from '@opidcore/components';
import BoundCommit from '@opidcore/components/BoundCommit';
import { useData, useNavBar } from '@opidcore/hooks/WTF';
import { Link } from 'react-router-dom';

import LookupInputSelect from '@opidcore/components/LookupInputSelect';
import { AddressBlock } from '../../components';

export default function ClientEdit(props){
    const nav = useNavBar("Loading Prospect...", [], {menu: ["prospects"]});
    const clientId = props.match.params.id;
    
    const [data, dataSet] = useData("customer", parseInt(clientId));
    const [estimateId, setEstimateId] = useState(1);
    useEffect(()=>{
        if(data != undefined){
            const friendlyId = data.friendlyId;
            const links = [
               <Link to={"/ui/prospect/" + friendlyId}>Prospect</Link>,
               <Link to={"/ui/clients/sites/" + friendlyId}>Sites</Link>,
               <Link to={"/ui/prospect/estimate/" + estimateId}>Estimate</Link>
               ];
            
            nav.update("Prospect: " + data.name, links);
        }
    }, [data, estimateId]);
              
    return <div key="edit_customer" className="basicForm">
        <BoundCommit initialValue={data} commit={APP.central.Prospect.saveChanges}>
        	<TagPicker/>
            <InputText field="name" name="Name"/>
            	
            <LookupInputSelect what="lookup:prospectStatus"  field="status" name="Status" showEmpty={true}/>
            <LookupInputSelect what="lookup" field="clientGroup" name="Client Group"/>
            <LookupInputSelect what="lookup" field="accountingGroup" name="Accounting Group"/>
		
            <AddressBlock className="field full" mapFields={["billingAddress1","billingCity","billingProvince"]}>
                <InputText field="billingAddress1" name="Address Line 1"/>
                <InputText field="billingAddress2" name="Address Line 2"/>
                <InputText field="billingCity" name="City"/>
                <InputText field="billingProvince" name="Province"/>
                <InputText field="billingPostal" name="Postal Code"/>
            </AddressBlock>
                
            <h3>Prospect Management</h3>
            
            <div className="full field">
            	<label>Last Contact</label>
            	<div className="value"><em>TODO This will be driven from the activity tab</em></div>
            </div>
            
            <LookupInputSelect what="lookup" field="industry" name="Industry"/>
            <LookupInputSelect what="lookup" field="industryDensity" name="Estimated Density"/>
            <FlexBreak/>			
            <FlexBreak/>
            

        </BoundCommit>
    </div>;
}