import React, { useEffect, useState } from 'react';

import * as _ from 'lodash';

function Tag(props){
	return <div className={"tag tag-" + props.tag}>
		<div className="hashy">#</div>
		<div className="content">{props.tag}</div>
		<div className="remove">x</div>
	</div>
}

export default function(props){
	//@ian disabled for now
	return null;
	
	const tags = ["needs-review", "pilot-marketing"];
	
	return <div className="tag-selector">
		{tags.map((t)=>{
			return <Tag key={t} tag={t}/>
		})}
	</div>
}