import React, { useState, useEffect, useContext, useRef }  from 'react';
//import { Data } from '@opidcore/Data.js';
import { Grid, GridItem, Bound, InputMultiCheckbox, Loading, Tabular, TabularColumn, LookupInputSelect, Searchable, TabularDataSet, InputText, Expandable, InputCheckbox } from '@opidcore/components';
import { BoundView, DataContext } from '@opidcore/components/Bound';
import { GridContext, GridHeading, GridItemGroup } from '@opidcore/components/Grid';
import File from '../../components/File';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { ActionBar, Button, Icon } from '../../../../../OpidCore/public/js/components';
import { NiceBox, NiceBoxContainer, NiceBoxSection } from '../../components/NiceBox';
import { ActivityMatch, ServiceMatch, ClientMatch, SiteMatch, SimpleValue } from './ARInvoice';
import { NiceCurrency, NiceDate } from '../Nice';
//import { SelectContract } from '../../components/JasonBlocks';
import { useData, useMagic } from '@opidcore/hooks/WTF';
import CesarFilter from '../../components/CesarFilter';
import PeriodSelect from '../../components/PeriodSelect';

const ColumnSelector = ({columns, setColumns}) =>{
    const gridContext = useContext(GridContext);
    let tempData = useRef({activeColumns: columns});

    return <div key="column-selector" className="column-selector">
        <Bound to={tempData.current} onChange={()=>setColumns(tempData.current.activeColumns)}>
            <InputMultiCheckbox field="activeColumns" optionsCollection={columns} optionKey="key">
                {(row) => <div className="tile-item">{row.label} ({row.colGroup})</div>}
            </InputMultiCheckbox>
        </Bound>
    </div>
}

export default function PayablesProcess(props){
    const params = useParams();
    const queryArgs = _.fromPairs(_.map( props.location.search.replaceAll("?","").split('&'), (a)=>{ return a.split('=') }));

    const apInvoiceId = params != null ? params.id : 0;

    const [stuff, setStuff] = useState({rows: []});
    const [columns, setColumns] = useState([]);
    const [currentFile, setCurrentFile] = useState({});
    const [visibleColumns, setVisibleColumns] = useState([]);
    const [recentPDFS, setRecentPDFS] = useState([]);
    const [gridCtx,setGridCtx] = useState(null);

    const [loaded, setLoaded] = useState(false);
    const [result, setResult] = useState({});


    const [createdThings, setCreatedThings] = useState([]);
    const [showUpload, setShowUpload] = useState(true);
    const [uploadMessage,setUploadMessage] = useState(null);
    const [refreshMessage,setRefreshMessage] = useState(null);
    const [importFailed, setImportFailed] = useState(false);
    const [importFailReason, setImportFailReason] = useState("The file you uploaded couldn't be processed.");

    const [fileMD5, setFileMD5] = useState(null)
    const [loading, setLoading] = useState(false);

    const tempMagic = useMagic({});

    const advancedOptions = useMagic({});

    useEffect( ()=>{
        if (apInvoiceId > 0){

            const _params = {};
            _params.status = queryArgs.status || "*";
            APP.central.Snowman.findLines(apInvoiceId, false, _params.status).then( (r)=>{
                if (_.size(r.result.invoice.rows) == 0 ){
                    setImportFailed(true);
                }else{
                    setImportFailed(false);
                }
                setStuff( {rows: r.result.invoice.rows });
                setColumns(r.result.invoice.columns);
                setLoaded(true);
                setShowUpload(false);
                setFileMD5(r.result.invoiceMD5)
            });
        }

        APP.central.Snowman.recentFiles().then((r)=>{
            setRecentPDFS(r.result);
        });

    }, [apInvoiceId]);

    const uploaded = (file)=>{
        setLoaded(false);
        setUploadMessage("Processing " + file.filename);

        setCurrentFile(file);
        useExistingFile(file.storageIdentifier);
        
    };


    const colGroups = {};

    const groupNames = _.compact( _.uniq( _.map( columns, "colGroup" ) ) );
    
    _.each( groupNames, (groupName)=>{
            const colDefs = _.sortBy( _.filter(columns, (c)=>c.colGroup == groupName), "index" );
            const cols = [];
            const showAll = _.size(visibleColumns) == 0;

            _.each(colDefs, (colDef)=>{
                    const showCol = showAll || _.find( visibleColumns, {key: colDef.key}) != null;

                    if (showCol){
                        cols.push(<GridItem title={colDef.key + " - " + groupName} key={colDef.key}>
                            <SimpleValue field={colDef.key}/>
                        </GridItem>);
                    }
            });
            colGroups[groupName] = cols;
        });

        const updateVisibleCols = (cols)=>{ 
                setVisibleColumns(cols);
                gridCtx.reset();
            };

            const vendorInvoiceColDefs = _.filter( visibleColumns, {colGroup: null});

            const vendorCols = _.map( vendorInvoiceColDefs, (colDef)=>{
                return <GridItem title={colDef.key} key={colDef.key}>
                            <SimpleValue field={colDef.key} format={colDef.type}/>
                        </GridItem>
            });

    //        <ColumnSelector columns={columns} setColumns={updateVisibleCols}/>

    		// Service #		Service Type	
         //   Container Size	 Start Date	Activity End Date			Reference	
          //  Ticket #	Activity Amount

    const createActivities = ()=>{
        APP.instance.createModal("ui/services/editor/0", {modal_name: "Create Activities"});
    };

    const useExistingFile = (md5)=>{
        const importOptions = _.pick(advancedOptions.to, ["filterVendorSiteReferences", "skipMatchingScreen"]);
        
        gridCtx && gridCtx.clear();
        if (md5 == undefined || md5 == null || md5 == "") md5 = fileMD5
        APP.central.File.findByStorageIdentifier( md5 ).then( (r)=>{
            const newFile = r.result;
            setCurrentFile(newFile);

            if (newFile.filename.toLowerCase().indexOf(".pdf") >= 0) {
              APP.central.Import.processThePDF( newFile, importOptions ).then( (s)=>{
                const uid = s.result.uid;


                const handleImport = (result)=>{
                    const res = result.data;

                    if (res.largeAP){
                        
                        APP.redirect("/ui/apinvoice/" + res.ap.id);
                    }
                    setResult(res);
                    setStuff( {rows: res.invoice.rows });
                    setColumns(res.invoice.columns);
                    setLoaded(true);
                    setShowUpload(false);
                    setFileMD5(res.invoiceMD5)
                    gridCtx && gridCtx.refresh();
                    if (res.invoice.rows.length == 0) {
                        setImportFailed(true)
                    }
                    APP.removeStateListener("handleSocket", handleImport);
                }
                APP.getState("handleSocket", handleImport);
                APP.central.Snowman.importVendorInvoiceFromWhatever(uid, newFile.id, importOptions);
                
            });
            } else {
              APP.central.Snowman.importVendorInvoice(newFile, importOptions).then((r) => {
                setStuff({ rows: r.result.invoice.rows });
                setColumns(r.result.invoice.columns);
                setLoaded(true);
                setShowUpload(false);
                setFileMD5(r.result.invoiceMD5);
                if (r.result.invoice.rows.length == 0) {
                  setImportFailed(true);
                }
              });
            }

        });
        
    };

    const uploadAgain = ()=>{
        setUploadMessage("");
        setShowUpload(true);
    }

    const refreshAgain = ()=>{
        setUploadMessage("...");
        setRefreshMessage("Refreshing Matches");
        setShowUpload(true);
        useExistingFile(currentFile.storageIdentifier);
    }

    const viewPDF = ()=>{
        APP.instance.createModal("ui/snowman/pdf/" + currentFile.storageIdentifier, {modal_name: "PDF: " + currentFile.filename});
    }

    const cancel = ()=>{
          APP.redirect("/ui/apinvoice/list")
    }

    const createManualAP = ()=>{
        let apId = 0;

        const promise = APP.central.Snowman.createManualAP(currentFile.id, tempMagic.magicalGet("vendor")).then( (r)=>{
            apId = r.result.ap.id;
            setLoaded(true);
            setCreatedThings(<h2>Done!</h2>);
        });
    
        promise.then( ()=>{
            APP.redirect("/ui/apinvoice/" + apId);
        });
    }

    const createFromCopy = (apToCopy) => {
        setLoading(true);
        APP.central.APInvoice.CopyAP(apToCopy.id, currentFile.id,0).then( (r)=>{
            if(r.result.id != undefined && r.result.id > 0){
                APP.redirect("/ui/apinvoice/" + r.result.id);
            }
        });
    }

    const downloadFile = () => {
		window.open("/file/download/" + currentFile.storageIdentifier +"/" + currentFile.filename);
    }

    const processSave = (complete) => {
        setCreatedThings(<h2>Processing</h2>);
        const billingPeriod = stuff.rows[0]["Billing Period"]
        let apId = 0;
        let promise = null;
        if (apInvoiceId > 0){
            apId = apInvoiceId;
            promise = APP.central.Snowman.findLines(apInvoiceId, true, "unmatched").then( (r)=>{
                setLoaded(true);
                setStuff( {rows: r.result.invoice.rows });
                setCreatedThings(<h2>Done!</h2>);
            });
        }else{
            const importOptions = _.pick(advancedOptions.to, ["filterVendorSiteReferences", "skipMatchingScreen"]);

            promise = APP.central.Snowman.importVendorInvoiceStep2(currentFile.id, importOptions, billingPeriod).then( (r)=>{
                apId = r.result.ap.id;
                setLoaded(true);
                setStuff( {rows: r.result.invoice.rows });
                setCreatedThings(<h2>Done!</h2>);
            });
        }

        promise.then( ()=>{
            if (complete){
                APP.redirect("/ui/apinvoice/" + apId);
            }else{
                APP.redirect("/ui/apinvoice/list")
            }
        });
    }

    const manualAP = ()=>{
        setImportFailed(true);
        setImportFailReason("You are manually creating this AP.");
    }
    
    const save = (complete)=>{
        const taxlessItems = _.filter(_.flatten(_.map(stuff.rows, (row)=>row.ap_line.items)), (item)=>item.taxId == null);
        
        if(taxlessItems.length > 0){
            APP.confirm(taxlessItems.length + " missing taxes. Continue?", "Warning", (confirmResult)=>{
                if(confirmResult){ 
                    processSave(complete); 
                } 
            });
        } else {
            processSave(complete);
        }
    }

    const updateBillingPeriod = () => {
        const billingPeriod = stuff.rows[0]["Billing Period"]
        APP.central.Snowman.updateBillingPeriod(billingPeriod, apInvoiceId).then((r) => {
           // console.log(r.result)
        })
    }

    if(loading == true){
        return <Loading />;
    }

    const header = <>
            <h2>Activity Matching</h2>

            <Expandable minimizedContent={"Advanced Options"} open={false}>
                
                <Bound to={advancedOptions}>
                    <InputText field="filterVendorSiteReferences" name="Site References"/>
                    <InputCheckbox field="skipMatchingScreen" name="Skip Matching Screen"/>
                </Bound>

            </Expandable>

        </>;

    if (showUpload){
       return <div className={"fancy-payables"}>
           {header}



            <div className="tiny-table">
                <File onUpload={uploaded} /*storeIn="db"*/ useExisting={true} label={refreshMessage ? refreshMessage : "Drop Invoice Here"} info={uploadMessage} bigDrop={true}/>
            </div>
        </div>
    } 

    if (importFailed){
        return <div className={"payable-process fancy-payables"}>
            {header}

            <p>{importFailReason}</p>

            <Bound to={tempMagic}>
                <LookupInputSelect what="active_vendors" fetch={APP.central.Vendor.listActiveVendors} field="vendor" store="object" name="Vendor" showEmpty={true}/>
                
                <h3>Create New AP {currentFile.filename}</h3>
                <Button onClick={createManualAP}>Create Manual AP</Button>

                <CopyAPThing copyFn={createFromCopy} defaultVendor={0}/>
            </Bound>
     </div>
    }

    if (Array.isArray(stuff) && stuff.length == 0) return <div className={"fancy-payables"}>
        <Bound to={stuff}>
            <div className="tiny-table">
                <Bound to={null} parentArray="rows" parentArrayIdx={0} /*onChange={() => updateBillingPeriod()}*/>
                    <NiceBoxSection>
                        <NiceBoxContainer>
                                <h4>AP Invoice</h4>
                                <NiceBox label="Vendor">
                                    <Vendor/> 
                                </NiceBox>

                                <NiceBox label="Invoice">
                                    <SimpleValue field="Invoice Number"/> 
                                </NiceBox>

                                <NiceBox label="Invoice Date">
                                    <SimpleValue field="Invoice Date" format="date"/>
                                </NiceBox>

                                <NiceBox label="Invoice Total">
                                    <SimpleValue field="Invoice Total"/> 
                                </NiceBox>

                                <NiceBox label="Invoice Tax Total">
                                    <SimpleValue field="Invoice Tax Total"/> 
                                </NiceBox>

                                <NiceBox label="Account">
                                    <SimpleValue field="Account #"/> 
                                </NiceBox>
                        </NiceBoxContainer> 
                    </NiceBoxSection>
                    <PeriodSelect field="Billing Period"/>
                </Bound>
                <Button onClick={uploadAgain}>Upload Another File</Button>
                <Button onClick={refreshAgain}>Refresh Matches</Button>
                <Button onClick={viewPDF}>View PDF</Button>
                <Button onClick={()=>save(true)}>Create AP &amp; AR</Button>
                <Button onClick={()=>manualAP()}>Manual AP</Button>
                <Button onClick={()=>cancel()}>Cancel</Button>
            </div>
        </Bound>
    </div>

    return <div className={"fancy-payables"}>
        {header}
        <Bound to={stuff}>

            <div className="tiny-table">
                <Bound to={null} parentArray="rows" parentArrayIdx={0} /*onChange={() => updateBillingPeriod()}*/>
                    <NiceBoxSection>
                        <NiceBoxContainer>
                                <h4>AP Invoice</h4>
                                <NiceBox label="Vendor">
                                    <Vendor/> 
                                </NiceBox>

                                <NiceBox label="Invoice">
                                    <SimpleValue field="Invoice Number"/> 
                                </NiceBox>

                                <NiceBox label="Invoice Date">
                                    <SimpleValue field="Invoice Date" format="date"/>
                                </NiceBox>

                                <NiceBox label="Invoice Total">
                                    <SimpleValue field="Invoice Total"/> 
                                </NiceBox>

                                <NiceBox label="Invoice Tax Total">
                                    <SimpleValue field="Invoice Tax Total"/> 
                                </NiceBox>

                                <NiceBox label="Account">
                                    <SimpleValue field="Account #"/> 
                                </NiceBox>
                        </NiceBoxContainer> 
                    </NiceBoxSection>
                    <PeriodSelect field="Billing Period"/>
                </Bound>
                
                <Button onClick={uploadAgain}>Upload Another File</Button>
                <Button onClick={refreshAgain}>Refresh Matches</Button>
                <Button onClick={viewPDF}>View PDF</Button>

                { result.possibleDuplicates && result.possibleDuplicates.length > 0 ?
                <DuplicateAPDetails aps={result.possibleDuplicates}/> : null }

                <Grid registerGrid={setGridCtx} boundData="rows" scroll={true}>
                    <GridHeading start={0} span={12}><h2>Vendor Invoice Lines</h2></GridHeading>
                    <GridHeading start={13} span={4}><h2>Matches</h2></GridHeading>
                    <GridHeading start={16} span={4}><h2>Client Info</h2></GridHeading>

                    <GridItemGroup key="vendor_lines">
                        <GridItem title="Account #" key="Account #">
                                <SimpleValue field="Account #"/>
                        </GridItem>
                        <GridItem title="Site #" key="Site #">
                                <SimpleValue field="Site #"/>
                        </GridItem>
                        <GridItem title="Site Name">
                                <SimpleValue field="Vendor Site Name"/>
                        </GridItem>
                        <GridItem title="Site City">
                                <SimpleValue field="Vendor Site City"/>
                        </GridItem>
                        <GridItem title="Site Prov.">
                                <SimpleValue field="Vendor Site Province"/>
                        </GridItem>
                        <GridItem title="Service #">
                                <SimpleValue field="Service #"/>
                        </GridItem>
                        <GridItem title="Service Description">
                                <SimpleValue field="Service Description"/>
                        </GridItem>
                        <GridItem title="Service Quantity">
                                <SimpleValue field="Service Quantity"/>
                        </GridItem>
                        <GridItem title="Container Size">
                                <SimpleValue field="Service Size"/>
                        </GridItem>
                        <GridItem title="Activity Quantity">
                                <SimpleValue field="Activity Quantity"/>
                        </GridItem>
                        <GridItem title="Activity Start Date">
                                <SimpleValue field="Activity Start Date"/>
                        <GridItem title="Activity End Date">
                                <SimpleValue field="Activity End Date"/>
                        </GridItem>
                        </GridItem>
                        {/*<GridItem title="End Date">
                                <SimpleValue field="Activity End Date"/>
                        </GridItem>*/}
                        <GridItem title="Vendor Activity">
                                <SimpleValue field="Activity Description"/>
                        </GridItem>
                        <GridItem title="Activity Amount">
                                <SimpleValue field="Activity Amount" format="currency"/>
                        </GridItem>
                        <GridItem title="Vendor Activity Reference">
                                <SimpleValue field="Activity Reference"/>
                        </GridItem>
                    </GridItemGroup>

                    <GridItemGroup key="matches">
                         <GridItem title="Client" boundData="vcl">
                                <ClientMatch/>
                        </GridItem>
                        <GridItem title="Site" boundData="vcl">
                                <SiteMatch show="small"/>
                        </GridItem>
                        <GridItem title="Service" boundData="vendorContractLineMatch">
                                <ServiceMatch show="small" handleRefresh={()=>refreshAgain()} file={currentFile}/>
                        </GridItem>
                        <GridItem title="Activity" boundData="activityMatchItem">
                                <ActivityMatch show="small" file={currentFile}/>
                        </GridItem>
                    </GridItemGroup>

                    <GridItemGroup key="client_info">
                        <GridItem title="Client Name">
                            <SimpleValue field="vcl.clientName"/>
                        </GridItem>
                        <GridItem key="xxx_site_name" title="WS Site Name">
                            <SimpleValue field="vcl.site.name"/>
                        </GridItem>
                        <GridItem title="Service Type">
                            <SimpleValue field="vcl.service.shortDescription"/>
                        </GridItem>
                        <GridItem title="WS Activity">
                            <SimpleValue field="activityMatchItem.activity"/>
                        </GridItem>

                    </GridItemGroup>
                </Grid>

                <Button onClick={()=>save(true)}>Create AP &amp; AR</Button>
                <Button onClick={()=>manualAP()}>Manual AP</Button>
                <Button onClick={()=>cancel()}>Cancel</Button>
            </div>

        </Bound>
    </div>
}

export function Show({when, children}){
    if (when == false){
        return null;
    }

    return children;
}

export function Vendor2(props){
    const magic = useContext(DataContext);

    if (magic.to && magic.to.vendor){
        return magic.to.vendor.name;
    }

    return "";
}

const Vendor = () => {
	const boundMagic = useContext(DataContext);

	return <div className="vendor-info">
            <BoundView boundTo={boundMagic.getBound()} >					
                <LookupInputSelect what="active_vendors" fetch={APP.central.Vendor.listActiveVendors} field="vendor" showEmpty={true} bound={true}/>
            </BoundView>
	</div>;
}

export const DuplicateAPDetails = ({aps})=>{
    const openPreview = (e, ap) => {
        e.stopPropagation();
        APP.instance.createModal("/ui/apinvoice/" + ap.id, {modal_name: "AP #" + ap.id});
    }

    return <div className="error-block error-duplicates">
        <h2>This is a possible duplicate AP invoice!</h2>

        <Tabular data={aps}>
                <TabularColumn title="ID" data={(row)=><ChunkBlock>{row.id}</ChunkBlock>} />
                <TabularColumn title="Invoice Number" data={(row)=><ChunkBlock>{row.externalInvoiceNumber}</ChunkBlock>} />
                <TabularColumn title="Date Received" data={(row)=><ChunkBlock>{row.dateReceived}</ChunkBlock>} />
                <TabularColumn title="Invoice Date" data={(row)=><ChunkBlock>{row.invoiceDate}</ChunkBlock>} />
                <TabularColumn title="Status" data={(row)=><ChunkBlock>{row.status}</ChunkBlock>} />
                <TabularColumn title="Total" data={(row)=><ChunkBlock>{<NiceCurrency>{row.total}</NiceCurrency>}</ChunkBlock>} />
                <TabularColumn title="Vendor Account" data={(row)=><ChunkBlock>{row.externalAccountNumber}</ChunkBlock>} />
                <TabularColumn
                    data={(row) => {
                        return <span>
                            <Icon onClick={(e) => openPreview(e, row)} icon="edit" size="2x" title="Preview"/>
                        </span>;
                    }}
                />
            </Tabular>
    </div>
    
    
}

export const CopyAPThing = ({copyFn = (ap)=>{ console.log("Setup a copy function")}, defaultVendor = 0}) =>{
    const boundMagic = useContext(DataContext);
    const [APs, APsDataSet] = useData("vendorAPs" + 0, APP.central.APInvoice.copyAPListForVendor, {vendor: defaultVendor});

    useEffect(()=>{
        boundMagic.magicalState("vendor", (vendor)=>{
            if(vendor != undefined){
                APsDataSet.setQueryArgs({vendor: vendor.id});
                APsDataSet.fetch();
            }
        });
    }, [boundMagic]);

    const doCopy = (e, ap) => {
        e.stopPropagation();

        copyFn(ap);
    }

    const openPreview = (e, ap) => {
        e.stopPropagation();
        APP.instance.createModal("/ui/apinvoice/" + ap.id, {modal_name: "AP #" + ap.id});
    }

    return <div className="copy-ap">
        <h3>Copy From</h3>

        <ActionBar title="Actions">
            <Searchable ds={APsDataSet} />
        </ActionBar>

        <CesarFilter
          columns={[{ column: "status" }, { column: "externalaccountnumber" }, { column: "billingperiod" }]}
          dataSet={APsDataSet}
        />

        <div className="copy-ap-chunks">
            <TabularDataSet pageSize={15} dataSet={APsDataSet} defaultSort="id" defaultSortOrder="desc">
                <TabularColumn title="ID" data={(row)=><ChunkBlock>{row.id}</ChunkBlock>} />
                <TabularColumn title="Invoice Date" data={(row)=><ChunkBlock>{row.invoicedate}</ChunkBlock>} />
                <TabularColumn title="Status" data={(row)=><ChunkBlock>{row.status}</ChunkBlock>} />
                <TabularColumn title="Total" data={(row)=><ChunkBlock>{<NiceCurrency>{row.total}</NiceCurrency>}</ChunkBlock>} />
                <TabularColumn title="Vendor Account" data={(row)=><ChunkBlock>{row.externalaccountnumber}</ChunkBlock>} />
                <TabularColumn title="Sites" data={(row)=><ExpandingChunk row={row} countField="site_count" contentField="sites" />} />
                <TabularColumn title="Clients" data={(row)=><ExpandingChunk row={row} countField="client_count" contentField="clients" />} />
                <TabularColumn title="Billing Period" data={(row)=><ChunkBlock>{row.billingperiod}</ChunkBlock>} />
                <TabularColumn title="Service Period" data={(row)=><DateRangeChunk row={row} />} />
                <TabularColumn title="Date Received" data={(row)=><ChunkBlock><NiceDate>{row.datereceived}</NiceDate></ChunkBlock>} />
                <TabularColumn
                    data={(row) => {
                        return <span>
                            <Icon onClick={(e) => openPreview(e, row)} icon="edit" size="2x" title="Preview"/>
                            <Icon onClick={(e) => doCopy(e, row)} icon="copy" size="2x" title="Copy"/>
                        </span>;
                    }}
                />
            </TabularDataSet>
        </div>
    </div>
}

const ExpandingChunk = ({row, countField, contentField}) => {
    const originalContent = useRef(null);
    const expanded = useRef(false);
    const [content, setContent] = useState(null);

    useEffect(()=>{
        if(_.isEmpty(row)){
            originalContent.current = "-";
        } else {
            originalContent.current = <div className="default-content">
                {(row[countField] == undefined || row[countField] <= 1)? null :
                "(" + row[countField] + ") "
                }
                {row[contentField]}
            </div>;
        }

        setContent(originalContent.current);
    }, []);

    const doExpand = (e) =>{
        e.stopPropagation();

        if(expanded.current == false){
            expanded.current = true;

            if(!_.isEmpty(row)){
                const newContent = <div className="expanded-content">
                    {
                        _.map(row[contentField].split(","), (c, index) => <div key={index} className="expanded-content-chunk">{c}</div>)
                    }
                </div>;
                setContent(newContent);
            }
            
        } else {
            expanded.current = false;
            setContent(originalContent.current);
        }
    }

    const blockChildren = <span title={expanded.current == false? "Click to expand" : "Click to collapse"}>{content}</span>;

    return <ChunkBlock className="expanding-chunk" onClickEvent={(e)=>doExpand(e)}>
        {blockChildren}
    </ChunkBlock>;
}

const DateRangeChunk = ({row}) => {
    if(_.isEmpty(row)){
        return <ChunkBlock>-</ChunkBlock>;
    }
    
    return <ChunkBlock className="date-chunk">      
        <NiceDate>{row.service_period_from}</NiceDate>
        <span className="date-spacer">to</span>
        <NiceDate>{row.service_period_to}</NiceDate> 
    </ChunkBlock>
}

const ChunkBlock = ({children, camelCase = false, className = "", onClickEvent = undefined, title = undefined}) =>{
    if(children == undefined){
        return null;
    }

    return <div className={"copy-ap-chunk-block " + className} onClick={onClickEvent}>
        <span className="content">{children}</span>
    </div>
}

