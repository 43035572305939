import React, { useState, useEffect, useRef }  from 'react';
import Icon from '@opidcore/components/Icon';
// import Searchable from '@opidcore/components/Searchable';
// import ActionBar from '@opidcore/components/ActionBar';
// import { Link } from 'react-router-dom';
import { Tabular, TabularColumn } from '@opidcore/components/Tabular';
import { BoundCommit, InputText,  LookupInputSelect, InputCheckbox, Grid,  Tabbed  } from '@opidcore/components';
import { useData} from '@opidcore/hooks/WTF';
import StaffSelect from '../../components/StaffSelect';

function DataGrid(props){
	return <div> I wish I was a grid </div>
}


export default function ACL(props){
	return <Tabbed title="Access Control">
		<GroupList key="groups" title="Groups"/>
		<ResourceList key="resources" title="Resources"/>
	</Tabbed>
}

function ResourceList(props){
	const [data, dataSet] = useData("acl_resource");
	
	useEffect(()=>{		
		//console.log("hello");
	}, []);
	
	useEffect( ()=>{
		//console.log( "acl data is", data );
	} );
	
	 const onSubmit = () => {
		 	newAudit();
	 };
	 
	 const openRow = (r)=>{
		 if (r.permissions.length == 0){
			 r.permissions.push({});
		 }
		 APP.instance.createModal( <EditResource row={r}/> );
	 }
	 
	 const newRow = (r)=>{
		 APP.instance.createModal( <EditResource row={{__type:'AccessResource', name: 'New Resource'}}/> );
	 }
		  
	 // <TabularColumn data={(r)=>JSON.stringify(r)}/>
    return <div className="full-size">   	
    	<button onClick={(e)=>newRow(e)}>add new</button>
    	<Tabular data={dataSet.filter()} showRaw={true}>
    		<TabularColumn title="Resource Name" data={(r)=>{ let path = "";
    		if (r.parentResource){ 
    			let parent = data.find(d=>d.id == r.parentResource);
    		    while (parent != null){
    		    	path = parent.name + " / " + path;	 
    		    	parent = data.find(d=>d.id == parent.parentResource);
    		    }

        		//path = path + " / "; 
    		}
    		return path + r.name }}/>
    		<TabularColumn title="Type" data={(r)=>r.type}/>
    		<TabularColumn title="# of Permissions" data={(r)=>r.permissions ? r.permissions.length : 0}/>
    		<TabularColumn title="" data={ (r)=><Icon icon="pencil" type="span" onClick={()=>openRow(r)}/> }/>
    	</Tabular>
    		
    </div>;
}


function GroupList(props){
	const [data, dataSet] = useData("acl_group");	

	
	 const onSubmit = () => {
		 	newAudit();
	 };
	 
	 const openRow = (r)=>{
		 if (r.groupMembers.length == 0){
			r.groupMembers.push({});
		 }
		 APP.instance.createModal( <EditGroup row={r}/> );
	 }
	 
	 const newRow = (r)=>{
		 APP.instance.createModal( <EditGroup row={{__type:'AccessGroup', name: 'New Group'}}/> );
	 }
		  
	 // <TabularColumn data={(r)=>JSON.stringify(r)}/>
    return <div className="full-size">   	
    	<button onClick={(e)=>newRow(e)}>add new</button>
    	<Tabular data={dataSet.filter()} showRaw={true}>
    	<TabularColumn title="Group Name" data={(r)=>{ let path = "";
		if (r.parentGroup){ 
			let parent = data.find(d=>d.id == r.parentGroup);
		    while (parent != null){
		    	path = parent.name + " / " + path;	 
		    	parent = data.find(d=>d.id == parent.parentGroup);
		    }

    		//path = path + " / "; 
		}
		return path + r.name }}/>
    		<TabularColumn title="# of Members" data={(r)=>r.groupMembers.length}/>
    		<TabularColumn title="" data={ (r)=><Icon icon="pencil" type="span" onClick={()=>openRow(r)}/> }/>
    	</Tabular>
    		
    </div>;
}


function EditGroup(props){
    const [ticky, setTicky] = useState(0);
    
	const form = useRef();
	form.current = props.row;
	
	const growFunction = ()=>{
		form.current.groupMembers.push({});
		setTicky(ticky+1);
	}
	
	return <div>
		<h2>Edit ACL Group {props.row.name}</h2>
	
		<BoundCommit initialValue={form.current} commit={APP.central.EditACL.saveChanges}>
			<InputText name="Group Name" field="name"/>
			<LookupInputSelect what="acl_group" name="Parent Group" field="parentGroup"/>

		
			<h3>Members</h3>
			<Grid boundData="groupMembers" ticky={ticky}  appendable={{text: "add", add: growFunction}}>
				<StaffSelect name="User" field="user"/>
			</Grid>
		</BoundCommit>
	</div>
}
    		

function EditResource(props){
	const form = useRef();
	const [ticky, setTicky] = useState(0);
	form.current = props.row;
	
	const growFunction = ()=>{
		form.current.permissions.push({});
		setTicky(ticky+1);
	}
	
	return <div>
		<h2>Edit ACL Resource {props.row.name}</h2>
	
		<BoundCommit initialValue={form.current} commit={APP.central.EditACL.saveChanges}>
			<InputText name="Resource Name" field="name"/>
			<LookupInputSelect what="lookup:aclResourceType" name="Resource Type" field="type"/>		
			<LookupInputSelect what="acl_resource" name="Parent Resource" field="parentResource"/>
			
			<h3>Permission Rules</h3>
			<Grid boundData="permissions" ticky={ticky} appendable={{text: "add", add: growFunction}}>
				<LookupInputSelect what="acl_group" name="Group" field="aclGroup"/>
				<InputCheckbox name="Read" field="readAllowed"/>
				<InputCheckbox name="Write" field="writeAllowed"/>
			</Grid>
		</BoundCommit>

	</div>
}