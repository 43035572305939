//import './AddFriendInput.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BoundComponent from './BoundComponent';
import { DataContext } from './Bound';


export default class InputCheckbox extends BoundComponent {
	

   static propTypes = {
    update: PropTypes.func,
    property: PropTypes.string,

  };

   trueish(value){
	   if (value == "true" || value == true || value == 1)
		   return true
		   
	  return false;		   
   }
   
  static contextType = DataContext;

   
  constructor(props, context) {
	  super(props, context);

	const boundValue = this.context.magicalGet(this.props.field, this.props.defaultValue);

	  this.state = {
			  value: this.trueish(boundValue)
	  }
	  
	  if(props.value != undefined){
		  this.state.value = this.trueish(props.value);
	  }

  }

  componentDidUpdate(prevProps, prevState){
		const boundValue = this.context.magicalGet(this.props.field, null);

		if(boundValue != null && prevState.value != boundValue){
			this.setState({value: boundValue});
		}
  	}
  
  toggleCheckbox(event){
	event.stopPropagation();

	  let newValue = ! this.state.value;
  	  if(this.props.field != undefined && this.props.obj != undefined){
		  this.setState({value: newValue});
		  //this.props.update(this.props.obj, this.props.field, !this.state.value);
	  } else {
		  this.setState({value: newValue});
	  }
	  
	  if (this.props.update != null){
		if(this.props.trueValue){
			newValue = newValue? this.props.trueValue : this.props.falseValue;
		}
      	this.props.update(this.props.obj, this.props.field, newValue);
  	  }
	  
	  this.context.magicalSet(this.props.field, newValue);
  }

  render() {  

	if(this.listeningForChanges != this.context && this.context.magicalState){
		this.listeningForChanges = this.context;
		this.context.magicalState(this.props.field, (value)=>this.setState({value: value}) );
	}
	  
    var name = this.props.name;
    var inputType = "checkbox";
    if( this.props.inputType != undefined ){
    	inputType = this.props.inputType;
    }
	
    let label = (name && inputType != "hidden")? <label>{name} {this.props.glyphicon? (<span className={this.props.glyphicon} />) : ""}</label> : "";
    if(this.props.hideLabel == true){
    	label = null;
    } 
    return ( this.props.name === "Remember Me?" ? 
      <div style={{display: "flex", flexDirection: "row", justifyContent: "start", marginTop:"20px" , color: "#b0bac9", fontFamily:"Red Hat Display", fontSize:"14px", fontWeight:"500"}}> 
      	<span>Remember Me?</span>
      	<input
	        type={inputType}
	        className={"form-checkbox"}
	        onClick={(e)=>this.toggleCheckbox(e)}
      		checked={this.state.value || false}
      		disabled={this.props.disabled || (this.props.disabledText? "disabled" : "")} 
		/>
		{this.props.text}
      </div> : 
	  <div className={"field " +  (this.props.className || "col-xs-12 col-md-12") }> 
      	{ label }
      	<input
	        type={inputType}
	        className={"form-checkbox"}
	        onClick={(e)=>this.toggleCheckbox(e)}
      		checked={this.state.value || false}
      		disabled={this.props.disabled || (this.props.disabledText? "disabled" : "")}/>{this.props.text}
      </div>

    );
  }
}
