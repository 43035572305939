import React, { Fragment, useState, useEffect } from "react";
import { Loading } from "@opidcore/components";
import { PageNumber } from "@opidcore/components/Pagination";
import _ from "lodash";
import { Button } from "../../../../OpidCore/public/js/components";

export default function SimplePDFPreview({ file }) {
  const fileMD5 = file.storageIdentifier;
  const filename = file.filename;
  const [pdfInfo, setPdfInfo] = useState({ loading: true });
  const [pageNum, setPageNum] = useState(0);

  const refreshPDF = () => {
    APP.central.Import.pdfInfo2(fileMD5, pageNum).then((r) => {
      if (r.result) {
        let newPdfInfo = r.result;
        newPdfInfo.loading = false;
        setPdfInfo(newPdfInfo);
      }
    });
  };

  useEffect(() => {
    refreshPDF();
  }, [pageNum]);

  const pages = _.map(_.range(1, pdfInfo.pages + 1), (p) => {
    return <PageNumber key={p} pageNumber={p} activePage={pageNum + 1} onClick={() => setPageNum(p - 1)} />;
  });

  const downloadFile = () => {
    window.open("/file/download/" + fileMD5 + "/" + filename);
  };

  return (
    <Fragment>
      {pdfInfo.loading ? ( 
        <Loading />
      ) : (
        <div className="pdf-preview">
          <Button onClick={() => downloadFile()}>Download</Button>
          <div id="pagination">
            <span>Page:</span> {pages}
          </div>
          <div className="pdf-preview-outer">             
            <img src={pdfInfo.image} className={filename.endsWith("xlsx")?"xls-preview":"pdf-img-preview"} />
          </div>
        </div>
      )}
    </Fragment> 
  );
}
