import React, { useEffect } from 'react';
import { useData } from '@opidcore/hooks/WTF';
import { TabularColumn,  DatePicker, Bound, Button,ActionBar,  useMagic, InputText, SaveToolbar, InputDecimal, InputSelect } from "@opidcore/components";
import { NicePercent } from '../../UI/Nice';
import moment from 'moment';
import _ from 'lodash';
import { Tabular } from '@opidcore/components';
import PeriodSelect from '../../components/PeriodSelect';

const CostAvoidance = ({}) => {    
    const [data, dataSet] = useData("cost_avoidance_period_increase");
    const bound = useMagic({billingPeriod: ""});
	
    useEffect( ()=>{
    //    APP.setState("currentMenu", ["tools", "auto_pay_menu_key"]);    
    }, []);

    const newIncrease = ()=>{
        editRow({__new: true, __type: "CostAvoidancePeriodIncrease", id: 0});
    }

    const editRow = (row)=>{
        APP.instance.createModal(<EditCostAvoidance row={row}/>);
    };
    
    return <div>


        <ActionBar title="Actions">

            <Bound to={bound}>
                <PeriodSelect field="period" />
            </Bound>

            <Button onClick={()=>newIncrease()}>New Increase</Button>
        </ActionBar>

        <h4>Details</h4>
        <Tabular data={data}>
            <TabularColumn data={(row)=>row.id} title="#"/>
            <TabularColumn data={(row)=>moment(row.effectiveDate).format("YYYY-MM-DD")} title="Date"/>
            <TabularColumn data={(row)=><NicePercent>{row.increasePercent}</NicePercent>} title="Increase"/>
            <TabularColumn data={(row)=>row.increaseType} title="Increase Type"/>
            <TabularColumn data={(row)=>row.applyTo} title="Apply To"/>
            <TabularColumn data={(row)=>row.notes} title="Notes"/>
            <TabularColumn data={(row)=><Button onClick={()=>editRow(row)}>Edit</Button>} title="Actions"/>
        </Tabular>
    </div>
}

const EditCostAvoidance = ({row}) =>{
    const bound = useMagic(row);

    const doSave = ()=>{
        APP.central.Bound.saveChanges(bound.getAllDeltas()).then( (r)=>{
            alert("ok");
            debugger;
        });
    }
    return <div>
        <Bound to={bound}>
            hey
            <InputText field="id" name="ID"/>
            <DatePicker field="effectiveDate" name="Effective Date"/>
            <InputDecimal field="increasePercent" name="Increase Percent"/>
            <InputSelect field="increaseType" name="Increase Type" options={["","Annual","Specific"]}/>
            <SaveToolbar handleSave={doSave} key="savetoolbar" saveVerb={"Save"}/>
        </Bound>
    </div>;
};

export default CostAvoidance;