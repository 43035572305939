import React, { useContext, useEffect, useState } from "react";
import Icon from "@opidcore/components/Icon";
import { useData } from "@opidcore/hooks/WTF";
import { ActionBar, Bound, InputToggleSwitch, TabularColumn, TabularDataSet } from "@opidcore/components";

import SupportButton from "../components/SupportButton";
//import { NiceRelativeTime } from "./Nice";
//import { Link } from "react-router-dom";
import CesarFilter from "../components/CesarFilter";

import * as _ from "lodash";
import { Data } from "@opidcore/Data";
//import LookupStatusSelect from "@opidcore/components/LookupStatusSelect";
import { CurrentModal } from "@opidcore/components/OpidApplication";
import { CaseListTabularColumns} from "./Support/ListSupport";

// if we pass in tasks we won't fetch from server
export default function Tasks(props) {
  const model = props.model;

  if (model.__type) {
    const relatedTable = model.__type;
    const relatedId = model.id;
    const [showAllRelated, setShowAllRelated] = useState(props.showAllRelated ? props.showAllRelated : false);
    const [data, dataSet] = props.tasks
      ? [props.tasks, new Data(props.tasks)]
      : useData("task;" + relatedTable + "-" + relatedId + "-" + (showAllRelated ? "showAll" : "notShowAll"), {
          method: "fetchTasks",
          relatedEntities: props.entities,
          visibility: props.visibility,
          relatedTable: relatedTable,
          relatedId: relatedId,
          showAllRelated: showAllRelated,
        });
    const [count, setCount] = useState(0); //total tasks new or in progress
    const [count2, setCount2] = useState(0); //total tasks complete

    const modalName = "Cases: " + relatedTable + " - " + (model.name ? model.name : model.friendlyId ? model.friendlyId : model.id);

    const refreshData = () => {
      APP.central.Util.clearCache(dataSet.uid).then(() => {
        dataSet.fetch();
      });
    };


    useEffect(() => {
      let partition = _.partition(data, (task) => {
        return task.status == "new" || task.status == "in_progress";
      });
      setCount2(partition[1].length);
      setCount(partition[0].length);     
    }, [data]);

    const doThing = (data) => {
      APP.instance.createModal(
        <TasksModal dataSet={dataSet} model={model} setShowAllRelated={setShowAllRelated} />, 
        { modal_name: modalName }, { afterClose: () => refreshData() }
      );
    };

    return (
      <div className="fa-2x" title={"Open Cases for " + relatedTable + " - " + (model.name ? model.name : model.friendlyId ? model.friendlyId : model.id)}>
        <span className="fa-layers fa-fw">
          <Icon fType="fal" icon="ticket-alt" size="2x" onClick={() => doThing(data)} type="span" className="clickable-icon" />
          {count || count2 ? (
            <span className="fa-layers-counter" style={count > 0 ? { background: "Tomato" } : { background: "Gray" }}>
              <strong>{count > 0 ? count : count2}</strong>
            </span>
          ) : null}
        </span>
      </div>
    );
  } else return null;
}

export function TasksModal(props) {
  const dataSet = props.dataSet;
  const modal = useContext(CurrentModal);
  const [toggleState, setToggleState] = useState({ value: false });

  const openTask = (task) => {
    APP.instance.createModal("/ui/support/case/" + task.id, {modal_name: "Case #" + task.id }, { afterClose: () => refreshStuff()});
    APP.instance.closeModal(modal.id);  
  };

  const doOnChange = (val) => {
    props.setShowAllRelated(val);
  };

  const refreshStuff = () => {
    APP.central.Util.clearCache(dataSet.uid).then(() => {
      dataSet.fetch();
    });
  };

  return (
    <div>
      <h3>Cases</h3>
      <ActionBar style={{ alignItems: "unset" }}>
        <CesarFilter
          columns={[
            { column: "id", heading: "Case #" },
            { column: "createdBy", heading: "Created By" },
            { column: "staff", heading: "Assigned To" },
            { column: "regionalAccountManager", heading: "Account Manager" },
            { column: "billingAnalyst", heading: "Billing Analyst" },
            { column: "sustainabilityAnalyst", heading: "Sustainability Analyst" },
            { column: "customer", heading: "Client" },
            { column: "taskCategory", lookup: "taskCategory", heading: "Case Category" },
            { column: "relatedTable", heading: "Related" },
            { column: "status", heading: "Status", defaults: ["new", "in_progress"] },
          ]}
          dataSet={dataSet}
        /><br></br>
        <div style={{ flex: 5 }} />
        <Bound to={toggleState}>
          <InputToggleSwitch
            field="value"
            onChange={(val) => doOnChange(val)}
            name={(value) => {
              return value == undefined || value == false ? "Hiding Related" : "Showing Related";
            }}
          />
        </Bound>
        <div style={{ flex: 5 }} />
        <SupportButton related={props.model} label="New Case" refresh={refreshStuff} modalToClose={modal} />
      </ActionBar>
      <TabularDataSet dataSet={dataSet}>
        {CaseListTabularColumns}
        <TabularColumn title="Edit Case" data={(row) => <Icon onClick={() => openTask(row)} icon="pencil" />} />
      </TabularDataSet>
    </div>
  );
}
