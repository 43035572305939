import React, { Component } from 'react';

export default class MetaTalker extends Component {
    constructor(props){
        super();
        this.backdoor = false;
    }
    
    componentDidUpdate(prevProps){
        if (this.state.val == undefined){
            this.process();
        }
    }
    
    process( theRMS, action ){
        console.log("meta talker needs a processor");
    }
    
    getTheRealId(){
        let theRealId = this.props.root;
        if(this.props.reference != undefined){
            theRealId = this.props.reference;
            this.backdoor = true;
        }
        
        if(this.props._loopIndex != undefined){
            const idChunks = theRealId.split("-");
            const myType = idChunks[idChunks.length-1];
            idChunks[idChunks.length-1] = 'loop';
            idChunks.push(this.props._loopIndex);
            idChunks.push(myType);
            theRealId = idChunks.join('-');
        }
        
        return theRealId;
    }
    
    findMyNumber(id, metaType){
        //does not work for sections
        
        const myTypePositions = _.filter(APP.NDT.scopePositions, {type: metaType});

        const myIndex = _.findIndex(myTypePositions, (el)=>{
            return el.injectedBecauseOf == id;
        });
        
        return myIndex + 1;
    }
    
    render(){        
        return null;
    }
}