import React, { useEffect, useState } from "react";
import { Icon, Loading } from "@opidcore/components";
import ReasonableFilter from "./ReasonableFilter";
//import { set } from "lodash";

// model: the model to call
// method: the method to call
// setData: the function to call to set the data
// doFetchData: if true, will call the method
// setDoFetchData: the function to call to set doFetchData back to false after the data has been fetched
// additionalParams: any additional params to pass to the method

// example usage:
/*
  const [apInvoices, setApInvoices] = useState([]);
  const [fetchAPInvoices, setFetchAPInvoices] = useState(false);

  const reFetchAPInvoices = () => {
    setFetchAPInvoices(true);
  }

  return (
    <FullTextSearchable model={"APInvoice"} method={"reasonableList"} setData={setApInvoices} doFetchData={fetchAPInvoices} setDoFetchData={setFetchAPInvoices} />

    <Tabular data={apInvoices} pageSize={50} paginationLocation={"both"}>
    ...
    </Tabular>
  )
*/
export default function FullTextSearchable({
  model,
  method,
  setData,
  doFetchData = false,
  setDoFetchData = null,
  maxRows = 200,
  additionalParams = [],
  filterOptions = null,
  allowBlank = false,
}) {
  const [query, setQuery] = useState("");
  const [showReset, setShowReset] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isMaxRows, setIsMaxRows] = useState(false);
  const [filterData, setFilterData] = useState([]);

  const [loading, setLoading] = useState(false);

  // call fetchData when the searchQuery changes
  useEffect(() => {
    if (!allowBlank && searchQuery.length == 0) {
      return;
    }
    fetchData();
  }, [searchQuery]);

  // call fetchData doFetchData is true
  useEffect(() => {
    if (doFetchData) {
      setDoFetchData(false);
      fetchData();
    }
  }, [doFetchData]);

  // fetches the data
  const fetchData = () => {
    setLoading(true);
    //call based on passed in model and method
    APP.central[model][method](searchQuery, maxRows, ...additionalParams).then((r) => {
      setLoading(false);
      if (r.result.length == maxRows) {
        setIsMaxRows(true);
      } else {
        setIsMaxRows(false);
      }

      if (filterOptions) {
        setFilterData(r.result);
      } else {
        setData(r.result);
      }
    });
  };

  // resets the query
  const reset = () => {
    setQuery("");
    setShowReset(false);
  };

  // shows search when we hit Enter
  const keyUp = (e) => {
    if (e.key === "Enter") {
      search();
    }
  };

  // calls setSearch with the query
  const search = () => {
    if (allowBlank && query.trim() == searchQuery) {
      fetchData();
    }
    setSearchQuery(query.trim());
  };

  return (
    <span className="fullTextSearchable">
      <div className="fullTextSearchableSearch">
        <input type="text" value={query} onKeyUp={(e) => keyUp(e)} onChange={(e) => setQuery(e.target.value)} />
        {showReset ? <Icon icon="times-square" onClick={reset} className="clear-search" title="Clear Search" /> : null}
        {loading ? <Loading size="2x" /> : <Icon icon="search" onClick={search} title="Search" />}
      </div>
      {filterOptions ? <ReasonableFilter {...filterOptions} dataset={filterData} setFilteredDataset={setData} /> : null}
      {isMaxRows ? <span className="maxRows">Max rows returned. Please refine your search.</span> : null}
    </span>
  );
}
