import React, { useEffect, useState } from 'react'
import { Loading, Tabular, TabularColumn } from '../../../../OpidCore/public/js/components'
import { NiceRelativeTime } from '../UI/Nice'
import PropTypes from 'prop-types'

const ClientTaskViewer = (props) => {

    const [data, setData] = useState(null)

    useEffect(() => {
        APP.central.Task.getClientTasks(props.id).then((r) => {
            setData(r.result)
        })
    }, [])

    if (data == null) {
        return <div>
            <Loading />
        </div>
    } else if (data.length < 1) {
        return <div>
            No active support cases.
        </div>
    } else return <div>
        <Tabular data={data}>
            <TabularColumn title="Subject" data={(row) => row.subject} />
            <TabularColumn title="Type" data={(row) => row.taskTypeObject.name} />
            <TabularColumn title="Status" data={(row) => row.status} />
            <TabularColumn title="Created" data={(row) => <NiceRelativeTime date={row.created} />} />
        </Tabular>
    </div>
}

ClientTaskViewer.propTypes = {
    id: PropTypes.number // just the id for the client
}

export default ClientTaskViewer