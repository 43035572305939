import React, {useEffect, useContext, useState} from 'react';
import {BoundDataContext, Button, Icon} from '@opidcore/components';
import _ from 'lodash';
import { useData } from '@opidcore/hooks/WTF';

export default function AddressBlock(props){
	/** TODO this is a placeholder that at least looks like a map. 
	 *  @ian thinks leaflet on OSM like we need for deliveryhop. **/
	const magic = useContext(BoundDataContext);
	const [mapSrc, setMapSrc] = useState(null);
	const [clicked, setClicked] = useState(false);
	const [address, _setAddress] = useState("");
	const [stateProvinceData, stateProvinceDataSet] = useData("stateProvinceLookup", APP.central.Lookup.fetchForName, {name: "State Province"});
	
	const refreshMapLocation = ()=>{
		const marker = magic.magicalGet("mapMarker");
		
		if (marker != null && marker.mapLong != undefined && marker.mapLat != undefined){
			const zoom = 5;
			const zoom2 = 100000;
			const long = [ parseFloat( marker.mapLong ) * (zoom2-zoom)/zoom2, parseFloat( marker.mapLong ) * (zoom2+zoom)/zoom2 ];
			const lat = [ parseFloat( marker.mapLat ) * (zoom2-zoom)/zoom2, parseFloat( marker.mapLat ) * (zoom2+zoom)/zoom2 ];
			const layer = "cyclemap"; //opnvkarte
			let addr = "https://www.openstreetmap.org/export/embed.html?bbox=" + long[0] + "%2C" + lat[0] + "%2C" + long[1] + "%2C" + lat[1] + "&layer=" + layer + "&marker=" + marker.mapLat + "%2C" + marker.mapLong;
			setMapSrc(addr);
		}
	};

	useEffect(()=>{
		setAddress();
	},[]);
	
	useEffect( ()=>{
		if(clicked == true){
			setAddress();
		}
	}, [clicked]);

	useEffect ( ()=>{
		lookup();
	}, [address]);

	
	const setAddress = ()=>{
		const addr = ["","","", ""];
		const kids = React.Children.map(props.children, (child)=>{ return [child, child.props ? child.props.children : null] });

		_.each( _.compact(_.flatten(kids)), (child)=>{
			let idx = -1;
			_.each( props.mapFields, (f,x)=>{
				if(child.props.what != undefined && child.props.what.indexOf("State Province") > -1){
					const provinceField = magic.magicalGet(f);
					const provinceObj = _.find(stateProvinceData, {key: provinceField});

					if(provinceObj != undefined){
						addr[x] = provinceObj.label;
					} else {
						addr[x] = provinceField;
					}
				} else if (_.endsWith(child.props.field, f)){
					addr[x] = magic.magicalGet(f);
				}
			});
		});
		
		if(addr[0].length > 0){
			const newAddress = addr[0] + " " + addr[1] + ", " + addr[2]; 

			if(address != newAddress){
				_setAddress(newAddress);
			} else {
				lookup();
			}
		}
	}
	
	const lookup = ()=>{
		if(address.length > 0){
			let country = magic.magicalGet("country") || magic.magicalGet("billingCountry");
			if(country == "" || country == null){
				country = "Canada";
			}
			
			fetch("https://nominatim.openstreetmap.org/?addressdetails=1&format=json&limit=5&q=" + address ).then( (r)=>r.json() ).then( (r)=>{
				let match = null;

				if(r != null && r.length > 0){
					match = _.find(r,(m)=>m.address.country == country);

					if (match){
						magic.magicalSet("mapMarker", {label: "here", mapLat: match.lat, mapLong: match.lon});
						magic.magicalSet("mapLat", match.lat);
						magic.magicalSet("mapLong", match.lon);
						refreshMapLocation();
					}
				}

				if (match == null){
					clicked && APP.confirm("Address not found, please try again.", ()=>{ setClicked(false); });
				}
			});
		} else {
			setClicked(false);
		}		
	};
	
    return <div className={props.className + " address_block"}> 
        <div className="inputs">
            {props.children}
        </div>
        <div className="map_holder">
        	{ props.mapFields ? <Button style={{ marginLeft:"70px", marginTop:"30px", marginBotton: "10px"}} onClick={()=>{setClicked(true);}}><Icon icon="map-marked-alt" color="white"/> Lookup Address</Button> : null }
            { mapSrc ? <div className="map_element"><iframe width="100%" height="100%" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src={mapSrc}></iframe></div> : null }
        </div>
    </div>; 
}
