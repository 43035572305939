import React, { Component, PropTypes } from 'react';
import Bound from './Bound';

export default class BoundList extends Bound {

	
	 constructor(props, context) {
		    super(props, context);

		    this.state = {};
		    
		  }

	   
	update(a,b,c,d){
		var that = this;
		that.props.parentBound.handleChangeList(this.props.parentList,a,b,c,d);
	}
}
