import React, { useState, useContext, useRef }  from 'react';
import Icon from '@opidcore/components/Icon';
// import Searchable from '@opidcore/components/Searchable';
// import ActionBar from '@opidcore/components/ActionBar';
//import { Link } from 'react-router-dom';
import { Tabular, TabularColumn } from '@opidcore/components/Tabular';
import { Bound,  Button, InputText,  Grid, useMagic  } from '@opidcore/components';
import { GridItem, GridContext } from '@opidcore/components/Grid';
import { useData} from '@opidcore/hooks/WTF';
//import StaffSelect from '../../components/StaffSelect';
import SaveToolbar from '@opidcore/components/SaveToolbar';
import _ from 'lodash';

function EditLookupList(props){
	const gridContext = useContext(GridContext);
	if (props.lookups[0] == null){
		return <div>?</div>;
	}
	
	const boundId = props.lookups[0].name;
	
	const saveList = (e)=>{
		_.forEach(_.values(gridContext.grids), (gridCtx)=>{
            const deltas = gridCtx.getAllBoundDeltas();
            if(deltas.length > 0){
                APP.central.Bound.saveChanges(deltas).then(()=>{
                    gridCtx.resetAllBoundDeltas();
                })
            }
        });	
	};

	const handleCellAction = (e, whatWasClicked, gridCtx, sourceAction)=>{
		let toAction = "Create";
		const modelName = "Lookup";
		const bodyData = {};
		bodyData.defaults = {name: props.name};

		APP.central.Grid.fetchGrid( modelName, toAction, bodyData ).then((r)=>{
			if(r.result.rows[0] && r.result.rows[0][modelName]){
				gridCtx.setData(r.result.rows[0][modelName][0], gridCtx.sortedData.length);
			} else {
				console.log("Config.jsx: something went wrong adding a lookup");
			}			
		});
	}

	const gridProps = {
        key: "lookups_grid" + boundId,
        gridIdentifier: "lookups" + boundId,
        data: props.lookups,
        rowBasis: "id",
        appendable: {text: "add a lookup", add: null},
        onCellAction: handleCellAction,
		orderBy: ["name"]
    }

	return <div>
		<Grid {...gridProps}>
			<GridItem title="Key"><InputText name="Key" field="key"/></GridItem>
			<GridItem title="Label"><InputText name="Label" field="label"/></GridItem>
			<GridItem title="Param1"><InputText name="Param1" field="parameter1"/></GridItem>
			<GridItem title="Param2"><InputText name="Param2" field="parameter2"/></GridItem>
			<GridItem title="Colour"><InputText name="Colour" field="colour" inputType="color"/></GridItem>
			<GridItem title="Class Name"><InputText name="Class Name" field="className"/></GridItem>
		</Grid>	
			
		<SaveToolbar handleSave={saveList}/>
	</div>
}

function EditConfig({config}){
    const magic = useMagic({...config});

    return <div>

        <Bound to={magic}>
            <InputText field="key" name="Key"/>
            <InputText field="type" name="Type"/>
            <InputText field="value" name="Value" inputType="textarea"/>
            <SaveToolbar handleSave={()=>{APP.central.Util.saveConfig(_.omit(magic.to, '__delta', '_myMagic'))}} />
        </Bound>
    </div>
}

export default function AdminConfigs(props){
	const [groups, setGroups] = useState([]);
	const [data, dataDS] = useData('config');
	const messagesEndRef = useRef(null);

    const newListItem = ()=>{

    };

    const editConfig = (config) => {
        APP.instance.createModal(<EditConfig config={config} />, { modal_name: "Edit: " + config.key });
    };
	
	return <div>
		<Button onClick={()=>newListItem()}>New Config</Button>

		<Tabular data={data}>
            <TabularColumn data={(row)=>row.key} title="Key"/>
            <TabularColumn data={(row)=>row.type} title="Type"/>
            <TabularColumn data={(row)=>_.truncate(row.value, 100)} title="Value"/>
            <TabularColumn data={(row)=><Icon icon="edit" onClick={() => editConfig(row)} size="2x" />} title="Value"/>

        </Tabular>

		<div className="scroll-anchor"/>
	</div>;
}
