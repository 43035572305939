import React, { Component } from 'react';
import Functioner from '../../../Functioner.js';

export default class Resource extends Component{
    constructor(props) {
        super(props);
        
        this.state = {};
        
    }

  	static getEditableProps(){
        
        return [
            {niceName: "Type", prop: "type", type: "text", options: ["","style","script"] },
            {niceName: "Content", prop: "content", type: "text", multiLine: true},
            {niceName: "Language", prop: "language", type: "text", options: ["","css","javascript"] }
        ]
        
    }

	static metaInfo(thing) {
		return {inContent: false, category: "scripts" };
	}
	
	componentWillMount(){
	    this.updateScript();
	    this.updateStyle();
	}
	
  	getTagName(){
  		return "style";
  	}
  	
  	attachAsStylesheet(styleElement){
      	  _.each( document.head.getElementsByTagName( this.getTagName() ), (styleEl)=>{
              if ( styleEl.getAttribute("data-from-uid") == this.props.root){                  
                  styleElement  = styleEl;
              }
              
          });
          if (styleElement == null){
              styleElement = document.createElement( this.getTagName() );
              styleElement.setAttribute("data-from-uid", this.props.root);
              document.head.append(styleElement);
          }
          
          /*** TODO note to future self - this is a gross way to limit our css rules to just the document **/
          
          var cssPrefix = "[class*='" + this.props.NDT.root + "']";
          
          var rules = this.props.content.split('}')
          var css = rules.map((rule)=>{
        	  if (rule.length > 2){
        	  	var ruleParts = rule.split('{');
        	  	return ruleParts[0].split(',').map( c=>cssPrefix + " " + c).join(", ") 
        	  	+ "{ " + ruleParts[1] + "}";
        	  }
          }).join("");
          let prefix = this.props.NDT.announcements.cssStuff ? this.props.NDT.announcements.cssStuff : [];
          if(!prefix.includes(cssPrefix)){
              prefix.push(cssPrefix);
          }
          this.props.NDT.announce('cssStuff',prefix);
          
          styleElement.textContent = css;
  	}
  	
  	updateScript(){
  	  if(this.props.type == "script"){
            var a = new Functioner(this.props.content);

            var NDT = this.props.NDT;
            a.createModule( this.props.root, NDT );
            this.setState({mounted: true});
      }
  	}
  	
  	updateStyle(){
  	  if(this.props.type == "style"){
  	      var styleElement = null;
  	      this.attachAsStylesheet(styleElement);
  	  }
  	}
  	
  	componentDidUpdate(prevProps, prevState, snapshot){
  	  var styleElement = null;

  		if(this.props.type == "script"){
	  		if (prevProps.content != this.props.content || (this.state.mounted != true) ){
    	  		this.updateScript();
	  		}  		  
  		}
  		else if(prevProps.type == "style"){
  	        this.updateStyle();
  		}
  		
  	}


    render(){
    	return null;
    }
}