import React  from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './price-map.css';
export default function(props){



    let markers = [];

    let mapProps = {};

    if (props.markers){
        markers = props.markers.map( (m,i)=>{
            if (m[0]){
                const icon = new L.divIcon({
                    iconSize: [32,32],
                    className: m[4],
                    html: '<div class="site-price" tooltip="' + m[3] +'">' + (m[2] ? m[2] : '') + '</div>'
                   });

            return <Marker position={[parseFloat(m[0]), parseFloat(m[1])]} icon={icon}>
            <Popup>{m[2]}</Popup>
        </Marker>;
            }  
        });
    }

    if (window.PORTAL_COUNTRY == "USA"){
        mapProps = { center: [39.8283,-98.5795], zoom: 4};
    }else{
        mapProps = { center: [43.1579465,-80.4580664], zoom: 8};
    }
    
    mapProps.style = {'height': '100%'}

    return <div className="map" style={{width: '100%', height: '70vh'}}>
        <MapContainer {...mapProps} scrollWheelZoom={false}>
        <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            //aurl="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png?api_key=51b1b016-c42d-41b5-99c6-cd94eabbd507"
        />
        {markers}
        </MapContainer>
    </div>;
};