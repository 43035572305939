import React, { useRef } from "react";
import { useData, useNavBar } from "@opidcore/hooks/WTF";
import {
  Icon,
  TabularDataSet,
  TabularColumn,
  Button,
  Pagination,
  Searchable,
  ActionBar,
  BoundCommit,
  InputText,
  UnlockToEdit,
  InputToggleSwitch,
} from "@opidcore/components";
import { NiceCurrency } from "../Nice.jsx";
import PeriodSelect from "../../components/PeriodSelect";
import * as _ from "lodash";

export default function OBM(props) {
  useNavBar("OBM Tracking", []);
  const [data, dataSet] = useData("periodcommodity", { paginate: 100 });

  const editPeriodCommodity = (periodCommodity) => {
    APP.instance.createModal(<EditPeriodCommodity periodCommodity={periodCommodity} />, { modal_name: "Edit: " + periodCommodity.id });
  };

  const newPeriodCommodity = () => {
    APP.instance.createModal(<EditPeriodCommodity periodCommodity={{ __new: true, __dataUID: dataSet.dataUID, __type: "PeriodCommodity" }} />, { modal_name: "New" });
  };

  const extraHeadingRow = (
    <tr style={{ backgroundColor: "#f9f9f9", textAlignLast: "center" }}>
      <th colSpan={3} style={{ cursor: "default" }}></th>
      <th colSpan={6} style={{ cursor: "default" }}>
        Cardboard (USD / ST)
      </th>
      <th colSpan={1} style={{ cursor: "default" }}></th>
      <th colSpan={6} style={{ cursor: "default" }}>
        Cardboard (CAD / MT)
      </th>
    </tr>
  );

  return (
    <div className="full-size">
      <ActionBar title="Actions">
        <Searchable ds={dataSet} />
        <Pagination dataSet={dataSet} />
        <Button onClick={() => newPeriodCommodity()}>New</Button>
      </ActionBar>

      <div>
        <Button onClick={() => dataSet.download("xlsx")}>excel</Button>
      </div>

      {dataSet.loading ? "loading" : ""}

      <TabularDataSet dataSet={dataSet} defaultSort="servicePeriod" defaultSortOrder="desc" extraHeadingRow={extraHeadingRow}>
        <TabularColumn data={(row) => row.id} title="#" />
        <TabularColumn data={(row) => row.servicePeriod} title="Service Period" />
        <TabularColumn data={(row) => row.exchangeRate.toFixed(4)} title="Exchange Rate" />

        <TabularColumn data={(row) => <NiceCurrency>{row.buffaloLow}</NiceCurrency>} title="Buffalo Low" />
        <TabularColumn data={(row) => <NiceCurrency>{row.buffaloHigh}</NiceCurrency>} title="Buffalo High" />
        <TabularColumn data={(row) => <NiceCurrency>{row.chicagoLow}</NiceCurrency>} title="Chicago Low" />
        <TabularColumn data={(row) => <NiceCurrency>{row.chicagoHigh}</NiceCurrency>} title="Chicago High" />
        <TabularColumn data={(row) => <NiceCurrency>{row.pacificNwLow}</NiceCurrency>} title="Pacific NW Low" />
        <TabularColumn data={(row) => <NiceCurrency>{row.pacificNwHigh}</NiceCurrency>} title="Pacific NW High" />

        <TabularColumn data={(row) => <div className="somethingstrange" />} title=" " />

        <TabularColumn data={(row) => <NiceCurrency>{row.buffaloLowInCADMT}</NiceCurrency>} title="Buffalo Low" />
        <TabularColumn data={(row) => <NiceCurrency>{row.buffaloHighInCADMT}</NiceCurrency>} title="Buffalo High" />
        <TabularColumn data={(row) => <NiceCurrency>{row.chicagoLowInCADMT}</NiceCurrency>} title="Chicago Low" />
        <TabularColumn data={(row) => <NiceCurrency>{row.chicagoHighInCADMT}</NiceCurrency>} title="Chicago High" />
        <TabularColumn data={(row) => <NiceCurrency>{row.pacificNwLowInCADMT}</NiceCurrency>} title="Pacific NW Low" />
        <TabularColumn data={(row) => <NiceCurrency>{row.pacificNwHighInCADMT}</NiceCurrency>} title="Pacific NW High" />

        <TabularColumn
          data={(row) => {
            return <Icon icon="edit" onClick={() => editPeriodCommodity(row)} size="2x" />;
          }}
        />
      </TabularDataSet>
    </div>
  );
}

function EditPeriodCommodity(props) {
  const periodCommodityRef = useRef({ ...props.periodCommodity });

  const doSave = () => {
		const data = APP.registeredBoundMagics["commodity"];
		if (data != undefined) {
			return APP.central.Bound.saveChanges(data.getAllDeltas());
		}
	}

  return (
    <div>
      <BoundCommit initialValue={periodCommodityRef.current} boundId="commodity" forceSaveProps={["periodcommodity"]} commit={doSave}>
        <UnlockToEdit>
          <InputText field="id" name="#" />
        </UnlockToEdit>
        <PeriodSelect field="servicePeriod" name="Service Period" />
        <InputText field="exchangeRate" name="Exchange Rate" inputType="decimal" />
        <InputText field="buffaloLow" name="Buffalo Low" inputType="decimal" />
        <InputText field="buffaloHigh" name="Buffalo High" inputType="decimal" />
        <InputText field="chicagoLow" name="Chicago Low" inputType="decimal" />
        <InputText field="chicagoHigh" name="Chicago High" inputType="decimal" />
        <InputText field="pacificNwLow" name="Pacific NW Low" inputType="decimal" />
        <InputText field="pacificNwHigh" name="Pacific NW High" inputType="decimal" />
      </BoundCommit>
    </div>
  );
}
