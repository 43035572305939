import React from 'react';
import Util from '@opidcore/Util';
import { ActionBar, Bound, Button, FlexBreak, InputText, LookupInputSelect, Pagination, Tabbed, TabularColumn, TabularDataSet, useMagic } from "@opidcore/components";
import { useData } from '@opidcore/hooks/WTF';
import _ from 'lodash';
import moment from 'moment';

import MapBlock from '../../components/MapBlock';

const PricingTool = ({}) => {    
    const [serviceData, serviceDataSet] = useData('service_schedule');

    const bound = useMagic({pricingQuery: {distance: 100, months: 12, type: 'Bin'}, dateFrom: moment().format("YYYY-MM-DD"), dateTo: moment().format("YYYY-MM-DD"), method: "exportRaw", paginate: 200});
    
    const [data, dataSet] = useData("pricingtool", {method: "lookupPricing"});
       
    const doGenerate = ()=>{
        const a = bound;
        const b = dataSet;
        
        dataSet.setQueryArgs({options: _.omit(a.to, '__modified', '__delta') });
        //_.pick(bound.to, ['dateFrom', 'dateTo','customer','umbrella','status', 'method', 'paginate']) 
        dataSet.fetch();
    }



    const columns = dataSet.columns ? dataSet.columns.map( (col)=><TabularColumn key={col.key} data={(row)=>row[col.key]} title={col.key}/> ) : [];
    const markers = _.map( data, (d)=>[ d.latitude, d.longitude, Util.round(d.per_lift_price,2), d.name, d.markerType ]);
    return <div>


            <ActionBar title="Actions">

				
				
			</ActionBar>


            <h2>Pricing Tool</h2>

        
            <Bound to={bound} className="">
                <div className="basicForm">
                    <InputText field="pricingQuery.postalCode" name="Postal Code"/>
                    <InputText field="pricingQuery.city" name="City"/>
                    <InputText field="pricingQuery.distance" name="Distance"/>
                    <InputText field="pricingQuery.months" name="Months Ago"/>
                    <FlexBreak />

                    <LookupInputSelect what="equipmentsize" field="pricingQuery.size" store="name" name="Size" showEmpty={true} />
                    <LookupInputSelect what="equipmenttype" field="pricingQuery.type" store="name" name="Type" showEmpty={true} />
                    <FlexBreak />

                    {/*<LookupInputSelect what="material" field="pricingQuery.material" store="name" name="Material" showEmpty={true} />*/}
                    <LookupInputSelect what="parent_materials" fetch={APP.central.Material.listParentMaterials} field="material" bound={true} showEmpty={true} />

                    <LookupInputSelect what="service_schedule" field="pricingQuery.frequency" store="frequency" name="Frequency" display="frequency" showEmpty={true}/>

                    <Button onClick={()=>doGenerate()}>Refresh</Button>
                </div>
            </Bound>

            <Tabbed inline={true}>
                <div title="Data">
                    <Pagination dataSet={dataSet}/>
                    <TabularDataSet dataSet={dataSet} noData="Select a date range">
                        {columns}
                    </TabularDataSet>
                </div>
                <div title="Map">
                    <MapBlock markers={markers}/>
                </div>
            </Tabbed>
    </div>
    /** <InputMultiCheckbox field="pricingQuery.frequency" optionsCollection={serviceData} optionKey="frequency" name="Frequency">
                        {(row) => <div className="tile-item">{row.frequency}</div>}
                    </InputMultiCheckbox> */
}

export default PricingTool;