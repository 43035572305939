import React, { Component, PropTypes } from 'react';

export default class Alert extends Component {
	
	static propTypes = {
	};

	constructor(props, context) {
		super(props, context);
	
	    this.state = {showDetails: false};
	    
	}
	  
	doAction(actionType){
		if(actionType == "closeAlert"){
			if (this.props.closeAction != null){
				this.props.closeAction();
			}else{
				alert("You need to setup a closeAction function in your view!");
			}
			this.setState({showDetails: false});
		}
	  
		if(actionType == "showDetails"){
			this.setState({showDetails: !this.state.showDetails});
		}
	}
	  
	render(){
		var detailSection = "";
			
		
		if(this.state.showDetails){
			detailSection = (<div className="alert_details">
				<p>Less Details <span id="alert_action" className="glyphicon glyphicon-menu-up" onClick={()=>this.doAction("showDetails")} /></p>
				{this.props.detailMessage}
			</div>);
		} else {
			detailSection = (<p>More Details <span id="alert_action" className="glyphicon glyphicon-menu-down" onClick={()=>this.doAction("showDetails")} /></p>);
		}
	  
		return (<div className="alert_body">
					{this.props.message}
					<span id="alert_close" className="glyphicon glyphicon-remove-circle" onClick={()=>this.doAction("closeAlert")} />
					<hr />
					{detailSection}
              </div>);
		
	}
}