import React, { useContext, useEffect, useRef, useState } from 'react';
import { DatePicker, Expandable, FlexBreak, InputText, TagPicker } from '@opidcore/components';
import BoundCommit from '@opidcore/components/BoundCommit';
import Grid, { GridContext, GridItem } from '@opidcore/components/Grid';
import { useMagic, useNavBar } from '@opidcore/hooks/WTF';

import LookupInputSelect from '@opidcore/components/LookupInputSelect';

import { CompensationSelector } from '../../components/JasonBlocks';

export default function EditEstimate(props){
    const nav = useNavBar("Loading Estimate...");
	let gridContext = useContext(GridContext);
	const estimateId = parseInt(props.match.params.id);
	const data = useMagic({});

    const [ticky, setTicky] = useState(1);
    
    const estimateData = useRef([]);
    
    /** useEffect(()=>{
        if(data != undefined){
            const links = [
               <Link to={"/ui/prospect/" + props.match.params.id}>Prospect</Link>,
               <Link to={"/ui/clients/sites/" + props.match.params.id}>Sites</Link>,
               <Link to={"/ui/prospect/estimate/" + estimateId}>Estimate</Link>
               ];
            
            nav.update("Prospect: " + data.name, links);
        }
    }, [data]); **/
    
    useEffect(()=>{
		

    	APP.central.ProspectEstimate.fetchEstimateDetails(estimateId, "").then( (r)=>{
    		estimateData.current = r.result.rows;
			data.replaceTo(r.result.rows[0].estimate);
			//debugger;
        	setTicky(Math.random());
    	});
    }, [estimateId]);
    
    
    const iWonder = ()=>{
    	
    	debugger;
    }   
    
    const doSave = ( id, changes, full)=>{
    	APP.central.ProspectEstimate.saveChanges(id, changes, full);
    	

		const deltas = gridContext.value.boundMagic.deltas.filter(d=>d.id >= 0);
		
		APP.central.ProspectEstimate.saveBoundDeltas(deltas).then((r)=>{
			gridContext.value.boundMagic.deltas = [];
		});        
    }
    
    const headers = [
    	<h2>Service</h2>,
    	<h2>Baseline</h2>
    ];
    
	const add = (e, thing, gridCtx)=>{
		//it would be nice to not need this?
		e.stopPropagation();       
        
        const bodyData = {};
        let toCreate = "site"
        let insertAt = gridCtx.sortedData.length;
        if(thing.boundAs){
            bodyData.existingRow = gridCtx.sortedData[thing.rowIndex];
            toCreate = thing.boundAs;
            insertAt = thing.rowIndex;
        }

		//don't use the row to create site and make sure it goes at the end
		if (toCreate == "site"){
			delete bodyData.existingRow;
        	insertAt = gridCtx.sortedData.length;
		}
        
        APP.central.ProspectEstimate.fetchEstimateDetails(estimateId, toCreate, bodyData).then( (r)=>{
            gridCtx.setData(r.result.rows[0], insertAt);
        });

		debugger;
		return false;
    }

	  
    const handleCellAction = (e, whatWasClicked, gridCtx)=>{
        const bodyData = {};
        bodyData.existingRow = whatWasClicked.row;
        
        if(whatWasClicked.currentBasis != undefined){
            bodyData.currentBasis = whatWasClicked.currentBasis;
        }

    	const wtf = APP.central.ProspectEstimate.fetchEstimateDetails(estimateId, whatWasClicked.boundAs, bodyData).then( (r)=>{
    		gridCtx.setData(r.result.rows[0],whatWasClicked.rowIndex);
    	});
    } 

    return <div key="edit_customer" className="basicForm">
        <BoundCommit to={data} commit={doSave}>
    		<h2>Estimate</h2>
    		<FlexBreak/>
        	<TagPicker/>
            <InputText field="name" name="Name"/>
            	
            <LookupInputSelect what="lookup:estimate"  field="status" name="Status" showEmpty={true}/>
            <InputText inputType="textarea" field="internalNotes" name="Internal Notes"/>

            <FlexBreak/>
            <Expandable minimizedContent={<h3>Setup</h3>}>
            	<h3>Setup</h3>
	            <DatePicker field="name" field="effectiveDate" name="Effective Date"/>
	            <InputText field="name" field="contractTerm" name="Contract Term (months)"/>
	            <LookupInputSelect what="lookup" field="clientType" name="Client Type" showEmpty={true}/>            	
	            <CompensationSelector name="Compensation Type"/>            
	            <LookupInputSelect what="lookup" field="changesToService" name="Changes to service type or frequency" showEmpty={true}/>
	            <LookupInputSelect what="lookup" field="priceCalculation" name="Client Price Calculation" showEmpty={true}/>
	            <LookupInputSelect what="lookup" field="priceCalculationPercent" name="%" showEmpty={true}/>
	            <LookupInputSelect what="lookup" field="showPercentOnOutput" name="Show % on client output" showEmpty={true}/>
            </Expandable>
            
            <h3>Estimate Details</h3>
            <FlexBreak/>

            <Grid data={estimateData.current} xray={false} headerTitles={headers} appendable={{text: "add a site", add: add}}  onCellAction={handleCellAction} >
        	
            <GridItem title="Site Name" boundData="site">
        		<InputText field="name"/>
        	</GridItem>
        	<GridItem title="Address" boundData="site">
        		<InputText field="address"/>
        	</GridItem>
        	<GridItem title="City" boundData="site">
        		<InputText field="city"/>
        	</GridItem>

        	<GridItem key="a">   
	            <Grid boundData="estimateLines" rowBasis="id" rowBasisGroup="aservice" isSubGrid={true} appendable={{text: "add service", add: add}}>
	                <GridItem title="Material">
	                    {/*<LookupInputSelect what="material" field="material" name="Material"/>*/}
                      <LookupInputSelect what="parent_materials" fetch={APP.central.Material.listParentMaterials} field="material" bound={true} showEmpty={true} />
	                </GridItem>
	                <GridItem title="Equipment Size">
	                    <LookupInputSelect what="equipmentsize" field="equipmentSize" name="Container Size" showEmpty={true}/>
	                </GridItem>
	                <GridItem title="Equipment Type">
	                    <LookupInputSelect what="equipmenttype" field="equipmentType" name="Container Type" showEmpty={true}/>
	                </GridItem>
	            </Grid>
	        </GridItem>

			<GridItem key="a">   
	            <Grid boundData="aestimate_line" rowBasis="id" rowBasisGroup="aservice" isSubGrid={true} appendable={{text: "add service", add: add}}>
	                <GridItem title="Material">
	                    {/*<LookupInputSelect what="material" field="material" name="Material"/>*/}
                      <LookupInputSelect what="parent_materials" fetch={APP.central.Material.listParentMaterials} field="material" bound={true} showEmpty={true} />
	                </GridItem>
	                <GridItem title="Equipment Size">
	                    <LookupInputSelect what="equipmentsize" field="equipmentSize" name="Container Size" showEmpty={true}/>
	                </GridItem>
	                <GridItem title="Equipment Type">
	                    <LookupInputSelect what="equipmenttype" field="equipmentType" name="Container Type" showEmpty={true}/>
	                </GridItem>
	            </Grid>
	        </GridItem>
	        
	        

	    </Grid>
	    <FlexBreak/>
           

        <FlexBreak/>
            

        </BoundCommit>
        
        
        
	    
    </div>;
}