import React, { Component, useEffect, useState, useContext} from 'react';
import ReactDOM from 'react-dom';
var _ = require('lodash');
import { InputText, Icon } from '@opidcore/components';
import { useData } from '@opidcore/hooks/WTF';
import { select } from 'd3';
import { DataContext } from '@opidcore/components/Bound';


const InputColour = ({className, updateFunc, field, label, defaultValue}) => {
    const bound = useContext(DataContext);
    const [colours, coloursDS] = useData("colours", APP.central.Stuff.getColours);
    const [showOther, setShowOther] = useState(false);
    const [optionsOpen, setOptionsOpen] = useState(false);

    useEffect(()=>{
        
    }, []);

    
    if (showOther){
        return <InputText className={className} inputType="color" update={updateFunc} name={label} field={field} defaultValue={defaultValue}/>;
    }
    const labelEl = <label>{label}</label>

    const style = {};

    const openOptions = ()=>{
        setOptionsOpen(true);
    };

    const select = (colour)=>{
        bound.magicalSet(field, colour);
        setOptionsOpen(false);
    };
    
    const options = optionsOpen ? _.map(colours, (colour)=>{
        return <div key={colour} className="select-option" style={{backgroundColor: colour}} onClick={()=>select(colour)}>{colour}</div>
    }) : [];

    const colour = bound.magicalGet(field) || "none";

    return  (
        <div className={"field " +  className} style={style}>
            { labelEl }
            <div className="selector">
                <div key={"selected"} className="select-option" style={{backgroundColor: colour}}  onClick={()=>openOptions(true)}><span className="hex">{colour}</span></div>
                {options}
            </div>
            <Icon icon="palette" onClick={()=>setShowOther(! showOther)}/>
        </div>
      );
}

export default InputColour;