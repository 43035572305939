import React from 'react';
import { MenuItem, SubMenu } from '@opidcore/components/Menu.jsx';

import EditVendor from './EditVendor.jsx';
import EditVendorContract from './EditVendorContract.jsx';
import ListVendors from './ListVendors.jsx';
import VendorContactList from './VendorContactList';
import VendorContractList from './VendorContractList.jsx';

export default function initialize(permissions, options) {
  window.APP.customRoutes.push({ path: "/ui/vendors/list", component: ListVendors, key: "vendors_list" });

  if (permissions.w || true) {
    window.APP.customRoutes.push({ path: "/ui/vendors/edit/:id", component: EditVendor, key: "vendors_edit" });
    window.APP.customRoutes.push({ path: "/ui/vendorcontract/edit/:id", component: EditVendorContract, key: "vendors_edit_contract" });
    window.APP.customRoutes.push({ path: "/ui/vendorcontactlist", component: VendorContactList, key: "vendor_contacts_list" });
    window.APP.customRoutes.push({ path: "/ui/vendorcontract", component: VendorContractList, key: "vendor_contracts_list" });
  }

  APP.addMenu(() => {
    const items = [];
    //items.push(<MenuItem key="vendors" menuKey="vendors" linkTo="/ui/vendors/list" icon="truck-container" text="Vendors"/>);
    items.push(<SubMenu key="vendors">
      <MenuItem key="vendors" menuKey="vendors" linkTo="/ui/vendors/list" icon="truck-container" text="Vendors" />
      <MenuItem key="contacts" menuKey="vendorContacts" className="nav-sub-menu" linkTo="/ui/vendorcontactlist" icon="id-card" text="Contacts" />
      <MenuItem key="contracts" menuKey="vendorContracts" className="nav-sub-menu" linkTo="/ui/vendorcontract" icon="file-contract" text="Contracts" />
    </SubMenu>);


    return items;
  });
}