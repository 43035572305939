import React from 'react';
import { Bound, TabularDataSet, TabularColumn, Kanban, InputMultiCheckbox, Searchable, InputSelect, Loading, Tabular, InputText, Button } from '@opidcore/components';
//import MultiOptionToggle from "../../components/MultiOptionToggle";
import { useData, useNavBar, useMagic } from "@opidcore/hooks/WTF";
//import { Icon } from '../../../../../OpidCore/public/js/components';
//import { Link } from 'react-router-dom';
import _ from 'lodash';
//import PeriodSelect from '../../components/PeriodSelect';
import moment from 'moment';
//import { Util } from '@opidcore/Util';
//import CesarFilter from '../../components/CesarFilter';
//import { NicePercent } from '../Nice';
import SimpleMonthPicker from '../../components/SimpleMonthPicker';

export default function APOverview () {

    useNavBar("AP Overview", []);

    const bound = useMagic({customerName: "Revera", period: moment().format("YYYY-MM")})
    const [data, dataSet] = useData("APOverview", APP.central.APInvoice.getAPOverview, bound);
    {/* const [rows, setRows] = useState(null); */}

    /*

    useEffect(() => { 
        sendQuery("Revera");
    }, [])

    */

    const sendQuery = (customerName) => {
        bound.magicalSet("customerName", customerName)
        dataSet.fetch();
    }

    if (dataSet.loading) {
        return <div>
            <Loading/>
        </div>
    }

    else return <div>
        <Bound to={bound.to}>
            <SimpleMonthPicker field="period"/>
            {/* <InputText field="customerName" name="Customer Name" /> */}
        </Bound>
        {/* <Button onClick={() => sendQuery()}>Send Query</Button> */}   
        
        <Button onClick={() => sendQuery("Revera")}>Revera</Button>
        <Button onClick={() => sendQuery("Bentall")}>BGO</Button>
        <Button onClick={() => sendQuery("Boardwalk")}>Boardwalk</Button>
        
        {/* <NicePercent>{rows.percent}</NicePercent> of returned services are complete ({rows.complete} / {rows.total}). */}
        <Searchable ds={dataSet} />
        <TabularDataSet dataSet={dataSet}>
            <TabularColumn title="Accounting Group" data={(row) => row.accounting_group} />
            <TabularColumn title="AP Invoice ID" data={(row) => row.ap_invoice_id} />
            <TabularColumn title="Client Type" data={(row) => row.client_type} />
            <TabularColumn title="Compensation Type" data={(row) => row.compensation_type} />
            <TabularColumn title="Customer ID" data={(row) => row.customer_id} />
            {/* <TabularColumn title="Period" data={(row) => row.period_guess} /> */}
            <TabularColumn title="Sent At" data={(row) => row.sent_at} />
            <TabularColumn title="Sent Status" data={(row) => row.sent_status} />
            <TabularColumn title="Service ID" data={(row) => row.sevice_id} />
            <TabularColumn title="Service Material" data={(row) => row.service_material} />
            <TabularColumn title="Service Size" data={(row) => row.service_size} />
            <TabularColumn title="Service Type" data={(row) => row.service_type} />
            <TabularColumn title="Site" data={(row) => row.site} />
            <TabularColumn title="Site ID" data={(row) => row.site_id} />
            <TabularColumn title="Vendor" data={(row) => row.vendor} />
            <TabularColumn title="Period" data={(row) => row.actual_period} />
        </TabularDataSet>
    </div>
}