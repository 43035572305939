import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as CentralActions from '../../../../OpidCore/public/js/actions/CentralActions';

//import * as InputTypes from '../../components/InputText';
//import { InputText, Bound } from '../../../../OpidCore/public/js/components';


import * as _ from 'lodash';

class StaffListView extends Component {
	
    componentDidMount(){	
        this.props.central.User.list();
    }
    
    handleClick(){
        this.props.actions.newModel("users", {id: 0});
        this.props.history.push("/admin/user/edit/0");
    }

    render () {
     var bodyData = [];
     
     if (this.props.staff){
         bodyData = this.props.staff.map(function(person){
           
         return  (<tr key={person.id}>
              <td>
                 {person.firstName +" " + person.lastName || "Unknown"}
              </td>
              <td>
                  <Link to={"/admin/user/edit/" + person.id}>Edit</Link>
              </td>
               <td>
                  <Link to={"/admin/user/view/" + person.id}>View</Link>
              </td>
          </tr>
          );
        })
      }
     
      return (        
        <div className='container text-center'>
       
          <h1>Users</h1>
          <a className="btn btn-success" onClick={ () => {this.handleClick() }}>Add Staff</a>
          <hr />
          <table className="table">
          <tbody>
              {bodyData}
          </tbody>
          </table>
        </div>
      );
    }
  }


  function mapStateToProps(state) {
    return {
        staff: state.central.users,
    };
  }

  function mapDispatchToProps(dispatch) {
      
    return {
      ...CentralActions.modifyProps(dispatch),  
      actions: bindActionCreators({...CentralActions}, dispatch),
      
    };
  }



  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(StaffListView);