import React, { Component } from 'react';
var _ = require('lodash');

import { Bound } from '../../../OpidCore/public/js/components';


export default class Section extends Component {
	constructor(props, context) {
        super(props, context);
        
        this.state = {
        };
    }
	
    render(){
    	var section = this.props.section;
    	      
	  let className = "section-outer";
	    
	    if (section.options && section.options.className ){
	    	className = className + " " + section.options.className;
	    }
	    
    	       
        return <div key={section.uid} className={className +" section_" + section.id + " section-" + section.uid}>
        	<h2>{section.title}</h2>
        	<Bound to={this.props.boundTo}>
        		{this.props.children}
        	</Bound>
        </div>
	
		        
    }
}