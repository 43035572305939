import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavBar } from '@opidcore/hooks/WTF';
import { Tabular, TabularColumn } from '@opidcore/components/Tabular';
import ActionBar from '@opidcore/components/ActionBar';
// import Searchable from '@opidcore/components/Searchable';
import Icon from '@opidcore/components/Icon';

export default function ListInvoices(props){
	useNavBar("Receivables", [], {menu: ["ar"]})
    //const [data, dataSet] = useData("arinvoice");
	const [data, setDate] = useState([]);
	
	//<Searchable ds={dataSet} />
	return <div key="list_invoices">
	    <ActionBar title="Actions">
	        
    		<a className="btn btn-new" href="/ui/admin/xero">Xero Status</a>

    		<a className="btn btn-new" href="/ui/ar/generate">Generate Flagged Invoices</a>

	       </ActionBar>

	
	    <Tabular data={data}>
        	<TabularColumn data={(row)=>row.id} title="#"/>
            <TabularColumn data={(row)=>row.date} title="date"/>
            <TabularColumn data={(row)=>row.invoice_number} title="Invoice #"/>
            <TabularColumn data={(row)=>{ return <Link to={"/ui/arinvoice/" + row.id}><Icon icon="caret-right" size="2x"/></Link> }}/>
        </Tabular>
	</div>;
}