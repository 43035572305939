import React, {useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types'

import { DataContext } from '.';

/** 
 * @param group - the ACL Group that is required to view the children of this component
 * @param resource - the ACL Resource that is required to view the children of this component 
 * @param currentPermissions - optional permissions, will grab these from current bound context
 * @param permission - the permission requred (R, W, X)
 * @example  
  		<AccessControl access="SpecialThing">
            <h1>Secret stuff for SpecialThing Resource</h1>
		</AccessControl> 
*/
const AccessControl = ({group="", resource="", currentPermissions={unknown: true, r: false, w: false, x: false}, permission="", children})=>{
    const [actualPermissions, setActualPermissions] = useState(currentPermissions);
    const myMagic = useContext(DataContext);

    useEffect( ()=>{
        if (myMagic.permissions && _.isObject(myMagic.permissions)){
            if (myMagic.permissions.unknown != true){
                setActualPermissions(myMagic.permissions);
            }
        }
    }, [myMagic]);

    if (resource > ""){
        if (APP.getWebSession().access( resource ) == false){
            return null;
        }
    }

    if (group > ""){
        if (APP.getWebSession().group( group ) == false){
            return null;
        }
    }

    // use actualPermissions.unknown for now
    if (permission && permission.length > 0 && actualPermissions.unknown != true){
        if (permission.toUpperCase() == "W" && actualPermissions.w == false){
            return null;
        }
        if (permission.toUpperCase() == "R" && actualPermissions.r == false){
            return null;
        }
        if (permission.toUpperCase() == "X" && actualPermissions.x == false){
            return null;
        }
    }

    return children;
}


AccessControl.propTypes = {
    requires: PropTypes.string,
    access: PropTypes.string,
    children: PropTypes.any
};

export default AccessControl;