
import React, { useState } from 'react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import { InputText } from '.';

export default function InputPercent(props){
	const [decimalPlaces, setDecimalPlaces] = useState( props.places != undefined ? props.places : 2 ); 

	const _places = props.places != undefined ? props.places : 2;

	let numFormat = new Intl.NumberFormat('en-ca', { style: 'decimal', minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces });

	const formatter = {
		parse(a,b){
			a =  parseFloat(a.replaceAll("\D",""));
			
			return a;
		},

		format(a,b){
			if (a.split){
				const decimalPlacesTyped = _.size(a.split(".")[1]);
				if (props.maxPlaces && decimalPlacesTyped <= props.maxPlaces){
					setDecimalPlaces(decimalPlacesTyped);
					numFormat = new Intl.NumberFormat('en-ca', { style: 'decimal', minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces });
				}			
			}

			a = numFormat.format(a);

			if(props.readOnly){
				a += "%";
			}
			
			return a;
		}
	};

	return <InputText {...props} doBlur={true} formatter={formatter} className={ (props.className || "") + " decimal"} />
}