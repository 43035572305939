import React, { Fragment, useEffect, useRef, useState } from "react";
import { Tabular, TabularColumn } from "@opidcore/components/Tabular";
import * as _ from "lodash";
import moment from "moment";
import { Bound, Button, Icon, Searchable } from "@opidcore/components";
import { Link } from "react-router-dom";
import CesarFilter from "../../components/CesarFilter";
//import { Data } from "@opidcore/Data";
import { useData } from "@opidcore/hooks/WTF";
import { CreateNewVendorContract } from "../../components/ContractBrowser";
import { CreateNewContract } from "../../UI/Customer/ClientContractList";
import MultiOptionToggle from "../../components/MultiOptionToggle";

export default function ContractExpiryManagement(props) {
  const [data, dataset] = useData("contracts;", { method: "contractExpiryManagementList" });
  const [sortedData, setSortedData] = useState([]); // data used in ContractExpiryTimeline
  const [monthWidthScrollData, setMonthWidthScrollData] = useState({ monthWidth: 80, scrollDest: 0 }); // width in pixels of a single month, scrollDest for scrolling back to same place
  const [newest, setNewest] = useState(moment()); // moment of oldest effective_from
  const [oldest, setOldest] = useState(moment()); // moment of newest effective_to

  const contractExpiryContainerRef = useRef(null); // for ContractExpiryTimeline container element

  const ZOOM_LEVELS = [20, 40, 80, 160, 240, 320]; // available zoom levels as possible monthWidth values

  const contractTypeToggle = {type: "client_contract"};

  useEffect(() => {
    dataset.callback(setPageData);
    updateTypeToggle();
  }, []);

  // when monthWidth is changed, scroll back to where we were
  useEffect(() => {
    doScrollTo(monthWidthScrollData.scrollDest);
  }, [monthWidthScrollData]);

  // sets newest, oldest and sortedData for use in ContractExpiryTimeline
  const setPageData = (callbackData) => {
    if (callbackData.length > 0) {
      let tempOldest = null;
      let tempNewest = null;
      _.forEach(callbackData, (row, index) => {
        if (row.effective_from && row.effective_to) {
          tempOldest = moment(row.effective_from);
          tempNewest = moment(row.effective_to);
          return false;
        }
      });
      _.forEach(callbackData, (row, index) => {
        if (row.effective_from && moment(row.effective_from).isBefore(tempOldest)) {
          tempOldest = moment(row.effective_from);
        }
        if (row.effective_to && moment(row.effective_to).isAfter(tempNewest)) {
          tempNewest = moment(row.effective_to);
        }
      });

      setNewest(tempNewest);
      setOldest(tempOldest);
      setSortedData(callbackData);
    }
  };

  // Scrolls ContractExpiryTimeline container to scrollTo as a % (0.0 - 1.0), or to today if no scrollTo given
  const doScrollTo = (scrollTo = null) => {
    if (contractExpiryContainerRef.current && contractExpiryContainerRef.current.offsetWidth) {
      const tempWidth = contractExpiryContainerRef.current.scrollWidth - contractExpiryContainerRef.current.offsetWidth;
      const destination = _.isFinite(scrollTo)
        ? tempWidth * scrollTo
        : monthWidthScrollData.monthWidth * moment().diff(oldest, "months", true) - contractExpiryContainerRef.current.offsetWidth / 2;

      contractExpiryContainerRef.current.scrollTo({
        behavior: scrollTo ? "auto" : "smooth",
        left: destination,
      });
    }
  };

  //TEST
  const gimmieStates = () => {
    // console.log("HEY CESAR HERES SOME STUFF:");
    // console.log("data:", data);
    // console.log("dataset:", dataset);
    // console.log("monthWidthScrollData:", monthWidthScrollData);
    // console.log("contractExpiryContainerRef.current.scrollLeft:", contractExpiryContainerRef.current.scrollLeft);
    // console.log("contractExpiryContainerRef.current.scrollWidth:", contractExpiryContainerRef.current.scrollWidth);
    // console.log("contractExpiryContainerRef.current.offsetWidth:", contractExpiryContainerRef.current.offsetWidth);
    // console.log("newest:", newest.format("YYYY-MM-DD"));
    // console.log("oldest:", oldest.format("YYYY-MM-DD"));
  };

  // scroll to today
  const doShowToday = () => {
    doScrollTo();
  };

  // Zooms ContractExpiryTimeline by changing monthWidth
  const doChangeZoom = (input) => {
    let zoomLevel = monthWidthScrollData.monthWidth;
    if (input == "+") {
      _.forEach(ZOOM_LEVELS, (zl) => {
        if (zl > zoomLevel) {
          zoomLevel = zl;
          return false;
        }
      });
    } else {
      _.forEachRight(ZOOM_LEVELS, (zl) => {
        if (zl < zoomLevel) {
          zoomLevel = zl;
          return false;
        }
      });
    }

    const tempWidth = contractExpiryContainerRef.current.scrollWidth - contractExpiryContainerRef.current.offsetWidth;
    const tempScrollTo = contractExpiryContainerRef.current.scrollLeft / tempWidth;
    if (zoomLevel != monthWidthScrollData.monthWidth) {
      setMonthWidthScrollData({
        monthWidth: zoomLevel,
        scrollDest: tempScrollTo,
      });
    }
  };

  // Calculated styles for variable components
  const overlayContainerStyle = { width: monthWidthScrollData.monthWidth * newest.diff(oldest, "months", true) };
  const clearOverlayStyle = { width: monthWidthScrollData.monthWidth * moment().diff(oldest, "months", true) };
  const overlayLineStyle = { width: _.max([monthWidthScrollData.monthWidth / 30, 1]) };

  // Refreshes dataset
  const refreshDataset = () => {
    dataset.fetch();
  };

  // Opens modal to create new client contract
  const newClientContract = () => {
    APP.instance.createModal(
      <CreateNewContract />,
      {
        modal_name: "New Client Contract",
      },
      {
        afterClose: () => refreshDataset(),
      }
    );
  };

  // Opens modal to create new vendor contract
  const newVendorContract = () => {
    APP.instance.createModal(
      <CreateNewVendorContract />,
      {
        modal_name: "New Vendor Contract",
      },
      {
        afterClose: () => refreshDataset(),
      }
    );
  };

  // Updates dataset filter for contract type
  const updateTypeToggle = () => {
    if(contractTypeToggle.type == "both") {
      dataset.unfilter("type");
      dataset.reset();
      return;
    }
    dataset.unfilter("type");
    dataset.filter({ type: contractTypeToggle.type }, true);
  }

  // Returns user's fullname if user is a regional account manager with contracts, otherwise null
  const getDefaultRam = () => {
    const fullName = APP.getWebSession().fullname;
    if(dataset.filter({ regional_account_manager: fullName }, false).length > 0) {
      return fullName;
    }
    return null;
  }
  const defaultRam = getDefaultRam();

  return (
    <Fragment>
      <div>
        <h2>Contract Expiry Management</h2>
        <div className="contractExpiry">
          <div className="contractExpiryUtilFilterContainer">
            <CesarFilter
              columns={[
                { column: "regional_account_manager", heading: "Account Manager", default: defaultRam },
                { column: "umbrella" },
                { column: "customer_name" },
                { column: "vendor_name" },
                //{ column: "type" },
                { column: "expiry_status", lookup: "clientContractExpiryStatus" },
                { column: "friendly_id" },
                { column: "status", lookup: "clientContractStatus", default: "active" },
              ]}
              dataSet={dataset}
            />
            <div style={{ textAlign: "center" }}>
              <Bound to={contractTypeToggle} onChange={updateTypeToggle}>
                <MultiOptionToggle field="type" name="typeToggle">
                  <div data-toggle-value={"client_contract"}>Client</div>
                  <div data-toggle-value={"both"}>Both</div>
                  <div data-toggle-value={"vendor_contract"}>Vendor</div>
                </MultiOptionToggle>
              </Bound>
              <Searchable ds={dataset} />
            </div>
            <TotalContractsCount data={sortedData} />
          </div>
          <div className="contractExpiryUtilContainer">
            <Button onClick={doShowToday}>Today</Button>
            <div className="contractExpiryZoomButtonContainer">
              <div className="contractExpiryZoomPlus">
                <Icon icon="plus" size="2x" color="white" onClick={() => doChangeZoom("+")} />
              </div>
              <div className="contractExpiryZoomMinus">
                <Icon icon="minus" size="2x" color="white" onClick={() => doChangeZoom("-")} />
              </div>
            </div>
            <div className="contractExpiryUtilFilterContainer" style={{ flexBasis: "fit-content", marginLeft: "auto" }}>
              <Button onClick={newClientContract}>New Client Contract</Button>
              <Button onClick={newVendorContract}>New Vendor Contract</Button>
            </div>
          </div>
        </div>

        <div className="contractExpiry">
          <div className="contractExpiryContainer">
            <ContractExpiryInfoTable data={data} setData={setSortedData} dataset={dataset} doScrollTo={doScrollTo} afterContractClose={refreshDataset} />
          </div>
          <div ref={contractExpiryContainerRef} className="contractExpiryContainerScroll">
            <div className="contractExpiryContainerChild">
              <ContractExpiryTimeline data={sortedData} monthWidth={monthWidthScrollData.monthWidth} oldest={oldest} newest={newest} />
              <div style={overlayContainerStyle} className="contractExpiryContainerChildOverlay">
                <div style={clearOverlayStyle} className="contractExpiryContainerChildOverlayClear" />
                <div style={overlayLineStyle} className="contractExpiryContainerChildOverlayLine" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

//a display for the count of total contracts with each status
export const TotalContractsCount = (props) => {
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props]);

  const totalContracts = _.reduce(
    data,
    (acc, cur) => {
      acc[cur.expiry_status] = acc[cur.expiry_status] ? acc[cur.expiry_status] + 1 : 1;
      return acc;
    },
    {}
  );
  

  const count = _.map(totalContracts, (count, expiry_status) => {
    let expString = expiry_status;
    if (expiry_status == "current") {
      expString = "Current";
    } else if (expiry_status == "expired") {
      expString = "Expired";
    } else if (expiry_status == "expiringSoon") {
      expString = "Expiring Soon";
    } else if (expiry_status == "lost") {
      expString = "Lost";
    } else if (expiry_status == "renewed") {
      expString = "Renewed";
    } else if (expiry_status == "monthToMonth") {
      expString = "Month To Month";
    }else if (expiry_status == "autoRenewal") {
      expString = "Auto Renewal";
    }
    return (
      <div key={expiry_status}>
        <span>
          <strong>{expString + ": "}</strong>
        </span>
        <span style={{ color: "black" }}>{count}</span>
      </div>
    );
  });

  return (
    <div style={{ display: "flex", gap: "5px" }}>
      <div style={{ display: "block", color: "#004a69" }}>{count}</div>
    </div>
  );
};

// Left side Table of contracts
const ContractExpiryInfoTable = (props) => {
  const data = props.data;
  const setData = props.setData;
  const doScrollTo = props.doScrollTo;
  const afterContractClose = props.afterContractClose;

  useEffect(() => {
    doScrollTo();
  }, [data]);

  const doAfterSort = (sortedData) => {
    setData(sortedData);
  };

  const handleClick = (row, e) => {
    let linkPath = "/ui/vendorcontract/edit/";
    if (row.type == "client_contract") {
      linkPath = "/ui/clientcontract/edit/";
    }

    if (e.defaultPrevented == false) {
      // @ts-ignore
      APP.instance.createModal(linkPath + row.friendly_id, {
        modal_name: "Contract Preview",
      },
      {
        afterClose: () => afterContractClose(),
      });
    }
  };

  const getFileIcon = (row) => {
    if (!row.files) {
      return <div>-</div>;
    }
    const count = row.files.length > 0 ? row.files.length : null;
    return (
      <div className="fa-2x">
        <span className="fa-layers fa-fw">
          <Icon fType="fal" icon="file-pdf" size="2x" type="span" />
          {count ? (
            <span className="fa-layers-counter" style={{ background: "Tomato" }}>
              <strong>{count}</strong>
            </span>
          ) : null}
        </span>
      </div>
    );
  };

  const getVendorLink = (row) => {
    if (row.vendor_name) {
      return (
        <Link to={"/ui/vendors/edit/" + row.vendor_friendly_id} className="plain_link">
          {row.vendor_name}
        </Link>
      );
    } else {
      return <div>-</div>;
    }
  };

  const getClientLink = (row) => {
    if (row.customer_name) {
      return (
        <Link to={"/ui/clients/" + row.customer_friendly_id} className="plain_link">
          {row.customer_name}
        </Link>
      );
    } else {
      return <div>-</div>;
    }
  };

  const colStyle = { height: "47px", maxHeight: "47px", minHeight: "47px", lineHeight: "47px" };
  return (
    <Tabular data={data} afterSort={doAfterSort} className="contractExpiryTabular" onClick={handleClick}>
      {/*<TabularColumn title="Contract" data={(row) => <RowWithStyle content={getContractLink(row)} />} style={{ ...colStyle, width: "7em" }} />*/}
      {/*<TabularColumn title="RAM" data={(row) => <RowWithStyle content={row.regional_account_manager} />} style={{ ...colStyle, width: "6em" }} />*/}
      <TabularColumn title="Client" data={(row) => <RowWithStyle content={getClientLink(row)} />} style={{ ...colStyle, width: "6em" }} />
      <TabularColumn title="Vendor" data={(row) => <RowWithStyle content={getVendorLink(row)} />} style={{ ...colStyle, width: "7em" }} />
      <TabularColumn title="Effective To" data={(row) => <RowWithStyle content={row.effective_to} />} style={{ ...colStyle, width: "9em", maxWidth: "9em" }} />
      <TabularColumn title="Sites" data={(row) => <RowWithStyle content={row.sites.length} />} style={{ ...colStyle, width: "6em", maxWidth: "6em" }} />
      <TabularColumn title="Files" data={(row) => <RowWithStyle content={getFileIcon(row)} />} style={{ ...colStyle, width: "6em", maxWidth: "6em" }} />
    </Tabular>
  );
};

// right side Timeline Bars
const ContractExpiryTimeline = (props) => {
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props]);

  const monthWidth = props.monthWidth;
  const oldest = props.oldest;
  const newest = props.newest;
  const oldestClone = oldest.clone();
  const newestClone = newest.clone();

  //These are various differences in dates, in months. used by all.
  const oldestNewestDiff = newest.diff(oldest, "months", true);
  const oldestOldestEndOfMonthDiff = oldestClone.endOf("month").diff(oldest, "months", true);
  const newestNewestStartOfMonthDiff = newest.diff(newestClone.startOf("month"), "months", true);

  const timeLine = _.map(data, (row, i) => {

    //These are various differences in dates, in months.
    const oldestFromDiff = moment(row.effective_from).diff(oldest, "months", true);
    const effectiveToLessExpiry = moment(row.effective_to).subtract(row.expiry_period, "days");
    const ToFromDiff = effectiveToLessExpiry.diff(moment(row.effective_from), "months", true);
    const expiryPeriod = effectiveToLessExpiry
      .clone()
      .add(row.expiry_period ? row.expiry_period : 0, "days")
      .diff(effectiveToLessExpiry, "months", true);
    const todayToDiff = moment().diff(moment(row.effective_to), "months", true);
    const newestToDiff = newest.diff(moment(row.effective_to), "months", true);

    const clearStyle = { background: "#ffffff00", width: _.max([monthWidth * oldestFromDiff, 0]) };
    const greenStyle = { background: "green", width: _.max([monthWidth * ToFromDiff, 0]) };
    const orangeStyle = { background: "orange", width: _.max([monthWidth * expiryPeriod, 0]) };
    const redStyle = { background: "#ff7777", width: _.max([monthWidth * todayToDiff, 0]) };
    const cyanStyle = { background: "#00ffff", width: _.max([monthWidth * todayToDiff, 0]) };
    const grayStyle = { background: "#adadad", width: _.max([monthWidth * (todayToDiff > 0 ? 0 : newestToDiff), 0]) };

    return (
      <div
        key={row.id + "-" + i}
        className="contractExpiryBarContainer"
        title={(row.expiry_status ? "Status: " + row.expiry_status : "No Status Listed") + ", From: " + row.effective_from + ", To: " + row.effective_to}
      >
        {/*<div key={row.id + "-" + i + "a"}>{row.friendly_id}</div> {/* TEST. THIS IS FOR TESTING SORTING. WILL BREAK ALIGNMENT */}
        <ContractExpiryBar row={row} index={i} barStyle={clearStyle} />
        <ContractExpiryBar row={row} index={i} barStyle={greenStyle} />
        <ContractExpiryBar row={row} index={i} barStyle={orangeStyle} />
        {row.expiry_status === "monthToMonth" ? <ContractExpiryBar row={row} index={i} barStyle={cyanStyle} /> : <ContractExpiryBar row={row} index={i} barStyle={redStyle} />}
        {/*<ContractExpiryBar row={row} index={i} barStyle={redStyle} />*/}
        <ContractExpiryBar row={row} index={i} barStyle={grayStyle} />
      </div>
    );
  });

  // returns the month labels
  const getMonthLabels = () => {
    let labelFormat = "MMM yyyy";
    if (monthWidth > 80) {
      labelFormat = "MMMM yyyy";
    }
    if (monthWidth < 80) {
      //quarter added in code below
      labelFormat = " yyyy";
    }
    if (monthWidth < 40) {
      //quarter added in code below
      labelFormat = "'YY";
    }

    // Calculated styles for month labels
    const monthLabelsStyle = { width: monthWidth * oldestNewestDiff };
    const firstMonthStyle = {
      width: monthWidth * oldestOldestEndOfMonthDiff,
      minWidth: monthWidth * oldestOldestEndOfMonthDiff,
      maxWidth: monthWidth * oldestOldestEndOfMonthDiff,
    };
    const lastMonthStyle = {
      width: monthWidth * newestNewestStartOfMonthDiff,
      minWidth: monthWidth * newestNewestStartOfMonthDiff,
      maxWidth: monthWidth * newestNewestStartOfMonthDiff,
    };
    const betweenMonthWidth = monthWidth < 80 ? monthWidth * 3 : monthWidth;
    const betweeenMonthStyle = { width: betweenMonthWidth, minWidth: betweenMonthWidth, maxWidth: betweenMonthWidth };

    const firstMonth = (
      <div className="contractExpiryMonthLabel" style={firstMonthStyle}>
        {oldest.format(labelFormat)}
      </div>
    );

    const oldestCloneTemp = oldest.clone();
    const monthCounter = oldestCloneTemp.startOf("month");
    let lastMonthColor = {};
    let betweenMonths = [];
    for (let i = 0; i < oldestNewestDiff - 2; i++) {
      const bgColor = { background: i % 2 == 0 ? "white" : "#d3d3d387" };
      monthCounter.add(1, "month");

      let label = monthCounter.format(labelFormat);
      if (monthWidth < 20) {
        if (_.includes([0, 3, 6, 9], monthCounter.month())) {
          label = "";
          betweenMonths.push(
            <div className="contractExpiryMonthLabel" style={{ ...betweeenMonthStyle, ...bgColor }} key={i + "monthCounter"}>
              {label}
            </div>
          );
          if (i < oldestNewestDiff - 2) {
            lastMonthColor = { background: i % 2 == 0 ? "#d3d3d387" : "white" };
          }
        }
      } else if (monthWidth < 80) {
        if (_.includes([0, 3, 6, 9], monthCounter.month())) {
          label = "Q" + monthCounter.quarter() + ", " + monthCounter.format(labelFormat);
          betweenMonths.push(
            <div className="contractExpiryMonthLabel" style={{ ...betweeenMonthStyle, ...bgColor }} key={i + "monthCounter"}>
              {label}
            </div>
          );
          if (i < oldestNewestDiff - 2) {
            lastMonthColor = { background: i % 2 == 0 ? "#d3d3d387" : "white" };
          }
        }
      } else {
        betweenMonths.push(
          <div className="contractExpiryMonthLabel" style={{ ...betweeenMonthStyle, ...bgColor }} key={i + "monthCounter"}>
            {label}
          </div>
        );
        if (i < oldestNewestDiff - 2) {
          lastMonthColor = { background: i % 2 == 0 ? "#d3d3d387" : "white" };
        }
      }
    }

    const lastMonth = (
      <div className="contractExpiryMonthLabel" style={{ ...lastMonthStyle, ...lastMonthColor }}>
        {newest.format(labelFormat)}
      </div>
    );

    return (
      <div style={monthLabelsStyle} className="contractExpiryMonthLabels">
        {firstMonth}
        {betweenMonths}
        {lastMonth}
      </div>
    );
  };

  return (
    <Fragment>
      {getMonthLabels()}
      {timeLine}
    </Fragment>
  );
};

const ContractExpiryBar = ({ row, index, barStyle }) => {
  return (
    <div className="contractExpiryBar" key={row.id + "-" + index}>
      <div key={row.id + "-" + index} style={barStyle} className="contractExpiryColoredBar" />
    </div>
  );
};

const RowWithStyle = (props) => {
  return <div className="contractExpiryTabularRow">{props.content}</div>;
};
