import React, {  useEffect, useState }  from 'react';
import { Icon } from '@opidcore/components';

export default function Searchable(props){
	const [query, _setQuery] = useState('');
	const [showReset, setShowReset] = useState(false);
	
	useEffect(()=>{
		if (props.ds._query){
			reset();
		}
	},[]);
	
	const setQuery = (val)=>{
	    if(props.onChange){
	        props.onChange(val);
	    }
	    
	    _setQuery(val);
	}
	
	const search = ()=>{

		if (props.searchLocal){
			props.ds.reset();
			props.ds.filter( (r)=> {
				return JSON.stringify(r).toLowerCase().indexOf( query.toLowerCase() ) >= 0;
			}, true);
			return;
		}

		props.ds._query = query;
		props.ds.fetchSorted({"query": query});
		setShowReset(true);
	};

	const reset = ()=>{
		setQuery('');
		if (props.searchLocal){
			props.ds.reset();
			return;
		}

		delete props.ds._query;
		props.ds.fetchSorted({"query": ''});
		setShowReset(false);
	}
	
	const keyUp = (e)=>{
		if (e.key === 'Enter'){
			search();
		}
	}
	return <span className="searchable">
		<input type="text" value={query} onKeyUp={e=>keyUp(e)} onChange={e=>setQuery(e.target.value)}/> 
		{ showReset ? <Icon icon="times-square" onClick={reset} className="clear-search" title="Clear Search"/> : null}
		<Icon icon="search" onClick={search} title="Search"/>		
	</span>;
}
