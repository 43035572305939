import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as CentralActions from '../../../../OpidCore/public/js/actions/CentralActions';

//import * as InputTypes from '../../components/InputText';
import { InputText, InputSelect, Bound } from '../../../../OpidCore/public/js/components';


import * as _ from 'lodash';

class StaffEditView extends Component {
    
    handleClick(){
        this.props.central.User.save(this.props.staff);
        window.history.go(-1);
    }

    render () {
        const staff = this.props.staff
        console.log("props: ", this.props);
        
        if (staff == null && this.props.match.params.id != 0){
            this.props.central.User.list();   
            return (<div>loading...</div>);
        }

		if (this.props.match.params.id == -1){
			/** if (this.staff == null){
				this.staff = {};	
			}	
			
			staff = this.staff;	**/
		}
     
        var updateAction = this.props.central.updateModel;
        return (        
                <div className='container text-center'>
                    <h1>{(staff == undefined || staff.id == 0)? "New staff" : staff.firstName }</h1>
                    <hr />
                    <Bound to={staff} statePath={"staff{:id}"} update={updateAction} userPermissions={APP.config.myAccessPermissions}>
                        <InputText name="First Name" field="firstName"/>
                        <InputText name="Last Name" field="lastName"/>
                        <InputText name="Email" field="email"/>
                        <InputSelect name="Role" field="role" options={["","admin","data","read"]}/>
                        <InputText name="Username" field="username"/>

                        <InputText name="Password" field="passCrypt"/>
                    </Bound>
              <div>
        
              </div>
                  <a className="btn btn-success" onClick={ () => {this.handleClick()} }>Save</a>
              </div>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        staff: _.find(state.central.users, {id: parseInt(props.match.params.id)}),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        ...CentralActions.modifyProps(dispatch),  
        actions: bindActionCreators({...CentralActions}, dispatch),  
    };
}



export default connect(
        mapStateToProps,
        mapDispatchToProps
)(StaffEditView);