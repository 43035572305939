import React from 'react';

import { ChangeLogList } from '@opidcore/ChangeLog';
import { Icon } from '@opidcore/components';

const ChangeLogs = ({ model }) => {
	const open = () => {
		let modalName = "Change Log: " + model.fullName;
		APP.instance.createModal(<ChangeLogList api={APP.central.ActivityLog.listChangeLogsByModel} options={{ clazzType: model.__type, id: model.id }} />, { modal_name: modalName });
	}

	return <div className="change-logs">
		<Icon size="2x" onClick={open} icon="history" title="View Change Logs" />
	</div>
};

export default ChangeLogs;