import React, { Component } from 'react';
import templated from './Templated.jsx';

export default class Heading extends Component{
    constructor(props) {
        super(props);
        
        this.state = {};
    }
    
    static metaInfo(thing) {
		return {icon: "heading", preview: (me)=>{ return _.truncate(me.element ? me.element.innerText : "no content", 30) }, showInEditorQuickAdd: true };
	}
    
  	static getEditableProps(){
  	    let options = [{key:"", value:""}, {key: "no", value:"No"}, {key: "yes", value: "Yes"}];

  	    const currentNDTSections = _.map(_.keys(APP.NDT.scope.sections), (s)=>{
  	    	const theSection = APP.NDT.scope.sections[s];
  	    	let title = theSection.content;
  	    	title = title.replace("{{section_number}}",theSection.value); 
  	    	
  	    	return {key: s, value: title}; 
  	    });
      
        return [
            {niceName: "Size", prop: "size", type: "text", options: ["h1","h2","h3"], default: "h1"},
            {niceName: "Start New Page?", prop: "newPage", type: "checkbox" },
			{niceName: "Content", prop: "content", type: "text", default : "Heading", editInPlace: true, allowJS:true}, 
			{niceName: "Am I a Section?", prop: "isSection", type: "list", options: options, default : "no"}, 
			{niceName: "Subsection Of", prop: "subsectionOf", type: "list", options: currentNDTSections, default : "no"}, 
			{niceName: "Style", prop: "cssClass", type: "style", default : "", lookup: "css"}
        ]
        
    }
  	
  	injectNext(){
  	    let section = undefined;
  	    if(this.props.isSection == "yes"){
  	        let struct = this.props.NDT.find(this.props.root);
  	        if(struct == undefined){
  	            struct = _.find(this.props.structure, {theId: this.props.root});
  	        }
            section = {
                children: null,
          	    cid: (struct.cid || struct.uid) + "-section",
          	    component: "SectionCounter",
          	    connections: [],
          	    connectors: null,
          	    next: null,
          	    options: {subsectionOf: this.props.subsectionOf != undefined && this.props.subsectionOf.trim().length > 0? this.props.subsectionOf : null},
          	    returns: null,
          	    theId: (struct.cid || struct.uid) + "-section",
          	    //root:  (struct.cid || struct.uid) + "-section",
          	    type: "MetaTalker",
          	    uid: struct.uid + (this.props.NDT.structure.length + 1)
      	    };  	    
  	    }
  	    
  	    return section;
  	}
    
    render(){
    	const TagName = this.props.size || "h1";
    	var content = templated(this.props.content || "No Content", this, (content)=>{this.props.handleInlineChange({content: content})});
    	
    	if (this.props.mode == "edit"){
    		return <TagName  className={this.props.cssClass} onClick={this.props.onClick}>{content}</TagName>	
    	}else{
    		return <TagName className={this.props.cssClass} dangerouslySetInnerHTML={{__html: content}}/>
    	}
    }
}