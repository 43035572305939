import React, { Fragment } from "react";
import { Button, Icon, TabularColumn, TabularDataSet } from "@opidcore/components";
import { useData } from "@opidcore/hooks/WTF";

import { Link } from "react-router-dom";
import CesarFilter from "../../components/CesarFilter";

export default function PortalSustainability() {
    const userId = APP.getWebSession().userId
    const [data, dataSet] = useData("sustainabilitystatus;status", { method: "fetchStatusPortal", analyst: 20 })



    return <div>
        <CesarFilter
            columns={[
                { column: "billing_period" },
                { column: "customer_name" },
                { column: "site_name" },
                { column: "client_group" },
                { column: "billing_status", defaults: ["approved", "complete"] },
                { column: "report_status", defaults: ["data", "approved", "waiting", "pending", "complete", "voided"] }
            ]}
            dataSet={dataSet}
        />
        <TabularDataSet dataSet={dataSet} defaultSort="billing_status" defaultSortOrder="asc">
            <TabularColumn data={(row) => row.billing_period} title="Period" />
            <TabularColumn data={(row) => row.customer_name} title="Customer" />
            <TabularColumn data={(row) => row.site_name} title="Site" />
            <TabularColumn data={(row) => row.metric_tonnes_waste} title="Waste (MT)" />
            <TabularColumn data={(row) => row.metric_tonnes_diverted} title="Diverted (MT)" />
            <TabularColumn data={(row) => (dataSet.loading ? null : <Bound to={row}><LookupStatusSelect field="billing_status" lookupName="Enviro Status" disabled={true} /></Bound>)} title="Billing Status" />
            <TabularColumn data={(row) => (dataSet.loading ? null : <Bound to={row}><LookupStatusSelect field="report_status" lookupName="Enviro Status" disabled={true} /></Bound>)} title="Report Status" />
            <TabularColumn
                data={(row) => {
                    return (
                        <Fragment>
                            {"" /* this exists so trying to sort this column doesnt kill Tabular */}
                            <Link onClick={(e) => e.stopPropagation()} to={"/ui/sustainabilityedit/" + row.customer_id + "/" + row.billing_period}>
                                <Icon icon="caret-right" size="2x" />
                            </Link>
                        </Fragment>
                    );
                }}
                title="Edit"
            />

        </TabularDataSet>
        <Button onClick={() => console.log(data, dataSet)}>Button</Button>
    </div>
}