import React from 'react';
import { useData } from '@opidcore/hooks/WTF';
import { InputSelect } from '.';
import Functioner from '../Functioner.js';

//e.g. dataOptions = {"data_source":"content_thing","filters":"{component: 'DocumentTemplate'}", "option_field": "cid"}
export default function InputDataSelect(props){
	//console.log("I wonder why we use this instead of LookupInputSelect - good enough for now, but later make them the same");
    
    let options = {};
    
    if(props.dataOptions){
        const filters = props.dataOptions.filters? new Functioner(props.dataOptions.filters).doEval() : null;
        const [data, dataSet] = useData(props.dataOptions.data_source, filters);
        
        _.each(data,(d)=>options[d[props.dataOptions.option_field]] = d[props.dataOptions.option_field]);
    }
    
    return <div className="input_data_select" key={"input_data_select-" + props.field}>
        <InputSelect className={props.className}  options={options} update={props.updateFunc} name={props.label || props.name} field={props.field}/>
    </div>
}