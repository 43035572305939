import React from 'react';
import { MenuItem } from '@opidcore/components/Menu.jsx';

import ContentView from '../../../../js/Welcome/ContentView';
import WasteAudit from '../WasteAudit';
import MasterTemplateList from './MasterTemplateList';

// if we ever change the number of tabs in an audit make sure you grep for 7!
function WrappedAudit(props){
	return <WasteAudit {...props} selectedItem={7}>
			<ContentView {...props}/>
		</WasteAudit>;
}

export default function initialize(permissions, options){
	window.APP.customRoutes.push({path: "/master_template/list", component: MasterTemplateList, key: "master_template_list"});
	window.APP.customRoutes.push({path: "/contentInstance/:cid/:name?/:id?", component: WrappedAudit, key: "wrapped_audit_report"});

	// Keeping menu items disabled for now.
	if (false){
		APP.addMenu( ()=>{
			const items = [];
			items.push(<MenuItem key="audit" menuKey="audit" linkTo="/ui/audit" icon="clipboard-list-check" text="Audits"/>);
			items.push(<MenuItem key="schedule" menuKey="schedule" linkTo="/ui/schedule" icon="calendar-alt" text="Schedule"/>);
			items.push(<MenuItem key="reports" menuKey="reports" linkTo="/ui/reports" icon="file-chart-pie" text="Reports"/>);
			return items;
		});
	}
				

}