import React  from 'react';
import EditARInvoice from './EditARInvoice.jsx';
import AmandaInvoiceList from './AmandaInvoiceList.jsx';
import GenerateInvoices from './GenerateInvoices.jsx';
import InvoiceList from './InvoiceList.jsx';
// import { Link } from "react-router-dom";
// import Icon from '@opidcore/components/Icon';
import { MenuItem, SubMenu } from '@opidcore/components/Menu.jsx';

export default function initialize(permissions, options){
	if(permissions.w || permissions.r){
		window.APP.customRoutes.push({path: "/ui/arinvoice/accounting", component: AmandaInvoiceList});
		window.APP.customRoutes.push({path: "/ui/arinvoice/:id", component: EditARInvoice, permissions: permissions });
    	window.APP.customRoutes.push({ path: "/ui/arinvoice", component: InvoiceList, permissions: permissions });
		window.APP.customRoutes.push({path: "/ui/ar/generate", component: GenerateInvoices});

		APP.addMenu( ()=>{
			const items = [];
			
			items.push(<SubMenu key="ar">
				<MenuItem key="ar" menuKey="ar" linkTo="/ui/arinvoice" icon="file-invoice-dollar" text="Receivables"/>
				{permissions.w ? <MenuItem key="ar-accounting" menuKey="ar-accounting" className="nav-sub-menu" linkTo="/ui/arinvoice/accounting" icon="file-invoice-dollar" text="Accounting Process"/> : null }
			</SubMenu>);
			
			return items;
		});
	}
	
		
}