import React, { Component, PropTypes } from 'react';

export default class Delta extends Component {
  
	render(){	
		var content = ""
		
		if(this.props.value != undefined && this.props.type != undefined){
			if( this.props.type == "trend" ){
				if( !isFinite(this.props.value) || this.props.value == 0 ){
					content = <span className="delta-zero">Ø</span>;
				} else if( this.props.value > 0 ){
					content = <span className="delta-positive">▲{100*this.props.value}%</span>;
				} else if( this.props.value < 0 ){
					content = <span className="delta-negative">▼{-100*this.props.value}%</span>;
				}
			} else if( this.props.type == "difference" ){
				if( !isFinite(this.props.value) || this.props.value == 0 ){
					content = ""; //<span className="delta-zero">Ø</span>;
				} else if( this.props.value > 0 ){
					content = <span className="delta-positive">+{this.props.value}</span>;
				} else if( this.props.value < 0 ){
					content = <span className="delta-negative">{this.props.value}</span>;
				}
			}
		}
		
		return (<span className="delta_body">
				<span className="delta_body-children">{this.props.children}</span> <span className="delta_body-content">{content}</span>
              </span>);
		
	}
}