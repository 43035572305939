/**
 * Another clever approach of writing reducers:
 *
 * export default function(state = initialState, action) {
 *   const actions = {
 *      [ACTION_TYPE]: () => [action.payload.data, ...state]
 *   };
 *
 *   return (_.isFunction(actions[action.type])) ? actions[action.type]() : state
 * }
 */

import * as types from '../constants/ActionTypes';
import { assign } from 'lodash';

const initialState = {
		messages: []
};

export default function (state = initialState, action, arg1) {
	let newState = {...state};
	switch (action.type) {


	case "WS_RECEIVED_MESSAGE":
		newState["messages"] = newState["messages"].slice()
		newState["messages"].push( action.msg ) 
		
		return newState;
		
	case "WS_SEND_MESSAGE":
		newState["messages"] = newState["messages"].slice()
		newState["messages"].push( action.msg ) 
		
		return newState;

	default:
		return state;
	}
}
