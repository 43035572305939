import React, { Component } from 'react';

export default class Header extends Component{
    constructor(props) {
        super(props);
        
        this.state = {};
    }
    
    render(){
        return <div className="header">
        {this.props.children}
        </div>
    }
}