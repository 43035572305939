import React, { Component } from 'react';
import RenderMeSoftly  from '../RenderMeSoftly.jsx';

function DisableEverythingChunk(props){
    const clickEvent = (event) =>{
        
        if(props.disabled){
            alert(props.message || "Access Denied.");
            event.stopPropagation();
        }
    }
    return <span onClick={(e)=>clickEvent(e)}>
        {props.children}
    </span>
}

export default class OverrideChunk extends Component {
    constructor(props) {
        super(props);
    }
    
    static getEditableProps(){
        return [
            {niceName: "Lookup", prop: "lookup", type: "text", allowJS:true},
            {niceName: "Report Element Types", prop: "report_element_types", type: "text"}
        ];
    }
    
    fetchOverride(){

        if(this.props.lookup == undefined || this.props.lookup.trim().length <= 0 || APP.NDT.find(this.props.lookup) == undefined){
            const editor = this.props.NDT.getEditor();
            if(editor != undefined){
                const viewView = editor.props.extraActions;
                
                if(viewView != undefined){
                    const newViewView = React.cloneElement(viewView, {overrideChunkId: this.props.lookup, report_element_types: this.props.report_element_types});
                    return newViewView;
                }
            }
            
            return null;            
        } else {
            let rmsProps = {
                central: "blaha",
                scope: {...this.props.scope},
                key: this.props.lookup,
                root: this.props.lookup,
                structure: this.props.NDT.structure,
                mode: "view"
            };
                
            const rmsView = <RenderMeSoftly {...rmsProps} />;
            
            return rmsView;
        }
    }
    
    render(){
        const disabled = this.props.lookup == undefined || this.props.lookup.trim().length <= 0? true: false;
        const ui = this.fetchOverride();
        
        return <div className={"override_chunk " + (disabled == false ? "empty_override" : "") }>
            <DisableEverythingChunk message="Missing Lookup ID." disabled={disabled}>
                {ui}
            </DisableEverythingChunk>
        </div>;
    }
}