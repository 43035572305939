/**
 * Another clever approach of writing reducers:
 *
 * export default function(state = initialState, action) {
 *   const actions = {
 *      [ACTION_TYPE]: () => [action.payload.data, ...state]
 *   };
 *
 *   return (_.isFunction(actions[action.type])) ? actions[action.type]() : state
 * }
 */

import * as types from '../constants/ActionTypes';
import { assign } from 'lodash';
import _ from 'lodash';

const initialState = {
};

export default function (state = initialState, action, arg1) {
  console.log('central called', state, action);
  let newState = {...state};

  if (newState["__dirty"] == null){
	  newState["__dirty"] = {};
  }

  if ( action.type == "CENTRAL_CLEAR_STATE" ){
	  delete newState[action.path];
  }
  
  if ( action.type == "CENTRAL_EXCEPTION" ){
	  newState.errors = action.details;
  }
  
  if ( _.startsWith(action.type, "CENTRAL_API_") ){
	  
	  let type = action.type.replace("CENTRAL_API_", "");
	  let chunks = type.split("_");
	  
	  console.log(chunks);
	  if (action.response.displayMessage != null){
		  newState.displayMessage = action.response.displayMessage;
	  }
	  
	  if ( action.method && action.method.returnsToState != null ){
	 	  let statePath = action.method.returnsToState.split('.');
	 	  //this is too simple but works for now.
	 	  newState[statePath[1]] = action.response.result;
	 	  
	  }else if (action.method && action.method.appendsToState != null ){
		  
		 var stateKey = action.method.usingKey;
		 let statePath = action.method.appendsToState.split('.');
		 	  //this is too simple but works for now.
		 
		 
		 if (state[statePath[1]] != null){
			 var found = false;
			 if(Array.isArray(action.response.result)){
				 newState[statePath[1]] = _.unionBy(state[statePath[1]], action.response.result, stateKey);
			 } else {
				 newState[statePath[1]] = state[statePath[1]].map( function(i){
					 if (i[stateKey] == action.response.result[stateKey]){
						 found = true;
						 return action.response.result; 
					 }else{
						 return i;
					 }
				 }
				 ); 
				 if (! found){
					 newState[statePath[1]].push( action.response.result );
				 }	
			 }
		 }else{
			 if(Array.isArray(action.response.result)){
				 newState[statePath[1]] = action.response.result;
			 } else {
				 newState[statePath[1]] = [ action.response.result ];
			 }
		 }
		 
		 	  
	  }
	  //newState.test = action.response.result;
  }
  
  if (action.type == "CENTRAL_CLEAR_DISPLAY_MESSAGE"){
	  if (newState.displayMessage){
		  delete newState.displayMessage;
	  }
  }
  
 if (action.type == "CENTRAL_LOGGED_OUT"){
	 newState.currentUser = {};
 }
	
 if (action.type == types.UPDATE_MODEL){
    //var wasState = {...state};
    //wasState.employers[0].name = wasState.employers[0].name + "!";
    //wasState.test = 'asdf';
    //return Object.assign(...state, {test: 1});
      //console.log("asked to update", arg1);
      
      if (action.statePath){
    	  let matches = action.statePath.match("(.*){(.*)}");
    	  newState[ matches[1] ] = newState[ matches[1] ].map((m) => {
    		  if (m.id == action.model.id  || (matches[2] == "*") ){
    			  var newArgs = {};
    			  if (action.value instanceof Object && action.value._isAMomentObject){
    				  newArgs[action.attribute] = action.value.format('YYYY-MM-DD');
    			  }else{
    				  newArgs[action.attribute] = action.value;
    			  }
    	          newArgs.__updated = true;
    	          return assign({}, m, newArgs);
    		  }
    		 return m; 
    	  });
      } else {
    	  action.model[action.attribute] = action.value;
      }
      
      /**
    return {
      ...state,
      employers: state.employers.map((employer) => {
        if (employer.id == action.model.id){
          var newArgs = {};
          newArgs[action.attribute] = action.value;
          newArgs.__updated = true;
          return assign({}, employer, newArgs)
        }

        return employer;

       })
      } **/
 }
 
 if (action.type == types.NEW_MODEL){
	 newState[action.statePath] = [ ...newState[action.statePath], action.model ];
 } 
 
 if (action.type == "CENTRAL_API_Login_currentUser"){
	 if (action.response.result && action.response.result.id > 0){
		 _APP.socketSend(JSON.stringify({cmd:"i_am", person: action.response.result.id}));
	 }
 }
 if (action.type == "WS_SEND_MESSAGE"){
	newState["messages"] = {...newState["messages"]}
	if(newState["messages"][action.msg.to] != undefined){
		newState["messages"][action.msg.to] = [ action.msg, ...newState["messages"][action.msg.to] ];
	} else {
		newState["messages"][action.msg.to] = [action.msg];
	}
	
	setTimeout( _APP.socketSend(JSON.stringify(action.msg)), 75);
	
	
	//return newState;
 }
 
 if (action.type == "WS_RECEIVED_MESSAGE"){
	 
	 newState["messages"] = {...newState["messages"]}
	 
	 if (action.msg.cmd != null){
		 console.log(" ... " + action.msg.cmd);
		 if (action.msg.cmd == "who_are_you"){
			 if (newState.currentUser){
				 _APP.socketSend(JSON.stringify({cmd:"i_am", person: newState.currentUser.id}));
			 }
		 }
	 }else if (newState["messages"][action.msg.from] != null){
		 newState["messages"][action.msg.from] = [ action.msg, ...newState["messages"][action.msg.from] ];
	 }else if (action.msg.from == newState.currentUser.id){
		 if (newState["messages"][action.msg.to][0].id < 0 && newState["messages"][action.msg.to][0].message_body == action.msg.message_body){
			 newState["messages"][action.msg.to] = [...newState["messages"][action.msg.to]];
			 newState["messages"][action.msg.to][0] = action.msg;
		 }else{
			 newState["messages"][action.msg.to] = [ action.msg, ...newState["messages"][action.msg.to] ];
		 }
	 }
	 console.log("should be in state now");
		
	//return newState;
 }
 
 if( action.type == "CENTRAL_DOWNLOAD" ){
	 var a = URL.createObjectURL(new Blob([action.response], { type: 'application/pdf' }));
	 var link=document.createElement('a');
	 link.href = a;
	 link.download = action.fileName;
	 link.click();
 }
 
  return newState;
}
