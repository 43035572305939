import React, { useState, useContext, useEffect } from 'react';
import { DataContext } from '@opidcore/components/Bound';
import PropTypes from 'prop-types';


import { InputSelect, InputAutoComplete, Button } from '@opidcore/components';
import AutoComplete from './AutoComplete';
import _ from 'lodash';

export default function SelectClientSiteService(props){
	const bound = useContext(DataContext);

	const [customer, setCustomer] = useState(null);
	const [siteId, setSiteId] = useState(null);
	const [serviceId, setServiceId] = useState(null);
	const [sites, setSites] = useState([]);
	const [services, setServices] = useState([]);
	const [activeService, setActiveServices] = useState([]);

	useEffect(()=>{
		if(props.customer == undefined){
			bound.magicalState("customer", (val)=>{
				setCustomer(val);
			});
		}

		bound.magicalState("site", (val)=>{
			if(val != undefined && typeof val == "object"){
				setSiteId(val.id) ;
			} else {
				setSiteId(val);
			}
		});

		bound.magicalState("service", (val)=>{
			if(val != undefined && typeof val == "object"){
				setServiceId(val.id) ;
			} else {
				setServiceId(val);
			}
		});
	},[bound]);

	useEffect(()=>{
		if(props.onChange != undefined){
			props.onChange(siteId == null? null : _.find(sites, {id: parseInt(siteId)}), serviceId == null? null : _.find(services, {id: parseInt(serviceId)}));
		}
	}, [siteId, serviceId]);

	useEffect(()=>{
		setCustomer(props.customer);
	}, [props.customer]);

	useEffect(()=>{
		let siteId = bound.magicalGet("site");
		if(siteId == ""){
			siteId = null;
		} else if(typeof siteId == "object"){
			siteId = siteId.id;
		}

		const found = _.filter(sites, {id: siteId}).length > 0;
		if(!found){
			if (sites.length == 1){
				bound.magicalSet("site", sites[0].id);
			} else if(sites.length > 1){
				bound.magicalSet("site", null);
			}
		}
	},[sites]);

	useEffect(()=>{
		const found = _.filter(services, {id: bound.magicalGet("service", 0)}).length > 0;
		if(!found){
			if (services != undefined && services.length == 1){
				bound.magicalSet("service", services[0].id);
			} else if(services.length > 1){
				bound.magicalSet("service", 0);
			}
		}
	}, [services]);
	
	useEffect(()=>{
		if (customer && customer.id != undefined){
			APP.central.Site.fetchSitesForCustomer(customer.id).then((a)=>{
				const results = a.result? a.result : [];
				setSites(results);
			})
		}else{
			if(props.customer == undefined){
				const selectedCustomer = bound.magicalGet("customer");
				if (selectedCustomer != "" && selectedCustomer != customer){
					setCustomer(selectedCustomer);
				}
			}
		}
	}, [customer]);
	
	useEffect(()=>{
		if (siteId > 0){
		    let customerId = null;
		    if(typeof customer == "string"){
		        customerId = parseInt(customer);
		    } else if(typeof customer == "number"){
		        customerId = customer;
		    } else if(typeof customer == "object" && customer != null){
		        customerId = customer.id;
		    } else {
		        //console.log("What are you doing with that customer...", customer);
		        customerId = 0;
		    }
		    
			APP.central.Service.fetchServicesForCustomer(customerId, siteId).then((a)=>{
				setServices(a.result ? a.result: []);				
			});

			APP.central.Service.fetchServicesForCustomerNoCache(customerId, siteId).then((a)=>{
				setActiveServices(a.result ? a.result.filter((serv)=>serv.historical == 0): []);
			});		
		}		 
	}, [siteId]);

	const createService = () => {
		const customerId = bound.magicalGet("customer");
		const siteId = bound.magicalGet("site");

		if (customerId && siteId) {

			APP.central.Service.create({ customer: customerId, site: siteId }).then(r => {
				const newServiceId = r.result.id;
				const newFriendlyId = r.result.friendlyId;

				const modalOptions = {
					afterClose: () => {
						bound.magicalSet("service", newServiceId);
						props.onAddService && props.onAddService(newServiceId);
					},
				};

				APP.instance.createModal("/ui/services/edit/" + newFriendlyId, { modal_name: "New Service" }, modalOptions); //extras ~ customer: params.customerId, site: props.site, 
			});
		}
	}
	
	return <div key={"select_client_site_service" + (customer ? customer.id : "") } className="select_client_site_service">
		
		{props.show.indexOf("customer") > -1?
		<InputAutoComplete field="customer" name="Client" central={APP.central.Customer.autocomplete} onSelect={(c)=>setCustomer(c.basis)}>
			{(customer)=><div className="customer_option">{customer.displayValue}</div>}
		</InputAutoComplete> : null}
		
		{props.show.indexOf("site") > -1? <InputSelect field="site" name="Site" options={_.fromPairs(sites.map((s)=>[s.id, s.name]))}/> : null}
		{props.show.indexOf("service") > -1? <InputSelect field="service" name="Service" options={props.forArEdit ? 
			(_.fromPairs(activeService.map((s)=>[s.id, s.longDescription]))) : 
			(_.fromPairs(services.map((s)=>[s.id, s.longDescription])))} 
			appendable={props.allowAddService} appendableLabel={"Create New Service"} handleAppendClicked={createService}/> : null}
		{props.children}
		</div>;
}

SelectClientSiteService.propTypes = {
	show: PropTypes.arrayOf(PropTypes.string),
	customer: PropTypes.any,
	onChange: PropTypes.func,
	forArEdit: PropTypes.bool
}

SelectClientSiteService.defaultProps = {
	show: ["customer", "site", "service"]
}