import React, { useState, useRef, useEffect } from "react";
import { useData, useNavBar } from "@opidcore/hooks/WTF";
import {
  Icon,
  TabularDataSet,
  TabularColumn,
  Button,
  Pagination,
  Searchable,
  ActionBar,
  BoundCommit,
  InputText,
  InputToggleSwitch,
  HoverElement,
  Tabular,
  SaveToolbar,
  LookupInputSelect,
} from "@opidcore/components";
import ChangeLogs from "../ChangeLog/View";
import * as _ from "lodash";
import Util from "@opidcore/Util";

export default function MaterialEdit(props) {
  useNavBar("Edit Materials", []);

  //const [allData, allDataSet] = useData("material;all");
  //const [data, dataSet] = useData("material;parentsOnly", { method: "listParentMaterials" });
  const [data, dataSet] = useData("material");

  const refreshMaterials = () => {
    dataSet.fetch();
  };

  const editMaterial = (material) => {
    APP.instance.createModal(<EditMaterial material={material} />, { modal_name: "Edit Material" }, { afterClose: () => refreshMaterials() });
  };

  const newMaterial = () => {
    APP.instance.createModal(
      <EditMaterial material={{ __new: true, __dataUID: dataSet.dataUID, id: 0 }} />,
      { modal_name: "New Material" },
      { afterClose: () => refreshMaterials() }
    );
  };

  const viewChildren = (row) => {
    APP.instance.createModal(<ViewMaterialChildren parent={row} refreshMaterials={refreshMaterials} />, { modal_name: "Sub-Materials" });
  };

  const hasChildren = (row) => {
    return (
      _.filter(data, (m) => {
        return m && m.parent && m.parent.id == row.id;
      }).length > 0
    );
  };

  return (
    <div className="full-size">
      <ActionBar title="Actions">
        <Searchable ds={dataSet} />
        <a className="btn btn-new" onClick={() => newMaterial()}>
          New Material
        </a>
      </ActionBar>

      <div>
        <Pagination dataSet={dataSet} />
        <Button onClick={() => dataSet.download("xlsx")}>excel</Button>
      </div>

      {dataSet.loading ? "loading" : ""}

      <TabularDataSet dataSet={dataSet} defaultSort="id" defaultSortOrder="asc" filter={(row) => row && (!row.parent || row.parent == row.id)}>
        <TabularColumn data={(row) => row.id} title="Material #" />
        <TabularColumn
          data={(row) => {
            let ret = <></>;
            if (hasChildren(row)) {
              ret = (
                <HoverElement content={<Icon icon="eye" onClick={() => viewChildren(row)} size="2x" />} hoverTime={50}>
                  {<ViewHoverMaterialChildren parent={row} data={_.filter(data, (d) => d.parent && d.parent.id == row.id)} />}
                </HoverElement>
              );
            }
            return ret;
          }}
          title="Sub-Materials"
        />
        <TabularColumn
          data={(row) => (
            <>
              {row.name} {row.sustainabilityName ? <em>{row.sustainabilityName}</em> : null}{" "}
            </>
          )}
          title="Name"
        />
        <TabularColumn data={(row) => _.round(row.densityInKgCubicYards, 2)} title="Density kg/yd^3" />
        <TabularColumn
          data={(row) => {
            return row.showOnReport ? "Yes" : "No";
          }}
          title="Show on Report?"
        />
        <TabularColumn data={(row) => _.round(row.epaConversionValue, 2)} title="Epa" />
        <TabularColumn
          data={(row) => {
            return row.epaRecycling ? "Yes" : "No";
          }}
          title="Epa Recycling"
        />
        <TabularColumn
          data={(row) => {
            return <input type="color" value={row.mainColour} disabled />;
          }}
          title="Main Colour"
        />
        <TabularColumn
          data={(row) => {
            return <input type="color" value={row.accentColour} disabled />;
          }}
          title="Accent Colour"
        />
        <TabularColumn
          data={(row) => {
            return <Icon icon={row.icon} color={row.mainColour} size="2x" />;
          }}
          title="Icon"
        />
        <TabularColumn
          data={(row) => {
            return <Icon icon="edit" onClick={() => editMaterial(row)} size="2x" />;
          }}
        />
      </TabularDataSet>
    </div>
  );
}

const ViewMaterialChildren = (props) => {
  const parent = props.parent;
  const [childrenData, childrenDataSet] = useData("material;childrenonly", { method: "listChildMaterials", parentId: parent.id });

  const editMaterial = (material) => {
    APP.instance.createModal(<EditMaterial material={material} />, { modal_name: "Edit Sub-Material" }, { afterClose: () => props.refreshMaterials() });
  };

  const createChild = () => {
    APP.instance.createModal(
      <EditMaterial material={{ ...parent, id: 0, parent: parent.id, __new: true }} />,
      { modal_name: "Create Sub-Material" },
      { afterClose: () => props.refreshMaterials() }
    );
  };

  return (
    <div className="full-size">
      {childrenDataSet.loading ? "loading" : ""}

      <ActionBar title="Actions">
        <h1>{parent.name}</h1>
        <div style={{ display: "flex", gap: "5px", marginTop: "10px" }}>
          <Button onClick={createChild}>Create Sub-Material</Button>
        </div>
      </ActionBar>

      <TabularDataSet dataSet={childrenDataSet} defaultSort="id" defaultSortOrder="asc" filter={(row) => row && row.parent && row.parent.id != row.id}>
        <TabularColumn data={(row) => row.id} title="Material #" />
        <TabularColumn
          data={(row) => (
            <>
              {row.name} {row.sustainabilityName ? <em>{row.sustainabilityName}</em> : null}{" "}
            </>
          )}
          title="Name"
        />
        <TabularColumn data={(row) => _.round(row.densityInKgCubicYards, 2)} title="Density kg/yd^3" />
        <TabularColumn
          data={(row) => {
            return row.showOnReport ? "Yes" : "No";
          }}
          title="Show on Report?"
        />
        <TabularColumn data={(row) => _.round(row.epaConversionValue, 2)} title="Epa" />
        <TabularColumn
          data={(row) => {
            return row.epaRecycling ? "Yes" : "No";
          }}
          title="Epa Recycling"
        />
        <TabularColumn
          data={(row) => {
            return <input type="color" value={row.mainColour} disabled />;
          }}
          title="Main Colour"
        />
        <TabularColumn
          data={(row) => {
            return <input type="color" value={row.accentColour} disabled />;
          }}
          title="Accent Colour"
        />
        <TabularColumn
          data={(row) => {
            return <Icon icon={row.icon} color={row.mainColour} size="2x" />;
          }}
          title="Icon"
        />
        <TabularColumn
          data={(row) => {
            return <Icon icon="edit" onClick={() => editMaterial(row)} size="2x" />;
          }}
        />
      </TabularDataSet>
    </div>
  );
};

const ViewHoverMaterialChildren = (props) => {
  const data = props.data;
  const parent = props.parent;
  
  return (
    <div style={{ width: "800px" }}>
      <ActionBar title="Actions">
        <h1>{parent.name}</h1>
      </ActionBar>

      <Tabular data={data} defaultSort="id" defaultSortOrder="asc">
        <TabularColumn data={(row) => row.id} title="Material #" />
        <TabularColumn
          data={(row) => (
            <>
              {row.name} {row.sustainabilityName ? <em>{row.sustainabilityName}</em> : null}{" "}
            </>
          )}
          title="Name"
        />
        <TabularColumn data={(row) => _.round(row.densityInKgCubicYards, 2)} title="Density kg/yd^3" />
        <TabularColumn data={(row) => _.round(row.epaConversionValue, 2)} title="Epa" />
      </Tabular>
    </div>
  );
};

function EditMaterial(props) {
  const materialRef = useRef({ ...props.material });
  const [material, setMaterial] = useState(props.material);
  const [isParent, setIsParent] = useState(props.material.parent == null);

  useEffect(() => {
    if (material && (material.parent == null || material.parent == material.id)) {
      setIsParent(true);
    } else {
      setIsParent(false);
    }
  }, [material]);

  const updateMaterial = (a, b) => {
    setMaterial({ ...b.to });
  };

  const createChild = () => {
    APP.instance.createModal(<EditMaterial material={{ ...material, id: 0, parent: material.id, __new: true }} />, { modal_name: "Create Sub-Material" });
  };

  //checks all requiured fields, returns false if any are empty
  const checkRequiredFields = (checkMaterial) => {
    if (_.isEmpty(checkMaterial.name) || _.isEmpty(_.trim(checkMaterial.name))) {
      APP.alert("Please enter a name for the material.");
      return false;
    }
    if (_.isEmpty(_.toString(checkMaterial.densityInKgCubicYards)) || _.isEmpty(_.trim(_.toString(checkMaterial.densityInKgCubicYards)))) {
      APP.alert("Please enter a density for the material.");
      return false;
    }

    return true;
  }

  const doSave = () => {
    if (material != undefined && checkRequiredFields(material)) {
      return APP.central.Material.saveChanges(material.id, material).then((r) => {
      				
			});
		}
  }

  const getPermissions = () => {
    const sustainabilityPerms = APP.getWebSession().permissions("Sustainability");
    const controlledDataListsPerms = APP.getWebSession().permissions("Controlled Data Lists");
    if(isParent) {
      return controlledDataListsPerms;
    } else {
      if(sustainabilityPerms.w) {
        return sustainabilityPerms;
      } else {
        return controlledDataListsPerms;
      }
    }
  }

  return (
    <div>
      <BoundCommit
        initialValue={materialRef.current}
        forceSaveProps={["material"]}
        commit={APP.central.Material.saveChanges}
        commitWithParent={true}
        className="basicForm"
        boundId={"editMaterial" + material.id}
        onChange={updateMaterial}
        permissions={getPermissions()}
      >
        <ActionBar title="Actions">
          <h2>Edit Material</h2>
          <div style={{ display: "flex", gap: "5px", marginTop: "10px" }}>
            {material.parent ? null : <Button onClick={createChild}>Create Sub-Material</Button>}
            <ChangeLogs model={materialRef.current} />
          </div>
        </ActionBar>

        <InputText field="name" name="Name" required={true} />
        <InputText field="sustainabilityName" name="Sustainability Name" />
        <InputText field="invoiceDescription" name="Invoice Description" />
        <InputText field="densityInKgCubicYards" name="Density kg/yd^3" required={true} />
        <LookupInputSelect what="material_destination" name="Material Destination" field="finalDestination" showEmpty={true} store="object" display="destination"/>
        <InputToggleSwitch
          field="showOnReport"
          name={(status) => {
            return status == undefined || status == false ? "Do Not Show On Report" : "Show On Report";
          }}
        />
        <InputText field="epaConversionValue" name="Epa Conversion Value" />
        <InputToggleSwitch
          field="epaRecycling"
          name={(status) => {
            return status == undefined || status == false ? "No Epa Recycling" : "Epa Recycling";
          }}
        />
        <InputText field="mainColour" name="Main Colour" inputType="color" />
        <InputText field="accentColour" name="Accent Colour" inputType="color" />
        <InputText field="icon" name="Icon" />
        <Icon icon={material.icon} color={material.mainColour} size="5x" />

        <SaveToolbar handleSave={doSave} showCancel={true} />
      </BoundCommit>
    </div>
  );
}
