import React, { Component } from 'react';
import Figure from './Figure.jsx';
import templated from './Templated.jsx';

export default class TestFigure extends Figure {
    constructor(props) {
        super(props);
    }
    
    render(){
        const text = "I am a figure and my name is " + this.props.root + "! {{figure_number}}";
        return <div>{templated(text, this)  || "No Content"}</div>;
    }
}