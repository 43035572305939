import React from "react";

export default function Panel({title,children}){

    return <div className="panel">
        { typeof title == "string" ? <h3>{title}</h3> : title }

        {children}
    </div>
}

export function PanelGroup({children, style={}}){

    return <div className="panel-group" style={style}>
        {children}
    </div>
}