import React, { useState, useRef, useEffect } from 'react';
import { PageHeading, ActionBar, Bound, TabularDataSet, TabularColumn, Kanban, InputMultiCheckbox, Searchable, InputSelect, Loading } from '@opidcore/components';
import MultiOptionToggle from "../../components/MultiOptionToggle";
import { useData } from "@opidcore/hooks/WTF";
import { Icon } from '../../../../../OpidCore/public/js/components';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import PeriodSelect from '../../components/PeriodSelect';
import moment from 'moment';
import { Util } from '@opidcore/Util';
import CesarFilter from '../../components/CesarFilter'; 

const ServiceChunk = ({ service }) => {
    if (service == undefined) {
        return null;
    }

    const editService = () => {
        APP.instance.createModal("ui/services/edit/" + service.id, { modal_name: service.shortDescription });
    }

    return <span className="service-chunk" key={"service-" + service.id}>
        {service.shortDescription} 
        <Icon title="Edit" icon="pencil" onClick={editService} />
    </span>
}

const SiteChunk = ({site}) =>{
    if(site == undefined){
        return null;
    }

    const editSite = () => {
        APP.instance.createModal("ui/sites/edit/" + site.id, { modal_name: site.name });
    }

    return <span className="site-chunk" key={"site-" + site.id}>
        {site.name}
        <Icon title="Edit" icon="pencil" onClick={editSite} />
    </span>
}

const APChunk = ({apId, externalID}) =>{
    if(apId == undefined || apId <= 0){
        return null;
    }

    const editAP = () => {
        APP.instance.createModal("ui/apinvoice/" + apId, { modal_name: "AP  #" + apId });
    }

    return <span className="ap-chunk" key={"ap-" + apId}>
        {externalID} <Icon title="Edit" icon="pencil" onClick={editAP} />
    </span>
}

const TotalChunk = ({total, mandatoryPrice, status, service}) =>{
    if(service == undefined){
        return null;
    }

    return <span className="total-chunk" key={"total-service-" + service.id}>
        <span className="total">${Util.roundNice(total == undefined ? 0 : total, 2)}</span>
        <span className="total-missing">{status == "missing" && mandatoryPrice != 0 ? "$" + Util.roundNice(mandatoryPrice, 2) : ""}</span>
    </span>
}

const NicePriority = ({children}) =>{
    return <span className="nice-priority" key="nice-priority">
        Priority: {children}
    </span>
}

const TabularView = ({ dataSet }) => {
    return <div key="tabular-view" className="tabular-view">
        <TabularDataSet dataSet={dataSet}>
            <TabularColumn data={(row) => <APChunk apId={row.ap_id} externalID={row.external_invoice_number} />} title="AP #" />
            <TabularColumn data={(row) => row.Vendor != undefined ? <div><span className="vendor-label">{row.Vendor.name}</span>{row.xero_uuid? <Icon icon="sync" colour="#00b300"/> : ""}</div> : ""} title="Vendor" />
            <TabularColumn data={(row) => <SiteChunk site={row.Site} />} title="Site" />
            <TabularColumn data={(row) => <ServiceChunk service={row.Service} />} title="Service" />
            <TabularColumn data={(row) => row.invoice_preparer} title="Invoice Preparer" />
            <TabularColumn data={(row) => <NicePriority>{row.client_billing_priority}</NicePriority>} title="Priority" />
            <TabularColumn data={(row) => row.status} title="Status" />
            <TabularColumn data={(row) => <TotalChunk total={row.total} mandatoryPrice={row.mandatory_price} status={row.status} service={row.Service} />} title="Total" />
        </TabularDataSet>
    </div>;
}

const kanbanCardContent = ({ data }) => {
    return <div key="ap-status-kanban-card" className="ap-status-kanban-card waste_kanban-card_content">
        <div className="xero-link">
            {data.xero_uuid? <Icon icon="sync" title="Synced to Xero" colour="#00b300"/> : ""}
        </div>
        <div className="ap-id">
            <APChunk apId={data.ap_id} externalID={data.external_invoice_number} />
        </div>
        <div className="total">
           <TotalChunk total={data.total} mandatoryPrice={data.mandatory_price} status={data.status} service={data.Service} />
        </div>
        <div className="vendor">
            <Icon title="Vendor" icon="house" />
            {data.Vendor ? data.Vendor.name : ""}
        </div>
        <div className="site">
            <Icon title="Site Location" icon="location" />
            <SiteChunk site={data.Site} />
        </div>
        <div className="service">
            <Icon title="Service" icon="trash" />
            <ServiceChunk service={data.Service} />
        </div>
        <div className="invoice-preparer">
            Prepared By: {data.invoice_preparer}
        </div>
        <div className="client-priority">
            <NicePriority>{data.client_billing_priority}</NicePriority>
        </div>        
        <div className="related-invoices flex">
            { data.ap_id > 0 ? <Link to={"/ui/snowman/ap/" + data.ap_id + "?status=" + data.status}>AP Import Details</Link> : null }
            { data.ap_id > 0 ? <Link to={"/ui/apinvoice/" + data.ap_id }>AP #{data.ap_id}</Link> : null }
            { data.ar_id > 0 ? <Link to={"/ui/arinvoice/" + data.ar_id }>AR #{data.ar_id}</Link> : null }
        </div>
    </div>
}

const KanbanView = ({ dataSet, columnTotals, statuses }) => {


    const headingFunction = (columnData, columnStatus) => {
        if (columnTotals[columnStatus] == undefined) {
            return "Total: $" + Util.roundNice(0, 2);
        }

        return "Total: $" + Util.roundNice(columnTotals[columnStatus], 2);
    };

    const kanbanProps = {
        data: dataSet,
        isLoading: dataSet.isLoading,
        status: statuses,
        cardContent: kanbanCardContent,
        headingFunction: headingFunction,
        sortBy: (r)=>{ return r.client_billing_priority_order},
        showActions: false
    }

    return <div key="kanban-view" className="kanban-view">
        <Kanban {...kanbanProps} />
    </div>;
}

const APStatusList = ({ }) => {
    const [loaded, setLoaded] = useState(false);
    const [showKanban, setShowKanban] = useState(true);
    const [columnTotals, setColumnTotals] = useState({});
    const [vendors, setVendors] = useState([]);
    const [data, dataSet] = useData("ap-status-list", APP.central.APInvoice.statusList);
    const allStatuses = [{ key: "missing", label: "Missing Vendor Invoice", order: 1 }, { key: "unmatched", label: "Missing Client Match", order: 2 }, { key: "match", label: "Matched", order: 3 }];
    const [statuses, setStatuses] = useState([]);
    const boundRef = useRef({ showKanban: true, vendor: undefined, period: moment().format("YYYY-MM"), activeCols: allStatuses });

    useEffect(() => {
        setStatuses(allStatuses); //set initial value
    }, []);

    useEffect(() => {
        if(vendors.length == 0){
            const tempVendors = {};

            _.forEach(data, (dataRow) => {
                if (dataRow.vendor_id != undefined && dataRow.Vendor != undefined && tempVendors[dataRow.vendor_id] == undefined) {
                    tempVendors[dataRow.vendor_id] = dataRow.Vendor.name;
                }
            });

            setVendors(_.map(_.keys(tempVendors), (vendorId) => {
                return { key: vendorId, value: tempVendors[vendorId] };
            }));
        }

        if (dataSet.loadedCount != 0) {
            setLoaded(true);
        }
    }, [dataSet.origData]);

    useEffect(() => {
        updateColumnTotals()
    }, [dataSet.rawData])

    useEffect(() => {
        if (statuses.length == allStatuses.length) {
            dataSet.reset();
        } else {
            const availableStatuses = {};
            _.forEach(statuses, (status) => { availableStatuses[status.key] = "seen" }); //simplify the search while in the dataset filter loop
            dataSet.filter((r) => availableStatuses[r.status] != undefined, true);
        }
    }, [statuses]);

    const updateColumnTotals = () => {
        const tempTotals = { default: 0 };

        _.forEach(data, (dataRow) => {
            if (tempTotals[dataRow.status] == undefined) {
                tempTotals[dataRow.status] = 0;
            }

            if (dataRow.total != undefined) {
                tempTotals[dataRow.status] += dataRow.total;
            }
        });

        setColumnTotals(tempTotals);
    }

    const updateView = (field, fieldValue) => {
        switch (field) {
            case "showKanban":
                boundRef.current.showKanban = fieldValue;
                setShowKanban(fieldValue);
                break;

            case "period":
                dataSet.setQueryArgs({date: fieldValue});
                dataSet.fetch();
                break;

            case "vendor":
                if (fieldValue == "") {
                    dataSet.reset();
                } else if(fieldValue != undefined){
                    dataSet.filter((r) => r.vendor_id == parseInt(fieldValue), true, null, "vendor");
                    dataSet.reset(dataSet.origData, {keepFilters: true});
                }

                boundRef.current.vendor = fieldValue;
                break;

            case "activeCols":
                setStatuses(_.sortBy(fieldValue, "order"));

                break;
        }
    }

    const grandTotal = Util.roundNice(_.sum(_.values(columnTotals)), 2);

    if (!loaded) {
        return <Loading />;
    }

    return <div key="ap-status-list" className="ap-status-list">
        <PageHeading>AP Status</PageHeading>

        <ActionBar title="Actions">
            <div>
                <Searchable ds={dataSet} />
                <CesarFilter
                  columns={[
                      { column: "Vendor" }, 
                      { column: "Site" }, 
                      { column: "Service" }, 
                      { column: "invoice_preparer" }, 
                      { column: "status" }, 
                      { column: "client_billing_priority" }
                  ]}
                  dataSet={dataSet}
                />
            </div>

            <Bound to={boundRef.current} onChange={(bound, boundMagic, field, fieldValue) => updateView(field, fieldValue)}>
                <div className="filters">
                    <PeriodSelect field="period" label="Period" />
                    <InputSelect name="Vendor" field="vendor" options={vendors} showEmpty={true} />

                    <div className="ARInvoice-InputMultiCheckbox">
                        <InputMultiCheckbox field="activeCols" optionsCollection={allStatuses} optionKey={"key"}>
                            {(row) => <div className="tile-item">{row.label}</div>}
                        </InputMultiCheckbox>
                    </div>
                </div>

                <div className="toggle">
                    <MultiOptionToggle field="showKanban" name="kanbanToggle">
                        <div data-toggle-value={false} key="list">List</div>
                        <div data-toggle-value={true} key="kanbab">Kanban</div>
                    </MultiOptionToggle>
                </div>


            </Bound>
        </ActionBar>
        
        Grand Total: ${grandTotal}
        {showKanban ? <KanbanView dataSet={dataSet} columnTotals={columnTotals} statuses={statuses} /> : <TabularView dataSet={dataSet} />}
    </div>
}

export default APStatusList;