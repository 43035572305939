import React from 'react';
import PropTypes from 'prop-types';
import { useData } from '@opidcore/hooks/WTF';
import { File } from '../../components';
import { Util } from '@opidcore/Util';
import _ from 'lodash';
import { Bound } from '@opidcore/components';  

const ViewContractFiles = ({contractType, contract}) => {
    const [data, dataSet] = useData(contractType, contract.id);

    let title = Util.capitalizeWords(contractType, "_") + " #" + contract.friendlyId;

    return <div className="view_contract_files">
        {title}
        <Bound to={data}>
            <File />
        </Bound>
    </div>
}

ViewContractFiles.propTypes = {
    contractType: PropTypes.oneOf(["baseline_contract", "client_contract", "vendor_contract"]),
    contract: PropTypes.object
}

export default ViewContractFiles;