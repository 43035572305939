import React, { Component } from 'react';
import templated from './Templated.jsx';

import TableOfContentsRow from './TableOfContentsRow.jsx';

export default class TableOfContents extends Component {
    constructor(props) {
        super(props);
    }
    
    static metaInfo() {
        return {regenerate: true};
    }
    
    render(){
        const title = "";
        const sections = _.sortBy(_.map(APP.NDT.scope.sections, (val, key)=>{ 
            return {id: key, section: val.value, content: val.content} 
        }), (el)=>{ 
            const elComponents = el.section.split(".");
            let val = 0; 
            let startingIndexVal = 100; //weight of each index
            _.forEach(elComponents, (a)=>{ 
                val += (a*startingIndexVal); 
                startingIndexVal = startingIndexVal / 10; 
            }); 
            return val; 
        });
        
        const content = _.map(sections, (section, index)=>{
            let page = this.props.NDT.scope._pages[section.id];
            const theRMS = this.props.NDT.getNode(section.id);
            
            if(page == undefined){
            	if (theRMS && theRMS.element){
	            	let parent = theRMS.element.parentElement;
	                while (page == undefined && parent != undefined ){
	                	if ( this.props.NDT.scope._pages[ parent.getAttribute("data-uid") ]){
	                		page = this.props.NDT.scope._pages[ parent.getAttribute("data-uid") ];
	                	}
	                	
	                	parent = parent.parentElement;
	                }
            	}
                
                let rootStructure = null;
                if(theRMS == undefined){
                    rootStructure = this.props.NDT.find(section.id);
                } else {
                    rootStructure = theRMS.rmsStruct;
                }
                
                if(rootStructure == undefined){
                   //debugger;
                }
                
                page = {structure: rootStructure, pageNumber: page ? page.pageNumber : "-/-"};
                
            }
            let title = "";
            if(page != undefined && page.structure != undefined){
            	try{
            		title = templated(page.structure.options.content, this);
            	}catch(e){
            		title = " an error in title for section " + section.id;
            	}
            }else{
            	title = templated(section.content, this);
            }
            
            let sectionNumber = section.section;    

            if(theRMS != undefined && theRMS.props.content.indexOf("{{section_number}}") < 0){
                sectionNumber = "";
            }
            
            
            return <TableOfContentsRow key={"tocRow"+index} elementId={section.id} identificationNumber={sectionNumber} title={title.toUpperCase()} pageNumber={page? page.pageNumber : ""} />;
        });
        
        return <div id="table_of_contents">
            {title}
            {content}
        </div>
    }
};