import React from 'react';
import SiteModule from '../Site/module';
import ClientContactList from './ClientContactList';
import ClientContractList from './ClientContractList';
import ClientHealth from './ClientHealth';
import NewClientHealth from './NewClientHealth'
import ClientSiteManager from './ClientSiteManager.jsx';
import EditClient from './EditClient.jsx';
import EditClientContract from './EditClientContract';
import EditPortalProfile from './EditPortalProfile.jsx';
import ListClients from './ListClients.jsx';
import ListInvoices from './ListInvoices';
import ListPortalProfiles from './ListPortalProfiles';
import UmbrellaManagement from './UmbrellaManagement';

import { MenuItem, SubMenu } from '@opidcore/components/Menu';
import ContractExpiryManagement from '../Contract/ContractExpiryManagement.jsx';

export default function initialize(permissions, options) {
    if (permissions.w || true) {
        window.APP.customRoutes.push({ path: "/ui/clients/sites/:customerId", component: ClientSiteManager, key: "customer_manage_sites" });
        window.APP.customRoutes.push({ path: "/ui/clients/:id/invoices", component: ListInvoices, key: "client_invoices" });
        window.APP.customRoutes.push({ path: "/ui/clients/:id", component: EditClient, key: "client_edit" });
        window.APP.customRoutes.push({ path: "/ui/clientcontract/edit/:id", component: EditClientContract, key: "client_edit_contract" });
        window.APP.customRoutes.push({ path: "/ui/clientcontract", component: ClientContractList, key: "client_contracts_list" });
        window.APP.customRoutes.push({ path: "/ui/clientcontactlist", component: ClientContactList, key: "client_contacts_list" });
        window.APP.customRoutes.push({ path: "/ui/portalprofile/:id", component: EditPortalProfile, key: "portal_profile_edit" });
        window.APP.customRoutes.push({ path: "/ui/portalprofiles", component: ListPortalProfiles, key: "portal_profile_list" });
        window.APP.customRoutes.push({ path: "/ui/contractexpirymanagement", component: ContractExpiryManagement, key: "contract_expiry_management" });

    }
    
    window.APP.customRoutes.push({ path: "/ui/clients", component: ListClients, key: "client_list" });
    window.APP.customRoutes.push({ path: "/ui/clienthealth", component: NewClientHealth, key: "client_health" });
    window.APP.customRoutes.push({path: "/ui/admin/umbrellamanagement", component: UmbrellaManagement, key: "umbrella_manangement"});

    if (true) {
        SiteModule(permissions, options);
    }

    APP.addMenu(() => {
        const items = [];
        items.push(<SubMenu key="clients">
            <MenuItem key="clients" menuKey="clients" linkTo="/ui/clients" icon="building" text="Clients" />
            <MenuItem key="sites" menuKey="sites" className="nav-sub-menu" linkTo="/ui/sites/list" icon="map-marker" text="Sites" />
            <MenuItem key="contacts" menuKey="clientContacts" className="nav-sub-menu" linkTo="/ui/clientcontactlist" icon="id-card" text="Contacts" />
            <MenuItem key="contracts" menuKey="clientContracts" className="nav-sub-menu" linkTo="/ui/clientcontract" icon="file-contract" text="Contracts" />
            <MenuItem key="contract_expiry_management" menuKey="contract_expiry_management_menu_key" className="nav-sub-menu" linkTo="/ui/contractexpirymanagement" icon="calendar" text="Contract Expiry"/>
            <MenuItem key="health" menuKey="clienthealth" className="nav-sub-menu" linkTo="/ui/clienthealth" icon="heartbeat" text="Client Health" />
            
        </SubMenu>);

        return items;
    });

}

