import React, { useRef, useEffect, useState, Fragment, useContext } from "react";
import { useData, useNavBar } from "@opidcore/hooks/WTF";
import {
  Icon,
  TabularDataSet,
  TabularColumn,
  Button,
  Pagination,
  Searchable,
  ActionBar,
  InputText,
  UnlockToEdit,
  InputSelect,
  DatePicker,
  Tabular,
  Bound,
  Loading,
  InputDecimal,
  useMagic,
} from "@opidcore/components";
import * as _ from "lodash";
import { CurrentModal } from "@opidcore/components/OpidApplication";
import LookupStatusSelect from "@opidcore/components/LookupStatusSelect";

export default function GlobalServiceEdit(props) {
  useNavBar("Global Service Edit", []);
  const [data, dataSet] = useData("globalservice", { method: "fetchGroupedByParents" });

  const editGlobalService = (globalService) => {
    APP.instance.createModal(
      <EditGlobalService globalService={globalService} dataSetUID={dataSet.dataUID} />,
      { modal_name: "Edit: " + globalService.parent.id },
      {
        afterClose: () => dataSet.fetch(),
      }
    );
  };

  const refreshStuff = () => {
    dataSet.reset();
    dataSet.fetch();    
  };

  const newGlobalService = () => {
    APP.instance.createModal(
      <EditGlobalService
        globalService={{
          __new: true,
          __dataUID: dataSet.dataUID,
          current: { __new: true, __dataUID: dataSet.dataUID, parentId: 0, id: 0 },
          children: [],
          parent: { __new: true, __dataUID: dataSet.dataUID },
        }}
      />,
      { modal_name: "New" },
      {
        afterClose: () => {
          refreshStuff();
        },
      }
    );
  };

  return (
    <div className="full-size">
      <ActionBar title="Actions">
        <Searchable ds={dataSet} />
        <Pagination dataSet={dataSet} />
        <Button onClick={() => newGlobalService()}>New</Button>
      </ActionBar>

      <div>
        <Button onClick={() => dataSet.download("xlsx")}>excel</Button>
      </div>

      {dataSet.loading ? <Loading /> : ""}

      <TabularDataSet
        dataSet={dataSet}
        defaultSort={(row) => {
          return row.parent.id;
        }}
        defaultSortOrder="desc"
      >
        <TabularColumn data={(row) => row.parent.id} title="#" sortCol="id" />
        <TabularColumn data={(row) => row.parent.name} title="Name" />
        <TabularColumn data={(row) => row.current.description} title="Current Description" />
        <TabularColumn data={(row) => row.current.date} title="Current Date" />
        <TabularColumn data={(row) => row.current.charge} title="Current Charge A" />
        <TabularColumn data={(row) => row.current.chargeB} title="Current Charge B" />
        <TabularColumn data={(row) => row.current.calculatedCharge} title="Current Total Charge" />
        <TabularColumn
          data={(row) => {
            return <Icon icon="edit" onClick={() => editGlobalService(row)} size="2x" />;
          }}
        />
      </TabularDataSet>
    </div>
  );
}

function EditGlobalService(props) {
  const globalServiceRef = useRef({ ...props.globalService });

  const [globalServiceData, setGlobalServiceData] = useState({
    current: {},
    children: [],
    parent: {},
  });
  const [isNew, setIsNew] = useState(globalServiceRef.current.__new ? true : false);
  const [historicalState, setHistoricalState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentlyEditable, setCurrentlyEditable] = useState({ ...props.globalService.current });

  const currentModal = useContext(CurrentModal);

  // fetch from server if its not new
  useEffect(() => {
    if (!isNew) {
      updateGlobalServiceData();
    }
  }, []);

  // fetches a single parent group and updates globalServiceData, historicalState...
  const updateGlobalServiceData = (parentId = null) => {
    setLoading(true);
    APP.central.GlobalService.fetchSingleParentGroup(parentId ? parentId : globalServiceRef.current.parent.id).then((r) => {
      if (!r.result.parent) {
        APP.instance.closeModal(currentModal != undefined && currentModal.id != undefined ? currentModal.id : undefined);
        return;
      }
      globalServiceRef.current = r.result;
      setGlobalServiceData(r.result);
      doSetHistoricalState(r.result);
      setLoading(false);
    });
  };

  // sets historical state, can take a global service group
  const doSetHistoricalState = (globalServiceGroup) => {
    if (globalServiceGroup) {
      const tempChildren = _.cloneDeep(globalServiceGroup.children);
      tempChildren.push(_.cloneDeep(globalServiceGroup.parent));
      setHistoricalState(tempChildren);      
      return;
    }
    const tempChildren = _.cloneDeep(globalServiceData.children);
    tempChildren.push(_.cloneDeep(globalServiceData.parent));
    setHistoricalState(tempChildren);
  };

  // saves then refreshes
  const doSave = (data) => {
    setLoading(true);
    APP.central.GlobalService.saveChanges(data.id, data).then((r) => {    
      if (isNew) {
        doSetEditable(r.result);
        setIsNew(false);
      }
      updateGlobalServiceData(r.result.parentId);
      //setLoading(false);
    });
  };

  // adds a new global service with current parent and sets it as current
  const doAddRate = () => {
    setLoading(true);

    globalServiceRef.current.current.isCurrent = false;
    APP.central.GlobalService.saveChanges(globalServiceRef.current.current.id, globalServiceRef.current.current).then((r) => {
     

      const newRate = _.cloneDeep(globalServiceRef.current.current);
      newRate.id = 0;
      newRate.isCurrent = true;
      newRate.date = "";
      newRate.__new = true;
      newRate.__dataUID = props.dataSetUID;

      APP.central.GlobalService.saveChanges(newRate.id, newRate).then((r) => {
        updateGlobalServiceData();
        doSetEditable(r.result);
      });
    });
  };

  // sets currentlyEditable state to passed in row
  const doSetEditable = (row) => {
    setLoading(true);
    setCurrentlyEditable(row);
    setLoading(false);
  };

  // unused, sets a new "current" global service
  const setCurrent = (row) => {
    setLoading(true);

    globalServiceRef.current.current.isCurrent = false;
    APP.central.GlobalService.saveChanges(globalServiceRef.current.current.id, globalServiceRef.current.current).then((r) => {
      row.isCurrent = true;
      globalServiceRef.current.current = row;
      APP.central.GlobalService.saveChanges(globalServiceRef.current.current.id, globalServiceRef.current.current).then((r) => {
        updateGlobalServiceData();
        //setLoading(false);
      });
    });
  };

  // deletes entry from db
  const delRow = (row) => {
    setLoading(true);
    APP.central.GlobalService.delete(row.id).then((r) => {   
      if (row.id == currentlyEditable.id) {
        doSetEditable(globalServiceRef.current.parent);
      }
      updateGlobalServiceData();
      //setLoading(false);
    });
  };

  return (
    <Fragment>
      <div>
        <h2>Editing</h2>
        <EditingTest data={currentlyEditable} save={(data) => doSave(data)} />
        <div>{isNew ? null : <Button onClick={doAddRate}>Add New Rate</Button>}</div>
        {loading ? <Loading /> : null}
      </div>
      <div>
        {isNew ? null : (
          <Fragment>
            <h2>Historical</h2>
            <Tabular
              data={historicalState}
              defaultSort={(row) => {
                return row.id;
              }}
              defaultSortOrder="desc"
            >
              <TabularColumn data={(row) => row.id} title="#" sortCol="id" />
              <TabularColumn data={(row) => row.name} title="Name" />
              <TabularColumn data={(row) => row.description} title="Description" />
              <TabularColumn data={(row) => row.date} title="Date" sortCol="date" />
              <TabularColumn data={(row) => row.charge} title="Charge A" />
              <TabularColumn data={(row) => row.chargeB} title="Charge B" />
              <TabularColumn data={(row) => row.calculatedCharge} title="Total Charge" />
              <TabularColumn data={(row) => <Icon icon="edit" onClick={() => doSetEditable(row)} size="2x" />} title="Edit" />
              <TabularColumn
                data={(row) => {
                  if (row.id != globalServiceRef.current.parent.id || historicalState.length <= 1) {
                    return <Icon icon="trash" onClick={() => delRow(row)} size="2x" />;
                  } else {
                    return null;
                  }
                }}
                title="Delete"
              />
            </Tabular>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

const EditingTest = ({ data, save }) => {
  //const [data, dataSet] = useData("globalService", id);
  const bound = useMagic(data);

  useEffect(() => {
    bound.replaceTo(data);
  }, [data]);

  return (
    <Fragment>
      <Bound to={bound}>
        <UnlockToEdit>
          <InputText field="id" name="#" />
        </UnlockToEdit>
        <InputText field="name" name="Name" />
        <InputText field="description" name="Description" />
        <InputDecimal field="charge" name="Charge" />
        <InputDecimal field="chargeB" name="Charge B" />
        <InputSelect field="calculationMethod" name="Calculation Method" options={["", "Regular", "Stacked"]} />
        <DatePicker field="date" name="Date" required={true} />
      </Bound>
      <Button onClick={() => save(data)}>Save</Button>
    </Fragment>
  );
};
