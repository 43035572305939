import React, { useState, useRef, useContext, useEffect, useLayoutEffect, Fragment } from "react";
import { Icon, BoundDataContext, Button, Bound, InputToggleSwitch } from "@opidcore/components";
import PropTypes from "prop-types";
import * as _ from "lodash";
import SimplePDFPreview from "./SimplePDFPreview";

function upload(f, bound, callback, props) {
  const reader = new FileReader();
  reader.onload = function (e) {
    const fileContents = e.target.result;
    const base64 = fileContents.substring(fileContents.lastIndexOf(",") + 1);

    const upload = { filename: f.name, base64: base64, lastModified: f.lastModified };

    if (props.useExisting) {
      upload.useExisting = props.useExisting;
    }

    if (bound && bound.magicalGet) {
      upload.relatedTable = bound.magicalGet("__type");
      upload.relatedId = bound.magicalGet("id");
    }
    if (props && props.storeIn) {
      upload.storeIn = props.storeIn;
    }
    APP.central.File.upload(upload).then((r) => {
      if (callback) {
        callback(f, r.result);
      }
    });
  };
  reader.readAsDataURL(f);
}

export function FileItem(props) {
  const f = props.file;
  const useId = props.useId ? props.useId : false;
  const [showDelete, setShowDelete] = useState(props.showDelete ? props.showDelete : false);
  const [showHistoricalSet, setShowHistoricalSet] = useState(props.showHistoricalSet ? props.showHistoricalSet : false);

  //TODO where do we actually want them to delete files?

  let icon = "file";
  const ext = _.last(f.filename.toLowerCase().split("."));
  if (["jpg", "png", "jpeg", "gif"].indexOf(ext) >= 0) {
    icon = "file-image";
  }
  if (["xlsx", "xls", "xlsb"].indexOf(ext) >= 0) {
    icon = "file-excel";
  }
  if (["pdf"].indexOf(ext) >= 0) {
    icon = "file-pdf";
  }
  if (["doc", "docx"].indexOf(ext) >= 0) {
    icon = "file-word";
  }

  const iconProps = {};

  if (props.iconSize) {
    iconProps.size = props.iconSize;
  }

  if (props.iconColor) {
    iconProps.color = props.iconColor;
  }

  const handleDelete = (e, f) => {
    props.removeFile && props.removeFile(f);
  };

  const handleHistoricalSet = (e, f) => {
    const confirmCallback = (continueResult) => {
      if (continueResult) {
        props.doToggleHistorical && props.doToggleHistorical(f);
      }
    };

    APP.instance.openConfirmDialog(
      f.historical ? "Restore?" : "Set as Historical?",
      "File: " + (props.text ? props.text : f.filename) + " will be " + (f.historical ? "Restored" : "set as Historical"),
      confirmCallback
    );
  };

  const openPDFPreview = (file, e) => {
    let fileToUse = file;
    if (file.filename.toLowerCase().endsWith(".pdf") || file.filename.toLowerCase().endsWith(".xlsx")) {
      e.stopPropagation();
      e.preventDefault();

      if (useId && file.id) {
        APP.central.File.findById(f.id).then((r) => {
          if (r.result) {
            fileToUse = r.result;
            APP.instance.createModal(<SimplePDFPreview file={fileToUse} />, { modal_name: "File:" + file.filename });
          }
        });
      } else {
        APP.instance.createModal(<SimplePDFPreview file={file} />, { modal_name: "File:" + file.filename });
      }
    }
  };

  return (
    <div className="file-item">
      <a href={"/file/download/" + (useId ? f.id : f.storageIdentifier) + "/" + f.filename} onClick={(e) => openPDFPreview(f, e)}>
        <Icon {...iconProps} icon={icon} /> <span className="filename">{props.text || f.filename}</span>
      </a>
      {showDelete ? <Icon icon="trash" title="Permanently Delete" onClick={(e) => handleDelete(e, f)} /> : null}
      {showHistoricalSet ? (
        f.historical ? (
          <Icon icon="trash-restore" color="green" title="Restore" onClick={(e) => handleHistoricalSet(e, f)} />
        ) : (
          <Icon icon="trash" title="Set As Historical" onClick={(e) => handleHistoricalSet(e, f)} />
        )
      ) : null}
    </div>
  );
}

export function FileLink(props) {
  const bound = useContext(BoundDataContext);
  const [fileList, setFileList] = useState([]);

  const refreshList = () => {
    if (bound.magicalGet("id") > 0) {
      APP.central.File.list(bound.magicalGet("__type"), bound.magicalGet("id")).then((r) => {
        if (r.result != null) {
          if (props.fileCategory) {
            setFileList(_.filter(r.result, (s) => s.fileCategory == props.fileCategory));
          } else {
            setFileList(r.result);
          }
        }
      });
    }
  };

  useEffect(() => {
    if (bound && bound.magicalGet && bound.magicalGet("__type")) {
      refreshList();
    }
  }, [bound.to]);

  const openFile = (e) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.onchange = (e) => {
      const uploadResult = upload(e.target.files[0], bound, null, props);
    };
    fileInput.click();
  };

  const previewFile = (fileForPreview) => {
    APP.instance.createModal(<SimplePDFPreview file={fileForPreview} />, { modal_name: "PDF:" + fileForPreview.filename });
  };

  //let theFile = fileList[0];
  let theFile = _.reverse(_.sortBy(fileList, ["id"]));

  if (props.file) {
    theFile = [props.file];
  }

  if (theFile && _.isObject(theFile)) {
    return (
      <Fragment>
        {_.map(theFile, (f, index) => {
          return (
            <Fragment>
              {index > 0 ? <br /> : null}
              <div className="block blueish">
                {" "}
                <FileItem iconColor="white" iconSize="2x" file={f} key={f.id || f.filename} />{" "}
              </div>
              {props.showPreview ? (
                <Button onClick={() => previewFile(f)} className="pdf-preview-button">
                  <Icon icon={props.previewIcon ? props.previewIcon : "search"} size="2x" color="white" />
                </Button>
              ) : null}
            </Fragment>
          );
        })}
      </Fragment>
    );
  }

  if (props.showOnlyIfExists) {
    return <div className="no-file"></div>;
  }

  return (
    <Fragment>
      <div className="block blueish" onClick={openFile}>
        <Icon color="white" size="2x" icon="file-pdf" />
        {props.text}
      </div>
      {props.showPreview ? (
        <div className="block blueish" onClick={previewFile}>
          <Icon color="white" size="2x" icon={props.previewIcon ? props.previewIcon : "file-magnifying-glass"} />
        </div>
      ) : null}
      {props.text}
    </Fragment>
  );
}

export default function File(props) {

  const MAX_FILE_SIZE = 10000000; //10MB

  const [className, setClassName] = useState();
  const bound = useContext(BoundDataContext);
  const [fileList, setFileList] = useState([]);
  const [activeDrag, setActiveDrag] = useState(false);
  const fileContainerRef = useRef(null);

  const histShow = useRef({ histShowStatus: false });

  useLayoutEffect((a, b, c) => {
    if (fileContainerRef.current != null) {
      fileContainerRef.current.parentElement.parentElement.ondragover = (e) => prepDropAction(e);
      fileContainerRef.current.parentElement.parentElement.ondragleave = (e) => prepDropAction(e);
      fileContainerRef.current.parentElement.parentElement.ondrop = (e) => prepDropAction(e);
    }
  });

  const doToggleHistorical = (f) => {
    APP.central.File.setHistorical(f.id, !f.historical).then((r) => {
      if (r.result) {
        const newFileList = _.map(fileList, (file) => {
          if (file.id == f.id) {
            file.historical = !file.historical;
          }
          return file;
        });
        setFileList(newFileList);
      } else {
        APP.instance.openConfirmDialog("Unable to update file historical", "Failed");
      }
    });
  };

  const removeFile = (f) => {
    APP.central.File.delete(f.id).then((r) => {
      if (r.result == true) {
        const newFileList = _.filter(fileList, (file) => {
          return file.id != f.id;
        });

        setFileList(newFileList);
      } else {
        APP.instance.openConfirmDialog("Unable to delete file", "Failed");
      }
    });
  };

  const refreshList = () => {
    if (bound && bound.magicalGet) {
      if (bound.magicalGet("id") > 0) {
        APP.central.File.list(bound.magicalGet("__type"), bound.magicalGet("id")).then((r) => {
          if (r.result != null) {
            setFileList(r.result);
            props.onFileList && props.onFileList(r.result);
          }
        });
      }
    }
  };

  useEffect(() => {
    if (bound && bound.magicalGet && bound.magicalGet("__type")) {
      refreshList();
    }
  }, [bound.to]);

  const filesToUpload = useRef([]);

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setClassName("dropped");

    const file = e.dataTransfer.files[0];
    let doUpload = true;

    if (props.beforeUpload) {
      props.beforeUpload();
    }
    _.each(e.dataTransfer.files, (f) => {
      if(f && f.size && f.size > MAX_FILE_SIZE){
        APP.alert("File(s) size is too large, please upload a file smaller than 2MB");
        doUpload = false;
      }
    });

    if(!doUpload){
      return;
    }


    _.each(e.dataTransfer.files, (f) => {
      filesToUpload.current.push(f);

      const uploadResult = upload(
        f,
        bound,
        (f, result) => {
          _.remove(filesToUpload.current, (g) => g == f);
          refreshList();
          props.onUpload && props.onUpload(result);
        },
        props
      );
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setClassName("ready_to_drag");
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setClassName("");
  };

  /*const files = fileList.map((f) => {
		return <FileItem file={f} key={f.id || f.filename} removeFile={removeFile} doToggleHistorical={doToggleHistorical} showHistoricalSet={props.showHistoricalSet}/>;
	});*/

  const files = histShow.current.histShowStatus
    ? _.map(fileList, (f) => {
        return <FileItem file={f} key={f.id || f.filename} removeFile={removeFile} doToggleHistorical={doToggleHistorical} showHistoricalSet={props.showHistoricalSet} />;
      })
    : _.map(_.filter(fileList, ["historical", false]), (f) => {
        return <FileItem file={f} key={f.id || f.filename} removeFile={removeFile} doToggleHistorical={doToggleHistorical} showHistoricalSet={props.showHistoricalSet} />;
      });

  const openFile = (e) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    let doUpload = true;
    fileInput.onchange = (e) => {
      _.each(e.target.files, (f) => {
        if(f && f.size && f.size > MAX_FILE_SIZE){
          APP.alert("File(s) size is too large, please upload a file smaller than 2MB");
          doUpload = false;
        }
      });

      if(!doUpload){
        return;
      }

      const uploadResult = upload(
        e.target.files[0],
        bound,
        (f, result) => {
          _.remove(filesToUpload.current, (g) => g == f);
          refreshList();
          props.onUpload && props.onUpload(result);
        },
        props
      );
    };
    fileInput.click();
  };
  const label = props.label ? props.label : "Files/Attachments";
  const info = props.info ? props.info : "";

  const mainClass = props.bigDrop ? "drop_target big_drop_target" : "drop_target";

  const executeMaxAreaDropAction = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type == "dragover") {
      setActiveDrag(true);
    } else if (e.type == "dragleave") {
      const parentLocation = e.currentTarget.getBoundingClientRect();

      if (e.x < parentLocation.left || e.x > parentLocation.right || e.y < parentLocation.top || e.y > parentLocation.bottom) {
        setActiveDrag(false);
      }
    } else if (e.type == "drop") {
      setActiveDrag(false);
      handleDrop(e);
    }
  };

  const prepDropAction = (e) => {
    if (_.map(e.path, (el) => el.className).indexOf("file-item") == -1) {
      //console.log('I should keep this file');
      executeMaxAreaDropAction(e);
    } else {
      //we are over another event so do nothing with this event
    }
  };

  return (
    <div className={mainClass + " " + className} ref={fileContainerRef} onDrop={(e) => handleDrop(e)} onDragLeave={(e) => handleDragLeave(e)} onDragOver={(e) => handleDragOver(e)}>
      <h4>
        {label} <em>{filesToUpload.current.length > 0 ? "Uploading " + filesToUpload.current.length : info}</em>
        {props.showHistoricalSet ? (
          <Bound to={histShow.current} onChange={refreshList}>
            <InputToggleSwitch
              field="histShowStatus"
              icon="eye"
              className="inline"
              title={histShow.current.histShowStatus == undefined || histShow.current.histShowStatus == false ? "Historical Not Shown" : "Historical Shown"}
            />
          </Bound>
        ) : null}
      </h4>

      {props.showFiles == false ? null : files}
      {props.bigDrop ? (
        <>
          {" "}
          <Button onClick={openFile}>
            Browse Files <Icon icon="upload" color="white" />
          </Button>{" "}
        </>
      ) : (
        <Icon icon="upload" onClick={openFile} />
      )}
      <div className={"upload-area " + (activeDrag ? "dragging" : "")}>{activeDrag == true ? <span className="dragging-text">Drop To Upload</span> : null}</div>
    </div>
  );
}

// onDragOver={(e)=>uploadAreaMouseEvents(e)} onDragLeave={(e) =>uploadAreaMouseEvents(e)} onClick={(e)=>uploadAreaMouseEvents(e)} onDrop={(e)=>uploadAreaMouseEvents(e)}

File.propTypes = {
  /** callback for a file is dropped or selected.  */
  onUpload: PropTypes.func,
  beforeUpload: PropTypes.func,
  label: PropTypes.string,
  bigDrop: PropTypes.bool,
  useExisting: PropTypes.bool, //tells the server to not save a new file and just return an existing one
  showFiles: PropTypes.bool, //hides list of files
};
