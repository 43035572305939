
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { DatePicker, FlexBreak, Grid, GridItem, Icon, InputDecimal, InputPercent, InputSelect, InputText, LinkButton, Loading, LookupInputSelect } from '@opidcore/components';
import { APP } from '../../../js';
import ViewContractFiles from '../UI/Contract/ViewContractFiles';
import PriceForecast from '../UI/Contract/PriceForecast';
import { NiceBox, NiceBoxContainer, NiceBoxSection } from './NiceBox';
import { useData } from '@opidcore/hooks/WTF';
import Bound, { BoundView, DataContext } from '@opidcore/components/Bound';
import _, { isObject } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Util } from '@opidcore/Util';
import ContractBrowser from '../components/ContractBrowser';
import { ReportTemplateLookupSelector } from '../UI/Customer/EditClient';
import InvoiceHistory from '../UI/Data/View/InvoiceHistory.jsx';
import SkipModal from '../UI/SkipModal.jsx';
import Button from '../../../../OpidCore/public/js/components/Button.js';

//use erp grab to fetch each block info

const PriceIncreaseForecastTool = ({ contractType, contract, service }) => {
	const [contractData, contractDataSet] = useData("price-forecast-tool" + contractType + "" + contract.id, APP.central.ContractLineIncrease.fetchData, { contractType: contractType, contractId: contract.id, siteId: null, serviceId: service.id });
	const [futureForecasts, setFutureForecasts] = useState(undefined);

	useEffect(()=>{
		setFutureForecasts(getFutureForecasts());
	}, [contractDataSet.loading]);
	
	const getFutureForecasts = () =>{
		if (contractData[0] != undefined) {
			return _.filter(contractData[0].forecasts, (f) => {
				return moment(f.effectiveDate).isAfter(moment());
			});
		}
		
		return undefined;
	}

	const priceIncreaseForcast = {
		contractId: contract.id,
		contractFriendlyId: contract.friendlyId,
		contractType: contractType,
		contractData: contractData,
		futureForecasts: futureForecasts,
		afterUpdate: ()=>{ contractDataSet.fetch(); }
	};

	const openForecast = () => {
		if (contract.id == undefined) {
			APP.instance.openConfirmDialog("Failed to continue.", "Contract Not Set");
			return;
		}

		const title = Util.capitalizeWords(contractType, "_") + " #" + contract.friendlyId + ": Price Forecast";

		APP.instance.createModal(<PriceForecast {...priceIncreaseForcast} />, { modal_name: title });
	};

	let warning = undefined;

	if (contractType != "VendorContract" && (futureForecasts == undefined || futureForecasts.length <= 0)) {
		warning = <span className="notification fa-layers-counter" style={{ background: "Tomato" }} title="No future forecasts scheduled"><strong>!</strong></span>;
	}

	return <div className="modal-link" onClick={openForecast}><Icon title="Price Forecast" icon="chart-line" size="3x" />{warning}</div>;
}

const ContractTool = ({contractType, contract}) => {

	const openContract = () => {
		if(contract.id == undefined){
			APP.instance.openConfirmDialog("Failed to continue.", "Contract Not Set");
			return;
		}

		let title = Util.capitalizeWords(contractType, "_") + " #" + contract.friendlyId + ": Files";

		APP.instance.createModal(<ViewContractFiles contract={contract} contractType={contractType}/>, { modal_name: title});
	};

	return <div className="modal-link" onClick={openContract}><Icon title="View Contract Files" icon="file-alt" size="3x" /></div>;
}

export const CompensationSelector = (props) => {
	const boundMagic = useContext(DataContext);
	let [compensationTypes, setCompensationTypes] = useState([]);
	const [compensationTypesData, compensationTypesDataSet] = useData("compensationType");
	const compensationDataRef = useRef([]);
	
	const _setCompensationTypes = (clientType) => {
		if (clientType != null) {
			let testString = clientType.name;

			if(testString == undefined && _.isString(clientType)){
				testString = clientType;
			}

			if (_.isString(testString)) {
				if(compensationDataRef.current.length > 0 && clientType != null && testString.trim().length > 0){
					let res = _.filter(compensationDataRef.current, (cTDRow)=>{ return cTDRow.clientType.indexOf(testString) > -1 });
	
					setCompensationTypes(res);
				}
			} else {
				console.log("JasonBlocks.jsx: Something weird is going on with ClientType.")
			}
		} else { 
			setCompensationTypes([])
		}
	}

	useEffect( () => {
		compensationDataRef.current = compensationTypesData;
		if (boundMagic.magicalGet("clientType") != undefined) {
			_setCompensationTypes(boundMagic.magicalGet("clientType"))
		}
	}, [compensationTypesDataSet.loading]); 

	useEffect( () => {
		boundMagic.magicalState("clientType", (clientType)=>_setCompensationTypes(clientType)); 
	}, [boundMagic]);


	return <InputSelect {...props} field="compensationType" showEmpty={!props.showEmpty ? props.showEmpty: true} optionsCollection={compensationTypes} store="object"/>
}

export const ClientInfo = ({disableEditing = false}) => {
	const boundMagic = useContext(DataContext);

	const service = boundMagic.getBound("service", undefined, {boundId: "clientInfo-service"});
	const site = boundMagic.getBound("site", undefined, {boundId: "clientInfo-site"});
	const client = boundMagic.getBound("client", undefined, {boundId: "clientInfo-client"});	
	const clientContract = boundMagic.getBound("clientContract");

	let typesBound = client;
	let clientTypeLabel = "Client Type";

	if (client.clientTypeOverrideLevel == "site"){
		typesBound = site;
		clientTypeLabel = "Client Type (Site)";
	}

	if (client.clientTypeOverrideLevel == "service"){
		typesBound = service;
		clientTypeLabel = "Client Type (Service)";
	}

	let reportTemplateBound = client;
	let reportTemplateLabelAdjuster = "";

	if (client.reportTemplateOverrideLevel == "site"){
		reportTemplateBound = site;
		reportTemplateLabelAdjuster = " (Site)";
	} else if (client.reportTemplateOverrideLevel == "service"){
		reportTemplateBound = service;
		reportTemplateLabelAdjuster = " (Service)";
	}
	
	return <div className="client-info">
		<NiceBoxContainer>
			<h4>Client & Site Info <PriceIncreaseForecastTool contractType="ClientContract" contract={clientContract} service={service}/></h4>
			<NiceBox label="Client - Site">
				{site.friendlyId}
			</NiceBox>
			<NiceBox label="Serv. #">
				{service.friendlyId}
			</NiceBox>
			<NiceBox label="Client">
				<LinkButton target={"/ui/clients/" + client.friendlyId} title={"Client " + client.friendlyId} modalName={"Client " + client.friendlyId} className="plainLink">
					{client.name}
              	</LinkButton>
			</NiceBox>
			<NiceBox label="Site">
				{site.name}
			</NiceBox>
			<NiceBox label="Site Tax">
				<BoundView boundTo={site} disableEditing={disableEditing}>					
					<LookupInputSelect what="taxes" field="defaultTax" display={(tax)=>{ return tax.name + " - " + tax.rate + "%"  }} showEmpty={true}/>
				</BoundView>
			</NiceBox>
			<NiceBox label="Service Address">
				<BoundView boundTo={service} disableEditing={disableEditing}>					
					<InputText field="serviceAddress" placeholder={site.address}/>
				</BoundView>
			</NiceBox>
			<NiceBox label={clientTypeLabel}>
				<BoundView boundTo={typesBound} disableEditing={disableEditing}>
					<LookupInputSelect what="clientType" field="clientType" store="object"/>
				</BoundView>
			</NiceBox>
			<NiceBox label="Compensation Type">
				<BoundView boundTo={typesBound} disableEditing={disableEditing}>
					<CompensationSelector />
				</BoundView>
			</NiceBox>
			<NiceBox label={"Report Template" + reportTemplateLabelAdjuster}>
				<BoundView boundTo={reportTemplateBound} disableEditing={disableEditing}>
					<ReportTemplateLookupSelector field="reportTemplate"/>
				</BoundView>
			</NiceBox>
			<NiceBox label="Client Contract #">
				<BoundView boundTo={boundMagic.getBound()} disableEditing={disableEditing}>
					{/*<SelectContract type="client" typeField="customerId" contractField="clientContract"/>*/}
          {<ContractBrowser type="client" typeField="customerId" contractField="clientContract"/>}
				</BoundView>
			</NiceBox>
		</NiceBoxContainer>
	</div>
}

//export const VendorInfo = ({disableEditing=true, setServiceVendor=null}) => {
export const VendorInfo = ({disableEditing=true}) => {
	const boundMagic = useContext(DataContext);
	const [vendor, setVendor] = useState(null);
	const service = boundMagic.getBound("service");
	const vendorContract = boundMagic.getBound("vendorContract");
	const contracts = boundMagic.getBound("contracts");
	
	useEffect(()=>{
		setVendor(boundMagic.getBound("service").vendor); 
	}, [boundMagic]);

	const updateVendor = (service)=>{
		setVendor(service.vendor);
		//setServiceVendor(service.vendor);
		
	}

	return <div className="vendor-info">
		<NiceBoxContainer>
			<h4>Vendor Info <PriceIncreaseForecastTool contractType="VendorContract" contract={vendorContract} service={service}/></h4>
			<NiceBox label="Vendor #">
				{vendor != undefined? vendor.friendlyId : ""}
			</NiceBox>
			{/* <NiceBox label="Vendor" serviceReqField={true}> */}
			<NiceBox label="Vendor">
				<BoundView boundTo={service} disableEditing={disableEditing}>					
					<LookupInputSelect what="active_vendors" fetch={APP.central.Vendor.listActiveVendors} fetchOptions={{vendorId: (service.vendor != undefined? service.vendor.friendlyId: undefined)}} field="vendor" showEmpty={true} bound={true} store="object" onChange={(service)=>updateVendor(service)}/>
				</BoundView>
			</NiceBox>
			<NiceBox label="Vendor Contract #">
				<BoundView boundTo={contracts} disableEditing={disableEditing}>
					{/*<SelectContract type="vendor" typeField="vendor" contractField="vendorContract"/>*/}
          			{<ContractBrowser type="vendor" typeField="vendor" contractField="vendorContract"/>}
				</BoundView>
			</NiceBox>
		</NiceBoxContainer>
	</div>;
}

export const BaselineInfo = ({disableEditing = false}) => {
	const boundMagic = useContext(DataContext);

	const baselineContract = boundMagic.getBound("baselineContract");

	const statusOptions = [{key: "active", value: "Active"}, {key: "estimate", value: "Estimate"}];

	return <div className="baseline-info">
		<NiceBoxContainer>
			<h4>Baseline Info</h4>
			<NiceBox label="Baseline Contract #">
				{baselineContract.friendlyId}
			</NiceBox>
		</NiceBoxContainer>
	</div>
}

const BillingInfo = ({disableEditing = false}) => {
	const boundMagic = useContext(DataContext);

	const service = boundMagic.getBound("service");
	const vendorContract = boundMagic.getBound("vendorContract");
	const client = boundMagic.getBound("client");	

	const openInvoiceHistory = ()=>{
		APP.instance.createModal(<InvoiceHistory service={service} />, {modal_name: service.friendlyId + " Invoice History"});
	}
	
	return <div className="billing-info">
		<NiceBoxContainer>
			<h4>Billing Info</h4>
			<NiceBox label="Client Billing Time">
				<BoundView boundTo={client} disableEditing={disableEditing}>
					<LookupInputSelect what="lookup:Billing Time" field="invoiceTiming" forcedDefault={true} defaultValue={"preBilled"} />
				</BoundView>
			</NiceBox>
			{/* <NiceBox label="Vendor Billing Time" serviceReqField={true}> */}
			<NiceBox label="Vendor Billing Time">
				<BoundView boundTo={service} disableEditing={disableEditing}>
					{/* <LookupInputSelect what="lookup:Billing Time" field="vendorInvoiceTiming" forcedDefault={true} defaultValue={"postBilled"}   serviceFieldName="serviceVendorBillingTime"/> */}
					<LookupInputSelect what="lookup:Billing Time" field="vendorInvoiceTiming"/>
				</BoundView>
			</NiceBox>
      <NiceBox label="Permanence">
        <BoundView boundTo={service} disableEditing={disableEditing}>
          <LookupInputSelect what="lookup:permanence" field="permanence" showEmpty={true} forcedDefault={true} defaultValue={"permanent"}/>
        </BoundView>
      </NiceBox>
			{/* <NiceBox label="WSC Service Start Date" serviceReqField={true}> */}
			<NiceBox label="WSC Service Start Date">
				<BoundView boundTo={service} disableEditing={disableEditing}>
					<DatePicker field="serviceStartDate"/>
				</BoundView>
			</NiceBox>
      <NiceBox label="Expected Removal Date">
				<BoundView boundTo={service} disableEditing={disableEditing}>
					<DatePicker field="expectedRemovalDate"/>
				</BoundView>
			</NiceBox>
			<NiceBox label="Invoice History">
				<Icon icon="history" title="Invoice History" size="2x" onClick={()=>openInvoiceHistory()}/>
			</NiceBox>
		</NiceBoxContainer>
	</div>
}

const SustainabilityInfo = ({disableEditing = false}) => {
	const boundMagic = useContext(DataContext);

	const service = boundMagic.getBound("service");
	const site = boundMagic.getBound("site");
	const [showWADensity, setShowWADensity] = useState(false);

	useEffect( ()=>{
		service._ourMagic.magicalState("sustainabilityReviewSetting", (val)=>{
			if (val == "estimate_audit"){
				setShowWADensity(true);
			}else{
				setShowWADensity(false);
			}
		});

		site._ourMagic.magicalState("sustainabilityReviewSetting", (val)=>{
			if (val == "estimate_audit"){
				setShowWADensity(true);
			}else{
				setShowWADensity(false);
			}
		});
	}, [service, site]);

  let something = 0; //TEST

	return (
    <div className="sustainability-info">
      <NiceBoxContainer>
        <h4>Sustainability Info</h4>
        <NiceBox label="(Site) Industry">
          <BoundView boundTo={site} disableEditing={disableEditing}>
            <LookupInputSelect what="lookup" field="environmentalIndustry" />
          </BoundView>
        </NiceBox>
        <NiceBox label="Sub-Material">
          <BoundView boundTo={service} disableEditing={disableEditing}>
            <LookupInputSelect what="child_materials" fetch={APP.central.Material.listChildMaterials} fetchOptions={{parentId: (service.material != undefined ? service.material : null)}} bound={true} store="object" field="sustainabilitySpecificMaterial" showEmpty={true} />
          </BoundView>
        </NiceBox>
        {service.environmentalDensity ? (
          <NiceBox label="(Service) Environmental Density">
            <BoundView boundTo={service} disableEditing={disableEditing}>
              <LookupInputSelect what="lookup" field="environmentalDensity" />
            </BoundView>
          </NiceBox>
        ) : (
          <NiceBox label="(Site) Environmental Density">
            <BoundView boundTo={site} disableEditing={disableEditing}>
              <LookupInputSelect what="lookup" field="environmentalDensity" />
            </BoundView>
          </NiceBox>
        )}
        <NiceBox label="Sustainability Review Setting">
          <BoundView boundTo={service} disableEditing={disableEditing}>
            <LookupInputSelect what="lookup" field="sustainabilityReviewSetting" forcedDefault={true} defaultValue={"Estimate"}/>
          </BoundView>
        </NiceBox>
        { showWADensity ? <NiceBox label="WA Density (kgs / yard)">
          <BoundView boundTo={service} disableEditing={disableEditing}>
            <InputDecimal field="densityOverride" />
          </BoundView>
        </NiceBox> : null }
        <NiceBox label="Container Fullness">
          <BoundView boundTo={service} disableEditing={disableEditing}>
            <InputDecimal field="containerFullness" defaultValue={"80%"}/>
          </BoundView>
        </NiceBox>
		<NiceBox label="Sustainability Frequency Method">
			<BoundView boundTo={service} disableEditing={disableEditing}>
				<LookupInputSelect what="lookup" field="sustainabilityFrequencyMethod" />
			</BoundView>
		</NiceBox>
      </NiceBoxContainer>
    </div>
  );
}

function SavingsPercent(props){
	const boundMagic = useContext(DataContext);
	const [priceSavingsRate, setPriceSavingsRate] = useState(0);
	const [volumeSavingsRate, setVolumeSavingsRate] = useState(0);
	const [split, setSplit] = useState(priceSavingsRate != volumeSavingsRate? true: false);
	const comboRateRef = useRef(0);
	const priceSavingsRateRef = useRef(0);
	const volumeSavingsRef = useRef(0);

	useEffect(()=>{
		priceSavingsRateRef.current = boundMagic.magicalGet("priceSavingsRate", 0);
		volumeSavingsRef.current = boundMagic.magicalGet("volumeSavingsRate", 0);

		const max = Math.max(priceSavingsRateRef.current, volumeSavingsRef.current);
		comboRateRef.current = max;

		const doSplit = priceSavingsRateRef.current != volumeSavingsRef.current? true: false;
		if(doSplit != split){
			setSplit(doSplit);
		}
	}, []);

	useEffect(()=>{
		priceSavingsRateRef.current = boundMagic.magicalGet("priceSavingsRate", 0);
		volumeSavingsRef.current = boundMagic.magicalGet("volumeSavingsRate", 0);
	}, [boundMagic])

	const toggleSplit = () =>{
		const newSplit = !split;

		if(newSplit == false){
			apply(boundMagic, "comboRate", comboRateRef.current);
		}
		
		setSplit(newSplit)
	}

	const apply= (uselessBound, field, value) =>{
		if(value != null){
			if(field == "comboRate"){
				boundMagic.magicalSet("priceSavingsRate", value);
				boundMagic.magicalSet("volumeSavingsRate", value);
			} else {
				boundMagic.magicalSet(field, value);
			}
		}
	}

	if (props.readOnly){
		return <>
			{priceSavingsRateRef.current + "%"}
			{priceSavingsRateRef.current != volumeSavingsRef.current ? ", " + volumeSavingsRef.current + "%" : ""}
		</>;
	}

	return <div className="savings" key="savings">
		<span className="savings-toggle" onClick={()=>toggleSplit()}>{split? "Group" : "Split"}</span>
		<Bound to={{comboRate: comboRateRef.current, priceSavingsRate: priceSavingsRateRef.current, volumeSavingsRate: volumeSavingsRef.current}}>
			{split == false? 
			<InputPercent field="comboRate" name="Combine" update={(bound, field, value)=>apply(bound, field, value)}/>
			:
			<><InputPercent field="priceSavingsRate" name="Price" update={(uselessBound, field, value)=>apply(uselessBound, field, value)}/>			
			<InputPercent field="volumeSavingsRate" name="Volume" update={(uselessBound, field, value)=>apply(uselessBound, field, value)}/></>
			}
		</Bound>
	</div>;
}

function DisablableCompactionSettings(props){
    const [useContainerCompactionSetting,setUseContainerCompactionSetting] = useState(false);
    const bound = useContext(DataContext);

	const _setUseContainerCompactionSetting = (val)=>{
		if(val == "true"){
			val = true;
		} else if(val == "false"){
			val = false;
		}

		setUseContainerCompactionSetting(val);
	};

	useEffect( ()=>{
		bound.magicalState("containerCompaction", _setUseContainerCompactionSetting, {defaultValue: false});
	}, []);

	const isCompacted = bound.magicalGet("containerCompaction");

	if(!isCompacted == true && props.readOnly){
		return <div key="compaction-settings" className="compaction-settings">
			-/-
		</div>
	}

    return <div key="compaction-settings" className="compaction-settings">
		{!props.readOnly? <InputSelect field="containerCompaction" options={{false: "No", true: "Yes"}} showEmpty={true} readOnly={props.readOnly}/> : ""}
		<InputDecimal field="containerCompactionSetting" disabled={!useContainerCompactionSetting} readOnly={props.readOnly}/>
	</div>
}


const EditServiceEquipmentSensors = ({siteEquipment = undefined}) =>{
    if(siteEquipment == undefined){
        return null;
    }

	const bound = siteEquipment._ourMagic;
    const handleCellAction = (e, whatWasClicked, gridCtx, sourceAction)=>{
        const modelName = "SiteEquipmentSensor";
		const bodyData = {};
		let toAction = "Create";

		if(sourceAction != undefined && sourceAction.toLowerCase().indexOf('delete') > -1){
			bodyData.data = whatWasClicked.row;
			toAction = "Delete";
			bodyData.existingRow = gridCtx.sortedData;
		} else {
			bodyData.defaults = {siteEquipment: siteEquipment, historical: 0, inventoryStatus: "New"};
		}
		
		APP.central.Grid.fetchGrid( modelName, toAction, bodyData ).then((r)=>{
			if(toAction == "Create"){
				bound.magicalAppend("siteEquipmentSensors", r.result.rows[0][modelName]);
			} else {
				if(r.result.rows[0] != undefined){
					if(r.result.rows[0][modelName] != undefined){
						gridCtx.setData(r.result.rows[0][modelName]);
					} else {
						gridCtx.setData(r.result.rows[0]);
					}
				}
			}
		});
	}

	const deleteRow = (e, toBeBound, whatWasClicked, gridCtx, sourceAction)=>{
		const confirmCallback = (continueResult)=>{
			if(continueResult){
				handleCellAction(e, whatWasClicked, gridCtx, "Delete");
			}
		}

		APP.instance.openConfirmDialog("Continue?", "Deleting is permanent." , confirmCallback);
	}

    const sensorGridProps = {
        rowBasis: "id",
        rowBasisGroup: "id",
        gridIdentifier: "sensors",
        appendable: {text: "add a sensor", add: null},
        key: "equipment_sensor_grid",
        scroll: true,
        rowActions: [{text: "Delete", action: (e, toBeBound, whatWasClicked, gridCtx, sourceAction)=>{ deleteRow(e, toBeBound, whatWasClicked, gridCtx, sourceAction); }}],
        onCellAction: handleCellAction,
		boundData: "siteEquipmentSensors",
        disableHoleActions: true
    }

    return <div key="edit_site_equipment_sensors">
        <h4>Sensors</h4>
		<Bound to={siteEquipment}>
			<Grid {...sensorGridProps}>
				<GridItem title="Sensor #"><InputText field="sensorNumber"/></GridItem>
				<GridItem title="Type"><InputText field="sensorType"/></GridItem>
				<GridItem title="Owner"><LookupInputSelect what="lookup:Site Equipment Owner" field="ownedBy" showEmpty={true}/></GridItem>
				<GridItem title="Status"><InputText field="sensorStatus"/></GridItem>
				<GridItem title="Status"><InputText field="inventoryStatus"/></GridItem>
			</Grid>  
		</Bound>
    </div>
}

const ServiceSensors = ({readOnly = true}) =>{
	const siteEquipment = useContext(DataContext);
	
	const doStuff = ()=>{
		APP.instance.createModal(<EditServiceEquipmentSensors siteEquipment={siteEquipment.to} />, {modal_name: "Service Sensors"});
	}

	const firstSensor = _.head(siteEquipment.magicalGet("siteEquipmentSensors", []));
	let text = [];
	
	if(firstSensor != undefined){
		text.push(<span key="preview" className="preview">{firstSensor.sensorNumber}</span>);

		if(siteEquipment.magicalGet("siteEquipmentSensors", []).length > 1){
			text.push(<span key="more" className="more">{(siteEquipment.magicalGet("siteEquipmentSensors", []).length-1)}</span>);
		}
	}

	return <div key="service-sensors" className="service-sensors">
		<span className="sensor-preview">{text}</span>
		{readOnly ? "" : <Icon icon="plus" className="sensor-add" title="Add Sensor" onClick={()=>doStuff()} />}
	</div>
}

//const ServiceInfo = ({disableEditing = false, setServiceSchedule = null}) => {
const ServiceInfo = ({disableEditing = false, setServiceSchedule = null}) => {
	const boundMagic = useContext(DataContext);
	let [showServiceDays, setShowServiceDays] = useState(true);

	const service = boundMagic.getBound("service");
	const clientContract = boundMagic.getBound("clientContract");
	const clientContractLine = boundMagic.getBound("clientContractLine");
	const siteEquipment = boundMagic.getBound("service.siteEquipment");

	let [showRepeatDate, setShowRepeatDate] = useState(service.schedule && service.schedule.monthlyFactor >0 && service.schedule.monthlyFactor < 4.33);

  const scheduleChangeCheck = () => {
	//setServiceSchedule(service.schedule); 
    setShowRepeatDate(service.schedule && service.schedule.monthlyFactor >0 && service.schedule.monthlyFactor < 4.33);
	//setShowServiceDays(service.schedule.frequency === "On Call" ? false : true); 
  }

  const openSkipModal = () => {    
    const skipServiceData = {
      services_friendly: service.friendlyId,
      service_ids: service.id,
      services_descriptions: service.description,
      query_period: null,
    }

    APP.instance.createModal(<SkipModal data={skipServiceData} />, {modal_name: "Skip", className:"task-note-modal"});
  };
	
	return (
    <div className="service-info">
      <NiceBoxContainer>
        <h4>Service Info</h4>

        <NiceBox label="Skip Missing Until" title={service.skipMissingReason ? service.skipMissingReason : "-"}>
          <BoundView boundTo={service} disableEditing={disableEditing}>
            {(service && service.skipMissingUntil) ? <span>{moment(service.skipMissingUntil).format("MM/DD/YYYY")}</span> : <Button onClick={() => openSkipModal()}>Skip</Button>}
          </BoundView>
        </NiceBox>

        <NiceBox label="Service Schedule">
          <BoundView boundTo={service} disableEditing={disableEditing}>
            <LookupInputSelect what="service_schedule" field="schedule" display="frequency" showEmpty={true} store={"object"} onChange={scheduleChangeCheck} />
            <InputDaysCheck field="scheduleDays" name="days" />
            {showRepeatDate ? <DatePicker name="Effective From" field="repeatDate" /> : <Fragment />}
          </BoundView>
        </NiceBox>

        <NiceBox label="Material">
          <BoundView boundTo={service} disableEditing={disableEditing}>
            <LookupInputSelect what="parent_materials" fetch={APP.central.Material.listParentMaterials} field="material" bound={true} showEmpty={true} />
          </BoundView>
        </NiceBox>

        <NiceBox label="Service Type">
          <BoundView boundTo={service} disableEditing={disableEditing}>
            <LookupInputSelect what="equipmenttype" field="equipmentType" showEmpty={true} />
          </BoundView>
        </NiceBox>

        <NiceBox label="Savings">
          <BoundView boundTo={clientContractLine} disableEditing={disableEditing}>
            <SavingsPercent />
          </BoundView>
        </NiceBox>

        <NiceBox label="Container Name">
          <BoundView boundTo={service} disableEditing={disableEditing}>
            <InputText field="containerName" />
          </BoundView>
        </NiceBox>

        <NiceBox label="Container Size">
          <BoundView boundTo={service} disableEditing={disableEditing}>
            <LookupInputSelect what="equipmentsize" field="equipmentSize" showEmpty={true} />
          </BoundView>
        </NiceBox>

        <NiceBox label="On Site Container Quantity">
          <BoundView boundTo={service} disableEditing={disableEditing}>
            <InputText field="quantityOnSite" />
          </BoundView>
        </NiceBox>

        <NiceBox label="Container Owner">
          <BoundView boundTo={siteEquipment} disableEditing={disableEditing}>
            <LookupInputSelect what="lookup:Site Equipment Owner" field="ownedBy" showEmpty={true} />
          </BoundView>
        </NiceBox>

        <NiceBox label="Sensors">
          <BoundView boundTo={siteEquipment} disableEditing={disableEditing}>
            <ServiceSensors />
          </BoundView>
        </NiceBox>

      </NiceBoxContainer>
    </div>
  );
}

export function SelectContract({type, typeField, contractField, readOnly = false}){
	const [selected, setSelected]  = useState(null);
	const [contractOptions, setContractOptions] = useState([]);

	let centralController = APP.central.VendorContract;
	let linkPath = "/ui/vendorcontract/edit/";
	
	if (type == "client"){
		centralController = APP.central.ClientContract;
		linkPath = "/ui/clientcontract/edit/";
	}
	
	const bound = useContext(DataContext);
	let serviceBound = null;
	if (type == "vendor"){
		serviceBound = bound.getParentMagic().getBound("service")._ourMagic;
	}else{
		//debugger;
	}

	useEffect( ()=>{
		if (serviceBound != null){
			serviceBound.magicalState(typeField,setSelected, null);
		}else{
			//client
			bound.magicalState(typeField,setSelected, null);
		}
	}, [serviceBound, bound]);

	const loadContractOptions = ()=>{
		
		let typeId = parseInt(selected);
		if (isNaN(typeId) && isObject(selected)){
			typeId = selected.id;
		}

		if (typeId > 0){
			centralController.listAll(typeId).then( (res)=>{
				setContractOptions(res.result);
			});
		}
	};

	useEffect( ()=>{
		loadContractOptions();
	}, [selected]);

	const addContract = (e)=>{
		//const serviceId = serviceBound.magicalGet("id");
		
		let typeId = parseInt(selected);
		if (isNaN(typeId) && isObject(selected)){
			typeId = selected.id;
		}
		centralController.createNew(typeId, 0 ).then( (r)=>{
			loadContractOptions();

		});
	};

	const options = contractOptions.map( (c)=>{
		return {key: c.id, value: c.nice_name };
	});

	const contract = bound.magicalGet(contractField);  

	return <div className="select-contract">
		{contract != null && contract.id > 0 ?  <Link to={linkPath + contract.friendlyId}><Icon title="View Contract Files" icon="file-alt" size="2x" /></Link> : ""}		
		<InputSelect readOnly={readOnly} appendable={true} field={contractField} handleAppendClicked={addContract} appendableLabel={"New Contract"} options={options}/>
	</div>;
}


//const JasonBlocks = ({serviceData, siteId, customerId, editing = false, altInfoBlock = null, setServiceVendor = null, setServiceSchedule = null}) => {
const JasonBlocks = ({serviceData, siteId, customerId, editing = false, altInfoBlock = null}) => {

  const useAltInfoBlockData = altInfoBlock != null;

	const [data, dataSet] = useAltInfoBlockData
    ? [null, null]
    : useData("jasonBlocks:" + serviceData.id + moment().format("YYYY-MM-DD hh:mm"), APP.central.JasonBlocks.fetchServiceEditInfoBlocks, {
        serviceId: serviceData.id,
        customerId: customerId,
        siteId: siteId,
      });
	const [editingWorld, setEditingWorld] = useState(false);

	useEffect(()=>{
		setEditingWorld(editing);
	}, [editing])

	if(!useAltInfoBlockData && data.length <= 0){
		return <Loading />; 
	}

  const boundTo = useAltInfoBlockData ? altInfoBlock : data[0];

	return <NiceBoxSection>
		<Bound to={boundTo} boundId="jasonBlocks" editing={editingWorld}>

			<ClientInfo disableEditing={useAltInfoBlockData}/>
			{/* <VendorInfo disableEditing={useAltInfoBlockData} setServiceVendor={setServiceVendor}/> */}
			<VendorInfo disableEditing={useAltInfoBlockData}/>
			<BaselineInfo disableEditing={useAltInfoBlockData}/>

			{/* <ServiceInfo disableEditing={useAltInfoBlockData} setServiceSchedule={setServiceSchedule}/> */}
			<ServiceInfo disableEditing={useAltInfoBlockData}/>
			
			<FlexBreak />

			
			<BillingInfo disableEditing={useAltInfoBlockData}/>			
			<SustainabilityInfo disableEditing={useAltInfoBlockData}/>			
		</Bound>
	</NiceBoxSection>;
}

export function InputDaysCheck({field="", readOnly=false}){
	const boundMagic = useContext(DataContext);
	const daysOfTheWeek = ['U','M','T','W','R','F','S'];
	const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
	const [daysSelected, setDaysSelected] = useState('');

	useEffect(()=>{
		setDaysSelected( boundMagic.magicalGet(field) );
	}, [boundMagic]);

	if (readOnly){
		const niceDays = _.join(_.map(daysSelected.split(' '), (d)=>dayNames[ _.indexOf(daysOfTheWeek,d) ]), ' ');
		return <div className="readOnly-field">
			{niceDays}
		</div>;
	}

	const handleDayClicked = (e)=>{
		const dayClicked = parseInt(e.currentTarget.value);
		const checked = e.currentTarget.checked;

		let newDaysSelected = daysSelected;
		if (checked == false){
			newDaysSelected = newDaysSelected.replace(daysOfTheWeek[dayClicked],'');
		}else{
			newDaysSelected += daysOfTheWeek[dayClicked];
		}

		const properDaysSelected = _.join(_.compact( _.map(daysOfTheWeek, (d)=>{ return newDaysSelected.indexOf(d) >= 0 ? d : null })), ' ');
		setDaysSelected( properDaysSelected );
		boundMagic.magicalSet(field, properDaysSelected);
	};
	
	const checks = _.map(daysOfTheWeek, (d,i)=>{
		return <div key={"day"+i}><em>{dayNames[i]}</em> <input type="checkbox" value={i} onChange={handleDayClicked} checked={daysSelected.indexOf(d) >= 0}/></div>
	});
	
	return <div className="field">
			<div style={{display: "flex", gap: "0.5em"}}>
				{checks}
			</div>
	</div>;
}

export default JasonBlocks;


