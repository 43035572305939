import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useNavBar } from "@opidcore/hooks/WTF";
import { Tabular, TabularColumn} from "@opidcore/components/Tabular";
import ActionBar from "@opidcore/components/ActionBar";
import PeriodSelect from "../../components/PeriodSelect";
import MultiOptionToggle from "../../components/MultiOptionToggle";
import { AccessControl, Bound, Button, HoverElement, Icon, InputMultiCheckbox, InputSelect, Kanban, Loading } from "@opidcore/components";
import moment from "moment";
import _ from "lodash";
import { FileItem } from "../../components/File";
import { NiceCurrency } from "../Nice";
import SimplePDFPreview from "../../components/SimplePDFPreview";
import Util from "@opidcore/Util";
import FullTextSearchable from "../../components/FullTextSearchable";
import { Truncated } from "../APInvoice/APInvoiceList";
import SkipModal from "../SkipModal";

export default function ARInvoiceList(props) {
  useNavBar("AR Invoices", []);

  const curPeriod = moment().subtract(1, "months").format("YYYY-MM");

  const stateRef = useRef({});
  const kanbanToggleRef = useRef({ kanbanShowStatus: false, viewBy: "service_period" });
  const allCols = useRef({ allCols: [] });
  const activeColRef = useRef({ activeCols: [] });

  const [activeCols, activeColsSet] = useState({ activeCols: [] });
  const [period, setPeriod] = useState(curPeriod);
  const [kanbanShow, setKanbanShow] = useState(false);
  const [reportStatus, setReportStatus] = useState([]);
  const [loading, setLoading] = useState(false);

  //const [data, dataSet] = useData("arinvoicelist", APP.central.Invoices.getReceivablesForPeriod, { period: period, viewBy: 'service_period' });
  const [arInvoices, setARInvoices] = useState([]);
  const [fetchARInvoices, setFetchARInvoices] = useState(false);
  const [updateCount, setUpdateCount] = useState(0);

  const downloadCostSavingsReport = (e, row) => {
    e.stopPropagation();
    APP.central.ARInvoice.downloadCostSavingsReport(row.invoice_id, false).then((r) => {
      const f = r.result;
      window.open("/file/download/" + f.storageIdentifier + "/" + f.filename);
    });
  };

  const doDelete = (row, e) => {
    e.stopPropagation();
    APP.confirm("Are you sure?", (res) => {
      if (res == true) {
        APP.central.ARInvoice.deleteInvoice(row.invoice_id).then((r) => {
          setFetchARInvoices(true);
        });
      }
    });
  };

  const createNewAR = () => {
    setLoading(true);

    APP.central.Snowman.createManualAR().then((r) => {
      if (r.result != undefined && r.result.ar != undefined) {
        APP.redirect("/ui/arinvoice/" + r.result.ar.id);
      } else {
        APP.alert("Failed to create a new AR");
        setLoading(false);
      }
    });
  };

  const doCopy = (row, e) => {
    e.stopPropagation();

    setLoading(true);
    APP.central.ARInvoice.CopyAR(row.invoice_id).then((r) => {
      if (r.result.id != undefined && r.result.id > 0) {
        APP.redirect("/ui/arinvoice/" + r.result.id);
      }
    });
  };

  //--------------------------------------------------------- USE EFFECT ---------------------------------------------------------

  useEffect(() => {
    const newUpdateCount = updateCount + 1;
    setUpdateCount(newUpdateCount);

    if(newUpdateCount > 1){
      const doFetch = setTimeout(() => {
        setFetchARInvoices(true);
      }, 300);

      return () => clearTimeout(doFetch);
    }
  }, [period, kanbanShow]);

  // set invoice status columns, report statuses
  useEffect(() => {
    APP.central.Lookup.list().then((r) => {
      const tempInvoiceStatus = r.result
        .filter((lookup) => lookup.name == "Invoice Status")
        .sort((a, b) => (a.parameter1 > b.parameter1 ? 1 : -1))
        .map((lookup) => {
          return lookup.key;
        });
      const optionsCollection = tempInvoiceStatus.map((s, index) => {
        let thing = {};
        thing.key = index;
        thing.label = s;

        return thing;
      });
      optionsCollection.unshift({ key: "missing", label: "missing" });
      allCols.current.allCols = optionsCollection;
      activeColsSet({ activeCols: optionsCollection });
      activeColRef.current.activeCols = optionsCollection;

      const tempReportStatus = r.result
        .filter((lookup) => lookup.name == "Report Status")
        .map((lookup) => {
          return lookup.key;
        });
      setReportStatus(tempReportStatus);
    });
  }, []);

  //--------------------------------------------------------- FUNCTIONS ---------------------------------------------------------

  const updateKanbanState = () => {
    setKanbanShow(kanbanToggleRef.current.kanbanShowStatus);
  };

  const updateRef = (them, magic, key) => {


    switch (key) {
      case "period":
        //setPeriod(stateRef.current.period);
        if (period != magic.to.period) {
          setPeriod(magic.to.period);
        }
        break;
      default:
        console.log("Case not covered, case was " + key + ".");
    }
  };

  const setColumns = () => {
    activeColRef.current.activeCols.sort((a, b) => a.key - b.key);
    activeColsSet(activeColRef.current);
    forceUpdate(); //above wasnt triggering a rerender for some reason (so its technically not needed)
  };

  // dont know why setting activeColsSet wasnt updating the state... should ask about this workaround
  const useForceUpdate = () => {
    const [value, setValue] = useState(0); // integer state
    return () => setValue((value) => value + 1); // update the state to force render
  };
  const forceUpdate = useForceUpdate();

  const openCostSavingsPreview = (row, e) => {
    e.stopPropagation();
    APP.central.ARInvoice.downloadCostSavingsReport(row.invoice_id, false).then((r) => {
      if (r.result != undefined) {
        const file = r.result;
        APP.instance.createModal(<SimplePDFPreview file={file} />, { modal_name: "PDF:" + file.filename });
      }
    });
  };

  const openInvoicePDFPreview = (row, e) => {
    e.stopPropagation();
    APP.central.File.list("ARInvoice", row.invoice_id).then((r) => {
      if (r.result && r.result.length > 0) {
        const file = _.find(r.result, (f) => f.fileCategory == "invoice");
        if (!file) {
          file = r.result[0];
        }
        if (file) {
          APP.instance.createModal(<SimplePDFPreview file={file} />, { modal_name: "PDF:" + file.filename });
        }
      }
    });
  };

  //--------------------------------------------------------- KANBAN FUNCTIONS ---------------------------------------------------------
  const kanbanCardContent = (props) => {
    const doDate = (date, format) => {
      if (date != undefined && date.trim().length > 0) {
        return moment(date, "YYYY-MM-DD").format(format);
      }

      return null;
    };

    let invoiceLinks = [];

    if (props.data.invoice_ids != undefined && props.data.invoice_ids.length > 0) {
      // invoice_ids is a string of comma separated invoice ids, separate them
      const invoiceIds = _.split(props.data.invoice_ids, ",");
      _.forEach(invoiceIds, (invoiceId, idx) => {
        invoiceLinks.push(
          <Link className="plain_link" onClick={(e) => e.stopPropagation()} to={"/ui/arinvoice/" + invoiceId}>
            {invoiceId}
          </Link>
        );

        if (idx < invoiceIds.length - 1) {
          invoiceLinks.push(<span className="spacer">| </span>);
        }
      });
    }

    return (
      <div className="waste_kanban-card_content">
        <Summary data={props.data} date={props.date} />
        <div>
          <small className="date">Billing Period: {props.data.billing_period}</small>
        </div>
        <div>
          <small className="date">Invoice Date: {doDate(props.data.date, "MMM Do YYYY")}</small>
        </div>
        <div>
          <small className="date">Service Date: {doDate(props.data.service_date, "MMM Do YYYY")}</small>
        </div>
        <div>
          <small className="invoicePreparer">Invoice Preparer: {props.data.invoice_preparer ? props.data.invoice_preparer_name : "-"}</small>
        </div>
        <div>
          <small className="billingAnalyst">Billing Analyst: {props.data.billing_analyst ? props.data.billing_analyst_name : "-"}</small>
        </div>
        <div>
          <small className="billingSystem">Billing System: {props.data.billing_system ? props.data.billing_system : "-"}</small>
        </div>
        <div className="invoiceNumber">Invoice #: {invoiceLinks}</div>
        <div className="total">
          Total: <NiceCurrency>{props.data.pre_tax_total}</NiceCurrency>
        </div>
        {props.data.accounting_id > 0 ? (
          <FileItem
            file={{
              filename: "invoice_" + props.data.accounting_id + ".pdf",
              id: props.data.invoice_file,
              relatedTable: "ARInvoice",
            }}
            useId={true}
          />
        ) : null}
      </div>
    );
  };

  const kanbanHeadingFunction = (ds) => {
    let total = 0;
    let count = 0;
    let isMissing = false;

    _.forEach(ds, (entry) => {
      total += entry.pre_tax_total;
      entry.status ? (entry.status == "missing" ? (isMissing = true) : null) : null;
      count++;
    });

    return (
      <div className="kanbanHeading">
        <div className="heading-chunk">
          Total: <NiceCurrency>{total}</NiceCurrency>
        </div>
        <div className="heading-chunk">
          <strong>{count}</strong> {isMissing ? "Clients with missing Invoices" : "Invoices"}
        </div>
      </div>
    );
  };

  const handleClick = (row) => {
    APP.redirect("/ui/arinvoice/" + row.invoice_id);
  };

  const tabularSort = (row) => {
    if (row.date == undefined) {
      return moment().format("YYYY-MM-DD") + "" + row.invoice_id;
    } else {
      return row.date + "" + row.invoice_id;
    }
  };

  // Returns user's fullname
  const getDefaultBillingAnalyst = () => {
    const fullName = APP.getWebSession().fullname;
    return fullName;
  }
  const defaultBillingAnalyst = getDefaultBillingAnalyst();

  //--------------------------------------------------------- RETURN ---------------------------------------------------------

  if (loading == true) {
    return <Loading />;
  }

  return (
    <div>
      {/* --------------------------------------------------------- ActionBar --------------------------------------------------------- */}
      <ActionBar title="Actions">
        {/*<Searchable ds={dataSet} searchLocal={true} />*/}

        <Bound to={stateRef.current} onChange={updateRef}>
          <PeriodSelect field="period" usePreviousMonth={true} />
        </Bound>

        <Bound to={kanbanToggleRef.current} onChange={updateKanbanState}>
          <InputSelect field="viewBy" options={{ billing_period: "Billing Period", service_period: "Service Period" }} />
          <MultiOptionToggle field="kanbanShowStatus" name="kanbanToggle">
            <div data-toggle-value={false}>List</div>
            <div data-toggle-value={true}>Kanban</div>
          </MultiOptionToggle>
        </Bound>
        <AccessControl currentPermissions={props.permissions} permission="W">
          <Button onClick={() => createNewAR()}>Create New</Button>
        </AccessControl>
      </ActionBar>

      {/* --------------------------------------------------------- Filter --------------------------------------------------------- */}

      <FullTextSearchable
        model={"Invoices"}
        method={"reasonableList"}
        setData={setARInvoices}
        doFetchData={fetchARInvoices}
        maxRows={-1}
        setDoFetchData={setFetchARInvoices}
        additionalParams={[period, kanbanToggleRef.current.kanbanShowStatus ? kanbanToggleRef.current.kanbanShowStatus : false, kanbanToggleRef.current.viewBy]}
        allowBlank={true}
        filterOptions={{
          columns: [
            { column: "customer_name", heading: "Client Name" },
            { column: "umbrella", heading: "Umbrella" },
            { column: "status", heading: "Status", lookup: "Invoice Status" },
            { column: "report_status", heading: "Report Status", lookup: "Report Status" },
            { column: "invoice_preparer_name", heading: "Invoice Preparer" },
            { column: "billing_analyst_name", heading: "Billing Analyst", default: defaultBillingAnalyst },
            { column: "billing_system", heading: "Billing System", lookup: "Billing System" },
          ],
        }}
      />

      {/* --------------------------------------------------------- Kanban | Tabular --------------------------------------------------------- */}

      {kanbanShow ? (
        <Fragment>
          <Bound to={activeColRef.current} onChange={setColumns}>
            <div className="ARInvoice-InputMultiCheckbox">
              <InputMultiCheckbox field="activeCols" optionsCollection={allCols.current.allCols} optionKey={"key"}>
                {(row) => <div className="tile-item">{row.label}</div>}
              </InputMultiCheckbox>
            </div>
          </Bound>

          <Kanban
            data={arInvoices}
            justData={true}
            //isLoading={dataSet.loading}
            status={activeColRef.current.activeCols.map((col) => {
              return col.label;
            })}
            cardContent={kanbanCardContent}
            headingFunction={kanbanHeadingFunction}
            showActions={false}
          />
        </Fragment>
      ) : (
        <Fragment>
          <Tabular pageSize={100} data={arInvoices} onClick={handleClick} paginationLocation={"both"}>
            <TabularColumn data={(row) => row.invoice_id} title="#" />
            <TabularColumn
              data={(row) => (
                <Link onClick={(e) => e.stopPropagation()} className="plain_link" to={"/ui/clients/" + row.customer_id}>
                  {row.customer_name}
                </Link>
              )}
              title="Client Name"
            />
            <TabularColumn
              data={(row) =>
                row.site_names != undefined ? (
                  <Truncated splitOn="|" maxCharacterLength={20}>
                    {row.site_names}
                  </Truncated>
                ) : null
              }
              title="Site(s)"
            />
            <TabularColumn data={(row) => row.date} title="Invoice Date" />
            <TabularColumn data={(row) => row.service_date} title="Service Date" />
            <TabularColumn data={(row) => <NiceCurrency>{row.pre_tax_total}</NiceCurrency>} title="Total" />
            <TabularColumn
              data={(row) => {
                return (
                  <Fragment>
                    {row.status}
                    {row.accounting_id > 0 ? (
                      <FileItem
                        file={{
                          filename: "invoice_" + row.accounting_id + ".pdf",
                          id: row.invoice_file,
                          relatedTable: "ARInvoice",
                        }}
                        useId={true}
                      />
                    ) : null}
                  </Fragment>
                );
              }}
              title="Status"
            />
            <TabularColumn data={(row) => row.report_status} title="Report Status" />
            <TabularColumn data={(row) => (row.invoice_preparer_name ? row.invoice_preparer_name : "-")} title="Invoice Preparer" />
            <TabularColumn data={(row) => (row.billing_analyst_name ? row.billing_analyst_name : "-")} title="Billing Analyst" />

            <TabularColumn
              data={(row) => (
                <span>
                  <Button onClick={(e) => downloadCostSavingsReport(e, row)}>Report</Button>
                </span>
              )}
              title="Cost Savings"
            />
            <TabularColumn
              data={(row) => {
                return (
                  <Fragment>
                    {""}
                    <Link onClick={(e) => e.stopPropagation()} to={"/ui/arinvoice/" + row.invoice_id}>
                      <Icon icon="caret-right" size="2x" />
                    </Link>
                  </Fragment>
                );
              }}
              title="Edit"
            />
            <TabularColumn
              data={(row) => {
                return <Icon className="action" icon="file-invoice-dollar" size="2x" onClick={(e) => openInvoicePDFPreview(row, e)} />;
              }}
            />
            <TabularColumn
              data={(row) => {
                return (
                  <span>
                    <AccessControl currentPermissions={props.permissions} permission="W">
                      <Icon onClick={(e) => doDelete(row, e)} icon="trash" size="2x" title="Delete" />
                    </AccessControl>
                  </span>
                );
              }}
            />
            <TabularColumn
              data={(row) => {
                return (
                  <span>
                    <AccessControl currentPermissions={props.permissions} permission="W">
                      <Icon onClick={(e) => doCopy(row, e)} icon="copy" size="2x" title="Copy" />
                    </AccessControl>
                  </span>
                );
              }}
            />
          </Tabular>
        </Fragment>
      )}
    </div>
  );
}

const Summary = ({ data }) => {
  // create sitesData
  const siteIds = data.site_ids ? data.site_ids.split(",") : [];
  const siteFriendlyIds = data.site_friendly_ids ? data.site_friendly_ids.split(",") : [];
  let siteNames = [];
  if (data.site_names != null) {
    if (data.site_names.split("|").length > data.site_names.split(",").length) {
      siteNames = data.site_names.split("|");
    } else {
      siteNames = data.site_names.split(",");
    }
  }
  //const siteNames = data.site_names ? data.site_names.split(",") : [];
  const sitesData = _.map(siteIds, (siteId, i) => {
    return {
      id: siteId,
      friendlyId: siteFriendlyIds[i],
      name: siteNames[i],
    };
  });
  // create servicesData
  const serviceIds = data.services ? data.services.split(",") : [];
  const serviceFriendlyIds = data.services_friendly ? data.services_friendly.split(",") : [];
  const serviceDescriptions = data.services_descriptions ? data.services_descriptions.split(",") : [];
  const serviceSkipMissingReasons = data.services_skip_missing_reasons ? data.services_skip_missing_reasons.split(",") : [];
  const servicesData = _.map(serviceIds, (serviceId, i) => {
    return {
      id: serviceId,
      friendlyId: serviceFriendlyIds[i],
      shortDescription: serviceDescriptions[i],
      skipReason: serviceSkipMissingReasons[i],
      historical: 0,
    };
  });

  const openService = (row, serviceId, e) => {
    APP.instance.createModal("/ui/services/edit/" + serviceId, { modal_name: "Service #" + serviceId });
  };

  const openSkipModal = () => {
    APP.instance.createModal(<SkipModal data={data} />, {modal_name: "Skip", className:"task-note-modal"});
  };

  const getincludeSkipReasonColumn = (servicesData) => {
    let includeSkipReasonColumn = false;
    _.forEach(servicesData, (service) => {
      if (service.skipReason != null) {
        includeSkipReasonColumn = true;
      }
    });
    return includeSkipReasonColumn;
  }

  const includeSkipReasonColumn = getincludeSkipReasonColumn(servicesData);

  return (
    <div className="summary">
      <Link className="plain_link" onClick={(e) => e.stopPropagation()} to={"/ui/clients/" + data.customer_id}>
        <strong>{data.customer_name}</strong>
      </Link>

      {sitesData.length > 0 ? (
        <div className="summary-sites">
          <HoverElement
            content={
              <Link to="#" onClick={null}>
                Sites
              </Link>
            }
          >
            <div className="combinedInvoiceHover">
              <Tabular data={sitesData}>
                <TabularColumn title="ID" data={(row) => <Link to={"/ui/sites/detail/" + row.friendlyId}>{row.friendlyId}</Link>} />
                <TabularColumn title="Name" data={(row) => row.name} />
              </Tabular>
            </div>
          </HoverElement>
        </div>
      ) : null}

      {servicesData.length > 0 ? (
        <div className="summary-sites">
          <HoverElement
            content={
              <Link to="#" onClick={null}>
                Services
              </Link>
            }
          >
            <div className="combinedInvoiceHover">
              <Tabular data={servicesData} defaultSort={(item) => Util.sortableFriendlyId(item.friendlyId)}>
                <TabularColumn
                  title="Service"
                  data={(row) => (
                    <Link to="#" onClick={(e) => openService(data, row.id, e)}>
                      {row.friendlyId}
                    </Link>
                  )}
                />
                <TabularColumn title="Description" data={(row) => row.shortDescription} />
                {includeSkipReasonColumn ? <TabularColumn title="Skip Reason" data={(row) => row.skipReason ? row.skipReason : "-"} /> : null}
              </Tabular>
            </div>
          </HoverElement>
          {data.status == "missing" ? <Button onClick={() => openSkipModal()}>Skip</Button> : null}
        </div>
      ) : null}
    </div>
  );
};

