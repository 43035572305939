import React, { Fragment, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Icon, TabularColumn, TabularDataSet, Loading } from "@opidcore/components";
import { useData, useNavBar } from "@opidcore/hooks/WTF";
import CesarFilter from "../../components/CesarFilter";
import { FileItem } from "../../components/File";
import { Truncated } from "../APInvoice/APInvoiceList";
import { NiceCurrency, NicePerson } from "../Nice";

export default function ListInvoices(props) {
  const params = useParams();
  const clientId = params.id;
	const nav = useNavBar(clientId == "0" ? "New Client" : "Loading Invoices...");

  //const [data, dataSet] = useData("invoices:" + clientId, APP.central.Invoices.getReceivablesForPeriod, { period: "all_time", customer: clientId });
  const [data, dataSet] = useData("invoices", { method: "getReceivablesForPeriod", period: "all_time", customer: clientId });

  useEffect(() => {
		const linkId = clientId;
		if (data != undefined && data.length > 0) {
			const links = [<Link to={"/ui/clients/" + linkId}>Client</Link>, <Link to={"/ui/clients/sites/" + linkId}>Sites</Link>, <Link to={"/ui/clients/" + linkId + "/invoices"}>Invoices</Link>];

			if (APP.instance.isModalOpen() == false){
				nav.update(data[0].customer_name + " - Invoices (Client #" + linkId + ")", links, { selectedItem: 2 });
			} else {
				nav.updateTitle(data[0].customer_name + " - Invoices (Client #" + linkId + ")")
			}
		}

	}, [dataSet.loading]);

  const handleClick = (row) => {
    APP.redirect("/ui/arinvoice/" + row.invoice_id);
  };
  
  if (dataSet.loading) {
		return <Loading />;
	} 

  return (
    <div>
      <CesarFilter 
        columns={[{ column: "site_names" }, { column: "invoice_preparer" }, { column: "invoice_type" }, { column: "report_status" }, { column: "status" }]}
        dataSet={dataSet}
      />
      <TabularDataSet pageSize={100} paginationLocation={"both"} showAllToggle={true} dataSet={dataSet} onClick={handleClick} filter={(row) => row.status != "missing"} >
        <TabularColumn data={(row) => row.invoice_id} title="#" />
        <TabularColumn
          data={(row) => (
            <Link onClick={(e) => e.stopPropagation()} className="plain_link" to={"/ui/clients/" + row.customer}>
              {row.customer_name}
            </Link>
          )}
          title="Client Name"
        />
        <TabularColumn
          data={(row) =>
            row.site_names != undefined ? (
              <Truncated splitOn="|" maxCharacterLength={20}>
                {row.site_names}
              </Truncated>
            ) : null
          }
          title="Site(s)"
        />
        <TabularColumn data={(row) => row.date} title="Invoice Date" />
        <TabularColumn data={(row) => row.service_date} title="Service Date" />
        <TabularColumn data={(row) => <NiceCurrency>{row.pre_tax_total}</NiceCurrency>} title="Total" />
        <TabularColumn
          data={(row) => {
            return (
              <Fragment>
                {row.status}
                {row.accounting_id > 0 ? (
                  <FileItem
                    file={{
                      filename: "invoice_" + row.accounting_id + ".pdf",
                      id: row.invoice_file,
                      relatedTable: "ARInvoice",
                    }}
                    useId={true}
                  />
                ) : null}
              </Fragment>
            );
          }}
          title="Status"
        />
        <TabularColumn data={(row) => (row.report_status ? row.report_status : row.status)} title="Report Status" />
        <TabularColumn data={(row) => (row.invoice_preparer ? <NicePerson>{row.invoice_preparer}</NicePerson> : "-")} title="Invoice Preparer" />
        {/** <TabularColumn data={(row) => <Button onClick={(e) => downloadCostSavingsReport(e, row)}>Report</Button>} title="Cost Savings" /> **/}
        <TabularColumn
          data={(row) => {
            return (
              <Fragment>
                {"" /* this exists so trying to sort this column doesnt kill Tabular */}
                <Link onClick={(e) => e.stopPropagation()} to={"/ui/arinvoice/" + row.invoice_id}>
                  <Icon icon="caret-right" size="2x" />
                </Link>
              </Fragment>
            );
          }}
          title="Edit"
        />
        {/** <TabularColumn
							data={(row) => {
								return <span>
									<Icon onClick={(e) => doDelete(row, e)} icon="trash" size="2x" title="Delete"/>
									<Icon onClick={(e) => doCopy(row, e)} icon="copy" size="2x" title="Copy"/>
								</span>;
							}}
						/> **/}
      </TabularDataSet>
    </div>
  );
}
