import React from 'react';
import { LinkButton, TabularColumn, TabularDataSet } from '@opidcore/components';
import { useData } from '@opidcore/hooks/WTF';
import moment from 'moment';

const LogsBySubject = ({subject}) =>{
	const [logData, logDataSet] = useData("logs-" + subject, APP.central.ActivityLog.bySubject, {subject: subject});

	return <div key="log-data" className="log-data">
		<h4>Logs - {subject}</h4>
		<TabularDataSet dataSet={logDataSet}>
			<TabularColumn data={(row)=>moment(row.start).format('YYYY-MM-DD hh:mm')} title="Date"/>
			<TabularColumn data={(row)=><APChunk row={row}/>} title="Invoice"/>
			<TabularColumn data={(row)=>row.details.externalInvoiceNumber} title="Invoice #"/>
			<TabularColumn data={(row)=>row.what} title="Status"/>
		</TabularDataSet>
	</div>
}

const APChunk = ({row}) =>{
	if (row.relatedTable == "APInvoice"){
		return <span className="ap-chunk" key={"ap-" + row.relatedTableId}>
			<LinkButton target={"/ui/apinvoice/" + row.relatedTableId} title={ "AP  #" + row.relatedTableId } className="simple">
				AP #{row.relatedTableId}
			</LinkButton>
		</span>;
	}

	return <>?</>;
}


export default LogsBySubject;