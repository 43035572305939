//import './AddFriendInput.scss';
import React, { Component, PropTypes } from 'react';

export default class Draggable extends Component {
	
  static propTypes = {
  };

  constructor(props, context) {
    super(props, context);

    	this.state = {dragging: false};

  }

  componentWillReceiveProps(nextProps, nextState) {
    //
  }
  
  handleDragStart(e){
	 this.setState({'dragging': true, draggingId: this.props.children.props.ourRef});
	 window._DRAGGING = this;
  }
  
  handleDragEnd(e){
	  
	  this.setState({'dragging': false});
	  window._DRAGGING = null;
	  this.props.onDragComplete(this.state.draggingId, this.state.lastTarget);
  }
  
  handleDrag(e){	  
  }
  
  handleDragOver(e) {	 
	   e.preventDefault();
	   window._DRAGGING.setState({'lastTarget': this.props.children.props.ourRef});
   }
  
  handleMouseOver(e){
	  if (window._DRAGGING == null){
		  this.setState({showHandle: true});
	  }
  }
  
  handleMouseOut(e){
	  if (this.state.showHandle){
		  this.setState({showHandle: false});
	  }
  }
  
  
  
  render() {
	    const TagName = this.props.tagName || "div";
	    
	    return <TagName className="draggable" onMouseOver={(e)=>this.handleMouseOver(e)} onMouseOut={(e)=>this.handleMouseOut(e)}>
	    	<div className={"dragHandle " + (this.state.showHandle ? "" : "hidden")} draggable={true} onDragStart={(e) => this.handleDragStart(e)} onDragEnd={(e) => this.handleDragEnd(e)} onDrag={(e) => this.handleDrag(e)}><span className="glyphicon glyphicon-sort"></span></div>
	    	<div className="draggable-target" onDragOver={(e)=>this.handleDragOver(e)}>
	    		{this.props.children}
	    	</div>
	    </TagName>
  }
}
