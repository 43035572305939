import React, { useState } from 'react';
import { useData } from '@opidcore/hooks/WTF';
import { Tabular, TabularColumn } from '@opidcore/components/Tabular';
import moment from 'moment';
import _ from 'lodash';

const deltaFormatter = (delta)=>{
    return <tr key={"delta-formatter-" + delta.field} className="delta-formatter">
        <td>{delta.field}</td>
        <td>{delta.before == null? "" : delta.before + ""}</td>
        <td>{delta.after == null? "" : delta.after + ""}</td>
    </tr>
}

const ChangeLogDelta = ({delta}) =>{
    return <div className="change-log-delta">
        <h4>{delta.changeType}</h4>
        <table>
            <thead>
                <tr>
                    <th>Field</th>
                    <th>Before</th>
                    <th>After</th>
                </tr>
            </thead>
            <tbody>
                { _.map(delta.diffs, (diff)=>deltaFormatter(diff)) }
            </tbody>
        </table>
        
    </div>
}

const ChangeLogList = ({api, options = undefined, title="Change Logs"}) =>{
    const [changeLogs, changeLogsSet] = useData("change_log", api, options);

    return <div className="change-log-list" key="change-log-list">
        <h4>{title}</h4>
        <Tabular data={changeLogs}>
            <TabularColumn title="Date" data={(row)=>moment(row.start).format("YYYY-MM-DD HH:mm")}/>
            <TabularColumn title="Record" data={(row)=>row.relatedTable}/>
            <TabularColumn title="Id" data={(row)=>row.relatedTableId}/>
            <TabularColumn title="User" data={(row)=>row.userObject.fullName}/>
            <TabularColumn title="Delta" data={(row)=><ChangeLogDelta delta={row.differentials}/>}/>
        </Tabular>
    </div>
}

export {ChangeLogList, ChangeLogDelta};