import React, { useContext, useEffect, useRef, useState } from "react";
import { ActionBar, Bound, Icon, InputAutoComplete, InputToggleSwitch, Loading, Pagination, Searchable, Step, TabularColumn, TabularDataSet, Workflow } from "@opidcore/components";

import { useLocalData } from "@opidcore/hooks/WTF";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { DataContext } from "@opidcore/components/Bound";
import moment from "moment";
import { EditClientContractDetails } from "../UI/Customer/EditClientContract";
import { EditVendorContractDetails } from "../UI/Vendor/EditVendorContract";
import { CurrentModal } from "@opidcore/components/OpidApplication";

export default function ContractBrowser({ type, typeField, contractField, readOnly = false }) {
  const [selected, setSelected] = useState(null);
  const [contract, setContract] = useState(null);

  let centralController = APP.central.VendorContract;
  let linkPath = "/ui/vendorcontract/edit/";
  if (type == "client") {
    centralController = APP.central.ClientContract;
    linkPath = "/ui/clientcontract/edit/";
  }

  const bound = useContext(DataContext);
  let serviceBound = null;
  if (type == "vendor") {
    serviceBound = bound.getParentMagic().getBound("service")._ourMagic;
  } else {
    //debugger;
  }

  useEffect(() => {
    if (serviceBound != null) {
      serviceBound.magicalState(typeField, setSelected, null);
    } else {
      //client
      bound.magicalState(typeField, setSelected, null);
    }
    setContract(bound.magicalGet(contractField));
  }, [serviceBound, bound]);

  const refreshContract = () => {
    if (type == "vendor") {
      setContract(bound.to.vendorContract);
    } else {
      setContract(bound.to.clientContract);
    }
  };

  const openContractBrowserModal = () => {
    APP.instance.createModal(
      <ContractBrowserModal
        contractField={contractField}
        bound={bound}
        centralController={centralController}
        linkPath={linkPath}
        serviceBound={serviceBound}
        serviceId={serviceBound ? serviceBound.to.id : bound.to.serviceId}
      />,
      {
        modal_name: "Contract Browser",
      },
      {
        afterClose: () => refreshContract(),
      }
    );
  };

  const openContractPreview = () => {
    APP.instance.createModal(
      linkPath + (contract.friendlyId ? contract.friendlyId : contract.friendly_id),
      {
        modal_name: "Contract Preview",
      },
      {
        afterClose: () => refreshContract(),
      }
    );
  };

  return (
    <div className="select-contract">
      {contract != null && contract.id > 0 ? (
        <span>
          {"#" + (contract.friendlyId ? contract.friendlyId : contract.friendly_id)} <Icon title="View Contract Files" icon="file-alt" size="2x" onClick={openContractPreview} />
        </span>
      ) : (
        "No Contract Selected"
      )}
      <span>
        <Icon title="Open Contract Browser" icon="search" size="2x" onClick={() => openContractBrowserModal()} />
      </span>
    </div>
  );
}

//------------------------------------------------------------------ CreateNewClientContract ------------------------------------------------------------------
export const CreateNewClientContract = (props) => {
  const clientId = props.clientId;
  const [data, setData] = useState({ friendlyId: null });
  const history = useHistory();

  useEffect(() => {
    APP.central.ClientContract.createContractForClient(clientId).then((r) => {
      setData(r.result);
    });
  }, []);

  if (data.friendlyId == null) {
    return <Loading />;
  }

  return (
    <div className="create-client-contract">
      <div className="contract-editor">
        <EditClientContractDetails data={data} dataSet={null} afterSave={() => history.push("/ui/clientcontract/edit/" + data.friendlyId)} />
      </div>
    </div>
  );
};

//------------------------------------------------------------------ CreateNewVendorContract ------------------------------------------------------------------
export const CreateNewVendorContract = (props) => {
  const [data, setData] = useState({ friendlyId: null });
  const history = useHistory();
  const serviceId = props.serviceId;
  const vendorId = props.vendorId;
  const boundRef = useRef();

  const [showVendorSelect, setShowVendorSelect] = useState(false);

  const [workflowContextState, setWorkflowContextState] = useState({ onStepFinish: undefined, onGoBack: undefined });

  const nextStep = () => {
    if (workflowContextState.onStepFinish != undefined) {
      workflowContextState.onStepFinish();
    }
  };

  const createNewContract = (theVendorId, theServiceId) => {
    APP.central.VendorContract.createNew(theVendorId, theServiceId).then((r) => {
      setData(r.result);
    });
  };

  useEffect(() => {
    if (vendorId && vendorId > 0) {
      createNewContract(vendorId, serviceId);
    } else {
      setShowVendorSelect(true);
    }
  }, []);

  /*
  if (data.friendlyId == null) {
    return <Loading />;
  }
  */

  return (
    <div className="create-vendor-contract">
      {showVendorSelect ? (
        <Workflow initialize={(context) => setWorkflowContextState(context)} initialStep={1}>
          <Step myStepNumber={1}>
            <div className="vendor-selector">
              <Bound to={boundRef.current}>
                <div className="field">
                  <label>Select Vendor by typing...</label>
                  <InputAutoComplete
                    central={APP.central.Vendor.autocomplete}
                    field="vendor"
                    onSelect={(value) => {
                      createNewContract(value.basis.id, serviceId);
                      nextStep();
                    }}
                  >
                    {(vendor) => <div className="vendor_option">{vendor.displayValue}</div>}
                  </InputAutoComplete>
                </div>
              </Bound>
            </div>
          </Step>
          <Step myStepNumber={2}>
            {data.friendlyId ? (
              <div className="contract-editor">
                <EditVendorContractDetails data={data} dataSet={null} afterSave={() => history.push("/ui/vendorcontract/edit/" + data.friendlyId)} />
              </div>
            ) : (
              <Loading />
            )}
          </Step>
        </Workflow>
      ) : data.friendlyId ? (
        <div className="contract-editor">
          <EditVendorContractDetails data={data} dataSet={null} afterSave={() => history.push("/ui/vendorcontract/edit/" + data.friendlyId)} />
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

//------------------------------------------------------------------ ContractBrowserModal ------------------------------------------------------------------
function ContractBrowserModal(props) {
  const contractField = props.contractField;
  const bound = props.bound;
  const centralController = props.centralController;
  const linkPath = props.linkPath;
  const currentModal = useContext(CurrentModal);

  const histShow = useRef({ histShowStatus: false });

  let isClient = false;
  let serviceBound = null;
  if (props.serviceBound) {
    serviceBound = props.serviceBound;
  }

  let contractString = "";
  if (contractField == "vendorContract") {
    contractString = "ContractBrowservendorcontract";
  } else if (contractField == "clientContract") {
    isClient = true;
    contractString = "ContractBrowserclientcontract";
  }

  const getOpts = () => {
    if (isClient) {
      return { paginate: 50, orderBy: "effectiveFrom desc", clientId: bound.to.customerId, showHistorical: histShow.current.histShowStatus };
    } else {
      if (serviceBound.to.vendor && serviceBound.to.vendor.id) {
        return { paginate: 50, orderBy: "effectiveFrom desc", vendorId: serviceBound.to.vendor.id, showHistorical: histShow.current.histShowStatus };
      } else {
        return { paginate: 50, orderBy: "effectiveFrom desc", vendorId: 0, showHistorical: histShow.current.histShowStatus };
      }
    }
  };

  const [options, setOptions] = useState(getOpts());

  const [data, dataSet] = useLocalData(contractString, centralController.listContractsContractBrowser, options);

  const dateFormat = "YYYY-MM-DD";

  useEffect(() => {
    let newQueryArgs = dataSet.queryArgs;
    newQueryArgs.showHistorical = options.showHistorical;
    if (options) {
      dataSet.setQueryArgs(newQueryArgs);
      dataSet.fetch();
    }
  }, [options]);

  const doSetHistorical = (a, b, c, value) => {
    let newOpts = { ...options };
    newOpts.showHistorical = value;
    setOptions(newOpts);
  };

  const doSelect = (e, row) => {
    bound.magicalSet(contractField, row);
    if (!isClient) {
      const vendorFull = row.Vendor;
      const servVendor = {
        friendlyId: vendorFull.friendlyId,
        fullEntity: null,
        id: vendorFull.id,
        likelyDescription: "?",
        type: "models.erp.Vendor",
        __is_shallow: true,
        __type: "Vendor",
      };
      serviceBound.magicalSet("vendor", servVendor);
    }
    const serviceId = props.serviceId;
    centralController.assignContract(serviceId, row.id);
    APP.instance.closeModal(currentModal != undefined && currentModal.id != undefined ? currentModal.id : undefined);
  };

  const refreshContractBrowser = () => {
    dataSet.fetch();
  };

  const newClientContract = () => {
    APP.instance.createModal(
      <CreateNewClientContract _dataUID={dataSet.dataUID} clientId={bound.to.customerId} />,
      {
        modal_name: "New Client Contract",
      },
      {
        afterClose: () => refreshContractBrowser(),
      }
    );
  };

  const newVendorContract = () => {
    const serviceId = serviceBound.to.id;
    const vendorId = serviceBound.to.vendor ? serviceBound.to.vendor.id : 0;

    APP.instance.createModal(
      <CreateNewVendorContract _dataUID={dataSet.dataUID} serviceId={serviceId} vendorId={vendorId} />,
      {
        modal_name: "New Client Contract",
      },
      {
        afterClose: () => refreshContractBrowser(),
      }
    );
  };

  return (
    <div>
      <ActionBar>
        {dataSet ? <Searchable ds={dataSet} /> : null}
        <Bound to={histShow.current} onChange={doSetHistorical}>
          <InputToggleSwitch
            field="histShowStatus"
            icon="eye"
            size="2x"
            className="inline"
            title={histShow.current.histShowStatus == undefined || histShow.current.histShowStatus == false ? "Historical Not Shown" : "Historical Shown"}
          />
        </Bound>
        {dataSet ? <Pagination dataSet={dataSet} /> : null}
        {isClient ? (
          <a className="btn btn-new" style={{cursor: 'pointer'}} onClick={() => newClientContract()}>
            New Client Contract
          </a>
        ) : (
          <a className="btn btn-new"  style={{cursor: 'pointer'}} onClick={() => newVendorContract()}>
            New Vendor Contract
          </a>
        )}
      </ActionBar>

      {dataSet && dataSet.loading ? "loading" : ""}

      {dataSet ? (
        isClient ? (
          <TabularDataSet dataSet={dataSet} onClick={(row) => {}} defaultSort="created" defaultSortOrder="desc">
            <TabularColumn
              data={(row) => {
                return <Icon icon="check" size="2x" onClick={(e) => doSelect(e, row)} />;
              }}
              title="Select"
            />
            <TabularColumn data={(row) => row.friendly_id} title="#" sortCol="friendly_id" />
            {/* <TabularColumn data={(row) => row.status} title="Status" sortCol="status" /> */}
            <TabularColumn data={(row) => row.expiry_status} title="Expiry Status" sortCol="expiry_status" />
            <TabularColumn data={(row) => (row.effective_from ? moment(row.effective_from).format(dateFormat) : "-")} title="Effective From" sortCol="effective_from" />
            <TabularColumn data={(row) => (row.effective_to ? moment(row.effective_to).format(dateFormat) : "-")} title="Effective To" sortCol="effective_to" />
            {/* <TabularColumn data={(row) => row.internal_notes} title="Internal Notes" sortCol="internal_notes" /> */}

            <TabularColumn data={(row) => row.Customer.name} title="Client" sortCol="Customer" />
            <TabularColumn data={(row) => row.contract_term} title="Contract Term" sortCol="contract_term" />
            <TabularColumn data={(row) => row.external_reference} title="External Reference" sortCol="external_reference" />
            <TabularColumn data={(row) => row.invoice_timing} title="Invoice Timing" sortCol="invoice_timing" />
            <TabularColumn
              data={(row) => {
                return <Icon icon="edit" size="1x" onClick={() => APP.instance.createModal(linkPath + row.id, { modal_name: "Preview" })} />;
              }}
              title="Preview"
            />
          </TabularDataSet>
        ) : (
          <TabularDataSet dataSet={dataSet}>
            <TabularColumn
              data={(row) => {
                return <Icon icon="check" size="2x" onClick={(e) => doSelect(e, row)} />;
              }}
              title="Select"
            />
            <TabularColumn data={(row) => row.friendly_id} title="#" />
            <TabularColumn data={(row) => row.status} title="Status" sortCol="status" />
            <TabularColumn data={(row) => row.expiryStatus} title="Expiry Status" sortCol="expiry_status" />
            <TabularColumn data={(row) => (row.Vendor.name ? row.Vendor.name : "-")} title="Vendor" />
            <TabularColumn data={(row) => row.clients} title="Clients" />
            <TabularColumn data={(row) => <span title={row.sites}>{row.site_count}</span>} title="Sites" />
            <TabularColumn data={(row) => (row.effective_from ? moment(row.effective_from).format(dateFormat) : "-")} title="Effective From" />
            <TabularColumn data={(row) => (row.effective_to ? moment(row.effective_to).format(dateFormat) : "-")} title="Effective To" />
            <TabularColumn data={(row) => (row.internal_notes ? row.internal_notes : "-")} title="Internal Notes" />
            <TabularColumn data={(row) => (row.invoice_timing ? row.invoice_timing : "-")} title="Invoice Timing" />
            <TabularColumn
              data={(row) => {
                return <Icon icon="edit" size="1x" onClick={() => APP.instance.createModal(linkPath + row.id, { modal_name: "Preview" })} />;
              }}
              title="Preview"
            />
          </TabularDataSet>
        )
      ) : null}
    </div>
  );
}
