import React, { Component } from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import Util from './Util';

class DataSet extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return null;
  }
}

/**
 * @param data accepts either an array of data, or an object describing the dataset
 * @returns a new instance of a Data 
 */
class Data {
  constructor(data, name, arg3) {
    this.apiMethod = APP.central.DataQuery.dataQuery;
    this.loadedCount = 0;
    this._callbacks = [];
    this.fetchRequired = true;
    this.loading = false;

    if (typeof data == "object" && !Array.isArray(data)) {
      this.setQueryArgs(data, arg3);
      this.form = this.queryArgs.form;

      data = [];     
      if (this.queryArgs && this.queryArgs.options && this.queryArgs.options.apiMethod) {
        this.apiMethod = this.queryArgs.options.apiMethod;
        delete this.queryArgs.options.apiMethod;
        this.queryArgs = this.queryArgs.options;
        delete this.singleDefaults;
      }

      if (typeof name == "function") {
        //this.callback = name;
        this.callback(name);
        name = arg3;
      }

      this._fetch = (queryParams, useAuto, c) => {
        if (this.loading) {
          // console.log("we are still loading");
          return;
        }       
        if (this.callback) {
          this.loading = true;
          this.callbacks(this.filter());
        }

        const requestArgs = { ...this.queryArgs };
        _.each(queryParams, (v, k) => {
          if (requestArgs[k] && _.keys(requestArgs[k]).length > 0) {
            requestArgs[k] = { ...requestArgs[k], ...v };
          } else {
            requestArgs[k] = v;
          }
        });
    

        /* let myMethod = null;

        _.forEach(window._API, (api)=>{ 
          if(myMethod == null){
            if(api.name == this.apiMethod.source){    
              const method = _.find(api.methods, {name: this.apiMethod.method});

              if(method != undefined){
                myMethod = method;
              }
            }
          }
        });

        if(myMethod.argumentNames.length > 0){
          _.forEach(_.keys(requestArgs), (requestKey)=>{
            if(myMethod.argumentNames.indexOf(requestKey) < 0){
              delete requestArgs[requestKey];
            }
          });
        } */

        if (requestArgs.options && requestArgs.options.paginate){
          this._paginate = requestArgs.options.paginate;
        }
        this.apiMethod(requestArgs).then((ret) => {
          let stuff = [];
          let columnDefinitions = null;
          if(ret.result == null){
            return;
          }
          if (_.isArray(ret.result)) {
            stuff = ret.result;
          } else if (ret.result.rows != undefined) {
            stuff = ret.result.rows;
            columnDefinitions = ret.result.columns;
          } else if (ret.status == "ok") {
            stuff = [ret.result];
          }
          if (stuff.length == 1 && this.singleDefaults) {
            stuff[0] = { ...ret.result.rows[0], ...this.singleDefaults };
            stuff[0].__delta = { ...this.singleDefaults };
          }

          if (ret.result.uid) {
            this.uid = ret.result.uid;
          }

          if (ret.result.pagination) {
            this.paginationInfo = ret.result.pagination;
          }
          this.fetchRequired = false;
          this.reset(stuff, { keepFilters: true });
          this.defineColumns(columnDefinitions);
        });
      };
    }

    if (data == undefined) {
      data = [];
    }
    this.name = name;
    this.rawData = JSON.parse(JSON.stringify(data));
    this.origData = data != undefined ? JSON.parse(JSON.stringify(this.rawData)) : null;
    this.filters = [];
  }

  defineColumns(columnDefinitions) {
    if(columnDefinitions != null) {
      this.columns = columnDefinitions;
    }
  }

  mergeQueryArgs(data, forced) {
    let args = _.cloneDeep(this.queryArgs)
    _.each(data, (v, k) => {
      if(forced == true){
        args[k] = v;
      } else {
        _.merge(args[k], v);
      }
    });
    this.setQueryArgs(args);
  }

  cancel(){
    if (this.loading){
      this.loading = false;
      this.apiMethod("CANCEL_ALL_REQUESTS_NOW");
    }
  }
  setQueryArgs(data, defaults) {

    let _queryArgs = this.queryArgs;

    if (typeof data == "object") {

      _queryArgs = { ...data };
      if (typeof data.options == "number" || typeof data.options == "string") {
        _queryArgs.single = data.options;
      }
    } else {
      if (typeof data == "number" || typeof data == "string") {
        _queryArgs.single = data;
      } else {
        //console.log("someone called a very strange data hook setQueryArgs  " + data);
      }
    }

    if (defaults) {
      this.singleDefaults = defaults;
    }

    if (_queryArgs.form == undefined) {
      _queryArgs.form = this.form;
    }

    if (!_.isEqual(_queryArgs, this.queryArgs)) {
      this.fetchRequired = true;
      this.loading = false;
      this.queryArgs = _queryArgs;
    }else{
      //we don't need to reload if it's the same?
      if (this.loading){
        this.fetchRequired = false;
      }
    }

  }
  callback(func, dataUID, setDataUID) {
    if (dataUID != null) {
      this.dataUID = dataUID;
      this.updateDataUID = setDataUID;
    }
    this._callbacks.push(func);
    return this._callbacks.length - 1;
  }

  removeCallback(cb_id) {
    this._callbacks[cb_id] = null;
  }

  removeCallbacks() {
    this._callbacks = [];
  }

  callbacks(res) {  
    if (res.__dataUID == null) {
      res.__dataUID = this.dataUID;
    }
    _.each(this._callbacks, (cb) => cb && cb(res));
  }

  getDataSummary() {
    if (this.customSummary) {
      return this.customSummary(this.filters);
    } else {
      return _.map(this.filters, (filter) => {
        return _.map(filter, (v, k) => {
          return k + ": " + v.join(", ");
        });
      }).join(" | ");
    }

    return "";
  }

  reset(data, opts) {
    //this is our default, and we apply the passed options.
    var _opts = { keepFilters: false, ...opts };

    if (data) {
      this.rawData = data;
      this.origData = this.rawData.slice();
      if (_opts.keepFilters == false) {
        this.filters = [];
      }
      if (APP && APP.customize && APP.listeners && APP.customize.listeners["DataSet"]) {
        var callback = APP.customize.listeners["DataSet"][this.name];
        callback && callback(this);
      }
      
    }

    if (this.origData && (_opts.keepFilters == false)) {
      this.filters = [];
      this.rawData = this.origData.slice();
    }

    if (this.dataUID && this.dataUID.split(":")[1] == 0 && data.length == 1) {
      this.dataUID = this.dataUID.split(":")[0] + ":" + data[0].id;
      this.updateDataUID && this.updateDataUID(this.dataUID);
    }
    if (_opts.keepFilters == true && this.filters.length > 0) {
      //console.log("Cesar is applying all filters you might be having a bad time. (Ian told him to)")
      this.applyAllFilters();
    }

    this.refreshCallbackIfNeeded();
  }

  unfilter(field, preventRefresh) {
    this.filters = this.filters.filter((f) => {
      return _.keys(f)[0] != field;
    });

    if (!preventRefresh) {
      this.rawData = this.origData.slice();
      this.applyAllFilters();
    }
  }

  resetFilters(filters) {
    this.filters = _.cloneDeep(filters);
  }

  listFilters() {
    return _.values(this.filters).map(x => _.keys(x)[0]);
  }

  applyAllFilters() {
    _.forEach(this.filters, (f) => { this.filter(f, false, true) });
  }

  filter(newFilter, persist, inplace, functionKey) {
    var filterKey = _.keys(newFilter)[0];
    //set filterKey to the option.key if it exists... 
    var filterValues = _.values(newFilter)[0];
    var a = null;
    if (typeof newFilter == "function") {
      a = _.filter(this.rawData, newFilter);
    } else if(typeof filterValues == "function") {
      a = _.filter(this.rawData, filterValues);
    } else if (filterValues && filterValues.length > 0) {
      a = _.filter(this.rawData, r => {
        let found = false;
        if (r[filterKey]) {
          if (typeof r[filterKey] == "number") {
            found = filterValues.indexOf(r[filterKey]) >= 0;
          } else if (typeof r[filterKey] == "boolean") {
            found = filterValues.indexOf(r[filterKey]) >= 0;
          } else if(Util.isBaseEntity(r[filterKey])){
            found = Util.containsBaseEntity(filterValues, r[filterKey]);
          }
          else {
            if (Array.isArray(filterValues)) {
              found = filterValues.map((f) => f.toLowerCase()).indexOf(r[filterKey].toLowerCase()) >= 0; //not sure why do toLowerCase here
            } else {
              found = filterValues.toLowerCase().indexOf(r[filterKey].toLowerCase()) >= 0;
            }
          }
        } else {
          found = filterValues.indexOf(r[filterKey]) >= 0;
        }

        return found;
      });
    } else {
      a = _.filter(this.rawData, newFilter);
    }
    if (persist || inplace) {
      this.rawData = a;

      var existingFilters = _.values(this.filters).map(x => _.keys(x)[0]);
      if (existingFilters.indexOf(filterKey) == -1 && typeof newFilter != "function") {
        this.filters.push(newFilter);
      } else if (typeof newFilter == "function") {
        if(existingFilters.indexOf(functionKey) >= 0) {
          this.filters[existingFilters.indexOf(functionKey)] = { [functionKey]: newFilter };
        } else {
          this.filters.push({ [functionKey]: newFilter });
        }
      }
      else {
        this.filters[existingFilters.indexOf(filterKey)] = newFilter;
      }
    }

    if (persist) {
      this.refreshCallbackIfNeeded();
    }
    return a;
  }

  filterByAllFilters() {
    let newArray = this.origData.filter((row) => {
      let truth = 0;
      _.forEach(this.filters, (filterObj) => {
        const key = _.keys(filterObj)[0];
        const values = _.values(filterObj)[0];
        if (values.indexOf(row[key]) > -1) {
          truth++;
        }
      });

      if (truth > 0 && truth >= this.filters.length) {
        return true;
      } else {
        return false;
      }
    });

    this.rawData = newArray;

    return newArray;
  }


  fetchIfRequired() {
    if (this.rawData.length > 0 && this.fetchRequired == false) {
      return false;
    }

    if (this.loading == true && this.fetchRequired){     
      this.loading = false;
    }

    this.fetch();
  }

  fetchSorted(opts) {
    const ourOpts = { ...opts };
    if (this._sort) {
      if (ourOpts.options == null) {
        ourOpts.options = {};
      }
      ourOpts.options.sortBy = this._sort + " " + this._sortOrder;
    }
    if (this._paginate && ourOpts.query != null && ourOpts.query.length == 0) {
      if (ourOpts.options == null) {
        ourOpts.options = {};
      }
      ourOpts.options.paginate = this._paginate;
    }
    if (this._query && ourOpts.query == null) {
      ourOpts.query = this._query;
    }

    return this.fetch(ourOpts);
  }

  fetch(a, b, c) {
    if (this._fetch) {
      return this._fetch(a, b, c);
    }

  }

  filtersForFetch() {
    return this.filters;
  }

  group(type, wtf) {
    var a = _.groupBy(wtf || this.rawData, type);
    return a;
  }

  count(hash) {
    return _.fromPairs(_.map(_.keys(hash), (k) => { return [k, hash[k].length] }));
  }

  sum(what, hash) {
    return _.fromPairs(_.map(_.keys(hash), (k) => { return [k, _.sum(_.map(hash[k], what))] }));
  }

  adjust(f) {
    _.each(this.rawData, row => f(row));
  }

  doubleGroup(firstGroup, secondGroup, aggregate) {
    // r=>r['DOA'].substring(0,4)
    var byType = this.group(firstGroup);
    var doWeReallyWantToResetThisAndHateYourLife = false;
    if (doWeReallyWantToResetThisAndHateYourLife) {
      if (typeof firstGroup == "string") {
        byType = this.group(firstGroup, this.filter(r => r[firstGroup] > ""));
      }
    }

    var ret = _.map(_.keys(byType), (t) => { return { dataset: t, values: aggregate(this.group(secondGroup, byType[t])) } });
    if (ret.length > 0) {
      var allKeys = _.uniq(_.flatten(_.map(ret, r => _.keys(r.values))))
      for (var x = 0; x < ret.length; x++) {
        var missing = _.difference(allKeys, _.keys(ret[x].values));

        missing.map(r => {
          ret[x]["values"][r] = 0;
        });
      }
    }
    return ret;

  }
  /** cody document this craziness **/
  find(where) {
    var wtf = _.flatten(this.filter(where));
    this.lastWhat = wtf;
    return this;
  }

  peek(field, what) {
    if (what == null) {
      what = this.lastWhat;
    }
    this.lastWhat = _.map(what, field)[0];
    return this;
  }

  collect() {
    return this.lastWhat;
  }

  replace(newRecord, matchField) {
    this.reset(_.map(this.rawData, (r) => {
      if (r[matchField] == newRecord[matchField]) {
        return newRecord;
      }

      return r;
    }));
    this.refreshCallbackIfNeeded();
  }

  add(newRecord) {
    this.reset([...this.origData, newRecord]);
    this.refreshCallbackIfNeeded();
  }

  addOrUpdate(newRecord) {
    let found = false;
    const newData = [...this.origData];

    const existingIndex = _.findIndex(newData, (d) => d.id == newRecord.id || d.id == 0);

    if (existingIndex >= 0) {
      newData[existingIndex] = newRecord;
    } else {
      newData.push(newRecord);
    }

    this.reset(newData);
  }

  refreshCallbackIfNeeded() {
    this.loading = false;
    this.loadedCount++;
    this.callbacks(this.filter());
  }

  sort(col, order) {
    if (col != this._sort || order != this._sortOrder) {
      this._sort = col;
      this._sortOrder = order;

      this.fetchSorted({});
    }
  }

  nextPage() {
    if (this.paginationInfo != undefined) {
      if (this.page == undefined) {
        //assume dataset was on page 1
        this.page = 2;
      } else if (this.page == this.paginationInfo.totalPages) {
        //go to first page
        this.page = 1;
      } else {
        this.page = this.page + 1;
      }

      this.fetchPage();
    }
  }

  previousPage() {
    if (this.paginationInfo != undefined) {
      if (this.page == undefined || this.page == 1) {
        //go to last page
        this.page = this.paginationInfo.totalPages;
      } else {
        this.page = this.page - 1;
      }

      this.fetchPage();
    }
  }

  setPage(pageNumber) {
    this.page = pageNumber;
    this.fetchPage();
  }

  fetchPage() {
    this.fetchSorted({ options: { page: this.page } });
  }

  download(format) {
    if (this.uid) {
      APP.central.Exporter.doExport(this.uid);
    } else {
      APP.alert("Sorry, we can't export this dataset.");
    }
  }

  getColumnInfo(column) {
    return {
      column: column,
      niceName: column.replaceAll(/_/g,' ').toUpperCase()
    }
  }
}

class DashboardTile extends Component {
  render() {
    return <div className={"tile " + this.props.className} style={this.props.style}>

      {this.props.title > "" ? <h2>{this.props.title}</h2> : null}
      {this.props.showHR == true ? <hr /> : null}
      {this.props.children}
    </div>;
  }
}



export { DataSet, Data, DashboardTile }