import React, { Component } from 'react';

import templated from './Templated.jsx';
import FileBrowser from './FileBrowser.jsx';
import Figure from './Figure.jsx';

export default class Image extends React.Component {
        constructor(props,context) {
          super(props);
          this.state = {};
        }
        
        static metaInfo(thing) {
    		return {icon: "image", preview: (me)=>{ return _.truncate(me.element.innerText, 30) }, showInEditorQuickAdd: true };
    	}
        
        static getEditableProps() {
            return [
                {niceName: "Width", prop:"width", type:"text", default: "", editInPlace: true},
                {niceName: "Height", prop:"height", type:"text", default: "", editInPlace: true},
                {niceName: "Class Name", prop:"className", type:"text", default: "", editInPlace: true},
                {niceName: "Show Image", prop:"image", type:"fileBrowser", default: "", allowJS: true },
                {niceName: "Raw Image", prop:"rawSrc", type:"text", default: "" },
                {niceName: "Title", prop: "title", type: "text", editInPlace: true, default: "New Figure"},
                {niceName: "Title Size", prop: "titleSize", type: "text", options: ["h1","h2","h3"], default: "h3"},
                {niceName: "Scale", prop:"scale", type:"text", default: "" },
            ]
        }
        
        injectNext(){
            const struct = this.props.NDT.find(this.props.root);
            
            if(this.props.title == undefined || this.props.title.trim().length <= 0){
                return undefined;
            }
            
            return {
                children: null,
                cid: struct.theId + "-diagram",
                component: "DiagramCounter",
                connections: [],
                connectors: null,
                next: null,
                options: null,
                returns: null,
                theId: struct.theId + "-diagram",
                type: "MetaTalker",
                uid: struct.uid + (APP.NDT.structure.length + 1)
            };
        }

        render() {

          let image = this.props.image == undefined ? null : this.props.image;
          if (typeof image == "string"){
              if(image == "cheese"){
                  image = null;
              } else {
            	  try{
            		  image = JSON.parse(image);
            	  }catch(e){
            		  
            	  }
              }
          }   
          
          let ViewImage = null;
          let imgProps = {};
          
          if (this.props.width){
        	  imgProps.width = this.props.width;
          }
          
          if (this.props.height){
        	  imgProps.height = this.props.height;
          }
          
          
          if (this.props.rawSrc){
        	  ViewImage = (<img className={this.props.className} src={this.props.rawSrc} {...imgProps}/>);
          }else if (image) {
              ViewImage = (<img className={this.props.className} src={image.url} {...imgProps}/>);
          } 
          else {
              ViewImage = (<div className="previewText emptyImage">Edit to select an image, or paste an image.</div>);
          }
          
          let newThis = {...this};
          if(newThis.props.scope && newThis.props.scope.diagram_number){
              newThis.props.scope.diagram_number = "Image " + newThis.props.scope.diagram_number + "";
          }
              
          var title = templated(this.props.title || "", newThis, (title)=>{this.props.handleInlineChange({content: title})});
          
          return (
              <div className="imgPreview" key={this.props.root}>
                  {ViewImage}
                  <span className="image_title">{title}</span>
              </div>
          )
        }
 }
          