import React from 'react';
import { BoundCommit, InputDecimal, InputSelect, InputText } from '@opidcore/components';
import { APP } from '../../../../../js';
import _ from 'lodash';

const EditTax = ({taxModel = {id: 0, __type: "Tax"}}) =>{
    const myBoundId = "editTax" +  taxModel.id;

    const doSave = () => {
        return new Promise((resolve) =>{
            const myMagic = APP.registeredBoundMagics[myBoundId];
            if(myMagic != undefined){

                const deltas = myMagic.getAllDeltas();
                if(deltas != undefined && deltas.length > 0){ 
                    APP.central.Bound.saveChanges(deltas).then(() =>{
                        resolve("saved");
                    });
                } else {
                    resolve("nothing to save");
                }
            } else {
                resolve("no magic");
            }
        });
    }

    const xeroARCodes = {
        "QC - GST/QST on Purchases":"CAN027",
        "SK - GST/PST on Purchases":"CAN010",
        "AB - GST on Purchases":"CAN001",
        "ON - PST on Purchases":"TAX009",
        "QC - GST/QST on Sales":"CAN022",
        "SK - GST/PST on Sales":"CAN023",
        "BC - GST on Sales":"TAX002",
        "Sales Tax on Imports":"GSTONIMPORTS",
        "BC - GST/PST on Sales":"CAN015",
        "MB - GST/RST on Sales":"CAN028",
        "ON - GST on Purchases":"TAX007",
        "Tax Exempt":"NONE",
        "NT - GST on Purchases":"CAN011",
        "NU - GST on Purchases":"CAN013",
        "NB - HST on Sales":"CAN017",
        "NS - HST on Sales":"CAN019",
        "SK - GST on Purchases":"TAX006",
        "MB - GST/RST on Purchases":"CAN029",
        "AB - GST on Sales":"CAN014",
        "NU - GST on Sales":"CAN026",
        "Tax on Purchases":"INPUT",
        "ON - HST on Purchases":"CAN007",
        "BC - GST on Purchases":"TAX004",
        "NS - HST on Purchases":"CAN006",
        "ON - HST on Sales":"CAN020",
        "QC - HST on purchases":"TAX008",
        "Exempt Sales":"CAN030",
        "PE - HST on Purchases":"CAN008",
        "YT - GST on Sales":"CAN025",
        "SK - GST on Sales":"TAX003",
        "Multi-Province Federal Taxes":"TAX001",
        "NL - HST on Sales":"CAN018",
        "NB - HST on Purchases":"CAN004",
        "NT - GST on Sales":"CAN024",
        "Exempt Purchases":"TAX005",
        "Tax on Sales":"OUTPUT",
        "YT - GST on Purchases":"CAN012",
        "BC - GST/PST on Purchases":"CAN002",
        "NL - HST on Purchases":"CAN005",
        "PE - HST on Sales":"CAN021"
    };

    const xeroAROptions = [];
    const xeroAPOptions = [];
    _.forEach(_.keys(xeroARCodes), (key)=>{
        if(key.indexOf("Sales") > -1){
            xeroAROptions.push({key: key, value: key});
        } else if(key.indexOf("Purchases") > -1){
            xeroAPOptions.push({key: key, value: key});
        }
    });

    return <div key={"edit-tax-" + taxModel.id} className="edit-tax">
        <BoundCommit initialValue={taxModel} commit={doSave} boundId={myBoundId} closeOnCommit={true}>
            <InputText name="Id" field="id" disabled={true} />
            <InputText name="Name" field="name"/>
            <InputText name="Code" field="code" />
            <InputDecimal name="Rate" field="rate" places={3}/>
            <InputSelect name="Xero Code AR" field="xeroCodeAR" options={xeroAROptions} />
            <InputSelect name="Xero Code AP" field="xeroCodeAP" options={xeroAPOptions} />
            <InputText name="Region Split" field="regionSplit" disabled={true} />
        </BoundCommit>
    </div>
}

export default EditTax;