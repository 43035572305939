import React, { useEffect }  from 'react';
import Util from '@opidcore/Util.js';


export default function HeaderFooter(props) {
	
  return <p className="previewText pagebreak">PAGE BREAK <span className="type">{props.orientation}</span></p>;
}

HeaderFooter.getEditableProps = ({})=>{
  	        
        return [
            {niceName: "Orientation", prop: "orientation", options: ["portrait","landscape"], type: "text" },
        ]
        
};

HeaderFooter.metaInfo = ({})=>{
	return {
		category: 'scripts',
		icon: 'file',
		showInEditorQuickAdd: true
	};
};

