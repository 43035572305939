import React, { useState, useEffect, useContext, useRef}  from 'react';
//import Searchable from '@opidcore/components/Searchable';
//import ActionBar from '@opidcore/components/ActionBar';
import { Link } from 'react-router-dom';
import { Tabular, TabularColumn } from '@opidcore/components/Tabular';
import { Bound, InputText, InputSelect, Tabbed, Button, Icon  } from '@opidcore/components';
import { useData, useNavBar } from '@opidcore/hooks/WTF';
import './portal.scss';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveBar } from '@nivo/bar'
import * as d3 from 'd3'
import _ from 'lodash';
import moment from 'moment';
//import { refreshDataNeeded } from '@opidcore/actions/CentralActions';
import ThrowawayMap from './ThrowawayMap.jsx';
import DetailSite from '../UI/Site/DetailSite.jsx';

window.currentMonth = '2021-04';

const MT_CO2_PER_MT_WASTE = 3.2414553472987;

const niceRound = (val, places)=>{
	if(val == undefined){
		val = 0;
	}
	if (val.toFixed){
		return +val.toFixed(places == undefined ? 2 : places);
	}
	
	return val;
};

const Format = {
	asCurrency: (val)=>{
		return parseFloat(val).toLocaleString('en-us', { style: 'currency', currency: 'USD', maximumFractionDigits: 0});
	}
};

const COLORS = {
	blackish: '#4C4C4E',
	green: '#82D902',
	green2: '#cddc29',
	//green2: '#c0ec80',
	wsc_blue: '#00bacd',
	acid_green: '#ccdc29',
	navy: '#004a69',
	teal: '#009a91',
	blue: '#87D7FC',
	blue2: '#00BACD',
	blue3: '#c3e8ee',
	grey: '#C0C0C1',
	money: '#CDDC29',
	yellow: '#CDDC29',
	orange: '#dc9229',
	savings: '#3871FD'
};

const gridTheme = {
	fontSize: 16,
	fontFamily: 'Red Hat Display',
};

const gridThemeClearTooltip = {
	fontSize: 14,
	fontFamily: 'Red Hat Display',
		tooltip: { container: {borderRadius: '0px', background: 'none',boxShadow: 'none'}}
}

const NivoCustomContext = React.createContext({});

function ColorBlock({color}){
	return <div style={{height: '1em', width: '1em', display: 'inline-block', backgroundColor: color}}></div>;
}

export function StatBlock({value, color, icon, label, symbol, units, fType}){
	let displayValue = Number(value).toLocaleString();

	if (symbol == "$"){
		displayValue = Format.asCurrency(value);
	}

	if (units == "MT" && window.PORTAL_COUNTRY == "USA"){
		units = "Tons";
	}

	if (units == "MT CO₂" && window.PORTAL_COUNTRY == "USA"){
		units = "Tons CO₂";
	}

	return <div className="stat-block">
		<div className="stat"><Icon icon={icon} fType={fType || "fal"} color={color} size="2x"/>
			 <span className="stat-label">{label}</span> </div>
		<div className="stat-value" style={{color: color}}> {displayValue} {units ? <span className="stat-units">{units}</span> : null}</div>
	</div>
}

export function LargeStatBlock({value, color, icon, label, symbol, units, fType}){
	let percent = null;
	if (units == "%"){
		percent = "%";
		units = null;
	}

	if (units == "MT CO₂" && window.PORTAL_COUNTRY == "USA"){
		units = "Tons CO₂";
	}

	return <div className="stat-block-large">
		<div className="stat"><Icon icon={icon} fType={fType || "fal"} color={color} size="2x"/></div>
		<div className="stat-value"> {symbol} {Number(value).toLocaleString()} {percent} {units ? <span className="stat-units">{units}</span> : null}</div>
		<div className="stat-label">{label}</div>
	</div>
}




const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
	const custom = useContext(NivoCustomContext);

    let total = 0
    dataWithArc.forEach(datum => {
        total += datum.value
    })

    return (
        <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
                fontSize: '28px',
                color: '#c00'
            }}
        >
            {niceRound(custom.centeredMetric ? custom.centeredMetric : total,1)} {custom.centeredUnits}
        </text>
    )
}

const FancyLabels = (props) => {
	const custom = useContext(NivoCustomContext);

	/** 
    let total = 0
    dataWithArc.forEach(datum => {
        total += datum.value
    })

    return (
        <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
                fontSize: '28px',
                color: '#c00'
            }}
        >
            {total}%
        </text>
    ) */

	const blocks = [];
	
	_.each( props.dataWithArc, (d)=>{
		const pos = custom[d.id];
		const icon = <Icon icon={d.data.icon} fType={d.data.fType||"fal"} color={d.data.color} svg={true}/>;
		if (pos){
			blocks.push( <g transform={"translate(" + (pos.x) +"," + (pos.y-4) +") scale(0.055,0.055)"}>{icon}</g>)
		}
	});

	return blocks;
}

function GraphLabel({ material, value, color, units,all }){
	const icon = all.icon || "toilet";

	return <>
	 	<tspan x="0" dy="-0.6em" className="icons" data-id={all.id}>XX</tspan>
		<tspan dx="0" style={{fill: color, fontWeight: '500'}}> {material}</tspan>
		<tspan x="0" dy="1.2em" style={{fill: "#8E8E8E", fontWeight: '500'}}>{niceRound(value,1)} {units}</tspan>
	</>;
	/** return <foreignObject className="wow-star__text" width="100%" height="100%">
		<body>
			<strong><Icon icon={icon} color={color}/> {material}</strong>
			<em>{value} {units}</em>
		</body>
	</foreignObject> **/
}

function Tooltip( props ){
	
	if (props.data.type == 'diversion'){


		return <div className="tooltip">
			<h3>{props.data.all.nicePeriodName}</h3>
			<strong>Diversion Rate: {niceRound(props.data.all.divertedPercent)}%</strong>
		</div>;
	}

	if (props.data.type == 'diversion-previous'){

		let details = <strong>Diversion Rate: {niceRound(props.data.all.divertedPercent)}%</strong>;

		if  (props.data.all.divertedPercent == "NaN"){
			details = null;
		}

		const year = 2020;
		let previousYear = <strong>{year}: {niceRound(props.data.allLastYear.divertedPercent)}%</strong>;

		return <div className="tooltip">
			<h3>{props.data.all.nicePeriodName} Annual Comparison</h3>
			{details}
			<br/>
			{previousYear}
		</div>;
	}


	if (props.data.type == 'material'){

		const kg = niceRound(parseFloat(props.data.value),0).toLocaleString() + " " + props.data.units;

		return <div className="tooltip">
			<h3>{props.data.label}</h3>
			<strong>{kg}</strong>
		</div>;
	}

	if (props.data.type == 'ghg'){


		return <div className="tooltip">
			<h3>{props.data.label}</h3>

			<h4> GHG Produced: {niceRound(props.data.Produced,2)} </h4>
			<h4> GHG Saved: {niceRound(-1 * props.data.Saved,2)} </h4>
			<h4> Net: {niceRound(props.data.Produced+props.data.Saved,2)}</h4>
		</div>;
	}

	if (props.data.type == 'money'){
		return <div className="tooltip">{props.data.label}: {Format.asCurrency(props.value)}</div>;

	}
	
	return <div className="tooltip">{props.data.label}: {parseFloat(props.value).toFixed(2)}</div>;
}

function MaterialGraph({data, units}){
	const isUS = window.PORTAL_COUNTRY == "USA";

	const data2 = []; // {label: 'Waste', value: 100, type: 'material' }, {label: 'Cardboard', value: 30, type: 'material' }];
	_.each( data, (d)=>{
		data2.push( {label: d.name, value: d.metric_tonnes * 1000, color: d.main_colour, type: 'material', units: isUS ? 'lb' : 'kg' })
	} );
	return <BarGraph data={data2} layout="horizontal" units={units} legend={false} height={500} margin={{top: 10, left: 200, right: 80, bottom: 100}} tickValues={8}/>
}

function DiversionHistogram({data, maxValue}){
	const data2 = [];
	
	if (data !=  null){
		_.each( data, (d)=>{
			data2.push( { label: d.shortPeriodName, 'Waste': d.waste, 'Recycled': d.recycled, 'Diverted': d.diverted, type: 'diversion', all: d });
		});
	}

	return <BarGraph data={data2} keys={[ 'Waste', 'Recycled' ]} layout="vertical" maxValue={maxValue} tickValues={5}/>
}

function SavingsAccumulated({data, maxValue}){
	const data2 = [];
	
	if (data !=  null){
		let running = 0;
		_.each( data, (d)=>{
			let thisMonth = d.savings; //1000 + 500*(Math.random());
			running += thisMonth;
			if (d.cost > 0){
				data2.push( { label: d.shortPeriodName, 'Accumulated Savings': running, type: 'money', all: d });
			}else{
				data2.push( { label: d.shortPeriodName, 'Accumulated Savings': 0, type: 'money', all: d });
			}
			
		});
	}

	return <BarGraph data={data2} keys={[ 'Accumulated Savings']} layout="vertical" maxValue={maxValue} tickValues={5} symbol="$"/>
}

function CostAvoidance({data, maxValue}){
	const data2 = [];
	
	if (data !=  null){
		_.each( data, (d)=>{
			//let thisMonth = 1000 + 500*(Math.random());
			data2.push( { label: d.shortPeriodName, 'Monthly Costs': d.cost, 'Costs Avoided': d.avoidance * 5.5, type: 'money', all: d });
		});
	}

	return <BarGraph data={data2} groupMode={"stacked"} keys={[ 'Monthly Costs', 'Costs Avoided' ]} layout="vertical" maxValue={maxValue} tickValues={5} symbol="$"/>
}



function DiversionYearly({data, dataLastYear,maxValue}){
	const data2 = [];
	
	let years = [];
	if (data !=  null){
		_.each(  _.range(0,12), (month)=>{
			const d = data[month];
			const lastD = dataLastYear[month];
			const year = d.date.split("-")[0];
			const lastYear = lastD.date.split("-")[0];
			years[0] = lastYear;
			years[1] = year;
			const plot = { label: d.shortPeriodName, type: 'diversion-previous', all: d, allLastYear: lastD }
			plot[year] = d.divertedPercent;
			plot[lastYear] = lastD.divertedPercent;
			data2.push( plot);
		});
	}

	return <BarGraph data={data2} groupMode={"grouped"} keys={years} layout="vertical" maxValue={maxValue} tickValues={5}/>
}


export function BarGraph({children, data, xAxisLabel, yAxisLabel, keys, layout, margin, maxValue, minValue, tickValues, groupMode, height=300,legend=true,symbol}){
	
	let _margin = margin || {top: 10, left: 75, right: 20, bottom: 100};
	//let labelFormat = null;
	if (layout == "horizontal"){
		_margin = {top: 10, left: 125, right: 40, bottom: 100};
		//labelFormat = (a,b)=>{ return "cheese"; }
	}
	const padding = {};

	const [custom, setCustom] = useState({cheese: 72});
	const container = useRef();
	
	const colors = (a,b,c)=>{
		if (["Waste","Produced"].indexOf(a.id) >= 0){
			return COLORS.grey;
		}
		if (["Recycled"].indexOf(a.id) >= 0){
			//return COLORS.blue;
			return COLORS.wsc_blue;
		}

		

		if (a.data.color){
			return a.data.color;
		}

		if (["2021","Diverted","Saved","Monthly Costs"].indexOf(a.id) >= 0){
			return COLORS.blue2;
		}

		if (["Accumulated Savings"].indexOf(a.id) >= 0){
			return COLORS.teal;
		}
		
		if (["2020","Previous Year","Costs Avoided"].indexOf(a.id) >= 0){
			return COLORS.blue3;
		}

		return '#c00';
	};
	
	let _maxValue = maxValue ? maxValue : 'auto'; //_.max(data.map((d)=>d.value)) * ( 1.20 );
	
	const axisLeft = {
		// tickSize: 5,
		tickValues: tickValues ? tickValues : 'auto',
		tickPadding: 5,
		tickRotation: 0,
		legend: yAxisLabel,
		legendPosition: 'middle',
		legendOffset: -1 * _margin.left + 20
	};

	if ( symbol == "$"){
		axisLeft.format = (f)=>Format.asCurrency( f )
	}

	const graph = <ResponsiveBar
		
		//colors={ourColor}
		  colors={colors}
		  groupMode={groupMode || "stacked"}
		  data={data}
		  theme={gridThemeClearTooltip}
		  keys={keys}
		  indexBy="label"
		  layout={layout || "vertical"}
		  margin={_margin}
		  enableLabel={false}
		  padding={0.5}
		  axisTop={null}
		  axisRight={null}
		  tooltip={Tooltip}
		enableGridX={false}
		enableGridY={true}
		minValue={minValue ? minValue : 0}
		maxValue={_maxValue}
		valueScale={{"type": "linear"}}
		gridYValues={5}
		  axisBottom={{
			  //tickSize: 5,
			  tickValues: tickValues ? tickValues : 'auto',
			  tickPadding: 5,
			  tickRotation: 0,
			  legend: xAxisLabel,
			  legendPosition: 'middle',
			  legendOffset: 32,
		  }}
		  axisLeft={axisLeft}
		  legends={legend ? [
            {
                dataFrom: 'keys',
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 70,
                itemsSpacing: 2,
                itemWidth: 130,
                itemHeight: 10,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 24,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ] : [] }
		  zlabelFormat={ (d,a,b) => { debugger; return <tspan>{ d }?</tspan> }}
		  animate={false}
		  //abarComponent={ (props) => <BarComp {...props} maxBarHeight={maxBarHeight}/>}
	  />;
	return <div>
		<NivoCustomContext.Provider value={custom}>
			<div ref={container} style={{width: "100%", height: height}}>
			{graph}
			</div>
		</NivoCustomContext.Provider>
		{children}
	</div>

}

function CircleChart({children, data, units, centeredMetric, colorScheme, margin}){
	const container = useRef();

	const [custom, setCustom] = useState({units: units, centeredMetric: centeredMetric});

	if (margin == null){
		margin = {top: 20, left: 10, right: 10, bottom: 30};
	}
	
	const labeller = (a,b,c)=>{
		return <GraphLabel material={a.label} value={a.formattedValue} color={data.color} units={units} all={a}/>
	};



	const updatePositions = ()=>{
		const positions = _.cloneDeep(custom);
		let changes = false;
		d3.select(container.current).selectAll('svg .icons').each( function(d,i){
			d3.select(this).attr('fill','rgba(255,255,255,0)');
			if (this && this.farthestViewportElement){
				const svgBounds = this.farthestViewportElement.getBoundingClientRect();
				const myBounds = this.getBoundingClientRect();

				const myCoords = {x: myBounds.x - svgBounds.x - margin.left - 10, y: myBounds.y - svgBounds.y - margin.top + 5, height: myBounds.height};
				const id = this.dataset.id;

				if (! _.isEqual( positions[id], myCoords )){
					changes = true;
					positions[id] = myCoords;
				}
			}

		});

		if (changes){
			setCustom(positions);
		}
	};

	useEffect(()=>{
		const observer = new MutationObserver(function() {
			updatePositions();
		});

		observer.observe(container.current, {subtree: true, childList: true});
	},[]);

	const forcedProps = {units: units};

	const color = colorScheme ? {scheme:colorScheme}  : { datum: 'data.color' };

	const graph = <ResponsivePie data={data}
		colors={color}
		margin={margin}
		animate={0}
		theme={gridTheme}
		enableRadialLabels={true}
		radialLabelsSkipAngle={10}
		radialLabelsTextXOffset={6}
		radialLabelsTextColor="#333333"
		radialLabel={labeller}
		innerRadius={0.7}
		padAngle={1}
		startAngle={-37}
		enableSlicesLabels={true}
		sliceLabel={null}
		animate={false}
		alayers={['slices', 'sliceLabels', 'radialLabels',CenteredMetric, FancyLabels]}
		layers={['arcLinkLabels', 'arcs','legends', CenteredMetric, FancyLabels]}
		/>;

	return <div>
		<NivoCustomContext.Provider value={custom}>
			<div ref={container} style={{width: "100%", height: 300}}>
			{graph}
			</div>
		</NivoCustomContext.Provider>
		{children}
	</div>
}

function Loading({}){
	return <div>Loading</div>;
}

function Summary({portalData, currentMonth}){
	if (portalData.materials == null){
		return <Loading/>
	};


	const thisMonth = portalData.materials.filter((r)=>r.date == currentMonth);
	const totalTonnes = _.sum(thisMonth.map(r=>r.metric_tonnes));
	

	const updateOptions = ()=>{
		//("do something");
	}

	let kg_title = "kg";
	if (window.PORTAL_COUNTRY == "USA"){
		kg_title = "lb";
	}
	
	return <>
		<div className="page-block stats"> 
			
			<StatBlock value="9783" icon="garbage" fType="fac" label="Waste" color={COLORS.grey} units="MT"/>
			<StatBlock value="3340" icon="recycle" label="Recycled" color={COLORS.blue} units="MT"/>
			<StatBlock value="1100" icon="organics" fType="fac" label="Organics" color={COLORS.green} units="MT"/>
			<StatBlock value="243" icon="ghg" fType="fac" label="GHG" color={COLORS.blue2} units="MT CO₂"/>
			<StatBlock value="2800" icon="piggy-bank" label="Savings" color={COLORS.money} symbol="$"/>
			<StatBlock value="7290" icon="lightbulb-dollar" label="Total Savings" color={COLORS.savings}  symbol="$"/>
			
		</div>

		<div className="flex"> 
				<div className="page-block">
					<h2><DateTitle month={currentMonth}/> Diversion rate</h2>
					<CircleChart data={ [
						{id: "waste", icon: "garbage", fType: "fac", label: 'Waste', color: COLORS.blackish, value: 100, },
						{id: "recycle", icon:'recycle', label: 'Recycled', color: COLORS.wsc_blue, value: 68}, 
						{id: "organics", icon:'organics', fType: "fac", label: 'Organics', color: COLORS.acid_green, value: 68}]  } units="MT" centeredMetric="41%"></CircleChart>
				</div>
				<div className="page-block">
					<h2>Historical diversion rate</h2>
					<DiversionHistogram data={ portalData.diversion } maxValue={30}/>
				</div>
		</div>

		<div className="page-block">
		<h2>Material composition</h2>

			<div className="flex"> 
				<div style={{paddingRight: 50}}>
					<MaterialGraph data={thisMonth} units="MT"/>
				</div>
					
				<div>
					<Tabular data={thisMonth}>
						<TabularColumn data={ (row)=><><ColorBlock color={row.main_colour}/> {row.name}</> } title="Material class"/>
						<TabularColumn data={ (row)=>row.material_type } title="Type"/>
						<TabularColumn data={ (row)=>niceRound(row.metric_tonnes*1000,0)} title={kg_title}/>
						<TabularColumn data={ (row)=>niceRound(100 * row.metric_tonnes/totalTonnes,1) } title="%"/>
					</Tabular>
				</div>
			</div>
		</div>

		<div className="page-block">
			<RankBlock portalData={portalData} currentMonth={currentMonth}/>
		</div>
	</>;
}

function RankBlock({ portalData, currentMonth }){
	const [siteView, setSiteView] = useState("Diversion");


	const thisMonth = portalData.materials.filter((r)=>r.date == currentMonth);
	const totalTonnes = _.sum(thisMonth.map(r=>r.metric_tonnes));
	const thisMonthSites = portalData.sites.filter((r)=>r.date == currentMonth);
	let rank = 1;
	_.each( thisMonthSites, (s)=>{ 
		s.rank = rank++ 
		s.percent_diverted =  s.metric_tonnes_diverted / (s.metric_tonnes_diverted + s.metric_tonnes_waste) * 100;
		if ( isNaN(s.percent_diverted) ){
			s.percent_diverted = 0;
		}
	});

	let siteCols = [<TabularColumn data={(r)=>r.rank} title="Rank" style={{width: "5em"}}/>,
		<TabularColumn data={(r)=>r.name} title="Name"/>,
		<TabularColumn data={(r)=>r.address} title="Address"/>,
		<TabularColumn data={(r)=>r.city +", " + r.province} title="City"/>];
	let siteTitle = "?";

	if (siteView == "Diversion"){
		siteTitle = "Landfill diversion ranking";
		siteCols.push( <TabularColumn data={(r)=>r.diversion_target} title="Diversion Target"/> );
		if (window.PORTAL_COUNTRY == "USA"){
			siteCols.push( <TabularColumn data={(r)=>niceRound(r.metric_tonnes_diverted,1)} title="Diverted (Tons)"/> );
		}else{
			siteCols.push( <TabularColumn data={(r)=>niceRound(r.metric_tonnes_diverted,1)} title="Diverted (MT)"/> );
		}
		
		siteCols.push( <TabularColumn data={(r)=>niceRound(r.percent_diverted,1)} title="Diverted (%)"/> );
	}

	if (siteView == "Savings"){
		siteTitle = "Site savings ranking";
		siteCols.push( <TabularColumn className="right" data={(r)=>Format.asCurrency( r.total_savings )} title="Savings"/> );
	}

	if (siteView == "Carbon Reduction"){
		siteTitle = "Site carbon reduction ranking";

		if (window.PORTAL_COUNTRY == "USA"){
			siteCols.push( <TabularColumn data={(r)=>niceRound(r.metric_tonnes_diverted * MT_CO2_PER_MT_WASTE,1) } title="Tons CO₂ Saved (Recycled)"/> );
			siteCols.push( <TabularColumn data={(r)=>niceRound(r.metric_tonnes_waste * MT_CO2_PER_MT_WASTE,1) } title="Tons CO₂ Produced (Waste)"/> );
			siteCols.push( <TabularColumn data={(r)=>niceRound((r.metric_tonnes_diverted - r.metric_tonnes_waste ) * MT_CO2_PER_MT_WASTE,1) } title="Tons CO₂ Net Reduction"/> );
		}else{
			siteCols.push( <TabularColumn data={(r)=>niceRound(r.metric_tonnes_diverted * MT_CO2_PER_MT_WASTE,1) } title="MT CO₂ Saved (Recycled)"/> );
			siteCols.push( <TabularColumn data={(r)=>niceRound(r.metric_tonnes_waste * MT_CO2_PER_MT_WASTE,1) } title="MT CO₂ Produced (Waste)"/> );
			siteCols.push( <TabularColumn data={(r)=>niceRound((r.metric_tonnes_diverted - r.metric_tonnes_waste ) * MT_CO2_PER_MT_WASTE,1) } title="MT CO₂ Net Reduction"/> );
		}
	}

	return <>
		<h2>{siteTitle}</h2>
		<InputSelect className="select-view" field="viewSitesBy" defaultValue={siteView} options={["Diversion","Savings","Carbon Reduction"]} handleChange={(a,b,c)=>{ setSiteView(a.currentTarget.value) }}/>
		<Tabular data={thisMonthSites} pageSize={6}>				
				{siteCols}
		</Tabular>
	</>;
}

function GHGTrends({ data }){

	const maxValue = 65;
		const data2 = [];
		
		if (data !=  null){
			_.each( data, (d)=>{
				data2.push( { label: d.shortPeriodName, 'Produced': d.waste * MT_CO2_PER_MT_WASTE, 'Saved': -1 * d.recycled * MT_CO2_PER_MT_WASTE, type: 'ghg', all: d });
			});
		}
	
	return <BarGraph data={data2} keys={[ 'Produced', 'Saved' ]} groupMode="grouped" layout="vertical" maxValue={maxValue} minValue={-45} tickValues={5} height={500}/>
	
}

function Environmental({portalData, currentMonth}){

	const thisMonth = portalData.materials.filter((r)=>r.date == currentMonth);
	const compGraphData = thisMonth.map( (r)=>{  return {id: r.name, icon: r.icon, color: r.main_colour, value: r.metric_tonnes } });

	const fakeYTD = portalData.diversion.map( (r)=>{

	});

	//const monthlyWaste = [];
	let monthlyCols = [];
	if (window.PORTAL_COUNTRY == "USA"){
	 	monthlyCols = [<TabularColumn data={ (row)=><><ColorBlock color={row.main_colour}/> {row.name}</>} title="Material class (lb)" className="material-col"/>];
	}else{
		monthlyCols = [<TabularColumn data={ (row)=><><ColorBlock color={row.main_colour}/> {row.name}</>} title="Material class (kg)" className="material-col"/>];
	}
	const groups = _.groupBy(portalData.materials, (r)=>r.name);
	const monthlyWaste = _.map( groups, (group)=>{
		const row = _.pick( group[0], "name", "accent_colour", "main_colour", "density", "material_type" );
		_.each( group, (g)=>{
			const month =  moment(g.date + "-01").format("MMM");
			row[ month ] = g.metric_tonnes;
		});
		return row;
	});
	_.each( _.range(0,12), (month)=>{
		const niceMonth = new moment().set('month', month).format('MMM');
		const className = (month+1) == parseInt(currentMonth.split("-")[1]) ? "current-month" : "";
		monthlyCols.push( <TabularColumn data={ (row)=>niceRound(row[niceMonth],1) } title={niceMonth} className={className}/> );
	})

	// <TabularColumn data={ (row)=>niceRound(row.metric_tonnes * 1000,1) } title="kg"/>

	const tons_unit = window.PORTAL_COUNTRY == "USA" ? "Tons" : "MT";
	const ghg_units = window.PORTAL_COUNTRY == "USA" ? "Tons CO₂" : "MT CO₂"; 

	return <>
				<div className="flex"> 
					<div className="page-block">
						<h2><DateTitle month={currentMonth}/> Diversion rate</h2>
						<CircleChart data={ [{id: "waste", icon:'garbage',fType:'fac', label: 'Waste', color: COLORS.blackish, value: 100, },
						  {id: "recycle", icon:'recycle', label: 'Recycled', color: COLORS.wsc_blue, value: 68}, 
						  {id: "organics", icon:'organics', fType:"fac", label: 'Organics', color: COLORS.acid_green, value: 68}]  } units="MT" centeredMetric="41%"/>
					</div>

					<div className="page-block">
						<h2>GHG Emissions Impact ({ghg_units})</h2>

						<div className="flex" style={{marginTop: "6em"}}>
							<LargeStatBlock value="3233" icon="ghg" fType="fac" label="GHG Saved from Recycling" color={COLORS.blue} units="MT CO₂"/>
							<LargeStatBlock value="4332" icon="footprint" fType="fac" label="Reduction from Benchmark" color={COLORS.green} units="MT CO₂"/>
							<LargeStatBlock value="1227" icon="cloud" label="Net Emissions Produced" color={COLORS.purple} units="MT CO₂"/>
						</div>

					</div>
				</div>

				<div className="page-block">
					<h2>Diversion rate history</h2>

					<DiversionYearly data={ portalData.diversion } dataLastYear={ portalData.diversionLastYear } maxValue={65}/>

				</div>

				<div className="page-block">
					<h2>Material composition</h2>
					<div className="flex">
						<div>
							<CircleChart data={compGraphData}/>
						</div>

						<div>
							<Tabular data={thisMonth}>
								<TabularColumn data={ (row)=><><ColorBlock color={row.main_colour}/> {row.name}</> } title="Material class"/>
								<TabularColumn data={ (row)=>row.material_type } title="Type"/>
								{ false ? <TabularColumn data={ (row)=>niceRound(row.density,1)} title="kg / yard"/> : null }
								{ (window.PORTAL_COUNTRY == "USA") ?
<TabularColumn data={ (row)=>niceRound(row.metric_tonnes * 1000,0) } title="lb"/> :
<TabularColumn data={ (row)=>niceRound(row.metric_tonnes * 1000,0) } title="kg"/> }
							</Tabular>
						</div>
					</div>
				</div>

				<div className="page-block">
					<h2>GHG emissions trends ({tons_unit} CO₂)</h2>
					
					<GHGTrends data={ portalData.diversion }/>
					

				</div>

				<div className="page-block">
					<h2>Waste stream composition by month</h2>
					
					<Tabular data={monthlyWaste} className="centered-data">
								{monthlyCols}
					</Tabular>

				</div>
	</>
}

function Financial(props){
	const portalData = props.portalData;

	return <>
		<div className="flex"> 
					<div className="page-block">
						<h2><DateTitle month={props.currentMonth}/> Key financial stats</h2>

						<div className="flex">
							<LargeStatBlock value="12332" icon="envelope-open-dollar"  label="April Cost Savings" color={COLORS.yellow} symbol="$"/>
							<LargeStatBlock value="243931" icon="piggy-bank" label="Accumulated Savings" color={COLORS.blueg} symbol="$"/>
							<LargeStatBlock value="15.4" icon="hand-holding-usd" label="Average Savings" color={COLORS.green2} units="%"/>
						</div>
					</div>
		</div>

		<div className="page-block">
						<h2>Accumulated savings</h2>

						<SavingsAccumulated data={ portalData.savings } />

		</div>

		<div className="page-block">
						<h2>Cost avoidance</h2>
						<CostAvoidance data={ portalData.savings } />
		</div>

	</>;
}

function DateTitle(props){
	const month = moment(props.month + "-01").format("MMMM YYYY");
	return <span className="title-date-picker">{month} {props.children}</span>;
}

function SiteComparision(props){
	const currentMonth = props.currentMonth;
	const portalData = props.portalData;
	const [viewWhat, setViewWhat] = useState("metric_tonnes_diverted");
	const [viewBy, setViewBy] = useState("group");
	const [topFiveWhat, setTopFiveWhat] = useState("diversion_percent");
	
	const grouped = _.groupBy(_.filter(portalData.sites, {date: currentMonth}), viewBy);
	const compareData = [];
	_.each( grouped, (values, key)=>{
		compareData.push({id: key, label: key, value: _.sum(_.map(values, viewWhat)) });
	});


	let sorted = _.orderBy( _.filter(portalData.sites, {date: currentMonth}), topFiveWhat).reverse();
	const topFive = _.cloneDeep( sorted.slice(0,5) );
	_.each( topFive, (r,i)=>{ r.rank = i + 1 });
	
	let topFiveCol = null;
	if (true){
		if (topFiveWhat == "diversion_percent"){
			topFiveCol = <TabularColumn title={"Diversion %"} data={(row)=>niceRound(row[topFiveWhat] >= 92 ? 92 : row[topFiveWhat],2)}/>
		}else{
			topFiveCol = <TabularColumn title={"Savings"} data={(row)=>Format.asCurrency(row[topFiveWhat])}/>
		}
	}

	let options = {"group": "Region", "banner": "Banner","province":"Province"};

	if (window.PORTAL_COUNTRY == "USA"){
			options = {"group": "Region", "banner": "Banner","province":"State"};
	}

	return <>
		<div className="flex"> 
					<div className="page-block">
						<h2>Group Comparison</h2>
						<div className="select-view">
							<InputSelect field="viewWhat" showEmpty={false} defaultValue={viewWhat} options={{"metric_tonnes_diverted": "Diverted Materials", total_savings: "Savings"}} handleChange={(a,b,c)=>{ setViewWhat(a.currentTarget.value) }}/>
							<InputSelect  field="viewBy" showEmpty={false} defaultValue={viewBy} options={options} handleChange={(a,b,c)=>{ setViewBy(a.currentTarget.value) }}/>
						</div>

						<CircleChart colorScheme="set3" data={ compareData } margin={{top: 40, left: 10, right: 10, bottom: 40}}/>

					</div>

					<div className="page-block">
						<h2>Top 5 Sites</h2>
						<div className="select-view">
							<InputSelect field="topFiveWhat" showEmpty={false} defaultValue={topFiveWhat} options={{"diversion_percent": "Diversion %", total_savings: "Savings"}} handleChange={(a,b,c)=>{ setTopFiveWhat(a.currentTarget.value) }}/>
						</div>
						<Tabular data={topFive} defaultSort={"rank"}>
							<TabularColumn title="Rank" data={(row)=>row.rank}/>
							<TabularColumn title="Site" data={(row)=>row.name}/>
							<TabularColumn title="Region" data={(row)=>row.group}/>
							{topFiveCol}
						</Tabular>
					</div>
		</div>

		<div className="page-block">
					<h2>Site Rankings</h2>


					<RankBlock portalData={portalData} currentMonth={currentMonth}/>
		</div>

	</>;
}

function SiteComparision2(props){
	   //let params = useParams();	
	   const [data, dataSet] = useData("site", {customer: 1216, historical: 0});
	   const filters = useRef({viewBy:"Diversion"});
		const [title, setTitle] = useState("?")

	   useEffect( ()=>{
			_.each( data, (row)=>{
				row.target = 65;
				row.diversion = (70 + (10*Math.random() - 5)).toFixed(1);
				row.ghg = (10 + (10*Math.random() - 5)).toFixed(1);
			});
	   }, [data]);

	   const option = <TabularColumn data={(row)=>row.ghg || 7} title="GHG"/>; //filters.current.viewBy == "aGHG" ?  : <TabularColumn data={(row)=>row.diversion || 70} title="Diversion"/>; 

	   const refresh = ()=>{
		   debugger;
		   setTitle("cheese " +  filters.current.viewBy );
	   }
	   return <div key="detail_list" className="detail_list">
       <h4 className="title">Services and baseline costs</h4>

       <Bound to={filters.current} update={()=>refresh()}>
	   	<InputSelect field="viewBy" options={["Diversion","GHG"]}/>
	   </Bound>

	   		<h1>{title}</h1>
       		<Tabular data={data}>
			   <TabularColumn data={(row)=>row.quantity || 1} title="Rank"/>
	           <TabularColumn data={(row)=><Link to={"ui/sites/detail/" + row.id}>{row.name}</Link>} title="Site Name"/>
	           
			   <TabularColumn data={(row)=>row.address || "123 Some Street"} title="Address"/>			   
			   <TabularColumn data={(row)=>(row.city || "Tillsonburg") + ", " + (row.province || "ON") } title="City"/>
			   <TabularColumn data={(row)=>row.target || 65} title="Target"/>
			   {option}
	        </Tabular>
	   </div>
}

function Filters(props){
	const currentMonth = moment(props.currentMonth + "-01").format("MMMM yyyy");

	const options = ["ABC Co.","East Region","West Region","North","GTA","Banner 1", "Banner 2", "Banner 3"];
	
	return <>

		<Bound to={props.portalData}>
				
				<div style={{display: "flex", justifyContent: "space-between"}}>
					<InputSelect options={options}  defaultValue={options[0]}/>
				
					<div className="search"><Icon icon="search" color="#cccccc"/><InputText className="searchText" placeholder="Search groups, site name, address, regions…"/></div>
					<div className="field filter-outer"><Button className="filter">Filter <Icon icon="filter" color="#cccccc"/></Button></div>

					<div className="field sites">
						Viewing 203 / 203 Sites
					</div>

					<div className="dates"><Icon icon="calendar" color="#cccccc"/><InputText className="searchText" placeholder={currentMonth}/></div>

				</div>



		</Bound>

	</>
}

export function PortalDemoSite(props){
	const siteId = parseInt(props.match.params.id)

	return <DetailSite siteId={siteId} mode="portal"/>

}

export function PortalDemoSites(props){
	const [portalData, setPortalData]  = useState({});
	const sharedProps = {portalData: portalData, currentMonth: window.currentMonth};

	//const nb = useNavBar("Sites", []);
	const [page,setPage] = useState(0);
	//const nb = useNavBar(<Filters portalData={portalData} outerTag="div"/>);

	let nb = useNavBar(<Filters portalData={portalData} outerTag="div" {...sharedProps} title="Sites"/> , [], {menu: "da"});


	const currentMonth = "2021-03";
	
	const args = {month: sharedProps.currentMonth, company: sharedProps.company, demoType: window.PORTAL_COUNTRY};

	const refreshPortal = ()=>{
		fetch('/api/v1/portal/demo', {method: 'post', body: JSON.stringify(args)})
			.then( (r)=>r.json() ).then( (r)=>{
				setPortalData(r.result);
			});
	}

	useEffect( ()=>{
		refreshPortal();
	}, []);

	if (portalData.sites == null){
		return <Loading/>;
	}

	const thisMonthSites = portalData.sites.filter((r)=>r.date == currentMonth);

	const chunks = _.chunk(thisMonthSites,8);
	
	const pages = chunks.map( (c,i)=>{
		return <span key={i} className={"page-num" + (page == i ? " page-num-active" : "")} onClick={()=>setPage(i)}>{i+1}</span>;
	});

	pages.unshift(<Icon icon="chevron-double-left" onClick={()=>page > 0 ? setPage(page-1) : null}/> );
	pages.push(<Icon icon="chevron-double-right" onClick={()=>(page < chunks.length - 1) ? setPage(page+1) : null}/>);

	/** <StatBlock value={niceRound(s.diversion_percent,0)} icon="recycle" label="Diversion" color={COLORS.blue2} units="%"/>
				<StatBlock value={s.total_savings} icon="piggy-bank" label="Total Savings" color={COLORS.money} symbol="$"/> */

	const sites = _.map( chunks[page], (s)=>{
		return <div className="site" key={s.site_id}>
			<Link to={"/portal/site/" + s.site_id}><h3>{s.name}</h3></Link>
			<strong className="address">{s.address}, {s.city}, {s.province}</strong>
			<div className="stats">
				<div>Diversion: {niceRound(s.diversion_percent,0)}%</div>
				<div>Monthly Savings: {Format.asCurrency(s.total_savings)}</div>
			</div>
		</div>;
	});
	return <div className="sites-demo">
		<div className="sites-outer">
			<div className="site-list">
				{sites}
				<div className="pages pagination">{pages}</div>
			</div>
			<ThrowawayMap markers={ chunks[page].map( (s)=>{  return [s.map_lat,s.map_long,s.name] } ) }/>
		</div>
	</div>;
}

export default function PortalDemo(props){
	const [portalData, setPortalData]  = useState({});
	const sharedProps = {portalData: portalData, currentMonth: window.currentMonth, company: props.company};

	useEffect( ()=>{
		const isUS = props.match.path.indexOf("wsa") >= 0;

		if (isUS){
			const b = document.getElementsByTagName("body")[0];
			b.classList.add("wsa");
			window.PORTAL_COUNTRY = "USA";
		}else{
			const b = document.getElementsByTagName("body")[0];
			b.classList.remove("wsa");
			window.PORTAL_COUNTRY = "CAN";
		}
		
	}, [props.match]);
	
	const [args, setArgs]  = useState({month: sharedProps.currentMonth, company: sharedProps.company, demoType: window.PORTAL_COUNTRY});

	let nb = useNavBar(<Filters portalData={portalData} outerTag="div" {...sharedProps}/>);

	
	const refreshPortal = ()=>{
		const theArgs = {...args};
		theArgs.demoType = window.PORTAL_COUNTRY;
		fetch('/api/v1/portal/demo', {method: 'post', body: JSON.stringify(theArgs)}).then( (r)=>r.json() ).then( (r)=>{
			setPortalData(r.result);
			APP.instance.setState({loggedIn: true});
		});
	}
	useEffect(()=>{
		//console.log("args changed", nb);
		refreshPortal();
	}, [args]);

	useEffect( ()=>{
		APP.forceMenu( ()=>{
			const items = [];

			/** Dashboard
			Sites
			Schedule
			Invoices
			Reports
			Documents
			Support
			Settings **/
			
			items.push(<li key="dashboard"><Link to="/portal"><Icon icon="tachometer"/> Dashboard</Link></li>);
			items.push(<li key="sites"><Link to="/portal/sites"><Icon icon="map-marker-alt"/> Sites</Link></li>);
			items.push(<li key="schedule"><Link to="/portal/schedule"><Icon icon="calendar"/> Schedule</Link></li>);
			items.push(<li key="invoices"><Link to="/portal/invoices"><Icon icon="file-invoice-dollar"/> Invoices</Link></li>);
			items.push(<li key="reports"><Link to="/portal/reports"><Icon icon="file-invoice"/> Reports</Link></li>);
			items.push(<li key="documents"><Link to="/portal/documents"><Icon icon="file-alt"/> Documents</Link></li>);
			items.push(<li key="support"><Link to="/portal/support"><Icon icon="life-ring"/> Support</Link></li>);

			items.push(<li key="settings"><Link to="/ui/settings"><Icon icon="cog"/> Settings</Link></li>);
			
			return items;
		});
	});


	return <Tabbed title="Dashboard" className="portal portal-demo">
		<Summary key="groups" title="Summary" {...sharedProps}/>
		<Environmental key="groups" title="Environmental" {...sharedProps}/>
		<Financial key="groups" title="Financial"  {...sharedProps}/>
		<SiteComparision key="groups" title="Site Comparison"  {...sharedProps}/>					
	</Tabbed>
}
