import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import BoundComponent from './BoundComponent';

import { DataContext } from './Bound';

import * as _ from 'lodash';

export default class Calculated extends BoundComponent {

  static propTypes = {
    update: PropTypes.func,
    property: PropTypes.string,

  };
  
  static contextType = DataContext;

  constructor(props, context) {
	  super(props, context);
	 
	  this.state = {value: "", me: context.me ? context.me.props.to : context }

	  context.on("*", (a,b,c,d)=>{
		this.setState({recalculate: Math.random()});
	  });
  }
  
  static getDerivedStateFromProps(props, state){
	  var className = null;
	  var value = null;
	  var me = state.me;
	  
	  if (props.formula){
			if ( props.formula.eval ){
				var value = eval(props.formula.eval);
				if (value == null || value.toString && (value.toString().includes("NaN") || value.toString() == "Infinity") ){				
					value = 0;
				}
			}
			
			if ( props.formula["function"] ){
				var myFunc = eval( props.formula["function"] );
				value = myFunc(me, _);
			}
		}
		
		if ( props.valueFunction ){
			value = this.props.valueFunction(me, _);
		}
		
		if (typeof value == "object"){
			className = value.className;
			value = value.value;			
		}
		
		if (value != state.value || className != state.className){
			return {value: value, className: className};
		}
		
		return null;
  }
  
  componentDidUpdate(){  
	  const value = this.state.value;
	  const currentValue = this.context.magicalGet(this.props.field);
	  
	  if(this.context.to && this.context.to._ourMagic == this.state.me){
		//all is ok
	  }else if (this.state.me != this.context.to){
		this.setState({me: this.context.to});
	  }
	  
	  if (this.props.setField){
			if (this.state.className == "error"){
				if ( currentValue != value){
					this.context.magicalSet(this.props.field, value);
				}
			}else{
				
				if (this.lastSet != value && currentValue != value){
					this.lastSet = value;
					if (_.isNaN(value) == false){
						this.context.magicalSet(this.props.field, value);
					}
				}
				
			}
		}
  }
  
  componentDidMount(){
	  if(this.props != undefined && this.props.value != undefined){
	    	this.setState({value: this.props.value});
	    	this.props.update(this.props.obj, this.props.field, this.props.value);
	    }
  }


  handleChange(e) {

  }

  
  render() {
	var icon = null;
	if (this.state.className == "error"){
		icon = <span className="glyphicon glyphicon-remove"/>
	}
    return <span className={this.state.className}>{icon}{this.state.value}</span>    
  }
}
