import React, { useEffect, useState } from "react";
import { useData, useNavBar } from "@opidcore/hooks/WTF";
import {Button} from "@opidcore/components";
import _ from "lodash"; 

export default function Status(props) {
  useNavBar("Wastemetric Status", []);
  const [status, setStatus] = useState({xeroStatus: {}});

  const [data, dataSet] = useData("queue_status", APP.central.ScheduledTasks.adminStatus, {   });
  useEffect( ()=>{
    dataSet.callback( (data)=>{
        setStatus(data[0]);
    });
  }, [dataSet]);

  const remaining = status.xeroStatus ? status.xeroStatus.requestsRemaining : -1;
  const requestsRetryIn = remaining == 0 ? " Xero locked out for " + status.xeroStatus.requestsRetryIn + " - ": "";

  return <div className="backend-status">
        <h2>Job Status</h2>
        <TaskRunning data={status} name="Files Process" runningFlag="filesRunning"/>
        <TaskRunning data={status} name="Outbound Emails Process" runningFlag="outboundRunning">
          { status.emailDisabled ? <Button onClick={()=>APP.central.ScheduledTasks.enableEmailQueue().then( ()=>dataSet.fetch() )}>Start Emails</Button> : <Button onClick={()=>APP.central.ScheduledTasks.disableEmailQueue().then( ()=>dataSet.fetch() )}>Stop Emails</Button>}
        </TaskRunning>
        <TaskRunning data={status} name="Xero Batch" runningFlag="xeroRunning"/>
        <TaskRunning data={status} name="Job Scheduler" runningFlag="ranScheduler"/>

        <TaskState data={status} name="Xero Token" flag="xeroTokenOK" extra={requestsRetryIn + remaining + " Remaining Requests "}/>

        <div className="actions">
            <Button onClick={()=>APP.central.ScheduledTasks.startQueues()}>Start Job Scheduler</Button>
            <Button onClick={()=>APP.central.Import.pollEmails()}>Get Inbound Messages</Button>
            <Button onClick={()=>dataSet.fetch()}>Refresh Status</Button>        
        </div>
  </div>
}

const TaskRunning = ({name,data,runningFlag,children=null}) => {
    const running = data[runningFlag];

    return <div className={"status-task " + (running ? "is-running" : "is-idle")}>
        <dd>{name}</dd>
        <dt>{running ? "Running" : "Idle"}
        { children }</dt>
    </div>;
}

const TaskState = ({name,data,flag,extra=""}) => {
  const state = data[flag];

  return <div className={"status-task " + (state ? "is-running" : "is-idle")}>
      <dd>{name}</dd>
      <dt>{state ? "OK" : "Error"} {extra}</dt>
  </div>;
}

