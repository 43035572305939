import React, { Component } from 'react';
import mustache from 'mustache';
import TemplatedEditor from './TemplatedEditor.jsx';
import moment from 'moment';

export default function Templated(content, what, callback){
	
	var hash = what && what.props ? what.props.scope : what;
	
	if (hash == undefined){
	    console.log("You have used templates without scope - why are you being stupid???", what);
	    hash = {};
	}
	
	//see https://mustache.github.io/mustache.5.html Lambda section for explanation. TL;DR: {{#bold}}stuff{{/bold}} will call the bold function on stuff
	hash.bold = function() {
        return function(text, render) {
            return "<strong class='defaultBold'>" + render(text) + "</strong>"
        }
    }
	
	hash.eval = function(){
	    return function(text, render) {
            return eval(text);
          }
	}
	
	hash.italic = function() {
        return function(text, render) {
            return "<i class='defaultItalic'>" + render(text) + "</i>"
        }
    }
	
	hash.longDate = function() {
        return function(text, render) {
        	const date = render(text);
        	return new moment(date).format("MMMM D, YYYY");
        }
    }
	
	hash.percent = function() {
        return function(text, render) {
        	const value = render(text);
        	return parseFloat(value).toFixed(2) + '%';
        }
    }
	hash.decimalKilograms = function() {
        return function(text, render) {
        	const value = render(text);
        	
        	const stuff = parseFloat(value).toFixed(2).split('.');
        	if (stuff.length == 2){
        		return parseInt(stuff[0]).toLocaleString() + "." + stuff[1] + " kilograms";
        	}
        	return parseFloat(value).toFixed(2) + " kilograms";
        }
    }

	hash.decimalKG = function() {
        return function(text, render) {
        	const value = render(text);
        	
        	const stuff = parseFloat(value).toFixed(2).split('.');
        	if (stuff.length == 2){
        		return parseInt(stuff[0]).toLocaleString() + "." + stuff[1] + " kg";
        	}
        	return parseFloat(value).toFixed(2) + " kg";
        }
    }
	
	hash.replace = function() {
        return function(text, render) {
            return "<span class='replace'>" + render(text) + "</span>"
        }
    }
	
	
	var ret = null;
	
	if (what && what.props && what.props.mode == "edit" && callback){
		ret = <TemplatedEditor hash={hash} content={content} update={callback} emptyOK={what.props.emptyOK}/>;
	}else{
		try{
			ret = mustache.render(content ? content : " ", hash);
		}catch(e){
			console.log(e);
			ret = "Oh, no - bad template!";
		}
	}
	
	return ret; 
}