import React, { useEffect, useState }  from 'react';
import Util from '@opidcore/Util.js';
import { ResponsivePie } from '@nivo/pie';
import * as OrigPieChart from '@opidcore/components/Content/Types/PieChart.jsx';
import Figure from '@opidcore/components/Content/Types/Figure.jsx';

function round(num, to){
	if (to == undefined){
		to = 2;
	}
	
	return (Math.round( num * 10 ** to ) / 10 ** to); //.toFixed(to); 
}


export default function PieChart(props) {
  let data = [];

  if (typeof props.plot == "object"){
	  data = _.map(props.plot, (value,label)=>{
	      return {id: label, label: label, value: round(value,2) }
	  });
  }
  
  const hideLabels = ['Not Diverted'];
  
  let ourColor = null;
  
  if(props.colourProvider && typeof props.colourProvider === "function"){
      try {
          const coloursCollection = props.colourProvider(_.map(data, "label")); //e.g. [{label: x, colour: y}, {label: a, colour: b}, ...]
          //likely don't need d.data here
          ourColor = (d)=>{ return _.filter(coloursCollection, {label: d.data ? d.data.label : d.label})[0].colour };
          /* e.g.
          function(dataLabels){
              return _.map(dataLabels, (dataLabel)=>{
                  const val = _.findWhere(APP.NDT.datasets['audit-materials-used'].rawData, (r)=>{ r.material_name == dataLabel);
                  if(val != undefined){
                      return {label: dataLabel, colour: val.material_colour};
                  }
                  
                  return {label: undefined, colour: #cc0000};
              });
          }
          */  
      } catch(e) {
          console.log(e);
      }
  } else if(props.colourProvider && typeof props.colourProvider === "string"){
      //props.colourProvider is the cid/uid of the user defined colourSet component
      data =_.map(data, (d)=>{
          d.colour = APP.NDT.colourMapper.findOrGuess(d.label, props.colourProvider);
          return d;
      });
      ourColor = (d)=>{ return d.data.colour; };
  }
  
  if(props.colourProvider == null || (typeof props.colourProvider === "string" && APP.NDT.colourMapper.findOrGuess(null, props.colourProvider) == null)){
      //this is an nivo default colour set
	  if (APP.NDT.find("standard_colour_set") != null){
		  data =_.map(data, (d)=>{
	          d.colour = APP.NDT.colourMapper.findOrGuess(d.label, "standard_colour_set");
	          return d;
	      });
	      ourColor = (d)=>{ return d.data.colour; };
	      
	  }else{
		  ourColor = {scheme: "category10"};
	  }
  }   

  let labeller = (d)=>d.label + " (" + d.value +")"
 
  if (props.showLabelsAs == "percent"){
	  const totalValue = props.totalValue ? props.totalValue : _.sum(_.map(data, 'value'));
	  
	  labeller =  (d)=>{return d.label + " (" + Math.round(100 * d.value/totalValue) +"%)"};
  }
  
  if (props.showLabelsAs == "percent_2decimals"){
	  const totalValue = props.totalValue ? props.totalValue : _.sum(_.map(data, 'value'));
	  
	  labeller =  (d)=>{return d.label + " (" + Math.round(10000 * d.value/totalValue)/100 +"%)"};
  }

  if (props.showLabelsAs == "percent_2decimals_only"){
	const totalValue = props.totalValue ? props.totalValue : _.sum(_.map(data, 'value'));
	
	labeller =  (d)=>{return Math.round(10000 * d.value/totalValue)/100 +"%"};
}
  
  if (props.showLabelsAs == "percent_only"){
	  const totalValue = props.totalValue ? props.totalValue : _.sum(_.map(data, 'value'));
	  
	  labeller =  (d)=>{
		  
		  if (hideLabels.indexOf(d.label) >= 0){
			  return null;
		  }
		  
		  let rounded = Math.round(100 * d.value/totalValue);
		  if (rounded == 100 && d.value != totalValue){
			  rounded = 99;
		  }
		  return rounded+"%"
	  };
  }
  
  const lineColor = (d)=>{  
	  if (hideLabels.indexOf(d.label) >= 0){
		  return 'rgba(0,0,0,0)';
	  }
	  
	  return '#000000';
  }
  
  const margin = { top: 40, right: 60, bottom: 40, left: 60 };
  
  if (props.margins != undefined && props.margins.length > 0){
	  var chunks = props.margins.split(",");
	  if (chunks.length == 1){
		  margin.top = parseInt(chunks[0]);
		  margin.right = parseInt(chunks[0]);
		  margin.bottom = parseInt(chunks[0]);
		  margin.left = parseInt(chunks[0]);
	  }else{
		  margin.top = parseInt(chunks[0]);
		  margin.right = parseInt(chunks[1]);
		  margin.bottom = parseInt(chunks[2]);
		  margin.left = parseInt(chunks[3]);
	  }
  }
  
  let diagonalLength = 16;
  let horizontalLength = 24;
  
  if (props.labelLineLength){
	  diagonalLength = props.labelLineLength;
	  horizontalLength = props.labelLineLength / 2 * 3;
  }

  //remove zeros
  data = data.filter( (v,k)=>{ return v.value > 0});

  const graph = <ResponsivePie data={data}
      colors={ourColor}
   	  margin={margin}
	  enableArcLabels={false}
  	  enableRadialLabels={true}
	  arcLinkLabelsSkipAngle={4.05}
	  radialLabelsTextXOffset={6}
	  radialLabelsTextColor="#333333"
	  arcLinkLabelsOffset={props.arcLinkLabelsOffset ? props.arcLinkLabelsOffset : 0}
	  arcLinkLabelsDiagonalLength={diagonalLength}
	  arcLinkLabelsStraightLength={horizontalLength}
	  radialLabelsLinkStrokeWidth={1}
  	  radialLabelsLinkColor={lineColor}
	  arcLinkLabel={labeller}
  	  innerRadius={0.025}
  	  padAngle={2}
  	  startAngle={props.startAngle != null ? parseInt(props.startAngle) : -95}
      enableSlicesLabels={true}
  	  sliceLabel={null}
  	  animate={false}
	  />
	
  return (
    <div className="graph-outer">
        <Figure title={props.graphTitle} {...props}>
        	<div className="graph" style={{height: props.height, width: props.width}}>
        		{graph}
        	</div>
		</Figure>   	
	</div>
  );
}

PieChart.metaInfo = ({})=>{
		return {renderAsPng: true, icon: "chart-bar", preview: (me)=>{ return "?" }, showInEditorQuickAdd: true };
  };
  
  PieChart.getEditableProps = ({})=>{
		var  editable = OrigPieChart.default.getEditableProps();
		editable.push( {niceName: "graph title", prop: "graphTitle", type: "text" } );
		editable.push( {niceName: "Label Line Length", prop: "labelLineLength", type: "text" } );
		editable.push( {niceName: "Start Angle", prop: "startAngle", type: "text" } );
		editable.push( {niceName: "Total Value", prop: "totalValue", type: "text", allowJS: true } );
		editable.push( {niceName: "Arc Offset", prop: "arcLinkLabelsOffset", type: "text", allowJS: true } );

		
		return editable;
};

PieChart.injextNext = (props)=>{
    if(props.hideTitle == undefined || !props.hideTitle){
        if(props.graphTitle){
            props.title = props.graphTitle;
        }
    }
    return Figure.staticInjectNext(props);
}