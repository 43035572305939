
import { Component } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FilterContext } from './FilterSet.jsx';


export default class DataFilter extends Component{
    static contextType = FilterContext;
    constructor(props,context) {
        super(props,context);
        
        this.state = {
                selectDate: this.props.defaultValue || moment().format("YYYY-MM-DD"),
                selectOption:'all',
        };
        
        if (this.context.applyDefault){
        	var rawData = this.context.getData();
        	const val = true ? ["gt", this.state.selectDate] : this.state.selectDate;
            rawData.filter({"date": val},true);
            rawData.fetch();
        }
    }

    static getEditableProps (){
        return [
            {niceName: "Type", prop: "type", type:"text", options: ["","date","select","text"] },    
            {niceName: "Field Name", prop: "fieldName", type: "text", default: ""},    
            {niceName: "Variable Name", prop: "variableName", type: "text", default: ""},
            {niceName: "Filter Options", prop: "filterOptions", type: "text", default: "[]"},   // read these from the dataset?
            {niceName: "Css Class", prop: "cssClass", type:"text", default:"", editInPlace: true},
            {niceName: "Default Value", prop: "defaultValue", type:"text", allowJS: true},

        ]
    }
    
    selectDate(e){
        var rawData = this.context.getData();
        let selectedDate = moment(e).format("YYYY-MM-DD")//.startOf('week');
        this.setState({selectDate: selectedDate})
        rawData.unfilter(this.props.variableName);
        const val = true ? ["gt", selectedDate] : selectedDate;
        rawData.filter({"date": val},true);
        this.context.refresh();
    }
    
    selectOption(e){
        var that = this;
        var rawData = this.context.getData();
        this.setState({selectOption:e.target.value});
        rawData.unfilter(this.props.variableName);
        rawData.filter((r)=>{return e.target.value == 'all' ? true : (r[this.props.variableName] ? (r[this.props.variableName] == e.target.value) : false)}, true, true, this.props.variableName);
        this.context.refresh();
    }
    
    handleTextInput(e){
        this.setState({textFilterInput : e.target.value});
    }
    
    filterByText(){
        var rawData = this.context.getData();
        rawData.unfilter(this.props.variableName);
        
        if(this.state.textFilterInput && this.state.textFilterInput != ""){
            rawData.filter({[this.props.variableName]: this.state.textFilterInput.toLowerCase()},true);
            this.context.refresh();
        }
        
        rawData.fetch();
    }
    
    render(){

        var that = this;
        var datePicker = null;
        var filterSelect = null;
        var textFilter = null;
        var rawData = this.context.getData();
        var filter_options = [];
        if(rawData && this.props.type == "date"){
            datePicker =                       
                <div>
                    <label htmlFor="selectDate">Select Date:  </label>
                    <DatePicker selected={moment(this.state.selectDate)} onChange={(e)=>this.selectDate(e)} />
                </div>;
//            rawData = _.filter(rawData,(d)=>{
//                if(d.date){
//                    var endDate = moment(that.state.selectDate).add(7, 'd').format('YYYY-MM-DD');
//                    var startDate = moment(that.state.selectDate).subtract(7, 'd').format('YYYY-MM-DD');
//
//                    return moment(d.date).isBetween(startDate,endDate );
//                }
//            })


        }
        
        if(rawData && this.props.type == "select"){
            filter_options.push(<option value="all">All</option>);
            _.each(this.props.filterOptions.split(","),function(f){ filter_options.push( <option value={f.replace(/[" '\[\]]/gi,"") }>{f.replace(/[" '\[\]]/gi,"") }</option>)});
            filterSelect = 
                <div>
                    <label >{"Select " + this.props.fieldName + ":  "}</label>
                        <select onChange={(e)=>this.selectOption(e)} >
                            {filter_options}
                        </select>
                </div>;
        }

        if(rawData && this.props.type == "text"){
            textFilter = 
                <div>
                    <label >{"Find " + this.props.fieldName + ":  "}</label>
                    <input onChange={(e)=>this.handleTextInput(e)} value={this.state.textFilterInput || ""}></input>
                    	<input type="button" value="Search" onClick={()=>this.filterByText()}></input>
                </div>;
        }
        return (
                <div className="container">

                  <div>
                     {filterSelect}
                     {datePicker}
                     {textFilter}
                     <br></br>
                  </div>
                </div>
                )
                

    }
}