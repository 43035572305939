import React from 'react';
import { AccessControl } from '@opidcore/components';
import { MenuItem, SubMenu } from '@opidcore/components/Menu.jsx';
import AutoPay from '../Contract/AutoPay';
import CostAvoidance from '../Contract/CostAvoidance';
import PrinceIncreaseOverview from '../Contract/PriceIncreaseOverview';
import ExcelBulkImporter from '../ExcelBulkImporter';
export default function initialize(permissions, options) {

  if (permissions.w) {

    window.APP.customRoutes.push({ path: "/ui/priceincrease/overview", component: PrinceIncreaseOverview, key: "price_overview" });
    window.APP.customRoutes.push({ path: "/ui/autopay", component: AutoPay, key: "auto_pay" });
    window.APP.customRoutes.push({ path: "/ui/costavoidance", component: CostAvoidance, key: "cost_avoidance" });
    window.APP.customRoutes.push({ path: "/ui/bulkimporter", component: ExcelBulkImporter, key: "bulk_importer" });


    APP.addMenu(() => {
      const items = [];

      items.push(<SubMenu key="tools">
        <MenuItem key="tools" menuKey="tools" linkTo="#" icon="wrench" text="Tools" />
        <MenuItem key="price_overview" menuKey="price_overview_menu_key" className="nav-sub-menu" linkTo="/ui/priceincrease/overview" icon="chart-line" text="Price Increases" />
        <MenuItem key="auto_pay" menuKey="auto_pay_menu_key" className="nav-sub-menu" linkTo="/ui/autopay" icon="repeat" text="Subscriptions" />
        <MenuItem key="cost_avoidance" menuKey="cost_avoidance_menu_key" className="nav-sub-menu" linkTo="/ui/costavoidance" icon="acorn" text="Cost Avoidance" />
        <AccessControl group="Sysadmin">
          <MenuItem key="bulk_importer" menuKey="bulk_importer_menu_key" className="nav-sub-menu" linkTo="/ui/bulkimporter" icon="fill" text="Bulk Excel Importer" />
        </AccessControl>
      </SubMenu>);

      return items;
    });

  }
}