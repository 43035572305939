//import './AddFriendInput.scss';
import React, { Component, PropTypes } from 'react';

export default class QuickFlip extends Component {
	
  static propTypes = {
  };

  constructor(props, context) {
	super(props, context);

	this.state = {editing: false};
  }
  
  handleClickEdit(){
	  if(!this.state.editing){
		  this.setState({editing: true});
	  }
  }
  
  render() {
    const TagName = this.props.tagName || "div";
    
    var amIEditing = this.state.editing && this.props.children.length ? this.props.children[1] : this.props.children[0] || this.props.children; 
    
    const innerComponent = React.cloneElement(amIEditing, {editing: this.state.editing});

    return <div className={"cardContent " + this.props.className} onClick={()=>this.handleClickEdit()}>
    	<div className="cardContent-inner">{innerComponent}</div>
    </div>;
  }
}
