
import React, { useLayoutEffect, useRef, useState }  from 'react';
import _ from 'lodash';

const HoverElement = ({title = null, style = null, children = null, content = "Click Me" || <div>Click Me</div>, modalUrl = undefined, modalOptions = undefined, hoverTime = 500}) =>{
	const [active, setActive] = useState(false);
	const [hoverStyle, setHoverStyle] = useState({});
	const hoverRef = useRef(null);
	const mouseIn = useRef({label: 0, hoverContent: 0});
	const [timeoutTimer, setTimeoutTimer] = useState(hoverTime);


	useLayoutEffect( ()=>{
		const abc = hoverRef;
		const offset = {};

		const hoverDimensions = hoverRef.current.getBoundingClientRect();
		if (hoverDimensions.right > document.documentElement.clientWidth){
			offset.left = (document.documentElement.clientWidth - hoverDimensions.right - 20) + "px";
		}

		if (_.size(offset) > 0 && _.isEqual( offset, hoverStyle) == false){
			setHoverStyle(offset);
		}
	});

    const doNav = ()=>{
        if(modalUrl != undefined){
            APP.instance.createModal(modalUrl, modalOptions);
        }
    }

	const leaveSoon = (which)=>{
		mouseIn.current[which] = 0; 

		//actually close out if our mouse isn't in either element
		setTimeout(()=>{
			if (_.sum(_.values( mouseIn.current )) ==  0){
				setActive(false)
			}
		}, timeoutTimer);
	}

	const doMouseEnter = () => {
		if(children == null){
			return;
		}
		
		mouseIn.current.label = 1; 
		setActive(true);
	}

	return <div title={title} className="hover-element" onMouseLeave={()=>leaveSoon('label')}>
		<div className={"hover-content " + (active == true? "active-content" : "inactive-content")} ref={hoverRef} style={{...hoverStyle, ...style}} onMouseEnter={()=>mouseIn.current.hoverContent = 1} onMouseLeave={()=>leaveSoon('hoverContent')}>
			{children}
		</div>
		<div className="hover-text" onClick={()=>doNav()} onMouseEnter={()=>doMouseEnter()}>{content}</div>
	</div>
}

export default HoverElement;