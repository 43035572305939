import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BoundComponent from './BoundComponent';
import Bound from './Bound';

import * as _ from 'lodash';

export default class InputMultiSearch extends BoundComponent {
	
	static propTypes = {
	    update: PropTypes.func,
	    property: PropTypes.string,

	};

	constructor(props, context) {
		super(props, context);

		let selectedOptions = [];

		if( this.props.obj.state[this.props.field] != null ){
			selectedOptions = this.props.obj.state[this.props.field];
		}
		
	    this.state = {
	    		remainingOptions: props.results,
	    		selectedOptions: selectedOptions,
	    		value: "",
	    		searching: false
	    };
	}
	
	componentWillReceiveProps(nextProps){
		let nextState = {... this.state};
		
		if( nextProps.obj.state[nextProps.field] != null ){
			nextState.selectedOptions = nextProps.obj.state[nextProps.field];
		}
		
		nextState.remainingOptions = _.filter(nextProps.results, (option) => {
			let found = true;
			
			for(var i=0; i<nextState.selectedOptions.length; i++){
				if(nextState.selectedOptions[i] == option){
					found = false;
					break;
				}
			}
			
			return found;
		});
		
		this.setState(nextState);
	}
	
	labelFormatter(row){
        return row[1];
    }
	
	keyFormatter(row){
        return row;
    }
	
	handleChange(e){   
		if(e.target.value != undefined && e.target.value.trim().length <= 0){
    		this.setState({value: "", searching: false});
    	} else {
    		this.setState({value: e.target.value, searching: true});
    		
    		if (this.searchDebounced == null){
  	          this.searchDebounced = _.debounce( this.search, 250 );
  	      	}
  	      
  	      	this.searchDebounced(e.target.value);
    	}
	}
	
	addResult(result){

		if(this.props.handleSelect != null){
			this.setState({value: "", searching: false}, ()=>{
					this.props.handleSelect(result);
			});
			
			return;
		}
	
		if(result != undefined){
			let newState = {...this.state};
			
			newState.selectedOptions.push(result); //add to selected
			newState.remainingOptions.splice(newState.remainingOptions.indexOf(result), 1); //remove from remaining
			newState.searching = false;
			newState.value = "";
			if (this.props.update != null){
	    		this.setState(newState, ()=>{
	    			this.props.update(this.props.obj, this.props.field, newState.selectedOptions);	
	    		});
	    	}else{
	    		alert("I didn't know how to handle this change");
	    	}
		}
	}
	
	removeResult(result){
		if(result != undefined && (this.props.disabled == undefined || this.props.disabled == false)){
			let newState = {...this.state};
			const resultIndex = newState.selectedOptions.indexOf(result);
			
			newState.selectedOptions.splice(resultIndex, 1); //remove from selected
			newState.remainingOptions.push(result); //add to remaining
			
			if (this.props.update != null){
	    		this.setState(newState, ()=>{
	    			this.props.update(this.props.obj, this.props.field, newState.selectedOptions);	
	    		});
	    	}else{
	    		alert("I didn't know how to handle this change");
	    	}
		}
	}
	
	closeSearch(){
		this.setState({value: "", searching: false});
	}
	
	search(term){
		alert('You should probably make a search function');
	}
	  
	render(){
		let searchOptions = _.map(this.state.remainingOptions, (result) => {
			return <li key={this.keyFormatter(result)} onClick={()=>this.addResult(result)} >{this.labelFormatter(result)}</li>;
		});

		let selected = _.map(this.state.selectedOptions, (option) => {
			return <span key={this.keyFormatter(option)} onClick={()=>this.removeResult(option)} className={(this.props.disabled? "disabled" : "active")}>{this.labelFormatter(option)}</span>;
		});
		
		let searchBox = "";
		
		let label = (<label>{this.props.name}</label>);
		
		if(this.props.hideLabel){		
			 selected = null;
		}
	
		if(this.state.searching){
			searchBox = <ul>{searchOptions}</ul>;
		}
		
		return (<div className={"multisearch_body field " +  (this.props.className || "col-xs-12 col-md-12") }>
			{label}
			
			<div className="selected">
				{selected}
			</div>
			
			<div className="searchBox">
				<div className="searchWrapper">
					<input
				        type="text"
				        className={"form-control" || this.props.className}
				        placeholder={this.props.placeHolder}
				        value={this.state.value}
						disabled={this.props.disabled}
				        onChange={(e)=>this.handleChange(e)} />
					
					{ this.props.disabled? "" : <div className="glyphicon glyphicon-remove" onClick={()=>this.closeSearch()} /> }
				</div>
				
				<div className="searchResults">
					{searchBox}
				</div>
			</div>
          </div>);
		
	}
}
