import React, { useState, useEffect, Fragment } from "react";
import { useData } from "@opidcore/hooks/WTF";
import { Icon, Button, Tabular, TabularColumn, TabularDataSet, Searchable, Pagination } from "@opidcore/components";
import _ from "lodash";
import PropTypes from "prop-types";

/*
This component is a multi-select for things. It will show a list of things that can be selected, and a list of things that have been selected.

ex:
  <MultiThingSelect
    thing="lookup:contactRole"                // same as a normal useData thing
    setter={setRoles}                         // optional, will call setter(thingList) when thingList changes
    existing={originalRoles}                  // optional, will pre-populate the list with these things
    addButtonLabel="Show Assignable Roles"    // optional, will change the label on the button that shows the list
    columns={["label"]}                       // optional, will change the columns in the list, defaults to customerName + name        
    showSearch={false}                        // optional, will hide the search bar, defaults to true
  />
*/

export default function MultiThingSelect(props) {
  const isLookup = props.thing ? props.thing.indexOf("lookup") > -1 : false;
  const theThing = isLookup ? props.thing.split(":")[1] : props.thing;
  const [data, dataSet] = isLookup ? useData("lookup;" + theThing, APP.central.Lookup.fetchForName, { name: theThing }) : useData(theThing, { paginate: 10 });

  const [thingList, setThingList] = useState(props.existing ? props.existing : []);
  const [showList, setShowList] = useState(_.has(props, "defaultShowList") ? props.defaultShowList : false);

  const addButtonLabel = props.addButtonLabel || "Show Site List";
  const showSearch = _.has(props, "showSearch") ? props.showSearch : true;
  const hideHeadings = _.has(props, "hideHeadings") ? props.hideHeadings : true;

  const columns = props.columns ? props.columns : null;
  const [tabularColumns, setTabularColumns] = useState(
    columns ? (
      _.map(columns, (col, i) => {
        return <TabularColumn data={(row) => row[col]} key={i} />;
      })
    ) : (
      <TabularColumn data={(row) => (row.customerName || row.customer_name) + ": " + row.name} />
    )
  );

  useEffect(() => {
    if (props.setter) {
      props.setter(thingList);
    }
  }, [thingList]);

  const addToList = (thing) => {
    const updateArray = [...thingList, thing];
    setThingList(
      _.uniqBy(updateArray, (thing) => {
        return thing.id;
      })
    );
  };

  const removeFromList = (row) => {
    setThingList(
      _.filter(thingList, (thing) => {
        return thing.id != row.id;
      })
    );
  };

  const ExistingThingsDisplay = () => {
    return (
      <Tabular data={thingList} hideHeadings={hideHeadings} tableCaption={"Assigned"}>
        {tabularColumns}
        <TabularColumn data={(row) => <Icon onClick={() => removeFromList(row)} icon="minus" />} />
      </Tabular>
    );
  };

  const AddableThingsDisplay = () => {
    return (
      <Fragment>
        <TabularDataSet dataSet={dataSet} hideHeadings={hideHeadings} tableCaption={"All"}>
          {tabularColumns}
          <TabularColumn data={(row) => <Icon onClick={() => addToList(row)} icon="plus" />} />
        </TabularDataSet>
        <Icon icon="chevron-left" size="2x" onClick={() => setShowList(false)} />
      </Fragment>
    );
  };

  return (
    <div className="multiThingSelect">
      <div className="searchAndPage">
        <div />
        <div>
          {showList && showSearch ? (
            <div className="search">
              <Searchable ds={dataSet} />
            </div>
          ) : null}
          {showList ? (
            <div className="page">
              <Pagination dataSet={dataSet} />
            </div>
          ) : null}
        </div>
      </div>

      <div>
        <ExistingThingsDisplay />
      </div>

      <div className="button">
        {showList ? (
          <Fragment>
            <AddableThingsDisplay />
            {props.children}
          </Fragment>
        ) : (
          <Button onClick={() => setShowList(true)}>{addButtonLabel}</Button>
        )}
      </div>
    </div>
  );
}

MultiThingSelect.propTypes = {
  thing: PropTypes.string, // the lookup string for the thing you want to select
  existing: PropTypes.node, // array of already existing objects?
  setter: PropTypes.func, // useState setter
  children: PropTypes.node, // any extra children to render
  addButtonLabel: PropTypes.string, // the label for the button that shows the list
  columns: PropTypes.array, // array of column names to display
  showSearch: PropTypes.bool, // show the search bar
  defaultShowList: PropTypes.bool, // show the list by default
};
