import DetailSite from './DetailSite.jsx';
import EditSite from './EditSite.jsx';
import ListSites from './ListSites.jsx';

export default function initialize(permissions, options){
	window.APP.customRoutes.push({path: "/ui/sites/list", component: ListSites, key: "site_list"});	

	if(permissions.w || true){
		window.APP.customRoutes.push({path: "/ui/sites/edit/:id", component: EditSite, key: "site_edit"});
	}

  window.APP.customRoutes.push({ path: "/ui/sites/detail/:id", component: DetailSite, key: "site_detail" });
}