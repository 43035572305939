import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class Button extends Component{
    constructor(props) {
        super(props);
        
        this.state = {};
    }

	static getEditableProps(){
		return [
			{niceName: "Content", prop: "content", type:"text", default:"", editInPlace: true},
			{niceName: "Link To", prop: "linkTo", type:"text", default:"", editInPlace: true, allowJS: true},
			{niceName: "Css Class", prop: "cssClass", type:"text", default:"", editInPlace: true},
			{niceName: "Icon", prop: "icon", type:"text", default:"", editInPlace: true}
		]
	}
    
    handleClick(){  
        if (this.props.onClick){
    		window.APP.executeFunction( this.props.onClick, this.props, this.props.history );
    	} 
    }
    
    
    render(){
        
        let icon = null;
        if(this.props.icon){
            icon = <FontAwesomeIcon icon={this.props.icon} />;
        }

        var linkTo = this.props.linkTo;
        if (this.props.navToCid) {
    		linkTo = "/" + this.props.viewtype + "/" + this.props.cid;
    	}
        return (
    	        <Link to={linkTo} onClick={()=>this.handleClick()} className={this.props.cssClass || "button defaultButton"} >
    	        {this.props.children || this.props.content}
    	        {icon}
    	        </Link>
    	        )
    	        

    }
}
export default withRouter(Button);