import React, { Component } from 'react';
import templated from './Templated.jsx';
import RenderMeSoftly from '../RenderMeSoftly.jsx';
import RenderMeSoftlyEditor from '../RenderMeSoftlyEditor.jsx';

export default class Embedded extends Component{
    constructor(props) {
        super(props);
        
        this.state = {};
        this.state.structure = props.strucure;
        const templatedVal = props.embeddedId? templated(props.embeddedId, this) : null
        this.state.embeddedId = templatedVal && templatedVal.trim().length > 0? templatedVal : null;
    }
    
    static getEditableProps(){      
        return [
            {niceName: "Embedded CID", prop: "embeddedId", type: "text", allowJS: true},
        ]
        
    }
    
    componentDidMount(){
        if(this.state.embeddedId && this.state.embeddedId.trim().length > 0){
            this.fetchEmbedded(this.state.embeddedId);
        }
    }
    
    static metaInfo(){
  		return {canHaveChildren: true}
  	}
    
    fetchEmbedded(id){  
        
    	this.props.central.Stuff.justWhatINeed(id).then((d) => {
            let newStructure = _.map(this.props.NDT.structure, (s)=>{    
                if(s.theId == this.props.root){
                    if(d.result.structure != undefined && d.result.structure.length > 0){
                        if(s.__fake_children == undefined){
                            s.__fake_children = [];
                        }
                        if(s.children == undefined){
                            s.children = [];
                        }
                        
                        const firstRes = d.result.structure[0];
                        if(s.children.indexOf(firstRes.theId) < 0){
                            s.children.push(firstRes.theId);
                        }
                        if(s.__fake_children.indexOf(firstRes.theId) < 0){
                            s.__fake_children.push(firstRes.theId);
                        }
                    }
                }
                return s;
            });
            
            //updateContentStructure will append for us
            this.props.NDT.getEditor().updateContentStructure(d.result.structure);
            
            this.setState({structure: d.result.structure});
        });
    }
    
    render(){    
        if(this.state.embeddedId != null){
            let rmsProps = {
                central: "blah",
                key: this.props.root + "-embedded" + this.props._loopIndex,
                root: this.state.embeddedId,
                structure: this.state.structure,
                mode: this.props.mode || "view",
                testing: "apple"
            };
            
            rmsProps = {...this.props, ...rmsProps};
            return <div>
                <RenderMeSoftly {...rmsProps} />
            </div>;
        }
        
        return null;
    }
}