import React, { Component } from 'react';
//import ReactDOM from 'react-dom';

//import * as CentralActions from '../../OpidCore/public/js/actions/CentralActions';

import { InputText, Bound } from '../../OpidCore/public/js/components';
import { Tabular, TabularColumn } from '@opidcore/components/Tabular';

import * as _ from 'lodash';
//import moment from 'moment';

import Modal from './components/Modal.js';

import './DataEditor.scss';

import Editor from '../../OpidCore/public/js/components/Content/Editor.jsx';

function simpleTable(props){ 
	if (props.rows && props.rows.length > 0){
		var cols = _.map( _.keys(props.rows[0]), (k)=>{
			return <TabularColumn title={k} data={(row)=>row[k]}/>
		});
		
		return <Tabular data={props.rows}>
			{cols}
		</Tabular>;
	}
}

class PumpkinEditor extends Component {
	constructor(props, ctx){
		super(props, ctx);
		var state = {...props.def};
		state.options = JSON.stringify(props.def.options);
		this.state = state;
		
		this.options = {...props.def.options};
	}
	
	save(e,savedOptions){
    	if (this.central == null){
    		this.central = window.APP.central;
    	}
    	
    	var thing = {...this.props.def};
    	if (savedOptions){
    		thing.options = savedOptions; 
		}else{
			thing.options = this.options;
		}
    	
    	this.central.ContentStuff.editComponent(thing).then(()=>{
    		this.props.onSave && this.props.onSave();
    		theGlobalEditor.load();
    	});
    }
   	
	render(){
		
		console.log("we have a ", this.state)
		
		var structToEdit = this.props.def;
		var defaultOptions = [];
		
		_.each(structToEdit.connections, (con)=>{
			defaultOptions.push( {niceName: con.name + "(" + con.direction + ")", prop: con.name, type: "text", default: ""} );
		});
		
		if (structToEdit.component == "Join"){
			defaultOptions.push( {niceName: "Join Condition", prop: "java", type: "text", multiLine: true, default: ""} );
		}
		
		if (structToEdit.component == "Collect"){
			defaultOptions.push( {niceName: "Collect Expression", prop: "java", type: "text", multiLine: true, default: ""} );
		}
		
		if (structToEdit.cid == "query2"){
			//defaultOptions.push( {niceName: "Collect Expression", prop: "fil", type: "text", multiLine: true, default: ""} );
		}
		
		//{"in1":"query2","in2":"query1","out":"my_result","java":"  return  arg0.get(\"id\").toString().equals(  arg1.get(\"material_type\").toString() );   ","join":"left"}
		
			//{niceName: "Form", prop: "form", type: "text", options: _.map(window.APP.forms, function(datum) {return datum.uid})},
			//{niceName: "Query", prop: "query", type: "text", default: ""},
			//{niceName: "Where", prop: "where", type: "text", default: ""},
			//{niceName: "Section", prop: "section", type: "text", options: sections}
		
		var view = <Editor struct={structToEdit} options={defaultOptions} bindTo={this.options} save={(a,b)=>this.save(a,b)}/>;

		if (true){
			return view;
		}
		
		/**
		var structToEdit = myNDT.find(uid); //_.find(myNDT.structure, {"cid": cid});

    		var defaultOptions = null;
    		if (NDT.lookupComponent(NDT.find(this.state.editingUID).component).getEditableProps) {
    			defaultOptions = NDT.lookupComponent(NDT.find(this.state.editingUID).component).getEditableProps();
    			defaultOptions = this.messWithDefaultOptions( defaultOptions, structToEdit )
    		}
    		
    		this.tempOptions = this.state.optionsValue ? JSON.parse(this.state.optionsValue) : {};
    		
    		
    		
    	**/	
		return <Bound to={this}>
			<InputText inputType="textarea" field="options" name="What"/>
				<button onClick={()=>this.save()}>Save</button>
		</Bound>
		
	}
}

class Pumpkin extends Component {
	constructor(props, ctx){
		super(props, ctx);
		this.state = {};
	}
	
	load(){
		
		///api/v1/Playground/flowerBouqet?cid=dv-test1
				
		APP.central.Playground.flowerBouqet({cid: this.props.def.cid}).then(r=>{
			this.setState({rows: r.result.rows});
		});	
	}
	
	edit(){
		this.modal = new Modal();
		var editor = <PumpkinEditor def={this.props.def} onSave={()=>this.modal.closeAction()}/>
		this.modal.openModal( editor );
	}
	
	render(){
		var data = null;
		
		if (this.state.rows && this.state.rows.length > 0){
			data = simpleTable({rows: this.state.rows});	
		}
		
		return <>
			{data}
			<button onClick={()=>this.load()}>view</button>
			<button onClick={()=>this.edit()}>edit</button>
			<div className='code'>{JSON.stringify(this.props.def,null,2)}</div>
			</>
		
	}
	
}

class Pickle extends Component {
	constructor(props, ctx){
		super(props, ctx);
		this.state = {position: {...props.position}};
	}
	
	startDrag(e){
		 this.setState({'dragging': true, draggingId: -1, startX: this.state.position.left, startY: this.state.position.top, startClientX: e.clientX, startClientY: e.clientY});
	}
	
	handleDrag(e){
		if (this.state.startX){
			console.log("X: " + (e.clientX + this.state.startX) );
		}
		this.updatePos(e);
		
	}
	
	handleDragEnd(e){
		this.updatePos(e, (a)=>{
			this.props.onMove( this.state.position ); 	
		});
		
	}
	
	updatePos(e, cb){
		var position = {...this.state.position};
		position.left = this.state.startX + (e.clientX - this.state.startClientX);
		position.top = this.state.startY + (e.clientY - this.state.startClientY);
		this.setState({position: position}, cb != null ? ()=>cb(position) : null);
	}

	static getDerivedStateFromProps(props, state){
		if (state.dragging != true && state.position != props.position){
			return {position: props.position};
		}
		
		return null;		
	}
	
	render(){
		var style = {};
		style.position = "absolute";
		style.top = this.state.position.top;
		style.left = this.state.position.left;
		style.width = this.state.position.width;
		style.height = this.state.position.height;
		
		var connectors = {left: [], right: []};
		if (this.props.connectors){
			_.each(this.props.connectors, (cDef)=>{
				var connector = <div key={cDef.name} className={"connector connector-" + cDef.direction + " connector-" + cDef.type + " " + "connector-named-" + cDef.name }><span>{cDef.name}</span></div>;
				
				if (cDef.direction == "in"){
					connectors.left.push(connector);
				}else{
					connectors.right.push(connector);
				}
			});
		}
		
		return <div className="pickle-outer" id={this.props.id} draggable={true} style={style} onDrag={(e)=>this.handleDrag(e)} onDragEnd={(e)=>this.handleDragEnd(e)} onDragStart={(e)=>this.startDrag(e)}>
		<div key="left" className="left-connectors">{connectors.left}</div>
		<div key="right" className="right-connectors">{connectors.right}</div>
		{this.props.children}
		</div>;
		
	}
}

export default class ThrowAway extends Component {
    constructor(props, context) {
        super(props, context);
        this.central = window.APP.central;
        this.state = {
        		id: this.props.match.params.cid,
        		contentStructure: [{"cid":"test1","type":"ReactThing","component":"Header","options":{"content": "Loading"}}],
        		positions: {}	
        };
        setTimeout(()=>this.load(), 500);
        
        window.theGlobalEditor = this;
    }
    
    load(){
    	var s = document.createElement("script");
    	s.type = "text/javascript";
    	s.src = "https://cdn.jsdelivr.net/npm/leader-line@1.0.5/leader-line.min.js";
    	window.document.head.append(s);    		
    	
    	
    	fetch("/api/v1/Stuff/everythingIWant?cid=" + this.state.id ).then((r)=>r.json()).then((d)=>{
    		this.setState({dataView: d.result.structure});
    		
    		_.each(d.result.structure, (v)=>{
    			if (v.cid == this.state.id){
    					this.rootQ = v;
    					this.setState({positions: this.rootQ.options.positions}, ()=>this.getStuffGoing());
    					window.rootQ = this.rootQ;
    				}
    		});
    				
    	});
    	
    	
    }
    
    getStuffGoing(){
    	var allConnectors = [];
    	var otherConnectors = {};
    	
    	_.each(this.state.dataView, (v)=>{
			if (v.cid != this.state.id){
				var connectors = v.options.connectors || v.connections;
				
				if (v.type == "DataSet"){
					var connectorId = (v.cid || v.uid) + ".out";
					otherConnectors[connectorId] = v.uid + ":out";
				}
				
				_.each(connectors, (connector)=>{
  				 	 var connectorId = (v.cid || v.uid) + "." + connector.name;
  				 	 otherConnectors[connectorId] =  v.uid + ":" + connector.name; //v.uid + ":" + v.name;
					 
				});
				
			}
		});
		
    		_.each(this.state.dataView, (v)=>{
    			if (v.cid != this.state.id){
    				var connectors = v.options.connectors || v.connections;
    				
    				if (v.type == "DataSet"){
    					var connectorId = (v.cid || v.uid) + ".out";
    					
    					//connectors.push({"name": connectorId,"type":"FlowDataSet","direction":"out"});
    					otherConnectors[connectorId] = v.uid + ":out";
    				}
    				
    				_.each(connectors, (connector)=>{
    					if ( v.options[connector.name] ){
    						
    						var connectorId = (v.cid || v.uid) + "." + connector.name;
    						var uidThing = (v.uid + ":" + connector.name)
    						var otherCid = v.options[connector.name];
    						connector["_connects_to"] = otherCid;
    						
    						if (connector.direction == "in"){
    							allConnectors.push({to: uidThing, from: otherConnectors[otherCid]});	
    						}else if (connector.direction == "out"){
    	    					otherConnectors[v.name] = connectorId; //v.uid + ":" + v.name;

    							console.log("we aren't sure");
    						}
    						
    					}
    				});
    			
    			}
    		});
    		
    		this.setState({leaderLines: allConnectors});
    }
    
    updatePosition(uid, newPos){
    	var pos = {...this.state.positions};
    	pos[uid] = newPos;
    	if (this.rootQ.options == null){
    		this.rootQ.options = {};
    	}
    	this.rootQ.options.positions = pos;
    	this.setState({positions: pos});
    }
    
    save(){
    	if (this.central == null){
    		this.central = window.APP.central;
    	}
    	
    	this.central.ContentStuff.editComponent(this.rootQ);
    }
    
    add(){
    	if (this.central == null){
    		this.central = window.APP.central;
    	}
    	
    	this.central.ContentStuff.addToComponent(this.rootQ);
    }
    
    componentDidUpdate(prevProps) {
    	if(this.leaderLines != null){
    		_.each(this.leaderLines, (line)=>{
    			line.remove();
    		});
    	}
    	
    	this.leaderLines = [];
    	
    	_.each(this.state.leaderLines, (line)=>{
    		
    		//a.getElementsByClassName("connector-named-query1")[0]
    		var fromEl = this.findEl(line.from);
    		var toEl = this.findEl(line.to);
    		if (fromEl && toEl){
	    		this.leaderLines.push( new LeaderLine(
	    					fromEl,
	    				  toEl
	    				) );
    		}
    	});
    }
    
    findEl(joinedThing){
    	try{
	    	var chunks = joinedThing.split(":");
	    	var outer = document.getElementById(chunks[0]);
	    	var connectors = outer.getElementsByClassName("connector-named-" + chunks[1]);
	    	
	    	if (connectors.length > 0){
	    		return connectors[0];
	    	}
    	}catch(e){ }
    }

    loadAll(){
		APP.central.Playground.flowerBouqet({cid: this.state.id}).then(r=>{
			this.setState({rows: r.result.rows});
		});	
    }
    render(){      
    	var data = simpleTable({rows: this.state.rows});
    	
    	var qs = [];
    	if (this.state.dataView){
    		
    		_.each(this.state.dataView, (v)=>{
    			if (v.cid != this.state.id){
    				var position = this.state.positions[v.uid]; 
    				if (position == null){
    					position = {top: 10, left: 10, width: 300, height: 300};
    				}
    				var connectors = v.options.connectors || v.connections;
    				var autoConnectors = [];
    				
    				if (v.type == "DataSet"){
    					autoConnectors.push( {"name":"out","type":"FlowDataSet","direction":"out"} );	
    				}
    				
    				_.each(connectors, (connector)=>{
    					if ( v.options[connector.name] ){
    						connector["_connects_to"] = v.options[connector.name];
    					}
    				});
    				
    				qs.push(<Pickle key={v.uid} id={v.uid} onMove={(pos)=>this.updatePosition(v.uid, pos)} position={position} connectors={_.union(autoConnectors,connectors)}>
	    				<div key="inner" className="element">
	    					<Pumpkin def={v}/>
	    				</div>
    				</Pickle>);
    			}
    		});
    	}
    	
        return <div> 
        	<h4>Data View: {this.rootQ ? this.rootQ.cid : "loading"}</h4>
        	
        	<div className="grided">
        		{qs}
        	</div>
        
        	<button onClick={()=>this.save()}>Save</button>  <button onClick={()=>this.loadAll()}>run query</button>
        	<button onClick={()=>this.add()}>Add</button>
        	
        	<div className="data">{data}</div>        	
        </div>
        
    }
}

