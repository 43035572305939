import React, {Component, useEffect} from 'react';
import { Data as DData } from '../../../Data.js';
import * as _ from 'lodash';

import Functioner from '../../../Functioner.js';
import { useData } from '@opidcore/hooks/WTF';

export default function Data(props) {
	const [data, ds] = useData(props.name, {method: props.method} );

    useEffect( ()=>{
        APP.NDT.getData(props.name);

        ds.callback( (a,b,c)=>{
            APP.NDT.postData(a.__dataUID, ds);
            setTimeout(()=>APP.NDT.refresh(), 250);
        });

    }, [ds]);
    return <div>data loaded {data.length} rows</div>;
  }
  
  Data.getEditableProps = ({})=>{
                
          return [
              {niceName: "Category", prop: "name", options: ["stats"], type: "text" },
              {niceName: "Method", prop: "method", options: ["billingTrends"], type: "text" },
              {niceName: "Argume", prop: "method", options: ["billingTrends"], type: "text" },
          ]
          
  };