import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { InputText, Bound } from '../../../OpidCore/public/js/components';

import * as CentralActions from '../../../OpidCore/public/js/actions/CentralActions';

import SaveToolbar from '../components/SaveToolbar.jsx';

import * as _ from 'lodash';
//import moment from 'moment';

class CoreEditor extends Component {
    static test = "B";

    constructor(props, context) {
        super(props, context);
        
        this.state = {
                __initialFetchCompleted: false,
                __fetching: false,
                id: 0
        };
    }
   
    static getDerivedStateFromProps(props, state){
        if (state.__initialFetchCompleted == false && state.__fetching == false ){
            var newState = {};
            newState.__fetchRequired = true;
            if(props.params != undefined){
                newState.__id = parseInt(props.params.id);
            } else if(props.match != undefined && props.match.params != undefined){
                newState.__id = parseInt(props.match.params.id);
            } else {
                newState.__id = 0;
            }
            
            return newState;
        }
        return null;
    }    
    
    buzzah = () => {
        return "hey";
    }
    
    componentDidUpdate(prevProps, prevState, snapshot){
        if (this.state.__fetchRequired == true){
            this.getCentral().fetch(this.state.__id).then((res)=>{
                this.setState(res.result);
                this.setState({__loaded: true});
            });
            this.setState({__fetchRequired: false, __fetching: true});
        }
    }
    
    render(){
        var inner = null;
        
        if (this.state.__loaded)
            inner = this.renderEditor();
        
        return <div>
        {inner}
        </div>;
    }
    
    getSaveState(){
        return {...this.state};
    }
    

    handleSave(callback){
        if (this.__handleSave == null){
            this.__handleSave = _.debounce( (c) => this._handleSave(c), 500 )
        }
        
        return this.__handleSave(callback);
    }
    
    _handleSave(callback){
        var saveObj = {...this.state};
        
        return this.getCentral().save(saveObj).then((r)=>{
            console.log(r);
            callback && callback();
            this.setState({__fetchRequired:true});
        });
    }
    
   
    
    save(){
        let saveObj = this.getSaveState();
        
        this.getCentral().save(saveObj).then((r)=>{
        });
        
        //alert('saved');
        //window.history.back();
    }
    
    cancel(){
        window.history.back();
    }
 
   
}



class EditDefinitionView extends CoreEditor {
    static test = "A";
    constructor(props){
        super(props);
        
        props.central.Stuff.listDefinitions();
    }
    
    getSaveState(){
        var ret = {...this.state};
        
  
        
        return ret;
    }
    
    handleBlurOptions(e){
        try{
            if (e.currentTarget.value > ""){
                var json = JSON.parse(e.currentTarget.value);
                var newOption = JSON.stringify(json);
                
                this.refs["my-bound"]._myMagic.magicalSet("options", json);
            }
            this.setState({"optionsError": null});
        }catch(e){
            
            this.setState({"optionsError": e.message, lastError: e});
        }
    }
    
    handleChangeOptions(e){
        
    }
    
      
    getCentral(){
        return this.props.central.Definition;
    }
    
    handleFieldUpdate(a,b,c,d){
        
        console.log(c,a);
        
    }
    
        
     renderEditor() {                   
             var error = null;
    
             if (this.state.optionsError){
                 error = <div className="error">{this.state.optionsError}</div>
             }
           
            return (
                <div id="plan" className="outer-container">
                    <div className="container" >
                        <Bound to={this} ref="my-bound" disabled={this.state.disabled}>
                         
                            <div className="header1">
                    
                    
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-xs-12 col-md-push-3 col-sm-push-3">
                                        <h2 className="middle">{this.state.title}</h2>
                                    </div>

                                </div>

                                <div className="row">
                                        <InputText className="field col-md-3" name="Field Name" field="fieldName"/>
                                        <InputText className="field col-md-3" name="Field Type" field="fieldType"/>
                                        <InputText className="field col-md-3" name="Default UID" field="defaultUid" />
                                        <h4>Options:</h4>
                                            <textarea ref="options" onBlur={(e)=>this.handleBlurOptions(e)} onChange={(e)=>this.handleChangeOptions(e)} defaultValue={JSON.stringify(this.state.options)} />
                                            {error}
                                            
                                </div>

                             
                           </div>

                        </Bound>   
                                
                                                            
                          <SaveToolbar handleSave={(e)=>this.handleSave(e)}>
                          </SaveToolbar>

                    </div>
                </div>
            );
        }       
}


function mapStateToProps(state) {
    return {
        plan: state.central.plans || {},
        users: state.central.users || [],
        dataDefinitions: state.central.dataDefinitions || []
    };
}

function mapDispatchToProps(dispatch) {
    return {
        ...CentralActions.modifyProps(dispatch),  
        actions: bindActionCreators({...CentralActions}, dispatch),
    };
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditDefinitionView);
