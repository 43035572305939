import React from 'react';
import { Link } from "react-router-dom";
import WorkingFile from './WorkingFile.jsx';

export default function initialize(permissions, options){
	window.APP.customRoutes.push({path: "/ui/workingFile/:customerId", component: WorkingFile, key: "working_file_default_customer"});
	window.APP.customRoutes.push({path: "/ui/workingFile", component: WorkingFile, key: "working_file"});
	
	APP.addMenu( ()=>{
		const items = [];

		// items.push(<li key="working_file"><Link to="/ui/workingFile"><Icon icon="truck-container"/> Working File</Link></li>);
		
		return items;
	});
}