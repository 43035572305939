import React from 'react';

export default function ViewPanel(props){
    return <div className="view-panel">
        <dl style={props.style}>
            {props.children}
        </dl>
    </div>
}

export function Field(props){
    return <div>
        <dt>{props.label}</dt>
        <dd>{props.children}</dd>
    </div>;
}
