import React from 'react';
//import { Data } from '@opidcore/Data.js';
import { useNavBar } from '@opidcore/hooks/WTF';
//import { Link } from 'react-router-dom';


export default function Prefs(props){
	useNavBar("My Preferences");	
	
    return <div>
    	<h2>Preferences</h2>
        	
    </div>;
}
