import React, { useEffect }  from 'react';
import Util from '@opidcore/Util.js';


export default function TitlePage(props) {
	
  return null;
}
TitlePage.getEditableProps = ({})=>{
	
        return [
            {niceName: "Picture", prop: "pic", options: ["","yes"], type: "text" },
            {niceName: "Title Line 1", prop: "main_title_1",  type: "text", templated: true },
            {niceName: "Title Line 2", prop: "main_title_2",  type: "text", templated: true },
            {niceName: "Sub Title", prop: "sub_title",  type: "text", templated: true },
            {niceName: "Details", prop: "details",  type: "text", multiLine: true,  templated: true }
        ]
        
};

TitlePage.metaInfo = ({})=>{
	return {
		category: 'scripts',
		icon: 'file'
	};
};

