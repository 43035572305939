import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Icon from '@opidcore/components/Icon';
import { useAppState } from '@opidcore/hooks/WTF.jsx';

export function MenuItem({ menuKey, linkTo, icon, text, onClick = null, className = "" }) {
	const [menusOpen, setMenusOpen] = useState([]);
	useAppState("currentMenu", null, setMenusOpen);

	const isOpen = menusOpen.indexOf(menuKey) >= 0;

	return <li className={className} onClick={onClick} key={menuKey}><Link to={linkTo}><Icon icon={icon} className={isOpen ? "highlighted" : ""} /> {text}</Link></li>
}

export function SubMenu(props) {
	const [expanded, setExpanded] = useState(false);
	const mainItem = React.cloneElement(props.children[0], { onClick: () => setExpanded(!expanded) });
	const otherItems = React.Children.map(_.compact(props.children.slice(1)), (child) => {
		return React.cloneElement(child, { className: child.props.className + " nav-sub-menu" });
	});

	return <>
		{mainItem}
		{expanded ? otherItems : null}
	</>
}