import React, { useState,  useRef }  from 'react';
import { useData, useNavBar } from '@opidcore/hooks/WTF';
import NeilDegrasseTyson from '@opidcore/components/Content/NeilDegrasseTyson.jsx';
import RenderMeSoftly from '@opidcore/components/Content/RenderMeSoftly.jsx';
import Bound from '@opidcore/components/Bound';
import { InputText, InputSelect } from '@opidcore/components';
import Fields from '../../../js/Form/Fields';


export default function (props) {
	const boundRef = useRef({});
	const [data, dataSet] = useData('DUMPSTER_FIRE');
	console.log(data)
	const updateFunc = (a, b, c, d) => {
		//debugger;
	}

	var subForm = APP.findForm('DUMPSTER_FIRE');


	window.test = boundRef.current;


	return <div>
		Crazy here
		{data.length}
		<Bound to={boundRef.current} boundId="test">
			<InputText field="cody" name="Cody1"/>
			<InputText field="cody" name="Cody2"/>
			<InputSelect field="anothercody" options={{1:"a", 2:"b"}} name="Updater"/>
		</Bound>

		<Bound to={data[0]}>
			<Fields className={"something_outer"} viewType={"embed"} form={subForm} central={APP.central} section={subForm.defaultSection} outerTag="div" update={updateFunc} fields={_.filter(subForm.fields, { section: subForm.defaultSection })} />
		</Bound>
	</div>;
}

export function something(props){
	const someCrazyStart = { a: 1, b: 2 };	

	return <div>
		<Bound to={someCrazyStart}>
			
			<h1>Hell-o</h1>
		
		</Bound>
	</div>;
}

 function Throwaway(props){
	const navBar = useNavBar("Copy!", []); 
	const [status, setStatus] = useState("?");
	const [rms, setRMSView] = useState(null);

	const NDTref = useRef(null);
	
	if (NDTref.current == null){
		const ourThis = {};
		ourThis.wtf = Math.random();
		
		NDTref.current = new NeilDegrasseTyson(ourThis, "copy")
	}
	
	const NDT = NDTref.current;
	
	let theIdToCopy = "standard-audit-exec-summary"
	theIdToCopy = "on.audit-template";
	
	const copyMe = ()=>{
		setStatus("copying");
		
		//_.uniq( NDT.structure.map( (r)=>{ return {uid: r.uid, cid: r.cid} } ) )
		
		APP.central.Stuff.everythingIWant(theIdToCopy).then((r)=>{
			NDT.loadStructure(r.result.structure);
			NDT.root = theIdToCopy;
			window.NDT = NDT;
			
			setStatus( "NDT Loaded" );
			
			setRMSView(<RenderMeSoftly debug={true} structure={NDT.structure} root={theIdToCopy}/>);
			//debugger;
		});
	};
	
	
	
	
    return <div>
    	<h2>Copy Stuff</h2>
        	<button onClick={copyMe}>Copy me?</button>
        	
    
	        <br/>	
        	Status: <pre>
        		{status}
        	</pre>
        	
        	{rms}
    </div>;
}
