//import './AddFriendInput.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BoundComponent from './BoundComponent';
import moment from 'moment';

export default class InputTimestamp extends BoundComponent {

	static propTypes = {
		update: PropTypes.func,
		property: PropTypes.string,

	};

	constructor(props, context) {
		super(props, context);
		if(this.props.field == undefined ){
			this.state = {
					value: ""
			};
		} else {
			this.state = {
					value:  moment(this.props.obj[this.props.field]).format("HH:mm") //this.props.obj[this.props.property]
			};

		}

		this.handleChange = this.handleChange.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.obj instanceof Component && nextProps.obj.state != null){
			this.setState({
				value: moment(nextProps.obj.state[nextProps.field]).format("HH:mm")
			});
		}else{
			console.log("The world just ended inside inputtimestamp component.");
		}
	}

	handleChange(e) {
		if(this.props.obj != undefined){
			let changingDate = moment(this.props.obj.date).set({hour: e.target.value.split(":")[0], minute: e.target.value.split(":")[1]});
			if(this.props.field != undefined ){
				this.setState({ value: e.target.value });
				this.props.update( this.props.obj, this.props.field, changingDate.unix()*1000 );
				
			} else {
				this.setState({ value: e.target.value });
			}
		} else {
			this.setState({ value: "00:00" });
		}

	}

	render() {
		var name = this.props.name;

		return (
				<div className={"field " +  (this.props.className || "col-xs-12 col-md-12") }>

				<label>{name}</label>
				<input
				type="time"
				className={"form-control"}
				placeholder={this.props.placeholder || ""}
				value={this.state.value || ""}
				onChange={this.handleChange} /> 
				</div>
		);
	}
}
