import React, { useState, useEffect, useContext, useRef }  from 'react';
// import Icon from '@opidcore/components/Icon';
// import Searchable from '@opidcore/components/Searchable';
// import ActionBar from '@opidcore/components/ActionBar';
// import { Link } from 'react-router-dom';
// import { Tabular, TabularColumn } from '@opidcore/components/Tabular';
import {  Button, InputText, Grid, Tabbed  } from '@opidcore/components';
import { GridItem, GridContext } from '@opidcore/components/Grid';
import { useData } from '@opidcore/hooks/WTF';
//import StaffSelect from '../../components/StaffSelect';
import SaveToolbar from '@opidcore/components/SaveToolbar';
import _ from 'lodash';


export default function Lookups(props){
	return <Tabbed title="Lookups">
		<LookupList key="groups" title="Groups" />
	</Tabbed>
}

function EditLookupList(props){
	const gridContext = useContext(GridContext);
	if (props.lookups[0] == null){
		return <div>?</div>;
	}
	
	const boundId = props.lookups[0].name;
	
	const saveList = (e)=>{
		_.forEach(_.values(gridContext.grids), (gridCtx)=>{
            const deltas = gridCtx.getAllBoundDeltas();
            if(deltas.length > 0){
                APP.central.Bound.saveChanges(deltas).then(()=>{
                    gridCtx.resetAllBoundDeltas();
                })
            }
        });	
	};

	const handleCellAction = (e, whatWasClicked, gridCtx, sourceAction)=>{
		let toAction = "Create";
		const modelName = "Lookup";
		const bodyData = {};
		bodyData.defaults = {name: props.name};

		APP.central.Grid.fetchGrid( modelName, toAction, bodyData ).then((r)=>{
			if(r.result.rows[0] && r.result.rows[0][modelName]){
				gridCtx.setData(r.result.rows[0][modelName][0], gridCtx.sortedData.length);
			} else {
				console.log("Lookup.jsx: something went wrong adding a lookup");
			}			
		});
	}

	const gridProps = {
        key: "lookups_grid" + boundId,
        gridIdentifier: "lookups" + boundId,
        data: props.lookups,
        rowBasis: "id",
        appendable: {text: "add a lookup", add: null},
        onCellAction: handleCellAction,
		orderBy: ["name"]
    }

	return <div>
		<Grid {...gridProps}>
			<GridItem title="Key"><InputText name="Key" field="key"/></GridItem>
			<GridItem title="Label"><InputText name="Label" field="label"/></GridItem>
			<GridItem title="Param1"><InputText name="Param1" field="parameter1"/></GridItem>
			<GridItem title="Param2"><InputText name="Param2" field="parameter2"/></GridItem>
			<GridItem title="Colour"><InputText name="Colour" field="colour" inputType="color"/></GridItem>
			<GridItem title="Class Name"><InputText name="Class Name" field="className"/></GridItem>
		</Grid>	
			
		<SaveToolbar handleSave={saveList}/>
	</div>
}



function LookupList(props){
	const [groups, setGroups] = useState([]);
	const [data, dataDS] = useData('lookup');
	const messagesEndRef = useRef(null);
	
	const newListItem = (listName)=>{
		const name = listName ? listName : APP.prompt("List Name");
		dataDS.add({ name: name , __type: "Lookup"}); //@ian and @cody fix this because we can't create new lookup tables without a bound delta having name
		messagesEndRef.current.scrollIntoView(true);
	};
	
	useEffect( ()=>{
		setGroups( _.uniq( _.map( data, l=>l.name ) ) );
	}, [dataDS.loadedCount]);
	
	return <div>
		<Button onClick={()=>newListItem()}>New List</Button>

		{groups.map( (g)=>{
			return <div key={g}>
				<h2>{g}</h2>
				
				<EditLookupList growFunction={()=>newListItem(g)} name={g} lookups={data.filter( (d)=>d.name == g )}/>
			</div>
		}) }
		<div className="scroll-anchor" ref={messagesEndRef}/>
	</div>;
}
