import React, { Component, PropTypes } from 'react';

import * as _ from 'lodash';
import moment from 'moment';

class Person extends Component {
	render(){
		
		if (this.props.person != null){
		return <span>
			{this.props.person.firstName} {this.props.person.lastName} &lt;{this.props.person.email}&gt;  
		</span>;
		}
		
		return <span>Nobody</span>;
	}
}

export default  class Preview extends Component {	
	constructor(props, context) {
        super(props, context);
    }
	
	componentWillMount(){
	}
	
	componentDidMount(){		
		this.refs.preview.contentWindow.document.write( this.props.message.body );
	}
	
	openFile(attachment){
		window.open(window.location.protocol + "//" + window.location.host + "/api/report/" + attachment.filename, "_blank");
	}
	
	sendEmail(e){
    	if(this.props.sendEmail){
            this.props.sendEmail(e);
    	} else {
            console.log("Preview email just broke. You need to set up a send function");
        }
    }

	render() {
		const message = this.props.message;
		let toPeople = null;
		
		if(message.toPeople != undefined){
			const numOfAddresses = message.toPeople.length
			
			toPeople = message.toPeople.map((person, index) =>{ 
				let result = <Person person={person}/>;
				if(index < numOfAddresses-1 && numOfAddresses > 1){
					return <span key={index}>{result}, </span>;
				} else {
					return <span key={index}>{result}</span>;
				}
			});
		} else {
			toPeople = <Person person={message.toPerson}/>;
		}
		
		let attachments = _.map(message.attachments, (attachment, index) =>{
			return <a key={index} className="fileDownload" onClick={()=>this.openFile(attachment)}>{attachment.filename}</a>;
		});
		
		return (
			<div className="preview-outer">
			 <div><strong>From: </strong>  <span><Person person={message.fromPerson}/></span></div>
			 <div><strong>To: </strong>  <span>{toPeople}</span></div>
			 <div><strong>Subject: </strong> <span>{message.subject}</span></div>			 
			 <div><strong>Attachments: </strong><span>{attachments}</span></div>
			 <div><strong>Body: </strong></div>
			 <iframe ref="preview"></iframe>
			 
			 <div><button type="button" className="btn btn-success btn-lg sendButton" onClick={(e)=>this.sendEmail(e)}><span className="glyphicon glyphicon-check text-success"></span>&nbsp;&nbsp;Send</button></div>
			</div>
		
		);
	}
}