
import React, { useEffect, useState } from 'react';
import _ from 'lodash';

const WorkflowContext = React.createContext({onStepFinish: undefined, onGoBack: undefined, setters: {}, lastKnownStep: -1});

const Step = ({children = undefined, myStepNumber}) => {
    const myContext = React.useContext(WorkflowContext);
    const [activeStep, setActiveStep] = useState(1);

    useEffect(()=>{
        myContext.setters[myStepNumber] = setActiveStep;
    },[]);

    if(activeStep != myStepNumber){
        return <div key={"empty_step_" + myStepNumber} className="empty-step">
        </div>;
    }

    return <div key={"step_" + myStepNumber} className="workflow-step">
        {children}
    </div>
}

const Workflow = ({children = undefined, initialStep = 1, initialize = undefined}) => {
    const [currentStep, setCurrentStep] = useState(initialStep);
    const context = React.useRef({onStepFinish: undefined, onGoBack: undefined, setters: {}, lastKnownStep: -1});

    useEffect(()=>{
        context.current.onStepFinish = () => {
            context.current.lastKnownStep = currentStep;
            setCurrentStep(currentStep+1);
        };

        context.current.onGoBack = () => {
            if(context.current.lastKnownStep > 0){
                setCurrentStep(context.current.lastKnownStep);
            }
        };

        if(initialize != undefined){
            initialize(context.current);
        }
    }, []);

    useEffect(()=>{
        React.Children.forEach(children, (child) => {
            if(context.current.setters[child.props.myStepNumber] != undefined){
                context.current.setters[child.props.myStepNumber](currentStep);
            }
        });
    }, [currentStep]);

    return <div className="workflow" key="workflow">
        <WorkflowContext.Provider value={context.current}>
            {children}
        </WorkflowContext.Provider>
    </div>
};

export {Step, WorkflowContext};
export default Workflow;