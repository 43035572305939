import React, { useState, useEffect, useContext } from 'react';
import Crazyness from './Crazyness.jsx';
import WasteKanban from './Kanban.jsx';
import MOETable_V from './MOETable_V.jsx';
import MOETable_D3 from './MOETable_D3.jsx';

import BarGraph from './BarGraph.jsx';
import PieChart from './PieChart.jsx';
import SourceSeparationProgramTable from './SourceSeparationProgramTable.jsx';

import WasteAuditList from './UI/WasteAuditList.jsx';
import WasteAudit from './UI/WasteAudit.jsx';
import WasteAuditTenants from './UI/WasteAuditTenants.jsx';
import Admin from './UI/Admin.jsx';
import AdminACL from './UI/Admin/ACL.jsx';
import AdminXero from './UI/Admin/Xero.jsx';
import DataMesser from './UI/Admin/DataMesser.jsx';
import AdminLookups from './UI/Admin/Lookups.jsx';
import AdminConfigs from './UI/Admin/Config.jsx';

import MaterialEdit from './UI/Admin/MaterialEdit.jsx';
import Boardwalker from './UI/Admin/Boardwalker.jsx';
import ExportInvoice from './UI/Admin/ExportInvoice.jsx';

import  WorkflowList, {WorkflowEdit} from './UI/Admin/WorkflowEdit.jsx';

import ListTax from './UI/Admin/Tax/ListTax.jsx';
import TaskTypeEdit from './UI/Admin/TaskTypeEdit'

import MondayMatch from './UI/Monday/MondayMatch.jsx';

import EquipmentSizeEdit from './UI/Admin/EquipmentSizeEdit.jsx';
import EquipmentTypeEdit from './UI/Admin/EquipmentTypeEdit.jsx';
import ServiceScheduleEdit from './UI/Admin/ServiceScheduleEdit.jsx';
import JointVentureEdit from './UI/Admin/JointVentureEdit.jsx';
import { MenuItem } from '@opidcore/components/Menu.jsx';





import AdminEditTable from './UI/AdminEditTable.jsx';
import WasteMaterialList from './UI/WasteMaterialList.jsx';
import AdminVendorActivities from './UI/Admin/VendorActivities.jsx';
import AdminActivitiesListing from './UI/Admin/ActivitiesListing.jsx'
import StaffEdit from './UI/Admin/StaffEdit.jsx';
import OBM from './UI/Admin/OBM.jsx';
import AdminStatus from './UI/Admin/Status.jsx';
import XeroCurrencies from './UI/XeroCurrencies.jsx'

import GlobalServiceEdit from './UI/Admin/GlobalServiceEdit.jsx';

import ReportManaged from './UI/Reports/ReportManaged.jsx';
import ReportConsulting from './UI/Reports/ReportConsulting.jsx';

import ReportStamped from './UI/Reports/ReportStamped.jsx';

import Schedule from './UI/Schedule.jsx';
import Reports from './UI/Reports.jsx';
import Settings from './UI/Settings.jsx';
import NewAudit from './UI/NewAudit.jsx';

import Throwaway from './UI/Throwaway.jsx';
import Prefs from './UI/Prefs.jsx';
import ContentView from '../../js/Welcome/ContentView.jsx';

import '../styles/report.scss';

import report from './report.jsx';

import Icon from '@opidcore/components/Icon';
import { useNavBar, useAppState, useData } from '@opidcore/hooks/WTF';
import { DataContext } from '@opidcore/components/Bound';
import ReferencedThing from '@opidcore/components/ReferencedThing.jsx';
import * as Modules from './Modules';
import { Link } from "react-router-dom";
import TestGrid from './test';
import MOEChunk from './MOEChunk';
import DateRange from './DateRange';
import NotificationBell from './components/NotificationBell.jsx';

import EmailQueue from './UI/Admin/EmailQueue'

function Todo(props) {
	return <div>

		<h2>Todo</h2>

		<p>this will be done at some point</p>

	</div>;
}

function MOEGuessContent(props) {
	return <span>this needs to be something</span>
}


function WasteSampleSum(props) {
	let context = useContext(DataContext);
	let totalSampleWeight = context.to["sample_weight"];
	let checkClass = "";
	let sumDiff = "";
	let sum = 0;
	if (context.to[props.what] != undefined) {
		sum = _.sumBy(context.to[props.what], (r) => { return parseFloat(r["sample_weight"]) }, 0);
	}

	sum = WASTE_AUDIT.round(sum, 3);
	totalSampleWeight = WASTE_AUDIT.round(parseFloat(totalSampleWeight), 3);

	let numFormat = new Intl.NumberFormat('en-ca', { style: 'decimal', minimumFractionDigits: 3, maximumFractionDigits: 3 });


	if (sum > totalSampleWeight) {
		checkClass = "text-danger";
	} else if (sum < totalSampleWeight) {
		checkClass = "text-success";
	}

	if (checkClass != "") {
		let diff = WASTE_AUDIT.round(sum - totalSampleWeight, 3);
		if (diff > 0) {
			diff = "+" + diff;
		} else {
			diff = diff;
		}
		sumDiff = <ReferencedThing className={checkClass}>{numFormat.format(diff)}</ReferencedThing>;
	}

	return <div className="waste_sample_sum">
		{props.children}
		<span className="array-item">
			<div className="outer-field detail" style={{ width: "16em" }}>
			</div>
			<div className="outer-field detail">
				<span className="weight_sum_check">Total: {numFormat.format(sum)} {sumDiff}</span>
			</div>
		</span>
	</div>;
}

const SubNav = (props) => {
	const [navBar, setNavBar] = useState({selectedItem: 0});
	// const [active, setActive] = useState(navBar.selectedItem || 0);
	const active = navBar.selectedItem;
	let OuterTag = "h2";

	const setActiveAndClick = (i, e, l) => {
		// setActive(i);
		navBar.selectedItem = i;
		if (l && l.props && l.props.onClick) {
			l.props.onClick(e);
		}
	};
	if (navBar && navBar.title && navBar.title.props && navBar.title.props.outerTag) {
		OuterTag = navBar.title.props.outerTag;
	}

	const [extraNavButtons, setExtraNavButtons] = useState(null);

	useAppState("navbar-buttons", null, (a) => { setExtraNavButtons(a) });

	useAppState("navbar", null, (newGuy) => { 
		//setActive(0);
		setNavBar(newGuy);
	 });

	const stupidTitle = navBar != null ? navBar.title : "";

	const mightBeLinks = navBar != null ? navBar.items : [];

	//set body for tabbing
	if (mightBeLinks && mightBeLinks.length > 0) {
		if (document.body.className.indexOf("nav-with-items") == -1) {
			document.body.className = document.body.className + " nav-with-items";
		}
	} else {
		if (document.body.className.indexOf("nav-with-items") > 0) {
			document.body.className = document.body.className.replace(" nav-with-items", "");
		}
	}

	const me = APP.getWebSession();
	const dbStatus = APP.setState("dbStatus");

	/**  
	useEffect(() => {
		setActive(active);
	}, []); */

	var bottom = <div className='nav-items' data-active={active}>
		{_.map(mightBeLinks, (l, i) => {
			return <div className={"nav-item " + (i == active ? "nav-item-active" : "")} onMouseUp={(e) => setActiveAndClick(i, e, l)}>{l}</div>
		})}
	</div>;
	if (me.userId == undefined && window.location.pathname.indexOf("portal") == -1) return <div></div>
	return <div className={"sub-nav " + (mightBeLinks && mightBeLinks.length > 0 ? "sub-nav-with-links" : "")}>
		<div className={"nav-title " + (dbStatus ? dbStatus.database : "")}>
			<OuterTag className="wide">{stupidTitle}</OuterTag>
			{ me ? <>
			
			<div className="notifications">
				{extraNavButtons}
				<NotificationBell me={me.userId}/> {/* @brian */}
			</div>
			<div className="avatar" title={(dbStatus ? "Using database " + dbStatus.database : "")}><img src={"/" + ( me.avatar || "assets/images/blank-avatar.png") }/></div>
			</>  : null }
		</div>		
		{mightBeLinks && mightBeLinks.length > 0 ? bottom : null}
	</div>;
}

export { SubNav };

export function Stuff(options) {

	//This will register our non "core" views for RMS.
	if (window._THE_GLOBAL_TYPES == null) {
		window._THE_GLOBAL_TYPES = {};
	}

	//this is temporary until we find out how generic forms work in opidcore.
	APP.central.DataQuery = APP.central.WasteAudit;

	window._THE_GLOBAL_TYPES['Crazyness'] = Crazyness;
	window._THE_GLOBAL_TYPES['MOETable_V'] = MOETable_V;
	window._THE_GLOBAL_TYPES['MOETable_D3'] = MOETable_D3;
	window._THE_GLOBAL_TYPES['MOEChunk'] = MOEChunk;

	window._THE_GLOBAL_TYPES['BarGraph'] = BarGraph;
	window._THE_GLOBAL_TYPES['PieChart'] = PieChart;
	window._THE_GLOBAL_TYPES['SourceSeparationProgramTable'] = SourceSeparationProgramTable;
	window._THE_GLOBAL_TYPES['WasteSampleSum'] = WasteSampleSum;

	window._THE_GLOBAL_TYPES['MOEGuessContent'] = MOEGuessContent;

	window._THE_GLOBAL_TYPES['ReportManaged'] = ReportManaged;
	window._THE_GLOBAL_TYPES['ReportConsulting'] = ReportConsulting;
	window._THE_GLOBAL_TYPES['ReportStamped'] = ReportStamped;

	window._THE_GLOBAL_TYPES['DateRange'] = DateRange;

	window.APP.customRoutes = [];
	window.APP.customRoutes.push({ path: "/kanbantest", component: WasteKanban });

	window.APP.customRoutes.push({ path: "/ui/audit/new", component: NewAudit });


	window.APP.customRoutes.push({ path: "/ui/audit/:id/invoice", component: Todo });
	window.APP.customRoutes.push({ path: "/ui/audit/:id/history", component: Todo });
	window.APP.customRoutes.push({ path: "/ui/audit/:id/tenants", component: WasteAuditTenants });
	window.APP.customRoutes.push({ path: "/ui/audit/:id/materials", component: WasteMaterialList });

	window.APP.customRoutes.push({ path: "/ui/audit/:id", component: WasteAudit });
	

	window.APP.customRoutes.push({ path: "/ui/audit", component: WasteAuditList });
	window.APP.customRoutes.push({ path: "/ui/admin/acl", component: AdminACL });
	window.APP.customRoutes.push({ path: "/ui/admin/xero", component: AdminXero });
  

	window.APP.customRoutes.push({ path: "/ui/admin/data", component: DataMesser });


  
  //window.APP.customRoutes.push({ path: "/ui/admin/materialEdit", component: MaterialEdit });
  window.APP.customRoutes.push({ path: "/ui/admin/equipmentSizeEdit", component: EquipmentSizeEdit });
  window.APP.customRoutes.push({ path: "/ui/admin/equipmentTypeEdit", component: EquipmentTypeEdit });
  window.APP.customRoutes.push({ path: "/ui/admin/serviceScheduleEdit", component: ServiceScheduleEdit });
  window.APP.customRoutes.push({ path: "/ui/admin/vendoractivities", component: AdminVendorActivities });
  //window.APP.customRoutes.push({ path: "/ui/admin/activities", component: AdminActivitiesListing });
  window.APP.customRoutes.push({ path: "/ui/admin/staffEdit", component: StaffEdit });
  window.APP.customRoutes.push({ path: "/ui/admin/obm", component: OBM });
  window.APP.customRoutes.push({ path: "/ui/admin/globalServiceEdit", component: GlobalServiceEdit });
  window.APP.customRoutes.push({ path: "/ui/admin/status", component: AdminStatus });
  window.APP.customRoutes.push({ path: "/ui/admin/caseTypeEdit", component: TaskTypeEdit })
  window.APP.customRoutes.push({ path: "/ui/admin/workflow/list", component: WorkflowList });
  window.APP.customRoutes.push({ path: "/ui/admin/workflow/:id", component: WorkflowEdit });
  window.APP.customRoutes.push({ path: "/ui/admin/boardwalker", component: Boardwalker });
  window.APP.customRoutes.push({ path: "/ui/admin/exportinvoice", component: ExportInvoice });

  
  window.APP.customRoutes.push({ path: "/ui/admin/jointVenture", component: JointVentureEdit });




  window.APP.customRoutes.push({ path: "/ui/mondayMatch", component: MondayMatch });
  //window.APP.customRoutes.push({ path: "/ui/clientContractList", component: ClientContractList });


	window.APP.customRoutes.push({ path: "/ui/admin/table/:what", component: AdminEditTable });
	window.APP.customRoutes.push({ path: "/ui/admin/lookups", component: AdminLookups });
	window.APP.customRoutes.push({ path: "/ui/admin/config", component: AdminConfigs });
	window.APP.customRoutes.push({path: "/ui/tax/list", component: ListTax});
	window.APP.customRoutes.push({path: "/ui/admin/emailqueue", component: EmailQueue})
	window.APP.customRoutes.push({path: "/ui/admin/xerocurrencies", component: XeroCurrencies})



	window.APP.customRoutes.push({ path: "/ui/schedule", component: Schedule });
	window.APP.customRoutes.push({ path: "/ui/audit-reports", component: Reports });
	window.APP.customRoutes.push({ path: "/ui/settings", component: Settings });
	window.APP.customRoutes.push({ path: "/ui/prefs", component: Prefs });
	window.APP.customRoutes.push({ path: "/ui/throwaway", component: Throwaway });


  

  const thingsIHave = [];
  thingsIHave.push({ module: "Dashboards", needs: null });
  thingsIHave.push({ module: "Services", needs: "Accounting" });
  thingsIHave.push({ module: "Vendors", needs: "Accounting" });
  thingsIHave.push({ module: "Contracts", needs: "Accounting" });

  thingsIHave.push({module: "WasteAudit", needs: "Waste Audit"});
  thingsIHave.push({ module: "Customers", needs: "Accounting" });
  thingsIHave.push({ module: "Sites", needs: "Accounting" });

  thingsIHave.push({ module: "APInvoice", needs: "Accounting" });
  thingsIHave.push({ module: "ARInvoice", needs: "Accounting" });

  thingsIHave.push({ module: "Sustainability", needs: "Sustainability" });  
  
  thingsIHave.push({ module: "WorkingFiles", needs: "Accounting" });
  thingsIHave.push({ module: "Prospect", needs: "Accounting" });
  thingsIHave.push({ module: "Support", needs: "Accounting" });
  
  thingsIHave.push({ module: "Reports", needs: "Accounting" });
  thingsIHave.push({ module: "SustainabilityReports", needs: "Sustainability" });

  thingsIHave.push({ module: "AbominableSnowman", needs: "Accounting" });
  thingsIHave.push({ module: "ChangeLogs", needs: null });
  thingsIHave.push({ module: "Data", needs: null });
  thingsIHave.push({ module: "Tools", needs: "Accounting Admin" });
  
  thingsIHave.push({ module: "Admin", needs: null });



	_.each(thingsIHave, (thing) => {
		const permissions = APP.getWebSession().permissions(thing.needs);
		if (thing.needs == null || permissions != null) {
			const moduleInitializer = Modules[thing.module];

			if (moduleInitializer != null) {			
				moduleInitializer(permissions, options);
			}
		}
	});

	window.APP.customRoutes.push({ path: "/ui/admin", component: Admin });

	//formatters for entities
	window.APP.baseEntities = {};
	window.APP.baseEntities["ARInvoice"] = {
		formatter: (r) => {
			return "Invoice ID: " + r.id + " (" + r.status + ")"
		},
		pluralName: "AR Invoices",
		singluarName: "AR Invoice"
	};
	window.APP.baseEntities["Customer"] = {
		pluralName: "Clients",
		singluarName: "Client"
	};
  window.APP.baseEntities["Staff"] = {
    formatter: (r) => {
      return r.fullName;
    },
    pluralName: "Staff",
    singluarName: "Staff"
  };
  window.APP.baseEntities["Lookup"] = {
    formatter: (r) => {
      return r.label;
    },
    pluralName: "Lookups",
    singluarName: "Lookup"
  };
  window.APP.baseEntities["ClientType"] = {
    formatter: (r) => {
      return r.name;
    },
    pluralName: "Client Types",
    singluarName: "Client Type"
  };
  window.APP.baseEntities["CompensationType"] = {
    formatter: (r) => {
      return r.name;
    },
    pluralName: "Compensation Types",
    singluarName: "Compensation Type"
  };

	APP.central.Status.info().then((r) => {
		APP.setState("dbStatus", r);
	});


	APP.addMenu(() => {
		const items = [];

		items.push(<MenuItem key="settings" menuKey="settings" linkTo="/ui/settings" icon="cog" text="Settings"/>);

		return items;
	});


	window.APP.home = '/what-home';

	window.APP.subNav = SubNav;
	window.APP.customize.on("stream_used", "field_change:stream_type", (boundMagic, field, value, d) => {
		var confirmed = false;
		let bypassConfirm = false;

		if (boundMagic.magicalGet("r_r_r_plan_rms") != undefined && boundMagic.magicalGet("r_r_r_plan_rms").length > 0) {
			bypassConfirm = boundMagic.magicalGet("r_r_r_plan_rms")[0].options.content.length == 0;
		} else if (boundMagic.magicalGet("r_r_r_plan_rms") == undefined || boundMagic.magicalGet("r_r_r_plan_rms").length == 0) {
			bypassConfirm = true;
		}

		if(boundMagic.magicalGet(field) == value){
			bypassConfirm = true;
		}

		if (bypassConfirm) {
			confirmed = true;
		} else {
			//confirmed = APP.confirm("Would you like to change the 3 R's plan as well?");
		}

		if (confirmed && boundMagic.to.stream_type_reference && boundMagic.to.stream_type_reference.moe_plan != undefined) {
			APP.central.Stuff.justWhatINeed(boundMagic.to.stream_type_reference.moe_plan).then(r => {
				boundMagic.magicalMerge("r_r_r_plan_rms", r.result.structure.length > 0 ? r.result.structure[0] : undefined, "options");
			})

		}
	});

	window.APP.customize.on("audit_collection", "field_change:sample_weight", (a, b, c, d) => {
		WASTE_AUDIT.updateRemainder(a);
	});

	window.APP.customize.customSectionComponent("audit_materials_profile","audit_material", MaterialsExtra);

	APP.triggerState("menus");
}

const MaterialsExtra = ({form, mode}) => {
	const [materialTypes, materialTypesDS] = useData("material_definition");

	if (mode == "headings"){
		return [
			<th className="small-col">Sample Size</th>,
			<th className="small-col">Diverted</th>,
			<th className="small-col">Contamination</th>,
			<th className="small-col">Landfill</th>,
			<th className="small-col">Colour</th>,
		];
	}

	if (mode=="footers"){
		const sampleWeight = WASTE_AUDIT.round(_.sum(_.map(_.filter(WASTE_AUDIT.rawData, {}), 'sample_weight')),3);
		const landfillWeight = WASTE_AUDIT.round(_.sum(_.map(_.filter(WASTE_AUDIT.rawData, {final_destination: 'Landfill'}), 'sample_weight')),3);
		const divertedWeight = WASTE_AUDIT.round(_.sum(_.map(_.filter(WASTE_AUDIT.rawData, {final_destination: 'Diverted'}), 'sample_weight_proper')),3);
		const contaminationWeight = WASTE_AUDIT.round(_.sum(_.map(_.filter(WASTE_AUDIT.rawData, {final_destination: 'Diverted'}), 'sample_weight_contamination')),3);

		return [	
			<tfoot>
				<tr>
					<td colspan="7"></td>
					<td>{sampleWeight}</td>
					<td>{divertedWeight}</td>
					<td>{contaminationWeight}</td>
					<td>{landfillWeight}</td>
				</tr>
			</tfoot>
		];		
	}

	let bound = useContext(DataContext);
	
	if (bound.to == undefined){
		return null;
	}

	const sampleWeight = WASTE_AUDIT.round(_.sum(_.map(_.filter(WASTE_AUDIT.rawData, {waste_category: bound.to.material_type}), 'sample_weight')),3);

	const landfillWeight = WASTE_AUDIT.round(_.sum(_.map(_.filter(WASTE_AUDIT.rawData, {waste_category: bound.to.material_type, final_destination: 'Landfill'}), 'sample_weight')),3);
	const divertedWeight = WASTE_AUDIT.round(_.sum(_.map(_.filter(WASTE_AUDIT.rawData, {waste_category: bound.to.material_type, final_destination: 'Diverted'}), 'sample_weight_proper')),3);
	const contaminationWeight = WASTE_AUDIT.round(_.sum(_.map(_.filter(WASTE_AUDIT.rawData, {waste_category: bound.to.material_type, final_destination: 'Diverted'}), 'sample_weight_contamination')),3);

	const bags = "Bags: " + _.uniq(_.map(_.filter(WASTE_AUDIT.rawData, {waste_category: bound.to.material_type}), 'bag_num')).sort().join(', ');

	const materialDef = _.find(materialTypes, {id: parseInt(bound.to.material_type)});
	
	const materialColour = materialDef != null ? <div style={{width: "3em", height: "1em", backgroundColor: materialDef.material_colour }}></div> : null;

	return [
		<td title={bags}>{sampleWeight}</td>,
		<td>{divertedWeight}</td>,
		<td>{contaminationWeight}</td>,
		<td>{landfillWeight}</td>,
		<td>{materialColour}</td>
	]
};

Stuff({});
window.onLogin = () => Stuff({});
