import React, { Component, Fragment, useState } from "react";
import * as _ from "lodash";
import moment from "moment";
import Modal from "../../../../public/js/components/Modal.js";

function Card(props) {
	let card = null;

	if (props.showActions === false) {
		card = <div className="kanban_board-card">{props.cardContent(props)}</div>;
	} else {
		card = (
			<div className="kanban_board-card">
				<div className="kanban_board-card-actions">
					<span className="action transition-down" onClick={() => props.cardActionEvent(props.data, "transition-down")}>
						Down
					</span>
					<span className="action reassign" onClick={() => props.cardActionEvent(props.data, null)}>
						Reassign
					</span>
					<span className="action transition-up" onClick={() => props.cardActionEvent(props.data, "transition-up")}>
						Up
					</span>
				</div>
				{props.cardContent(props)}
			</div>
		);
	}

	return card;
}

function Column(props) {
	const cards = _.map(props.dataset, (dataEntry, index) => {
		return (
			<Card key={"card" + index} data={dataEntry} cardActionEvent={props.cardActionEvent} cardContent={props.cardContent} showActions={props.showActions}>
				{props.children}
			</Card>
		);
	});

	return (
		<div className="kanban_board-column">
			<div className="kanban_board-column-header">
				<h4>{props.heading == null ? "N/A" : props.heading}</h4>
				<div className="kanban_board-column-actions">{props.headingFunction(props.dataset, props.status)}</div>
			</div>
			{cards}
		</div>
	);
}

function modalView(props) {
	let selectInput = null;

	return (
		<div className="kanban_confirm">
			<div>Status Update Confirmation</div>
			<div>Current Status: {props.data.status}</div>
			<div>New Status: {props.status}</div>
			<div className="card_content_wrapper">{props.cardContent(props)}</div>
			<div>
				<label htmlFor="owner_select">Owner:</label>
				<select
					id="owner_select"
					ref={(input) => {
						selectInput = input;
					}}
				></select>
			</div>
			<div>
				<button onClick={() => props.confirmUpdate({ status: props.status, user: selectInput, data: props.data })}>Confirm</button>
			</div>
		</div>
	);
}

const generateColumns = (props) => {
  let dataSets = {};
  if(props.justData) {
    dataSets = _.groupBy(props.data, "status");
  } else {
    dataSets = _.groupBy(props.data.rawData, "status");
  }

	let headingFunction = function (dataset) {
		return "";
	}; //placeholder

	if (props.headingFunction != undefined) {
		headingFunction = props.headingFunction;
	}

	const statuses = _.map(props.status, (status) =>{
		let statusKey = status;
		if(typeof statusKey == "object"){
			statusKey = statusKey.key;
		}

		return statusKey;
	})

	const cardActionEvent = (props, data, action) => {
		let status = null;
		let statusIndex = statuses.indexOf(data.status);
		if (action == "transition-up") {
			if (statusIndex >= statuses.length - 1) {
				//do nothing
			} else {
				statusIndex++;
			}
		} else if (action == "transition-down") {
			//transition-downd
			if (statusIndex <= 0) {
				//do nothing
			} else {
				statusIndex--;
			}
		} else {
			//do nothing because we are just reassigning
		}

		status = statuses[statusIndex];

		const modal = new Modal();
		modal.openModal(React.createElement(modalView, { data: data, status: status, confirmUpdate: (obj) => confirmUpdate(obj), cardContent: props.cardContent }));
	};

	const confirmUpdate = (obj) => {
		let newData = { ...obj.data };
		newData.user = obj.user;
		newData.status = obj.status;

		alert("Updating " + obj.data.id + " to status of " + newData.status + " owned by " + newData.user);
	};

	return _.map(statuses, (status) => {
		let statusLabel = _.find(props.status, (s)=>{
			if(typeof s == "string"){
				return s == status;
			}

			return s.key == status;
		});

		if(typeof statusLabel == "object"){
			statusLabel = statusLabel.label;
		}

		let dataset = dataSets[status];
		if(props.sortBy){
			dataset = _.sortBy(dataset, props.sortBy);
		}
		
		const columnProps = {
			dataset: dataset,
			status: status,
			isLoading: props.isLoading,
			heading: statusLabel.charAt(0).toUpperCase() + statusLabel.slice(1),
			headingFunction: headingFunction,
			cardActionEvent: (data, action) => cardActionEvent(props, data, action),
			cardContent: props.cardContent,
			showActions: props.showActions
		};

		return <Column key={"column" + status} {...columnProps} />;
	});
};

export default function Kanban(props) {
	const columns = generateColumns(props);

	return (
		<div className="kanban_board">
			<h2>Kanban Board</h2>
			<div className="kanban_board-columns-container">{columns}</div>
		</div>
	);
}
