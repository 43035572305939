import React, { Component } from 'react';

export default class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
	  console.log( "OpidCore CAUGHT AN ERROR at " + this.props.path, error, errorInfo );
	  if (window.DEBUG){		 
	  }    
  }

  render() {
    if (this.state.hasError) {
      if (this.props.silent){
        return null;
      }
      if (this.props.path){
        // You can render any custom fallback UI
        return <h1 key="error_handler">Something went wrong in {this.props.path}</h1>;
      }

      return <span className="error"><strong>oops</strong> </span>
    }

    return this.props.children; 
  }
}