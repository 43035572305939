import React from 'react';
import Loading from '@opidcore/components/Loading';
import { useData } from '@opidcore/hooks/WTF';
import _ from 'lodash';
import { Link } from 'react-router-dom';

function ServiceChunk(props){
    return <div className="service_chunk">
        <div className="service_label"><Link to={"/ui/services/edit/" + props.service.friendlyId} className="plain_link">{props.service.shortDescription}</Link></div>
    </div>
}
//******make the Links just open modals. Make modals have an expand option that does APP.redirect(modal.url). Do the modal close and minimize buttons as well
function SiteChunk(props){
    const serviceChunks = _.map(props.site.services, (service)=>{
        return <ServiceChunk service={service} key={"service_chunk_" + service.id}/>;
    });

    return <div className="site_chunk">
        <div className="site_label"><Link to={"/ui/sites/edit/" + props.site.friendlyId} className="plain_link">{props.site.fullName}</Link></div>
        <div className="service_list">{serviceChunks}</div>
    </div>
}

export default function ClientTree(props){
    const [sites, sitesSet] = useData("site;" + props.client.id, APP.central.Site.fetchSitesForCustomerWithServices, {customerId: props.client.id});

    if(sitesSet.loading){
        return <Loading />;
    }

    const siteChunks = _.map(sites, (site)=>{
        return <SiteChunk site={site} key={"site_chunk_" + site.id}/>;
    });

    return <div className="client_tree" key={"client_tree_" + props.id}>
        <div className="client_label"><Link to={"/ui/clients/" + props.client.friendlyId} className="plain_link">{props.client.fullName}</Link></div>
        <div className="site_list">{siteChunks}</div>  
    </div>
}