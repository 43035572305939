import React, { useContext, useState } from "react";
import { useData } from "@opidcore/hooks/WTF";
import {
  Bound,
  BoundCommit,
  Button,
  Icon,
  InputDecimal,
  InputSelect,
  InputText,
  Loading,
  LookupInputSelect,
  SaveToolbar,
  Tabular,
  TabularColumn,
} from "@opidcore/components";
import { CurrentModal } from "@opidcore/components/OpidApplication";

export default function TaskTypeEdit(props) {
  const [data, dataSet] = useData("task_type");

  const viewAssociated = (row) => {
    APP.instance.createModal(<ViewAssociated item={row} />);
  };

  const newTaskType = (id) => {
    APP.instance.createModal(<NewTaskType id={id} />, { modal_name: "Contract Browser" }, { afterClose: () => dataSet.fetch() });
  };

  return (
    <div>
      <Button onClick={() => newTaskType()}>New Task Type</Button>

      <Tabular data={data}>
        <TabularColumn title="Name" data={(row) => <span>{row.niceName}</span>} />
        <TabularColumn title="Hour Estimate" data={(row) => <span>{row.estimatedHoursToClose}</span>} />
        <TabularColumn title="Additional Fields" data={(row) => <span>{row.itemTable}</span>} />
        <TabularColumn title="Edit" data={(row) => <Icon onClick={() => newTaskType(row.id)} icon="pencil" />} />
        <TabularColumn title="View ACL Associations" data={(row) => <Icon onClick={() => viewAssociated(row)} icon="eye" />} />
      </Tabular>
    </div>
  );
}

const ViewAssociated = (props) => {
  const taskTypeId = props.item.id;
  const [data, dataSet] = useData("task_acl_group", { tasktype: { id: taskTypeId } });

  const [loading, setLoading] = useState(false);

  const refreshDataset = () => {
    APP.central.Util.clearCache(dataSet.uid).then(() => dataSet.fetch());
  };

  const openNewTaskACLGroupModal = () => {
    APP.instance.createModal(
      <NewTaskACLGroup id={taskTypeId} />,
      {
        modal_name: "New Task ACL Group",
      },
      {
        afterClose: () => refreshDataset(),
      }
    );
  };

  const handleDelete = (row) => {
    setLoading(true);
    APP.central.TaskACLGroup.delete(row.id).then(() => {
      setLoading(false);
      refreshDataset();
    });
  };

  if (loading || dataSet.loading) {
    return <Loading />;
  }

  return (
    <div>
      <Button onClick={() => openNewTaskACLGroupModal()}>Add Group Association</Button>
      {data && data.length == 0 ? <div>No associated ACL groups. This Case Type will be available to all Groups.</div> : null}
      <Tabular data={data}>
        <TabularColumn title="Case Type" data={(row) => <span>{row.tasktype.id}</span>} />
        <TabularColumn title="ACL Group" data={(row) => <span>{row.aclGroup}</span>} />
        <TabularColumn title="Remove" data={(row) => <Icon icon="trash" onClick={() => handleDelete(row)} />} />
      </Tabular>
    </div>
  );
};

const NewTaskACLGroup = (props) => {
  const theNewTaskACLGroup = { taskType: { id: props.id } };
  const currentModal = useContext(CurrentModal);

  const doSave = () => {
    APP.central.TaskACLGroup.saveChanges(0, theNewTaskACLGroup).then((r) => {
      APP.instance.closeModal(currentModal != undefined && currentModal.id != undefined ? currentModal.id : undefined);
    });
  };

  return (
    <div>
      <h4>New Group Association</h4>
      <Bound to={theNewTaskACLGroup}>
        <LookupInputSelect what="acl_group" field="aclGroup" />
      </Bound>
      <SaveToolbar handleSave={doSave} />
    </div>
  );
};

const NewTaskType = (props) => {
  const [data, dataSet] = useData("task_type", props.id != undefined ? props.id : 0);
  const [optionsData, optionsDataSet] = useData("data_form_", { formType: "standard" });

  const options = optionsData.map((o) => {
    return { id: o.uid, name: o.title };
  });
  if (options.length > 0) {
    //debugger;
  }

  return (
    <div>
      <h4>Case Type Setup</h4>
      <BoundCommit commit={APP.central.TaskType.saveChangesFull} initialValue={data}>
        <InputText name="Name" field="name" />
        <InputDecimal name="Estimated Hours to Close" field="estimatedHoursToClose" />
        <LookupInputSelect name="Case Category" what="lookup:taskCategory" field="taskCategory" />
        <InputSelect name="Additional Fields" field="itemTable" optionsCollection={options} showEmpty={true} />
      </BoundCommit>
    </div>
  );
};
