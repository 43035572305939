import React from 'react';
import { Button, Pagination, TabularColumn, TabularDataSet } from '@opidcore/components';
import ActionBar from '@opidcore/components/ActionBar';
import Searchable from '@opidcore/components/Searchable';
import { useData, useNavBar } from '@opidcore/hooks/WTF';

// THIS DASH IS UNUSED

export default function SupportDashboard(props) {
	const navBar = useNavBar("Support Tickets", []);
	const createTicket = () => {
		APP.instance.createModal()
	}
	const [data, dataSet] = useData("site", { paginate: 6 })

	return <div key="list_support">
		<div className="page-block" key="static_part">
			<h2>Waste Solutions Canada is here to help</h2>
			<b>Contact our 24/7 Live Customer Service
			Test</b>
			<p>Test</p>
		</div>


		<div className="page-block">

		<ActionBar title="Actions">
			<Searchable ds={dataSet} />
		</ActionBar>
		<Pagination dataSet={dataSet} />

		{dataSet.loading ? "loading" : ""}

		<TabularDataSet dataSet={dataSet}>
			<TabularColumn data={(row) => row.id} title="Site Name" />
			<TabularColumn data={(row) => row.name} title="Description" />
			<TabularColumn data={(row) => row.address} title="Date Sent" />
			<TabularColumn data={(row) => row.city} title="Status" />
		</TabularDataSet>

		<Button onClick={createTicket}>New Support Ticket</Button>

		</div>

	</div>;
}
