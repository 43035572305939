
import React, { useContext, useEffect, useState } from "react";
import { Bound, Button, Icon, Tabular, TabularColumn } from "@opidcore/components";
import LookupStatusSelect from "@opidcore/components/LookupStatusSelect";
import { CurrentModal } from "@opidcore/components/OpidApplication";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import { NiceRelativeTime } from "../UI/Nice";

const NotificationCount = ({ displayedCount = 0, showHighlighted = false, title = undefined, fontSize = undefined }) => {
    let styles = { background: "Gray" };

    if(showHighlighted){
        styles.background = "Tomato";
    }

    if(fontSize){
        styles.fontSize = fontSize;
    }
    
    return <span className="fa-layers-counter" title={title} style={styles}>
        {displayedCount}
    </span>
}

export { NotificationCount };

export default function NotificationBell(props) {

    const [myTasks, setMyTasks] = useState([])
    const [newCount, setNewCount] = useState(0)
    const [progressCount, setProgressCount] = useState(0)
    const [filteredData, setFilteredData] = useState([])
    const me = APP.getWebSession()

    useEffect(() => {
        updateTasks()
    }, [])

    const updateTasks = () => {
        APP.central.Task.fetchMyTasks(props.me).then((r) => {
            setMyTasks(r.result)
            var newCounter = 0
            var progressCounter = 0
            for (const task in r.result) {
                for (const taskActivity in r.result[task].taskActivity) {
                    if (r.result[task].taskActivity[taskActivity].assignedToStaff.id == me.userId) {
                        if (r.result[task].taskActivity[taskActivity].status == "new") {
                            newCounter = newCounter + 1
                        }
                        else if (r.result[task].taskActivity[taskActivity].status == "in_progress") {
                            progressCounter = progressCounter + 1
                        }
                    }
                }
            }
            /*
            let newPartition = _.partition(r.result, task => {
                return task.status == "new"
            })
            let progressPartition = _.partition(r.result, task => {
                return task.status == "in progress"
            })
            */
            setNewCount(newCounter)
            setProgressCount(progressCounter)
        })
    }

    useEffect(() => {
        APP.getState("handleSocket", (thing) => {           
            let found = false;
            if (thing.object) {
                if (thing.object.__type == 'TaskActivity') {                    
                    //APP.toast(thing.message);
                    //APP.central.Util.clearCache();
                    updateTasks();
                }
            }
        });
    }, [])

    const doStuff = () => {
        APP.instance.createModal(<NotificationViewer tasks={myTasks} />)
    }

    const NotificationViewer = (props) => {

        const data = props.tasks
        const currentModal = useContext(CurrentModal)
        const me = APP.getWebSession()
        const hour = moment().format("HH")
        const [filter, setFilter] = useState(true)
        const [filteredData, setFilteredData] = useState([])
        const [progressData, setProgressData] = useState([])

        useEffect(() => {
            let newArr = [] //@brian all taskactivities from the same task get the same description
            for (let i = 0; i < data.length; i++) {
                for (let j = 0; j < data[i].taskActivity.length; j++) {
                    if (data[i].taskActivity[j].assignedToStaff.id == me.userId) {
                        let newItem = {}
                        newItem.id = data[i].id
                        newItem.subject = data[i].subject
                        newItem.taskTypeObject = data[i].taskTypeObject
                        newItem.status = data[i].status
                        newItem.related = data[i].related
                        newItem.relatedTable = data[i].relatedTable
                        newItem.relatedId = data[i].relatedId
                        newItem.created = data[i].created
                        newItem.createdBy = data[i].createdBy
                        newItem.notes = data[i].taskActivity[j].notes //this has an issue
                        newItem.activityStatus = data[i].taskActivity[j].status //so this probably does as well
                        newArr.push(newItem)
                    }
                }
            }
            setFilteredData(newArr)
            setProgressData(_.filter(newArr, (item) => {
                return item.activityStatus != "complete"
            }))
        }, [data])

        const notificationStuff = (id) => {
            APP.redirect("/ui/support/case/" + id)
            APP.instance.closeModal(currentModal != undefined && currentModal.id != undefined ? currentModal.id : undefined)
        }

        return (
          <div>
            <h4>
              {hour >= 7 && hour <= 12 ? "Good morning" : "Good afternoon"}, {me.fullname}.
            </h4>
            <h4>Assigned Cases</h4>
            <Button onClick={() => setFilter(!filter)}>{filter ? "In-Progress" : "All"}</Button>
            <Tabular data={filter ? progressData : filteredData}>
              <TabularColumn title="Title" data={(row) => <span>{row.subject}</span>} />
              <TabularColumn title="Type" data={(row) => <span>{row.taskTypeObject.name}</span>} />
              <TabularColumn title="Status" data={(row) => (<Bound to={row}><LookupStatusSelect field="status" lookupName="supportTaskStatus" disabled={true} /></Bound>)}/>
              <TabularColumn title="Subject" data={(row) => {
                  switch (row.relatedTable) {
                    case "Customer":
                      return <Link to={"/ui/clients/" + row.related.friendlyId}><span>{row.relatedTable + ": " + row.related.name}</span></Link>
                    case "Site":
                      return <Link to={"/ui/clients/sites/" + row.related.id}><span>{row.relatedTable + ": " + row.related.name}</span></Link>
                    case "Vendor":
                      return <Link to={"/ui/vendors/edit/" + row.related.friendlyId}><span>{row.relatedTable + ": " + row.related.name}</span></Link>
                    case "Contract":
                      return <Link to={"/ui/vendorcontract/edit/" + row.related.friendlyId}><span>{row.relatedTable + ": " + row.related.name}</span></Link>
                    case "APInvoice": 
                      return <Link to={"/ui/apinvoice/" + row.related.id}><span>{row.relatedTable + ": " + row.related.vendor.name + " " + row.related.billingPeriod}</span></Link>
                    case "ARInvoice":
                      return <Link to={"/ui/arinvoice/" + row.related.id}><span>{row.relatedTable + ": " + row.related.customer.friendlyId + " " + row.related.billingPeriod}</span></Link>
                    case "SiteSustainabilityStatus":
                      return <Link to={"/ui/sustainabilityedit/" + row.related.site.customer + "/" + row.related.reportPeriod}><span>{row.related.site.customerName}: {row.related.reportPeriod}</span></Link>
                    default:
                      return null;
                  }
                }}
              />
              <TabularColumn title="Created" data={(row) => <NiceRelativeTime date={row.created} />} />
              <TabularColumn title="Created By" data={(row) => <span>{row.createdBy}</span>} />
              <TabularColumn title="Activity Description" data={(row) => <span>{row.notes}</span>} />
              <TabularColumn
                title="Activity Status"
                data={(row) => (
                  <Bound to={row}>
                    <LookupStatusSelect field="activityStatus" lookupName="supportTaskStatus" disabled={true} />
                  </Bound>
                )}
              />
              <TabularColumn title="Edit Case" data={(row) => <Icon icon="pencil" onClick={() => notificationStuff(row.id)} />} />
            </Tabular>
          </div>
        );
    } 

    return  <span className="fa-layers fa-fw">
            <Icon fType="fal" icon="bell" onClick={() => doStuff()} type={"span"} style={{cursor: 'pointer'}}/>
            {newCount > 0 || progressCount > 0 ? (
                <NotificationCount displayedCount={newCount > 0 ? newCount : progressCount} showHighlighted={newCount > 0 ? true : false}/>
            ) : null}
        </span>
}