import React, { useEffect, useState } from 'react';
import { ActionBar, Loading, Tabular, TabularColumn, } from '@opidcore/components';
import moment from 'moment';
import ReasonableFilter from '../../components/ReasonableFilter';
import { Link } from 'react-router-dom';

export default function AutomationLog(props) {
    const [data, setData] = useState(null)
    const [filteredData, setFilteredData] = useState(null)

    useEffect(() => {
        APP.central.ARInvoice.getAutomationLogs().then((r) => {
            setData(r.result.rows)
            setFilteredData(r.result.rows)
        })
    }, [])

    if (data == null) {
        return <Loading />
    } else {
        return <div>
            <h1>Automation Log</h1>
            <ActionBar title="Actions">
                <ReasonableFilter columns={[
                    {column: "user_name", heading: "User"}, 
                    {column: "related_table", heading: "Invoice Type"},
                    {column: "subject", heading: "Automation Status"}          
                ]} dataset={data} setFilteredDataset={setFilteredData} />
            </ActionBar>
            <Tabular data={filteredData}>
                <TabularColumn title="User" data={(row) => row.user_name}/>
                <TabularColumn title="Time" data={(row) => row.start} />
                <TabularColumn title="Invoice Type" data={(row) => row.related_table} />
                <TabularColumn title="Invoice ID" data={(row) => row.related_table == "APInvoice" ? 
                    <Link to={"/ui/apinvoice/" + row.related_table_id}>AP {row.related_table_id}</Link> : 
                    <Link to={"/ui/arinvoice/" + row.related_table_id}>AR {row.related_table_id}</Link>} />
                <TabularColumn title="Details" data={(row) => row.what} />
                <TabularColumn title="Subject" data={(row) => row.subject} />
            </Tabular>
        </div>
    }
        
}