import React from 'react';
import * as _ from 'lodash';
// import templated from '@opidcore/components/Content/Types/Templated.jsx';
// import moment from 'moment';
import { chunk } from 'lodash';

function roundMe(x){
	if (typeof x == "string"){
		if (x.indexOf("%") >= 0){
			return x;
		}
		x = parseFloat(x);
	}
	const value = x != null && isNaN(x) == false ? x.toFixed(2) : (0).toFixed(2);
    const chunks = value.split('.');

    if (parseInt(chunks[0]) == 0){
       return '-';
    }

    return parseInt(chunks[0]).toLocaleString() + '.' + (chunk[1] || '00');

}
function CategoryChunk(props){
    let res = [];
    res.push(<td key="chunk1">{roundMe(props.base)}</td>);
    res.push(<td key="chunk2">{roundMe(props.current)}</td>);
    res.push(<td key="chunk3">{props.total && props.base ? roundMe(props.diff) : '-'}</td>);
    
    return res;
}

function CategoryRow(props){
    return <tr>
        <td>{props.detail.material}</td>
        {_.map(props.categories, (category)=>{      
            const baseValue = null;
            const diff = props.detail[category.field]? props.detail[category.field]-baseValue : 0;
            return <CategoryChunk key={props.detail.material + "" + category.field} current={props.detail[category.field]} base={baseValue} diff={diff}/>;                                
        })}
    </tr>
}

export default function MOETable_V(props){
	

    let columnTotals = {};
    const workingDays = 1;
    
    const data = _.map(props.NDT.announcements.finalDestinationTotals, (values, material)=>{
        let normalizedValues = {};
        let rowGenerated = 0;
        _.forEach(values, (value, key)=>{
            if(value != undefined){
                normalizedValues[key] = parseFloat(value * workingDays).toFixed(2);
                rowGenerated += parseFloat(normalizedValues[key]);
                
                if(columnTotals[key+"Total"] == undefined){
                    columnTotals[key+"Total"] = 0;
                }
                columnTotals[key+"Total"] += parseFloat(normalizedValues[key]);
            }
        });
        
        normalizedValues["generated"] = rowGenerated;
        if(columnTotals["generatedTotal"] == undefined){
            columnTotals["generatedTotal"] = 0;
        }
        columnTotals["generatedTotal"] += rowGenerated;
        return {material: material, ...normalizedValues};
    });
    
    const categories = [{label: "Generated", field: "generated"}, {label: "Reused", field: "reused"}, {label: "Recycled", field: "Diverted"}, {label: "Disposed", field: "Disposed"}];
    
    
    let yearGroups = [];
    _.forEach(categories, (category)=>{
        yearGroups.push(<th key={category.field+"year1"}>"A" Base Year</th>);
        yearGroups.push(<th key={category.field+"year2"}>"B" Current Year</th>);
        yearGroups.push(<th key={category.field+"change"}>"C" Change (B-A)</th>);
    });
    
    return <div className="moe-v-outer">
    <table key="moe_table" className="compact moe-table moe-v">
        <thead>
            <tr>
                <th rowSpan="2">Part V</th>
                <th colSpan={categories.length * 3}>Estimated Amount of Waste Produced in Kilograms (kg)</th>
            </tr>
            <tr>
                {_.map(categories, (category)=>{
                    return <th colSpan="3" key={category.field + "categoryLabel"}>{category.label}</th>;
                })}
            </tr>
            <tr>
                <th>Waste Categories:</th>
                {yearGroups}
            </tr>
        </thead>
        <tbody>
                {_.map(data, (detail)=>{
                    return <CategoryRow detail={detail} key={detail.material} categories={categories}/>
                })}
                <tr className="totals">
                    <td>Total:</td>
                    {_.map(categories, (category)=>{
                        let baseYearValue = props[category.field + "BaseYear"];

                        if(baseYearValue == undefined || isNaN(baseYearValue)){
                            baseYearValue = 0;
                        }
                        const diff = (columnTotals[category.field+"Total"] || 0) - baseYearValue;
                        return <CategoryChunk total={true} key={category.field + "total"} current={columnTotals[category.field+"Total"] || 0} base={baseYearValue} diff={diff}/>;                               
                    })}
                </tr>
                <tr className="percentChange">
                    <td>Percent Change<br/>(total C/total A x 100)</td>
                    {_.map(categories, (category)=>{                 
                        let baseYearValue = props[category.field + "BaseYear"];

                        if(baseYearValue == undefined || isNaN(baseYearValue)){
                            baseYearValue = 0;
                        }
                        const diff = (columnTotals[category.field+"Total"] || 0) - baseYearValue;
                        const val = baseYearValue > 0? ((columnTotals[category.field+"Total"] - baseYearValue) / baseYearValue) * 100 : 100;
                        return <CategoryChunk total={true} key={category.field + "Total"} diff={WASTE_AUDIT.round(val,2) + "%"}/>;                               
                    })}
                </tr>
        </tbody>
    </table>
    </div>;
}

MOETable_V.metaInfo = ({})=>{
	return {
		rendersChildren: true,
		showInEditorQuickAdd: false
	};
};

MOETable_V.getEditableProps = ()=>{
    return [
        {niceName: "Generated Base Year", prop: "generatedBaseYear", type: "text", default: "0.0"},
        {niceName: "Reused Base Year", prop: "reusedBaseYear", type: "text", default: "0.0"},
        {niceName: "Recycled Base Year", prop: "DivertedBaseYear", type: "text", default: "0.0"},
        {niceName: "Disposed Base Year", prop: "LandfillBaseYear", type: "text", default: "0.0"}
    ];
}
    