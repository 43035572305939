import React from 'react';

export default function UnlockToEdit(props){
	const TagName = props.tag || React.Fragment;
	
    let children = props.children
    if( !Array.isArray(children) ){
        children = [children];
    }
    
    children = _.map(children, (child, index)=>{
       return React.cloneElement(child, {key: index, disabled: props.unlocked == true? false : true}); 
    });
    
    return <TagName key="unlock_to_edit">
        {children}
    </TagName>
}