import React, { useContext, useEffect, useRef, useState } from 'react';
import { DataContext } from './Bound';

import * as _ from 'lodash';

const InputTextWithSelect = ({parent, options, field, disabled = false, placeholder = undefined, className = undefined, formatter = undefined, name = undefined, onChange = undefined, required = false}) => {
    const boundMagic = useContext(DataContext);
    const inputRef = useRef(null)
    const [active, setActive] = useState(false);
    const [value, setValue] = useState(boundMagic.magicalGet(field));
    const currentDropDownFocusIndex = useRef(0);
    let searchBox = null;
    let label = null;

    useEffect(()=>{
        boundMagic.magicalState(field, (v)=>{ setValue(v); });
    }, [boundMagic]);

    useEffect(()=>{
        if(value != boundMagic.magicalGet(field)){  
            boundMagic.magicalSet(field, value);
        }
    }, [value]);

    const labelFormatter = (v) =>{
        if(formatter != undefined){
            return formatter(v);
        }

        return v;
    }

    const handleChange = (v) =>{
        if(parent === 'termField' && v !==""){
            v= parseInt(v);
            if(_.isNaN(v)){ 
                v=""
            }
        }
                if(onChange != undefined){
            onChange(v);
        }
        
        setValue(v);
    }

    let selectOptions = _.map(options, (option) => {
        return <li key={option} onClick={() => { handleChange(option); setActive(false); }} >{labelFormatter(option)}</li>;
    });

    if(name != undefined){
        label = <label>{name}</label>;
    }

    if(active){
        searchBox = <ul>{selectOptions}</ul>;
    }

    const doKeyDownEvent = (e) => {
		if (e.key == 'Tab') {
			setTimeout(() => {
				setActive(false);
			}, 100);
		} else if(e.key == "ArrowDown" || e.key == "ArrowUp"){
            //do something to be able arrow down and up and select a term
            if(e.key == "ArrowDown"){
                if(currentDropDownFocusIndex.current < selectOptions.length){
                    currentDropDownFocusIndex.current = currentDropDownFocusIndex.current + 1;
                }
            } else if(e.key == "ArrowUp"){
                if(currentDropDownFocusIndex.current > 0){
                    currentDropDownFocusIndex.current = currentDropDownFocusIndex.current - 1;
                }
            }

            if(currentDropDownFocusIndex.current >= selectOptions.length){
                currentDropDownFocusIndex.current = selectOptions.length - 1;
            }

            if(currentDropDownFocusIndex.current <= 0){
                currentDropDownFocusIndex.current = 0;
            }
            
            const newOption = selectOptions[currentDropDownFocusIndex.current];
            newOption.props.onClick();
        }
	}

    return (<div className={"multisearch_body field " + (className || "col-xs-12 col-md-12")} onMouseLeave={()=>{ inputRef.current.blur(); setActive(false);}}>
        {label}

        <div className="searchBox">
            <div className="searchWrapper">
                <input
                    type="text"
                    className={"form-control" || className}
                    ref={inputRef}
                    placeholder={placeholder}
                    value={labelFormatter(value)}
                    disabled={disabled}
                    onFocus={()=>setActive(true)}
                    onChange={(e) => handleChange(e.currentTarget.value)} 
                    onKeyDown={(e)=>doKeyDownEvent(e)}/>
            </div>

            <div className="searchResults">
                {searchBox}
            </div>
        </div>
    </div>);
}

export default InputTextWithSelect;