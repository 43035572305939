import React, { useEffect }  from 'react';
import { useMagic } from '@opidcore/hooks/WTF';
import { Bound, DatePicker } from '@opidcore/components';

export default function DateRange({}){
    const bound = useMagic({});

    useEffect( ()=>{
        bound.on('*', ()=>{
            debugger;
        });
    }, [bound]);

    return <div>
        <Bound to={bound}>
            <DatePicker name="From" field="from"/>
            <DatePicker name="To" field="to"/>
        </Bound>
    </div>
}

/** PeriodSelect.metaInfo = ({})=>{
		return {renderAsPng: true, icon: "chart-bar", preview: (me)=>{ return "?" }, showInEditorQuickAdd: true };
  }; **/
  
  DateRange.getEditableProps = ()=>{
    return [
        {niceName: "Stuff", prop: "stuff", type: "text"}
    ];
}