import React, { Fragment, useEffect, useState } from "react";
import Icon from "@opidcore/components/Icon";

export default function StatusLight(props) {
  const colour = props.colour;

  return (
    <Fragment>
      <Icon fType="fas" icon="circle" size="2x" color={colour} />
    </Fragment>
  );
}

export function ContractStatusLight(props) {
  const [status, setStatus] = useState({ contractStatusColour: "purple", expiryTime: "", statusString: "Loading or No Status" });
  const data = props.contract;

  useEffect(() => {
    if (data && data.id) {
      if (data.__type == "ClientContract") {
        APP.central.ClientContract.getContractStatusInfo(data.id).then((r) => {
          if (r.result != null){
            setStatus(r.result);
          }
        });
      }
      if (data.__type == "VendorContract") {
        APP.central.VendorContract.getContractStatusInfo(data.id).then((r) => {
          if (r.result != null){
            setStatus(r.result);
          }
        });
      }
    }
  }, [data]);

  const statusString = status.statusString ? (
    <span>
      {status.statusString == "Expiring Soon" ? <strong>{ "-" + status.statusString }</strong> : "-" + status.statusString } {status.expiryTime}
    </span>
  ) : (
    <span>{"-No Status"}{status.expiryTime}</span>
  );
  
  return (
    <Fragment>
      <StatusLight colour={status.contractStatusColour} />
      {statusString}
    </Fragment>
  );
}

