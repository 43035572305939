import * as _ from 'lodash';
import moment from 'moment';
 
export class Util {

		// could really be a browser dimension or somthing
        static parseSize(width) {
			// for now expect it to be a px string, but later we want % and em to be accepted
            return parseInt(width);

        }
		static currency(value){
	
			if (typeof value == "string"){
				value = parseFloat(value);
			}
			if (value == null){
				value = 0;
			}
			
			return '$' + value.toFixed(2);
		}
		
		static round(num, places){
			if(num == undefined){
				num = 0;
			}
			const _num = typeof num == "string" ? parseFloat(num.replace(",", "").replace("$", "").replace(" ", "")) : num;
			const val = (Math.round((_num + Number.EPSILON) * Math.pow(10,places)) / Math.pow(10,places)).toFixed(places); 

			return val;
		}
		
		static roundNice(num, places){
			if(isNaN(num)){
				num = 0;
			}
			const to = places == undefined ? 2 : places;

			const val = Util.round(num, places);
			let formatted = parseFloat(val).toLocaleString();
			if(isNaN(parseFloat(formatted))){
				//debugger;
			}
			if (to > 0){
				if (formatted.indexOf(".") == -1){
					formatted += ".0"
				}
				while ((formatted.length-1) - formatted.indexOf(".") < to){
					formatted += "0";
				}
			}
			
			return formatted;
		}
		
		static stringify(data) {
			return JSON.stringify(data, (name, val) => {
				if(name == "__modified" || name == "_ourMagic" || name == "_myMagic" || name == "parentMagic" || (name == "kid" && _.isObject(val))  || (name == "me" && _.isObject(val))){
					return null;
				}
				
				return val; 
			});
		}
		
		static memoHash = _.memoize(Util.hash);

		static hash(str){
		        var hash = 0;

		        if (typeof str == "object"){
		        	return Util.hash( Util.stringify( str ) );
		        }
		        
		        if (typeof str == "function"){
		        	
		        	return "function";
		        }
		        
		        if (str == undefined || str.length == 0) return hash;

		        for (var i = 0; i < str.length; i++) {
		            var char = str.charCodeAt(i);
		            hash = ((hash<<5)-hash)+char;
		            hash = hash & hash; // Convert to 32bit integer
		        }

		        return hash;
		}
		
		//thanks @jvanderberg
		static objectDifference(object, base) {
			return _.transform(object, (result, value, key) => {
				if (!_.isEqual(value, base[key])) {
					let isArray = false;
					if(_.isArray(value) && _.isArray(base[key])){
						isArray = true;
					}
					
					if (!isArray && _.isObject(value) && _.isObject(base[key])){
						result[key] = Util.objectDifference(value, base[key]);
						if 	(result[key].__type == null && base[key].id){
							result[key].__type = base[key].__type;
							if(result[key].id == undefined || result[key].id == 0){
								result[key].id = base[key].id;
							}
						}
					} else if(isArray){
						const valString = JSON.stringify(value);
						const baseString = JSON.stringify(base[key]);
						if(valString != baseString){
							result[key] = value;
						}
					} else{
						result[key] =  value;
					}

				}
			});
		}
		
		
		static deepMap(obj, iter){
			return _.transform(obj, (result, value, key)=>{
					//console.log("in deep:",  key, value );
					result[key] = iter(value,key) == true ? ( _.isObject(value) ? Util.deepMap(value, iter) : value ) : null;
				});
		}

		static OpidMerge(obj, src){
			const mergeHelper = (objValue, srcValue) => {
				if (objValue === undefined) {
					return srcValue;
				} else if (_.isPlainObject(objValue)) {
					return _.mergeWith({}, objValue, srcValue, mergeHelper);
				} else if (Array.isArray(objValue)) {
					return [...srcValue];
				}
			}

			_.mergeWith(obj, src, mergeHelper);
		}

		static capitalizeWord = (word) => {
			if(word.length == 0){
				return "";
			}
			
			if (word.length < 2) {
				return word[0].toUpperCase();
			}
		
			return word[0].toUpperCase() + word.substring(1, word.length);
		}

		static capitalizeWords = (words, splitString) =>{
			if(Array.isArray(words)){
				_.map(words, (s)=>Util.capitalizeWord(s)).join(" ");
			}

			return _.map(words.split(splitString), (s)=>Util.capitalizeWord(s)).join(" ");
		}

    static isBaseEntity = (b) => {
      return b.__type != null;
    }

    static containsBaseEntity = (things, b) => {
      return _.filter(things, (t) => {
        return t.__type == b.__type && t.id == b.id
      }).length > 0
    }

	static findBaseEntity = (things, b) => {
		return _.first(_.filter(things, (t) => {
		  return t != null && t.__type == b.__type && t.id == b.id
		}));
	  }

  static isSameAs = (a, b) => {
    return a.__type == b.__type && a.id == b.id
  }

  static addTerm = (date, term) => {
    const origDate = moment(date);
    const momentDate = moment(date);
    momentDate.add(term, "month");
    if(origDate.date() <= momentDate.date()) {
      momentDate.subtract("day", 1);
    }
    return momentDate;
  }

  static deCamelCase = (str) => {
    if(str) {
      str = str.replace(/([a-z])([A-Z])/g, '$1 $2');
      str = str.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
    }
    return str;
  }

  // returns a friendly id that is sortable
  static sortableFriendlyId = (friendlyId) => {
    let splitId = _.split(friendlyId, "-");

    let sortable = "";
    _.forEach(splitId, (s, i) => {
      if(i == 0){
        return; //continue
      }
      sortable += _.padStart(s, 10, "0");
    });
    return sortable
  }


  static doCheckPass = (staff) => {
    let validPass = false;

    if (staff && staff.passCrypt) {
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/gm;

      let m;

      while ((m = regex.exec(staff.passCrypt)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }

        // The result can be accessed through the `m`-variable.
        validPass = false;
        m.forEach((match, groupIndex) => {
          validPass = true;
        });
      }
    } else {
      validPass = false;
    }

    return validPass;
  };

};
window.Util = Util;

export default Util;