import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types'
import _ from 'lodash';
import { Util } from '@opidcore/Util';
//import { useData } from '@opidcore/hooks/WTF';
import { BoundCommit, DatePicker, InputDecimal, InputMultiCheckbox, InputSelect, InputText, Loading } from '@opidcore/components';
import Grid, { GridContext, GridItem } from '@opidcore/components/Grid';
import { APP } from '../../../../js';
import moment from 'moment';

const PriceForecast = ({contractType, contractId, contractFriendlyId, contractData, futureForecasts = undefined, afterUpdate = undefined}) =>{
	const gridContext = useContext(GridContext);
	const [activities, setActivites] = useState(contractData[0].allActivities);
	const [materials, setMaterials] = useState(contractData[0].allMaterials);
	const gridId = "price-forecast" + contractId;

	if(contractData[0] == undefined){
		return <Loading />;
	}

	const clearActivitiesAndMaterials = (boundTo, field, val, ctx) => {
		ctx.magicalSet("activities", null);
		ctx.magicalSet("materials", null);
	}

	let applicableToIds = [{key: contractType + "|" + contractId, value: "Contract"}];

	_.forEach(contractData[0].contractLines, (line)=>{
		let description = line.service.friendlyId;
		if(line.service.material != null && line.service.material > 0){
			description += " " + line.service.shortDescription;
		}
		
		applicableToIds.push({key: contractType + "Line|" + line.id, value: description});
	});

	applicableToIds = _.uniqBy(applicableToIds, "key");

	const saveList = (e)=>{
		const values = _.values(gridContext.grids);
		let index = 0;

		const complete = ()=>{
			for(let i=0; i<values.length;i++){
				if(values != undefined && values[i] != undefined){
					values[i].resetAllBoundDeltas();
				}
			}

			if(afterUpdate != undefined && typeof afterUpdate == "function"){
				afterUpdate();
			}
		}

		(function saveDeltas(){
			if(index < values.length){
				const gridCtx = values[index];
				const deltas = gridCtx.getAllBoundDeltas();

				if(deltas.length > 0){
					APP.central.ContractLineIncrease.saveDeltas(deltas).then(()=>{
						index++;
						saveDeltas();
					})
				}
			} else {
				complete();
			}
		}());
	};

	const handleCellAction = (e, whatWasClicked, gridCtx, sourceAction)=>{
		const modelName = "ContractLineIncrease";
		const date = moment().format("YYYY-MM-DD");
		let toAction = "Create";

		APP.central.Grid.fetchGrid( modelName, toAction, {defaults: {relatedId: contractId, relatedType: contractType, effectiveDate: date}} ).then((r)=>{
			let res = r.result.rows[0][modelName];
			if(toAction == "Create"){
				if( Array.isArray(res) && res.length > 0 ){
					res = res[0];
				}
				gridCtx.setData(res, gridCtx.sortedData.length);
			} else {
				console.log("PriceForecast.js: not setup");
			}		
		});
	}

	let warnings = [];

	if(futureForecasts != undefined && futureForecasts.length <= 0){
		warnings.push(<span key="future_warning">No price increases scheduled.</span>);
	}

	const gridProps = {
        key: "price-forecast-grid" + contractId,
        gridIdentifier: gridId,
        data: contractData[0].forecasts,
        rowBasis: "id",
        appendable: {text: "add a change", add: null},
        onCellAction: handleCellAction,
		orderBy: ["relatedType", "effectiveDate desc"],
		disableHoleActions: true
    }

    return <div key="price-forecast" className="price-forecast">
		<h4>{Util.capitalizeWords(contractType, "_") + " #" + (contractFriendlyId || contractId)}</h4>
		<div className="warnings">
			{warnings}
		</div>
		<BoundCommit initialValue={contractData[0]} commit={saveList}>
			<Grid {...gridProps}>
				<GridItem title="Effective Date"><DatePicker field="effectiveDate"/></GridItem>
				<GridItem title="Client Increase"><InputDecimal field="clientIncreaseValue"/></GridItem>
				<GridItem title="Client Increase Type"><InputSelect field="clientIncreaseType" options={[{key: "unit_price", value: "Unit Price"}, {key: "percent", value: "Percent"}, {key: "needs_review", value: "Needs Review"}]}/></GridItem>
				{contractType.indexOf("Vendor") >= 0? <GridItem title="Vendor Increase"><InputDecimal field="vendorIncreaseValue"/></GridItem> : null}
				{contractType.indexOf("Vendor") >= 0? <GridItem title="Vendor Increase Type"><InputSelect field="vendorIncreaseType" options={[{key: "unit_price", value: "Unit Price"}, {key: "percent", value: "Percent"}, {key: "needs_review", value: "Needs Review"}]}/></GridItem> : null}
				<GridItem title="Baseline Increase"><InputDecimal field="baselineIncreaseValue"/></GridItem>
				<GridItem title="Baseline Increase Type"><InputSelect field="baselineIncreaseType" options={[{key: "unit_price", value: "Unit Price"}, {key: "percent", value: "Percent"}, {key: "needs_review", value: "Needs Review"}]}/></GridItem>
				<GridItem title="Notes"><InputText field="notes"/></GridItem>
				<GridItem title="Applicable To"><InputSelect field="applicableTo" options={applicableToIds} onChange={(boundTo, field, val, ctx)=>clearActivitiesAndMaterials(boundTo, field, val, ctx)}/></GridItem>
				<GridItem title="Activities">
					<InputMultiCheckbox field="activities" optionsCollection={activities} optionKey="activity" storeAs="string" grouped={true}>
						{(row, magic) =>{
							if(magic.magicalGet("applicableTo").indexOf("Contract") > -1){
								return <div className="tile-item">{row.activity}</div>
							} else {
								//check if activity in local contractline
								if(_.find(contractData[0].activities, {activity: row.activity}) != undefined){
									return <div className="tile-item">{row.activity}</div>
								}
							}
						}}
					</InputMultiCheckbox>
				</GridItem>
				<GridItem title="Materials">
					<InputMultiCheckbox field="materials" optionsCollection={materials} optionKey="name" storeAs="string" grouped={true}>
						{(row, magic) =>{
							if(magic.magicalGet("applicableTo").indexOf("Contract") > -1){
								return <div className="tile-item">{row.name}</div>
							} else {
								//check if material in local contractline
								if(_.find(contractData[0].materials, {name: row.name}) != undefined){
									return <div className="tile-item">{row.name}</div>
								}
							}
						}}
					</InputMultiCheckbox>
				</GridItem>
				<GridItem title="Status">
					<InputText readOnly={true} field="status"/>
				</GridItem>
			</Grid>
		</BoundCommit>
    </div>
}

PriceForecast.propTypes = {
	contractType: PropTypes.string,
    contractId: PropTypes.number
}

export default PriceForecast;