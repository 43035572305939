import React from 'react';
import Util from '@opidcore/Util.js';


	
function Welcome(props) {
  return <h2>Hello, {props.name}</h2>;
}

export default function Funky(props) {
	
  return (
    <div>
    	<h1>{props.something} {Util.currency(300.45)}</h1>
      <Welcome name="Ian" />
      <Welcome name="Cody" />
      <Welcome name="Jack" />    	  
      <Welcome name="Michael" />
     </div>
  );
}
Funky.getEditableProps = ({})=>{
  	        
        return [
            {niceName: "Something Snazzy", prop: "something", type: "text" }
        ]
        
};
