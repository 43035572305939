import React, { Component } from 'react';
import templated from './Templated.jsx';
import TableOfContentsRow from './TableOfContentsRow.jsx';

export default class ListOfPageNumbers extends Component {
    constructor(props) {
        super(props);
    }
    
    static metaInfo() {
        return {regenerate: true};
    }
    
    static getEditableProps(){
        const currentNDTScope = _.map(APP.NDT.perusableScopes, (s)=>{ return {key: s, value: s} });
      
        return [
            {niceName: "Title", prop: "title", type: "text", default : "Title Here"},
            {niceName: "Scope", prop: "selectedScope", type: "list", options: currentNDTScope, default : ""}
        ];        
    }
    
    render(){
        const title = this.props.title;
        const rows = _.sortBy(_.map(APP.NDT.scope[this.props.selectedScope], (val, key)=>{ 
            let tempId = val.injectedTo.injectionId;
            let node = APP.NDT.getNode(tempId);
            
            if(node == undefined){
                node = val.node;
            }
            
            if(node == undefined){
                node = APP.NDT.getNode(key);
            }
            
            if(tempId == undefined){
                tempId = key;
            }
            
            return {id: tempId, value: val.value + "", node: node, position: APP.NDT.getMyDOMPosition(tempId)};
        }), (el)=>{           
            if(isNaN(el.value)){
                return -1;
            } 
            
            if((el.value == undefined || el.value <= 0) && el.position.y > 0){
                return el.position;
            }
            return parseInt(el.value); 
        });
        
        const content = _.map(rows, (row, index)=>{
            let page = APP.NDT.scope._pages[row.id];
            let rootStructure = null;

            
            if(row.node != undefined){
                rootStructure = row.node.rmsStruct;
            } else {
                rootStructure = APP.NDT.find(row.id);
            }
            const theRMS = APP.NDT.getNode(row.id);

            if(page == undefined){
            	if (theRMS && theRMS.element){
	            	let parent = theRMS.element.parentElement;
	                while (page == undefined && parent != undefined ){
	                	if ( APP.NDT.scope._pages[ parent.getAttribute("data-uid") ]){
	                		page = APP.NDT.scope._pages[ parent.getAttribute("data-uid") ];
	                	}
	                	
	                	parent = parent.parentElement;
	                }
            	}
         
                
                page = {structure: rootStructure, pageNumber: page ? page.pageNumber : "-/-"};
                
            }
            
            let title = "";
            if(rootStructure != undefined && rootStructure != undefined){
                title = templated(rootStructure.options.title || rootStructure.options.content || rootStructure.options.graphTitle, row.node || this);
            }
            
            const idNumber = "";
        
            return <TableOfContentsRow key={"tocRow"+index} elementId={row.id} identificationNumber={idNumber} title={title} pageNumber={page? page.pageNumber : ""} node={row.node}/>;
        });
        
        return <div id="table_of_contents">
            <h2>{title}</h2>
            {content}
        </div>
    }
};
