import React, { useState }  from 'react';
import { useNavBar } from '@opidcore/hooks/WTF';

export default function Tabbed(props){
	const [tab, setTab] = useState(props.defaultTab || 0);
	const inlineTabs = props.inline == true;
	
	let children = props.children;
	if(!Array.isArray(children)){
	    children = [children];
	}
	
	const tabs = _.map( children, (c)=>{ return {key: c.key, title: c.props.title } } );

	const tabEls = _.map( tabs, (t, i)=>{
		return <span key={t.key} onClick={()=>{ setTab(i); }}>{t.title}</span>
	});

	if (inlineTabs == false){
		const nav = useNavBar(props.title, 
					tabEls,
					{selectedItem: tab}			
				); 
		return <div className={props.className} data-tab={tab}>{children[tab]}</div>;

	}


	return <div>
		<div className='nav-items' data-active={tab}>
			{_.map(tabEls, (l, i) => {
				return <div className={"nav-item " + (i == tab ? "nav-item-active" : "")} onMouseUp={(e) => setTab(i)} key={'tab-el' + i} >{l}</div>
			})}
		</div>
		<div className={props.className} data-tab={tab}>{children[tab]}</div>
	</div>
	;
}