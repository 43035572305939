import * as types from '../constants/ActionTypes';

export function sendMessage(msg) {
  return {
    type: "WS_SEND_MESSAGE",
    msg
  };
}

export function announceTyping(toPersonId) {
  return {
    type: "WS_ANNONCE_TYPING",
    toPersonId
  };
}

export function receivedMessage(msg) {
  return {
    type: "WS_RECEIVED_MESSAGE",
    msg
  };
}

