import React, { Component, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link, Route, Router, Switch, useHistory, useLocation } from 'react-router-dom';

import { createMemoryHistory } from 'history';

import { Button, Icon, Loading } from '@opidcore/components';
import _ from 'lodash';
import NeilDegrasseTyson from '../components/Content/NeilDegrasseTyson.jsx';

export const OpidSession = React.createContext({});
export const CurrentModal = React.createContext({id: 0});
export const RoutedDefaults = React.createContext({});
export const DataCache = React.createContext({});

function ModalWrapper({modal, maximizedTarget}){
	const minMe = <span className="minimized" key={modal.id} onClick={()=>window.APP.instance.openModal(modal.id)} title={modal.title}>
		{modal.label} 
		<Icon icon="times" onClick={(e)=>window.APP.instance.closeModal(modal.id, e)}/>
	</span>;

	modal.minimizedForm = minMe;

	if (modal.status == 'maximized'){
		return ReactDOM.createPortal(modal.region, maximizedTarget.current);
	}

	return modal.region;
}
function OpidRedirect(props){
	  const history = useHistory();
	  
	  useEffect( ()=>{
		  history.push(props.to);
	  }, []);
	  
	  return null;
}

export class WebSession {
    constructor(loginInfo){
	    	if (! loginInfo){
	    		   APP.central.TimedTokens.isLoggedIn().then((r)=>{
	    			   if (r.result != null){
		    	        	_.forEach(r.result.result, (val, key)=>{
		    	        		this[key] = val;
		    	        	});
	    			   }
	    			   
	    			   APP.triggerState("webSession");
	    	        });
	    		   
	    	} else {
	    		_.forEach(loginInfo, (val, key)=>{
	        		this[key] = val;
	        	});
	    		APP.setState("webSession", this);
	    	}
	    	
	    	window.onLogin && window.onLogin();
    }

    permissions(resource){
			const access = this.access(resource);
      if (access == false){
        return { r: false, w: false, x: false };
      }
      return (access == "R" ? { r: true, w: false, x: false } : { r: true, w: true, x: true });
    }
    
    access(resource){
    	if (this.acl_resources == null){
    		return false;
    	}
    	
    	if (this.acl_resources[resource] >= ""){
			return this.acl_resources[resource];
		  }

		  return false;
    }

	  group(group){
    	if (this.acl_groups == null){
    		return false;
    	}
    	
    	return this.acl_groups.indexOf(group) >= 0;
    }
    
    getFullName(){
        return this.fullname;
    }
    
    getRandomNumber(){
        return Math.random();
    }
    
    setSubNavItems(items){
    	this.navItems = _.cloneDeep(items);
    	APP.instance.forceOnEveryone(APP.getWebSession());
    }
    
    getSubNavItems(){
    	return this.navItems;
    }
    
    isLoggedIn(){
    	if(this.token != undefined){
    		return true;
    	} 
    	
    	return false;
    }
}




export class SubNavState {
	constructor(setter){
		this.setter = setter;
	}
	
	setTitle(title){
		this.title = title;
		window.IAN_DOES_NOT_UNDERSTAND_HOOKS = title;
		if(this.setter)
			this.setter(_.cloneDeep(this));
	}
	
	set(items, reset){
		if (_.isEqual(this.items, items)){
			return;
		}
		
		this.items = items;
		
		if (reset){
			this.setActive && this.setActive(0);
		}
		
		if(this.setter){
			this.setter(_.cloneDeep(this));
		}
		
	}
}

export const SubNavContext = React.createContext();


export class OpidRouter extends Router {
	componentDidMount(){
		var that = this;
		
		this.props.history._push = this.props.history.push;
		this.props.history._router = this;
		this.props.history.listen((a)=>{ this.go(a) });
		
		if (APP.regions == null){
			APP.regions = {};
		}
		
		APP.regions[ this.props.name ] = this;
	}
	
	go(loc){
		this.setState({location: loc});
	}
	
	navigateTo(path){
		if (this.props.history.location.pathname != path){
			this.props.history.push(path);
			this.go(this.props.history.location);
		}
	}
}

export function RoutableRegion(props){
	
	//wtf.props.history.push("/login")
	//wtf.setState({location: wtf.props.history.location})
	
	//const [history, setHistory] = React.useState(() => createMemoryHistory());

	return <div className={props.className}>
		<OpidRouter history={props.memory} name={props.name}>
			<Switch>
				{props.routes}
			</Switch>
		</OpidRouter>
	</div>
}

export function RouteWatcher() {
	  let location = useLocation();

	  useEffect(
	    () => {
	      //ga.send(['pageview', location.pathname])
	    	window.APP.locations.push(location);
	        window.scrollTo(0, 0);

	    	if (window.APP.instance){
	    		const mySession = window.APP.instance.state.webSession;
	    	
	    		if (mySession && mySession.userId > 0){
	    			const activity = {"resourcePath": location.pathname, "activity": "page", user: mySession.userId};
	    			if (APP.customize.form && APP.customize.form.id){
	    				activity.instance = APP.customize.form.id;
	    			}
	    			//APP.instance.socketSend(activity); Cesar did this to stop the spam in activity_ log, hopefully it didn't break anything	    			
	    			
	    		}
	    	}
	    	
	        
	    },
	    [location]
	  );
	 
	  return null; //<span>I'm watching you: {location.pathname}</span>;	  
}

function ConfirmDialog(props){
	if(!props.open){
		return null;
	}

	let buttons = [];

	if(props.onConfirm == undefined){
		buttons.push( <Button key="cancel" onClick={()=>{ props.setOpen(false); }}>Ok</Button> );
	} else if(props.onConfirm.length == 0){
		buttons.push( <Button key="cancel" onClick={()=>{ props.setOpen(false); props.onConfirm(); }}>Ok</Button> );
	}else {
		buttons.push( <Button key="ok" onClick={()=>{ props.setOpen(false); props.onConfirm(true); }}>OK</Button> );
		buttons.push( <Button key="cancel" onClick={()=>{ props.setOpen(false); props.onConfirm(false); }}>Cancel</Button> );
	}
	
	return <div id="confirm_dialog">
		<div className="content">
			<h4 className="title">{props.title}</h4>
			<div className="dialog">{props.children}</div>
			<div className="actions">
				{buttons}
			</div>
		</div>
	</div>
}

const ModalComponent = ({modalHeadingSetter, modalId, extra, thisModal, routes, maxFn, minFn, closeFn, heading = ""}) =>{
	const [localHeading, setLocalHeading] = useState(heading);

	useEffect(()=>{
		modalHeadingSetter(setLocalHeading);
	}, []);

	return <div key={"modal_region_" + modalId} className={"modal_region " + modalId + (extra && extra.className ? " " + extra.className : "")}>
		<div key="modal_actions" className="modal_actions">
			<span className="modal-heading">{localHeading}</span>
			<div className="window-actions">
				<Icon icon="window-maximize" onClick={()=>maxFn(modalId)}/>
				<Icon icon="minus" onClick={()=>minFn(modalId)}/>
				<Icon icon="times" onClick={()=>closeFn(modalId)}/>
			</div>
		</div>
		<CurrentModal.Provider value={thisModal}>
			<RoutedDefaults.Provider value={extra}>
				<RoutableRegion key="modal_routes" name={modalId} routes={routes} memory={thisModal.memory}/>
			</RoutedDefaults.Provider>
		</CurrentModal.Provider>
	</div>
}

export class OpidApplicationBase extends Component {	  
	constructor(){
		super();
		this.state = {webSession: null, modals: [], menuOpen: true, confirmOpen: false, confirmTitle: "", confirmDialog: "", confirmCallback: undefined };
		APP.instance = this;
		
		APP.NDT = new NeilDegrasseTyson({realRoot: true}, "APP");
		
		this.socket = new WebSocket( APIHOST.replace("http", "ws") + "/api/v1/ping");
		this.socket.onmessage = (m)=>this.onSocketMessage(m);
		APP.socket = this.socket;
		
		this.socketCheck = setInterval(() => {
			if(APP.socket.readyState != WebSocket.OPEN && APP.socket.readyState != WebSocket.CONNECTING){
				console.log("Trying to reconnect...");
				var newSocket = new WebSocket( APIHOST.replace("http", "ws") + "/api/v1/ping");
				//newSocket.reactParent = window.APP.socket.reactParent;
				newSocket.onmessage = (m)=>this.onSocketMessage(m); //APP.onSocketMessage;
				window.APP.socket = newSocket;
			}else{
				
			}
		}, 5000);
	}

	isMobile(){
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	}
	
	socketSend(json){
		if(window.APP.socket.readyState == WebSocket.OPEN){
			var payload = json;
			if (typeof payload == "object"){
				payload = JSON.stringify(json);
			}
			window.APP.socket.send(payload);
		}else{
			console.log("can't socket this!");
		}
	}
	
	onSocketMessage(msg) {
		const result = JSON.parse(msg.data);
		console.log(" socket result ", result);

		if (result && result.__socket == "HandshakeEvent") {
			const session = result.session;
			if (this.state.webSession && this.state.webSession.role){
				this.socketSend({__socket: "HandshakeEvent", message: this.state.webSession.token});
			}
			APP.getState("webSession", (ws) => {
				if (ws && ws.role) {
					this.socketSend({__socket: "HandshakeEvent", message: ws.token});
				}
			});
		}else if (result && result.message) {
			try {
				const realResult = JSON.parse(result.message);

				if (realResult.task == "download") {
					if (this.lastLoading) {
						this.closeModal(this.lastLoading);
					}
					window.open("/result/" + realResult.uid, "_blank")
				}

				if(realResult.task == "data"){
					console.log("downloaded background data");
				}

				if (realResult._taskProgress) {
					console.log("_taskProgress was true");
				}

				APP.handleSocket && APP.handleSocket(realResult);
			} catch (e) {
				console.log(" could not parse", result);
				APP.handleSocket;
			}
		}
	}

	getPrintFooter(){
		return null;
	}
	
	getFooterChildren(){
		return null;
	}
	
	componentDidMount(){
		APP.setState("webSession", new WebSession(), ( webSession )=>{
			this.setState({webSession: webSession});
		});
		
		APP.setState("redirect", null, ( path )=>{
			this.setState({redirect: path});
		});
		
		APP.getState("menus", ()=>{ this.setState({menusUpdated: new Date().getTime()})})
	}
	
	handleClick(){
		this.props.actions.alogout();
	}

	showLoading(props){
		this.lastLoading = this.createModal(<Loading {...props}/>, {what: 1,className:"small-modal"} );
	}
	
	openMenu(){
		if (this.state.menuOpen != true){	
			this.setState({menuOpen: true}); 
		}
		
		document.body.className = document.body.className.replaceAll(" collapsed", "");
	}
	
	collapseMenu(){
		if (this.state.menuOpen){
			this.setState({menuOpen: false});
		}
		if (document.body.className.indexOf("collapsed") == -1){
			document.body.className = document.body.className + " collapsed";
		}
	}
	renderMenu(){
		
	}
	logoSrc(){
		
	}
	clearErrors(){
		APP.store.dispatch({type: 'CENTRAL_CLEAR_STATE', path: 'errors'});
	}
	
	getModal(id){
		if(id == undefined){
			return this.state.modals;
		}
		
		return _.filter(this.state.modals, {id: id});
	}

	isModalOpen(){
		return _.find( this.keepMyModalsAlive, (m)=>m.current.props.modal.status == 'open' ) != null;
	}
	
	clearModals(){
		this.keepMyModalsAlive = [];
		APP.regions = {};
		this.modalHeadingSetters = {};
		APP._MODAL_COUNT = 0;
		this.setState({modals: []});
	}

	closeThisModal(id, event, promiseEvent){
		if(event){
			event.stopPropagation();
		}

		try{
			const dependsOn = _.map( _.compact( _.map( APP.instance.state.modals, (rm)=>rm.props.modal.options.dependantOn ) ), (m)=>m.id )
			if (dependsOn.indexOf(id) >= 0){
				APP.toast("Can't close this modal!")
				return;
			}
		}catch(e){ /** who knows what we will have in that hash */}

		if(id == undefined){
			console.log("Unable to find modal id. Cancelled closeModal actions.");
			return;
		}

		const toDelete = []
		
		_.forEach(this.keepMyModalsAlive, (modalWrapper, index)=>{
			if(modalWrapper.current.props.modal.id == id || modalWrapper.current.props.modal.label == id){
				toDelete.push(index);
			}
			
			if (modalWrapper.current.props.modal.options && modalWrapper.current.props.modal.options.afterClose){
				console.log("calling modal " + id + " afterClose");
				modalWrapper.current.props.modal.options.afterClose(modalWrapper);
			}
		});

		_.forEach(toDelete, (index) => {
			delete this.keepMyModalsAlive[index];
		});

		this.keepMyModalsAlive = _.compact(this.keepMyModalsAlive);

		delete APP.regions[id];
		delete this.modalHeadingSetters[id];

		APP._MODAL_COUNT -= toDelete.length;
		
		this.setState({modals: this.keepMyModalsAlive.map( (m)=>m.current )}, ()=>{
			promiseEvent && promiseEvent();
		});		
	}

	closeModalByTitle(title, event, promiseEvent) {
		if(event){
			event.stopPropagation();
		}

		try{
			const dependsOn = _.map( _.compact( _.map( APP.instance.state.modals, (rm)=>rm.props.modal.options.dependantOn ) ), (m)=>m.id )
			if (dependsOn.indexOf(title) >= 0){
				APP.toast("Can't close this modal!")
				return;
			}
		}catch(e){ /** who knows what we will have in that hash */}

		if(title == undefined){
			console.log("Unable to find modal id. Cancelled closeModal actions.");
			return;
		}

		const toDelete = []
		
		_.forEach(this.keepMyModalsAlive, (modalWrapper, index)=>{
			if(modalWrapper.current.props.modal.title == title || modalWrapper.current.props.modal.label == title){
				toDelete.push(index);
			}
			
			if (modalWrapper.current.props.modal.options && modalWrapper.current.props.modal.options.afterClose){
				console.log("calling modal " + title + " afterClose");
				modalWrapper.current.props.modal.options.afterClose(modalWrapper);
			}
		});

		_.forEach(toDelete, (index) => {
			delete this.keepMyModalsAlive[index];
		});

		this.keepMyModalsAlive = _.compact(this.keepMyModalsAlive);

		delete APP.regions[title];
		delete this.modalHeadingSetters[title];

		APP._MODAL_COUNT -= toDelete.length;
		
		this.setState({modals: this.keepMyModalsAlive.map( (m)=>m.current )}, ()=>{
			promiseEvent && promiseEvent();
		});		
	}
	
	closeModal(id, event, promiseEvent){
		if(this.state && this.state.modals && this.state.modals[0] 
			&& this.state.modals[0].props && this.state.modals[0].props.modal 
			&& this.state.modals[0].props.modal.options && this.state.modals[0].props.modal.options["newCase"]){
				APP.confirm("Closing the modal will save the new case. Are you sure you want to close the modal? ", (res) => {
					if(res == true){
						this.closeThisModal(id,event,promiseEvent); 
					}
				});
			}
		else if(this.state && this.state.modals && this.state.modals[0] 
			&& this.state.modals[0].props && this.state.modals[0].props.modal 
			&& this.state.modals[0].props.modal.options && this.state.modals[0].props.modal.options["changesNotSaved"]){
				APP.confirm("Are you sure you want to close the modal without saving the changes? ", (res) => {
					if(res == true){
						this.closeThisModal(id,event,promiseEvent); 
					}
				});
		}
		else{
			this.closeThisModal(id,event,promiseEvent); 
		}		
	}
	
	createModal(val, extra, options){
		let existingModalForUrl = _.filter(this.state.modals, {path: val}); //for path based modals

		if(existingModalForUrl.length == 0 && extra != undefined){
			//check if component val model exists
			existingModalForUrl = _.filter(this.keepMyModalsAlive, (modal)=>{ return modal.current.props.modal.label == extra.modal_name })
		}

		if(existingModalForUrl.length > 0){
			this.openModal(existingModalForUrl[0].id || existingModalForUrl[0].current.props.modal.id);
			return;
		}
		
		if (APP._MODAL_COUNT == null){
			APP._MODAL_COUNT = 0;
		}
		APP._MODAL_COUNT++
		let path = val;
		const modalId = "modal_" + (APP._MODAL_COUNT);
		
		let routes = [];
		
		const flattenedPropRoutes = _.flatten(this.props.routes);
			
		_.forEach(flattenedPropRoutes, (route, index)=>{
			if(route.props != undefined && route.props.path != undefined){
				routes.push(<Route exact={route.props.exact? true : false} path={route.props.path} extra={extra} render={route.props.render} component={route.props.component} key={modalId + "_" + index} />);
			}
		});

		let modalName = modalId;
		if(extra && extra.modal_name){
			modalName = extra.modal_name;
		}
		const title = modalName + "";
		if(modalName.length > 20){
			modalName = modalName.substring(0, 20) + "...";
		}
		
		const thisModal = {id: modalId, label: modalName, title: title, status: "open", path: path, extra: extra, options: options, memory: createMemoryHistory()};
		if (extra){
			delete extra.modal_name;	
		}
				
		if(typeof val == "string"){
			//we don't need to do anything
		} else {
			path = "/temp/" + modalId;
			thisModal.path = path;
			const newRoute = <Route path={path} key={modalId + "_new"}>
					{val}
			</Route>;
			routes.push(newRoute);
		}
		if (this.keepMyModalsAlive  == null){
			this.keepMyModalsAlive = [];
		}

		if(this.modalHeadingSetters == undefined){
			this.modalHeadingSetters = {};
		}

		const modalRegion = <ModalComponent modalHeadingSetter={(setter)=>{ this.modalHeadingSetters[modalId] = setter}} modalId={modalId} thisModal={thisModal} heading={""} maxFn={(modalId)=>this.maximizeModal(modalId)} minFn={(modalId)=>this.minimizeModal(modalId)} closeFn={(modalId)=>this.closeModal(modalId)} extra={extra} routes={routes}/>;
		thisModal.region = modalRegion;
        thisModal.defaultPath = path;
		
		const newModal = React.createRef();
		newModal.current = <ModalWrapper maximizedTarget={this.props.portalThing} modal={thisModal} key={thisModal.id}/>;
		this.keepMyModalsAlive.push(newModal);

		this.setState({modals: this.keepMyModalsAlive.map( (m)=>m.current )}, ()=>{
			this.activateModalEvents();
			if (APP.regions[modalId]){
				APP.regions[modalId].navigateTo(path);
			}
		});
		
		return modalId;
	}
	
	openModal(modalId){
		let modalIndex = _.findIndex(this.state.modals, (modalWrapper)=>{ return modalWrapper.props.modal.id == modalId || modalWrapper.props.modal.label == modalId});

		this.keepMyModalsAlive[modalIndex].current.props.modal.status = "open";
		const path = this.keepMyModalsAlive[modalIndex].current.props.modal.path;
		
		this.setState({modals: this.keepMyModalsAlive.map( (m)=>m.current )}, ()=>{
			if(path){
				this.activateModalEvents();
				//easiest fix ever?
				//APP.regions[modalId].navigateTo(path);
			}
		});
	}

	crazyRouteMatcher(matchingEntities, chunk, matchBy){
		let res = "";
		if(chunk == undefined || chunk == ""){
			return res;
		}

		const test1 = chunk.match(/\/[0-9]+/g);
		const test2 = chunk.match(/[0-9]+\//g);
		if(test1 != null || test2 != null){
			//if '###/' or '/###' then add the number
			res += chunk;
			return res;
		}

		const match = _.find(matchingEntities, (me)=>{
			if(matchBy != undefined){
				return me[matchBy].indexOf(chunk) > -1;
			}

			return me.indexOf(chunk) > -1;
		});

		if(match != undefined){
			res += chunk;
			return res;
		}

		let lastSlashFirstHalf = chunk.substring(0, Math.floor(chunk.length/2)+1).lastIndexOf("/");

		const splitChunks = [chunk.substring(0, lastSlashFirstHalf), chunk.substring(lastSlashFirstHalf)];

		if(splitChunks[0] == chunk || splitChunks[1] == chunk){
			return res;
		}		

		const r1 = this.crazyRouteMatcher(matchingEntities, splitChunks[0], matchBy);
		const r2 = this.crazyRouteMatcher(matchingEntities, splitChunks[1], matchBy);

		if(r1.length > 0){
			res += r1;
		}

		if(r2.length > 0){
			res += r2;
		}

		return res;
	}

	maximizeModal(modalId){
		if(this.isMobile()){
			console.log("maximize is unsupported in mobile");
			return;
		}

		let modalIndex = _.findIndex(this.state.modals, (modalWrapper)=>{ return modalWrapper.props.modal.id == modalId || modalWrapper.props.modal.label == modalId});
		const path = this.keepMyModalsAlive[modalIndex].current.props.modal.path;

		//check if route exists
		let ourRoute = _.find(window.APP.customRoutes, {path: path});

		if(ourRoute == undefined){
			//this is gross
			ourRoute = this.crazyRouteMatcher(window.APP.customRoutes, path, "path");
		}

		if(ourRoute == undefined || ourRoute == ""){
			//TODO: make maximize work
			this.keepMyModalsAlive[modalIndex].current.props.modal.status = "maximized";

			this.setState({modals: this.keepMyModalsAlive.map( (m)=>m.current )}, ()=>{
				if(path){
					APP.regions[modalId].navigateTo(path);
				}
			});
		} else {
			//open in new tab
			this.closeModal(modalId, null, ()=>window.open(path, '_blank').focus());
		}
	}
	
	minimizeModal(modalId){
		let modalIndex = _.findIndex(this.state.modals, (modalWrapper)=>{ return modalWrapper.props.modal.id == modalId || modalWrapper.props.modal.label == modalId});
		
		if (this.keepMyModalsAlive[modalIndex] == null){
			return;
		}

		this.keepMyModalsAlive[modalIndex].current.props.modal.status = "minimized";
		
		if (this.keepMyModalsAlive[modalIndex].current.props.modal.options && this.keepMyModalsAlive[modalIndex].current.props.modal.options.afterClose){
			console.log("calling modal " + modalId + " afterClose");
			this.keepMyModalsAlive[modalIndex].current.props.modal.options.afterClose(this.keepMyModalsAlive[modalIndex].current);
		}
		
		this.setState({modals: this.keepMyModalsAlive.map( (m)=>m.current )});
	}
	
	minimizeAllModals(){
		_.forEach(this.keepMyModalsAlive, (modalWrapper)=>{
			modalWrapper.current.props.modal.status = "minimized";
		});
		
		this.setState({modals: this.keepMyModalsAlive.map( (m)=>m.current )});
	}
	
	activateModalEvents(){
		var entireModalRegion = document.getElementById('modal_active');
		var modalBoxes = document.getElementsByClassName('modal_region');
		
		entireModalRegion.addEventListener("mousedown", (e)=>{
			let found = false;
			_.forEach(modalBoxes, (modal)=>{
				if (modal.contains(e.target)){
					found = true;
				}
			});
			
			if(!found){
		 		this.minimizeAllModals();
		 	}
		}, false);
	}
	
	openConfirmDialog(dialog, title, callback){
		const newConfirmState = {};
		newConfirmState.confirmOpen = true;
		newConfirmState.confirmTitle = title;
		newConfirmState.confirmDialog = dialog;
		newConfirmState.confirmCallback = callback;
		
		this.setState(newConfirmState);
	}
	
	render() {
		
		if (this.state.webSession == null){
			return <div className="main_loading"><h2>Loading!</h2></div>;
		}
	
		let hello = "";
		
		if (this.props.user != undefined && this.props.user.id > 0){
			hello = this.props.user.firstName;
		}
		//console.log("this: app ", this.props.user.sessionToken);
		// renderMenu is a prime candidate for SessionCustomization (this needs a proper name). 
		let menu = this.renderMenu();
		let logo = this.logoSrc();
		
		let loginText = "Sign In";
		
		if(this.props.loggedIn){
			loginText = "Sign Out";
		}		

		
		var errors = null;
		if (this.props.errors){
		
			errors = <div className="page-errors">
				<button className="close" onClick={()=>this.clearErrors()}><span>X</span></button>
				<h2>{this.props.errors.title}</h2>
				<h3>{this.props.errors.path} <span className={"request_body"}>{APP.stringify(this.props.errors.body)}</span></h3>
				<pre>{this.props.errors.details.exception}</pre>
			</div>
		}

		const memory = createMemoryHistory();
		var region1 = <div className="testing region1">
		<div className="r1_left">{APP.extras}</div>
		<RoutableRegion className="scrolly" memory={memory} name="panel1" routes={this.props.children}/>
		</div>;
		
		let redirect = null;
		
		if (this.state.redirect){
			const to = this.state.redirect;
			
			this.setState({"redirect": null});
			APP.removeState("redirect");
		
			redirect = <OpidRedirect push={true} to={to}/>;
		}


		
		const activeModals = _.filter(this.state.modals, (modalWrapper)=>{
			//catch all because this had been changed to always return true for some reason so there must have been an issue
			if(modalWrapper.props == undefined || modalWrapper.props.modal == undefined){
				return true;
			}

			return modalWrapper.props.modal.status != "minimized"; 
		});

		const minimizedModals = _.map(_.filter(this.state.modals, (modal)=>{ return modal.props.modal.status == "minimized"}), (modalWrapper) =>{
			return modalWrapper.props.modal.minimizedForm;
		});

		if (window._GLOBAL_DATA_CACHE == null){
			window._GLOBAL_DATA_CACHE = {cheese: 1};
		}
		
		return (
			<DataCache.Provider value={window._GLOBAL_DATA_CACHE}>
				<div className="outer">
					<div id="header">
						<nav className="navbar navbar-default">
							<div className="container-fluid">

								<Link className="navbar-brand" to="/">
									<img alt="Logo" src={logo} className="logo"/>
								</Link>
				
					
								<div className="navbar-header">
					
									<button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#mainNavBar" onClick={()=>this.openMenu()}>
										<span className="icon-bar"></span>
								        <span className="icon-bar"></span>
								        <span className="icon-bar"></span>                        
							        </button>
	
					        	</div>
						
					        	<div className={ (this.state.menuOpen ? "" : "collapse") + " navbar-collapse"} id="mainNavBar" ref="menu">
									<ul className="nav navbar-nav">
										{menu}									
										{/*<button className="btn btn-default navbar-btn pull-right" onClick={ () => {this.handleClick()} }>{loginText}</button> */}
									</ul>
										
									{ this.state.menuOpen != false ? 
											<a className="menu-collapse" key="collapse" onClick={()=>this.collapseMenu()}><Icon icon="arrow-to-left"/>Collapse</a> :
				 							<a className="menu-open" key="open" onClick={()=>this.openMenu()}><Icon icon="arrow-to-right"/></a>  
									}
									<span style={{display: 'none'}}>{this.state.menusUpdated}</span>
								</div>
				
				
							</div>
						</nav>
					</div>
					{errors}
					<div className="page-container-outer">
						<div className={this.state.mainContainerClass}>
							{this.props.children}
						</div>
					</div>
	
					<footer>	
						{this.getFooterChildren()}
						<span className="noprint">
							
						</span>
						<span className="onlyprint">
							{this.getPrintFooter()}
						</span>
					</footer>
					
					{redirect}
					
					{APP.showRegion1 ? region1 : null}
					<div id="modal_container">
						<div id="modal_active" className={this.state.modals.filter((m)=>m.props.modal.status == 'open').length > 0? "active" : ""}>
							{activeModals}
						</div>
						<div className="modal_minimized">
							{minimizedModals}
						</div>
					</div>
					<ConfirmDialog open={this.state.confirmOpen} setOpen={(val)=>this.setState({confirmOpen: val})} title={this.state.confirmTitle} onConfirm={this.state.confirmCallback}>
						{this.state.confirmDialog}
					</ConfirmDialog>
				</div>
			</DataCache.Provider>
			);
	}
}

