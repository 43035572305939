//import './AddFriendInput.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BoundComponent from './BoundComponent';

import * as _ from 'lodash';
import { DataContext } from './Bound';

export default class InputMultiSelect extends BoundComponent {

  static propTypes = {
    update: PropTypes.func,
    property: PropTypes.string,

  };

  static contextType = DataContext;

  constructor(props, context) {
	  super(props, context);
	  var state = {};
	  if(this.props.field == undefined || this.props.field === "passCrypt" || this.props.field === "confirmPassCrypt" ){
		  state = {
				  value: ""
		  };
	  } else {
		  if (this.props.obj instanceof Component && this.props.obj.state != null){
			  state = {
					  value: this.props.obj.state[this.props.field]
			  }
		  }else{
			  state = {
					  value: this.props.obj[this.props.field] //this.props.obj[this.props.property]
			  };
		  }
	  }
	  
	  this.handleChange = this.handleChange.bind(this);
	  
	  if (Array.isArray(this.props.options)){
		  var options = {};
		  this.props.options.forEach((opt) => 
		  	options[opt] = opt
		  );
		  state.options = options;
	  }else if (this.props.optionsCollection != null){
		  
		  state.options = _.fromPairs(this.props.optionsCollection.map((a) => [a.id, (this.props.optionLabel? a[this.props.optionLabel]: a.name)] ));
		  
		  //state.value = ;
	  }else{
		  state.options = {...this.props.options}
	  }
	  
	  this.state = state;
  }

  componentWillReceiveProps(nextProps) {
	  var nextState = {...this.state};

	  if (Array.isArray(nextProps.options)){
		  var options = {};
		  nextProps.options.forEach((opt) => 
		  	options[opt] = opt
		  );
		  nextState.options = options;
		  
		  //horrible for now
	      nextState.value = this.props.obj.state[this.props.field];  
		  
	  }else if (this.props.optionsCollection){  
		  nextState.options = _.fromPairs(this.props.optionsCollection.map((a) => [a.id, (this.props.optionLabel? a[this.props.optionLabel]: a.name)] ));
		  
		  var thisObj = this.props.obj[this.props.field];
		  if (thisObj instanceof Object){
			  nextState.value = this.props.obj[this.props.field]["id"];
		  }else{
			  nextState.value = this.props.obj[this.props.field];  
		  }
	  }else{
		  nextState.options = {...nextProps.options};
		  if(this.props.obj.state != undefined && this.props.obj.state[this.props.field] != undefined){
			  nextState.value = this.props.obj.state[this.props.field];
		  } else {
			  nextState.value = this.props.obj[this.props.field];
		  }
	  }

	  this.setState(nextState);
  }

  handleChange(e) {
	if(this.props.field != undefined ){
		
		const selectedValue = e.target.value;
		const shiftClicked = e.shiftKey;
		const allValues = Object.keys(this.state.options);
	
		let currentSelectedArray = [];
		
		if(shiftClicked && this.state.lastClicked != undefined){
			//selecting multiple
			const startIndex = allValues.indexOf(selectedValue);
			const endIndex = allValues.indexOf(this.state.lastClicked);
			
			if(startIndex < endIndex){
				currentSelectedArray = allValues.slice(startIndex, endIndex+1);
			} else {
				currentSelectedArray = allValues.slice(endIndex, startIndex+1);
			}
		} else {
			//only selected one
			currentSelectedArray.push(selectedValue)
		}
	
		if(shiftClicked && this.state.lastClicked != undefined){
			if(this.state.value.indexOf(this.state.lastClicked) > -1){
				//we are adding all selected
				currentSelectedArray = _.uniq(currentSelectedArray.concat(this.state.value));
			} else {
				//we are removing the selected
				currentSelectedArray = this.state.value.filter(function(x) { 
					return currentSelectedArray.indexOf(x) < 0;
				});
			}
		}
		
		this.setState({ value: currentSelectedArray, lastClicked: e.target.value });
		
		if(this.props.update){
			this.props.update(this.props.obj, this.props.field, currentSelectedArray);
		}

		if(this.context){
			this.context.magicalSet(this.props.field, currentSelectedArray);
		}
	} else {
		this.setState({ value: [e.target.value], lastClicked: e.target.value });
	}
    
    
  }

  render() {
    var name = this.props.name;
    if( this.props.field === "passCrypt" || this.props.field === "confirmPassCrypt" ){
    	inputType = "password";
    }
    
    const allOptions = _.keys(this.state.options).map((key)=>
    	<option key={key} value={key}>{this.state.options[key]}</option>
    ); 
   
    if  (this.props.showEmpty){
    	allOptions.unshift(<option key={null}></option>);
    }
    
    if  (this.props.appendable){
    	allOptions.push(<option key={"..."}>...</option>);
    }
    if  (this.props.hint){
    	allOptions.unshift(<option key={null} selected value="">{this.props.hint}</option>);
    }
    if  (this.state.addedOption){
    	allOptions.push(<option key={this.state.addedOption}>{this.state.addedOption}</option>);
    }
    
    var modalView = this.state.modalView;
    return (
      <div className={"field " +  (this.props.className || "col-xs-12 col-md-12") } style={this.props.style}>
     
      <label>{name} {this.renderLabelChildren()}</label>
      <select multiple={true}
      	defaultValue={this.state.value}
      	value={this.state.value}
        className={"form-control"}
        placeholder={this.props.placeholder || ""}
        onClick={this.handleChange} 
      	disabled={this.props.disabled}
		style={this.props.selectStyle}>
      	{allOptions}
      </select>
      </div>
    );
  }
}
