import { useState, useEffect, useRef, useContext } from 'react';
import { Data } from '@opidcore/Data.js';
import { CurrentModal, DataCache } from '@opidcore/components/OpidApplication';
import Util from '@opidcore/Util';
import { BoundMagic } from '@opidcore/components/Bound';
import _ from 'lodash';


export function useLocalData(_formUid, options, defaults) {
	const [rando, setRando] = useState(Math.random() * 10000000);
	return useData(_formUid + ";transient-" + rando, options, defaults);
}

/** make sure this API/hook structure makes sense to everyone
 *  talk to a human about what you want to do before  
**/
/** @param formUid - what form to select
 *  @param options - this can be a single integer or string (id of record), or a hash of params to query models with
 * @example  
 * 		const [data, ds] = useData("client", 123); // loads client 123
 * 		const [data, ds] = useData("client", {name: "Cheese Inc"}); // loads client by name
 *  	const [data, ds] = useData("client"); // loads all clients
 * *  	const [data, ds] = useData("client;cheese", {name: "Cheese Inc"}); // loads all clientsbut will cache as client;cheese instead of just client.
 * 		const [data, ds] = useData("plans", APP.central.Plan.trackablePlans, {active: 1}); // uses a central call to populate our dataset called plans
 * @returns  [ array of models, dataset ]
 */
export function useData(_formUid, options, defaults) {
	const formUid =  _formUid.split(";")[0];
	const [data, setData] = useState([]);
	const [ loadedCount, setLoadedCount ] = useState(0);
	const [ staleInterval, setStaleInterval] = useState();	
	const isSingle = typeof options == "number" || typeof options == "string";
	const [ dataUID, setDataUID ] = useState( isSingle ? Util.capitalizeWord(formUid) + ":" + options : _formUid );
	const otherData = useContext( DataCache );
	
	const dataSetRef = useRef({newId: undefined, setQueryArgs: undefined, fetchRequired: undefined, fetchIfRequired: undefined, form: undefined, loading: undefined, queryArgs: undefined, _query: undefined, filter: undefined});
	const wtf1 = useRef(-1);
	
	useEffect(()=>{
		//const thing =  {index: 1, funct: ()=>{ console.log("i got stuff " + wtf1.current) }};
		if (wtf1.current == -1){
			wtf1.current = otherData[dataUID].callback( setData, dataUID, (newDataUID)=>{ 
					otherData[newDataUID] = otherData[dataUID];
					dataSetRef.current.newId = otherData[newDataUID].filter()[0].id;
					setDataUID(dataUID) 
				} );
		}else{
			console.log("we avoided doing bad things");
		}
		
		// what happens if we have more than one?
		 if (options && (typeof options != "function")){
			if (isSingle){
				//dataSetRef.current.setQueryArgs({options: dataSetRef.current.newId ? dataSetRef.current.newId : options}, defaults);
				dataSetRef.current.setQueryArgs({options: options}, defaults);
				dataSetRef.current.fetchRequired = true;
			}else{
				dataSetRef.current.setQueryArgs({options: options}, defaults);
			}
		}else if (typeof options == "function"){
			dataSetRef.current.setQueryArgs({ apiMethod: options, ...defaults });
		}
		
		dataSetRef.current.fetchIfRequired();

		return ()=>{
			otherData[dataUID].removeCallbacks();
			wtf1.current = -1;
			if (dataUID.indexOf(";transient-") >= 0){
				delete otherData[dataUID];
			}
			//dataSetRef.current.removeCallback( setData );
			//otherData[formUid].removeCallback( setData );
		}
	},[]);

	useEffect(()=>{
		if(formUid == dataSetRef.current.form && dataSetRef.current.loading != true){
			  if (options && (typeof options != "function")){
				if (isSingle){
					//dataSetRef.current.setQueryArgs({options: dataSetRef.current.newId ? dataSetRef.current.newId : options}, defaults);
					dataSetRef.current.newId = options;
					dataSetRef.current.setQueryArgs({options: options}, defaults);
					dataSetRef.current.fetchIfRequired();
					dataSetRef.current.fetchRequired = true;
				}else{
					const newOptions = _.cloneDeep( dataSetRef.current.queryArgs.options );
					_.merge(newOptions, options);
					if ( ! _.isEqual( dataSetRef.current.queryArgs.options, newOptions ) ){
            			console.log("Ian looked at it and is should be awesome.");
						if (dataSetRef.current._query != null) {
							dataSetRef.current.setQueryArgs({options: newOptions, query: dataSetRef.current._query});
						} else {
							dataSetRef.current.setQueryArgs({options: newOptions});
						}
						dataSetRef.current.fetchIfRequired();
					}
				}
			}
		}
		
	},[options]);
	
	if (dataSetRef.current == null || dataSetRef.current.form == undefined){			
		// dataSetRef.current = new Data({form: formUid, options: options}, setData, defaults );			
		let _options = options == undefined ? {} : options;
		//put the method that was passed into the options
		if (typeof options == "function"){
			_options = { apiMethod: options, ...defaults };
		}

		if (options == "0"){
			otherData[dataUID] = null;
		}else{
			//otherData[dataUID].setQueryArgs({options: _options});
			if(otherData[dataUID] != null){
				otherData[dataUID].setQueryArgs(_options);
			}
		}
	

		if ( otherData[dataUID] == null){
			otherData[dataUID] = new Data({form: formUid, options: _options}, null, defaults ); 			
		}
		
		dataSetRef.current = otherData[dataUID]; 
	}

	let ret = null;

	if (isSingle){
		ret = _.first(dataSetRef.current.filter({friendlyId: dataSetRef.current.newId ? dataSetRef.current.newId : options}));

		if (ret == undefined){
			ret = _.first(dataSetRef.current.filter({id: dataSetRef.current.newId ? dataSetRef.current.newId : options}));
		}

		if (ret == undefined){
			ret = _.first(dataSetRef.current.filter({uid: options}));
		}
				
		if (ret == undefined){
			ret = {...defaults, __new: true};
		}

		ret.__dataUID = dataUID;
	}else{
    //IAN should probably make this better :) 
		ret = dataSetRef.current.filter();
		_.each( ret, (r)=>{
			if(r.__dataUID != undefined || typeof r == "object"){
				r.__dataUID = dataUID;
			}
		});
	}
	
	return [ret, dataSetRef.current];
}

function createNavBar(title, items, options){
	const existing = APP.getState("navbar");

	const me = {...existing};

	if (title != null){
	    me.previous = me.title + "";
		me.title = title;
	}
	if (items != null){
		me.items = items;
		me.selectedItem = 0;
	}
	if (options && options.selectedItem >= 0){
		me.selectedItem = options.selectedItem;
	}
	if (options && options.menu){
		setTimeout( ()=>{ APP.setState("currentMenu", options.menu) }, 50 );
	}
	me.rando = Math.random();
	
	me.update = (newTitle, newItems, newOptions)=>{
		APP.setState("navbar", createNavBar(newTitle, newItems, newOptions) );
		me.updateTitle(newTitle);
	}

	me.fullUpdate = (updateOptions)=>{
		APP.setState("navbar", createNavBar(updateOptions.pageTitle, updateOptions.items, updateOptions.options) );
		me.updateTitle(updateOptions.documentTitle);
	}

	me.createUpdateOptions = (documentTitle = null, pageTitle = "", newItems = null, newOptions = null) =>{
		if(documentTitle == undefined){
			documentTitle = title; //title will stay as it was
		}

		if(newItems == undefined){
			newItems = items
		}

		if(newOptions == undefined){
			newOptions = options;
		}

		return {
			pageTitle: pageTitle,
			documentTitle: documentTitle || pageTitle,
			items: newItems,
			options: newOptions
		};
	}

	me.updateTitle = (title) =>{
		const modalOpen = APP.instance.isModalOpen();

		if (modalOpen == false ){
	    	document.title = APP.getTitle() + " - " + title;
		} else {
			if(me.modalContext != undefined){
				const modalHeadingSetter = APP.instance.modalHeadingSetters[me.modalContext.id];
				if(modalHeadingSetter != undefined){
					modalHeadingSetter(title);
				}
			}
		}
	}
	
	return me;
}

export function useNavBar(title, items, options){
	let navbar = useAppState("navbar");
	const modalContext = useContext(CurrentModal);
	const modalOpen = APP.instance.isModalOpen();

	let wasNew = false;
	if (navbar == null){
		wasNew = true;
		navbar = createNavBar(title, items, options);
	}
	
	//set our title the first time
	useEffect( ()=>{
		if (modalOpen == false && (navbar.title != title || wasNew)){
			APP.setState("navbar", createNavBar(title, items, options) );
		}

		navbar.modalContext = modalContext;
	}, []);
	
	useEffect( ()=>{
		if(modalOpen == false){
			navbar.updateTitle(title);
		}
	}, [title]);

	useEffect(()=>{
		navbar.modalContext = modalContext;
	}, [modalContext]);

	if (modalOpen){
		return navbar;
	}

	if (navbar && options && (navbar.selectedItem != options.seletedItem) ){
		APP.setState("navbar", createNavBar(title, items, options) );
	}

	return navbar;	
}

function createRelatedForm(a){
    
    const existing = APP.getState("related_forms");
    const me = {...existing};

    if (a != null){
        me.forms = a;
    }
    
    me.update = (title, items, options)=>{              
        // navBarCtx.setter( createNavBar(navBarCtx, title, items, options) );
        APP.setState("related_forms", createRelatedForm(a) );
    }
    
    return me;
}

export function useRelatedForms(a){
    const forms = Array.isArray(a)? a : [a];
    let relatedForms = useAppState("related_forms");
    
    if (relatedForms == null){
        relatedForms = createRelatedForm(forms);
    }
    
    useEffect( ()=>{
        if ( !_.isEqual(relatedForms.forms, forms) ){
            APP.setState("related_forms", createRelatedForm(forms) );
        }   
    }, []);
    
    return relatedForms;  
}

export function addRelatedForms(a){
    const forms = Array.isArray(a)? a : [a];
    let relatedForms = useAppState("related_forms");
    
    if (relatedForms == null){
        relatedForms = createRelatedForm(forms);
    }
    
    const unionForms = _.uniq( relatedForms.forms.concat(forms) );

    if ( APP._state["related_forms"] == undefined || unionForms.length != relatedForms.forms.length ){
        APP.setState("related_forms", createRelatedForm(unionForms) );
    }   
    
    return relatedForms;  
}

export function useAppState(path, setValue, listener){
    return APP.setState(path, setValue, listener);
	/*useEffect(()=>{
		APP.setState(path, setValue, listener);
		
		return ()=>{
			APP.removeListener(path, listener);
		}
	});
	
	return APP.setState(path, setValue);*/
}

export function useBoundEffect(bound, callback, conditions){
    const ourBoundState = useRef({changes: 0});
    
    
    if (ourBoundState.current.lastModified != (bound.to && bound.to.__modified) || (bound.to && ourBoundState.current.changes == 0)){
        ourBoundState.current.lastModified = (bound.to && bound.to.__modified);
        ourBoundState.current.changes++;
        callback && callback();
    }
}

export function useBoundEffect2(bound, callback, conditions){
    
    useEffect(()=>{
        if (bound.to){
            callback && callback();
        }
    }, [bound.to, bound.to && bound.to.__modified]);
    
    if (true){
        //
    }
}

export function useMagic(to, parent){
	const [boundMagic, setBoundMagic] = useState(BoundMagic.create(to,null,null,parent));
	
	useEffect( ()=>{
		// debugger;		
	},[to]);

	console.log("Deprecated use the bopnd one");

	return boundMagic;
}

export function useChromeBlockProvider(){
	const [loaded, setLoaded] = useState(false);
	const [provider, setProvider] = useState(new ChromeBlockProvider({loaded: setLoaded}));

	useEffect(()=>{
        // setProvider(new ChromeBlockProvider({loaded: setLoaded}));
		provider.initialize();
    },[]);

	useEffect(()=>{
		if (loaded){
			// not sure?
		}
    });

	return provider;
}

export class ChromeBlockProvider {
	/**  tell chrome provider we are ready for args */
    ready() {
		if (window.onChromeBlockReady){
            window.onChromeBlockReady("Ready for reportArgs!");
        }
    }
	constructor({loaded}){
		this.setLoaded = loaded;
	}

	initialize = ()=>{
		console.log("hello to the java side?");
	}

	/**  background processes should be done, wait for rendering to settle */
	doneLoading = () =>{
		console.log("thanks, will render you soon then!");
		this.setLoaded(true)

		this.timeout = setTimeout( ()=>{
			APP.NDT.peekNext = ()=>{ return {print: "normal", pdfMeta: {} } };
			
			this.printNow();
		}, 500);

	}

	/**  tell chrome provider to print (instead of SNAP which cuts up into chunks) */
	printNow() {
		console.log("PRINTNOW");
	}
}