
import React, { Fragment, useEffect, useState } from "react";
import { Button } from "@opidcore/components";
import _ from "lodash";
import Select from "react-select";
import makeAnimated from "react-select/animated";

/*

  ex:
  <ReasonableFilter
    columns={[
      { column: "name", heading: "Name" },
      { column: "friendlyId", heading: "Friendly ID" },
      { column: "active", lookup: "clientStatus", default: ["true"], heading: "Active" },
    ]}
    dataset={dataset}
    setFilteredDataset={setFilteredDataset}
  />

*/

export default function ReasonableFilter({ columns, dataset, setFilteredDataset }) {
  // holds dataset so we dont do an infinite loop useEffect
  let myDataset = dataset;

  // holds modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);
  // holds filters in format: {column: value}
  const [filters, setFilters] = useState({});
  // holds filtered data
  const [filteredData, setFilteredData] = useState(myDataset);
  // holds options for dropdowns in format: {column: [{value: "value", label: "label", column: "column"}]}
  const [options, setOptions] = useState({});
  // show clear filter button
  const [showClear, setShowClear] = useState(false);
  // default options for dropdowns
  const [defaultOptions, setDefaultOptions] = useState({});
  // true if filter has been initialized once
  const [initialized, setInitialized] = useState(false);

  /******************************************************************************************/

  // call InitFilter when dataset changes
  useEffect(() => {
    if (dataset.length > 0) {
      myDataset = dataset;
      initFilter();
    }
  }, [dataset]);

  // Update filtered data when dataset changes
  useEffect(() => {
    setFilteredDataset(filteredData);
  }, [filteredData]);

  /******************************************************************************************/

  // Set default filters and fetch lookup data when dataset changes
  const initFilter = () => {
    // Check if we need to fetch lookups
    let lookups = [];
    _.forEach(columns, (column) => {
      if ("lookup" in column) {
        lookups.push(_.toString(column.lookup));
      }
    });

    // fetch lookups if needed
    if (lookups.length > 0) {
      APP.central.Lookup.fetchForNames(_.join(lookups, ",")).then((fetchForNames) => {
        finishInit(fetchForNames.result);
      });
    } else {
      finishInit();
    }
  };

  // Finish initFilter
  const finishInit = (lookupResult) => {
    let initialFilters = {};
    let newOptions = options;
    let newDefaultOptions = defaultOptions;

    if(!initialized || lookupResult){
      setInitialized(true);
      _.forEach(columns, (column) => {

        //set options for dropdowns
        if ("lookup" in column) {
          let lookupOptions = [];
          _.forEach(lookupResult, (lookup) => {
            if (lookup.name === column.lookup) {
              lookupOptions.push( { value: lookup.key, label: lookup.label, column: column.column });
            }
          });
          newOptions[column.column] = lookupOptions;

          /*
          newOptions[column.column] = lookupResult.map((lookup) => {
            return { value: lookup.key, label: lookup.key, column: column.column };
          });
          */
        } else {
          newOptions[column.column] = myDataset.map((data) => {
            return { value: data[column.column], label: data[column.column], column: column.column };
          });
        }
  
        //set default filters
        if ("default" in column) {
          if (typeof column.default === "object") {
            initialFilters[column.column] = column.default;
            newDefaultOptions[column.column] = column.default.map((def) => {
              return { value: def, label: def, column: column.column };
            });
          } else {
            initialFilters[column.column] = [column.default];
            newDefaultOptions[column.column] = { value: column.default, label: column.default, column: column.column };
          }
        }
      });
    } else {
      _.forEach(columns, (column) => {
        //set options for dropdowns
        if ("lookup" in column) {
          newOptions[column.column] = lookupResult.map((lookup) => {
            return { value: lookup.key, label: lookup.key, column: column.column };
          });
        } else {
          newOptions[column.column] = myDataset.map((data) => {
            return { value: data[column.column], label: data[column.column], column: column.column };
          });
        }
      });
      initialFilters = filters;
    }

    //remove duplicates from options
    _.forEach(Object.keys(newOptions), (key) => {
      newOptions[key] = _.uniqBy(newOptions[key], "value");
    });

    //set default options, filters, then apply the filters
    setOptions(newOptions);
    setFilters(initialFilters);
    applyFilter(initialFilters);
    
  };

  // Update filters to apply when filters change
  const handleChange = (filterChange, additionalInfo) => {
    //switch on additionalInfo.action (select-option, remove-value, clear)
    let newFilters = filters;
    let newDefaultOptions = defaultOptions;
    switch (additionalInfo.action) {
      case "select-option":
        newFilters[additionalInfo.option.column] = _.map(filterChange, "value");
        newDefaultOptions[additionalInfo.option.column] = filterChange;
        break;
      case "remove-value":
        if (filterChange.length === 0) {
          delete newFilters[additionalInfo.removedValue.column];
          delete newDefaultOptions[additionalInfo.removedValue.column];
        } else {
          newFilters[additionalInfo.removedValue.column] = _.map(filterChange, "value");
          newDefaultOptions[additionalInfo.removedValue.column] = filterChange;
        }
        break;
      case "clear":
        delete newFilters[additionalInfo.removedValues[0].column];
        delete newDefaultOptions[additionalInfo.removedValues[0].column];
        break;
      default:
        break;
    }

    // set filters
    setDefaultOptions(newDefaultOptions);
    setFilters(newFilters);
  };

  // Apply filters and set filtered data
  const applyFilter = (filtersToApply) => {
    // Apply filters to dataset
    let newFilteredData = _.filter(myDataset, (data) => {
      return _.every(Object.entries(filtersToApply), ([key, value]) => {
        return _.includes(
          _.map(value, (v) => _.toString(v)),
          _.toString(data[key])
        );
      });
    });

    setShowClear(true);
    setFilteredData(newFilteredData);
  };

  // Toggle modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Clear filters
  const clearFilter = () => {
    setDefaultOptions({});
    setFilters({});
    setShowClear(false);
    setFilteredData(myDataset);
  };

  // Custom styles for dropdowns
  const animatedComponents = makeAnimated();
  const customStyles = {
    input: (provided, state) => ({
      ...provided,
      height: 25,
      display: "flex",
      alignItems: "center",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      marginTop: 2,
    }),
  };

  /******************************************************************************************/

  return (
    <Fragment>
      <div className="cesarFilterButtons">
        <Button style={{ marginRight:"20px"}}
          onClick={() => {
            toggleModal();
          }}
     
          //style={{backgroundColor: _.isEmpty(filters) ? "#3CBAC3" : "#C9D733"}}
        >
          Filter
        </Button>
      </div>

      {isModalOpen && (
        <div className="reasonableFilterContainer">
          <div className="reasonableFilterEntryContainer">
            {columns.map(({ column, heading }) => (
              <div key={column} className="reasonableFilterEntry">
                <span>
                  <h5>{heading || column.charAt(0).toUpperCase() + column.slice(1)}</h5>
                </span>
                {/* Render Dropdown with Options */}
                <div className="reasonableFilterSelector">
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={options[column]}
                    defaultValue={defaultOptions[column]}
                    key={`my_unique_select_key__${defaultOptions[column]}`}
                    onChange={handleChange}
                    menuPosition={"fixed"}
                    styles={customStyles}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="reasonableFilterButtons">
            <Button onClick={toggleModal}>Close</Button>
            {showClear ? (
              <Button
                onClick={() => {
                  clearFilter();
                }}
              >
                Clear Filter
              </Button>
            ) : null}
            <Button onClick={() => applyFilter(filters)}>Apply</Button>
          </div>
        </div>
      )}
    </Fragment>
  );
}
