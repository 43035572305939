import React, { Component } from 'react';
import TableCell from './TableCell.jsx';
import templated from './Templated.jsx';
import { Tabular, TabularColumn } from '@opidcore/components/Tabular';
import Functioner from '../../../Functioner.js';
import RenderMeSoftly from '../RenderMeSoftly.jsx';
import {ErrorHandler} from '../..';
import Icon from '@opidcore/components/Icon.jsx';

export default class Table extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            columns: this.getColumnCount(),
            rows: this.getRowCount(),
            tableStructure: props.rootStructure.children
        };
        
        this.buildTableGrid();
    }
    
    getColumnCount(){
        let cols = 1;
        if(this.props.columns != undefined){
            if(!isNaN(this.props.columns)){
               cols = parseInt(this.props.columns); 
            } else {
                cols = this.props.columns;
            }
        }
        
        if(isNaN(cols) && typeof cols != "string"){
            cols = 1;
        }
        
        return cols;
    }
    
    getRowCount(){
        let rows = 1;
        if(this.props.rows != undefined){
            if(!isNaN(this.props.rows)){
                rows = parseInt(this.props.rows); 
            } else {
                rows = this.props.rows;
            }
        }
        
        if(isNaN(rows) && typeof rows != "string"){
            rows = 1;
        }
        
        return rows;
    }
    
    componentDidUpdate(prevProps, prevState){
        if(this.getColumnCount() != prevState.columns || this.getRowCount() != prevState.rows){
            this.buildTableGrid(this.getRowCount(), this.getColumnCount());
            this.setState({columns: this.getColumnCount(), rows: this.getRowCount(), tableStructure: this.props.rootStructure.children});
        }
    }
    
    buildTableGrid(rows, columns){
        this.childAtLocation = [];
        //fill in the grid
        if(rows == undefined){
            rows = this.state.rows;
        }
        
        if(columns == undefined){
            columns = this.state.columns;
        }
        for(let x=0; x<rows; x++){
            for(let y=0; y<columns; y++){
                if(this.childAtLocation[x] == undefined){
                    this.childAtLocation[x] = [];
                }
                this.childAtLocation[x][y] = null;
            }
        }
    }
    
    static getEditableProps(){
		var datasets = Object.keys(window.APP.NDT.datasets);
        return [
            {niceName: "Data Driven?", prop: "dataDriven", type: "list", options:[{key:"", value:""}, {key:"yes", value:"yes"}, {key:"no", value:"no"}]},
            {niceName: "Data Set", prop: "dataset", type: "text", options: datasets},
            {niceName: "Title", prop: "title", type: "text", editInPlace: true, default: "New Table"},
            {niceName: "Title Size", prop: "titleSize", type: "text", options: ["h1","h2","h3","h4"], default: "h1"},

            {niceName: "Rows", prop: "rows", type: "text", default: "1"},
            {niceName: "Columns", prop: "columns", type: "text", default: "1"},
            {niceName: "Style", prop: "cssClass", type: "style", default : ""},
            {niceName: "Hide on Table of Contents", prop: "hideTOC", type: "text", options: ["","yes"], default: ""},
            {niceName: "Filter", prop: "filter", type: "dataset", allowJS:true}
        ];
    }

	static metaInfo() {
		var allowableChildren = ["DataColumn", "TableCell"];
		return {
			allowableChildren: allowableChildren, 
            canHaveChildren: true,
            inlineChildren: {
                type: "TableCell",
                dimensions: 2
            },
			rendersChildren: true,
			icon: "table",
            showInEditorQuickAdd: true
		};
	}
    
    injectNext(){
        const struct = this.props.NDT.find(this.props.root);

        if(this.props.title == undefined || this.props.title.trim().length <= 0 || this.props.hideTOC == "yes"){
            return undefined;
        }
        
        return {
            children: null,
            cid: struct.theId + "-table",
            component: "TableCounter",
            connections: [],
            connectors: null,
            next: null,
            options: null,
            returns: null,
            theId: struct.theId + "-table",
            type: "MetaTalker",
            uid: struct.uid + (this.props.NDT.structure.length + 1)
        };
    }
    
    addRow(atIndex){
        const defaultCellProps = TableCell.defaultProps();      
        let children = _.cloneDeep(this.props.rootStructure.children);
        
        children.splice(atIndex, 0, new Array(this.getColumnCount()).fill(defaultCellProps));
        
        this.props.NDT.getEditor().queueContent(this.props.root, {rows: this.state.rows + 1}, "UPDATE_CHILDREN", children);
    }
    
    addColumn(atIndex){
        const defaultCellProps = TableCell.defaultProps();   
        
        let children = _.map(_.cloneDeep(this.props.rootStructure.children), (row)=>{
            row.splice(atIndex, 0, defaultCellProps);
            return row;
        });
        
        this.props.NDT.getEditor().queueContent(this.props.root, {columns: this.state.columns + 1}, "UPDATE_CHILDREN", children);
    }
    
    deleteRow(atIndex){
        if(this.getRowCount() > 1){            
            let children = _.cloneDeep(this.props.rootStructure.children);
            children.splice(atIndex, 1);
            
            this.props.NDT.getEditor().queueContent(this.props.root, {rows: this.state.rows - 1}, "UPDATE_CHILDREN", children);
        }
    }
    
    deleteColumn(atIndex){
        if(this.getColumnCount() > 1){            
            let children = _.map(_.cloneDeep(this.props.rootStructure.children), (row)=>{
                row.splice(atIndex, 1);
                return row;
            });
            
            this.props.NDT.getEditor().queueContent(this.props.root, {columns: this.state.columns - 1}, "UPDATE_CHILDREN", children);
        }
    }

    clickHeading(columnDefinition){
        //do something
    }
    
    generateDataDrivenTable(){
    	console.log("asking for data");
        let data = this.props.NDT.getData(this.props.dataset, this).filter();

        if(this.props.filter != undefined){
            data = this.props.filter(data);
        }
        const columnDefinitions = _.filter(this.props.children, (cd) => {return cd != null});
        let myGroupings = {}; //column + value: # of occurrences
        let columnDataToSort = [];

		var dynamicColumns = this.getColumnCount() != undefined && this.getColumnCount().split ? this.getColumnCount().split(",") : [];
        
        if (data != undefined && data.length > 0) {
            let columns = [];
            _.forEach(columnDefinitions, (cd, index)=> {
                const myStructure = this.props.NDT.find(cd.props.root);
                if(myStructure){
                    if(window.react == undefined){
                        window.react = React;
                    }
                    let data = undefined;
                    if(myStructure.options.jsEval){
                    	//console.log("this is a column", cd.props.root, myStructure.options.jsEval);
                        data = new Functioner(myStructure.options.jsEval).doEval();
                        
                        if(myStructure.options.groupBy != undefined && myStructure.options.groupBy.trim().length > 0){
                            columnDataToSort.push({id: myStructure.options.title, dataFunction: new Functioner(myStructure.options.groupBy).doEval()});
                        }
                    }
                    else {
                        data = (row)=>{ return ""};
                    }
                    
                    var children = null;

                    if (myStructure.children && myStructure.children.length > 0){
                    	var childScope = {...this.props.scope};
                    	childScope["row"] = {a:"not sure"};
                    	children = myStructure.children.map((id)=><RenderMeSoftly mode={this.props.mode} scope={childScope} root={id} structure={this.props.structure} NDT={this.props.NDT}/>);
                    }
                    
                    const style = {};
                    if (myStructure.options.width){
                    	style.width = myStructure.options.width;
                    }
                    columns.push( <TabularColumn key={this.props.root + "tabularColumn" + index} data={data} title={myStructure.options.title} className={myStructure.options.cssClass} children={children} style={style}/> );
                    
                    
                }
            });
        
            if(columns.length > 0){
                if(columnDataToSort.length > 0){
                    const orderFunctions = _.map(columnDataToSort, (cData)=>{
                        return (row)=>{ return cData.dataFunction(row); };
                    })
                    if(orderFunctions.length > 0){
                        data = _.orderBy(data, orderFunctions);
                        
                        _.forEach(data, (row)=>{
                            _.forEach(columnDataToSort, (cData)=>{
                                const keyLookup = cData.id + "" + cData.dataFunction(row);
                                if(myGroupings[keyLookup] == undefined){
                                    myGroupings[keyLookup] = 0;
                                }

                                myGroupings[keyLookup] = myGroupings[keyLookup] + 1;                          
                            })
                        });
                    }
                }
                
                return <>
                    <Tabular data={data} rowGroupings={myGroupings} className={this.props.cssClass}> 
                        {columns}
                    </Tabular>
                </>;
            }

			else if (dynamicColumns.length > 0) {
				for (var i = 0; i < dynamicColumns.length; i++) {
					columns.push(<TabularColumn key={this.props.root + "tabularColumn" + i} data={datum => datum[dynamicColumns[i]]} title={dynamicColumns[i]} />);
				}
				
				return <>
					<Tabular data={data} rowGroupings={myGroupings}> 
                        {columns}
                    </Tabular>
                        </>
			}
        }
        
        return undefined;
    }

    addButtons(tableRows){
        tableRows = _.map(tableRows, (row, rowIndex)=>{
            let rowValues = []
            
            _.forEach(row, (col, colIndex)=>{
                if(col != undefined){
                    let actions = [];
                    
                    if(colIndex == 0){
                        //add the add/remove row button
                        if (rowIndex == 0){
                            actions.push(<button key="add_row_before" onClick={()=>this.addRow(rowIndex)}><Icon icon="plus-square"/> Row (Before)</button>);
                        }
                        actions.push(<button key="remove_row" onClick={()=>this.deleteRow(rowIndex)}><Icon icon="trash"/> Row</button>);
                        actions.push(<button key="add_row_after" onClick={()=>this.addRow(rowIndex+1)}><Icon icon="plus-square"/> Row</button>);
                        
                    }
                    
                    if(rowIndex == 0){
                        //add the add/remove column button
                        if (colIndex == 0){
                            actions.push(<button key="add_column_before" onClick={()=>this.addColumn(colIndex)}><Icon icon="plus-square"/> Col (Before)</button>);
                        }
                        actions.push(<button key="remove_column" onClick={()=>this.deleteColumn(colIndex)}><Icon icon="trash"/> Col</button>);
                        actions.push(<button key="add_column_after" onClick={()=>this.addColumn(colIndex+1)}><Icon icon="plus-square"/> Col</button>);
                        
                    }

                    let newProps = {...col.props};
                    newProps.actions = actions;
                    
                    rowValues.push(React.cloneElement(col, newProps, null));
                }
            });
            
            return rowValues;
        });
        
        return tableRows;
    }
    
    getChild(row, col){
        //this.childAtLocation[row][col] = null means we saw it already and created a spot for it
        //this.childAtLocation[row][col] = undefined means we have never seen this so it was created by add button

        //pull the element from tableStructure and store the element in the appropriate location
        if (this.childAtLocation[row] == undefined){
            this.childAtLocation[row] = [];
        }
        
        if(this.state.tableStructure == undefined ||this.state.tableStructure[row] == undefined || this.state.tableStructure[row][col] == undefined){
            //tableStructure doesn't have any data on row and col
            this.childAtLocation[row][col] = {inlineCell: true};
        } else {
            const elementId = this.state.tableStructure[row][col];
            
            if(elementId != undefined){
                //we found a child in tableStructure that we need to put in childAtLocation array
                if(typeof elementId == "string"){
                    //element was a component
                    const element = _.find(this.props.children, (c)=>{ return c.props.root == elementId});
                    
                    this.childAtLocation[row][col] = element;
                } else {
                    //elementId was a string hash for a non rms cell
                    elementId.inlineCell = true;
                    this.childAtLocation[row][col] = elementId;
                }
            } else {
                //we shouldn't get here but just in case replace null placeholder with '...'
                this.childAtLocation[row][col] = {inlineCell: true};
            }
        }
        
        return this.childAtLocation[row][col];
    }
    
    fillTableStructure(){
        let res = [];
        let rowArrayIndex = 0;
        
        for(rowArrayIndex; rowArrayIndex < parseInt(this.state.rows); rowArrayIndex++){
            let colArrayIndex = 0;
            let rowChildren = [];

            for(colArrayIndex; colArrayIndex < parseInt(this.state.columns); colArrayIndex++){
                let contentObject = this.getChild(rowArrayIndex, colArrayIndex);

                if(contentObject.inlineCell == undefined){
                    //content is an rms element
                    rowChildren[colArrayIndex] = contentObject;
                } else {
                    //content is a string
                    let structure = this.props.NDT.find(this.props.root + "$" + rowArrayIndex + ":" + colArrayIndex);
                    
                    const rmsProps = {
                        key: structure.theId,
                        mode: this.props.mode,
                        scope: this.props.scope,
                        root: structure.theId,
                        rootStructure: structure,
                        NDT: this.props.NDT
                    }
                    
                    rowChildren[colArrayIndex] = <RenderMeSoftly {...rmsProps} iCameFromTable={true}/>;
                }
                
                if(this.props.root == "cid_V0FTVEUhAAAAV9ai9X6FEmNZllKo" && rowArrayIndex == 4){
                    //debugger
                }
                
                let incrementColIndexByExtra = 0;
                if(contentObject.props != undefined && contentObject.props.rootStructure != undefined && contentObject.props.rootStructure.options.colSpan){
                    incrementColIndexByExtra = contentObject.props.rootStructure.options.colSpan;
                } else if(contentObject.colSpan){
                    incrementColIndexByExtra = contentObject.colSpan;
                }
                
                const oldColIndex = colArrayIndex + 0;
                colArrayIndex += (parseInt(incrementColIndexByExtra) - 1); //-1 because the loop itself increments by 1
                
                if(oldColIndex > colArrayIndex){
                    //something went wrong. likely an unset colspan. reset.
                    colArrayIndex = oldColIndex + 0;
                }
            }
            res.push(rowChildren);
        }
        
        res = this.addButtons(res);

        res = _.map(res, (rowChildren, index)=>{
           return  <tr key={"row" + index}>{rowChildren}</tr>;
        });
    
        return res;
    }
    
    render(){
        let table = undefined;

        if(this.props.dataDriven == "yes"){
        	try{
        		table = this.generateDataDrivenTable();
        	}catch(e){
        		console.log("This is misbehaving on a drag.  I you see this and it's not dragging you have a real table problem.", e);
        	}
        } else {
            table = <table className={this.props.cssClass}>
                <tbody>
                    {this.fillTableStructure()}
                </tbody>
            </table>;
        }
        
        let newThis = {...this};
        if(newThis.props.scope.table_number){
            newThis.props.scope.table_number = "Table " + newThis.props.scope.table_number + ". ";
        }
            
        const title = templated(this.props.title || "", newThis, (title)=>{this.props.handleInlineChange({content: title})});

        const TitleTagName = this.props.titleSize || "h2";

        return <ErrorHandler path={"table called " + this.props.root}><div>
            {this.props.title > "" ? <TitleTagName className="table_heading">{title}</TitleTagName> : null}
            {this.props.mode == "edit" && this.props.dataDriven == "yes" ? this.props.children : undefined}
            {table}
        </div></ErrorHandler>;
    }
}
