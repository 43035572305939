import React, { Fragment, useEffect, useRef, useState } from "react";
import { Data } from "@opidcore/Data.js";
import {
  Bound,
  Button,
  Icon,
  InputAutoComplete,
  InputSelect,
  InputToggleSwitch,
  TabularColumn,
  TabularDataSet
} from "@opidcore/components";
import { useNavBar } from "@opidcore/hooks/WTF";
import moment from "moment";

import ViewPanel, { Field } from "../../components/ViewLayouts";

export default function MondayMatch(props) {
  useNavBar("Monday Matching", []);
  const [dataSet, setdataSet] = useState(new Data([]));
  const [mondayMapping, setMondayMapping] = useState([]);
  const [tableContent, setTableContent] = useState(null);

  const dataSetRef = useRef(new Data([]));
  const dataSetCols = useRef([]);
  const selectedBoard = useRef({ selected: null });
  const matchedShow = useRef({ matchedShowStatus: false });

  //gets MondayMappings and sets state
  useEffect(() => {
    APP.central.SyncMonday.getMondayMappings().then((r) => {
      setMondayMapping(r.result);
    });
  }, []);

  //Returns options for Monday Board selector
  const getOptions = () => {
    let ops = {};

    for (let i = 0; i < mondayMapping.length; i++) {
      ops[i] = mondayMapping[i].boardName;
    }
    return ops;
  };

  //sets state based on return form Java SyncMonday.getBoardDataSet
  const doReadMonday = () => {
    let boardName = null;
    if (mondayMapping.length > 0 && selectedBoard.current.selected != "") {
      boardName = mondayMapping[selectedBoard.current.selected].boardName;
    }
    if (boardName != null) {
      APP.central.SyncMonday.getBoardDataSet(boardName, matchedShow.current.matchedShowStatus).then((r) => {
        dataSetRef.current.reset(r.result.rows);
        dataSetCols.current = r.result.columns;

        setdataSet(dataSetRef.current);

        let tableCont = [];
        tableCont.push(
          <TabularColumn
            data={(row) => {
              return <Icon icon="equals" onClick={() => openMatchModal(row)} className="plain_link" size="2x" />;
            }}
          />
        );
        tableCont = tableCont.concat(
          dataSetCols.current.map((col) => {
            return (
              <TabularColumn
                data={(row) => {
                  if (row[col.key] === true) {
                    return <div>{"[x]"}</div>;
                  } else if (row[col.key] === false) {
                    return <div>{"[_]"}</div>;
                  }
                  return <div>{row[col.key]}</div>;
                }}
                title={col.key}
              />
            );
          })
        );

        setTableContent(tableCont);
      });
    } else {
      setdataSet(new Data([]));
      setTableContent(null);
    }
  };

  //opens MondayMatcher modal
  const openMatchModal = (entry) => {
    APP.instance.createModal(<MondayMatcher entry={entry} />, { modal_name: "Monday Match" });
  };

  //MondayMatcher modal
  function MondayMatcher(props) {
    //const [entityFields, setEntityFields] = useState([]);
    const [successMessage, setSuccessMessage] = useState("");
    const entry = props.entry;
    let tableFields = [];
    let selected = { boardItemId: -1, entityId: {} };
    let entity = mondayMapping[selectedBoard.current.selected].entity;

    // add a ViewPanel Field for each column in the entry
    for (const e in entry) {
      tableFields.push(<Field label={e}>{entry[e] ? entry[e] : "-"}</Field>);
      e == "ID" ? (selected.boardItemId = entry[e]) : null;
    }

    const doSave = () => {
      const changes = {
        relatedId: selected.entityId[entity.toLowerCase()],
        lastUpdated: moment().format(),
      };

      APP.central.MondayItem.saveChangesToMondayItemId(selected.boardItemId, changes).then((r) => {
        setSuccessMessage(r.result ? "Saved Successfully" : "Save Unsuccessful");
      });
    };

    return (
      <Fragment>
        <ViewPanel>{tableFields}</ViewPanel>
        <Bound to={selected.entityId}>
          <InputAutoComplete field={entity.toLowerCase()} name={entity} central={APP.central[entity].autocomplete}>
            {(complete) => <div>{complete.displayValue}</div>}
          </InputAutoComplete>
        </Bound>
        <Button onClick={doSave}>Save</Button>
        <div>{successMessage}</div>
      </Fragment>
    );
  }

  //-----------------------------------------------------------------------------------------------------------------------
  return (
    <div className="full-size">
      <Bound to={selectedBoard.current} onChange={doReadMonday}>
        <InputSelect field="selected" name="Monday Board" showEmpty={true} options={getOptions()} />
      </Bound>
      <Bound to={matchedShow.current} onChange={doReadMonday}>
        <InputToggleSwitch
          field="matchedShowStatus"
          name={(status) => {
            return status == undefined || status == false ? "Do Not Show Matched" : "Show Matched";
          }}
        />
      </Bound>

      {selectedBoard.current.selected == null || selectedBoard.current.selected == "" ? "Select a Board" : ""}

      <TabularDataSet style={{ display: "block", overflowX: "auto", whiteSpace: "nowrap" }} dataSet={dataSet} defaultSort="ID" defaultSortOrder="asc">
        {tableContent != [] ? tableContent : ""}
      </TabularDataSet>
    </div>
  );
}

// <LookupInputSelect field={entity.toLowerCase()} what={entity.toLowerCase()} name={entity} showEmpty={true} />
// onSelect={doSetEntityFields}

/*
    // Display selected entity's attributes and Input fields pre-filled with board-obtained info.
    const doSetEntityFields = () => {
      console.log("selected HELLO");
      console.log(selected);
      APP.central[entity].fetch(selected[entity.toLowerCase()]).then((r) => {
        let tempEntityFields = [];
        for (const b in r.result) {
          if ((typeof r.result[b] != "object" || r.result[b] == null) && !b.startsWith("_")) {
            tempEntityFields[0].push(<Field label={b}>{r.result[b] ? r.result[b] : "-"}</Field>);
          }
        }
        tempEntityFields.push(<FlexBreak />);

        for (const mapping in mondayMapping[selectedBoard.current.selected]) {
          for (const e in entry) {
            if (mapping == e) {
              tempEntityFields[1].push(<InputText field={mondayMapping[selectedBoard.current.selected].mapping} name={e} defaultValue={entry[e]} />);
            }
          }
        }
        setEntityFields(tempEntityFields);
      });
    };
    */
