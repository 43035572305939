import React, { useState } from "react";
//import CreateSupport from "../UI/Support/CreateSupport.jsx";
import Button from "@opidcore/components/Button";
//import Tasks from "../UI/Tasks.jsx";

export default function SupportButton(props) {
  if (props.related == undefined || props.related.__type == undefined) {
    return <span>...</span>;
  }

  const [loading, setLoading] = useState(false);
  const label = props.label || "Support";
  const me = APP.getWebSession();

  const addSupport = () => {
    setLoading(true);
    APP.central.Task.makeNewTask(props.related.__type, props.related.id, me.fullname, me.userId).then((r) => {
      APP.instance.createModal("/ui/support/case/" + r.result, { modal_name: "Case #" + r.result }, { afterClose: () => refreshStuff(), newCase:true});
      setLoading(false);
      if (props.refresh) {
        props.refresh();
      }
      if (props.modalToClose) {
        APP.instance.closeModal(props.modalToClose.id);
      }
    });
  };

  const refreshStuff = () => {    
    if (props.refresh) {
      props.refresh();
    }   
  };

  if (loading) {
    return (
      <span>
        <Button className={props.className || "support-button"}>Loading...</Button>
      </span>
    );
  } else
    return (
      <span>
        <Button className={props.className || "support-button"} onClick={() => addSupport()}>
          {label}
        </Button>
      </span>
    );
}
