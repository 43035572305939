//import history from "./history";

import _ from "lodash";
import { Util } from "./Util";


export default class OpidApp {
	constructor(name) {
		window.APP = this;
		this.events = [];
		this.reacts = [];
		this.listeners = {};
		this.tempGlobals = {};
		this.locations = [];

		this.menus = [];
		this.name = name;
		this.instance = null;
		this.central = null;
		
	}

	getTitle() {
		return this.name;
	}

	addMenu(functionThing) {
		this.menus.push(functionThing);
	}

	forceMenu(functionThing){
		this.menus = [];
		this.addMenu(functionThing);
	}
	
	//this is a half baked thing that didn't work.
	pooledRequest(controller, method, args) {
		const a = "123";
		const debouncedMethod = "debounced" + method
		const debouncedMethodArgs = "debouncedArgs" + method
		const debouncedMethodPromises = "debouncedPromises" + method

		if (this[debouncedMethod] == null) {
			this[debouncedMethodArgs] = [];
			this[debouncedMethodPromises] = [];

			this[debouncedMethod] = setTimeout(() => {
		
				debugger;
			}, 250);
		}


		this[debouncedMethodArgs].push(args);

		const n = new Promise((resolve) => {
			this[debouncedMethodPromises].push([resolve, args]);
		});

		return n;
	}

	cachedRequest(name, request, promise) {
		if (this.tempGlobals[name] == null) {
			this.tempGlobals[name] = { status: "pending", promises: [] };

			this.tempGlobals[name].promises.push(promise);
			request().then((res) => {
				this.tempGlobals[name].status = "complete";
				this.tempGlobals[name].result = res;

				_.each(this.tempGlobals[name].promises, (prom) => {
					prom(this.tempGlobals[name].result)
				});
			});
		} else {
			if (this.tempGlobals[name].status != "pending") {
				promise(this.tempGlobals[name].result);
			} else {
				this.tempGlobals[name].promises.push(promise);
			}
		}
	}

	updateModel(modelId, attributes) {
		const id = modelId.split(":")[1];

		return APP.central.Stuff.updateModel({ id: id, update: attributes });
		//this.alert("Sure we will do that to " + modelId);
	}

	toast(message, options) {
		this.alert("TODO TOAST: " + message);
	}

	alert(message, title) {
		
		APP.confirm(message, title);
	}

	prompt(message, options) {
		
		return prompt(message);
	}

	confirm(message, option1, option2){
		let title = null;
		let callback = null;
		if(typeof option1 == "function"){
			callback = option1;
			title = option2;
		} else {
			title = option1;
			callback = option2;
		}

		APP.instance.openConfirmDialog(message, title, callback);
	}

	showLoading(message, options) {
		if (typeof message == "string") {
			message = { message: message, children: null };
		}
		if (APP.instance) {
			APP.instance.showLoading(message);
		} else {
			this.alert("Loading " + message);
		}

		options();
	}

	triggerState(path) {
		if (this._stateListeners[path] == null) {
			this._stateListeners[path] = [];
		}

		this._stateListeners[path].map((listen) => {
			listen(this._state[path]);
			
		});
	}

	getState(path, listener) {
		return this.setState(path, null, listener);
	}

	removeState(path) {
		delete this._state[path];
	}

	removeListener(path, listener) {


		if (this._stateListeners[path].indexOf(listener) >= 0) {
			const idx = this._stateListeners[path].indexOf(listener);
			this._stateListeners[path].splice(idx, 1);
		}
	}

	removeStateListener(path, listener){
		_.pull(this._stateListeners[path], listener);
	}

	setState(path, setValue, listener) {
		if (this._state == null) {
			this._state = {};
		}

		if (this._stateListeners == null) {
			this._stateListeners = {};
		}

		let changed = false;

		if (setValue != null) {
			if (!_.isEqual(this._state[path], setValue)) {
				changed = true;
				this._state[path] = setValue;
			}
		}

		if (this._stateListeners[path] == null) {
			this._stateListeners[path] = []
		}

		if (listener != null) {
			if (this._stateListeners[path].indexOf(listener) == -1) {
				this._stateListeners[path].push(listener);
			}
		}

		if (changed) {
			this._stateListeners[path].map((listen) => {
				listen(this._state[path]);				
			});
		}

		return this._state[path];
	}

	onsocket(event) {


		window.APP.events.push(event.data);		
		window.APP.reacts.map((r) => r.thisHappened(event.data));
	}

	findForm(byWhat) {

		if (parseInt(byWhat) > 0) {
			return _.find(APP.forms, { id: parseInt(byWhat) });
		}

		return _.find(APP.forms, { uid: byWhat });
	}

	setup(_setup) {
		this.log("Application setup! (" + (_setup ? "with" : "without") + " a setup callback)");

		if (_setup) {
			_setup(this);
		} else {

		}
	}

	log(info) {
		console.log("OpioApp.js: ",info);
	}

	formatString(varString, capitalizedWords) {
		//varString: input string, capitalizedWords: amount of words capitalized (all if undefined)	    
		if (varString != undefined) {
			let tempSplit = varString.split(/(?=[A-Z])|[_]+/);

			for (var i = 0; i < tempSplit.length; i++) {
				if (capitalizedWords == undefined || capitalizedWords > 0) {
					tempSplit[i] = tempSplit[i].charAt(0).toUpperCase() + tempSplit[i].slice(1);

					if (capitalizedWords != undefined) {
						capitalizedWords--;
					}
				} else {
					break;
				}
			}

			return tempSplit.join(" ");
		} else {
			return "";
		}
	}

	executeFunction(fid, props) {
		const functionDefinition = _.find(this.functions, { cid: fid });

		var func = this.central.Stuff[functionDefinition.options.theFunc];
		var that = this;

		func(props.uid).then(response => {

			if (functionDefinition.options.redirect == "true") { 
				var id = response.result.id;

				APP.redirect("/view/" + props.uid + "/" + id);
			}
		});
	}

	executeNavToCid(cid, viewtype) {
		APP.redirect("/" + viewtype + "/" + cid);
	}

	getWebSession() {
		if (APP.getState('webSession')) {
			return APP.getState('webSession');
		}

		return {
			access: () => false,
			permissions: () => {return { r: false, w: false, x: false }}
		};
	}
	
// FIX THIS AT SOME POINT

	redirect(path){
		if (path.__type == "Site") {
			this.setState("redirect", "/ui/sites/edit/" + path.id)
		}
		else if (path.__type == "Vendor") {
			this.setState("redirect", "/ui/vendors/edit/" + path.id)
		}
		else if (path.__type == "Client") {
			this.setState("redirect", "ui/clients/" + path.id)
		}
		else {
			this.setState("redirect", path);
		}
	}

	setHistory(context, path) {
		/*DO NOT USE - wrong history. 
		 * 
		 *Need this.props.history from the component to change this.props.match.params. This just changes url...
		const routeObj = {
			pathname: path,
			state: null,
			query: ""
		}
		history.replace(routeObj);
		*/
		context.props.history.push(path);
	}

	registerBoundMagic(id, boundMagic) {
		if (this.registeredBoundMagics == undefined) {
			this.registeredBoundMagics = {};
		}

		if(this.registeredBoundMagics[id] != undefined){
			console.log("overriding registered bound magic...", this.registeredBoundMagics[id]);
		}
		
		this.registeredBoundMagics[id] = boundMagic;

		_.forEach(this.customize.get("BoundMagics", id), (boundMagicListener)=>{
			boundMagicListener(boundMagic);
		});
	}

	unregisterBoundMagic(id) {
		if (typeof id == "object"){
			delete this.registeredBoundMagics[id.boundId];
		}
		if(this.registeredBoundMagics != undefined && this.registeredBoundMagics[id] != undefined){
			delete this.registeredBoundMagics[id];
		}
	}

	stringify(data) {
		return Util.stringify(data);
	}

	updateDataCache(dataUID, newRow){
		if(window._GLOBAL_DATA_CACHE[ dataUID ] != undefined){
			if (_.isArray(newRow)){
				console.log("OpidApp.js: ERROR - can't update a data cache with an array!");
				return;
			}
			window._GLOBAL_DATA_CACHE[ dataUID ].addOrUpdate( newRow );
			_.each( APP.registeredBoundMagics, (rbm,key)=>{
				if (rbm.to && rbm.to.__dataUID == dataUID){
					let cachedId = null;
					
					if(rbm.magicalGet("id", 0) == 0){
						cachedId = newRow.id;
					} else {
						cachedId = rbm.magicalGet("id");
					}

					if(cachedId != null){
						const cachedObject = window._GLOBAL_DATA_CACHE[ dataUID ].filter({id: cachedId});

						if(cachedObject != null && cachedObject.length > 0){
							rbm.replaceTo(cachedObject[0]);
						}
					}
				}
			});
		} else {
			console.log("OpidApp.js: no data cache for " + dataUID);
		}
	}

};