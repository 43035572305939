import React, { useEffect, useState } from 'react';
import { useChromeBlockProvider } from '@opidcore/hooks/WTF';
import _ from 'lodash';
import moment from 'moment';

import Util from '../../../../../OpidCore/public/js/Util';
import { Icon } from '../../../../../OpidCore/public/js/components';
import '../../../styles/pdf-reports.scss';

const ReportManaged = ({reportTemplate, invoiceIds, showBaseline}) => {
    const [data, setData] = useState({details: {rows:[]}});
    const [apInvoice, setAPInvoice] = useState({});
    const [groupedData, setGroupedData] = useState({});
    const [related, setRelated] = useState([]);
    const chromeBlock = useChromeBlockProvider();

    let columnsWide = 2;
    

    useEffect(()=>{        
        APP.central.APInvoice.getInvoice(invoiceIds[0]).then( (r)=>{
            setData(r.result);
            setRelated(r.result.related);
            setAPInvoice(r.result.entity);            

            chromeBlock.doneLoading();
        });        
    }, []);

    useEffect( ()=>{
        if (data.details){
            const grouped = _.map( _.groupBy( data.details.rows, (d)=>d.site_name ), (lines,site_name)=>{
                return {title: site_name, lines: _.sortBy(lines, (l)=>l.invoice_description + " " + l.service_short_description + " " + l.period_from) };
            });

            setGroupedData(grouped);
        }
    }, [data]);

    let adjustmentTotal = 0;
    let totalToPay = 0;

    let taxes = null;

    const rows = _.flatten(_.map([apInvoice], (apInvoice)=>{
        if (apInvoice.vendor == null){
            return null;
        }


        const rows = [
            <tr>
                <td>Original Invoice Amount (See Following Pages)</td>
                <td><DollarValue value={apInvoice.vendorTotal}/></td>
            </tr>
        ];
          
        const linesBySite = _.groupBy(apInvoice.lines, (l)=>l.items[0].site.id)

        let lastShortDescription = "";
        _.forEach( linesBySite, (lines, siteId)=>{
            const site = Util.findBaseEntity(related, lines[0].items[0].site);
            rows.push( <tr><th colSpan={columnsWide} className="title">{site.name} - {site.address}, {site.city}, {site.province} {site.postalCode}</th></tr> );
            let issues = 0;
            _.forEach( lines, (line)=>{
                if (line.paymentAdjustment != 0){
                    issues++;
                    const service = Util.findBaseEntity(related, {__type:"Service", id: line.items[0].service  })
                    const activity = line.items[0].vendorActivity.activity

                     rows.push(<tr>
                        <td colSpan={columnsWide}><strong>{service != null ? service.cleanDescription : ""}</strong></td>
                        </tr>);

                      rows.push ( <tr>
                            <td className="leftPad"><div className="red-check"><span>x</span></div> {activity} - <em>{line.paymentAdjustmentNotes}</em></td>
                            <td><DollarValue value={line.paymentAdjustment}/></td>
                        </tr>
                    );
                }

                adjustmentTotal += line.paymentAdjustment;
            });

            taxes = _.groupBy(_.compact(_.map( lines, (l)=>{ return l.paymentAdjustmentTaxes })), (v)=>_.keys(v)[0] );

            _.forEach( taxes, (taxedItems, tax)=>{
                const amount = _.sum(_.flatten(_.map(taxedItems, (t)=>_.values(t))))
                adjustmentTotal += amount;

                rows.push ( <tr>
                    <td>{tax}</td>
                    <td><DollarValue value={amount}/></td>
                </tr> );
            });

            if (issues == 0){
                rows.push ( <tr>
                    <td colSpan={columnsWide}>{"No issues found"}</td>
                </tr> );
            }
        });
        

        const totalSite = 0; //_.sum( _.map(data.details.rows, 'extended_price') );


        totalToPay = apInvoice.vendorTotal + adjustmentTotal;
    
        return rows;
    
        rows.push( );
        
        rows.push ( <tr className="total">
                    <th>{"Amount To Pay"}</th>
                    <td style={{width: "8em"}}><DollarValue value={totalToPay}/></td>
                </tr>
            );

        return rows;
    } ));


    if (apInvoice.vendor == null){
        return "loading";
    }

    let summaryTable = <div className="top-summary"><BlockTable title="PAYABLE SUMMARY">
            <tr>
            <th className="summary-title" colSpan={columnsWide}>
                <div className="flex">
                    <div><strong>Payable To</strong> - {apInvoice.vendor.name}</div>
                    <div><strong>Invoice Date</strong> - {moment(apInvoice.invoiceDate).format("MMMM D, YYYY")}</div>
                </div>
                <div className="flex">
                    <div><strong>AMOUNT DUE <span>&nbsp;&nbsp;</span> <DollarValueSpan value={totalToPay} space={true}/> <div className="green-check"><Icon icon={"check"} fType="fas" color="white"/></div></strong></div>
                    <div><strong>Invoice #</strong> - {apInvoice.externalInvoiceNumber}</div>
                </div>
            </th>
        </tr>
    </BlockTable>
    </div>;

    let invoiceTotals = <BlockTable title={null} columns={columnsWide}>
        {rows}
    </BlockTable>;
    
    let totalTaxes = 0;

    let taxRows = [];
    taxes = _.groupBy(_.compact(_.map( apInvoice.lines, (l)=>{ return l.netTaxes })), (v)=>_.keys(v)[0] );

    _.forEach( taxes, (taxedItems, tax)=>{
        const amount = _.sum(_.flatten(_.map(taxedItems, (t)=>_.values(t))))
        totalTaxes += amount;

        taxRows.push ( <tr>
            <td>{tax}</td>
            <td><DollarValue value={amount}/></td>
        </tr> );
    });

    totalTaxes = Math.ceil(totalTaxes*100)/100;

    let toPay = <BlockTable title="Amounts To Pay" columns={2} className="toPay" footer={<tr>
        <th>Total</th>
        <th><DollarValue value={totalToPay}/></th>
    </tr>}>
            <tr>
                <td>Subtotal</td>
                <td><DollarValue value={totalToPay - totalTaxes}/></td>
            </tr>
            {taxRows}
    </BlockTable>;

    const reportDate = moment( data.entity ? data.entity.dateReceived : null  ); //data.details.rows.length > 0 ? moment(_.min(data.details.rows.map((r)=>r.period_from))) : null;

    return <div>
        <div className="heading">
            <div>
                <img src="/assets/images/logo_compact.png" className="brand-logo-compact"/>
                <div className="titles">
                    <h1>Vendor Invoice Review</h1>
                </div>
            </div>
        </div>
        <br/>
        <br/>

        {summaryTable}
        
        {invoiceTotals}   

        <div className="review-footer">
            <div className="branding">
            <div>
                <p>Vendor Invoice Reviewed By</p>
                <p>
                    <img src="/assets/waste/ws-logo.png" className="brand-logo"/>
                </p>
            </div>
            </div>
            {toPay}
        </div>
 
    </div>;
}


export const BlockTable = ({title="No Title", className="block-table", columns=1, children, footer=null}) => {

    return <>
        <table className={className}>
            {title != null ? <thead>
                <tr><th colSpan={columns} className="title">{title}</th></tr>
            </thead> : null }
            <tbody>
                {children}
            </tbody>
            <tfoot>
                {footer}
            </tfoot>
        </table>
    </>;
}

const DollarValue = ({value})=>{
    const displayValue = value < 0 ? "(" + Util.roundNice(-1 * value, 2) + ")" : Util.roundNice(value, 2);
    return <div className="dollar-value"><span className="sign">$</span> <span>{displayValue}</span></div>
}

const DollarValueSpan = ({value, space=false})=>{
    return <span className="dollar-value"><span className="sign">$</span> {space?<span>&nbsp;&nbsp;&nbsp;</span>:null} <span>{Util.roundNice(value, 2)}</span></span>
}

const DecimalValue = ({value,units})=>{
    return <div className="decimal-value" style={units ? {textAlign: "right"} : {} }><span>{Util.roundNice(value, 2)}</span> { units ? <span>{units}</span> : null }</div>
}

const SubTotalRow = ({label, children, columnsWide})=>{
    return <tr>
            {columnsWide > 2 ? <td></td> : null}
            <td colSpan={columnsWide > 2 ? columnsWide-2 : 1}>{label}</td>
            <td>{children}</td>
    </tr>
};
    
export default ReportManaged;