import React, { useState, useRef, useEffect } from "react";
import { useData, useNavBar } from "@opidcore/hooks/WTF";
import { TabularDataSet, TabularColumn, Loading, ActionBar, Searchable, Button, Pagination, BoundCommit, InputText, Icon, MagicalState } from "@opidcore/components";
//import ChangeLogs from "../ChangeLog/View";
import * as _ from "lodash";

const MaterialDestinationList = ({ }) => {
    useNavBar("Edit Material Destinations", []);
    const [data, dataSet] = useData("material_destination");

    const refreshMaterialDestinations = () => {
        dataSet.fetch();
    };

    const editMaterialDestination = (materialDestination) => {
        APP.instance.createModal(<MaterialDestinationEdit materialDestination={materialDestination} />, { modal_name: "Edit " + materialDestination.name }, { afterClose: () => refreshMaterialDestinations() });
    };

    const newMaterialDestination = () => {
        APP.instance.createModal(
            <MaterialDestinationEdit materialDestination={{ __new: true, __type: "materialDestination", id: 0 }} />,
            { modal_name: "New Material Destination" },
            { afterClose: () => refreshMaterialDestinations() }
        );
    };

    if(dataSet.loading == true){
        return <Loading />;
    }

    return <div key="material_destination_list">
        <ActionBar title="Actions">
            <Searchable ds={dataSet}/>
            <Button onClick={() => newMaterialDestination()}>New Material Destination</Button>
        </ActionBar>

        <Pagination dataSet={dataSet} />

        <TabularDataSet dataSet={dataSet} defaultSort="id" defaultSortOrder="asc">
            <TabularColumn data={(row) => row.id} title="#" />
            <TabularColumn data={(row) => row.destination} title="Destination" />
            <TabularColumn data={(row) => <Icon icon={row.icon} color={row.colour} size="2x"/>} title="Icon" />
            <TabularColumn data={(row) => <Icon icon="pencil" onClick={()=>editMaterialDestination(row)} />} />
        </TabularDataSet>
    </div>;
}

const MaterialDestinationEdit = ({materialDestination= {}}) => {
    const materialDestinationRef = useRef({ ...materialDestination });
    const [iconState, setIconState] = useState({icon: materialDestination.icon, colour: materialDestination.colour});
    const [updateValues, setUpdateValues] = useState({});

    useEffect(()=>{
        setIconState({...iconState, ...updateValues});
    }, [updateValues]);

    const updateIconState = (value, field) => {
        const newFieldValue = {};
        newFieldValue[field] = value;

        setUpdateValues(newFieldValue);
    }

    return <div key="material_destination_edit">
        {/*<ActionBar title="Actions">
            <h2>Edit Material Destination</h2>
            <ChangeLogs model={materialDestinationRef.current} />
        </ActionBar>*/}

        <BoundCommit 
            initialValue={materialDestinationRef.current} 
            boundId={"editMaterialDestination" + materialDestinationRef.current.id}
            commit={(id,changes,to,bound)=>APP.central.Bound.saveChanges(bound.getAllDeltas())}
            permissions={APP.getWebSession().permissions("Controlled Data Lists")}>
                <InputText name="Destination" field="destination" />
                <InputText field="colour" name="Icon Colour" inputType="color" />
                <InputText field="icon" name="Icon" />
                <Icon icon={iconState.icon} color={iconState.colour} size="5x" />
                <MagicalState to="icon" setter={(value)=>updateIconState(value, "icon")} />
                <MagicalState to="colour" setter={(value)=>updateIconState(value, "colour")} />
        </BoundCommit>
    </div>
}

export default MaterialDestinationList;