import React, { Fragment, useEffect, useState } from "react";
import Icon from "@opidcore/components/Icon";

export function ActionBarAdvanced(props) {
  const [open, setOpen] = useState(false);

  return (
    <div className="advanced">
      <Icon icon="cog" size="2x" onClick={() => setOpen(!open)} /> {open ? props.children : null}
    </div>
  );
}

export default function ActionBar(props) {
  return <div className={"actionbar " + props.className} style={props.style}>{props.children}</div>;
}
