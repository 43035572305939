import React, { useState, useEffect } from 'react';
import { Button } from '@opidcore/components';
import _ from 'lodash';

export default function LinkButton({target, text="", className="", title="", children=null, modalName = undefined}) {

    function openModal(e) {
        e.preventDefault()
        APP.instance.createModal(target, {modal_name: modalName});
    }

    return <a href={target} className={className} title={title} onClick={(e) => openModal(e)}>{text}{children}</a>
}