import React from "react";


export default function Dashboard(props) {
    
  const me = APP.getWebSession();

  return <div id="userDashboard" className="text-center container">
    <h2>Welcome {me.fullname}!</h2>  
  </div>

}
