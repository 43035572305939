import React, { useEffect, useRef, useState } from 'react';
import { useChromeBlockProvider } from '@opidcore/hooks/WTF';
import _ from 'lodash';
import moment from 'moment';

import Util from '../../../../../OpidCore/public/js/Util';
import '../../../styles/pdf-reports.scss';

import { footnotes } from './ReportConsulting';

const ReportManaged = ({reportTemplate, invoiceIds, showBaseline}) => {
    const [data, setData] = useState({details: {rows:[]}});
    const [groupedData, setGroupedData] = useState({});
    const chromeBlock = useChromeBlockProvider();
    const footnotesRef = useRef([]);

    let columnsWide = 4;
    let isDetailed = false;
    let showActivity = false;
    let showAllPricing = false;
    let showServiceDescription = false;

    if (reportTemplate == "detailed"){
        columnsWide = 7;
        showAllPricing = true;
        isDetailed = true;
        showActivity = true;
    }

    if (reportTemplate == "service"){
        columnsWide = 5;
        isDetailed = false;
        showAllPricing = false;
    }

    if (reportTemplate == "activity"){
        columnsWide = 5;
        showActivity = true;
        showAllPricing = false;
        showServiceDescription = true;
    }

    if (reportTemplate == "activityWithPrice"){
        columnsWide = 7;
        isDetailed = true;
        showAllPricing = true;
        showActivity = true;
    }


    useEffect(()=>{        
        APP.central.ARInvoice.findInvoiceData(invoiceIds, reportTemplate, showBaseline).then( (r)=>{
            setData(r.result);

            chromeBlock.doneLoading();
        });        
    }, []);

    useEffect( ()=>{
        if (data.details){
            const grouped = _.map( _.groupBy( data.details.rows, (d)=>d.site_name ), (lines,site_name)=>{
                return {title: site_name + ' - ' + lines[0].site_address, lines: _.sortBy(lines, (l)=>l.invoice_description + " " + l.service_short_description + " " + l.period_from) };
            });

            setGroupedData(_.sortBy(grouped, (r)=>r.title));
        }
    }, [data]);

    //make sure cleared if we rerender
    footnotesRef.current = [];

    
    const tables = _.map(groupedData, (site)=>{
        const rows = [];

        if (isDetailed){
            rows.push ( <tr>
                <td></td>
                <td></td>
                <td></td>
                {showAllPricing ? <><th>Quantity</th>
                <th>Price</th></> :
                null } 
                <th>Amount</th>
                <th></th>
            </tr>
            );
        }
        let lastShortDescription = "";
        _.forEach( site.lines, (line)=>{
            if (line.service_short_description != lastShortDescription && (isDetailed || showServiceDescription) ){
                rows.push ( <tr>
                    <td className="service" colSpan={columnsWide}>{line.service_short_description}</td>
                </tr>
                );
            }
            lastShortDescription = line.service_short_description;
            if (isDetailed){
                if (line.extended_price != 0 || line.activity > ""){    
                    // Should extract this to some kind of format function?
                    let dateToShow = line.period_from ? moment(line.period_from).format("MM-DD-YYYY") : "";
                    if (line.period_from && line.period_to){
                        const dateFrom = moment(line.period_from);
                        const dateTo = moment(line.period_to);
                        
                        if (reportTemplate == "detailed" || dateFrom.format("MM-DD-YYYY") != dateTo.format("MM-DD-YYYY")){
                            dateToShow = dateFrom.format("MM-DD-YYYY") + " - " + dateTo.format("MM-DD-YYYY");
                        }else if (dateFrom.get('date') == 1 && dateTo.get('date') == dateTo.endOf('month').get('date') ){
                            dateToShow = dateFrom.format("MMM YYYY");
                        }

                        if (dateFrom.format("MM-DD-YYYY") == dateTo.format("MM-DD-YYYY")){
                            dateToShow = dateFrom.format("MM-DD-YYYY");
                        }


                        // detailed don't truncate to the month
                        // remove quantity from surcharge
                        // 
                    }

                    let priceDisplayed = <DollarValue value={line.unit_price}/>;
                    let showQuantity = true;
                    if (line.unit_price_per == "surcharge" || line.unit_price_per == "%"){
                        priceDisplayed = <DecimalValue value={line.unit_price * 100} units={"%"}/>;
                        showQuantity = false;
                        dateToShow = "";
                    }
                    rows.push ( <tr>
                            <td>{dateToShow}</td>
                            <td>{showActivity ? line.activity : line.invoice_description }</td>
                            <td>{line.item_description }</td>
                            {showAllPricing ? <><td>{ showQuantity ? <DecimalValue value={line.quantity}/> : null}</td>
                            <td>{priceDisplayed}</td></>
                            : null}
                            <td><DollarValue value={line.extended_price}/></td>
                            <td>{footnotes(footnotesRef,line)}</td>
                        </tr>
                        );
                }
            }else{
                rows.push ( <tr>
                        <td>{line.invoice_description}</td>
                        <td>{showActivity ? line.activity : null}</td> 
                        <td>{line.item_description }</td>
                        <td><DollarValue value={line.extended_price}/></td>
                        <td>{footnotes(footnotesRef,line)}</td>
                    </tr>
                    );
            }
        });

            let totalSite = _.sum( _.map(site.lines, (line)=>line.extended_price));

            rows.push( <SubTotalRow columnsWide={columnsWide} label={"Subtotal"}><DollarValue value={totalSite}/></SubTotalRow> );

            let totalTax = 0;
            _.forEach( _.groupBy( site.lines, 'tax_name'), (lines, tax)=>{ 
                const thisTax = _.sum(_.map(lines, 'total_tax'));
                totalTax += thisTax;                
                if (thisTax != 0){
                    rows.push( <SubTotalRow columnsWide={columnsWide} label={tax}><DollarValue value={thisTax}/></SubTotalRow> );
                }
            });

            
            rows.push ( <tr className="total">
                <th>{"Site Total"}</th>
                { columnsWide > 2 ? <td colSpan={columnsWide-3}></td> : null }
                <td style={{width: "8em"}}><DollarValue value={totalSite + totalTax}/></td>
                <td></td>
            </tr>
            );

            

            if (footnotesRef.current.length > 0){
                rows.push ( <tr className="total">
                <td colSpan={columnsWide} className="footnote">
                    {_.map(footnotesRef.current, (f)=><p>{f.number + ': ' + f.text}</p>)}
                </td>
                </tr>
                );  
            }

            footnotesRef.current = [];
        return  <BlockTable title={site.title} columns={columnsWide}>
                {rows}
            </BlockTable>;
    } );


    const totalSite = _.sum( _.map(data.details.rows, 'extended_price') );

    const rows = [<tr>
        <td colSpan={columnsWide-1}>Subtotal</td>
        <td style={{width: "8em"}}><DollarValue value={totalSite}/></td>
    </tr>];


    let totalTax = 0;
    _.forEach( _.groupBy( data.details.rows, 'tax_name'), (lines, tax)=>{ 
        const thisTax = _.sum(_.map(lines, 'total_tax'));
        totalTax += thisTax;
        if (tax != null && tax != "null"){
            rows.push( <tr>
                    <td colSpan={columnsWide-1}>{tax}</td>
                    <td><DollarValue value={thisTax}/></td>
            </tr> );
        }
    });


    rows.push ( <tr className="total">
                <th colSpan={columnsWide-1}>{"Invoice Total"}</th>
                <td style={{width: "8em"}}><DollarValue value={totalSite + totalTax}/></td>
            </tr>
            );

    let invoiceTotals = <BlockTable title="Invoice Totals" columns={columnsWide}>
        {rows}
    </BlockTable>;


    const reportDate = data.details.rows.length > 0 ? moment(_.max(data.details.rows.map((r)=>r.period_to))) : null;



    return <div>
        <div className="heading">
            <div>
                <img src="/assets/waste/ws-logo.png" className="brand-logo"/>
                <div className="titles">
                    <h1>Invoice Details</h1>
                    <h2>{reportDate != null  ? reportDate.format('MMMM YYYY') : ""}</h2>
                </div>
            </div>
        </div>
        <br/>
        <br/>
        
       {tables}

       {invoiceTotals}   
    </div>;
}


export const BlockTable = ({title="No Title", columns=1, children}) => {

    return <>
        <table className="block-table">
            <thead>
                <tr><th colSpan={columns} className="title">{title}</th></tr>
            </thead>
            <tbody>
                {children}
            </tbody>
        </table>
    </>;
}

const DollarValue = ({value})=>{
    return <div className="dollar-value"><span className="sign">$</span> <span>{Util.roundNice(value, 2)}</span></div>
}

const DecimalValue = ({value,units})=>{
    return <div className="decimal-value" style={units ? {textAlign: "right"} : {} }><span>{Util.roundNice(value, 2)}</span> { units ? <span>{units}</span> : null }</div>
}

const SubTotalRow = ({label, children, columnsWide})=>{
    return <tr>
            {columnsWide > 2 ? <td></td> : null}
            <td colSpan={columnsWide > 2 ? columnsWide-2-1 : 1}>{label}</td>
            <td>{children}</td>
            <td></td>
    </tr>
};
    
export default ReportManaged;