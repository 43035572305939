import React, { useEffect, useState }  from 'react';
import Util from '@opidcore/Util.js';
import { ResponsiveBar } from '@nivo/bar';
import * as OrigBarGraph from '@opidcore/components/Content/Types/BarGraph.jsx';
import Figure from '@opidcore/components/Content/Types/Figure.jsx';
import { max } from 'lodash';
import { ErrorHandler } from '@opidcore/components';

function BarComp({ data,

    x,
    y,
    width,
    height,
    borderRadius,
    color,
    borderWidth,
    borderColor,

    label,
    shouldRenderLabel,
    labelColor,

    showTooltip,
    hideTooltip,
    onClick, maxBarHeight}){
	let newY = y;
	let realHeight = height;
	if (height > maxBarHeight){
		newY += (height - maxBarHeight)/2;
		realHeight = maxBarHeight;
	}
	
    return (
            <g transform={`translate(${x}, ${newY})`}>
                <rect
                    width={width}
                    fill={color}
                    height={height > maxBarHeight ? maxBarHeight : height}
                    rx={borderRadius}
                    ry={borderRadius}
                    strokeWidth={borderWidth}
                    stroke={borderColor}
                    onClick={onClick}
                />
                {shouldRenderLabel && (
                    <text
                        x={width + 5}
                        y={realHeight / 2}
                        textAnchor="start"
                        alignmentBaseline="central"
                        style={{
                            pointerEvents: 'none',
                            fill: labelColor,
                        }} className='bar-label'
                    >
                        {Util.roundNice(data.value, 2)}
                    </text>
                )}
            </g>
        )
}

export default function BarGraph(props) {
  let data = props.data;
  if (typeof props.plot == "object"){
	  data = _.map(props.plot, (value,label)=>{  return {label: label, value: value} } );
  }

  if (data == null){
      data = [];
  }

  //remove data by the label provided from the graph.
  if (props.excludeLabels > ""){
	  data = _.filter(data, (r)=>{ return props.excludeLabels.split(',').indexOf( r.label  ) == -1 });
  }
  
  let ourColor = null;
  
  if(props.colourProvider && typeof props.colourProvider === "function"){
      try {
          const coloursCollection = props.colourProvider(_.map(data, "label")); //e.g. [{label: x, colour: y}, {label: a, colour: b}, ...]
          ourColor = (d)=>{ try{ 
        	  	return _.filter(coloursCollection, {label: d.data.label})[0].colour 
        	  }catch(e){ 
        		console.log("we couldn't find a colour, so here is #00cccc",e,d); 
        	  }
          
          		return '#00cccc';
          }
          /* e.g.
          function(dataLabels){
              return _.map(dataLabels, (dataLabel)=>{
                  const val = _.findWhere(APP.NDT.datasets['audit-materials-used'].rawData, (r)=>{ r.material_name == dataLabel);
                  if(val != undefined){
                      return {label: dataLabel, colour: val.material_colour};
                  }
                  
                  return {label: undefined, colour: #cc0000};
              });
          }
          */   
      } catch(e) {
          console.log(e);
      }
  } else if(props.colourProvider && typeof props.colourProvider === "string"){
      //props.colourProvider is the cid/uid of the user defined colourSet component
      data =_.map(data, (d)=>{
          let colour = APP.NDT.colourMapper.findOrGuess(d.label, props.colourProvider);
          d.colour = colour;
          return d;
      });
      ourColor = (d)=>{ return d.data.colour; };
  }
  
  if(props.colourProvider == null || (typeof props.colourProvider === "string" && APP.NDT.colourMapper.findOrGuess(null, props.colourProvider) == null)){
      //this is an nivo default colour set
      ourColor = {scheme: "set3"};
  }  
	 const margin = { top: 20, right: 80, bottom: 50, left: 120 };
	  
	  if (props.margins != undefined && props.margins.length > 0){
		  var chunks = props.margins.split(",");
		  if (chunks.length == 1){
			  margin.top = parseInt(chunks[0]);
			  margin.right = parseInt(chunks[0]);
			  margin.bottom = parseInt(chunks[0]);
			  margin.left = parseInt(chunks[0]);
		  }else{
			  margin.top = parseInt(chunks[0]);
			  margin.right = parseInt(chunks[1]);
			  margin.bottom = parseInt(chunks[2]);
			  margin.left = parseInt(chunks[3]);
		  }
	  }
	  
	  const padding = parseFloat(props.padding) || 0.3;
	  const maxBarHeight = parseFloat(props.maxBarHeight) || 50;

      let tickSize = 500;

      let maxValue = 100;
      if (_.size(data) > 0){
        maxValue = _.max( data.map( (d)=>d.value ) );

        if (maxValue < 1500){
            tickSize = 100;
        }

        
        maxValue = Math.ceil((maxValue/tickSize)) * tickSize;

      }

  const graph =		 <ResponsiveBar
        colors={ourColor}
	        data={data}
            maxValue={props.maxValue != undefined ? props.maxValue : maxValue}            
            tickSize={tickSize}
	        keys={[ 'value' ]}
	        indexBy="label"
	        layout="horizontal"
	        margin={margin}
	        padding={padding}
	        axisTop={null}
	        axisRight={null}
  			enableGridX={true}
  			enableGridY={false}
  			minValue={0}
  			valueScale={{"type": "linear"}}
	        axisBottom={{
	            tickSize: 5,
	            tickPadding: 5,
	            tickRotation: 0,
	            legend: props.xAxisLabel,
	            legendPosition: 'middle',
	            legendOffset: 32
	        }}
	        axisLeft={{
	            tickSize: 5,
	            tickPadding: 5,
	            tickRotation: 0,
	            legend: props.yAxisLabel,
	            legendPosition: 'middle',
	            legendOffset: -1 * margin.left + 20
	        }}
  			xdisabledLabelFormat={ (d,a,b) => { debugger; return <tspan>{ d }?</tspan> }}
	        animate={false}
	        barComponent={ (props) => <BarComp {...props} maxBarHeight={maxBarHeight}/>}
	    />;
  return (
      <ErrorHandler>
    <div className="graph-outer">
          <Figure title={props.hideTitle ? null : props.graphTitle} {...props}>
        	<div className="graph" style={{height: props.height, width: props.width}}>
        		{graph}
        	</div>  	
    	</Figure>
     </div>
     </ErrorHandler>
  );
}

  BarGraph.metaInfo = ({})=>{
		return {renderAsPng: true, icon: "chart-bar", preview: (me)=>{ return "?" }, showInEditorQuickAdd: true };
  };
  
BarGraph.getEditableProps = ({})=>{
		var  editable = OrigBarGraph.default.getEditableProps();
		editable.push( {niceName: "graph title", prop: "graphTitle", type: "text" } );
		editable.push( {niceName: "Item Padding (0.1 - 0.9)", prop: "padding", type: "text" } );
		
		editable.push( {niceName: "Max Bar Size", prop: "maxBarHeight", type: "text" } );
		
		editable.push( {niceName: "Exclude Labels", prop: "excludeLabels", type: "text" } );
		return editable;
};

BarGraph.injextNext = (props)=>{
    if(props.hideTitle == undefined || !props.hideTitle){
        if(props.graphTitle){
            props.title = props.graphTitle;
        }
    }
    return Figure.staticInjectNext(props);
}