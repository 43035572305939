import React from 'react';
import { MenuItem, SubMenu } from '@opidcore/components/Menu.jsx';

import ListSupport, { EditSingleTask } from "./ListSupport.jsx";
import SupportImports from "./SupportImports.jsx";



export default function initialize(permissions, options) {
  if(permissions.w || true){
    window.APP.customRoutes.push({ path: "/ui/support/list", component: ListSupport, key: "support_list" });
    window.APP.customRoutes.push({ path: "/ui/support/case/:id", component: EditSingleTask, key: "support_dashboard_task" });
    window.APP.customRoutes.push({ path: "/ui/support/imports", component: SupportImports, key: "support_imports" });
  }

  APP.addMenu(() => {
    const items = []
    items.push(
      <SubMenu key="support">
        <MenuItem key="supportList" menuKey="supportList" linkTo="/ui/support/list" icon="user-headset" text="Support" />
        <MenuItem key="supportImports" menuKey="supportImports" linkTo="/ui/support/imports" icon="envelope" text="Grease Trap Import" />
      </SubMenu>
    );
    return items;
  })
}
