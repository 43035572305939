import React, { Component } from 'react';
//import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as CentralActions from '../../../OpidCore/public/js/actions/CentralActions';

import { InputText, Bound, InputCheckbox } from '../../../OpidCore/public/js/components';
import { WebSession } from '@opidcore/components/OpidApplication';

import * as _ from 'lodash';

class Login extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
                loginError: undefined
        };
    }
    
   
    componentDidMount(){  
        //let loggedInUser = localStorage.getItem("loggedInUser");      
        if (document.cookie){
            _.each( document.cookie.split(";"), (cookie)=>{
                const [key, val] = cookie.split("=");   
                if (key == "remember_username"){
                    this.setState({username: val});
                    this.setState({rememberMe: true});   
                } 
            });
        }
    }
 
    login(){
        if (this.state.rememberMe != true){
            document.cookie = "remember_username=; max-age=-1;";
         }

        this.props.central.TimedTokens.loginWithJson(this.state).then((r)=>{
                 if(r.result.status == "error"){
                      this.setState({loginError: r.result.message.join(' ')});
                   
                 } else if(r.result.status == "success") {
                     const that = this; 
                     localStorage.setItem("loggedInUser", r.result.user); 
                     sessionStorage.setItem("loggedInUser", r.result.user); 
                     localStorage.setItem("jwtToken", r.result.jwtToken);  
                     let cookieString = r.result.user+"="+r.result.jwtToken+ "; max-age=31536000;";            
                     document.cookie = cookieString.trim();                                
                     if (that.state.rememberMe){;
                        document.cookie = "remember_username=" + that.state.username + "; max-age=31536000;"; 
                     }
                     new WebSession(r.result.result);
                	 this.props.history.push("/ui/dashboard"); 
                 }        	
        });
    }
    
    checkForAutoLogin(e){
    	if (e.target.type == "password" && e.key == "Enter"){
    		this.login();
    	}
    }
    
    render(){        
      return (
        <div id="login-body" className="wtf">
          <div className="login-inner" tabIndex={-1} onKeyUp={(e) => this.checkForAutoLogin(e)}>
            <h2>Login</h2>
            <Bound to={this}>
              <InputText name="Username" field="username" className="form-group" />
  
              <InputText name="Password" ref="password" field="password" inputType="password" className="form-group" />
              <InputCheckbox name="Remember Me?" field="rememberMe" className="form-group" />
              <button type="button" className="btn btn-default" style={{ marginBottom: "20px" }} onClick={() => this.login()}>
                Login
              </button>
            </Bound>
  
            {this.state.loginError ? <div className="error">{this.state.loginError}</div> : ""}
          </div>
          {/*<a href="/forgot" className="faint">Forgot Password?</a>*/}
          <div style={{ color: "#999", fontSize: "0.75em", textDecoration: "none" }}>Please contact your manager or a member of the IT Team if you forgot your password.</div>
        </div>
      );
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.central.login || {}
    };
}

function mapDispatchToProps(dispatch) {
    return {
        ...CentralActions.modifyProps(dispatch),  
        actions: bindActionCreators({...CentralActions}, dispatch),    
    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login); 