import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Bound,
  Button,
  FlexBreak,
  Icon,
  InputSelect,
  InputText,
  InputTextPattern,
  LookupInputSelect,
  Tabbed,
  Tabular,
  TabularColumn,
  TabularDataSet,
  UnlockToEdit
} from "@opidcore/components";
import BoundCommit from "@opidcore/components/BoundCommit";
import { useData, useNavBar } from "@opidcore/hooks/WTF";


import { AddressBlock, StaffSelect, File } from "../../components";

import { RoutedDefaults } from "@opidcore/components/OpidApplication";
import { useParams } from "react-router-dom";


import ActionBar from "@opidcore/components/ActionBar";
import Searchable from "@opidcore/components/Searchable";
import _ from "lodash";
import ViewPanel, { Field } from "../../components/ViewLayouts";

import FullTextSearchable from "../../components/FullTextSearchable";
import ChangeLogs from "../ChangeLog/View";
import Notes from "../Notes";
import ListSites from "../Site/ListSites";
import Tasks from "../Tasks";

export default function VendorEditTabs(props) {
  useNavBar("Edit Vendor");

  return (
    <Tabbed title="Edit Vendor">
      <VendorView {...props} key="vendor_edit" title="Vendor Details" />
      <ContractsList {...props} key="vendor_edit" title="Contracts" />
      <APInvoiceList {...props} key="vendor_invoices" title="Invoices" />
      <ListSites forVendor={props.match.params.id} key="list_sites" title="Client Sites" />
    </Tabbed>
  );
}

export function APInvoiceList(props) {
  const vendorFriendlyId = props.match.params.id;
  const [invoices, setInvoices] = useState([]);
  const [fetchInvoices, setFetchInvoices] = useState(false);

  return (
    <div key="list_invoices">
      <ActionBar title="Actions">
        <FullTextSearchable
          model={"Vendor"}
          method={"reasonableList"}
          setData={setInvoices}
          doFetchData={fetchInvoices}
          setDoFetchData={setFetchInvoices}
          additionalParams={[vendorFriendlyId]}
          allowBlank={true}
          maxRows={300}
          filterOptions={{
            columns: [{ column: "status", heading: "Status" }],
          }}
        />
      </ActionBar>

      <Tabular data={invoices} pageSize={50} paginationLocation={"both"}>
        <TabularColumn data={(row) => row.external_invoice_number} title="Invoice #" />
        <TabularColumn data={(row) => row.date} title="Invoice Date" />
        <TabularColumn data={(row) => row.total} title="Total" />
        <TabularColumn data={(row) => row.status} title="Status" />

        <TabularColumn
          data={(row) => {
            return (
              <Link to={"/ui/apinvoice/" + row.id}>
                <Icon icon="caret-right" size="2x" />
              </Link>
            );
          }}
        />
      </Tabular>
    </div>
  );
}

export function ContractsList(props) {
  const id = props.match.params.id;
  const dateFormat = "YYYY-MM-DD";
  const extra = useContext(RoutedDefaults);
  const [contractList, dataSet] = useData("vendorcontract;" + id, { method: "listContracts", Vendor: id });

  return (
    <div key="list_contracts">
      <ActionBar title="Actions">
        <Searchable ds={dataSet} />
      </ActionBar>

      {dataSet.loading ? "loading" : ""}

      <TabularDataSet
        dataSet={dataSet}
        onClick={(row) => {
          APP.redirect("/ui/vendorcontract/edit/" + row.friendly_id);
        }}
      >
        <TabularColumn data={(row) => row.friendly_id} title="#" />
        <TabularColumn data={(row) => row.expiryStatus} title="Expiry Status" sortCol="expiry_status" />
        <TabularColumn data={(row) => row.clients} title="Clients" />
        <TabularColumn data={(row) => <span title={row.sites}>{row.site_count}</span>} title="Sites" />
        <TabularColumn data={(row) => (row.effective_from ? moment(row.effective_from).format(dateFormat) : "-")} title="Effective From" />
        <TabularColumn data={(row) => row.contract_term} title="Contract Term" sortCol="contract_term" />
        <TabularColumn data={(row) => (row.effective_to ? moment(row.effective_to).format(dateFormat) : "-")} title="Effective To" />
        <TabularColumn data={(row) => (row.internal_notes ? row.internal_notes : "-")} title="Internal Notes" />
        <TabularColumn data={(row) => row.externalContractNumber} title="External Contract Number" sortCol="external_vendor_contract_number" />
        <TabularColumn data={(row) => (row.invoice_timing ? row.invoice_timing : "-")} title="Invoice Timing" />
        <TabularColumn data={(row) => row.status} title="Status" sortCol="status" />
        <TabularColumn
          data={(row) => {
            return (
              <Link to={"/ui/vendorcontract/edit/" + row.friendly_id}>
                <Icon icon="caret-right" size="2x" />
              </Link>
            );
          }}
          title=" "
        />
      </TabularDataSet>
    </div>
  );
}

export function EditContact(props) {
  return (
    <div>
      <BoundCommit initialValue={props.contact} forceSaveProps={["vendor"]} commit={APP.central.Vendor.saveContact} className="basicForm">
        <InputText field="firstName" name="First Name" />
        <InputText field="lastName" name="Last Name" />
        <InputText field="title" name="Title" />
        <InputText field="email" name="Email" />
        <InputText field="phone" name="Phone Number" />
        <InputText field="cellPhone" name="Mobile Number" />
      </BoundCommit>
    </div>
  );
}

function PersonContactCard({ person, edit }) {
  const editContact = () => {
    APP.instance.createModal(<EditContact contact={person} />);
  };

  return (
    <div className="person">
      <div className="name">
        {person.firstName} {person.lastName}
      </div>
      <div className="email">
        <Icon icon="envelope" /> {person.email}
      </div>
      <div className="phone">
        <Icon icon="phone" /> {person.cellPhone}
      </div>
      {edit ? (
        <Button onClick={editContact} className="small white">
          <Icon icon="user-edit" /> Edit Contact
        </Button>
      ) : null}
    </div>
  );
}

function ContactList(props) {
  const extra = useContext(RoutedDefaults);
  //TODO was this here as a reminder?
  //debugger;
  const vendorId = props.vendor ? props.vendor.id : 0;

  const [contactList, contactListDataset] = useData("contacts-" + vendorId, APP.central.Vendor.fetchContacts, { id: vendorId });

  const addContact = () => {
    APP.instance.createModal(<EditContact contact={{ __new: true, __dataUID: contactListDataset.dataUID, vendorId: props.vendor.id }} />, { onClose: () => refreshContacts() });
  };

  return (
    <div className="full">
      {contactList.map((c) => (
        <PersonContactCard person={c} key={c.id} edit={true} />
      ))}

      <Button onClick={addContact}>Add Contact</Button>
    </div>
  );
}

//vendor view
export function VendorView(props) {
  const nb = useNavBar("Edit Vendor");
  let params = useParams();
  const extra = useContext(RoutedDefaults);
  const vendorId = params.id;
  const [vendor, dataSet] = useData("vendor", vendorId, extra);
  const [staffData, ataffDataSet] = useData("staff");
  const [contactList, contactListDataset] = useData("contacts", APP.central.Vendor.fetchContacts, { id: vendorId });

  let parentLink = null;
  if (vendor && vendor.parentVendor) {
    parentLink = (
      <Link to={"/ui/vendors/edit/" + vendor.parentVendor.friendlyId}>
        <Icon icon="sitemap" />
      </Link>
    );
  }

  useEffect(() => {
      const navbarUpdateOptions = nb.createUpdateOptions( 
      vendor.name,
      <>
        {vendor.name} {parentLink}
      </>
    );

    nb.fullUpdate(navbarUpdateOptions);
  }, [vendor]);

  const [isEditing, setIsEditing] = useState(false);

  if (isEditing) {
    return <VendorEdit {...props} setIsEditing={setIsEditing} />; 
  }

  //building the full name of payable staff
  const findPayableStaffName = (payableStaffId)=>{
    const foundPayableStaff = staffData.find((element)=> element.id === payableStaffId);
    return foundPayableStaff ?  foundPayableStaff.firstName + " " + foundPayableStaff.lastName : "";
  }

  //formatting payment method 
  const formatPaymentMethod= (paymentMethod) =>{
    let formattedPayment = '';
    switch(paymentMethod){
      case 'cheque' :
        formattedPayment = 'Cheque';
        break;
      case 'creditCard':
        formattedPayment = 'Credit Card';
        break;

      case 'eft':
        formattedPayment = 'EFT';
        break;
      case 'onlineBanking':
        formattedPayment = 'Online Banking'
        break;
        default :
        formattedPayment = paymentMethod;
    }
    return formattedPayment;  
  }

  const childLocations = [];
  _.each(vendor.childLocations, (location) => {
    childLocations.push(
      <div style={{ width: "30em" }}>
        <Field label={location.name}>{location.address}</Field>
        <Field label="City">{location.city}</Field>
        <Field label="Province">{location.province}</Field>
        <Field label="Postal Code">{location.postalCode}</Field>  
      </div>
    );
  });

  let url = vendor.website;
  if (url && url.length > 0 && url.indexOf("http") != 0) {
    url = "https://" + vendor.website;
  }
  return (
    <div>
      <ActionBar>
        <div style={{ flex: 5 }}></div>
        <Notes model={vendor} />
        <Tasks model={vendor} />
        <ChangeLogs model={vendor} />
      </ActionBar>

      <ViewPanel>
        <Field label="Vendor #">{vendor.friendlyId}</Field>
        <Field label="Vendor Name">{vendor.name}</Field>
        <Field label="Region">{vendor.vendorRegion}</Field>
        <FlexBreak />
        <Field label="Account #">{vendor.accountNumber}</Field>
        <Field label="Email">
          <a href={"mailto:" + vendor.email}>{vendor.email}</a>
        </Field>
        <Field label="Phone">{vendor.phone}</Field>
        <Field label="Website">
          <a href={url} target="_new">
            {vendor.website}
          </a>
        </Field>

        <FlexBreak className="line" />
        {contactList.length == 0 && <Field label="Contacts"></Field>}

        {contactList.map((contact) => (
          <>
            <Field label="Name">
              {contact.firstName} {contact.lastName}
            </Field>
            <Field label="Title">{contact.title}</Field>
            <Field label="Email">
              {contact.email ? (
                <a href={"mailto:" + contact.email}>
                  <Icon icon="envelope" /> {contact.email}
                </a>
              ) : null}
            </Field>
            <Field label="Phone">
              {contact.phone ? (
                <>
                  <Icon icon="phone" /> {contact.phone}
                </>
              ) : null}
            </Field>
            <Field label="Mobile">
              {contact.cellPhone ? (
                <>
                  <Icon icon="mobile" /> {contact.cellPhone}
                </>
              ) : null}
            </Field>
            <FlexBreak />
          </>
        ))}

        <FlexBreak className="line" />

        <Field label="Accounting Group">{vendor.accountingGroup}</Field>
        <Field label="AP Coordinator">{vendor.payablesStaff && staffData ? findPayableStaffName(vendor.payablesStaff.id) : ""}</Field>
        <Field label="Payment Method">{vendor.paymentMethod && formatPaymentMethod(vendor.paymentMethod)}</Field>

        <FlexBreak className="line" />
        <div style={{ width: "30em" }}>
          <Field label="Billing Address">{vendor.address}</Field>
          <Field label="City">{vendor.city}</Field>
          <Field label="Province">{vendor.province}</Field>
          <Field label="Postal Code">{vendor.postalCode}</Field>
        </div>

        {childLocations}

        <FlexBreak />
        {vendor.id ? (
          <Bound to={vendor} boundId="vendorFiles">
            <File showHistoricalSet={true} />
          </Bound>
        ) : null}
      </ViewPanel>

      <Button onClick={() => setIsEditing(true)} className="white">
        <Icon icon="edit" />
        Edit
      </Button>
    </div>
  );
}

export function VendorEdit(props) {
  const nb = useNavBar("Edit Vendor");
  let params = useParams();
  const extra = useContext(RoutedDefaults);
  const vendorId = params.id;
  const [data, dataSet] = useData("vendor", vendorId, extra);

  const siteStatus = { active: "Active", inactive: "Inactive" };

  const [isUS, setIsUS] = useState(false);

  let parentLink = null;
  if (data && data.parentVendor) {
    parentLink = (
      <Link to={"/ui/vendors/edit/" + data.parentVendor.friendlyId}>
        <Icon icon="sitemap" />
      </Link>
    );
  }

  if (data && data.name) {
    nb.update(
      <>
        {data.name} {parentLink}
      </>,
      nb.items
    );
  }

  const updateCountry = (a, b, c) => {
    if (c == "United States") {
      setIsUS(true);
    } else {
      setIsUS(false);
    }
  };

  const addVendorLocation = () => {
    APP.central.Vendor.addChildVendor(data.id).then((r) => {
      APP.central.Util.clearCache(dataSet.uid);
      dataSet.fetch();
    });
  };

  const childLocations = [
    <ul className="clean-list">
      {data && data.childLocations
        ? data.childLocations.map((v) => (
            <li>
              <Link to={"/ui/vendors/edit/" + v.friendlyId}>
                {v.name} <Icon icon="arrow-right" />{" "}
              </Link>
            </li>
          ))
        : null}
      {data && data.parentVendor ? (
        <li>
          <Link to={"/ui/vendors/edit/" + data.parentVendor.friendlyId}>
            {" "}
            {"Parent"} <Icon icon="sitemap" />
          </Link>
        </li>
      ) : null}
      <li>
        {" "}
        <Button onClick={() => addVendorLocation(true)} className="white">
          Add Location <Icon icon="plus" />
        </Button>{" "}
      </li>
    </ul>,
  ];

  const permissions = vendorId == 0 ? APP.getWebSession().permissions("Controlled Data Lists") : { r: true, w: true, x: true };

  return (
    <div key="edit_site" className="basicForm">
      <ActionBar>
        <Icon icon="chevron-left" size="2x" title="Stop Editing" onClick={() => props.setIsEditing(false)} />
        <div style={{ flex: 5 }}></div>
        <Notes model={data} />
        <Tasks model={data} />
        <ChangeLogs model={data} />
      </ActionBar>

      <BoundCommit initialValue={data} dataSet={dataSet} commit={APP.central.Vendor.saveChanges} permissions={permissions}>
        <div className="small-flex">
          <UnlockToEdit>
            <InputText field="friendlyId" name="Vendor #" />
          </UnlockToEdit>
        </div>

        <UnlockToEdit unlocked={APP.getWebSession().permissions("Controlled Data Lists").w}>
          <InputText className="larger" field="name" name="Name" required={true} />
        </UnlockToEdit>

        <FlexBreak />
        <LookupInputSelect what="lookup:vendorRegion" field="vendorRegion" name="Region" showEmpty={true} />
        <LookupInputSelect what="lookup:vendorType" field="vendorType" name="Type" showEmpty={true} />
        <InputSelect field="historical" name="Status" field="status" options={siteStatus} showEmpty={true} required={true} />

        <InputText field="accountNumber" name="Account Number" />
        <InputText field="email" name="Email" required={true} />
        <InputText field="phone" name="Phone" />
        <InputText field="website" name="Website" />

        {data && data.parentVendor ? <InputText field="vendorAccountNumberMatch" name="Account Number Match" /> : null}

        <AddressBlock className="full" mapFields={["address", "city", "province"]}>
          <InputText field="address" name="Address Line 1" />
          <InputText field="addressLine2" name="Address Line 2" />
          <InputText field="city" name="City" />
          <LookupInputSelect what="lookup:State Province" parameter1={isUS ? "USA" : "CAN"} field="province" name={isUS ? "State" : "Province"} />
          {isUS ? (
            <InputTextPattern field="postalCode" name="Zip Code" message="54321" pattern={"^([0-9][0-9][0-9][0-9][0-9])$"} />
          ) : (
            <InputTextPattern field="postalCode" name="Postal Code" message="A0A 1B1" pattern={"^([A-Z][0-9][A-Z]) ?([0-9][A-Z][0-9])$"} />
          )}
          <LookupInputSelect what="lookup:Country" name="Country" field="billingCountry" onChange={(a, b, c) => updateCountry(a, b, c)} />
        </AddressBlock>

        <FlexBreak />
        <h3>Team Members</h3>
        <LookupInputSelect what="lookup" field="accountingGroup" name="Accounting Group" defaultValue="WSC" forcedDefault={true} style={{ width: "10%" }} />
        {/* <StaffSelect what="staff" field="billingAnalyst" name="Billing Team Member" /> */}
        <StaffSelect what="staff" field="payablesStaff" name="AP Coordinator" />
        <LookupInputSelect what="lookup:payablesPaymentMethod" field="paymentMethod" name="Payment Method" />

        <FlexBreak />

        <FlexBreak />
      </BoundCommit>
      <FlexBreak />
      <h3>Contacts</h3>
      {data.id > 0 ? <ContactList vendor={data} /> : null}

      <h3>Locations &amp; Related Vendors</h3>
      {/*childLocations*/}
      <ChildLocations vendor={data} addLocation={addVendorLocation} />

      {data.id ? (
        <Bound to={data} boundId="vendorFiles">
          <File showHistoricalSet={true} />
        </Bound>
      ) : null}
    </div>
  );
}

export const ChildLocations = (props) => {
  const data = props.vendor;

  return (
    <ul className="clean-list">
      {data && data.childLocations
        ? data.childLocations.map((v) => (
            <li>
              <Link to={"/ui/vendors/edit/" + v.friendlyId}>
                {v.name} <Icon icon="arrow-right" />
              </Link>
            </li>
          ))
        : null}
      {data && data.parentVendor ? (
        <li>
          <Link to={"/ui/vendors/edit/" + data.parentVendor.friendlyId}>
            {"Parent"} <Icon icon="sitemap" />
          </Link>
        </li>
      ) : null}
      <li>
        <Button onClick={() => props.addLocation()} className="white">
          Add Location <Icon icon="plus" />
        </Button>
      </li>
    </ul>
  );
};
