import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useLocalData, useNavBar } from "@opidcore/hooks/WTF";
import { LookupInputSelect, TabularColumn, Button, Bound, Icon, Tabular, Selection, TabularDataSet, Searchable, Loading, InputText, Tabbed, DatePicker } from "@opidcore/components";
import * as _ from "lodash";
import { NiceCurrency } from "../Nice";
import LoadingProgress from "@opidcore/components/LoadingProgress";
import LookupStatusSelect from "@opidcore/components/LookupStatusSelect";
import CesarFilter from "../../components/CesarFilter";
import APInvoiceEdit from "./EditAPInvoice";
import { CurrentModal } from "@opidcore/components/OpidApplication";
import ViewPanel from "../../components/ViewLayouts";
import moment from "moment";

export default function ShortPayExport(props) {
  useNavBar("Short Pay Export", []);

  const defaultEndDate = moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD");
  const defaultStartDate = moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");

  const filters = useRef({ vendor: -1, invNumbers: "", startDate: defaultStartDate, endDate: defaultEndDate });
  const selectedStatus = useRef({ export_status: "" });
  const [selection, setSelection] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noDataString, setNoDataString] = useState("No Short Pays Found For This Vendor");

  const [data, dataSet] = useLocalData("vendor;shortPays" + filters.current.vendor + filters.current.startDate + filters.current.endDate, {
    method: "listShortPaysGrouped",
    vendorId: filters.current.vendor,
    startDate: filters.current.startDate,
    endDate: filters.current.endDate,
  });

  useEffect(() => {
    if (!dataSet.loading) {
      setTimeout(() => {
        setLoading(false);
      }, 2000); // theres gotta be a way to check if a react component is done rendering
    }
  }, [dataSet.loading]);

  const doClearSelection = () => {
    setSelection([]);
  };

  const doSelectVendor = () => {
    //check that no more than a 3 month range has been selected
    const startDate = moment(filters.current.startDate).month();
    const endDate = moment(filters.current.endDate).month();
    const diff = endDate - startDate;
    if (diff >= 3 || diff <= -3) {
      setNoDataString("Please select a date range of 3 months or less");
    } else {
      setNoDataString("No Short Pays Found For This Vendor");
    }

    setLoading(true);
    doClearSelection();
    APP.central.Util.clearCache(dataSet.uid);
    dataSet.reset();
    dataSet.fetch();
  };

  const doShortPayExport = () => {
    if (selection.length <= 0) {
      return;
    }

    const idList = _.map(selection, (s) => s.ap_id);
    APP.showLoading({ message: "Generating Report", children: <LoadingProgress /> }, () => {
      APP.central.Vendor.listShortPaysForExport(filters.current.vendor, idList, filters.current.startDate, filters.current.endDate).then((r) => {
        APP.central.APInvoice.getShortPayExportLayout().then((layout) => {
          APP.central.Exporter.doExport(r.result.uid, layout.result).then((ex) => {
            APP.socket.send(JSON.stringify(ex));
            //we did it
          });
        });
      });
    });
  };

  const doSetExportStatus = () => {
    const idList = _.map(selection, (s) => s.ap_id);
    if (idList.length > 0) {
      APP.showLoading({ message: "Setting Status", children: <LoadingProgress onFinishLoad={() => doSelectVendor()}/> }, () => {
        APP.central.Vendor.listShortPaysForExport(filters.current.vendor, idList, filters.current.startDate, filters.current.endDate).then((r) => {
          APP.central.APInvoice.setApInvoiceLinesExportStatus(r.result.rows, selectedStatus.current.export_status).then(() => {
            APP.socket.send(JSON.stringify(r));
            //doSelectVendor();
          });
        });
      });
    }
  };

  const viewDetails = (apGroup) => {
    if (selection.length > 0) {
      APP.instance.createModal(<ViewDetailsWithTabs data={selection} apId={apGroup.ap_id} />, { modal_name: "Details For Selected" });
    } else {
      APP.instance.createModal(<ViewDetails apGroup={apGroup} />, { modal_name: "Details" + (apGroup.ap_id ? " - AP#" + apGroup.ap_id : "") });
    }
  };

  const doSelectAll = () => {
    setSelection(data);
  };

  const doFilterByInvNumbers = () => {
    doClearSelection();
    const splitString = filters.current.invNumbers.split(/[ ,\n]+/);
    const splitInvNumbers = _.filter(splitString, _.size);
    dataSet.unfilter("ap_external_id");
    if (splitInvNumbers.length > 0) {
      dataSet.filter({ ["ap_external_id"]: splitInvNumbers }, true);
    } else {
      dataSet.reset();
    }
  };

  const doShowAll = () => {
    filters.current.vendor = 0;
    doSelectVendor();
  };

  const modalOptions = {
    afterClose: () => {
      // probably some kind of refresh?
    },
  };

  const doOpenModal = () => {
    if (selection.length > 0) {
      APP.instance.createModal(<EditAPsWithTabs data={selection} apId={selection[0].ap_id} />, { modal_name: "AP Invocies" }, modalOptions);
    }
  };

  return (
    <div className="full-size">
      <div>
        <div className="shortPayButtonContainer">
          <Bound to={filters.current} onChange={doSelectVendor}>
            <LookupInputSelect className="shortPayVendorSelect" what="active_vendors" fetch={APP.central.Vendor.listActiveVendors} field="vendor" showEmpty={true} bound={true} name="Select Vendor" />
            <div style={{ display: "flex", alignItems: "center" }}>
              <DatePicker field="startDate" name="From" />
              <DatePicker field="endDate" name="To" />
            </div>
          </Bound>
          <Button onClick={() => doShowAll()}>All Vendors</Button>
        </div>

        <CesarFilter
          columns={[
            { column: "export_status", lookup: "exportStatus", default: "no_status" },
            { column: "client_name" },
            { column: "client_type" },
            { column: "site_name" },
            { column: "ap_external_id" },
          ]}
          dataSet={dataSet}
          onChange={doClearSelection}
          reInitOptions={["vendorId"]}
        />

        <div className="shortPayButtonContainer">
          <div className="invoiceNumberTextArea">
            <Bound to={filters.current} onChange={() => doFilterByInvNumbers()}>
              <InputText inputType="textarea" field="invNumbers" name="Filter By Invoice #" />
            </Bound>
          </div>

          <Searchable ds={dataSet} onChange={doClearSelection} />
         <ViewPanel> 
          <Button style={{ marginRight:"10px"}} onClick={() => doShortPayExport()}>Export</Button> 

        <div style={{ gap: "1em", display: "flex", alignItems: "center", marginLeft: "auto" }}>
            <Button onClick={() => doSetExportStatus()}>Set Status For Selection</Button> 
            <Bound to={selectedStatus.current}>
            {/*<LookupStatusSelect lookupName="exportStatus" field="export_status" showEmpty={true} />*/}
                <LookupInputSelect what="lookup:exportStatus" field="export_status" showEmpty={true} />
            </Bound>

            <Button onClick={() => doOpenModal()}>Edit Selected</Button>

            {data.length > selection.length ? <Button onClick={() => doSelectAll()}>Select All</Button> : <Button onClick={() => doClearSelection()}>Clear Selection</Button>}
        </div></ViewPanel>
        
      </div> 
      </div>
      <br />

      {filters.current.vendor == -1 ? (
        "Select a Vendor"
      ) : loading ? (
        <Loading />
      ) : (
        <Fragment>
          <div>
            <TabularDataSet dataSet={dataSet} noData={noDataString}>
              {filters.current.vendor == 0 ? (
                <TabularColumn
                  data={(row) => {
                    if (!row.vendor) {
                      return "-";
                    }
                    return (
                      <Link className="plain_link" to={"/ui/vendors/edit/" + row.vendor}>
                        {row.vendor_name}
                      </Link>
                    );
                  }}
                  title="Vendor"
                />
              ) : null}
              <TabularColumn
                data={(row) => {
                  if (!row.client) {
                    return "-";
                  }
                  return (
                    <Link className="plain_link" to={"/ui/clients/" + row.client}>
                      {row.client_name}
                    </Link>
                  );
                }}
                title="Client"
              />
              <TabularColumn
                data={(row) => {
                  if (!row.site) {
                    return "-";
                  }
                  return (
                    <Link className="plain_link" to={"/ui/sites/detail/" + row.site}>
                      {row.site_name}
                    </Link>
                  );
                }}
                title="Site"
              />
              <TabularColumn
                data={(row) => {
                  return (
                    <Link className="plain_link" to={"/ui/apinvoice/" + row.ap_id}>
                      {row.ap_external_id}
                    </Link>
                  );
                }}
                title="External Invoice #"
              />
              <TabularColumn
                data={(row) => {
                  return (
                    <Link className="plain_link" to={"/ui/apinvoice/" + row.ap_id}>
                      {row.ap_id}
                    </Link>
                  );
                }}
                title="AP #"
                style={{ width: "5em" }}
              />
              <TabularColumn data={(row) => row.ap_date} title="AP Invoice Date" />
              <TabularColumn data={(row) => <NiceCurrency>{row.total}</NiceCurrency>} title="Invoice Total" />
              <TabularColumn data={(row) => <NiceCurrency>{row.shortPayTotal}</NiceCurrency>} title="Total Short Pay" />
              <TabularColumn
                data={(row) => {
                  return <Icon icon="eye" onClick={() => viewDetails(row)} size="2x" />;
                }}
                title="View Details"
                style={{ width: "10em" }}
              />
              <TabularColumn
                data={(row) => (
                  <Bound to={row}>
                    <LookupStatusSelect lookupName="exportStatus" field="export_status" disabled={true} />
                  </Bound>
                )}
                title="Export Status"
              />
              <TabularColumn
                data={(row) => (
                  <Fragment>
                    <Selection selection={selection} data={data} setter={setSelection} item={row} label={(row) => ""} />
                  </Fragment>
                )}
                title="Select"
              />
            </TabularDataSet>
          </div>
        </Fragment>
      )}
    </div>
  );
}

function ViewDetails(props) {
  const [apGroup, setApGroup] = useState(props.apGroup);
  const [selection, setSelection] = useState([]);
  const [loading, setLoading] = useState(false);

  const currentModal = useContext(CurrentModal);

  useEffect(() => {
    setApGroup(props.apGroup);
  }, [props]);

  const openService = (serviceFriendlyId) => {
    APP.instance.createModal("/ui/services/edit/" + serviceFriendlyId, { modal_name: "Service #" + serviceFriendlyId });
  };

  const doAdjustSelected = () => {
    setLoading(true);
    const something = _.map(selection, "id");

    APP.central.APInvoice.adjustAP(apGroup.ap_id, something).then((r) => {
      if (r.result.id != undefined && r.result.id > 0) {
        APP.redirect("/ui/apinvoice/" + r.result.id);
        setLoading(false);
        APP.instance.closeModal(currentModal.id);
      }
    });
  };

  return (
    <div className="full-size">
      <Button onClick={() => doAdjustSelected()}>Adjust Selected</Button>
      {loading ? <Loading /> : null}

      <Tabular data={apGroup.lines} noData={"No Short Pays Found For This Vendor"}>
        <TabularColumn data={(row) => <NiceCurrency>{row.total}</NiceCurrency>} title="Original Amount" />
        <TabularColumn data={(row) => <NiceCurrency>{row.payment_adjustment}</NiceCurrency>} title="Payment Adjustment" />
        <TabularColumn data={(row) => row.payment_adjustment_notes} title="Note" />
        <TabularColumn data={(row) => row.ar_status} title="AR Status" />
        <TabularColumn
          data={(row) => (
            <div className="service-info simple-link" onClick={() => openService(row.service_friendlyid)} title={"Customer: " + apGroup.client_name + ", Site: " + apGroup.site_name}>
              {row.service_friendlyid} {row.service_container_name}
            </div>
          )}
          title="Service"
        />
        <TabularColumn data={(row) => row.service_description} title="Service Description" />
        <TabularColumn
          data={(row) => (
            <Bound to={row}>
              <LookupStatusSelect lookupName="exportStatus" field="export_status" disabled={true} />
            </Bound>
          )}
          title="Export Status"
        />
        <TabularColumn
          data={(row) => (
            <Fragment>
              <Selection selection={selection} data={apGroup.lines} setter={setSelection} item={row} label={(row) => ""} />
            </Fragment>
          )}
          title="Select"
        />
      </Tabular>
    </div>
  );
}

export function EditAPsWithTabs(props) {
  const apIdList = _.map(props.data, (d) => d.ap_id);
  const titles = _.map(props.data, (d) => {
    let title = "";
    title += d.ap_external_id;
    return title;
  });
  const apIdSelected = props.apId;
  let defaultIndex = null;

  const serviceSetups = _.map(apIdList, (id, i) => {
    if (id == apIdSelected) {
      defaultIndex = i;
    }
    return <APInvoiceEdit match={{ params: { id: id } }} key={"ap_inv" + id} title={titles[i]} />;
  });

  return (
    <Tabbed inline={true} title="Edit AP" defaultTab={defaultIndex ? defaultIndex : 0}>
      {serviceSetups}
    </Tabbed>
  );
}

function ViewDetailsWithTabs(props) {
  const apIdList = _.map(props.data, (d) => d.ap_id);
  const titles = _.map(props.data, (d) => {
    let title = "";
    title += d.ap_external_id;
    return title;
  });
  const apIdSelected = props.apId;
  let defaultIndex = null;

  const viewDetails = _.map(apIdList, (id, i) => {
    if (id == apIdSelected) {
      defaultIndex = i;
    }
    return <ViewDetails apGroup={props.data[i]} title={titles[i]} />;
  });

  return (
    <Tabbed inline={true} title="View Details" defaultTab={defaultIndex ? defaultIndex : 0}>
      {viewDetails}
    </Tabbed>
  );
}
