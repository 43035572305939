import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { library } from '@fortawesome/fontawesome-svg-core';
//import { svg } from 'd3-fetch';

import { BoundDataContext } from '@opidcore/components';

import('@fortawesome/pro-light-svg-icons')
    .then(mod => {
        library.add(mod.fal);
        if (window._GLOBAL_ICONS) {
            library.add(_.values(window._GLOBAL_ICONS))
        }
    }).catch(e => {
        console.log("could not find the fortawesome module");
    });

export default function IconNew(props) {
    const Type = props.type || (props.onClick ? "button" : "span");
    const lib = library;
    const fontAwesomeType = props.fType || "fal";
    const [loading, setLoading] = useState(0);

    const bound = useContext(BoundDataContext)
    var extraProps = {}

    if (props.onClick) {
        extraProps.onClick = () => {
            props.onClick(bound)
        }
    }

    if (props.onAuxClick) {
        extraProps.onAuxClick = () => {
            props.onAuxClick(bound)
        }
    }

    if (lib.definitions[fontAwesomeType] == null) {

        if (loading > 50) {
            console.log("Error with Font Awesome Library " + fontAwesomeType);
        } else {
            setTimeout(() => {
                setLoading(loading + 1);
            }, 100);
        }
        return null;
    }

    if (props.icon == null || props.icon == "") {
        return null;
    }

    const svg = <FontAwesomeIcon title={props.title} icon={[fontAwesomeType, props.icon]} size={props.size} color={props.colour || props.color || "#00bacd"} />;

    if (props.svg) {
        return svg;
    }

    return (<>
        <Type {...extraProps} className={"icon-outer " + props.className} onMouseOver={props.onMouseOver}>{svg}</Type>
    </>
    );
}