import React, {
    Component,
    PropTypes
} from 'react';
import nv from 'nvd3';
import * as d3 from "d3";

import * as _ from 'lodash';
import something from './OpidBar.jsx';
import OpidGraphBase from './OpidGraphBase.jsx';

export default class BarGraph extends OpidGraphBase {
    constructor(props) {
        super(props);
        this.state = {
            renders: 0
        };
    }

    render() {       
        return <div className = "hey" >
            <
            div className = "graph-contents"
        ref = "graph-contents" > < svg ref = {
            svgNode => this.svgNode = svgNode
        }
        /></div >
        <
        /div>
    }

    
    static getEditableProps() {
        let options = [{
            key: "",
            value: ""
        }, {
            key: "no",
            value: "No"
        }, {
            key: "yes",
            value: "Yes"
        }];

        return [{
                niceName: "Plot",
                prop: "plot",
                type: "text",
                default: "",
                allowJS: true
            },
            {
                niceName: "Orientation",
                prop: "orientation",
                type: "text",
                options: ["horizontal", "vertical"],
                default: "horizontal"
            },
            {
                niceName: "Height",
                prop: "height",
                type: "text",
                default: "10em",
                allowJS: true
            },
            {
                niceName: "Width",
                prop: "width",
                type: "text",
                default: "10em"
            },
            {
                niceName: "Margins",
                prop: "margins",
                type: "text",
                default: "10em"
            },
            {
                niceName: "Show Legend",
                prop: "showLegend",
                type: "checkbox",
                default: true
            },
            {
                niceName: "Colour Set",
                prop: "colourProvider",
                type: "text",
                default: true,
                allowJS: true
            },
            {
                niceName: "Y Axis Label",
                prop: "yAxisLabel",
                type: "text"
            },
            {
                niceName: "X Axis Label",
                prop: "xAxisLabel",
                type: "text"
            },
        ]

    }

 	static metaInfo(){
  		return {renderAsPng: true, icon: "chart-bar", preview: (me)=>{ return "?" } };
  	}

    componentDidUpdate(prevProps, prevState, snapshot) {
        var changed = _.isEqual(prevProps.plot, this.props.plot) == false;

        _.each(BarGraph.getEditableProps(), (p) => {
            changed = changed || (prevProps[p.prop] != this.props[p.prop]);
        });

        if (changed) {
            this.renderGraph(this.props);
        }
    }

    componentDidMount() {
        this.renderGraph(this.props);
        if (window.isIE) {
            //alert(" I know oyu are ie");
            if (window.charts == null) {
                window.charts = []
            }

            window.charts.push(this);
            setTimeout(() => {
                this.chart.update()
            }, 1000);
        }
    }

    renderGraph(props) {

        var legendPosition = "top";
        var showTotals = false;

        if (props && props.legendPosition) {
            legendPosition = props.legendPosition;
        }

        if (props && props.showTotals) {
            showTotals = props.showTotals;
        }


        var datasets = false;
        var iamData = [];

        if (props == null || props.plot == null) {
            return null;
        }

        var initialData = {
            ...props.plot
        };

        if(Array.isArray(props.plot)){
            datasets = true;
            
            iamData = iamData.concat(props.plot);
        } else if (initialData[0] != null && initialData[0]["dataset"] != null) {
            datasets = true;

            _.each(initialData, ds => {
                var bah = {
                    key: ds["dataset"],
                    values: _.map(ds["values"], function(a, b) {
                        return {
                            label: b,
                            value: a
                        }
                    })
                };
                bah.values = _.sortBy(bah.values, r => r.label);
                iamData.push(bah);
            });

        } else {
            iamData.push({
                key: "DataSet1",
                values: _.map(initialData, function(a, b) {
                    return {
                        label: b,
                        value: a
                    }
                })
            });

        }
        var maxlength = 3;




        var truncateTo = 0;

        if (props.truncateTo) {
            truncateTo = props.truncateTo;
        }

        if (props.order != undefined) {
            if (props.order == "lowest") {
                iamData[0].values = _.sortBy(iamData[0].values, 'value');
            } else if (props.order == "highest" || props.top > 0) {
                iamData[0].values = _.sortBy(iamData[0].values, 'value').reverse();
            } else if (props.order == "alphabetical") {
                iamData[0].values = _.sortBy(iamData[0].values, 'label');
            } else {
                iamData[0].values = _.sortBy(iamData[0].values, 'value').reverse(); //how we sorted before giving them the option to change sort order
            }
        }

        var totals = {};
        _.each(_.flatten(_.map(iamData, 'values')), d => {
            totals[d.label] = totals[d.label] || 0;
            totals[d.label] += d.value
        });
        var allLabels = _.keys();

        var sortedLabels = _.sortBy(_.keys(totals), k => {
            return props.order == "alphabetical" ? k : totals[k]
        });

        if (props.order == "lowest" || props.order == "alphabetical") {
            // do nothing, my if just looks nicer this way
        } else {
            sortedLabels = sortedLabels.reverse();
        }

        _.each(iamData, plot => {

            plot.values = _.sortBy(plot.values, p => {
                return sortedLabels.indexOf(p.label)
            });

        });

        if (props.values_to_show != undefined) {
            iamData[0].values = _.slice(iamData[0].values, 0, props.values_to_show);
        } else if (props.top > 0) {
            iamData[0].values = _.slice(iamData[0].values, 0, props.top);
        }



        if (props.values_to_show == null) {
            maxlength = _.max(_.map(sortedLabels, l => l.length));
        } else {
            maxlength = _.max(_.map(_.slice(sortedLabels, 0, props.values_to_show), l => l.length));
        }

        //maxlength = _.max( _.map( _.flatten(_.map(iamData, 'values'), r=>r.label);
        if (datasets == false && props.values_to_show != null) {
            //    maxlength = _.max(_.map(iamData[0].values, r=>r.label.length))
        }

        //ian doesn't understand how this works
        if (props.values_to_show != null) {
            var indiceRemaining = iamData.length - 1;

            while (indiceRemaining > 0) {
                //we have other rows of data which need to match up with the first rows elements
                var origValues = iamData[indiceRemaining].values.slice(0);
                iamData[indiceRemaining].values = [];

                _.map(iamData[0].values, 'label').map(i => {
                    var existing = origValues.find(j => j.label == i);
                    iamData[indiceRemaining].values.push(existing);
                })

                indiceRemaining--;
            }
        }

        if (maxlength >= 20 && truncateTo == 0) {
            truncateTo = 20;
            maxlength = truncateTo;
        }

        var that = this;
        const colours = this.fetchColours(iamData);
        var handleClickingMe = (a, b, c) => {

            debugger;
        };    
        
        nv.addGraph({
            generate: function() {

                var chart = nv.models.aMultiBarHorizontalChart()

                    .showControls(false)
                    .showLegend(true)
                    .legendPosition(legendPosition)
                    .showValues(true)
                    .groupSpacing(0.2) //Distance between each group of bars.
                    .valueFormat(function(v) {
                        return v
                    });
                
                // disable on print
                if ( window.matchMedia("print").matches ){
                	chart.duration(0);
                }

                chart.dispatch.on('renderEnd', function() {
                    
                });



                if (props.orientation == "vertical") {
                    chart = nv.models.multiBarChart()
                        .showControls(false)

                        .groupSpacing(0.2) //Distance between each group of bars.
                        .reduceXTicks(false);

                    if (props.stacked == true) {
                        chart.stacked(true)
                    }
                } else {
                    if (props.stacked == false) {
                        //                    d3.selectAll(".totals").style("display", "none");
                    }
                }

                that.chart = chart;

                var leftMargin = props.leftMargin ? props.leftMargin : 30 + ((truncateTo > 0 ? truncateTo : maxlength) * 7);
                
                that._leftMargin = leftMargin;


                //weird ghost labels might be able to be fixed by replaceing truncate to with a cool truncate function that adds fake blank characters for "new ones".
                var margin = {
                    top: 50,
                    right: 20,
                    bottom: 40,
                    left: leftMargin
                };
                chart
                    .x(function(d) {
                        var label = d.label;
                        label = props.translate ? window.tryTranslate(label) : label;
                        return truncateTo > 0 ? _.truncate(label, {
                            length: truncateTo
                        }) : label
                    }) //Specify the data accessors.
                    .y(function(d) {
                        return d.value
                    })
                    .margin(margin)
                //.forceY([0,100])

                if (colours && colours.length > 0) {
                    /*chart.color(function(d){
                        return "green"; //colours
                    });*/
                    
                    chart.barColor(function(item, index) {
                        return colours[index];
                    });
                } else if (props.colors) {
                    chart.color(props.colors);
                }

                if (props.stacked) {
                    chart.stacked(true).showControls(true);
                }

                if (props.showLegend != undefined) {
                    chart.showLegend(props.showLegend);
                }

                chart.dispatch.on('renderEnd', function() {                  
                    that._moveCrazyLabels && that._moveCrazyLabels();

                });
                chart.dispatch.on('stateChange', function() {                   
                    try {
                        if (props.stacked) {
                            if (d3.selectAll(".totals-" + props.root).attr("style") == null || d3.selectAll(".totals-" + props.root).attr("style").includes("display: block;")) {
                                d3.selectAll(".totals-" + props.root).style("display", "none");
                            } else {
                                d3.selectAll(".totals-" + props.root).style("display", "block");
                            }
                        } else {
                            if (d3.selectAll(".totals-" + props.root).attr("style") != null || d3.selectAll(".totals-" + props.root).attr("style").includes("display: none;")) {
                                d3.selectAll(".totals-" + props.root).style("display", "block");
                            } else {
                                d3.selectAll(".totals-" + props.root).style("display", "none");
                            }
                        }
                    } catch (e) {
                        console.log("something strange here michael... ", e);
                    }

                    setTimeout(() => {
                        that._moveCrazyLabels && that._moveCrazyLabels();
                    }, 250);


                });
                chart.dispatch.on('changeState', function() {                    
                    that._moveCrazyLabels && that._moveCrazyLabels();
                });
                chart.legend.dispatch.on('legendClick', function(e, a) {
                    var field = that.props.origProps ? that.props.origProps.group1 : that.props.group1;

                    var clicked = [e.key];
                    that.props.handleClickThrough && that.props.handleClickThrough(field, clicked);


                    //that._moveCrazyLabels && that._moveCrazyLabels();
                });

                chart.multibar.dispatch.on("elementClick", function(e, a, b) {
                    var field1 = that.props.origProps ? that.props.origProps.group1 : that.props.group1;

                    var field2 = that.props.origProps ? that.props.origProps.group2 : that.props.group2;
                    var clicked1 = e.series ? e.series.key : e.data.key;
                    var clicked2 = e.data.label;
                    that.props.handleClickThrough && that.props.handleClickThrough([
                        [field1, [clicked1]],
                        [field2, [clicked2]]
                    ]);

                });

                that._moveCrazyLabels = function(a) {
                    if (showTotals == true) {
                        window.wtf = that;
                        d3.select(that.svgNode)
                            .selectAll('.nv-series-2 g')
                            .each(function(a, b, c) {
                                var width = parseFloat(d3.select(this).select('rect').attr('width')) + 4;
                                var height = parseFloat(d3.select(this).select('rect').attr('height'));                               

                            });

                        if (that.svgNode) {
                            var ticks = d3.select(that.svgNode).selectAll('.nv-x .tick');


                            var data = that.svgNode.__data__;

                            var formattedData;


                            var endOfBar = [];
                            for (var x = 0; x < data.length; x++) {
                                endOfBar[x] = "";
                            }

                            var dataLength = data[0].values.length;

                            for (var x = 0; x < dataLength; x++) {
                                var total = 0;

                                for (var y = 0; y < data.length; y++) {
                                    total += data[y].values[x].value;
                                    endOfBar[x] = total;
                                }
                            }

                            var myThat = this;


                            if (d3.selectAll(".totals-" + myThat.props.root)[0].length < endOfBar.length) {
                                ticks.append("text").attr("class", "totals-" + myThat.props.root);
                            }

                            var totals = d3.selectAll(".totals-" + myThat.props.root);
                            if (this.props.orientation == "horizontal" || this.props.orientation == undefined) {
                                totals.each(function(d, j) {
                                    d3.select(this).text(endOfBar[j]).attr('transform', 'translate(' + (that.chart.yAxis.scale()(endOfBar[j]) + 5) + ', 0 )')
                                });
                            } else if (this.props.orientation == "vertical" && totals.length > 0) {
                                var max = Math.max(...endOfBar)
                                totals.each(function(d, j) {
                                    d3.select(this).text(endOfBar[j]).attr('transform', 'translate(0, ' + -(that.chart.yAxis.scale()(max - endOfBar[j]) + 5) + ')')
                                });

                            }
                            if (!this.props.stacked) {
                                totals.style("display", "none");
                            }                           


                            
                        } else {
                            
                            
                        }

                    }


                    var moveToControlsTo = -0;
                    var ytransform = 0; ///d3.select(that.svgNode).selectAll(".nv-y").attr("transform");
                    if (that._leftMargin > 0) {
                        //adjust it so it's not dumb;

                        moveToControlsTo = that._leftMargin - 20;

                        d3.select(that.svgNode).selectAll(".nv-controlsWrap").attr("transform", "translate(" + (-1 * (moveToControlsTo)) + ",-" + margin.top + ")");
                    }

                    if (legendPosition == "top") {

                        if (d3.select(that.svgNode).node() != null) {
                            var svgBox = d3.select(that.svgNode).node().getBBox();
                            if (d3.select(that.svgNode).selectAll(".nv-legendWrap").node() != null) {
                                var thisBox = d3.select(that.svgNode).selectAll(".nv-legendWrap").node().getBBox();
                                var translateX = 0;

                                var controls = d3.select(that.svgNode).selectAll(".nv-controlsWrap").node().getBBox();


                                var before = d3.select(that.svgNode).selectAll(".nv-legendWrap").attr("transform");
                                var beforeLeftTranslate = before ? parseInt(before.match(/translate\((\d*)/)[1]) : 180;


                                var diff = svgBox.width - (thisBox.width + thisBox.x + beforeLeftTranslate + that._leftMargin)
                                if (diff < 0) {
                                    beforeLeftTranslate += diff - that._leftMargin + 50;
                                    d3.select(that.svgNode).selectAll(".nv-legendWrap").attr("transform", "translate(" + beforeLeftTranslate + ",-" + margin.top + ")");
                                } else if (controls && thisBox.x > 0 && (thisBox.x < controls.x + controls.width)) {
                                    beforeLeftTranslate = controls.width + controls.x - that._leftMargin + 50;
                                    d3.select(that.svgNode).selectAll(".nv-legendWrap").attr("transform", "translate(" + beforeLeftTranslate + ",-" + margin.top + ")");
                                }

                            }
                        }

                    }
                }

                chart.tooltip = nv.models.tooltip();

                chart.yAxis.tickValues(d3.range(chart.yAxis.scale().domain()[0], chart.yAxis.scale().domain()[1]));

                chart.yAxis.axisLabel(props.yAxisLabel || "").tickFormat(d3.format('.0f'));
                chart.xAxis.axisLabel(props.xAxisLabel || "");
                
                //d3.select(that.svgNode).selectAll(.nv-x .nv-axislabel
                
                if (props.xAxisTickFormat) {
                    chart.xAxis.tickFormat(props.xAxisTickFormat);
                }
                if (props.yAxisTickFormat) {
                    chart.yAxis.tickFormat(props.yAxisTickFormat);
                }

                if (props.valueFormat) {
                    chart.valueFormat(props.valueFormat);
                }

                d3.selectAll(".nvtooltip").remove();

                d3.select(that.svgNode)
                    .attr('width', props.width)
                    .attr('height', props.height)
                    .datum(iamData)
                    .transition().duration(0)
                    .call(chart);



                if (props.handleClickLabel != "no") {

                    var wtf = (a, b, c) => {
                        var notSure = iamData;
                        var allLabels = _.uniq(_.map(_.flatten(_.map(iamData, (d) => d.values)), 'label'));

                        a = a.replace('...', '');

                        var clicked = allLabels.filter(l => l.indexOf(a) == 0);

                        //group1 is from the loblaws, group2 is the origprops thing from the editor.
                        var field = that.props.origProps ? that.props.origProps.group2 : that.props.group2;
                        if (field == "" || field == undefined) {
                            field = that.props.origProps ? that.props.origProps.group1 : that.props.group1;
                        }
                        that.props.handleClickThrough && that.props.handleClickThrough(field, clicked);


                        // alert(clicked.join(' '));                    

                    }

                    d3.select(that.svgNode).selectAll(".tick").style('cursor', 'pointer').on("click", wtf);
                }

                if (props.orientation == "vertical" && props.averageLine) {
                    var yAvg = 0;
                    var ySum = 0;
                    var yCount = 0
                    var svg = d3.select(that.svgNode);
                    if (iamData.length > 0) {
                        _.each(iamData, (dataSet) => {
                            _.each(dataSet.values, (valueObj) => {
                                ySum = ySum + valueObj.value;
                                yCount++;
                            })
                        });

                        yAvg = chart.yAxis.scale()(ySum / yCount);
                    }
                    svg.append("line")
                        .style("stroke", "#ff0000")
                        .style("stroke-width", "3px")
                        .attr("x1", margin.left)
                        .attr("y1", yAvg + margin.top)
                        .attr("x2", 100000) //props.width - margin.right
                        .attr("y2", yAvg + margin.top);
                }

            },

            callback: function(graph) {
                that.setState({
                    renders: that.state.renders + 1
                }, () => {
                    that.forceUpdate()
                });
                if (that._moveCrazyLabels) {
                    that._moveCrazyLabels();
                    setTimeout(() => {
                        that._moveCrazyLabels()
                    }, 1000);
                }

            }
        });

    }
}