import React, { Fragment, useEffect, useState } from "react";
import { TabularColumn, PageHeading, Tabular, Selection, Button, Loading } from "@opidcore/components";
import _ from "lodash";
import FullTextSearchable from "../../components/FullTextSearchable";
import { Link, useParams } from "react-router-dom";
import LoadingProgress from "@opidcore/components/LoadingProgress";

const defaultApplyText = "Apply Increases";

const PriceIncreaseFile = (props) => {
  let params = useParams();

  // props
  const fileID = (props.fileId != undefined ? props.fileId : params.id) || null;
  const type = props.type || "all";
  const viewOnly = props.viewOnly || false;

  // file
  const [fileName, setFileName] = useState(props.filename || "");

  // data
  const [priceIncreaseRows, setPriceIncreaseRows] = useState([]);
  const [fetchPriceIncreaseRows, setFetchPriceIncreaseRows] = useState(false);

  // selections
  const [selection, setSelection] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [applyButtonText, setApplyButtonText] = useState(<Fragment>{defaultApplyText}</Fragment>);

  // useEffect
  useEffect(() => {
    if (fileName == "" && fileID != null) {
      APP.central.File.fetchFilename(fileID).then((r) => {
        setFileName(r.result);
      });
    }
  }, [fileName]);

  useEffect(() => {
    if (allSelected == true) {
      setSelection(priceIncreaseRows);
    } else if (selection.length >= priceIncreaseRows.length) {
      setSelection([]); //everything was checked when we unchecked select all so uncheck all
    }
  }, [allSelected]);

  // ============ Modals
  const openService = (row) => {
    if (row.service_friendly_id) {
      APP.instance.createModal(
        "/ui/services/edit/" + row.service_friendly_id,
        { modal_name: "Edit Service " + row.service_friendly_id },
        {
          afterClose: () => {
            // nothing
          },
        }
      );
    } else {
      APP.alert("No service found");
    }
  };

  const openClient = (row) => {
    if (row.client_friendly_id) {
      APP.instance.createModal(
        "/ui/clients/" + row.client_friendly_id,
        { modal_name: row.client_friendly_id + " - " + row.client_name },
        {
          afterClose: () => {
            // nothing
          },
        }
      );
    } else {
      APP.alert("No client found");
    }
  };

  const openSite = (row) => {
    if (row.site_friendly_id) {
      APP.instance.createModal(
        "/ui/sites/detail/" + row.site_friendly_id,
        { modal_name: row.site_friendly_id + " - " + row.site_name },
        {
          afterClose: () => {
            // nothing
          },
        }
      );
    } else {
      APP.alert("No site found");
    }
  };
  //
  const runUpdate = () => {
    setApplyButtonText(<Loading size="1x" colour="white" style={{ display: "inline-block" }} />);

    APP.showLoading(
      {
        message: "Applying PI",
        children: (
          <Fragment>
            <LoadingProgress onFinishLoad={() => reloadPage()} />
            Page will refresh when the process completes. Cleanup runs in background; refresh as needed to view updated row statuses.{" "}
          </Fragment>
        ),
      },
      () => {
        APP.central.ContractLineIncrease.runLineUpdates(selection).then((runLineUpdates) => {
          APP.socket.send(JSON.stringify(runLineUpdates));
        });
      }
    );
  };

  // ============ Other Functions

  const reloadPage = () => {
    location.reload();
  };

  // ============ Selection Logic
  const selectAll = (e) => {
    e.stopPropagation();
    if (selection.length >= priceIncreaseRows.length) {
      setAllSelected(false); //unselect everything
    } else {
      setAllSelected(true); //select everything
    }
  };

  const _setSelection = (value) => {
    if (value.length < priceIncreaseRows.length) {
      setAllSelected(false); //unchecked something so make sure select all is unchecked
    }
    setSelection(value);
  };

  // ============ Render
  return (
    <div className="price-increase-rows">
      <PageHeading size="h3">{fileName} PI Rows</PageHeading>

      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: "1" }}>
          <FullTextSearchable
            model={"ContractLineIncrease"}
            method={"reasonablePriceIncreaseList"}
            setData={setPriceIncreaseRows}
            doFetchData={fetchPriceIncreaseRows}
            setDoFetchData={setFetchPriceIncreaseRows}
            maxRows={-1}
            allowBlank={true}
            additionalParams={[fileID, type]}
            filterOptions={{
              columns: [
                { column: "status", heading: "Status" },
                { column: "client_umbrella", heading: "Umbrella" },
                { column: "client_name", heading: "Client Name" },
                { column: "client_friendly_id", heading: "Client Friendly ID" },
                { column: "site_name", heading: "Site Name" },
                { column: "site_friendly_id", heading: "Site Friendly ID" },
                { column: "service_description", heading: "Service Description" },
                { column: "service_friendly_id", heading: "Service ID" },
                { column: "vendor_name", heading: "Vendor Name" },
                { column: "vendor_friendly_id", heading: "Vendor Friendly ID" },
                { column: "client_increase_type", heading: "Client Increase Type" },
                { column: "vendor_increase_type", heading: "Vendor Increase Type" },
                { column: "notes", heading: "Note" },
              ],
            }}
          />
          {selection.length > 0 ? (
            <Button onClick={() => runUpdate()}>
              {applyButtonText} ({selection.length})
            </Button>
          ) : (
            <Button className="apply-greyed-out" onClick={null} disabled>
              {applyButtonText} 0
            </Button>
          )}
        </div>
      </div>

      <Tabular data={priceIncreaseRows} onClick={null} pageSize={100} paginationLocation={"both"}>
        {viewOnly ? null : (
          <TabularColumn
            headingComponent={() => (
              <span>
                <input type="checkbox" onClick={selectAll} checked={allSelected} />
                Select
              </span>
            )}
            data={(row, idx, previousRow) => {
              return (
                <span title={"price increase id# " + row.id}>
                  <Selection selection={selection} data={priceIncreaseRows} setter={_setSelection} item={row} label={(row) => ""} />
                </span>
              );
            }}
          />
        )}

        <TabularColumn title="Status" data={(row) => row.status} />

        <TabularColumn title="Umbrella" data={(row) => row.client_umbrella ?? "-"} />
        <TabularColumn
          title="Client ID"
          data={(row) => (
            <Link to="#" onClick={() => openClient(row)}>
              {row.client_friendly_id}
            </Link>
          )}
        />
        <TabularColumn
          title="Client"
          data={(row) => (
            <Link to="#" onClick={() => openClient(row)}>
              {row.client_name}
            </Link>
          )}
        />

        <TabularColumn
          title="Site ID"
          data={(row) => (
            <Link to="#" onClick={() => openSite(row)}>
              {row.site_friendly_id}
            </Link>
          )}
        />
        <TabularColumn
          title="Site"
          data={(row) => (
            <Link to="#" onClick={() => openSite(row)}>
              {row.site_name}
            </Link>
          )}
        />

        <TabularColumn
          title="Service ID"
          data={(row) => (
            <Link to="#" onClick={() => openService(row)}>
              <div>{row.service_friendly_id}</div>
            </Link>
          )}
        />
        <TabularColumn
          title="Service"
          data={(row) => (
            <Link to="#" onClick={() => openService(row)}>
              <div>{row.service_description}</div>
            </Link>
          )}
        />

        <TabularColumn title="Site Activity" data={(row) => row.activities ?? "-"} />
        <TabularColumn title="Material" data={(row) => row.materials ?? "-"} />

        <TabularColumn
          title="Vendor ID"
          data={(row) => (
            <Link to="#" onClick={() => openService(row)}>
              <div>{row.vendor_friendly_id}</div>
            </Link>
          )}
        />
        <TabularColumn
          title="Vendor"
          data={(row) => (
            <Link to="#" onClick={() => openService(row)}>
              <div>{row.vendor_name}</div>
            </Link>
          )}
        />

        <TabularColumn title="Vendor Activity" data={(row) => row.vendor_activity ?? "-"} />

        <TabularColumn title="Client Increase Value" data={(row) => row.client_increase_value ?? "-"} />
        <TabularColumn title="Client Increase Type" data={(row) => row.client_increase_type ?? "-"} />
        <TabularColumn title="Client Before" data={(row) => row.client_unit_price ?? "-"} />
        <TabularColumn title="Client After" data={(row) => row.client_unit_price_new ?? "-"} />

        <TabularColumn title="Vendor Increase Value" data={(row) => row.vendor_increase_value ?? "-"} />
        <TabularColumn title="Vendor Increase Type" data={(row) => row.vendor_increase_type ?? "-"} />
        <TabularColumn title="Vendor Before" data={(row) => row.vendor_unit_price ?? "-"} />
        <TabularColumn title="Vendor After" data={(row) => row.vendor_unit_price_new ?? "-"} />

        <TabularColumn title="Note" data={(row) => row.notes ?? "-"} />
        <TabularColumn title="Effective Date" data={(row) => row.effective_date ?? "-"} />
      </Tabular>
    </div>
  );
};

export default PriceIncreaseFile;
