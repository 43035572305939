import React, { Fragment, useEffect, useState } from "react";
import Loading from "./Loading";

/*
ex:
  APP.showLoading({message: "Generating Report", children: <LoadingProgress /> }, ()=>{
    APP.central.Reports.dumpToExcel(dataSet.uid, "Sustainability Data Export").then((r) => {  <-- This needs to have an OpidBackgroundTask
      APP.socket.send(JSON.stringify(r));
    });
  })
*/

export default function LoadingProgress({ colour = "#27b2bc", onFinishLoad = null }) {
  const [progress, setProgress] = useState(0);
  const [style, setStyle] = useState({ background: colour, width: "0%" });

  useEffect(() => {
    APP.getState("handleSocket", (socket) => {
      // if socket and socket.progress is a number then setProgress to socket.progress
      if (socket && typeof socket.progress == "number") {
        setProgress(socket.progress);
        setStyle({ background: colour, width: socket.progress + "%" });

        if (socket.progress == 100) {
          setTimeout(() => {
            if (onFinishLoad) {
              onFinishLoad();
            }
          }, 1000);
        }
      }
    });
  }, []);

  useEffect(() => {
  }, [progress]);

  return (
    <Fragment>
      <div className="progressBarContainer">
        <div className="progressBar" style={style} />
      </div>
      {progress == 0 ? (<Loading/>) : (progress == 100 ? (<div className="loadingProgress ">{"Finished! You may close this progress bar."}</div>) : (<div className="loadingProgress ">{progress + "%"}</div>))}
    </Fragment>
  );
}
