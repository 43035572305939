import React, { Component, useState } from 'react';
import ReactDOM from 'react-dom';
import RenderMeSoftly from '../RenderMeSoftly.jsx';
import * as _ from 'lodash';
import moment from 'moment';
//import SingleDayTimeLog from './TimeLog/SingleDayTimeLog.jsx';
import Month from '../../Calendar/Month.js';
import Week from '../../Calendar/Week.js';
import { Link } from 'react-router-dom';


import { CustomizeContext } from '../../../Customization.jsx';
import Icon from "@opidcore/components/Icon";

function BlockComponent(props){
    
    const [show, setShow] = useState(false);
    
    let blockContentSingle = [];
    let blockContentMulti = [];
    let badge = null;
    var idCount = 0;
    
    if(props.eventsForDay.length > 10){
        
        badge = <a onClick={() => setShow(show ? false : true)} >{props.badgeLabel} <span className="badge">{props.eventsForDay.length}</span></a>;
        props.eventsForDay.map((e)=>{
            var childProps = {scope:e} 
            blockContentMulti.push(<Link to={props.linkTo + eval(e.event ? e.event.id : "")}>{Calendar.injectScopeToChildren(props.dayChildren, childProps)}</Link>)
        })
    }
    else{
        props.eventsForDay.map((e)=>{
            var childProps = {scope:e} 
            blockContentSingle.push(<Link key={e.event ? e.event.id : idCount++} to={props.linkTo + eval(e.event ? e.event.id : "")} target="_blank" >{Calendar.injectScopeToChildren(props.dayChildren, childProps)}</Link>)
        })
    }
    
    return <div>
              {badge}
              {show ? blockContentMulti : ""}
              {blockContentSingle}
          </div>
    
}

export default class Calendar extends Component {
      static contextType = CustomizeContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
                contentStructure: [{"cid":"test1","type":"ReactThing","component":"Header","options":{"content": "Loading"}}],
                startDate: ""
        };
    }
    
    static getEditableProps (){
        var datasets = Object.keys(window.APP.NDT.datasets);
        return [
            {niceName: "Title", prop: "title", type:"text", default:"", editInPlace: true},
         //   {niceName: "Range", prop: "range", type:"text", default:"", editInPlace: true, options:["","Week","Month"]},
            {niceName: "Data Set", prop: "dataset", type: "text", options: datasets},
            {niceName: "Date Variable Name", prop: "dateVarName", type:"text", default:"", editInPlace: true},
            {niceName: "Link To", prop: "linkTo", type:"text", default:"", editInPlace: true},
            {niceName: "Badge Label", prop: "badgeLabel", type:"text", default:"", editInPlace: true},
        ]
    }
    
    static injectScopeToChildren(children, props){
        return React.Children.map(children,  (child)=>{
            return React.cloneElement( child, props, children )
        });
        
    }
    componentWillMount(){
        const startDate = moment().set({date: 1}).format('MMM YYYY');
        this.setState({startDate : startDate})
    }
    
    generateCalendarEvents(){
        let events = [];
        let data = typeof this.props.dataset == "string" ? this.props.NDT.getData(this.props.dataset, this).filter() : this.props.dataset.filter();
        if(data != undefined && data.length > 0){

            _.forEach(data,(r)=>{
                events.push({event: r, date:eval("r." + this.props.dateVarName)});
            });
        }
        return events;
    }
    
    prevMonth(){
        let startDate = moment(this.state.startDate).subtract(1, 'months').format('MMM YYYY');
        this.setState({startDate : startDate})
    }
    
    
    nextMonth(){
        let startDate = moment(this.state.startDate).add(1, 'months').format('MMM YYYY');
        this.setState({startDate : startDate})
    }
    
    render(){        

        let e = this.generateCalendarEvents(); 
        
        let calendar = null;
        calendar = <Month DayComponent={BlockComponent} onClickDay={this.props.onClickDay} data={e} dayChildren={this.props.children} linkTo={this.props.linkTo} date={this.state.startDate} />;
                     
        return <div className={"calendar " + this.props.className}> 
                <h4>{this.props.title}</h4>
                <button className="simple-button" onClick={()=>this.prevMonth()} style={{backgroundColor: "#e5ecf2", fontWeight: "bold"}} ><Icon icon="chevron-double-left"/> previous</button>
                <button className="simple-button" onClick={()=>this.nextMonth()} style={{float : "right", backgroundColor: "#e5ecf2", fontWeight: "bold"}} >next <Icon icon="chevron-double-right"/></button>
                
                {calendar}
            </div>
        
    }
}
