import React, { useEffect, useRef, useState } from "react";
import { Bound, BoundCommit, Icon, InputAutoComplete, InputText, InputToggleSwitch, Loading, PageHeading, TabularColumn, TabularDataSet } from "@opidcore/components";
import { useData } from "@opidcore/hooks/WTF";


const EditPortalProfile = ({portalProfileSettingsId, afterSave = undefined, skipSave = false}) => {
    const boundId = "profile" + portalProfileSettingsId + ";transient-";
    const [profileData, profileDataSet] = useData(boundId, APP.central.Portal.fetchProfileSettings, {id: portalProfileSettingsId});
    const [affectedContacts, affectedContactsDataSet] = useData("affected-contacts-" + portalProfileSettingsId, APP.central.CustomerContact.fetchForPortalProfileSettings, {portalProfileSettingsId: portalProfileSettingsId});
    const [boundProfile, setBoundProfile] = useState(null);
    
    useEffect(()=>{
        if(profileData != undefined && profileData.length > 0){
            setBoundProfile(profileData[0]);
        }
    },[]);

    useEffect(()=>{
        if(profileData != undefined && profileData.length > 0){
            setBoundProfile(profileData[0]);
        }
    },[profileDataSet.loading]);

    if(boundProfile == undefined){
        return <Loading />;
    }

    const doSave = () => {
        const profile = APP.registeredBoundMagics[boundId];
		if (profile != undefined) {
			return APP.central.Bound.saveChanges(profile.getAllDeltas()).then((r) => {
				if (afterSave != undefined) {
                    const result = r.result[profile.magicalGet("__dataUID")];
					afterSave(result);

					return false;
				}
			});
		}
    }

    const removeContactProfile = (contactId) => {
        return APP.central.CustomerContact.removeFromPortalProfileSetting(contactId).then((r)=>{
            affectedContactsDataSet.fetch();
        });
    }

    const addContactToProfile = (contactId) => {
        return APP.central.CustomerContact.addToPortalProfileSetting(contactId, portalProfileSettingsId).then((r)=>{
            affectedContactsDataSet.fetch();
        });
    }

    if(profileDataSet.loading){
        return <Loading />;
    }

    return <div id="edit-portal-profile" key={"pps-"+boundId} style={{display: "flex", flexDirection: "column", alignItems: "center", width: (skipSave? 500 : 1000)}}>
        <PageHeading>Edit Portal Profile</PageHeading>
        <div style={{display: "flex", flexDirection: "row"}}>
            <div style={{display: "flex", flexDirection: "column"}}>
                <BoundCommit initialValue={boundProfile} boundId={boundId} commit={()=>doSave()} skipSave={skipSave}>
                    <InputText field="profileName" name="Profile Name" disabled={skipSave} style={{width: 300}}/>
                    <InputToggleSwitch field="portalAccess" name="Portal Access" disabled={skipSave}/>
                    <InputToggleSwitch field="invoiceAccess" name="Invoice Access" disabled={skipSave}/>
                    <InputToggleSwitch field="selfServeInvoiceAccess" name="Self Serve Invoice Access" disabled={skipSave}/>
                    <InputToggleSwitch field="costSavingsAccess" name="Cost Savings Access" disabled={skipSave}/>
                    <InputToggleSwitch field="costAvoidanceAccess" name="Cost Avoidance Access" disabled={skipSave}/>
                    <InputToggleSwitch field="sustainabilityAccess" name="Sustainability Access" disabled={skipSave}/>
                    <InputToggleSwitch field="complianceAccess" name="Compliance Access" disabled={skipSave}/>
                    <InputToggleSwitch field="reportAccess" name="Report Access" disabled={skipSave}/>
                    <InputToggleSwitch field="supportAccess" name="Support Access" disabled={skipSave}/>
                </BoundCommit>
            </div>
            {skipSave == true ? null : <AffectedContacts dataSet={affectedContactsDataSet} removeContactProfile={removeContactProfile} addContactToProfile={addContactToProfile}/>}
        </div>
    </div>;
}

const AffectedContacts = ({dataSet, removeContactProfile, addContactToProfile}) => {
    const [updatingContact, setUpdatingContact] = useState(0);
    const searchResult = useRef();

    useEffect(()=>{
        if(updatingContact > 0){
            removeContactProfile(updatingContact).then((r)=>{
                setUpdatingContact(0);
            });
        }
    }, [updatingContact]);

    const doDelete = (contactId)=>{
        setUpdatingContact(contactId);
    }

    const addContact = (autocompleteResult) => {
        addContactToProfile(parseInt(autocompleteResult.key));
    }

    return <div style={{marginLeft: 50, display: "flex", flexDirection: "column", alignItems: "center"}}>
        <PageHeading>Affected Contacts</PageHeading>
        <TabularDataSet dataSet={dataSet} defaultSort={(row)=>row.likelyDescription + " " + row.lastName}>
            <TabularColumn data={(row)=>row.lastName} title="Last" />
            <TabularColumn data={(row)=>row.firstName} title="First" />
            <TabularColumn data={(row)=>row.customer.friendlyId} title="Customer ID" />
            <TabularColumn data={(row)=>row.customer.likelyDescription} title="Customer Name" />
            <TabularColumn data={(row)=>updatingContact == row.id? <Loading size="2x" /> : <Icon icon="trash" onClick={()=>doDelete(row.id)} disabled={updatingContact > 0? true : false} />} />
        </TabularDataSet>
        
        <Bound to={searchResult.current}>
            <InputAutoComplete central={APP.central.CustomerContact.autocomplete} field="contactId" onSelect={(value) => addContact(value)} shouldExpand={true} name="Add Contact">
                {(contact) => <div className="contact_option" style={{display: "flex", flexDirection: "row"}}>
                    <div>{contact.displayValue}</div>
                    <div>{contact.basis != undefined && contact.basis.fullEntity != undefined && contact.basis.fullEntity.customer != undefined ? contact.basis.fullEntity.customer.likelyDescription : ""}</div>
                </div>}
            </InputAutoComplete>
        </Bound>
    </div>;
}

export default EditPortalProfile;