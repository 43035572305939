import React, { useEffect, useState } from 'react';
//import { BoundDataContext, Button, Icon } from '@opidcore/components';
import _ from 'lodash';
//import { useData } from '@opidcore/hooks/WTF';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default function ThingLink({ relatedType, relatedId }) {
    const [link, setLink] = useState(null)

    useEffect(() => {
        switch (relatedType) {
            case "Customer":
                setLink("/ui/clients/")
                break;
            case "ARInvoice":
                setLink("/ui/arinvoice/")
                break;
            case "APInvoice":
                setLink("/ui/apinvoice/")
                break;
            case "Commodity":
                setLink("")
                break;
            case "VendorContract":
                setLink("/ui/vendorcontract/edit/")
                break;
            case "Site":
                setLink("/ui/sites/detail/")
                break;
            case "Service":
                setLink("/ui/services/edit/")
                break;
            case "ClientContract":
                setLink("/ui/clientcontract/edit/")
                break;
        }
    }, [])

    if (link != null) {
        return <Link to={link + relatedId}>{relatedType} {relatedId}</Link>
    } else {
        return null;
    }
}