export function newAudit(){
	APP.setState("redirect", "/ui/audit/new");
}

export function newCustomer(){
	APP.setState("redirect", "/ui/clients/0");
}

export function newSite(){
	APP.setState("redirect", "/ui/sites/edit/0");
}