import React, { Component } from 'react';

export default class Footer extends Component{
    constructor(props) {
        super(props);
        
        this.state = {};
    }

	static getEditableProps() {
		return [
			
		];
	}
    
    render(){
        return <div className="footer">
        	{this.props.children}        
        </div>
    }
}