import React, { Component } from 'react';

export default class ArgsWatcher extends Component{
    constructor(props) {
        super(props);

        var chunks = window.location.hash.split('?');
        var readableParams = {};
        
        if (chunks.length > 1){
            var params = chunks[1].split("&");
            
            for(var x=0; x<params.length; x++){
                var theSplit = params[x].split('=');
                readableParams[theSplit[0]] = theSplit[1];
            }        	
            
            var args = [];
            
            if(props.args != undefined){
                args = args.concat(props.args);
            } else if(props.arg){
                args.push({arg: props.arg, announce_as: props.announce_as});
            }

            for(var i=0; i<args.length; i++){
                var me = readableParams[args[i].arg];
            
                if(me != undefined && props.NDT){
                    props.NDT.announce(args[i].announce_as, me);
                }
            }
        }
    }
    
    render(){
    	return <span></span>;

    }
}