/**
 * Basic class for unit conversion.
 * @param val - Double: The value you're looking to convert.
 * @param type - String: The type of value you're looking to convert, current options are "volume" or "v" for volumes, and "distance" or "d" for distance.
 * @param from - String: The source unit for the given value.
 * @param to - String: The target unit for the conversion.
 *
 * The current supported units are as follows: 
 * Volume:
 * "m" for cubic meters
 * "yd" for cubic yards
 * "gal" for gallons
 * 
 * Distance: 
 * "m" for meters
 * "yd" for yards
 * "in" for inches
 * "ft" for feet
 * 
 * @return The return value is a double.
 * 
 * @example Converter.Convert(1.0, volume, m, yd) would convert one cubic meter into one cubic yard.
 */

export default class Converter {

    static volumeTable = {
        m: 1.0,
        yd: 0.764555,
        gal: 0.00378541
    }

    static distanceTable = {
        m: 1.0,
        yd: 0.9144,
        in: 0.0254,
        ft: 0.3048
    }

    static Convert(val, type, from, to) {
        switch (type) {
            case "volume": case "v":                
                return (val * (this.volumeTable[from] / this.volumeTable[to]))
            case "distance": case "d":               
                return (val * (this.distanceTable[from] / this.distanceTable[to]))
            default:                
                return "Something went wrong."
        }
    }

    static TestMethod() {
        return "Hello world." 
    }
}