import React, { Component } from 'react';

export default class Document extends Component{
    constructor(props) {
        super(props);
        
        this.state = {};
    }
    
  	static getEditableProps(){
        
        return [
			{niceName: "ID", prop: "id", type: "text", default : ""}, 
        ]
        
    }

    render(){
    	var style = {};
    	
    	if (this.props.flex){
    		style.flex = this.props.flex;
    	}
    	
    	if (this.props.flexOuter){
    		style.display = "flex";
    		//  flex-wrap: nowrap;
    	}
    	
        return <div id={this.props.id}>{this.props.children}</div>
    }
}