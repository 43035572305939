import React, { Fragment,  useMemo, useRef, useState } from "react";
import { useEffect } from "react";
import { Button, Icon } from "@opidcore/components";
import { useData } from "@opidcore/hooks/WTF";
import Util from "@opidcore/Util";
import _ from "lodash";
import { NiceBaseEntity, NiceBaseEntityClassName } from "../UI/Nice";

/*
  ex:
  <CesarFilter
        columns={[
          { column: "name", heading: "Name" },
          { column: "friendlyId", heading: "Friendly ID" },
          { column: "active", lookup: "clientStatus", default: true, heading: "Active" },
          { column: "clientType" },               // These are objects so they need formatters in \wastemetrics\public\waste\js\index.js line ~355
          { column: "compensationType" },         // These are objects so they need formatters in \wastemetrics\public\waste\js\index.js line ~355
        ]}
        dataSet={dataSet}
      />
*/

export default function CesarFilter(props) {
  const [query, _setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [showReset, setShowReset] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showClear, setShowClear] = useState(false);
  const [lookups, setLookups] = useState([]);
  const [searchFacetsResult, setSearchFacetsResult] = useState([]);
  const [lookupsData, lookupsDataSet] = useData('lookup');
  const searchingFacetsRef = useRef('');

  const orgPinnedResults = useRef({});
  const initChecked = useRef([]);

  const columns = props.columns.map((col) => {
    return col.column;
  });
  const dataSet = props.dataSet;

  const [openColumns, setOpenColumns] = useState({});

  const emptyQueryArgs = {form: "", options: []}; 
  const [datasetQueryArgs, setDatasetQueryArgs] = useState(_.cloneDeep(emptyQueryArgs));
  const reInitOptions = props.reInitOptions;

  useEffect(() => {
    let reInit = false;

    _.forEach(reInitOptions, (op) => {
      if( !_.isEqual(dataSet.queryArgs.options[op], datasetQueryArgs.options[op]) ){
        reInit = true;
      }
    });
    
    if(_.isEqual(datasetQueryArgs, emptyQueryArgs) || !_.isEqual(dataSet.queryArgs.form, datasetQueryArgs.form) || reInit == true){
      setDatasetQueryArgs(dataSet.queryArgs);
      APP.central.Lookup.list().then((r) => {
        let tempOrgPinnedResults = {};
        let tempLookups = [];
        let defaultsDetected = false;
  
        _.forEach(columns, (col, index) => {
          tempOrgPinnedResults[col] = [];
  
          if (props.columns[index].lookup != undefined) {
            tempLookups[index] = r.result
              .filter((lookup) => lookup.name == props.columns[index].lookup)
              .map((lookup) => {
                return lookup;
              })
              .sort();
          } else {
            tempLookups[index] = [];
          }
  
          
          if(props.columns[index].default != undefined){
            tempOrgPinnedResults[col].push(props.columns[index].default);
            defaultsDetected = true;
          } else if(props.columns[index].defaults != undefined && Array.isArray(props.columns[index].defaults)) {
            props.columns[index].defaults.forEach((d) => {
              tempOrgPinnedResults[col].push(d);
            });
            defaultsDetected = true;
          }
          
        });
  
        orgPinnedResults.current = tempOrgPinnedResults;
        setLookups(tempLookups);
  
        if(_.filter(dataSet._callbacks, (cb) => cb.name == "doSetLookups").length == 0){
          dataSet.callback(() => debouncedSearch());
          dataSet.callback(doSetLookups);
        }
      
        if(defaultsDetected == true){ //This isnt working?
          doApply();
          //search();
          debouncedSearch();
        }
        
      });
    }
  }, [dataSet.loading]);

  useEffect(() => {
    //search();
    debouncedSearch();
  }, [lookups]);

  //--------------------------------------------------------- Functions ---------------------------------------------------------

  const doSetLookups = () => {
      let tempLookups = [];

      columns.forEach((col, index) => {
        if (props.columns[index].lookup != undefined) {
          tempLookups[index] = lookupsData.filter((lookup) => lookup.name == props.columns[index].lookup).sort();
        } else {
          tempLookups[index] = [];
        }
      });
      setLookups(tempLookups);
  };

  const doOnChange = (val) => {
    if (props.onChange != undefined) {
      props.onChange(val);
    }
  }

  const setQuery = (val) => {
    doOnChange(val);
    _setQuery(val);
    //search(val);
    debouncedSearch(val);
  };

  const search = (val) => {
    let searchQuery;
    if (val != undefined) {
      searchQuery = val;
    } else {
      searchQuery = query;
    }
    let tempResults = [];
    let tempResult1 = [];
    let tempResult2 = [];
    let tempResult3 = [];

    if (dataSet.paginationInfo != undefined && dataSet.uid && searchingFacetsRef.current != dataSet.uid) {
      const columnsString = columns.join();
      searchingFacetsRef.current = dataSet.uid;
      APP.central.Util.searchFacets(searchQuery, dataSet.uid, columnsString).then((r) => {
        setSearchFacetsResult(r.result);
        if (r != null && r.result != null && Array.isArray(r.result)) r.result.forEach((res, index) => {
          if (props.columns[index].lookup != undefined && lookups.length > 0) {
            tempResult1 = _.map(lookups[index], (lu) => {
              if(lu.key === "true" || lu.key === "false"){
                return lu.key === "true";
              }
              return lu.key;
            });
          } else {
            let parsed = [];
            parsed = _.map(res.options, (op) => {
              try {
                return JSON.parse(op);
              } catch (error) {
                console.log("CesarFilter.jsx: Couldn't parse JSON:", error);
              }
            });
            tempResult1 = parsed;
          }
          if (orgPinnedResults.current[res.column] != undefined) {
            tempResult2 = orgPinnedResults.current[res.column];
          }

          tempResult3 = _.uniqBy(tempResult2.concat(tempResult1), (t) => {
            if(t === "true" || t === "false"){
              return t === "true";
            }
            if(t.toLowerCase != undefined){
              return t.toLowerCase().trim();
            }
            
            return t;
          }).sort();
          tempResults.push(tempResult3);
        });
        setResults(tempResults);
        setShowReset(true);
      });
    } else {
      columns.forEach((col, index) => {
        if (props.columns[index].lookup != undefined && lookups.length > 0) {
          tempResult1 = _.map(lookups[index], (lu) => {
            if(lu.key === "true" || lu.key === "false"){
              return lu.key === "true";
            }
            return lu.key;
          });
        } else {
          tempResult1 = _.filter(
            _.map(dataSet.origData, (r) => {
              if (r[col] == undefined) {
                return null;
              }

              let thing = r[col];
              if (typeof thing === "object") {
                //thing = JSON.stringify(thing);
              }
              if(thing === "true" || thing === "false"){
                thing  = thing === "true";
              }

              if (thing.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
                return thing;
              } else {
                return null;
              }
            }), (r)=>{ return r != undefined && r !== "" }
          ).sort();
        }

        if (orgPinnedResults.current[col] != undefined) {
          tempResult2 = _.filter(
            _.map(dataSet.origData, (r) => {
              if (r[col] == undefined) {
                return null;
              }

              let thing = r[col];
              if (typeof thing === "object") {
                //thing = JSON.stringify(thing);
              }

              if(thing === "true" || thing === "false"){
                thing  = thing === "true";
              }

              if (orgPinnedResults.current[col].indexOf(thing) >= 0) {
                return thing;
              } else {
                return null;
              }
            }), (r)=>{ return r != undefined && r !== "" }
          ).sort();
        }

        tempResult3 = _.uniq(tempResult2.concat(tempResult1));
        tempResults.push(tempResult3);
      });
      
      setResults(tempResults);
      setShowReset(true);
    }
  };

  const debouncedSearch = useMemo(() => _.debounce(search, 800), []);

  const reset = () => {
    _setQuery("");
    setResults([]);
    setShowReset(false);
    let tempOrgPinnedResults = {};
    columns.forEach((e) => {
      tempOrgPinnedResults[e] = [];
    });
    orgPinnedResults.current = tempOrgPinnedResults;
    //search("");
    debouncedSearch("");
    dataSet.reset();
    dataSet.fetch();
  };

  const clearFilter = () => {
    if (dataSet.paginationInfo != undefined) {
      const newArgs = _.cloneDeep(dataSet.queryArgs.options);
      for (const property in orgPinnedResults.current) {
        if (newArgs[property] != undefined) {
          delete newArgs[property];
        }
      }
      dataSet.setQueryArgs({ options: newArgs });
    }
    reset();
    doOnChange(query);
    closeCesarFilter();
    setShowClear(false);
  };

  const keyUp = (e) => {
    if (e.key === "Enter") {
      //search();
      debouncedSearch();
    }
  };

  const toggleShowCesarFilter = () => {
    setShowFilter(!showFilter);
  };

  const closeCesarFilter = () => {
    _setQuery("");
    setShowFilter(false);
  };

  const doApply = () => {
    if (dataSet.paginationInfo != undefined) {
      const newArgs = _.cloneDeep(dataSet.queryArgs.options);
      for (const property in orgPinnedResults.current) {
        if (orgPinnedResults.current[property].length > 0) {
          //newArgs[property] = orgPinnedResults.current[property];
          try {
            let newProps = [];
            _.forEach(orgPinnedResults.current[property], (thing) => {
              const index = _.findIndex(_.find(searchFacetsResult, { column: property }).options, (o) => {
                return _.isEqual(JSON.parse(o), thing);
              });
              newProps.push(_.find(searchFacetsResult, { column: property }).rawOptions[index]);
            });
            newArgs[property] = newProps;
          } catch (error) {
            newArgs[property] = orgPinnedResults.current[property]; //fallback for defaults
          }
        } else if (newArgs[property] != undefined && newArgs[property].length > 0) {
          delete newArgs[property];
        }
      }

      dataSet.setQueryArgs({ options: newArgs });
      dataSet.fetch();
    } else {
      for (const property in orgPinnedResults.current) {
        const theProperty = property;
        if (orgPinnedResults.current[theProperty].length == 0) {
          dataSet.unfilter(theProperty);
        } else {
          dataSet.filter({ [theProperty]: orgPinnedResults.current[theProperty] }, true);
        }
      }
    }

    doOnChange();
    setShowClear(true);
    closeCesarFilter();
  };

  //--------------------------------------------------------- CesarFilterColumns ---------------------------------------------------------

  const CesarFilterColumns = (props) => {
    return (
      <Fragment>
        {props.columns
          ? props.columns.map((col, index) => {
              return (
                <CesarFilterExpandable
                  col={col.column}
                  key={col.column}
                  open={openColumns.hasOwnProperty(col.column) ? openColumns[col.column] : false}
                  dataSet={props.dataSet}
                  index={index}
                  columns={props.columns}
                  colResults={results[index]}
                  heading={col.heading ? col.heading : null}
                />
              );
            })
          : null}
      </Fragment>
    );
  };

  //--------------------------------------------------------- CesarFilterExpandable ---------------------------------------------------------

  const CesarFilterExpandable = (props) => {
    const [open, setOpen] = useState(false || props.open);
    const col = props.col;
    const index = props.index;
    const colResults = props.colResults;
    const resultLength = _.uniqBy(colResults, (t) => {
      if (typeof t == "object") {
        return t.__type + t.id;
      } else {
        return t;
      }
    }).length;
    const [initiallyChecked, setInitiallyChecked] = useState([]);

    useEffect(() => {
      if (props.open != undefined) {
        setOpen(props.open);
      }
      setInitiallyChecked(_.cloneDeep(orgPinnedResults.current[col]));
    }, []);

    useEffect(() => {
      initChecked.current = initiallyChecked;
    }, [initiallyChecked]);

    const clickCheck = (e, index, c) => {
      let tempOrgPinnedResults = _.cloneDeep(orgPinnedResults.current);
      let options = tempOrgPinnedResults[props.columns[index].column];

      if (Util.isBaseEntity(c)) {
        if (Util.containsBaseEntity(options, c)) {
          options = _.remove(options, (o) => {
            return Util.isSameAs(o, c);
          });
        } else {
          options.push(c);
        }
      } else {
        if (options != undefined && options.indexOf(c) >= 0) {
          options = _.remove(options, (o) => {
            return o == c;
          });
        } else {
          options.push(c);
        }
      }

      //setOrgPinnedResults(tempOrgPinnedResults);
      orgPinnedResults.current = tempOrgPinnedResults;
      // TODO this causes selected checkboxes to jump to the top of the list but is also a fix for boxes not updating correctly.
      setInitiallyChecked(_.cloneDeep(orgPinnedResults.current[col]));
    };

    const selectAll = () => {
      let tempOrgPinnedResults = _.cloneDeep(orgPinnedResults.current);
      let options = tempOrgPinnedResults[props.columns[index].column];
      let allSelected = true;

      _.uniqBy(
        _.filter(
          colResults.map((entry) => {
            return entry;
          }), (r)=>{ return r != undefined && r !== "" }
        ),
        (t) => {
          if (typeof t == "object") {
            return t.__type + t.id;
          } else {
            return t;
          }
        }
      ).forEach((c) => {
        if (Util.isBaseEntity(c)) {
          if (!Util.containsBaseEntity(options, c)) {
            options.push(c);
            allSelected = false;
          }
        } else {
          if (!(options && options.indexOf(c) >= 0)) {
            options.push(c);
            allSelected = false;
          }
        }
      });

      if (allSelected == true) {
        _.uniqBy(
          _.filter(
            colResults.map((entry) => {
              return entry;
            }), (r)=>{ return r != undefined && r !== "" }
          ),
          (t) => {
            if (typeof t == "object") {
              return t.__type + t.id;
            } else {
              return t;
            }
          }
        ).forEach((c) => {
          if (Util.isBaseEntity(c)) {
            _.remove(tempOrgPinnedResults[props.columns[index].column], (o) => {
              return Util.isSameAs(o, c);
            });
          } else {
            _.remove(tempOrgPinnedResults[props.columns[index].column], (o) => {
              return o == c;
            });
          }
        });
      }

      //setOrgPinnedResults(tempOrgPinnedResults);
      orgPinnedResults.current = tempOrgPinnedResults;
      //search();
      debouncedSearch();

    };

    let unchecked = [];
    let prevChecked = [];
    let objType = null;

    if (colResults != undefined) {
      _.uniqBy(
        _.filter(
          colResults.map((entry) => {
            return entry;
          }), (r)=>{ return r != undefined && r !== "" }
        ),
        (t) => {
          if (typeof t == "object") {
            objType = t.__type;
            return t.__type + t.id;
          } else {
            return t;
          }
        }
      ).map((entry, i) => {
        let passedProps = {};
        if (typeof entry == "object") {
          if (Util.containsBaseEntity(orgPinnedResults.current[col], entry)) {
            passedProps.checked = true;
          }
          if (Util.containsBaseEntity(initiallyChecked, entry)) {
            passedProps.initiallyChecked = true;
          }
        } else {
          if (orgPinnedResults.current[col].indexOf(entry) >= 0) {
            passedProps.checked = true;
          }
          if (initiallyChecked.indexOf(entry) >= 0) {
            passedProps.initiallyChecked = true;
          }
        }

        let returnVal = null;
        let lookup = null;
        if (props.columns[index].lookup != undefined && lookups[index].length > 0){
          lookup = _.find(lookups[index], (l)=>{ 
            if(l.key === "true" || l.key === "false"){
              return (l.key === "true") == entry;
            }

            return l.key == entry 
          })
        }
        if (lookup != undefined) {
          returnVal = lookup.label;
        } else if (typeof entry === "object") {
          returnVal = <NiceBaseEntity thing={entry} />;
        } else {
          returnVal = entry;
        }

        if (passedProps.initiallyChecked == true) {
          prevChecked.push(
            <CFCheckboxEntry
              col={col}
              entry={entry}
              clickCheck={clickCheck}
              index={index}
              passedProps={passedProps}
              returnVal={returnVal}
              key={`${col}-${typeof entry === "object" ? entry.id : entry}`}
            />
          );
        } else {
          unchecked.push(
            <CFCheckboxEntry
              col={col}
              entry={entry}
              clickCheck={clickCheck}
              index={index}
              passedProps={passedProps}
              returnVal={returnVal}
              key={`${col}-${typeof entry === "object" ? entry.id : entry}`}
            />
          );
        }
      });
    }

    return (
      <div className="cesarFilterExpandable" key={col}>
        <div className="cesarFilterCol">
          <div
            className="columnAndChevron"
            onClick={() => {
              setOpen(!open);
              setOpenColumns({ ...openColumns, [col]: !open });
            }}
          >
            {/*<h4>{objType ? <NiceBaseEntityClassName thing={{__type: objType}}/> : props.dataSet.getColumnInfo(col).niceName}</h4>*/}
            <h4>{props.heading ? props.heading : objType ? <NiceBaseEntityClassName thing={{__type: objType}}/> : props.dataSet.getColumnInfo(col).niceName}</h4>
            {resultLength > 0 ? (
              <div className="cesarFilterBadge">
                <span>{resultLength}</span>
              </div>
            ) : null}
            <div className="cfChevron">
              <Icon className="action" icon={open ? "chevron-up" : "chevron-down"} />
            </div>
          </div>
          <div
            className="cesarFilterSelectAll"
            onClick={() => {
              selectAll();
            }}
          >
            <Icon className="cfSelectAllIcon" size="2x" icon={"check-square"} />
          </div>
        </div>

        <div style={{ display: open ? "initial" : "none" }}>
          <div className="cesarFilterCheckbox">
            {prevChecked}
            {unchecked}
          </div>
        </div>
      </div>
    );
  };

  //--------------------------------------------------------- RETURN ---------------------------------------------------------

  return (
    <Fragment>
      <div className="cesarFilterButtons">
        <Button
          onClick={() => {
            toggleShowCesarFilter();
          }}
        >
          Filter
        </Button>
        {showClear ? (
          <Button
            onClick={() => {
              clearFilter();
            }}
          >
            Clear Filter
          </Button>
        ) : null}
      </div>
      {showFilter ? (
        <div className="cesarFilter">
          <span className="cesarFilterSearch">
            <h4>Filter</h4>
            <div className="cesarFilterSearchBox">
              <input type="text" value={query} onKeyUp={(e) => keyUp(e)} onChange={(e) => setQuery(e.target.value)} />
              {showReset ? <Icon icon="times-square" onClick={reset} className="clear-search" title="Clear Search" /> : null}
            </div>
          </span>
          <CesarFilterColumns columns={props.columns} dataSet={props.dataSet} />
          <div className="cesarFilterButtons">
            <Button
              onClick={() => {
                closeCesarFilter();
              }}
            >
              Close
            </Button>
            <Button
              onClick={() => {
                doApply();
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
}

function CFCheckboxEntry(props) {
  const col = props.col;
  const entry = props.entry;
  const clickCheck = props.clickCheck;
  const index = props.index;
  const passedProps = props.passedProps;
  const returnVal = props.returnVal;
  delete passedProps.initiallyChecked;

  return (
    <div key={`${col}-${typeof entry === "object" ? entry.id : entry}`}>
      <input type="checkbox" id={entry.toString()} key={entry.toString()} onChange={(e) => clickCheck(e, index, entry)} {...passedProps} />
      {returnVal}
    </div>
  );
}
