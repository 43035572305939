import React, { Component } from 'react';
import templated from './Templated.jsx';

export default class TableCell extends Component {
    constructor(props) {
        super(props);
    }
    
    static getEditableProps(){
        return [
            {niceName: "Cell Type", prop: "tagName", type: "list", options:[{key:"", value:""}, {key:"td", value:"td"}, {key:"th", value:"th"}], defaultValue: "td"},
            {niceName: "Column Span", prop:"colSpan", type:"text", defaultValue: "1"},
            {niceName: "Align", prop: "align", type: "list", options:[{key:"", value:""}, {key:"left", value:"left"}, {key:"right", value:"right"}, {key:"center", value:"center"}], defaultValue: "left"},
            
            {niceName: "Content", prop:"content", type:"text", multiLine: true, editInPlace: true, defaultValue: ""},
            
            {niceName: "Advanced", prop: "displayAs", type: "list", options:[{key:"", value:""}, {key:"content", value:"content"}, {key:"container", value:"container"}], defaultValue: "content"},
        ];
    }
    
    static defaultProps(){
        const options = TableCell.getEditableProps();
        
        let defaults = {};
        
        _.forEach(options, (option)=>{
           defaults[option.prop] = option.defaultValue;
        });
        
        return defaults;
    }
    
    static metaInfo(myStruct){
    	const canHaveChildren = myStruct.options != undefined && myStruct.options.displayAs == "container";
    	
  		return {canHaveChildren: canHaveChildren, icon: "table", isAlreadyInABlock: true, inContent: false, wtf: true}
  	}
    
    keyEvent(e){
        //NOTE: focusNext is incomplete at the moment.
        if(e.keyCode == 9 && false){
            this.props.NDT.getEditor().focusNext(this.props.root, e);
        }
    }
    
    render(){
    	const TagName = this.props.tagName || "td";
        
        var inner = this.props.children;
       
        if ( this.props.root != undefined && this.props.displayAs != "container" ){
            inner = templated(this.props.content || "", this, (content)=>{this.props.handleInlineChange({content: content})});           

            if (inner.trim){
            	inner = inner.trim();
            }
          
            if (inner && inner.match && inner.match(/^([0-9\. ]*)$/) != null){
            	//inner = parseFloat().toFixed(2);
            	inner = parseFloat(parseFloat(inner).toFixed(2)).toLocaleString()
            	
            	if ("" + inner == "NaN"){
            		inner = "";
            	}
            }
            
        }        
        
        const theRealProps = {...this.props};

        
        
        return <TagName className={"cell-align-" + (this.props.align || "left") } onKeyUp={(e)=>this.keyEvent(e)} colSpan={this.props.colSpan || "1"} data-uid={this.props.root} ref={(node)=>APP.NDT.crazyRefer(node,theRealProps)}>
            {inner}
            {this.props.mode == "edit" && this.props.actions.length > 0 ? <div className="table-actions">{this.props.actions}</div> : null}
        </TagName>
    }
}