import { Data }  from '@opidcore/Data';
import Util from '@opidcore/Util';
import _ from 'lodash';
import moment from 'moment';

const WASTE_AUDIT = {
		init: function(auditId){
			// store customized functions into App.customize.on(#listeners
			// collection,
			// #listenerName, me.yourFunctionName)
		    //APP.customize.on("DataSet", "dv-test1", WASTE_AUDIT.totals);
			if (auditId != null && typeof auditId == "number"){
				this.auditId = auditId;
			}
				
			this.dataSet = APP.NDT.getData("audit-data");
			this.fetch();
		},
		
		prepSingle: function(single){
			
			if (single.size_of_site && single.size_of_site.indexOf("ft2")){
				single.size_of_site = single.size_of_site.replace("ft2", "ft²");
			}
			
			if (single.client_name_informal == "" || single.client_name_informal  == null){
				single.client_name_informal = single.client_name;
			}
			
			if (single.site_name == "" || single.site_name == null){
				single.site_name = single.site_address;
			}

			if (single.city && single.province){
				single.city_province = single.city + ", "  + single.province;
			}
			
			if (single.date){
				single.date_month_year = moment(single.date).format("MMMM YYYY");
			
				single.date_year = moment(single.date).format("YYYY");
			}

			if (single.tenant_noun == "" || single.tenant_noun == null){
				single.tenant_noun = 'Tenants';
			}
			

			single.tenant_noun_title = single.tenant_noun;
			single.tenant_noun = single.tenant_noun.toLowerCase(); //single.tenant_noun.charAt(0).toUpperCase() + single.tenant_noun.slice(1);
			if (single.tenant_noun.lastIndexOf('s') == single.tenant_noun.length - 1){
				single.tenant_noun = single.tenant_noun.substring(0,single.tenant_noun.length-1);
			}
			
				
			return single;
		},

		round: function(val, places){
		    if(val == undefined){
		        val = 0;
		    }
			return Util.round(val, places == undefined ? 2 : places);
		},
		
		sum(what, hash){
			const ret = _.fromPairs(_.map( hash, (v,k)=>{ return [k, _.sumBy(v,what)] }));
			return ret;
		},
		
		process(data){		
			// this is weird, and only works because we execute the 
			// callback in the fetch function.
			return new Promise((resolve, reject)=>{
				 this.callback = (ret)=>{ resolve(ret) };
			});
		},
		
		makeGraphy(waste_type){
			const materialsDS = APP.NDT.datasets["audit-materials-used"];
			
			const dataSummary = {};	
			APP.NDT.datasets["audit-materials-used"].filter({id: 1925})

			if (waste_type == undefined){
				dataSummary.totalWasteToLandfillA = this.sum('sample_weight', _.groupBy(APP.NDT.datasets['audit-data'].filter((r)=>r.final_destination == 'Landfill'), (dd)=>{
					return APP.NDT.datasets["audit-materials-used"].filter({id: parseInt(dd.waste_category)})[0].waste_type;
				}));
			}else{			
				dataSummary.totalWasteToLandfillA = this.sum('annual_weight', _.groupBy(APP.NDT.datasets['audit-data'].filter((r)=>r.final_destination == 'Landfill'), (dd)=>{
					const wt = APP.NDT.datasets["audit-materials-used"].filter({id: parseInt(dd.waste_category)})[0].waste_type;
					if (wt == waste_type){
						return dd.material_name;
					}
				}));
			}
			delete dataSummary.totalWasteToLandfillA["undefined"];
			if (dataSummary.totalWasteToLandfillA["Mixed Waste"] != null){
				dataSummary.totalWasteToLandfillA["Mixed Waste"] = dataSummary.totalWasteToLandfillA["Mixed Waste"];
				if (dataSummary.totalWasteToLandfillA["Liquid Waste"] > 0){
					dataSummary.totalWasteToLandfillA["Mixed Waste"] += dataSummary.totalWasteToLandfillA["Liquid Waste"];
				}
			}
			delete dataSummary.totalWasteToLandfillA["Liquid Waste"];
			
			return dataSummary.totalWasteToLandfillA;

		},
		
		processData: function(data){
		    /* 
                sample weight: total amount that went to x
                sample weight proper: amount that should have gone to x
            */
			if (data != null){
				this.rawData = data;
			}else{
				data = this.rawData;
			}
			
			
			var totalsForStream = {};
			_.each( _.groupBy(data, 'stream'), (rows, stream)=>{
				totalsForStream[stream] = {sampleWeight: this.round( _.sumBy(rows, 'sample_weight'), 8 ) }
			});
			
			_.each(data, (d)=>{
				
				d.annual_multiplier = 1;
				if (d.annualized_method == "weight"){
					d.annual_multiplier = 1 / totalsForStream[d.stream].sampleWeight * parseFloat(d.generic_decimal);
				}
				
				if (d.annualized_method == "days"){
					d.annual_multiplier = parseFloat(d.generic_decimal);
				}
				
				if (d.sample_weight > 0){
					d.annual_weight = d.sample_weight * d.annual_multiplier;
					d.annual_weight_proper = d.sample_weight_proper * d.annual_multiplier;
					d.annual_weight_contamination = d.sample_weight_contamination * d.annual_multiplier;
				}
				
				if (d.annualized_method == "annual"){
					//zero it out just in case, we only want to treat it as an annual number
					d.sample_weight = 0;
					d.sample_weight_proper = 0;
				}
				
			});
			
			this.dataSet.reset( data );
			
			var grandTotal = 0;
			var totalContamination = 0;
			var totalContaminationAnnual = 0;
			var totalsA = {"Contamination": 0};
			var totalsMaterialDiverted = {};
			var totalsAnnualMaterialDiverted = {};
			
			this.dataSet.filter().forEach(function(row){
				if (row.stream == "null"){ 
					row.stream = 1123;
				}
				var sample_weight = row.sample_weight;
				
				var material_name = row.material_name;
				
				if ( totalsMaterialDiverted[ material_name ] == undefined){
					totalsMaterialDiverted[ material_name ]  = 0;
				}
				if ( totalsAnnualMaterialDiverted[ material_name ] == undefined){
					totalsAnnualMaterialDiverted[ material_name ]  = 0;
				}

				if ( totalsA[ row.final_destination ] == null ){
					totalsA[row.final_destination] = 0;
				}

				grandTotal += sample_weight;
				if (row.final_destination != "Landfill"){
					totalContamination += row.sample_weight_contamination;
					totalContaminationAnnual += row.annual_weight_contamination;
					
					totalsMaterialDiverted[ material_name ]  += row.sample_weight_proper;
					totalsAnnualMaterialDiverted[ material_name ]  += row.annual_weight_proper;
				}
				
				totalsA["Contamination"] = totalContamination; //totalsA["Contamination"]  + row.sample_weight_contamination;
				totalsA[row.final_destination]  = totalsA[row.final_destination]  + row.sample_weight_proper;
			});
			
			 const finals = ['Disposed', 'Diverted']
			 const finalTotals = _.fromPairs(_.map( APP.NDT.datasets['audit-data'].group('material_name'),  (v,k)=>{ 
				 	var ret = [k, {}];
				 	_.each(finals, (final)=>{
				 		if (ret[1][final] == null){
				 			ret[1][final] = 0;
				 		}
				 		
				 	});
				 	
				 	ret[1]['Diverted'] += _.sumBy( _.filter( v, (x)=>x.final_destination == 'Diverted' && x.expected_final_destination == 'Diverted' && x.annual_weight_proper > 0 ), 'annual_weight');
				 	ret[1]['Disposed'] += _.sumBy( _.filter( v, (x)=>!(x.final_destination == 'Diverted' && x.expected_final_destination == 'Diverted' && x.annual_weight_proper > 0 )), 'annual_weight');
				 	return ret;
			 } ))

			 
			 let tenantFormatter = (r)=>{return r.tenant_name + (r.unit_num ? " " + r.unit_num : "") };
			 
			const dataSummary = {};			
			dataSummary.finalDestinationTotals = finalTotals;
			
			dataSummary.divertedTotals = this.sum('annual_weight_proper', _.groupBy(APP.NDT.datasets['audit-data'].filter((r)=>r.final_destination != 'Landfill'), 'stream_name'))
			

			dataSummary.materialTotalGenerated = this.sum( 'sample_weight', this.dataSet.group('material_name', this.dataSet.filter((r)=>r.bag_num > 0 )) );

			dataSummary.materialTotalGeneratedAnnual = this.sum( 'annual_weight', this.dataSet.group('material_name') );
			dataSummary.materialTotalGeneratedAnnualByStream = this.sum( 'annual_weight', this.dataSet.group('stream_name') );
			dataSummary.materialTotalGeneratedAnnualByStreamContamination = this.sum( 'annual_weight_contamination', this.dataSet.group('stream_name') );
			dataSummary.materialTotalGeneratedAnnualProper = this.sum( 'annual_weight_proper', _.groupBy(this.dataSet.filter((r)=>r.final_destination != 'Landfill'), 'material_name') );
			

			dataSummary.materialTotalAnnualByStreamContaminationPercent = _.fromPairs( _.map(dataSummary.materialTotalGeneratedAnnualByStream, (v,k)=>[k, 100 * dataSummary.materialTotalGeneratedAnnualByStreamContamination[k]/v ] ));
			
			dataSummary.totalWasteGenerated = this.round( _.sumBy(data, 'sample_weight'));
			dataSummary.totalWasteGeneratedAnnual = this.round( _.sumBy(data, 'annual_weight') );

			dataSummary.totalWasteToLandfill = this.sum('sample_weight', _.groupBy(APP.NDT.datasets['audit-data'].filter((r)=>r.final_destination == 'Landfill'), 'material_name'));
			dataSummary.totalWasteToLandfillAnnual = this.sum('annual_weight', _.groupBy(APP.NDT.datasets['audit-data'].filter((r)=>r.final_destination == 'Landfill'), 'material_name'));
			
			dataSummary.totalWasteToLandfillByTenant = this.sum('annual_weight', _.groupBy(APP.NDT.datasets['audit-data'].filter((r)=>r.final_destination == 'Landfill'), tenantFormatter ));
			delete dataSummary.totalWasteToLandfillByTenant['annual'];
			delete dataSummary.totalWasteToLandfillByTenant['annual annual'];
			
			dataSummary.totalWasteDivertedByTenant = this.sum('annual_weight_proper', _.groupBy(APP.NDT.datasets['audit-data'].filter((r)=>r.final_destination != 'Landfill'), tenantFormatter ));
			dataSummary.totalWasteDivertedByTenantByStream = {};
			delete dataSummary.totalWasteDivertedByTenant['annual'];
			delete dataSummary.totalWasteDivertedByTenant['annual annual'];
			
			dataSummary.totalContaminationInDiversion = this.sum('annual_weight_contamination', _.groupBy(APP.NDT.datasets['audit-data'].filter((r)=>r.final_destination != 'Landfill'), 'material_name'));
			
			const diversionStreams = _.uniq( _.map( APP.NDT.datasets['audit-data'].filter((r)=>r.final_destination != 'Landfill'), (r) => r.stream_name.replaceAll(" ", "") ) );
			dataSummary.totalContaminationInDiversionByStream = {};
			dataSummary.totalContaminationInDiversionByTenantByStream = {};
			_.forEach(diversionStreams, (streamName) => {
				dataSummary.totalContaminationInDiversionByStream[streamName] = this.sum('annual_weight_contamination', _.groupBy(APP.NDT.datasets['audit-data'].filter((r)=>r.stream_name.replaceAll(" ", "") == streamName), 'material_name'));
				dataSummary.totalContaminationInDiversionByTenantByStream[streamName] = this.sum('annual_weight_contamination', _.groupBy(APP.NDT.datasets['audit-data'].filter((r)=>r.stream_name.replaceAll(" ", "") == streamName), tenantFormatter ));

				dataSummary.totalWasteDivertedByTenantByStream[streamName] = this.sum('annual_weight_proper', _.groupBy(APP.NDT.datasets['audit-data'].filter((r)=>r.final_destination != 'Landfill' && r.stream_name.replaceAll(" ", "") == streamName), tenantFormatter ))

				delete dataSummary.totalContaminationInDiversionByTenantByStream[streamName]['annual'];
				delete dataSummary.totalContaminationInDiversionByTenantByStream[streamName]['annual annual'];

				delete dataSummary.totalWasteDivertedByTenantByStream[streamName]['annual'];
				delete dataSummary.totalWasteDivertedByTenantByStream[streamName]['annual annual'];
			});

			dataSummary.totalContaminationInDiversionByTenant = this.sum('annual_weight_contamination', _.groupBy(APP.NDT.datasets['audit-data'].filter((r)=>r.final_destination != 'Landfill'), tenantFormatter ));
			delete dataSummary.totalContaminationInDiversionByTenant['annual'];
			delete dataSummary.totalContaminationInDiversionByTenant['annual annual'];
			
			dataSummary.totalWasteToLandfillByTenantDetails = [];
			
			_.map( _.groupBy(APP.NDT.datasets['audit-data'].filter((r)=>r.final_destination == 'Landfill'), tenantFormatter ), (rows, g)=>{
				const subData = new Data(rows);
				
				const tenantDetail = {name: g};
				tenantDetail.weights = this.sum( 'sample_weight', subData.group('material_name') );
				dataSummary.totalWasteToLandfillByTenantDetails.push( tenantDetail );
			} );
			
			dataSummary.totalContamination = totalContamination; //this.round( _.sumBy(APP.NDT.getData("audit-data").filter(), 'sample_weight_contamination'));
			dataSummary.totalAnnualContamination = totalContaminationAnnual; //this.round( _.sumBy(APP.NDT.getData("audit-data").filter(), 'annual_weight_contamination'));

			_.each(totalsA, (v,k)=>{ totalsA[k] = this.round(v) });
			dataSummary.totalByType = totalsA;
			dataSummary.totalsMaterialDiverted = totalsMaterialDiverted;
			dataSummary.totalsAnnualMaterialDiverted = totalsAnnualMaterialDiverted;

			dataSummary.totalMaterialDiverted = this.round( _.sum(_.values(totalsMaterialDiverted)));
			
			const destinationTotal = {Contamination: 0};
			_.forEach(APP.NDT.datasets['audit-data'].rawData, (row)=>{
			    if(row.annual_weight_contamination > 0 && row.final_destination == "Diverted"){ //went into diversion when it shouldn't have
			        destinationTotal["Contamination"] += row.annual_weight_contamination;
			    } else {
			        if(destinationTotal[row.final_destination] == undefined){
			            destinationTotal[row.final_destination] = 0;
                    }
			        
			        destinationTotal[row.final_destination] += row.annual_weight;
			    }
			});
			dataSummary.destinationTotal = destinationTotal;
			
			dataSummary.materialTotalDiversion = _.fromPairs(_.map( APP.NDT.getData("dv-test1").group('details.waste_category_nice_name'), (f,k)=>{ return [k, { proper: _.sumBy(f,(i)=>parseFloat(i.proper)),   contamination: _.sumBy(f,(i)=>parseFloat(i.contamination)) } ]  }));
			
			_.forEach(this.dataSet.group("final_destination"), (values, key)=>{
                dataSummary["total" + key] = this.round(_.sum(_.map(values, 'sample_weight')));
                dataSummary["total" + key+"Proper"] = this.round(_.sum(_.map(values, 'sample_weight_proper')));
                
                dataSummary["totalAnnual" + key] = this.round(_.sum(_.map(values, 'annual_weight')));
                dataSummary["totalAnnual" + key+"Proper"] = this.round(_.sum(_.map(values, 'annual_weight_proper')));
            });
            
            dataSummary.captureDiverted = _.sum(_.map(this.dataSet.group('expected_final_destination')['Diverted'], 'annual_weight'));
            dataSummary.captureDivertedProper = _.sum(_.map(this.dataSet.group('expected_final_destination')['Diverted'], 'annual_weight_proper'));
	
            // old diversion rate
            // dataSummary.diversionRate =  WASTE_AUDIT.round( 100 * destinationTotal["Diverted"] / _.sum(_.values(destinationTotal)), 2)
            dataSummary.diversionRate = 100 * dataSummary.totalAnnualDivertedProper / dataSummary.totalWasteGeneratedAnnual;            
            
            dataSummary.captureRate = WASTE_AUDIT.round( 100 * dataSummary.captureDivertedProper / dataSummary.captureDiverted, 2)
            dataSummary.notCaptureRate = WASTE_AUDIT.round( 100 - 100 * dataSummary.captureDivertedProper / dataSummary.captureDiverted, 2);
            
            // this is the real percetage
            // dataSummary.contaminationRate = dataSummary.totalAnnualContamination / _.sum(_.values(dataSummary.materialTotalGeneratedAnnual));
            
            // this is the ratio that was requested, expressed as a percent.
            dataSummary.contaminationRate = dataSummary.totalAnnualContamination / dataSummary.totalAnnualDiverted * 100;

            dataSummary.materialsGeneratedAndDiverted = _.fromPairs( _.compact( _.map( totalsAnnualMaterialDiverted, (v,k)=>{ if (v > 0){ const a = {'Not Diverted': dataSummary.materialTotalGeneratedAnnual[k] - v};a[k]=v; return [k, a ] } }) ) )
            
            dataSummary.totalDivertableInLandfill =  dataSummary.totalAnnualLandfill - dataSummary.totalAnnualLandfillProper;
            dataSummary.totalDivertableInLandfillPercent =  100 * dataSummary.totalDivertableInLandfill / dataSummary.totalAnnualLandfill;

            const materialDS = APP.NDT.getData("audit-materials-used");
            if (materialDS.__theSetCallback == null){
				materialDS.__theSetCallback = true;
            	materialDS.callback( ()=>{
					this.processData();

					APP.NDT.refreshSoon();
				 });
            }
			
            if (materialDS.rawData.length > 0){
	            dataSummary.divertableInLandfill = _.fromPairs(_.map( this.sum('annual_weight', _.groupBy(this.dataSet.filter((r)=>r.final_destination == 'Landfill'), (r)=>{ const material = materialDS.filter({id: r.waste_category })[0]; return material != null ? material.waste_type : "Unknown"} )), (v,k)=>{ 
	                
	            	return [ k, { kgs: v, percent: 100 * v / dataSummary.totalAnnualLandfill } ];
	            	
	            }));
	            
	            
	            dataSummary.divertableInLandfill = {};
	            
	            _.each(this.sum('annual_weight', _.groupBy(this.dataSet.filter((r)=>r.final_destination == 'Landfill'), (r)=>{ const material = materialDS.filter({id: r.waste_category })[0]; return material != null ? material.waste_type : "?" } )), (v,k)=>{
	        		const name = k.split(' ')[0];
	        		dataSummary["divertableInLandfill" + name] = v;
	        		dataSummary["divertableInLandfill" + name + "Percent"] = 100 * v / dataSummary.totalAnnualLandfill;            		
	        	});

				//clear out our scenarios
				_.forEach(["Recyclable","Organic"], (name)=>{
					if (dataSummary["divertableInLandfill" + name] == null){
						dataSummary["divertableInLandfill" + name] = 0;
						dataSummary["divertableInLandfill" + name + "Percent"] = 0;
					}
				});
	            
	            
	            _.each(this.sum('annual_weight', _.groupBy(this.dataSet.filter((r)=>r.final_destination > ''), (r)=>{ const material = materialDS.filter({id: r.waste_category })[0]; return material != null ? material.waste_type : "?" } )), (v,k)=>{
	        		const name = k.split(' ')[0];
	        		dataSummary["totalByMaterialType" + name] = v;
	        		dataSummary["totalByMaterialType" + name + "Percent"] = 100 * v / dataSummary.totalWasteGeneratedAnnual;            		
	        	});

				//for scenarios
				_.forEach(["Recyclable","Organic"], (name)=>{
					if (dataSummary["totalByMaterialType" + name] == null){
						dataSummary["totalByMaterialType" + name] = 0;
						dataSummary["totalByMaterialType" + name + "Percent"] = 0;
					}
				});
	            
	            dataSummary.annualWeightByType = this.sum('annual_weight', _.groupBy(this.dataSet.filter((r)=>r.final_destination > '' ), (r)=>{ const material = materialDS.filter({id: r.waste_category })[0]; return material != null ? material.waste_type : "?" } ));
	            
	            _.each(this.sum('annual_weight_proper', _.groupBy(this.dataSet.filter((r)=>r.final_destination > ''), (r)=>{ const material = materialDS.filter({id: r.waste_category })[0]; return material != null ? material.waste_type : "?" } )), (v,k)=>{
	        		const name = k.split(' ')[0];
	        		dataSummary["totalByMaterialTypeProper" + name] = v;
	        		
	        		
	        		dataSummary["totalByMaterialTypeProper" + name + "Percent"] = 100 * v / dataSummary.annualWeightByType[ k ];
	        		dataSummary["totalByMaterialTypeProper" + name + "PercentMissing"] = 100 - dataSummary["totalByMaterialTypeProper" + name + "Percent"];
	        	});
	            
	        	if (dataSummary.totalByMaterialTypeRecyclable > 0){
	        		dataSummary.recycleScenarioWeight  = dataSummary.divertableInLandfillRecyclable * 0.5;
	        		
	        		dataSummary.recycleScenarioDiversionRateNew = 100 * (parseFloat(dataSummary.totalAnnualDivertedProper) + parseFloat(dataSummary.recycleScenarioWeight)) / parseFloat(dataSummary.totalWasteGeneratedAnnual);
	        		dataSummary.recycleScenarioDiversionRateDelta = dataSummary.recycleScenarioDiversionRateNew - dataSummary.diversionRate;  
	        	}
	        	
	        	if (dataSummary.totalByMaterialTypeOrganic > 0){
	        		dataSummary.organicScenarioWeight = dataSummary.divertableInLandfillOrganic * 0.5;
	        		
	        		dataSummary.organicScenarioDiversionRateNew = 100 * (parseFloat(dataSummary.totalAnnualDivertedProper) + parseFloat(dataSummary.organicScenarioWeight)) / parseFloat(dataSummary.totalWasteGeneratedAnnual);
	        		dataSummary.organicScenarioDiversionRateDelta = dataSummary.organicScenarioDiversionRateNew - dataSummary.diversionRate;  
	        	}	        	
            }else{
            	// we will process again in a second, hoping we have the material
            	setTimeout( ()=>{ 
            		console.log("processData called again (waiting for audit-materials-used dataset)");
            		//this.processData() 
            	}, 1000);	
            }
            
            
			dataSummary.tenantLastGraph = 5 + _.size(APP.NDT.announcements.totalWasteToLandfillByTenantDetails);

            _.each(dataSummary, (v,k)=>{
				this.NDT.announce(k, v);
			});
			
			return dataSummary;
		},
		
		fetch: function(me){
			this.NDT = APP.NDT;
			if (this.NDT == null){
				//
			}			
		
			var that = this;
			
			const auditId = this.auditId || this.NDT.me.scope.params.audit;
			this.loading = true;
			
			APP.central.WasteAudit.allData( auditId ).then((r)=>{
				this.loading = false;
				const ret = that.processData(r.result);
				if (this.callback){
					this.callback(ret);
				}
			});
		
			var recommendations = _.filter(this.NDT.getData("ds-recommendations").filter(), {type: "Recommendation"}).map(function(r){ return r.title.replace(/{{.*}} ?/,'')}).join("\n");
			this.NDT.announce("recommendations_list", recommendations );			
			

		},

		testing: function(me){
			if (this.NDT == null){
				this.NDT = APP.NDT;
			}

			if(this.NDT.datasets.materials){ var byType = this.NDT.getData("dv-test1").group('material_type');
			var results = {};
			var that = this;
			_.each( _.keys(byType), function(k){
				var material = that.NDT.datasets.materials.filter({id: k})[0];
				var val = _.sumBy( byType[k], r=>{return parseFloat(r['details.sample_weight'])})

				val = isNaN(val) ? 0 : val;

				results[material.category_summary] = Math.round(val * 100) / 100;
			})	
			return results;
			}

		},
		
		copyStandardMaterials: function(fid, instanceId){
		    const form = APP.customize.getForm(fid);
            if(form != undefined){
                APP.central.Form.copyInstancesWithValue(instanceId || "", "is_standard_material", "Yes").then( response=> {
                    if(APP.customize.getForm(fid).form["waste_audit_num"] == undefined && APP.NDT.announcements["Audit_Number"] != undefined){
                        APP.customize.getForm(fid).formActions.set("waste_audit_num", APP.NDT.announcements["Audit_Number"]);
                    }
                    let existingElements = {};
                    _.forEach(form.form["audit_material"], (am)=>{
                        if(existingElements[am.material_type] == undefined){
                            existingElements[am.material_type] = "seen";
                        }
                    })
                    
                    _.forEach(response, (r)=>{
                        let stream = null;
                        if(r["standard_audit_material_stream"] != undefined && r["standard_audit_material_stream"].trim().length > 0){
                            stream = parseInt(r["standard_audit_material_stream"]);
                        }

                        if(existingElements[r.id] == undefined){
                            form.formActions.addRow("audit_material", {
                                material_name: r["standard_audit_material_name"] || r["material_name"], 
                                material_type: r["id"], 
                                stream: stream,
                                waste_policy: r["standard_audit_material_waste_policy"],
                                waste_policy_rms: r["standard_audit_material_waste_policy_rms"],
                                source_separation_required: r["standard_audit_source_separation_required"]
                            });
                            existingElements[r.id] == "seen";
                        } else {
                            console.log(r.id + " already exists in material list");
                        }
                    });
                });
            } else {
                APP.alert("missing materials form! " + fid);
            }
		},
		
		copyStandardStreams: function(fid, instanceId){
            const form = APP.customize.getForm(fid);
            if(form != undefined){
                APP.central.Form.copyInstancesWithValue(instanceId || "", "is_standard_stream", "Yes").then( response=> {
                    if(APP.customize.getForm(fid).form["stream"] == undefined && APP.NDT.announcements["Audit_Number"] != undefined){
                        APP.customize.getForm(fid).formActions.set("waste_audit_num", APP.NDT.announcements["Audit_Number"]);
                    }
                    let existingElements = {};
                    _.forEach(form.form["stream"], (am)=>{
                        if(existingElements[am.stream_type] == undefined){
                            existingElements[am.stream_type] = "seen";
                        }
                    })
                    
                    _.forEach(response, (r)=>{
                        let stream = null;

                        if(existingElements[r.id] == undefined){
                            form.formActions.addRow("stream", {
                                r_r_r_plan: r.moe_plan,
                                r_r_r_plan_rms: r.moe_plan_rms,
                                stream_type: r.id
                            });
                            existingElements[r.id] == "seen";
                        } else {
                            console.log(r.id + " already exists in stream list");
                        }
                    });
                });
            } else {
                APP.alert("missing stream form! " + fid);
            }
        },
        
        updateRemainder: function(boundContext){
            var sampleWeight = boundContext.magicalGet("sample_weight");
            var sum = 0;
            _.each(boundContext.to.details,(r)=>{
                if(r.isRemainder != "yes" && Number.isInteger(parseInt(r.sample_weight))){ 
                    sum += parseFloat(r.sample_weight)
                } 
                return sum;
            });
            let remainderValue = this.round(sampleWeight - sum,3);
            if(remainderValue < 0){
                remainderValue = 0;
            }

            var remainderIndex = null;
            for(var i = 0; i < boundContext.to.details.length;i++){
                if(boundContext.to.details[i].isRemainder == "yes"){
                    remainderIndex = i;
                }
            }
            if(remainderIndex != undefined){
                boundContext.to.details[remainderIndex].sample_weight = remainderValue;
                var newBound = boundContext.to.details[remainderIndex];
                newBound.sample_weight = remainderValue;
                boundContext.magicalSet("details." + remainderIndex, newBound);
            }
        },
        
        materialColoursBySummary: function(labels){
            if(!Array.isArray(labels)){ 
                  labels = [labels];
            }

            return labels.map( (l)=>{
                var m = APP.NDT.datasets["audit-materials-used"].filter({'material_name': l})[0];
                if (m == null){
                    m = APP.NDT.datasets["audit-materials-used"].filter({'material_type_nice_name': l})[0];
                }
                if (m == null){
                    m = APP.NDT.datasets["audit-materials-used"].filter({'category_summary': l})[0];
                }
                
                if (m == null) {
                    m = WASTE_AUDIT.guess(l);
                }
                m.colour = m.material_colour || m.colour;
                return {label: l, colour: m ? m.colour : '#cc0000'};
            });
        },
        
        guess: function(l){
        	
        	const blues = ["Recyclable Material","Scrap Metal","Containers","Cardboard","Diverted","Mixed Recycling"];
            const greens = ["Organic Material"];
            const blacks = ["Mixed Waste", "Landfill","Not Diverted", "Contamination"];
            
            let s = null;
            
            if (blues.indexOf(l) >= 0){
                s = {colour: "#3C64C8"};
            }else if (blacks.indexOf(l) >= 0){
                s = {colour: "#000000"};
            }else if (greens.indexOf(l) >= 0){
                s = {colour: "#289646"};
            }else if (l.indexOf("Liquid") >= 0){
            	s = {colour: "#2896F0"};
            }
            
            if (s == null){
            	s = {colour: '#cccc00'};
            }
        	return s;
        },
        
        streamColours: function(labels){
            if(!Array.isArray(labels)){ 
                labels = [labels];
            }
            
            return labels.map( (l)=>{                
                var s = APP.NDT.datasets["audit-data"].filter({'stream_name': l})[0];

                if (s == null) {
                    s = WASTE_AUDIT.guess(l);
                }
                s.colour = s.default_colour || s.colour;
                
                return {label: l, colour: s ? s.colour : '#cc0000'};
            });
        },
        
        copyMissingStreams: function(fid, instanceId){
            const form = APP.customize.getForm(fid);
            if(form != undefined){
                if(APP.customize.getForm(fid).form["stream"] == undefined && WASTE_AUDIT.auditId != undefined){
                    APP.customize.getForm(fid).formActions.set("waste_audit_num", WASTE_AUDIT.auditId);
                }
                let existingElements = {};
                _.forEach(form.form["stream"], (am)=>{
                    if(existingElements[am.stream_type] == undefined){
                        existingElements[am.stream_type] = "seen";
                    }
                })
                
                const streamsUsed = _.uniq(_.compact(WASTE_AUDIT.dataSet.filter().map((r)=>r.stream)));
                
                APP.central.Stuff.listFormInstances(29).then((response)=>{
                    const allStreams = {};
                    _.forEach(response.result, (streamData)=>{
                        allStreams[streamData.id] = streamData;
                    });
                    
                    _.forEach(streamsUsed, (streamId)=>{
                        
                        if(existingElements[streamId] == undefined && allStreams[streamId] != undefined){
                            form.formActions.addRow("stream", {
                                r_r_r_plan: allStreams[streamId].moe_plan,
                                r_r_r_plan_rms: allStreams[streamId].moe_plan_rms,
                                stream_type: streamId
                            });
                            existingElements[streamId] == "seen";
                        } else {
                            console.log(streamId + " already exists in stream used list or missing from stream definitions");
                        }
                    });
                });
                
            } else {
                APP.alert("missing stream form! " + fid);
            }
        },
        
        copyMissingMaterials: function(fid, instanceId){
            const form = APP.customize.getForm(fid);
            if(form != undefined){
                if(APP.customize.getForm(fid).form["audit_material"] == undefined && WASTE_AUDIT.auditId != undefined){
                    APP.customize.getForm(fid).formActions.set("waste_audit_num", WASTE_AUDIT.auditId);
                }
                let existingElements = {};
                _.forEach(form.form["audit_material"], (am)=>{
                    if(existingElements[am.stream_type] == undefined){
                        existingElements[am.stream_type] = "seen";
                    }
                })
                
                const materialsUsed = _.uniq(_.compact(WASTE_AUDIT.dataSet.filter().map((r)=>r.waste_category)));
                
                APP.central.Stuff.listFormInstances(28).then((response)=>{
                    const allMaterials = {};
                    _.forEach(response.result, (materialData)=>{
                        allMaterials[materialData.id] = materialData;
                    });
                    
                    _.forEach(materialsUsed, (materialId)=>{
                        if(existingElements[materialId] == undefined && allMaterials[materialId] != undefined){
                            let stream = null;
                            if(allMaterials[materialId].standard_audit_material_stream != undefined && allMaterials[materialId].standard_audit_material_stream.trim().length > 0){
                                stream = parseInt(allMaterials[materialId].standard_audit_material_stream);
                            }
                            
                            form.formActions.addRow("audit_material", {
                                material_name: allMaterials[materialId].standard_audit_material_name || allMaterials[materialId].category_summary, 
                                material_type: materialId, 
                                stream: stream,
                                waste_policy: allMaterials[materialId].standard_audit_material_waste_policy,
                                waste_policy_rms: allMaterials[materialId].standard_audit_material_waste_policy_rms? allMaterials[materialId].standard_audit_material_waste_policy_rms.structure : undefined,
                                source_separation_required: allMaterials[materialId].standard_audit_source_separation_required
                            });
                            existingElements[materialId] == "seen";
                        } else {
                            console.log(materialId + " already exists in material used list or missing from material definitions");
                        }
                    });
                });
                
            } else {
                APP.alert("missing material form! " + fid);
            }
        },
        
        totalAmountList: function(){
        	const list = [];
        	
        	_.each( APP.NDT.announcements.materialTotalGeneratedAnnualByStream, (value, stream)=>{
        		let streamName = stream.toLowerCase();
        		if (streamName == "organics"){
        			streamName = "composting";
        		}
        		if (streamName == "landfill"){
        			list.push("The total annual amount of waste sent to landfill.")
        		}else{
        			list.push("The total annual amount of waste diverted through the " + streamName + " stream.")
        		}
        	});
        	
        	list.push("The total annual amount of the contamination in the various diversion streams.");
        	return list;
        }
};

window.WASTE_AUDIT = WASTE_AUDIT;

export default WASTE_AUDIT;

