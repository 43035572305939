import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DataContext } from  './components/Bound';

export default class ArrayOf extends Component {
    static contextType = DataContext;

	constructor(props, context) {
        super(props, context);
        var hasValues = (context.magicalGet(props.field) && context.magicalGet(props.field).length); 
        var count = hasValues  || 1;

        if (hasValues){
        	this.state = { startIndex: count, values: [], count: count };
        }else{
        	this.state = { editing: 0, startIndex: 0, values: [], count: count };
        }
        
    }
    
    handleAddArray(){
    	var dom = ReactDOM.findDOMNode(this);
		var inputs = dom.getElementsByTagName("input");
		var values = _.map(inputs, (el)=>{
			return el.value;
		});
		
    	this.setState({count: this.state.count+1, editing: this.state.count, values: values}, ()=>{
			
			if (inputs.length >= 1) {
				var lastInput = inputs[inputs.length-1];
				lastInput.focus();
			}
		});
    	return false;
    }
	
	handleKeyUp(e){
		if (e.key == "Enter"){
			this.handleAddArray()
		}
	}
	
	render(){
	
		var editors = [];
		
		for (var x = 0; x<this.state.count; x++){
			var theField = this.props.children.props.children.props.field || this.props.field;
			if (theField == null){
				console.log("you're going to have a bad time because you have an array not tied to a field.'")	
			}
			var currentData = null;
			
			if (this.context.to[this.props.field]) {
				currentData = this.context.to[this.props.field][x];
			}
			
			if (currentData == null){ 
				//give us an empty hash to be able to edit things
				currentData = {};
			}
			
			const editor = React.cloneElement(this.props.children.props.children, {to: currentData, field: theField + "." + x, grossparent: this.context});
			editors.push(<span className="array-item" key={"array"+x} onKeyUp={(e)=>this.handleKeyUp(e)}>{editor}</span>);
		}
		
		return <div className="array-outer">
			{editors}
			<button onClick={()=>this.handleAddArray()}>+</button>
			
		</div>
		
	}
}