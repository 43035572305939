import EditLines from '../Contract/EditLines.jsx';
import EditService from './EditService.jsx';
import ListServicess from './ListServices.jsx';

export default function initialize(permissions, options){
	window.APP.customRoutes.push({path: "/ui/services/list", component: ListServicess, key: "service_list"});	
	
	if(permissions.w || true){
		window.APP.customRoutes.push({path: "/ui/services/edit/:id", component: EditService, key: "service_edit"});
		window.APP.customRoutes.push({path: "/ui/services/editor/:serviceIds", component: EditLines, key: "service_edit_lines"});
	}
}