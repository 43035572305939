import React, { useState, useEffect, useContext } from 'react';
import { InputText } from '.';
import { DataContext } from './Bound';

export default function InputTextPattern(props){        
    const bound = useContext(DataContext);    
    const [matches, setMatches] = useState(true);
    const regex = props.pattern;

	const regexFormat = { format: (a,b)=>{ 
		
		let ret = a.toUpperCase();
			let match = ret.match(regex);
			if (match != null ){
                setMatches(true);
                let ret = match[0];
                if ( match[1] ){
                    ret += " " + match[1];
                }

                return a;
			}else{
                setMatches(false);
            }
			
			return a
		},
		parse: (a,b)=>{
			
			return a;
		}
	};    


    const myProps = {...props};
    myProps.formatter = regexFormat;
    if (matches == false){
        myProps.className="error";
        myProps.children = <em>Invalid Format{ props.message ? ": " + props.message : null}</em>;
    }
    
    return <InputText {...myProps}/>;
}
