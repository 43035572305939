import React, { useRef, useState, useContext } from 'react';

//import { InputText, InputSelect, InputCheckbox, TextArea, DatePicker, Button, Bound, InputMultiSelect, InputMultiPeopleSearch, ToolTip } from '../../../OpidCore/public/js/components';

import * as _ from 'lodash';
//import moment from 'moment';
import { DataContext } from '@opidcore/components/Bound';

export default function JsonEditor(props) {
	const textRef = useRef(null);
	const magic = useContext(DataContext);

	const [ rawJson, setRawJson ] = useState(magic.magicalGet(props.field));
	const [ optionsError, setOptionsError ] = useState(null);

	const handleChangeOptions = (e)=>{
			setRawJson( e.currentTarget.value);
	};
	// onBlur={(e)=>this.handleBlurOptions(e)} onChange={(e)=>this.handleChangeOptions(e)} defaultValue={JSON.stringify(this.state.fieldOptions)}

    const handleBlur = (e) => {
        try{
            if (e.currentTarget.value > ""){
                var json = JSON.parse(e.currentTarget.value);
                var newOption = JSON.stringify(json);
                
                magic.magicalSet(props.field, json);
            }
            setOptionsError(null);
        }catch(e){
            
        	setOptionsError({"optionsError": e.message, lastError: e});
        }
    }
	
	const value = typeof rawJson == "object" ? JSON.stringify(rawJson) : rawJson;
	const error = optionsError != null ?  <div className="error">{optionsError.optionsError}</div> : null;
    
	return <div className="jsonEditor">
		{error}
    	<textarea ref={textRef} value={value} onBlur={(e)=>handleBlur(e)} onChange={(e)=>handleChangeOptions(e)} />
    </div>;
}
