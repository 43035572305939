import React, { Component, useRef } from 'react';
//import ReactDOM from 'react-dom';

import * as _ from 'lodash';

//import SingleDayTimeLog from './TimeLog/SingleDayTimeLog.jsx';
import Month from '../../OpidCore/public/js/components/Calendar/Month.js';

import { CustomizeContext } from '../../OpidCore/public/js/Customization.jsx';
import { Bound, InputMultiCheckbox } from '@opidcore/components/index.js';


function TestBlock(props){
		return <div> 
			{props.eventsForDay.map((e)=>{return <strong>{e.lunch}</strong> })}
		</div>
}

export class ThrowAway extends Component {
	  static contextType = CustomizeContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
        		contentStructure: [{"cid":"test1","type":"ReactThing","component":"Header","options":{"content": "Loading"}}]
        };
        var a = CustomizeContext;
        setTimeout(()=>this.load(), 500);
    }
    
    load(){
    	/*fetch("/api/v1/contentStuff/testing?cid=test1").then((r)=>r.json()).then((d)=>{
    		this.setState({contentStructure: d.result})
    	});*/
    	
    	/** fetch("/testing").then((d)=>{
    	    console.log("d: " , d);
    	}); **/
    }
    
    render(){
        return <div> 
        	<h4>Throw away test1</h4>
        	
        	<Month DayComponent={TestBlock} data={[{date: '2020-06-03', 'lunch': 'pizza'}]}/>
        	
        </div>
        
    }
}

export default function TestingMulti(props){
    const ref = useRef({});

    return <div>

        <h2>I am multicheck</h2>
        <Bound to={ref.current}>
            <InputMultiCheckbox field="things" label="Selected" optionsCollection={[{id: 1, label: "abc"}, {id: 2, label: "def"}]} optionKey={"label"}>
                {(a,b,c)=><span>wut {a.label}</span>}
                </InputMultiCheckbox>
        </Bound>
    </div>
}