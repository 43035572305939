import React, { Component, PropTypes } from 'react';

export default class Modal extends Component {
    
    static propTypes = {
    };
    constructor(props, context) {
        super(props, context);
    
        this.state = {};
        
    }
    
    closeAction(){
        if (this.props.closeModal != null){
            this.props.closeModal();
        }else{
            alert("You need to setup a closeModal function in your view!");
        }
    }
      
    render(){     
        return (<div className={"modal_body " + this.props.className}>
            
                    {this.props.children}
                    <span id="modal_close" className="glyphicon glyphicon-remove-circle" onClick={()=>this.closeAction()} />
              </div>);
        
    }
}