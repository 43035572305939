import React, { useState, useEffect, useRef }  from 'react';
//import { Data } from '@opidcore/Data.js';
import { Loading, Button } from '@opidcore/components';
import { PageNumber} from  '@opidcore/components/Pagination';
// import { DataContext } from '@opidcore/components/Bound';
// import { GridContext, GridHeading, GridItemGroup } from '@opidcore/components/Grid';
// import File from '../../components/File';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

export default function PDFPreview({parameters}){
    const params = parameters ? parameters : useParams();
    const [pdfInfo, setPdfInfo] = useState({loading: true});
    const [shortenCoords, setShortenCoods] = useState(true)
    const [toClipboard, setToClipboard] = useState(false)
    const [pos,setPos] = useState({x:0,y:0});
    const [rect, setRect] = useState(null)
    const fileMD5 = params.md5;
    const rectRef = useRef(null);
    const [pageNum, setPageNum] = useState(params.pageNumber ? params.pageNumber : 0);

    const refreshPDF = () => {
        APP.central.Import.pdfInfo2(fileMD5,pageNum).then( (r)=>{
            if(r.result) {
              let newPdfInfo = r.result;
              newPdfInfo.loading = false;
              setPdfInfo( newPdfInfo );
            }
        });
    };

    const addAnnotation = (annotation) => {
        APP.central.Import.addAnnotation(fileMD5, {...annotation, page:pageNum} ).then( (r)=>{
            refreshPDF();
        });
    };

    useEffect( ()=>{
        refreshPDF();
    }, [pageNum]);

    if (pdfInfo.loading){
        return <Loading/>;
    }

    const positionFromMouse = (e)=>{
        const outer = e.target.getBoundingClientRect();
        const newPos = {x: e.pageX - outer.x - window.scrollX, y: e.pageY - outer.y - window.scrollY};
        newPos.pdfX = pdfInfo.width * newPos.x / outer.width;
        newPos.pdfY = pdfInfo.height * newPos.y / outer.height;
        return newPos;
    }

    const followMouse = (e)=>{
        const newPos = positionFromMouse(e);
        setPos(newPos);
    };

    const mouseUp = (e)=>{
        rectRef.current.end = positionFromMouse(e);

        const left = rectRef.current.start.pdfX < rectRef.current.end.pdfX ? rectRef.current.start.pdfX : rectRef.current.end.pdfX;
        const bottom = pdfInfo.height - (rectRef.current.start.pdfY > rectRef.current.end.pdfY ? rectRef.current.start.pdfY : rectRef.current.end.pdfY);

        const width = Math.abs(rectRef.current.start.pdfX - rectRef.current.end.pdfX);
        const height = Math.abs(rectRef.current.start.pdfY - rectRef.current.end.pdfY);

        const annotation = { left: left, bottom: bottom, width: width, height: height };
        annotation.text = params.serviceDescription || "Service Description Here";
        addAnnotation(annotation);
        const clipString = "x1: " + rectRef.current.start.pdfX + " y1: " + rectRef.current.start.pdfY + ", x2: " + rectRef.current.end.pdfX + " y2: " + rectRef.current.end.pdfY
        setRect(clipString)
        if (toClipboard) {
            navigator.clipboard.writeText(clipString)
            APP.alert(clipString + " was copied to the clipboard.")
        }
    };

    const mouseDown = (e)=>{
        e.preventDefault();
        rectRef.current = {start: positionFromMouse(e) };
    };

    const pages = _.map( _.range(1,pdfInfo.pages+1), (p)=>{
        return <PageNumber pageNumber={p} activePage={pageNum+1} onClick={()=>setPageNum(p-1)} />
    });

    return <div className="pdf-preview">
        <Button onClick={() => setShortenCoods(!shortenCoords)}>Toggle Coordinate Length</Button><Button onClick={() => setToClipboard(!toClipboard)}>{toClipboard ? "Copying to Clipboard" : "Not Copying to Clipboard"}</Button>
        <div>{rect ? "Rectangle coordinates: " + rect : "Click and drag to make a rectangle."}</div>
        {shortenCoords ? <div className="where">x: {_.round(pos.x,0)}, y: {_.round(pos.y,0)} | pdf x: {_.round(pos.pdfX,0)}, pdf y: {_.round(pos.pdfY,0)}</div> : <div className="where">{pos.x},{pos.y}  {pos.pdfX},{pos.pdfY}</div>}
        <div id="pagination">
            <span>Page:</span> {pages}
        </div>
        <div className="pdf-preview-outer">
            <img src={pdfInfo.image} className="preview" onMouseMove={followMouse} onMouseDown={mouseDown} onMouseUp={mouseUp}/>
        </div>
    </div>;
 }