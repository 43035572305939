import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BoundComponent from './BoundComponent';
import Bound from './Bound';
import * as _ from 'lodash';

export default class MultiFilter extends Component {
	static propTypes = {
	    update: PropTypes.func,
	    property: PropTypes.string,

	};

	constructor(props, context) {
		super(props, context);
		
	    this.state = {
	    		searchResults: [],
	    		selectedOptions: [],
	    		allOptions: props.options,
	    		value: "",
	    		resultsShown: false
	    };
	}
	
	labelFormatter(row){
        return row.name;
    }
    
    handleChange(e){
    	if(e.target.value != undefined && e.target.value.trim().length <= 0){
    		this.setState({value: "", resultsShown: false});
    	} else {
    		this.props.search(e.target.value);
    		this.setState({value: e.target.value, resultsShown: true});
    	}
    }
    	
	componentWillReceiveProps(nextProps){
		let nextState = {... this.state};
		nextState.allOptions = nextProps.options;

		nextState.searchResults = {};

		_.forEach(Object.keys(nextProps.options), (key) => {
			let currentList = [];
			
			if(nextState.value != undefined && nextState.value.trim().length > 0){
				currentList = _.filter(nextProps.options[key], (option) => {
					let notFound = true; //should make this filter out already selected options
					
					return notFound;
				});
			}
			
			nextState.searchResults[key] = currentList;
		});
		
		this.setState(nextState);
	}
    	
	addResult(field, result){
		if(result != undefined && this.state.selectedOptions.indexOf(result) < 0){
			let newState = {...this.state};
			
			newState.selectedOptions.push(result);
			newState.resultsShown = false;
			
			this.setState(newState, ()=>{
				this.props.handleAdd(field, result);
				
				//if(result.id == 0){ //we pressed enter or search button so auto filter
				this.props.filter();
				//}
			});
		}
	}
	
	closeSearch(){
		this.setState({resultsShown: false, value: ""});
	}
    	  
	render(){	
		let searchOptions = _.map(Object.keys(this.state.searchResults), (key) => {
			let groupList = _.map( this.state.searchResults[key], (option) =>{
				return <li key={key + " " + (option.id || Math.random())} onClick={()=>this.addResult(key, option)} >{this.labelFormatter(option)}</li>;
			});
			
			return <div className="filterGroup" key={key}>
				<label>{key[0].toUpperCase() + key.substring(1)}</label>
				<ul>
					{groupList}
				</ul>
			</div>
		});
		
		let searchResults = "";
		
		if(this.state.resultsShown){
			searchResults = <div className="searchResults col-md-10 col-sm-10">
				{searchOptions}
			</div>;
		}
		
		return (<div className={"multifilter_body field " +  (this.props.className || "col-xs-12 col-md-12") }>
			<label>{this.props.name}</label>
			
			<div className="searchWrapper">
				<div className="searchBox">
					<div className="searchTextbox col-md-10 col-sm-10">
						<div className="glyphicon glyphicon-remove" onClick={()=>this.closeSearch()} />
						<input
					        type="text"
					        className={"form-control" || this.props.className}
					        placeholder={this.props.placeHolder}
					        value={this.state.value}
					        onChange={(e)=>this.handleChange(e)} 
							onKeyPress={(e) => {(e.key === 'Enter' ? this.addResult("keyword", {id: 0, name: e.target.value}) : null)}} />
						
					</div>
					<div id="searchButtonContainer" className="col-md-2 col-sm-2">
						<div id="searchButton" className="btn btn-default btn-sm" onClick={()=>this.addResult("keyword", {id: 0, name: this.state.value})}>Search</div>
					</div>
				</div>
				
				{searchResults}
			</div>
          </div>);
		
	}
}
