import React, { Fragment, useEffect, useRef, useState } from "react";
import { Bound, HoverElement, Icon, InputToggleSwitch, Loading } from "@opidcore/components";
import { Tabular, TabularColumn } from "@opidcore/components/Tabular";
import { useData, useNavBar } from "@opidcore/hooks/WTF";
import _ from "lodash";
import { Link, useParams } from "react-router-dom";
import { APP } from "../../../../js";
import PreviewList from "../../components/PreviewListWithDetails";
import { NiceCurrency, NiceEquipmentSize, NiceEquipmentType, NiceMaterial, NiceServiceSchedule } from "../Nice.jsx";
import { EditServicesWithTabs } from "../Service/EditService";

function DetailsList(props) {
	let [data, setData] = useState([]);
	let [editLoading, setEditLoading] = useState(null); 
	const openedServiceId = useRef(0);	
	const histShow = useRef({ histShowStatus: false });
	let params = useParams();
	const clientId = (props.id != undefined ? props.id : params.customerId) || "0";

	const detailsFetcher = (site) => {   
	  if (props.site != null && props.site.customer != null && props.site.id != null) {
		  APP.central.Service.fetchServicesWithCostsForCustomer(props.site.customer, props.site.id).then((r) => {
			  histShow.current.histShowStatus ? setData(_.sortBy(r.result, "friendlyId")) : setData(_.sortBy(_.filter(r.result, (s) => {
				  return s.historical <= 0;
			  }),"friendlyId"));
		  });
		} 
    };

	useEffect(() => {
		if (props.site != undefined) { 
			detailsFetcher(props.site);
		}
	}, [props.site]); 

	const modalOptions = { 
		afterClose: () => { 
			detailsFetcher(props.site);
		},
	};

	const serviceModalOptions = {
		afterClose: () => {
			if(openedServiceId.current != 0){
				saveService(openedServiceId.current);						    
			}			 
		},
	};

	const createService = () => {
		if (props.site != undefined) {
			APP.central.Service.create({ customer: clientId, site: props.site }).then(r => {
				APP.instance.createModal("/ui/services/edit/" + r.result.friendlyId, { modal_name: "New Service" }, modalOptions);
			});
		} else {
			APP.instance.openConfirmDialog("No site selected.");
		}
	};

	
  const openService = (data, serviceId, e) => {
		if(serviceId != 0){
			openedServiceId.current = serviceId; 
		}
		setEditLoading({[serviceId]: true});		
		setTimeout(()=>{	
			if (e.shiftKey != false){
				APP.instance.createModal("/ui/services/edit/" + serviceId, { modal_name: "Service #" + serviceId }, modalOptions);
			}else{				
				APP.instance.createModal(
					<EditServicesWithTabs data={data} serviceId={serviceId} />, 
					{ modal_name: "Services " + data[0].site.friendlyId }, 
					serviceModalOptions
					);
			} 
			setEditLoading({[serviceId]: false});
		}, 2000); 	 
    }; 

	const saveService = (serviceId)=>{
		  
		const deltasToSaveInOrder = [];
		_.forEach(_.keys(APP.registeredBoundMagics), (rbmKey) => {
			let isMine = true;
	
			//we could make isMine smarter, but it mostly messes up with line items.
			if (rbmKey.startsWith("AP") || rbmKey.startsWith("AR")) {
			isMine = false;
			}
	
			if (isMine) {
			const rbm = APP.registeredBoundMagics[rbmKey];  
	
			if (rbm.isDirty()) {
				const deltas = rbm.getAllDeltas();
				deltasToSaveInOrder.push(deltas);         
				
			}
			}
		});			 
		
		return _.reduce(
			deltasToSaveInOrder,
			(previousPromise, deltas) => {
			return previousPromise.then(() => {
				return APP.central.ServiceContract.saveBoundDeltas(serviceId, deltas).then((r) => {
				if (props.afterSave != undefined) {
					if (r.result["Service:" + serviceId] != undefined) {
					props.afterSave(r.result["Service:" + serviceId]);
					}
				}
				});
			});
			},
			Promise.resolve()
		);	 
		
	}
  

	//copy service by id
	const copyService = (serviceId) => {
		const confirmCallback = (continueResult) => {
			if (continueResult) {
				APP.central.Service.copyService(serviceId).then((r) => {
					//r.result will be the copy
					detailsFetcher(props.site);
				});
			}
		};

		APP.instance.openConfirmDialog("Create a Copy?", "", confirmCallback);
	};

	const deleteService = (serviceId) => {
		const confirmCallback = (continueResult) => {
			if (continueResult) {
				APP.central.Service.saveChanges(serviceId, { historical: 1 }).then((r) => {
					detailsFetcher(props.site);
				});
			}
		};

		APP.instance.openConfirmDialog("Set as Historical?", "Service ID: " + serviceId + " will be set as historical", confirmCallback);
	};

	const restoreService = (serviceId) => {
		const confirmCallback = (continueResult) => {
			if (continueResult) {
				APP.central.Service.saveChanges(serviceId, { historical: 0 }).then((r) => {
					detailsFetcher(props.site);
				});
			}
		};

		APP.instance.openConfirmDialog("Restore?", "Service ID: " + serviceId + " will be set as non-historical", confirmCallback);
	};

  const getVendorCost = (theRow) => {
    return (
      <HoverElement content={theRow.additionalInformation.costs.vendor_cost} >
        <div className="combinedInvoiceHover">
          <h4>Period AP Invoices</h4>
          <Tabular data={theRow.additionalInformation.ap_invoice_ids}>
            <TabularColumn title="AP" data={(row) => <Link to={"/ui/apinvoice/" + row}>{row}</Link>} />
          </Tabular>
        </div>
      </HoverElement>
    )
  }

	return (
    <div key="detail_list" className="detail_list">
      <h4 className="title">
        Services{" "}
        <Bound to={histShow.current} onChange={detailsFetcher}>
          <InputToggleSwitch
            field="histShowStatus"
            className="inline"
            icon="eye"
            title={histShow.current.histShowStatus == undefined || histShow.current.histShowStatus == false ? "Historical Not Shown" : "Historical Shown"}
          />
        </Bound>
		
      </h4>

      <div className="add_service action_button" onClick={createService}>
        +
      </div>

      <Tabular data={data} defaultSort="friendlyId" defaultSortOrder="asc">
        <TabularColumn data={(row) => row.friendlyId} title="Service #" />
        <TabularColumn data={(row) => <NiceMaterial>{row.material}</NiceMaterial>} title="Material" />
        <TabularColumn data={(row) => row.quantityOnSite || 1} title="Qty" />
        <TabularColumn
          data={(row) => (
            <div className="container-stuff">
              {row.containerName ? <span>{row.containerName + " - "}</span> : null} <NiceEquipmentSize>{row.equipmentSize}</NiceEquipmentSize> <NiceEquipmentType>{row.equipmentType}</NiceEquipmentType>
            </div>
          )}
          title="Container"
        />
        <TabularColumn data={(row) => <NiceServiceSchedule>{row.schedule}</NiceServiceSchedule>} title="Schedule" />

		{/*
        <TabularColumn
          data={(row) => (
            <Link to={"/ui/arinvoice/" + row.additionalInformation.ar_invoice_id} className="plainLink">
              <NiceCurrency title={row.additionalInformation.client_latest_month}>{row.additionalInformation.costs.client_cost}</NiceCurrency>
            </Link>
          )}
          title="Client Cost"
        />
        <TabularColumn
          data={(row) => (
            <HoverElement
              content={<NiceCurrency title={row.additionalInformation.client_latest_month}>{row.additionalInformation.costs.vendor_cost}</NiceCurrency>}
            >
              <div className="combinedInvoiceHover">
                <h4>{row.additionalInformation.client_latest_month}</h4>
                <Tabular data={row.additionalInformation.ap_invoice_ids}>
                  <TabularColumn title="AP" data={(thisRow) => <Link to={"/ui/apinvoice/" + thisRow}>{thisRow}</Link>} />
                </Tabular>
              </div>
            </HoverElement>
          )}
          title="Vendor Cost"
        />
        <TabularColumn data={(row) => <NiceCurrency>{row.additionalInformation.costs.margin_latest}</NiceCurrency>} title="Margin" />
		*/}

		<TabularColumn data={(row) => <>{row.scheduleDays}</>} title="Service Days"/>
		<TabularColumn data={(row) => <>{row.additionalInformation.vendorName}</>} title="Vendor"/> 

        <TabularColumn
        
		  data={(row) => {
            return (editLoading && editLoading[row.id]) ? (
				<span>
					<Icon icon="caret-right" size="3x" onClick={(e) => openService(data, row.id, e)} type="span" className="clickable-icon" />
				    <Loading/>
				</span>
            ) : (
				<Icon icon="caret-right" size="3x" onClick={(e) => openService(data, row.id, e)} type="span" className="clickable-icon" />
            );
          }}
          title="Edit"
        />
        <TabularColumn data={(row) => <Icon icon="copy" size="2x" onClick={() => copyService(row.id)} type="span" className="clickable-icon" />} title="Copy" />
        <TabularColumn
          data={(row) => {
            return row.historical != 1 ? (
              <Icon icon="trash" size="2x" onClick={() => deleteService(row.id)} type="span" className="clickable-icon" />
            ) : (
              <Icon icon="trash-restore" color="green" size="2x" onClick={() => restoreService(row.id)} type="span" className="clickable-icon" />
            );
          }}
          title="Delete / Restore"
        />
      </Tabular>
    </div>
  );
}

function SiteActions(props) {
	const openEditModal = (e) => {
		e.stopPropagation();
		APP.redirect("/ui/sites/edit/" + props.site.friendlyId);
	};

	const openSiteView = (e) => {
		APP.redirect("/ui/sites/detail/" + props.site.friendlyId);
	};

	return (
		<div className="actions" onMouseLeave={props.mouseLeaveEvent}>
			<div className="action-item" onClick={openSiteView}>
				View
			</div>
			<div className="action-item" onClick={openEditModal}>
				Edit
			</div>
			<div className="action-item" onClick={props.deleteSite}>
				Delete
			</div>
		</div>
	);
}

function SitePreview(props) {
	let [showSiteActions, setShowSiteActions] = useState(false);

	const openActions = (e) => {
		e.stopPropagation();
		setShowSiteActions(true);
	};

  const openSiteView = (e) => {
		APP.redirect("/ui/sites/detail/" + props.site.friendlyId);
	};

	return (
		<Fragment>
      <div className="action_button" onClick={(e) => openSiteView(e)}>
        <Icon icon="map-marker" size="2x" />
      </div>
			<div key={"site_preview" + props.site.id} className="site_preview" title={props.site.fullName}>
				<div>
					{props.site.friendlyId || props.site.id}
          <br /> 
          {props.site.name} 
				</div>
			</div>
			<div className="action_button" onClick={(e) => openActions(e)}>
				<Icon icon="ellipsis-v" size="2x" />
				{showSiteActions ? (
					<SiteActions
						site={props.site}
						mouseLeaveEvent={() => setShowSiteActions(false)}
						deleteSite={(e) => props.deleteSite(e, props.site)}
						modalOptions={props.modalOptions}
					/>
				) : (
					""
				)}
			</div>
		</Fragment>
	);
}

export default function CustomerSiteManager(props) {
	let params = useParams();
	const initialClientId = props.customerId != undefined ? props.customerId : params.customerId;
	const [clientId, setClientId] = useState(initialClientId);
	const [data, dataSet] = useData("site;" + clientId, { customer: clientId });
	const [clientData, clientDataSet] = useData("customer;" + clientId, clientId);    

	const nav = useNavBar();
	useEffect(() => {
		if (clientData != undefined && clientData.id != undefined) {
			const friendlyId = clientData.friendlyId;
			setClientId(clientData.id);

			const links = [<Link to={"/ui/clients/" + friendlyId}>Client</Link>, <Link to={"/ui/clients/sites/" + friendlyId}>Sites</Link>, <Link to={"/ui/clients/" + friendlyId + "/invoices"}>Invoices</Link>];

			nav.update(clientData.name + " - Sites & Services (Client #" + friendlyId + ")", links, { selectedItem: 1 });
			
		}
	}, [clientData]);


	const modalOptions = {
		afterClose: () => {
			dataSet.setQueryArgs({ options: { customer: clientId, historical: 0 } }); //opening the site fetches single site and throws away our cached useData
			dataSet.fetch();			
		},
	};

	const addSite = () => {
		APP.instance.createModal("/ui/sites/edit/0", { customer: clientId }, modalOptions); 
	};

	const deleteSite = (e, site) => {
		e.stopPropagation();

		const confirmCallback = (continueResult) => {
			if (continueResult) {       
				APP.central.Site.saveChanges(site.id, { historical: 1 }).then((r) => {                    
					//fetch again or just update the record manually
					dataSet.replace(r.result, "id");
					dataSet.filter({ historical: 0 }, true);
				});
			}
		};

		APP.instance.openConfirmDialog("Continue?", "Deleting is permanent.", confirmCallback);
	};

	if (dataSet.loading) {
		return <Loading />;
	} 
	
	return (
    <div key="customer_site_manager" className="customer_site_manager">     
      <PreviewList
        data={_.sortBy(data, (site) => {
          if (site.friendlyId) {
            return _.toNumber(_.split(site.friendlyId, "-").pop());
          }
          return site.id;
        })}
        dataSet={dataSet}
        add={addSite}
        topAdd={addSite}
        title="Sites"
        addTitle="Add New Site"
        showHistoricalToggle={true}
        historicalField="historical"
      >
        {(dataRow) => <SitePreview site={dataRow} deleteSite={deleteSite} modalOptions={modalOptions} />}
        {(activePreviewData) => <DetailsList site={activePreviewData} />}
      </PreviewList>
    </div>
  );
}

