import React, { useRef, Fragment, useState, useEffect } from "react";
import { useData, useNavBar } from "@opidcore/hooks/WTF";
import {
  Icon,
  TabularDataSet,
  TabularColumn,
  Button,
  Pagination,
  Searchable,
  ActionBar,
  Bound,
  InputText,
  UnlockToEdit,
  InputToggleSwitch,
  FlexBreak,
  Expandable,
  useMagic,
} from "@opidcore/components";
import ViewPanel, { Field } from "../../components/ViewLayouts";
import { StaffSelect } from "../../components";
import * as _ from "lodash";
import { NiceBaseEntity } from "../Nice.jsx";
import Util from "@opidcore/Util";

export default function StaffEdit(props) {
  useNavBar("Edit Staff", []);
  const [data, dataSet] = useData("staff", { paginate: 100 });
  const [aclGroups, setAclGroups] = useState([]);

  useEffect(() => {
    APP.central.Staff.getAllStaffAclGroups().then((r) => {
      if (r.result.length > 0) {
        setAclGroups(r.result);
      }
    });
  }, []);

  const editStaff = (staff) => {
    APP.instance.createModal(<EditStaff staff={staff} />, { modal_name: "Edit: " + staff.id + "-" + staff.firstName + " " + staff.lastName });
  };

  const newStaff = () => {
    APP.instance.createModal(<EditStaff staff={{ __new: true, __dataUID: dataSet.dataUID, id: 0 }} />, {
      modal_name: "New Staff",
    });
  };

  const takeoverStaff = (staff) => {
    APP.instance.createModal(<TakeoverStaff staff={staff} />, { modal_name: "Takeover: " + staff.id + "-" + staff.firstName + " " + staff.lastName });
  };

  return (
    <div className="full-size">
      <ActionBar title="Actions">
        <Searchable ds={dataSet} />
        <a className="btn btn-new" onClick={() => newStaff()}>
          New Staff
        </a>
      </ActionBar>

      <div>
        <Pagination dataSet={dataSet} />
        <Button onClick={() => dataSet.download("xlsx")}>excel</Button>
      </div>

      {dataSet.loading ? "loading" : ""}

      <TabularDataSet dataSet={dataSet} defaultSort="id" defaultSortOrder="asc">
        <TabularColumn style={{ width: "30px" }} data={(row) => row.id} title="#" />
        <TabularColumn data={(row) => row.firstName} title="First Name" />
        <TabularColumn data={(row) => row.lastName} title="Last Name" />
        <TabularColumn data={(row) => row.email} title="Email" />
        {/*<TabularColumn data={(row) => row.role} title="Role" />*/}
        <TabularColumn
          data={(row) => {
            let ret = "-";
            _.forEach(aclGroups, (a) => {
              if (a.id == row.id) {
                ret = a.acl_groups;
              }
            });
            return ret;
          }}
          title="ACL Group"
        />
        <TabularColumn
          data={(row) => {
            return row.lastLogin ? row.lastLogin : "-";
          }}
          title="Last Login"
        />
        <TabularColumn
          data={(row) => {
            return row.active ? "Yes" : "No";
          }}
          title="Active?"
        />
        <TabularColumn
          data={(row) => {
            return row.active ? (
              <Icon icon="edit" onClick={() => editStaff(row)} size="2x" />
            ) : (
              <div style={{ justifyContent: "space-between", display: "flex" }}>
                <Icon icon="edit" onClick={() => editStaff(row)} size="2x" />
                <Icon icon="people-arrows" onClick={() => takeoverStaff(row)} size="2x" />
              </div>
            );
          }}
        />
      </TabularDataSet>
    </div>
  );
}

function EditStaff(props) {
  const [staff, setStaff] = useState({ ...props.staff });

  const saveStaff = () => {
    let doSave = false;
    if (!staff.passCrypt) {
      doSave = true;
    } else {
      doSave = Util.doCheckPass();
    }

    if (doSave) {
      let saveStaff = { ...staff };
      //delete saveStaff.aclGroups;
      //delete saveStaff.phoneNumber;
      //delete saveStaff.position;
      APP.central.Staff.saveChanges(staff.id, saveStaff).then((r) => {
        if (r.status == "ok") {
          setStaff(r.result);
        } else {
          APP.alert("Something Went Wrong Saving Staff Changes.");
        }
      });
    } else {
      APP.alert("Follow password rules, or leave blank to save.");
    }
  };

  const sendWelcomeEmail = () => {
    APP.central.Staff.sendWelcomeEmail(staff.id).then((r) => {
      if (r.status == "ok") {
        APP.alert("Welcome Email Sent.");
      } else {
        APP.alert("Something Went Wrong.");
      }
    });
  };

  return (
    <Fragment>
      <Bound to={staff} className="basicForm" permissions={APP.getWebSession().permissions("Controlled Data Lists")}>
        <UnlockToEdit>
          <InputText field="id" name="#" />
        </UnlockToEdit>
        <InputText field="firstName" name="First Name" />
        <InputText field="lastName" name="Last Name" />
        <InputText field="email" name="Email" />
        <InputToggleSwitch
          field="active"
          name={(status) => {
            return status == undefined || status == false ? "Inactive" : "Active";
          }}
        />
        <InputText
          field="passCrypt"
          name="Password (Must be over 8 characters and contain at least one of each: capital letter, lowercase letter, number, and special character)"
          inputType="password"
          maxlength="31"
        />
      </Bound>
      <br />
      <Button onClick={saveStaff}>
        Save
      </Button>
      {staff && staff.id > 0 ? <Button title="Will generate random password if password field is blank." onClick={sendWelcomeEmail}>Send Welcome Email</Button> : ""}
    </Fragment>
  );
}

//Takeover modal allows user to easily transfer database items from an inactive staff to different staff
function TakeoverStaff(props) {
  const inactiveStaffRef = useRef({ ...props.staff });
  const boundRef = useRef({ takeOverStaff: -1 });
  const [staffTransferData, setStaffTransferData] = useState();
  const [updatedItems, setUpdatedItems] = useState("");

  useEffect(() => {
    updateTransferData();
  }, []);

  //gets new results for Transfer availability
  const updateTransferData = () => {
    APP.central.Staff.getStaffTransferList(inactiveStaffRef.current.id).then((r) => {
      setStaffTransferData(r.result);
    });
  };

  //called when transfer button is clicked.
  const transferSelected = () => {
    const options = boundRef.current;
    if (options.takeOverStaff > 0) {
      APP.central.Staff.doStaffTransfer(inactiveStaffRef.current.id, options.takeOverStaff, options).then((r) => {
        const types = r.result.reduce((types, item) => {
          const type = types[item.__type] || [];
          if (type.map((t) => t.id).indexOf(item.id) == -1) {
            type.push(item);
          }
          types[item.__type] = type;
          return types;
        }, {});

        let updatedString = "No Changes Made";
        if (!_.isEmpty(types)) {
          updatedString = "Updated: ";
          _.forEach(types, (type) => {
            const baseEntity = window.APP.baseEntities[type[0].__type] || {};
            updatedString += type.length + " " + (baseEntity.pluralName || type[0].__type) + ", ";
          });
        }

        setUpdatedItems(updatedString);
        updateTransferData();
      });
    }
  };

  return (
    <Fragment>
      <Bound to={boundRef.current}>
        <h3>Staff Takeover for {inactiveStaffRef.current.firstName + " " + inactiveStaffRef.current.lastName}</h3>

        <ViewPanel>
          <NiceTakeoverItems items={staffTransferData ? staffTransferData : null} />
        </ViewPanel>

        <StaffSelect what="staff" field="takeOverStaff" name="Available Staff" />

        <Button onClick={transferSelected}>Transfer Selected</Button>
      </Bound>
      <h3>{updatedItems}</h3>
    </Fragment>
  );
}

//Displays any takeover items that the Java backend decided to give us.
function NiceTakeoverItems(props) {
  const items = props.items;
  //console.log("items");
  //console.log(items);

  if (items) {
    let labels = [];
    for (let i in items) {
      if (items[i].length > 0) {
        labels.push(i);
      }
    }

    return (
      <Fragment>
        {labels.map((label) => {
          const baseEntity = window.APP.baseEntities[items[label][0].__type] || {};
          return (
            <Field key={label} label={label}>
              For {items[label].length} {baseEntity.pluralName || items[label][0].__type}
            </Field>
          );
        })}

        <FlexBreak />

        {labels.map((label) => {
          return (
            <Expandable key={label} open={false}>
              {items[label] ? <NiceItems items={items[label]} /> : null}
            </Expandable>
          );
        })}

        <FlexBreak />

        {labels.map((label) => {
          return (
            <InputToggleSwitch
              key={label}
              field={label}
              name={(status) => {
                return status == undefined || status == false ? "Do Not Transfer" : "Transfer";
              }}
            />
          );
        })}
      </Fragment>
    );
  } else {
    return null;
  }
}

function NiceItems(props) {
  const items = props.items;

  return (
    <ul>
      {items.map((item) => (
        <li key={item.id}>
          <NiceBaseEntity thing={item} />
        </li>
      ))}
    </ul>
  );
}
