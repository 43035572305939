import React, { useEffect, useRef, useState }  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { library } from '@fortawesome/fontawesome-svg-core';

const SETTINGS = {
  library: 'fas',
  defaultColour: '#cc0000'
}
;

//import { fas } from 

/**
 * How to use: https://fontawesome.com/how-to-use/on-the-web/using-with/react 
 * e.g. <FontAwesomeIcon icon={["far", "coffee"]} /> represents class='far fa-coffee'
 * NOTE: there should be fas, far, fal, fad. We seem to only have fas and fal accessible
 * 
 * props.size must be in the form of '1x', '2x', '3x', etc.
 */

function loadFAL(){
  if ( library.definitions['fal'] == null ){
    import('@fortawesome/pro-light-svg-icons')
    .then( mod=>{
      library.add(mod.fal);
      if (window._GLOBAL_ICONS){
        library.add( _.values(window._GLOBAL_ICONS) )
      }
    }).catch( e=>{
      console.log("Icon.jsx: Caught exception: could not find the fortawesome module");
    });	
  }
}

function loadFAS(){
  if (library.definitions['fas'] == null ){
    import('@fortawesome/free-solid-svg-icons')
    .then( mod=>{
      library.add(mod.fas);
      if (window._GLOBAL_ICONS){
        library.add( _.values(window._GLOBAL_ICONS) )
      }
    }).catch( e=>{
      console.log("Icon.jsx: caught exception: could not find the fortawesome module");
    });	
  }
}

export function IconSetup(library, colour){
  SETTINGS.library = library;
  SETTINGS.defaultColour = colour;

  if (SETTINGS.library == 'fal'){
    loadFAS();
  }else{
    loadFAL();
  }

}

export default function Icon(props) {
  const Type = props.type || (props.onClick ? "button" : "span");
  const lib = library;
  const fontAwesomeType = props.fType || SETTINGS.library;
  const [loading, setLoading] = useState(0);
  
  if (lib.definitions[fontAwesomeType] == null){
    if (fontAwesomeType == 'fal'){
      loadFAS();
    }else{
      loadFAL();
    }
    if (loading > 50){
      console.log("Error with Font Awesome Library " + fontAwesomeType);
    }else{
      setTimeout( ()=>{
        setLoading(loading + 1);
      }, 100);
    }

    return null;
  }

  if (props.icon == null || props.icon == ""){
    return null;
  }

  const svg = <FontAwesomeIcon title={props.title} icon={[fontAwesomeType, props.icon]} size={props.size} color={props.colour || props.color || SETTINGS.defaultColour}/>;

  if (props.svg){
    return svg;
  }

  return (<>
  		<Type className={"icon-outer " + props.className + " " + (props.disabled? "disabled": "")} onClick={props.disabled? null : props.onClick} onMouseOver={props.onMouseOver} title={props.title} style={props.style}>{svg}</Type>
     </>
  );
}
