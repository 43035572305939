import React from 'react';
import { Tabular, TabularColumn } from '@opidcore/components/Tabular';
//import FakeRouted from '@opidcore/components/Content/FakeRouted.jsx';
import { useData } from '@opidcore/hooks/WTF';
//import ViewView from '../../../js/view.jsx';
import Button from '../../../../OpidCore/public/js/components/Button.js';
//import Loading from '@opidcore/components/Loading.jsx';

export default function XeroCurrencies() {
    const [data, dataSet] = useData("currency")
    
    const updateCurrencies = () => {
        APP.central.Xero.currencies().then((r) => {
            return r.result
        })
    }

    return <div>
        <Tabular data={data}>
            <TabularColumn title="ID" data={(row) => row.id}/>
            <TabularColumn title="Code" data={(row) => row.code}/>
            <TabularColumn title="Description" data={(row) => row.description}/>
        </Tabular>
        <Button onClick={() => updateCurrencies()}>Update Currencies</Button>
    </div>
}