import React, { useContext, useEffect, useState } from 'react';
import { InputAutoComplete, InputText, InputUnboundAutoComplete, LookupInputSelect, SaveToolbar } from '@opidcore/components';
import Grid, { GridContext, GridItem } from '@opidcore/components/Grid';

import { useParams } from "react-router-dom";

export default function WorkingFile(props){
    let [data, setData] = useState([]);
    let gridContext = useContext(GridContext);
    const params = useParams();
    let [customerId, setCustomerId] = useState(0);

    useEffect(()=>{
        if(props.customerId || params.customerId){
            setCustomerId(props.customerId || params.customerId);

        }
    },[]);
    
    useEffect(()=>{
        if(customerId > 0){
        	APP.central.ServiceContract.workingFileData(customerId, "", {}).then((result)=>{
            	//const wtf = { site: {}, services: [{id: -1}, {id: -2}], client_contract_line_items: [{service: -1},{service: -2}] };
            	
                setData(result.result.rows);
            });
        }
    }, [customerId]);
    
    const doSave = ()=>{
        const deltas = gridContext.value.boundMagic.deltas;
        APP.central.ServiceContract.saveBoundDeltas(deltas).then((r)=>{
            gridContext.value.boundMagic.deltas = [];
        });        
    }
    
    const changeCustomer = (customerInfo)=>{
        const customerId = customerInfo.basis.id;
        
        if(customerId != null){
            setData([]);
            if(gridContext.value && gridContext.value.boundMagic && gridContext.value.boundMagic.deltas.length > 0){
                const confirmCallback = (continueResult)=>{
                    if(continueResult){
                        gridContext.value.boundMagic.deltas = [];
                        setCustomerId(customerId);
                    } else {
                        //don't change customers and keep current deltas
                    }                    
                }
                APP.instance.openConfirmDialog("Continue?", "You have " + gridContext.value.boundMagic.deltas.length + " unsaved changes." , confirmCallback);          
            } else {
                setCustomerId(customerId);
            }
        }
    }
    
    const handleCellAction = (e, whatWasClicked, gridCtx)=>{
        e.stopPropagation();       
        const bodyData = {};

        let toCreate = "site";
        let insertAt = gridCtx.sortedData.length;

        if(whatWasClicked.currentBasis != undefined){
            bodyData.currentBasis = whatWasClicked.currentBasis;
        }

        if(whatWasClicked.boundAs){
            toCreate = whatWasClicked.boundAs;
            insertAt = whatWasClicked.rowIndex;
            bodyData.existingRow = whatWasClicked.row;
        }
    
    	const wtf = APP.central.ServiceContract.workingFileData(customerId, toCreate, bodyData).then( (r)=>{
    		gridCtx.setData(r.result.rows[0], insertAt);
    	});
    } 
           
    
    return <div key="working_file" className="working_file">
        <h2>Working Files</h2>

        <InputUnboundAutoComplete name="Customer" central={APP.central.Customer.theRealAutocomplete} onSelect={(val)=>changeCustomer(val)}/>
        <Grid data={data} orderBy={["site.name"]} rowBasis="service.id" appendable={{text: "add a site", add: null}} key="site_grid" onCellAction={handleCellAction}>
            <Grid boundData="site">
                <GridItem title="Site Name">
                    <InputAutoComplete field="id" central={APP.central.Site.autocomplete} preFetchAll={true}>
                        {(site)=><div className="site_option">{site.displayValue}</div>}
                    </InputAutoComplete>
                </GridItem>
                <GridItem title="Address"><InputText field="address"/></GridItem>
                <GridItem title="City"><InputText field="city"/></GridItem>
                <GridItem title="Province"><InputText field="province"/></GridItem>
            </Grid>

            <Grid boundData="service">
                <GridItem title="Service Type">
                    <LookupInputSelect what="equipmenttype" field="equipmentType" name="Container Type" showEmpty={true}/>
                </GridItem>
                <GridItem title="Equipment Size">
                    <LookupInputSelect what="equipmentsize" field="equipmentSize" name="Container Size" showEmpty={true}/>
                </GridItem>
                <GridItem title="Date"><span>To-Do</span></GridItem>
                <GridItem title="Material">
                    {/*<LookupInputSelect what="material" field="material" name="Material"/>*/}
                    <LookupInputSelect what="parent_materials" fetch={APP.central.Material.listParentMaterials} field="material" bound={true} showEmpty={true} />
                </GridItem>
            </Grid>

            <GridItem key="client_line_item_grid">
                <Grid boundData="client_contract_line_items" rowBasis="activity" rowBasisGroup="activity">
                    <GridItem title="Activity">
                        <LookupInputSelect what="lookup:activity" field="activity" showEmpty={true}/>
                    </GridItem>
                    <GridItem title="Additional Invoice Description"><span>To-Do</span></GridItem>
                    <GridItem title="Invoice Type"><span>To-Do</span></GridItem>
                    <GridItem title="Frequency"><span>Exists somewhere</span></GridItem>
                    <GridItem title="Quantity">
                        <InputText field="quantity"/>
                    </GridItem>
                    <GridItem title="Unit"><span>To-Do</span></GridItem>
                    <GridItem title="PO #"><span>To-Do</span></GridItem>
                    <GridItem title="#"><span>To-Do</span></GridItem>
                    <GridItem title="On Site Quantity"><span>To-Do</span></GridItem>
                    <GridItem title="Monthly Yards"><span>To-Do</span></GridItem>
                    <GridItem title="Material Density"><span>To-Do</span></GridItem>
                    <GridItem title="Monthly MT"><span>To-Do</span></GridItem>
                </Grid>
            </GridItem>
                    
            <GridItem title="Billing Team Notes to Sustainability Team">
                <InputText inputType="textarea" field="site.billing_notes"/>
            </GridItem>
        </Grid>
        <SaveToolbar handleSave={doSave} message={""} />
    </div>;
}