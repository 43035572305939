import React  from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

export default function(props){



    const icon = new L.Icon({
     iconUrl: '/assets/images/marker.png',
     iconSize: new L.Point(33, 45),
     className: 'site-icon' 
    });


    let markers = [];

    let mapProps = {};

    if (props.markers){
        markers = props.markers.map( (m,i)=>{
            if (m[0]){
            return <Marker position={[parseFloat(m[0]), parseFloat(m[1])]} icon={icon}>
            <Popup>{m[2]}</Popup>
        </Marker>;
            }  
        });
    }

    if (window.PORTAL_COUNTRY == "USA"){
        mapProps = { center: [39.8283,-98.5795], zoom: 4};
    }else{
        mapProps = { center: [43.1579465,-80.4580664], zoom: 8};
    }

    return <div className="map">
        <MapContainer {...mapProps} scrollWheelZoom={false}>
        <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            //aurl="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png?api_key=51b1b016-c42d-41b5-99c6-cd94eabbd507"
        />
        {markers}
        </MapContainer>
    </div>;
};