import React, { Component } from 'react';
import * as _ from 'lodash';

class Changer {
	constructor(rowToChange, keyValues) {
		this.changed = false;
		this.rowToChange = rowToChange;
		this.keyValues = keyValues;
	}
	
	setChanged(myBool) {
		this.changed = myBool;
	}
	
	changeARow(row) {
		var that = this;
		var res = {};
		//this way you can only pass an object with the key value pairs that you want to change in a row 
		//rather than changing the whole row
		if (_.isEqual(row, that.rowToChange)) {
			var keys = Object.keys(this.keyValues);
			
			for (var i = 0; i < keys.length; i++) {
				row[keys[i]] = this.keyValues[keys[i]];
			}
			
			this.setChanged(true);
			res.changed = true;
			res.row = row;
		}
		
		return res;
	}
}

export default Changer;