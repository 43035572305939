import { Component } from 'react';
export default class CustomButton extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.handleClick = this.handleClick.bind(this);
	}
	
	handleClick(){
    
        if (this.props.linkTo) {
            window.open(this.props.linkTo,'_blank'); 
        }
    	if (this.props.onClick){
    		window.APP.executeFunction( this.props.onClick, this.props );
    	}
    	if (this.props.navToCid) {
    		window.APP.executeNavToCid(this.props.cid, this.props.viewtype);
    	}

    }
	
	render() {
		return <div onClick={this.handleClick} style={{cursor: "pointer", backgroundColor: "black", color: "white", width: "10%", height: "100%"}}>
			<p>{this.props.text}</p>
		</div>
	}
}