import EditEstimate from './EditEstimate.jsx';
import EditProspect from './EditProspect.jsx';


export default function initialize(permissions, options){
	if(permissions.w || true){
		window.APP.customRoutes.push({path: "/ui/prospect/estimate/:id", component: EditEstimate, key: "client_edit"});
		window.APP.customRoutes.push({path: "/ui/prospect/:id", component: EditProspect, key: "client_edit"});
	}
	
	
	/**
	 * APP.addMenu( ()=>{
		const items = [];
		items.push(<MenuItem key="prospects" menuKey="prospects" linkTo="/ui/prospect/753" icon="building" text="Prospects"/>);
		
		return items;
	}); **/
	
}