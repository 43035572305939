import React from "react";
import { Button, Icon, PageHeading, TabularColumn, TabularDataSet } from "@opidcore/components";
import { useData } from "@opidcore/hooks/WTF";
import EditPortalProfile from "./EditPortalProfile";

const ListPortalProfiles = () => {
    const [profiles, profileDataSet] = useData("portal_profile_settings");

    const updateData = () => {
        profileDataSet.fetch();
        APP.instance.clearModals();
    }

    const openProfile = (profile) => {
        APP.instance.createModal(<EditPortalProfile portalProfileSettingsId={profile.id} afterSave={()=>updateData()}/>, { modal_name: "Edit Portal Profile", className: "no-flex" });
    }

    const createNewPortalProfile = () =>{
		APP.instance.createModal(<EditPortalProfile portalProfileSettingsId={0} afterSave={()=>updateData()}/>, { modal_name: "New Portal Profile", className: "no-flex" });
	}

    return <div id="portal-profile-list">
        <PageHeading>Portal Profiles</PageHeading>
        <Button onClick={()=>createNewPortalProfile()}>New</Button>
        <TabularDataSet dataSet={profileDataSet} defaultSort="profileName" defaultSortOrder="asc">
            <TabularColumn title="Profile" data={(r)=>r.profileName} />
            <TabularColumn data={(r)=><Icon icon="edit" size="1x" onClick={()=>openProfile(r)} />}/>
        </TabularDataSet>
    </div>;
}

export default ListPortalProfiles;