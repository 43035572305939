import React, { Component } from 'react';
import MetaTalker from './MetaTalker.jsx';

export default class ColourSet extends MetaTalker {
    constructor(props){
        super(props);
        
        this.state = {val: undefined};
    }
    
    static getEditableProps() {
        return [
            {niceName: "Set Name", prop:"name", type:"text", default: ""},
            {niceName: "Colours", prop: "colours", type:"colourPicker", multiValue: true}
        ]
    }
    
    componentDidUpdate(prevProps, prevState, snapshot){
        if (this.state.val == undefined){
            this.process();
        }
    }
    
    process(){
        if(this.props.name != undefined && this.props.name.trim().length > 0 && this.props.scope.colourSets){
            
            
            let val = this.props.scope.colourSets[this.props.name];
            
            if(val != this.props.colours){
                val = this.props.colours;
            }
    
            if(val != this.state.val){
                this.props.announceScope(this.props.name, val, "colourSets");
                
                this.setState({val: val});
            }
        }
    }
    
    render(){
        if(this.props.mode == "edit"){
            const colours = _.map(this.props.colours, (colour)=>{
                return <span key={colour} style={{backgroundColor: colour, paddingLeft: "10px", marginLeft: "5px"}}>&nbsp;</span>;
            });
            
            return <div className="colourSet">
                <div>
                    <label htmlFor="setName">Colour Set:</label>
                    <span id="setName">{this.props.name}</span>
                </div>
                <div>
                    <label htmlFor="colours">Colours:</label>
                    <span id="colours">{colours}</span>
                </div>
            </div>
        }
        
        return null;
    }
}