import React, { Component } from 'react';


export default class If extends Component{
    constructor(props) {
        super(props);
        
        this.state = {};
        
    }
    
   
  	static getEditableProps(){
        
        return [
            {niceName: "Condition", prop: "condition", type: "text"}
        ]
        
    }
  	
  	static metaInfo(){
  		return {canHaveChildren: true, showInEditorQuickAdd: true}
  	}
  	
    render(){
    	var doit = true;
    	
    	if (this.props.children == null){
    		return <div className="empty-container"></div>;
    	}
    	
    	try{ 
    		const scope = this.props.scope;
    		doit = eval(this.props.condition);
    	}catch(e){

    	}
    	if (doit){
    		return <>{this.props.children}</>;
    	}else{
    		if (this.props.mode == "edit"){
    			return <div className="empty-container"><p className="info-text">hidden if block</p></div>;	
    		}
    		return null;
    	}
    }
}