
import React, { useEffect, useState, useRef }  from 'react';
import ReactDOM from 'react-dom';
import OpidApp from '@opidcore/OpidApp';
import { Provider } from 'react-redux';
// const moduleAlias = require('module-alias') 
// moduleAlias.addAlias('@core', __dirname + '@opidcore')
// import { hot } from 'react-hot-loader/root';
// import { connect } from 'react-redux';
import * as CustomIcons from '../waste/icons/wsc.jsx';
import BarePDF from '../waste/js/components/BarePDF.jsx';

import {
	  BrowserRouter as Router,	 
	  Switch,
	  Route,	 
	} from "react-router-dom";

import history from "@opidcore/history";


import  {SubNavContext, RouteWatcher, OpidSession } from '@opidcore/components/OpidApplication';
import * as CentralActions from '@opidcore/actions/CentralActions';

import configureStore  from '@opidcore/store/configureStore';

import Application from './Application.jsx';
import HomePage from './Welcome/home.jsx';

import ContentView, { RMSWrapper } from './Welcome/ContentView.jsx';
import Login from './Welcome/login.jsx';
import DataEditor from './DataEditor.jsx';

import ViewView from './view.jsx';
import ViewList from './list.jsx';
import ListThing from './ListThing.jsx';


import EditFormView from './EditForm/view.jsx';
import EditDataViewView from './EditDataView/view.jsx';
import EditDefinitionView from './EditDefinition/view.jsx';

import UserView from './Admin/User/view.jsx';
import UserEditView from './Admin/User/edit.jsx';
import UserListView from './Admin/User/list.jsx';
import AdminView from './Admin/index.jsx';

import ThrowAway from './ThrowAway.jsx';
import Forms from './forms.jsx';

import SimpleListView from './components/SimpleListView.jsx';
import EditInModal from './components/EditInModal.jsx';

import Content from '@opidcore/components/Content.jsx';

import {CustomizeContext, Something } from '@opidcore/Customization.jsx';
import { useAppState } from '@opidcore/hooks/WTF';

import PortalDemo, {PortalDemoSites, PortalDemoSite} from '../waste/js/Portal/Throwaway.jsx';

const store = configureStore();
var _ = require('lodash');

import {IconSetup} from '@opidcore/components/Icon.jsx';
IconSetup('fal',"#00bacd");
	    
const isLoggedIn = (nextState, replace, callback) => {

    fetch(APIHOST + "/api/v1/timedtokens/isLoggedIn", { method: "POST", body: JSON.stringify({}), credentials: 'include'})
        .then(response => response.json())
        .then(json => { 
            if (json.result.result.userId == null){ 
                replace('/login');
            }else if (json.result.result.userId != null){
            	//they exist
                //alert( "hello " + json.result.result.userId )
            }
            callback();
         }
        );
    
}

//var APIHOST = "http://192.168.2.15:9001";
//window.APIHOST = "http://127.0.0.1:9000";
//window.APIHOST = "http://localhost:9000";

window.APIHOST = window.location.origin;



export const APP = new OpidApp("WasteMetric");


var fup = CentralActions.modifyProps();
APP.central = fup.central;

APP.setup((that)=>{
	
	//This will register our non "core" views for RMS.
	if (window._THE_GLOBAL_TYPES == null){
		window._THE_GLOBAL_TYPES = {};
	}

	if (window._THE_GLOBAL_TYPES == null){
		window._THE_GLOBAL_TYPES = {};

		if (CustomIcons){
			_.each( _.keys(CustomIcons), (k)=>{
				window._THE_GLOBAL_TYPES[k] = CustomIcons[k]; //wscGHG = wscGHG;
			});
		}
		/** 
		window._THE_GLOBAL_TYPES.wscFootprint = wscFootprint;
		window._THE_GLOBAL_TYPES.wscGarbage = wscGarbage;
		window._THE_GLOBAL_TYPES.wscOrganics = wscOrganics; **/
		
	}
	
	window._THE_GLOBAL_TYPES['ViewView'] = ViewView;
	window._THE_GLOBAL_TYPES['SimpleListView'] = SimpleListView;
	window._THE_GLOBAL_TYPES['EditInModal'] = EditInModal;
	//this.socket.reactParent = this;
	window.APP = that;
	
	//simple config options here
	that.config = {
      
    };
	
});

APP.store = store;
APP.customize = new Something();
APP.handleSocket = (m)=>{
	console.log("handle socket message in index.js");
	APP.setState('xeroRefresh', Math.random());
	APP.setState('handleSocket', m);
};

fup.central.ContentStuff.functions().then(f=>{
	window.APP.functions = f.result;
});

function EmptyApp(props){
	useEffect(()=>{

	}, []);
	
	return props.children;
}

fup.central.Stuff.listForms().then(r=>{
	APP.forms = r.result;

	console.log("Asking for the views");
	fup.central.Stuff.listViews().then(r=>{
	
		APP.views = r.result;
		
	
			
	
		const rootElement = document.getElementById('app');
		
		const NAVBAR = window.APP.subNav ? window.APP.subNav : ()=>{ return <></> };
		
		const AnApp = function(props){
			const [mySession,setSession] = useState();
			const [myRouteCount, setRouteCount] = useState(0);
			const [maximizedModal, setMaximizedModal] = useState(); 
			const contentOverrideRef = useRef();
			const idleTime = 28800000;// 8 hours			
			const idleCheckInterval = 300000; //5 minutes	  	 	
			const idleMessage = "You are being logged out because the maximum idle time has been reached. Please login to continue using this application.";	  
			useAppState("webSession", null, setSession );
			useAppState("menus", null, ()=>{
				setRouteCount(APP.customRoutes.length);  
			});

			const logout = () =>{
				APP.central.TimedTokens.logout().then( ()=>{
					window.location = "/login";	 
				});				
			}
 
			const checkBeingIdle = () =>{				
				APP.central.TimedTokens.isLoggedIn().then((r)=>{
					if(r.result && r.result.result && Object.keys(r.result.result).length > 0){  
						const idleMax = localStorage.getItem("idleMax"); 
						// @ts-ignore
						if(idleMax < Date.now()){									
							APP.alert(idleMessage, ()=>{ 
								logout();						
							});					
						}
					}
				});				
			}
			const updateIdleTime = () =>{
				const idleMax = Date.now() + idleTime;
				// @ts-ignore
				localStorage.setItem("idleMax", idleMax);
			}
			useEffect(()=>{
				const interval = setInterval(()=>{
					checkBeingIdle();
				}, idleCheckInterval);
				// @ts-ignore
				return ()=> clearInterval(interval);
			},[]);

			useEffect(()=>{ 
				updateIdleTime();
				window.addEventListener("click", updateIdleTime);
				window.addEventListener("keypress", updateIdleTime);
				window.addEventListener("scroll", updateIdleTime);
				window.addEventListener("mousemove", updateIdleTime);
				return ()=>{
					window.removeEventListener("click", updateIdleTime);
					window.removeEventListener("keypress", updateIdleTime);
					window.removeEventListener("scroll", updateIdleTime);
					window.removeEventListener("mousemove", updateIdleTime);
				}
			},[]);

			const maximizeModal = (modal)=>{
				console.log(React);
				debugger
				setMaximizedModal(modal);
			}

			console.log('my app rendered');
			const [myNav, setMyNav] = useState({empty: true});
			//const myNavRef = useRef();
			
			/**  const setNav  = (a)=>{
					if (a.title == null || a.title == ""){
						if (myNav.current){
							a.title = myNav.current.title;
						}
					}
					myNav.current = a;
			} **/
			
			
			const [mainClass, setMainClass] = useState('main-ui-content');
		
			window.setMainClass = setMainClass;
			window.mainClass = mainClass;
			
			useEffect(()=>{
				/** if (myNav.current == null){
					setNav( new SubNavState(setNav) );
				} **/
				
				setInterval( ()=>{
					  const mainEl = window.document.getElementById("main-app-content");
						if (mainEl){
							const realPageBlocks = _.filter(document.getElementsByClassName("page-block"), (a)=>{ return a.closest("#modal_container") == null });
							if (realPageBlocks.length > 0 && window.mainClass == "main-ui-content"){
								window.setMainClass("clear-ui-content");					
							}else if (realPageBlocks.length == 0 && window.mainClass != "main-ui-content"){
								window.setMainClass("main-ui-content");					
							}
						}
				  }, 100);
				
			}, []);
			
			var editViews =  _.map(APP.views, (v)=>{
				return <Route key={v.uid} path={"/view/" + v.uid + "/:id"}
				render={(aprops) => <ViewView {...aprops}  viewDefinition={v} />}/>
				}
			);
			
			var listViews =  _.map(APP.views, (v)=>{
				return <Route key={v.uid}  path={"/view/" + v.uid}
				  render={(aprops) => <ViewList {...aprops} viewDefinition={v} />}/>
				}
			);
			

			 var  pdfRoutes = <EmptyApp>
			    		<Route path="/pdf/:cid/:name?/:value?" render={(aprops)=> <RMSWrapper><ContentView {...aprops} pdfMode={true}/></RMSWrapper>} />
						<Route path="/report/pdf/:name" component={BarePDF} />
			 		</EmptyApp>;
			 		
			 	var NotFound = function(props){ return <div> 
			 		<h2>404 Not Found</h2>
			 		<p>There is nothing here to be seen.  Sorry. </p>
			 		</div>
			 	}
			 	
			 	//const webSession = window.APP.getWebSession();
			 
			 	//const fakeLocation = window.location;
			 	
			 	const customRoutes = _.map(APP.customRoutes, (routeDef, index)=><Route key={"custom_" + index} path={routeDef.path} render={(props)=>{
					if (mySession != null && mySession.userId > 0){
						const TheComponent = routeDef.component;
						return <TheComponent {...props} permissions={routeDef.permissions}/>
					}

					return <Login {...props}/>;
				}}/>);
			 	
			 	const routes = <Switch>
				 	<Route key="login" path="/login" component={Login}/>
				 	<Route key="form_edit" path="/form/:id/edit" component={EditFormView}/>
				 	<Route key="form_list" path="/form/list" render={(props)=><ListThing centralPath="Form" editLink="/form/:id/edit"/>}/>
				 	<Route key="definition_list" path="/definition/list" render={(props)=><ListThing centralPath="Definition" editLink="/definition/:id/edit"/>}/>
				 	<Route key="definition_edit" path="/definition/:id/edit" component={EditDefinitionView}/>
				 	<Route key="data_view_list" path="/dataview/list" render={(props)=><ListThing centralPath="View" editLink="/dataview/:id/edit"/>}/>
				 	<Route key="data_view_edit" path="/dataview/:id/edit" component={EditDataViewView}/>
				 	<Route key="admin_user_view" path="/admin/user/view/:id" component={UserView} onEnter={isLoggedIn}/>
				 	<Route key="admin_user_edit" path="/admin/user/edit/:id" component={UserEditView} onEnter={isLoggedIn}/>
				 	<Route key="admin_user_list" path="/admin/user/list" component={UserListView} onEnter={isLoggedIn}/>
				 	<Route key="admin" path="/admin" component={AdminView} onEnter={isLoggedIn}/>	    
				 	<Route key="content_view" path="/content/:cid/:name?/:value?" component={ContentView} onEnter={isLoggedIn} />
				 	<Route key="throwaway" path="/wtf" component={ThrowAway}/>
				 	<Route key="data_editor" path="/data/edit/:cid" component={DataEditor}/>
				 	<Route key="all_content" path="/allContent" component={Content} />
				 	<Route key="forms" path="/forms" component={Forms} />
				 	
					 <Route key="portal-site" path="/portal/site/:id" component={PortalDemoSite} />
					<Route key="portal-sites" path="/portal/sites" component={PortalDemoSites} />
					<Route key="portal-wsa" path="/portal-wsa" component={PortalDemo}/>
					<Route key="portal" path="/portal" component={PortalDemo}/>

				 	{editViews}	
				 	{listViews}
				 	
				 	{customRoutes}
				 	
				 	
				 	<Route key="root_route" exact path="/" component={HomePage}/>
				 	<Route key="not_found" component={NotFound} />
			 	</Switch>;

			return <Provider store={store}>
		
			<OpidSession.Provider value={mySession}>
				<SubNavContext.Provider value={ {current: myNav, setter: setMyNav} }>
						<CustomizeContext.Provider value={APP.customize}>
								<Router history={history}>
								<RouteWatcher/>
					           	 {pdfRoutes}
					           	<Application routes={routes.props.children} onUpdateSession={(s)=>setSession(s)} maximizeModal={(modal)=>maximizeModal(modal)} portalThing={contentOverrideRef}> 
					           		<NAVBAR/>
					           		<div id="main-app-content" className={mainClass}>
									    <div id="crazy-thing" ref={contentOverrideRef}></div>
						           		{maximizedModal == null ? routes : null}
				    				</div>
						    	</Application>
					            </Router>
			          </CustomizeContext.Provider>
		          </SubNavContext.Provider>
	          </OpidSession.Provider>
	        </Provider>
		};
		        
		        
		
		ReactDOM.render(<AnApp/>, rootElement);
		window.unsafe_way_to_talk_to_react = app;
	
	});
});
							
export default class App {
	something(){
		return "a";
	}
}
