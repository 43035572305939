import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { ChangeLogList } from '@opidcore/ChangeLog';
import { Util } from '@opidcore/Util';
import { Bound, HoverElement, Icon, InputToggleSwitch, Loading, SaveToolbar, Tabbed, Tabular, TabularColumn } from '@opidcore/components';
import { CurrentModal, RoutedDefaults } from "@opidcore/components/OpidApplication";
import { useData } from '@opidcore/hooks/WTF';
import _ from 'lodash';
import moment from 'moment';
import { Link, useParams } from "react-router-dom";
import { APP } from '../../../../js';
import JasonBlocks from '../../components/JasonBlocks';
import MultipleEditContract from '../Contract/EditLines';
import { NiceCurrency } from '../Nice';
import Notes from '../Notes';


 const ServiceEdit = ({serviceData, siteId, customerId}) => {        
    const [editing, setEditing] = useState(false);
    return <div key="edit_service" className="edit-service">
      <div style={{display: "flex"}}>
        <h4 className="title">Service </h4>
        <div style={{alignSelf: "center", marginLeft: "auto", display: "flex"}}>
          <Notes model={serviceData}/>
          <Icon onClick={() => APP.instance.createModal(<LineHistory serviceId={serviceData.id}/>)} icon="history"/>
        </div>
      </div>
               
      <Bound to={serviceData} boundId="serviceData">
          <JasonBlocks serviceData={serviceData} customerId={customerId} siteId={siteId} editing={editing}/>  
          {/* <JasonBlocks serviceData={serviceData} customerId={customerId} siteId={siteId} editing={editing} setServiceVendor={setServiceVendor} setServiceSchedule={setServiceSchedule}/>         */}
      </Bound>
    </div>;
}

export function LineHistory(props) {
    const [lines, setLines] = useState(null)

    useEffect(() => {
        APP.central.Service.getServiceHistory(props.serviceId).then((r) => {          
            setLines(r.result)
        })
    }, [])

    const deltaFormatter = (delta)=>{
        return <tr key={"delta-formatter-" + delta.field} className="delta-formatter">
            <td>{delta.field}</td>
            <td>{delta.before == null? "" : delta.before + ""}</td>
            <td>{delta.after == null? "" : delta.after + ""}</td>
        </tr>
    }

    const ChangeLogDelta = ({delta}) =>{
        return <div className="change-log-delta">
            <h4>{delta.changeType}</h4>
            <table>
                <thead>
                    <tr>
                        <th>Field</th>
                        <th>Before</th>
                        <th>After</th>
                    </tr>
                </thead>
                <tbody>
                    { _.map(delta.diffs, (diff)=>deltaFormatter(diff)) }
                </tbody>
            </table>
        </div>
    }

    if (lines == null) {
        return <Loading />
    } else return <div>

        <ChangeLogList title="Service Change Logs" api={APP.central.ActivityLog.listChangeLogsByModel} options={{ clazzType: "Service", id: props.serviceId }} />

        <h4>Activity Log Changes</h4>
        <Tabular data={lines}>
            {/*<TabularColumn title="LI Types" data={(row) => (row.baselineContractLine != null ? row.baselineContractLine.lineItemType : null) + " " + (row.vendorContractLine != null ? row.vendorContractLine.lineItemType : null) + " " + (row.clientContractLine != null ? row.clientContractLine.lineItemType : null)}/>*/}
            {/*<TabularColumn title="Activity Mapping ID" data={(row) => row.mappingId} />*/}
            <TabularColumn title="Changed By" data={(row) => row.person.fullName}/>
            <TabularColumn title="Change Time" data={(row) => row.timestamp}/>
            {/*<TabularColumn title="Baseline Contract ID" data={(row) => row.baselineContractLog.id}/>*/}
            
            {/*<TabularColumn title="Client Contract ID" data={(row) => row.clientContractLog.id}/>*/}
            <TabularColumn title="Client Contract Changes" data={(row) => row.clientContractLog == null || row.clientContractLog.differentials == null || row.clientContractLog.differentials.changeType == null ? "No changes." : <ChangeLogDelta delta={row.clientContractLog.differentials}/>}/>
            {/*<TabularColumn title="Vendor Contract ID" data={(row) => row.vendorContractLog.id}/>*/}
            <TabularColumn title="Vendor Contract Changes" data={(row) => row.vendorContractLog == null || row.vendorContractLog.differentials == null || row.vendorContractLog.differentials.changeType == null ? "No changes." : <ChangeLogDelta delta={row.vendorContractLog.differentials}/>}/>
            <TabularColumn title="Baseline Contract Changes" data={(row) => row.baselineContractLog == null || row.baselineContractLog.differentials == null || row.baselineContractLog.differentials.changeType == null ? "No changes." : <ChangeLogDelta delta={row.baselineContractLog.differentials}/>}/>
        </Tabular>
    </div>
}

function ServiceSetup(props) {
  let params = useParams();
  const extra = useContext(RoutedDefaults);

  let defaults = { ...extra };
  if (props.defaults != undefined) {
    defaults = { ...defaults, ...props.defaults };
  }
  const [serviceId, setServiceId] = useState(props.id != undefined ? props.id : params.id);
  const [serviceData, serviceDataSet] = useData("service;" + serviceId, serviceId, defaults);
  let [contractLines, setContractLines] = useState([]);
  
  const registeredGrids = useRef({});

  const additionalInfo =
    props.additionalInfo != undefined /*&& props.additionalInfo.isServiceChange != undefined*/ ? props.additionalInfo : null;
  const doMakeChanges = additionalInfo ? additionalInfo.doMakeChanges : null;

  const thisModal = useContext(CurrentModal);

  useEffect(() => {
    if (serviceId == 0) {
      APP.central.Service.create(defaults).then((r) => {
        setServiceId(r.result.id);
      });
    }
  }, []);

  useEffect(() => {
    if (serviceData != undefined && serviceData.id > 0 && serviceData.site) {
      APP.central.ServiceContract.fetchGriddedContractLines({ serviceId: serviceData.id, siteId: serviceData.site.id }, "", "").then((result) => {
        setContractLines(result.result.rows);
      });
    }
  }, [serviceDataSet.loading]); 

  if (serviceId == 0 || serviceData == null || serviceData.__type == undefined) { 
    return <Loading />;
  } 
 

  const doSave = () => {    
    
    const deltasToSaveInOrder = [];
    _.forEach(_.keys(APP.registeredBoundMagics), (rbmKey) => {
      let isMine = true;

      //we could make isMine smarter, but it mostly messes up with line items.
      if (rbmKey.startsWith("AP") || rbmKey.startsWith("AR")) {
        isMine = false;
      }

      if (isMine) {
        const rbm = APP.registeredBoundMagics[rbmKey];  

        if (rbm.isDirty()) {
          const deltas = rbm.getAllDeltas();
          deltasToSaveInOrder.push(deltas);         
          
        }
      }
    });
   
     

    return _.reduce(
      deltasToSaveInOrder,
      (previousPromise, deltas) => {
        return previousPromise.then(() => {
          return APP.central.ServiceContract.saveBoundDeltas(serviceData.id, deltas).then((r) => {
            if (props.afterSave != undefined) {
              if (r.result["Service:" + serviceId] != undefined) {
                props.afterSave(r.result["Service:" + serviceId]);
              }
            }
          });
        });
      },
      Promise.resolve()
    );    
  };

  let customerId = 0;
  let siteId = 0;

  if (serviceData.customer != undefined) {
    if (serviceData.customer > 0) {
      customerId = serviceData.customer;
    }
  }

  if (serviceData.site != undefined) {
    if (serviceData.site.id > 0) {
      siteId = serviceData.site.id;
    }
  }

  const doCreateOrUpdateAdditionalThing = () => {
    const deltasToSaveInOrder = [];
    _.forEach(_.keys(APP.registeredBoundMagics), (rbmKey) => {
      let isMine = true;
      if (rbmKey.startsWith("AP") || rbmKey.startsWith("AR")) {
        isMine = false;
      }

      if (
        APP.registeredBoundMagics[rbmKey].to &&
        APP.registeredBoundMagics[rbmKey].to.__type == "Task"
      ) {
        isMine = false;
      }

      if (isMine) {
        const rbm = APP.registeredBoundMagics[rbmKey];
        if (rbm.isDirty()) {
          const deltas = rbm.getAllDeltas();
          deltasToSaveInOrder.push(deltas);
        }
      }
    });
    doMakeChanges(additionalInfo.caseId, deltasToSaveInOrder, additionalInfo.serviceInfoBlocks, additionalInfo.griddedContractLines).then((r) => {
      APP.instance.closeModal(thisModal != undefined && thisModal.id != undefined ? thisModal.id : undefined);
    });
  }; 

  const doTempBinSave = () => {
    doSave().then((r) => {
      APP.central.TempBin.doTempBinServiceThings(serviceData.id, additionalInfo.caseId).then((doTempBinServiceThings) => {
        doMakeChanges(additionalInfo.caseId, serviceData.id).then((r) => {
          APP.instance.closeModal(thisModal != undefined && thisModal.id != undefined ? thisModal.id : undefined);
        });
      });
    });
  }

  if(additionalInfo && additionalInfo.isServiceChange){
    return (
      <div key="service_setup_container">
        <ServiceEdit serviceData={serviceData} customerId={customerId} siteId={siteId} />
        <ContractLines
          lines={contractLines}
          serviceData={serviceData}
          serviceDataSet={serviceDataSet}
          registeredGrids={registeredGrids}
          isServiceChange={true}
        />
        <SaveToolbar
          handleSave={doCreateOrUpdateAdditionalThing}
          saveVerb={additionalInfo.existingServiceChange ? "Update Service Change" : "Create Service Change"}
        />
      </div>
    );
  }

  if(additionalInfo && additionalInfo.isTempBin){
    return (
      <div key="service_setup_container">
        <ServiceEdit serviceData={serviceData} customerId={customerId} siteId={siteId} />
        <ContractLines
          lines={contractLines}
          serviceData={serviceData}
          serviceDataSet={serviceDataSet}
          registeredGrids={registeredGrids}
        />
        <SaveToolbar
          handleSave={doTempBinSave}
          saveVerb={additionalInfo.existingTempBin ? "Update Temp Bin Request" : "Create Temp Bin Request"}
        />
      </div>
    );
  }

  return (
    <div key="service_setup_container">
      {/* <ServiceEdit serviceData={serviceData} customerId={customerId} siteId={siteId} setServiceVendor={setServiceVendor} setServiceSchedule={setServiceSchedule}/> */}
      <ServiceEdit serviceData={serviceData} customerId={customerId} siteId={siteId}/>
      <ContractLines lines={contractLines} serviceData={serviceData} serviceDataSet={serviceDataSet} registeredGrids={registeredGrids} />
      <SaveToolbar handleSave={doSave} />
    </div>
  );
}

export default function EditService(props){
    return <Tabbed title="Edit Service">
        <ServiceSetup {...props} key="service_setup" title="Setup"/>
    </Tabbed>
}

export function EditServicesWithTabs(props) {
  const serviceIdList= _.map(props.data, (d) => d.id);
  const titles= _.map(props.data, (d) => {
    let title = "";
    title += d.friendlyId.split('-').pop();
    title += " : ";
    title += d.shortDescription;
    if(d.historical == 1) {
      title += " : INACTIVE";
    }
    return title;
  });
  const serviceIdSelected = props.serviceId;
  let defaultIndex = null;

  const serviceSetups = _.map(serviceIdList, (id, i) => {
    if(id == serviceIdSelected) {
      defaultIndex = i;
    }
    return <ServiceSetup id={id} key={"service_setup" + id} title={titles[i]} />
  });
  
  return (
    <Tabbed inline={true} title="Edit Service" defaultTab={defaultIndex ? defaultIndex : 0}>
      {serviceSetups}
    </Tabbed>
  );
}

export function ContractLines({ lines = [], serviceDataSet, serviceData, registeredGrids, isServiceChange = false, isViewAdditionalThing = false }) {
  const [editingActivities, setEditingActivities] = useState(false);
  const [showInactive, setShowInactive] = useState(false);
  const dumbRef = useRef({ inactive: false });
  const [data, setData] = useState([]); 


  const startDateIsNotEmpty = (item) => item.clientItem.periodFrom && item.vendorItem.periodFrom;

  const updateData = () => {
    const line = lines[0];

    const activities = _.uniq(
      _.compact(
        _.flatten(
          _.map(line, (v, k) => {
            return _.map(v, "activityMappingId");
          })
        )
      )
    );

    const allLines = activities.map((activity, i) => {
      return {
        row: i,
        activity: activity,
        clientItem: _.filter(line.client_contract_line_items, (item) => {
          return item.activityMappingId == activity && (item.active != showInactive || showInactive);
        })[0],
        vendorItem: _.filter(line.vendor_contract_line_items, (item) => {
          return item.activityMappingId == activity && (item.active != showInactive || showInactive);
        })[0],
        baselineItem: _.filter(line.baseline_contract_line_items, (item) => {
          return item.activityMappingId == activity && (item.active != showInactive || showInactive);
        })[0],
      };
    });    

     //only show the lines that are visible
     setData(_.filter(allLines, (l) => l.clientItem));
  }; 


  useEffect(() => {
    updateData();
  }, [lines, showInactive]);

  if (lines[0] == undefined) {
    return null;
  }

  const serviceId = lines[0] && lines[0].service ? lines[0].service.id : 0;

  const toggleEditingActivities = () => {
    if (editingActivities) {
      serviceDataSet.fetch();

      setEditingActivities(false);
    } else {
      setEditingActivities(true);
    }
  };

  return (
    <div className="service-line_item-editor">
      <h4>
        <span>Activities</span>
        {isViewAdditionalThing ? null : (
          <Fragment>
            <Icon title="Edit" icon="pencil" onClick={() => toggleEditingActivities()} />
            <Link to={"/ui/services/editor/" + [lines[0].service.id].join(",")}>
              <Icon title="Edit" icon="window-restore" />
            </Link>
          </Fragment>
        )}
        <Bound to={dumbRef.current}>
          <InputToggleSwitch className="inline" field="inactive" icon="eye" title="Toggle Active" onChange={(value) => setShowInactive(value)} />
        </Bound>
      </h4>
      {editingActivities ? (
        <MultipleEditContract
          registeredGrids={registeredGrids}
          manageMultiple={false}
          // @ts-ignore
          serviceData={serviceData}
          singleService={serviceId}
          embedded={true}
          showInactive={showInactive}
          isServiceChange={isServiceChange}           
        />
      ) : (
        <Tabular data={data}>
          <TabularColumn title="Client Activity" data={(r) => (r.clientItem ? r.clientItem.activity : "")} />
          <TabularColumn title="Price" className="right" data={(r) => <UnitPrice item={r.clientItem} />} />
          <TabularColumn title="Unit" data={(r) => (r.clientItem ? <UnitPricePer unitPricePer={r.clientItem.unitPricePer} /> : "")} />
          <TabularColumn title="Start" data={(r) => doMomentFormatting(r.clientItem, "periodFrom", "YYYY-MM-DD")} />
          <TabularColumn title="End" data={(r) => doMomentFormatting(r.clientItem, "periodTo", "YYYY-MM-DD")} />
          <TabularColumn title="Billed Up To" data={(r) => doMomentFormatting(r.clientItem, "billedUpTo", "YYYY-MM-DD")} />
          <TabularColumn title="Vendor Activity" data={(r) => (r.vendorItem ? r.vendorItem.vendorActivityDescription : "")} />
          <TabularColumn
            title="Price"
            data={(r) => (
              <span>
                <UnitPrice item={r.vendorItem} />
              </span>
            )}
          />
          <TabularColumn title="Unit" data={(r) => (r.vendorItem ? r.vendorItem.unitPricePer : "")} />
          <TabularColumn title="Start" data={(r) => doMomentFormatting(r.vendorItem, "periodFrom", "YYYY-MM-DD")} />
          <TabularColumn title="End" data={(r) => doMomentFormatting(r.vendorItem, "periodTo", "YYYY-MM-DD")} />
          <TabularColumn title="Billed Up To" data={(r) => doMomentFormatting(r.vendorItem, "billedUpTo", "YYYY-MM-DD")} />
          <TabularColumn title="Baseline Activity" data={(r) => (r.baselineItem ? r.baselineItem.activity : "")} />
          <TabularColumn title="Price" className="right" data={(r) => <UnitPrice item={r.baselineItem} />} />
          <TabularColumn title="Unit" data={(r) => (r.baselineItem ? r.baselineItem.unitPricePer : "")} />
          <TabularColumn title="PI" data={(r) => <PriceIncreaseInfo clientItem={r.clientItem} />} />
        </Tabular>
      )}
    </div>
  );
}
 
const PriceIncreaseInfo = ({clientItem}) => {
    if(clientItem.unitPriceIncrease == null || clientItem.unitPriceIncrease.length == 0){
        return null;
    }

    return <HoverElement key={clientItem.id + "-PI"} content={clientItem.unitPriceIncrease}>
        {clientItem.notes}
    </HoverElement>
}

const doMomentFormatting = (item, field, format) =>{
    if(item != undefined && item[field] != undefined){
        return moment(item[field]).format(format);
    }

    return "";
}

export const UnitPrice = ({item}) =>{ 
    if(item == undefined){
        return null;
    }

    let unitPrice = undefined;

    if(item.unitPricePer != undefined && (item.unitPricePer.toLowerCase() ==  "percent" || item.unitPricePer.toLowerCase() ==  "surcharge")){
        unitPrice = Util.roundNice(100 * item.unitPrice, 2) + "%";
    } else {
        unitPrice = <NiceCurrency>{item.unitPrice}</NiceCurrency>;
    }

    return <span key="unit-price">
        {unitPrice}
        {item.additionalItemBilling == true? <Icon title="Always Billed to Client" icon="check" /> : ""}
        {item.fixedBilling == true? <Icon title="Fixed Billing" icon="check" /> : ""}
    </span>
}

const UnitPricePer = ({unitPricePer}) =>{
    let val = unitPricePer;
    if(unitPricePer != undefined && unitPricePer.toLowerCase() ==  "percent"){
        val = "%";
    }

    return <span key="unit-price-per">{val}</span>;
}
