import React, { Component } from 'react';

import moment from 'moment';

export default class Week extends Component {


	handleClick(){
		this.props.actions.alogout();
	}

	
	renderDay(day, eventsForDay){
		
		let body = <div>?</div>;
		
		if (this.props.renderDay != null)
			body = this.props.renderDay(this, day, eventsForDay);
		
		if (this.props.DayComponent != null){
			body = React.createElement(this.props.DayComponent, {day: day, eventsForDay: eventsForDay, key: day.format('D')});
		}
	
		return (<td key={day.format("D")}><span className="dayOfMonth">{day.format('D')}</span>
		
					{body}
		
		</td>);
	}
	render() {
		const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

		const headers = weekDays.map( (heading) =>  
			<th key={heading}>{heading}</th>		
		);
		
		const weeks = [];
		
		const startDate = moment(this.props.weekStart);
		let currentWeek = [];
		
		for (var x = 0; x <7; x++){
			
			const date = moment(startDate).add({days: x});
			
			var timestamp = date.unix() * 1000;
		
			const eventsForDay = _.filter(this.props.data, function(row){
				return row.date == timestamp || row.date == date.format("YYYY-MM-DD");
			});
			var day = this.renderDay(date, eventsForDay);
			
			currentWeek.push(day);
			if (currentWeek.length == 7){
				weeks.push( <tr key={date}>{Object.assign([], currentWeek)}</tr> );
				currentWeek = [];
			}
			
		}
		
		weeks.push( <tr key={startDate}>{currentWeek}</tr> );

		let weekTitle = "Week of "+moment(startDate).add({day: 1}).format('MMMM Do');
		if(this.props.title != undefined){
			weekTitle = (<span>{this.props.title}</span>);
		}
		
		let subTitle = null;
		if(this.props.subTitle != undefined){
			subTitle = <h4>{this.props.subTitle}</h4>;
		}
		
		return (
			<div className="calendar-outer">
					<h1> {weekTitle} </h1>
					{subTitle}
					<table className="monthly-view">
					<thead>
						<tr>
						{headers}
						</tr>
					</thead>
						<tbody>
						{weeks}
						</tbody>
					</table>
				
			</div>
		
		);
	}
}