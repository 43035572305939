import React, { useRef } from "react";
import { useData, useNavBar } from "@opidcore/hooks/WTF";
import { Icon, TabularDataSet, TabularColumn, Button, Pagination, Searchable, ActionBar, BoundCommit, InputText, UnlockToEdit } from "@opidcore/components";
import * as _ from "lodash";

export default function EquipmentTypeEdit(props) {
  useNavBar("Edit Equipment Type", []);

  const [data, dataSet] = useData("equipmenttype", { paginate: 100 });

  const editEquipmentType = (equipmentType) => {
    APP.instance.createModal(<EditEquipmentType equipmentType={equipmentType} />); 
  };

  const newEquipmentType = () => {
    APP.instance.createModal(<EditEquipmentType equipmentType={{ __new: true, __dataUID: dataSet.dataUID }} />);
  };

  return (
    <div className="full-size">
      <ActionBar title="Actions">
        <Searchable ds={dataSet} />
        <a className="btn btn-new" onClick={() => newEquipmentType()}>
          New Equipment Type
        </a>
      </ActionBar>

      <div>
        <Pagination dataSet={dataSet} />
        <Button onClick={() => dataSet.download("xlsx")}>excel</Button>
      </div>

      {dataSet.loading ? "loading" : ""}

      <TabularDataSet dataSet={dataSet} defaultSort="id" defaultSortOrder="asc">
        <TabularColumn data={(row) => row.id} title="Equipment Type #" />
        <TabularColumn data={(row) => row.name} title="Name" />
        <TabularColumn
          data={(row) => {
            return <Icon icon="edit" onClick={() => editEquipmentType(row)} size="2x" />;
          }}
        />
      </TabularDataSet>
    </div>
  );
}

function EditEquipmentType(props) {
  const equipmentTypeRef = useRef({ ...props.equipmentType });

  return (
    <div>
      <BoundCommit
        initialValue={equipmentTypeRef.current}
        forceSaveProps={["equipmenttype"]}
        commit={APP.central.EquipmentType.saveChanges}
        className="basicForm"
        permissions={APP.getWebSession().permissions("Controlled Data Lists")}
      >
        <UnlockToEdit>
          <InputText field="id" name="#" />
        </UnlockToEdit>
        <InputText field="name" name="Name" />
      </BoundCommit>
    </div>
  );
}
