import React  from 'react';
//import { Data } from '@opidcore/Data.js';
import { Link } from 'react-router-dom';


export default function Forms(props){
	
	
	const links = [];
	
 	if (APP.views && APP.forms){
			    
			    	_.each(APP.views, (v)=>{
			    	    var view = v.view;
			    		var form = _.find(APP.forms, {uid: v.formUid}) || {};
						var track = null;
		    	    		links.push(
		    	    			<Link key={v.uid} className="btn btn-default btn-lg option" to={"/view/" + v.uid}>{ v.title }</Link>
		    
		    	    		);
			    	});
			    	
	}
			    
    return <div>
    	<h2>Forms to edit</h2>

		<div className="row">    	
   			{links}
		</div>   
    </div>;
}
 
 