import React, { useEffect, useState } from "react";
import { AccessControl, Bound, DatePicker, Icon } from "@opidcore/components";
import { Tabular, TabularColumn } from "@opidcore/components/Tabular";
import { useData } from "@opidcore/hooks/WTF";

import CesarFilter from "../../components/CesarFilter";
import { TotalContractsCount } from "../Contract/ContractExpiryManagement";
import { CreateNewContract } from "../Customer/ClientContractList";
import _ from "lodash";
import FullTextSearchable from "../../components/FullTextSearchable";
import moment from "moment";

// This is the dashboard which will display contract expiry information for the user
// Team managers will be able to see all users
export default function AccountManagementDashboard(props) {
  //const [data, dataset] = useData("contracts;", { method: "contractExpiryManagementList" });

  const [clientContracts, setClientContracts] = useState([]);
  const [fetchClientContracts, setFetchClientContracts] = useState(false);

  const [dateRangeState, setDateRangeState] = useState({ fromDate: moment().subtract(30, "days").format("YYYY-MM-DD"), toDate: moment().format("YYYY-MM-DD") });

  const me = APP.getWebSession();

  const dateUpdated = () => {
    setFetchClientContracts(true);
  };

  // Opens modal to create new client contract
  const newClientContract = () => {
    APP.instance.createModal(
      <CreateNewContract />,
      {
        modal_name: "New Client Contract",
      },
      {
        afterClose: () => setFetchClientContracts(true),
      }
    );
  };

  const handleClick = (row, e) => {
    let linkPath = "/ui/vendorcontract/edit/";
    if (row.type == "client_contract") {
      linkPath = "/ui/clientcontract/edit/";
    }

    if (e.defaultPrevented == false) {
      // @ts-ignore
      APP.instance.createModal(
        linkPath + row.friendly_id,
        {
          modal_name: "Contract Preview",
        },
        {
          afterClose: () => setFetchClientContracts(true),
        }
      );
    }
  };

  const getColumns = () => {
    const columns = [
      { column: "type", heading: "Contract Type" },
      { column: "umbrella", heading: "Umbrella" },
      { column: "customer_name", heading: "Client Name" },
      { column: "vendor_name", heading: "Vendor Name" },
      { column: "expiry_status", heading: "Expiry Status" },
    ];

    APP.getWebSession().access("All AM Contracts") && columns.push({ column: "regional_account_manager", heading: "Account Manager" });

    return columns;
  };

  return (
    <div id="userDashboard" className="text-center container">
      <h1>Account Management Dashboard</h1>
      <h3>{me.fullname}</h3>

      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: "1" }}>
          <FullTextSearchable
            model={"Contracts"}
            method={"reasonableAccountManagemntDashboardList"}
            setData={setClientContracts}
            doFetchData={fetchClientContracts}
            setDoFetchData={setFetchClientContracts}
            maxRows={-1}
            allowBlank={true}
            additionalParams={[dateRangeState.fromDate, dateRangeState.toDate]}
            filterOptions={{
              columns: getColumns(),
            }}
          />

          <Bound to={dateRangeState} onChange={() => dateUpdated()}>
            <div style={{ display: "flex" }}>
              <DatePicker name="Start" field="fromDate" />
              <DatePicker name="End" field="toDate" />
            </div>
          </Bound>
        </div>

        <div>
          <TotalContractsCount data={clientContracts} />
        </div>
      </div>

      <Tabular data={clientContracts} defaultSort="effective_to" defaultSortOrder="asc" onClick={handleClick}>
        <TabularColumn title="Contract ID" data={(row) => row.friendly_id} />
        <TabularColumn title="Client" data={(row) => <span title={row.customer_friendly_id}>{row.customer_name}</span>} />
        <TabularColumn title="Expiry Status" data={(row) => <StyledExpiryStatus row={row} />} />
        <TabularColumn title="Contract Expiry Date" data={(row) => row.effective_to} />
      </Tabular>
    </div>
  );
}

const StyledExpiryStatus = ({ row }) => {
  const status = row.expiry_status;
  let statusString = row.expiry_status;

  switch (status) {
    case "expired":
      statusString = "Expired";
      break;
    case "expiringSoon":
      statusString = "Expiring Soon";
      break;
    case "current":
      statusString = "Current";
      break;
    case "renewed":
      statusString = "Renewed";
      break;
    case "lost":
      statusString = "Lost";
      break;
    case "monthToMonth":
      statusString = "Month to Month";
      break;
    case "autoRenewal":
      statusString = "Auto Renewal";
      break;
    default:
      break;
  }

  let warningString = "";
  if (moment(row.effective_from).isAfter(moment(row.effective_to))) {
    warningString += "Contract start date is after end date.";
  }

  const hasWarning = warningString.length > 0;
  const style = { color: "red" };

  return (
    <div className={"expiryStatus" + status} style={hasWarning ? style : null} title={warningString}>
      {statusString}
      {hasWarning ? (
        <span style={{ margin: "5px" }} title={warningString}>
          <Icon icon="info" color={"red"} size={"1x"} />
        </span>
      ) : null}
    </div>
  );
};
