import React, { useRef } from "react";
import { useData, useNavBar } from "@opidcore/hooks/WTF";
import {
  LookupInputSelect,
  TabularDataSet,
  TabularColumn,
  Pagination,
  Searchable,
  ActionBar,
  BoundCommit,
  InputText,
  UnlockToEdit,
  Icon,
  InputToggleSwitch,
  InputDecimal,
} from "@opidcore/components";
import * as _ from "lodash";
//import Util from "@opidcore/Util";

export default function ActivitiesListing(props) {
  useNavBar("Activities", []);

  const [data, dataSet] = useData("activity_definition");
  //const [data, dataSet] = useData("activity");


  const editActivity = (activity) => {
    APP.instance.createModal(<EditActivity activity={activity} />);
  };

  const newActivity = () => {
    APP.instance.createModal(<EditActivity activity={{ __new: true, __type: "ActivityDefinition", __dataUID: dataSet.dataUID }} />);
  };

  return (
    <div className="full-size">
      <ActionBar title="Actions">
        <Searchable ds={dataSet} />
        <a className="btn btn-new" onClick={() => newActivity()}>
          New Activity
        </a>
      </ActionBar>

      <div>
        <Pagination dataSet={dataSet} />
      </div>

      <TabularDataSet dataSet={dataSet} defaultSort="id" defaultSortOrder="asc" noData={"No activities found."}>
        <TabularColumn data={(row) => row.id} title="#" sortCol="id"/>
        <TabularColumn data={(row) => row.activity} title="Activity" sortCol="activity"/>
        <TabularColumn data={(row) => row.showFrequency ? "Yes" : "No"} title="Show Frequency" sortCol="showFrequency"/>
        <TabularColumn data={(row) => row.status} title="Status" sortCol="status"/>
        <TabularColumn data={(row) => row.enviroFactor} title="Enviro"/>
        <TabularColumn data={(row) => row.activityType} title="Type" sortCol="activityType"/>
        <TabularColumn
          data={(row) => {
            return <Icon icon="edit" onClick={() => editActivity(row)} size="2x" />;
          }}
        />
      </TabularDataSet>
    </div>
  );
}

export function EditActivity(props) {
  const activityRef = useRef({ ...props.activity });
  return (
    <div>
      <BoundCommit
        initialValue={activityRef.current}
        forceSaveProps={["activityDefinition"]}
        commit={APP.central.ActivityDefinitions.saveChanges}
        className="basicForm"
        permissions={APP.getWebSession().permissions("Data Governance Restricted List")}
      >
        <UnlockToEdit>
          <InputText field="id" name="#" />
        </UnlockToEdit>
        <InputText field="activity" name="Activity" />
        <InputText field="invoiceDescription" name="Invoice Description" />
        <InputText field="postToGL" name="Post GL"/>
        <InputText field="transferGL" name="Transfer GL"/>
        <InputText field="apRevenueGL" name="AP Revenue GL"/>
        <InputText field="apExpenseGL" name="AP Expense GL"/>

        <InputToggleSwitch
          field="showFrequency"
          name={(showFrequency) => {
            return showFrequency == undefined || showFrequency == false ? "Do Not Show Frequency" : "Show Frequency";
          }}
        />
				<LookupInputSelect what="lookup:activityStatus" field="status" forcedDefault={true} showEmpty={false} name="Status" />
				<LookupInputSelect what="lookup:activityType" field="activityType" forcedDefault={true} showEmpty={false} name="Type" />
        <InputDecimal field="enviroFactor" name="Enviro Factor"/>
        <InputDecimal field="productionFactor" name="Production Factor"/>
      </BoundCommit>
    </div>
  );
}
