//import './AddFriendInput.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BoundComponent from './BoundComponent';
import * as _ from 'lodash';

export default class InputFile extends BoundComponent {

  static propTypes = {
    update: PropTypes.func,
    property: PropTypes.string,

  };

  constructor(props, context) {
	  super(props, context);
	  let newState = {value: ""};
	  
	  if(this.props.field == undefined ){
		  newState.value = "";
	  } else {
		  if (this.props.obj instanceof Component && this.props.obj.state != null){
			  newState.value = this.props.obj.state[this.props.field];
		  }else{
			  newState.value = this.props.obj[this.props.field];
		  }
	  }
	  
	  this.state = {...newState};
	  
	  this.handleChange = this.handleChange.bind(this);
	  if (props.update){
		  this.debouncedUpdate = _.debounce(props.update, 500);
	  }
  }
  
  componentDidMount(){
	  if(this.props != undefined && this.props.value != undefined){
	    	this.setState({value: this.props.value});
	    	this.props.update(this.props.obj, this.props.field, this.props.value);
	    }
  }
  
  componentWillReceiveProps(nextProps){
	  var nextState = {...this.state};	  
	  if(this.props.obj != undefined){
		  if(this.state.file == undefined || nextProps.obj.file != this.state.file){
			  nextState.file = this.props.obj.file;
		  }
		  
		  if(this.state.value == undefined || this.state.value != nextProps.obj.fileName){
			  nextState.value = this.props.obj.fileName;
		  }
	  }
	  
	  this.setState(nextState);
}


  handleChange(e) {
	  if(this.props.field != undefined ){
		  let reader = new FileReader();
		  let filesToUpload = e.target.files;
		  
		  if(filesToUpload.length > 0){
			  reader.readAsDataURL(filesToUpload[0]);
		  }
		  reader.onload = () => {
				  this.setState({ value: filesToUpload[0].name, file: reader.result }, ()=> {
					  let updateHash = {};

					  if (this.props.isCoreFile){
						  updateHash.filename = filesToUpload[0].name;
						  updateHash.type = reader.result.split(';')[0].split(':')[1];
						  updateHash.contents = reader.result.split(',')[1];
					  }else{
						  let updateHash = {};
						  updateHash["fileName"] = filesToUpload[0].name;
						  updateHash[this.props.field] = reader.result;
					  }
					  
					  this.debouncedUpdate(this.props.obj, this.props.field, updateHash);
				  });
          };
		  
		  
	  }
  }

  render() {
    var name = this.props.name;
    
    let download = "";

    if(this.state.value){
    	download = <a download={this.state.value || ""} href={this.state.file}>{this.state.value || "Download"}</a>;
    }
    return (
      <div className={"field " +  (this.props.className || "col-xs-12 col-md-12") } style={this.props.style}>
     
      	<label>{name}</label>
      	{download}
      	
      	<input
      		className={"form-control"}
      		type="file"
      		onChange={(e)=>this.handleChange(e)} 
      		disabled={this.props.disabled || this.props.disabledText? "disabled" : ""}/>
      </div>
    );
  }
}
