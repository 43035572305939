import React, { useState } from "react";
import { BoundCommit, Button, Icon, InputText, LookupInputSelect, Tabular, TabularColumn } from "@opidcore/components";
import ActionBar from "@opidcore/components/ActionBar";
import { useData, useNavBar } from "@opidcore/hooks/WTF";

import ExcelExportModal from "../../components/ExcelExportModal";
import FullTextSearchable from "../../components/FullTextSearchable.jsx";

export default function VendorContactList(props) {
  useNavBar("Vendor Contacts", []);
  const [vendorContacts, setVendorContacts] = useState([]);
  const [fetchVendorContacts, setFetchVendorContacts] = useState(false);

  const excelExport = (e) => {
    e.stopPropagation();
    APP.central.VendorContact.fetchContactsWithExistingVendors().then((fetchContactsWithExistingVendors) => {
      APP.central.VendorContact.getDefaultExportLayout().then((getDefaultExportLayout) => {
        APP.instance.createModal(<ExcelExportModal defaultLayout={getDefaultExportLayout.result} dataSet={fetchContactsWithExistingVendors.result} model={"VendorContact"} />, { modal_name: "Excel Export" });
      });
    });
  };

  const handleClick = (row, e) => {
    const vendorId = row.vendor_friendly_id ? row.vendor_friendly_id : row.vendor_id;
    APP.redirect("/ui/vendors/edit/" + vendorId);
  };

  const refreshDS = () => {
    setFetchVendorContacts(true);
  };

  const editContact = (e, contact) => {
    e.stopPropagation();
    APP.instance.createModal(<EditContact contactId={contact.id} />, {
      onClose: () => refreshDS(),
    });
  };

  const newContact = (e) => {
    e.stopPropagation();
    APP.instance.createModal(<EditContact contact={{ __new: true }} />);
  };

  return (
    <div className="full-size">
      <ActionBar title="Actions">
        <FullTextSearchable
          model={"VendorContact"}
          method={"reasonableList"}
          setData={setVendorContacts}
          doFetchData={fetchVendorContacts}
          setDoFetchData={setFetchVendorContacts}
          maxRows={300}
          filterOptions={{
            columns: [{ column: "vendor_name", heading: "Vendor" }],
          }}
        />
        <Icon icon="file-excel" size="2x" title="Open export modal" onClick={(e) => excelExport(e)} />
        {/* <Button onClick={(e) => newContact(e)}><Icon color="white" icon="plus" /> {"\u00a0"}New Vendor Contact</Button> */}
      </ActionBar>

      <Tabular data={vendorContacts} onClick={handleClick} pageSize={50} paginationLocation={"both"}>
        <TabularColumn
          data={(row) => {
            const vendorId = row.vendor_friendly_id ? row.vendor_friendly_id : row.vendor_id;
            return vendorId;
          }}
          title="Vendor ID"
        />
        <TabularColumn data={(row) => row.vendor_name} title="Vendor" />
        <TabularColumn data={(row) => row.first_name + " " + row.last_name} title="Name" />
        <TabularColumn data={(row) => <a href={"mailto:" + row.email}>{row.email}</a>} title="Email" />
        <TabularColumn
          data={(row) => {
            let ret = "";
            if (row.phone) {
              ret += row.phone;
            }
            if (row.phone && row.cell_phone) {
              ret += ", " + row.cell_phone + " (mobile)";
            } else if (row.cell_phone) {
              ret += row.cell_phone;
            }
            return ret;
          }}
          title="Phone | Cell"
        />
        <TabularColumn data={(row) => row.city} title="City" />
        <TabularColumn data={(row) => row.province} title="Province" />
        <TabularColumn
          data={(row) => {
            return <Icon icon="edit" onClick={(e) => editContact(e, row)} size="2x" />;
          }}
        />
      </Tabular>
    </div>
  );
}

function EditContact(props) {
  const [vendorContact, vendorContactDataset] = useData("vendorcontact", props.contactId);

  return (
    <div>
      <BoundCommit initialValue={vendorContact} forceSaveProps={["vendorContact"]} commit={APP.central.VendorContact.saveChanges} boundId={"editcontact"}>
        <LookupInputSelect what="vendor" field="vendor" name="Vendor" showEmpty={true} />
        <InputText field="firstName" name="First Name" />
        <InputText field="lastName" name="Last Name" />
        <InputText field="title" name="Title" />
        <InputText field="email" name="Email" />
        <InputText field="phone" name="Phone Number" />
        <InputText field="cellPhone" name="Mobile Number" />
        <InputText field="address" name="Address Line 1" />
        <InputText field="addressLine2" name="Address Line 2" />
        <InputText field="city" name="City" />
        <InputText field="province" name="Province" />
        <InputText field="postalCode" name="Postal Code" />
      </BoundCommit>
    </div>
  );
}
