import React, { useState, useEffect, useContext, useRef, Fragment } from "react";
import { Icon } from "@opidcore/components";
import _ from "lodash";
import moment from "moment";
import { DataContext } from "@opidcore/components/Bound";

export default function PeriodSelect(props) {
  const bound = useContext(DataContext);

  let currentPeriod = moment().set("year", moment().get("year")).set("month", moment().get("month")).format("YYYY-MM");

  if (props.usePreviousMonth){
    currentPeriod = moment().set("year", moment().get("year")).set("month", moment().get("month")).add(-1, "month").format("YYYY-MM");
  }
  
  if (bound.to[props.field]) {
    currentPeriod = bound.to[props.field];
  }

  const [year, setYear] = useState(moment(currentPeriod).get("year"));
  const [month, setMonth] = useState(moment(currentPeriod).get("month"));
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [typedValue, _setTypedValue] = useState(currentPeriod);
  const setTypedValue = (v) => {
    if(moment(v, "YYYY-MM", true).isValid() === true){
      bound.magicalSet(props.field, v);
    }
    
    _setTypedValue(v);
  };

  /* bound.on(() => {
    const existing = bound.magicalGet(props.field);
  }); */

  /* useEffect(() => {
    window.testing = currentPeriod;
  }, []); */

  useEffect(() => {
    bound.magicalSet(props.field, moment().set("year", year).set("month", month).format("YYYY-MM"));
    _setTypedValue(bound.magicalGet(props.field));
    setDropDownOpen(false);
  }, [month]);

  const mouseLeaveDropDown = () => {
    setDropDownOpen(false);
  };

  const decrementPeriod = () => {
    if (month == 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  const incrementPeriod = () => {
    if (month == 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  //--------------------------------------------------------- PeriodSelectDropdown ---------------------------------------------------------

  const PeriodSelectDropdown = (props) => {
    const [highlightYear, setHighlightYear] = useState(props.year);
    const [highlightMonth, setHighlightMonth] = useState(props.month);

    const setDropdownYear = (i) => {
      props.setYear(i);
      setHighlightYear(i);
    };

    const setDropdownMonth = (i) => {
      props.setMonth(i);
      setHighlightMonth(i);
    };

    return (
      <Fragment>
        <div className="drop-down" onMouseLeave={() => mouseLeaveDropDown()}>
          <div className="years">
            {_.map(_.range(highlightYear - 1, highlightYear + 2), (i) => {
              return (
                <div key={i} onClick={() => setDropdownYear(i)} className={highlightYear == i ? "current-value" : ""}>
                  {i}
                </div>
              );
            })}
          </div>
          <div className="months">
            {_.map(_.range(0, 12), (i) => {
              return (
                <div key={i} onClick={() => setDropdownMonth(i)} className={highlightMonth == i ? "current-value" : ""}>
                  {moment().set("month", i).format("MMM")}
                </div>
              );
            })}
          </div>
        </div>
      </Fragment>
    );
  };

  //--------------------------------------------------------- RETURN ---------------------------------------------------------

  return (
    <div className="field">
      <label>{props.name}</label>
      <div className="period-select">
        <input type="text" className="searchText" value={typedValue} onChange={(e) => setTypedValue(e.target.value)} />
        <div className="calendarIcon">
          <Icon icon="calendar" size="2x" onClick={() => setDropDownOpen(true)} />
        </div>
        <div className="arrowIcons">
          <Icon icon="chevron-circle-left" size="1x" onClick={decrementPeriod} />
          <Icon icon="chevron-circle-right" size="1x" onClick={incrementPeriod} />
        </div>
        {dropDownOpen ? <PeriodSelectDropdown year={year} month={month} setMonth={setMonth} setYear={setYear} /> : null}
      </div>
    </div>
  );
}
