import React from "react";
import { Bound, Button,  useMagic } from "@opidcore/components";
import moment from "moment";
import { SimpleMonthPicker } from "../../components";

export default function Boardwalker() {
  const bound = useMagic({ period: moment().format("YYYY-MM") });

  /*
  useEffect(() => {
    APP.getState("handleSocket", (thing) => {
      console.log("HEY CESAR THIS NEW THING", thing);
      if (thing.object) {
        if (thing.object.__type == "DunnoYet") {
          console.log("SOMETHING?");
          //APP.central.Util.clearCache();
          //updateTasks();
        }
      }
    });
  }, []);
  */

  const doThing = () => {
    APP.central.Stuff.doBoardwalkStuff(bound.to.period).then((r) => {      
    });
  };

  const doExcel = () => {
    APP.central.Reports.exportBoardwalk(bound.to.period, false).then((r) => {
      APP.socket.send(JSON.stringify(r));
    });
  };

  const doExcelInvoice = () => {
    APP.central.Reports.exportBoardwalk(bound.to.period, true).then((r) => {
      APP.socket.send(JSON.stringify(r));
    });
  };

  return (
    <div>
      <h1>Boardwalk Utility</h1>
      <Bound to={bound}>
        <h3>Billing Period</h3>
        <div className="somethingsomething" style={{maxWidth: "300px"}}>
          <SimpleMonthPicker field="period" />
        </div>
        <Button onClick={doThing}>Calculate Fees</Button>
        <Button onClick={doExcel}>Export Excel</Button>
        <Button onClick={doExcelInvoice}>Post to Xero</Button>
      </Bound>
    </div>
  );
}
