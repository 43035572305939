import React, { useRef, useState } from "react";
//import { Data } from "@opidcore/Data.js";
import { Link } from "react-router-dom";
import { useNavBar } from "@opidcore/hooks/WTF";
import { AccessControl, Bound, Button, InputToggleSwitch, UnlockToEdit } from "@opidcore/components";

export default function Admin(props) {
  const links = [
    <Link to={"/ui/settings"}>My Preferences</Link>,
    <Link to={"/ui/admin"}>Admin</Link>,
    <AccessControl group="Sysadmin">
      <Link to={"/forms"}>Configuration</Link>
    </AccessControl>,
  ];

  useNavBar("Admin", links, { selectedItem: 1 });

  const openServiceSetupExportModal = () => {
    APP.instance.createModal(<ServiceSetupExportModal />, { modal_name: "Service Setup Export" });
  };

  return (
    <div>
      <h2>Admin Options</h2>

      <ul>
        <AccessControl group="Sysadmin">
          <li>
            <Link to="/ui/admin/acl">Access Control</Link>
          </li>
        </AccessControl>
        <AccessControl resource="Controlled Data Lists">
          <li>
            <Link to={"/ui/admin/activities"}>Activities Listing</Link>
          </li>
        </AccessControl>
        <AccessControl resource="Accounting">
          <li>
            <Link to="/ui/admin/boardwalker">Boardwalk Utility</Link>
          </li>
        </AccessControl>
        <AccessControl resource="Controlled Data Lists">
          <li>
            <Link to={"/ui/admin/caseTypeEdit"}>Edit Case Types</Link>
          </li>
        </AccessControl>
        <AccessControl resource="Accounting Admin">
          <li>
            <Link to="/ui/admin/config">Edit Configs</Link>
          </li>
        </AccessControl>
        <AccessControl group="Staff">
          <li>
            <Link to="/ui/admin/equipmentSizeEdit">Edit Equipment Size</Link>
          </li>
        </AccessControl>
        <AccessControl group="Staff">
          <li>
            <Link to="/ui/admin/equipmentTypeEdit">Edit Equipment Type</Link>
          </li>
        </AccessControl>
        <AccessControl resource="Accounting">
          <li>
            <Link to={"/ui/admin/globalServiceEdit"}>Edit Global Services</Link>
          </li>
        </AccessControl>
        <AccessControl resource="Accounting Admin">
          <li>
            <Link to="/ui/admin/jointVenture">Edit Joint Ventures</Link>
          </li>
        </AccessControl>
        <AccessControl resource="Accounting Admin">
          <li>
            <Link to="/ui/admin/lookups">Edit Lookups</Link>
          </li>
        </AccessControl>
        <AccessControl group="Staff">
          <li>
            <Link to="/ui/admin/materialDestinationEdit">Edit Material Destinations</Link>
          </li>
        </AccessControl>
        <AccessControl group="Staff">
          <li>
            <Link to="/ui/admin/materialEdit">Edit Materials</Link>
          </li>
        </AccessControl>
        <AccessControl group="Sysadmin">
          <li>
            <Link to="/ui/portalprofiles">Edit Portal Profiles</Link>
          </li>
        </AccessControl>
        <AccessControl resource="Accounting Admin">
          <li>
            <Link to="/ui/admin/serviceScheduleEdit">Edit Service Frequency</Link>
          </li>
        </AccessControl>
        <AccessControl resource="Accounting Admin">
          <li>
            <Link to={"/ui/admin/staffEdit"}>Edit Staff</Link>
          </li>
        </AccessControl>
        <AccessControl resource="Accounting Admin">
          <li>
            <Link to={"/ui/tax/list"}>Edit Taxes</Link>
          </li>
        </AccessControl>
        <AccessControl resource="Accounting Admin">
          <li>
            <Link to="/ui/admin/workflow/list">Edit Workflows</Link>
          </li>
        </AccessControl>
        <AccessControl group="Sysadmin">
          <li>
            <Link to="/form/list">Edit Forms</Link>
          </li>
        </AccessControl>
        <AccessControl resource="Email Queue">
          <li>
            <Link to="/ui/admin/emailqueue">Email Queue</Link>
          </li>
        </AccessControl>
        <AccessControl resource="Accounting">
          <li>
            <Link to="/ui/admin/exportinvoice">Generate Excel AR Invoices</Link>
          </li>
        </AccessControl>
        <AccessControl resource="Accounting">
          <li>
            <Link to={"/ui/admin/obm"}>OBM Periods</Link>
          </li>
        </AccessControl>
        <AccessControl resource="Controlled Data Lists">
          <li>
            <Link to={"/ui/admin/umbrellamanagement"}>Umbrella Management</Link>
          </li>
        </AccessControl>
        <AccessControl resource="Accounting">
          <li>
            <Link to={"/ui/admin/vendorActivities"}>Vendor Activities Listing</Link>
          </li>
        </AccessControl>
        <AccessControl resource="Accounting">
          <li>
            <Link to={"/ui/admin/status"}>View Queues</Link>
          </li>
        </AccessControl>
        <AccessControl group="Sysadmin">
          <li>
            <Link to="/ui/admin/xero">Xero Settings</Link>
          </li>
        </AccessControl>
        <AccessControl group="Sysadmin">
          <li>
            <Link to="/ui/admin/xerocurrencies">Xero Currencies</Link>
          </li>
        </AccessControl>
      </ul>

      <h2>Reports</h2>
      <ul>
        <AccessControl resource="Accounting Admin">
          <li>
            {/*
            <a href="/api/v1/reports/exportServices" target="_new">
              Service Setup Export
            </a>
            */}
            <Link to="#" onClick={openServiceSetupExportModal}>
              Service Setup Export
            </Link>
          </li>
        </AccessControl>
        <AccessControl resource="Accounting Admin">
          <li>
            <Link to="/ui/reports/arinvoice/raw">AR Invoices Raw</Link>
          </li>
        </AccessControl>
        <AccessControl resource="Accounting Admin">
          <li>
            <Link to="/ui/reports/apinvoice/raw">AP Invoices Raw</Link>
          </li>
        </AccessControl>
        <AccessControl resource="Accounting Admin">
          <li>
            <Link to="/ui/reports/pricingtool">Pricing Tool</Link>
          </li>
        </AccessControl>
        <AccessControl resource="Accounting Admin">
          <li>
            <a href="/api/v1/reports/exportVendors" target="_new">
              Vendor Export
            </a>
          </li>
        </AccessControl>
      </ul>

      <h2>Buttons For Testing Things</h2>
      <ul>
        <Button
          onClick={() => {
            APP.central.Util.testRetry(300).then((r) => {
              console.log(r);
            });
          }}
        >
          Test Retry
        </Button>
      </ul>
      <h2>Buttons For Testing Email</h2>
      <ul>
        <Button
          onClick={() => {
            APP.central.Util.testEmail().then((r) => {
              console.log(r);
            });
          }}
        >
          Test Email
        </Button>
      </ul>
    </div>
  );
}

const ServiceSetupExportModal = () => {
  const histShow = useRef({ histShowStatus: false });
  const [buttonEnabled, setButtonEnabled] = useState(true);

  const doExport = () => {
    setButtonEnabled(false);
    window.location.href = "/api/v1/reports/exportServices?showHistorical=" + histShow.current.histShowStatus;
  };

  return (
    <div>
      <h2>Service Setup Export</h2>
      <UnlockToEdit>
        <div>
          <Bound to={histShow.current}>
            Export Historical:
            <InputToggleSwitch
              field="histShowStatus"
              size="2x"
              className="inline"
              title={histShow.current.histShowStatus == undefined || histShow.current.histShowStatus == false ? "Historical Exported" : "Historical Not Exported"}
            />
          </Bound>
        </div>
        {buttonEnabled ? <Button onClick={() => doExport()}>Export Services</Button> : <Button disabled>Exporting...</Button>}
      </UnlockToEdit>
    </div>
  );
};
