import React, { useContext, useState, useEffect} from 'react';
import { Icon } from '.';
import { DataContext } from './Bound';

const trueish = (v)=>{
	if (v == "true" || v == true || v == 1)
		return true
		
   return false;		   
}

export default function InputToggleSwitch(props) {
	const bound = useContext(DataContext);
	const [value, setValue] = useState( trueish(bound.magicalGet(props.field, false)) );
	const storeAs = props.storeAs == 'int' ? 'int' : 'boolean';

	useEffect(()=>{
		const boundValue = trueish(bound.magicalGet(props.field, false));
		if(boundValue != value){
			setValue(boundValue);
		}
	});

	useEffect(()=>{
		if(props.onChange){
			props.onChange(value, props.field, bound);
		}
	}, [value]);

	let label = null;
    if((props.hideLabel == undefined || props.hideLabel == false) && props.name != undefined){
		if(props.name instanceof Function){
			label = <label>
				{props.name(value)} 
				{props.glyphicon? (<span className={props.glyphicon} />) : ""}
			</label>;
 		} else {
			label = <label>
				{props.name} 
				{props.glyphicon? (<span className={props.glyphicon} />) : ""}
			</label>;
		}
    }

	const toggleCheckbox = ()=>{
		let newValue = !value;	
		
		let writeValue = null;
		writeValue = newValue;

		if (storeAs == 'int'){
			writeValue = writeValue == true ? 1 : 0;
		}
		bound.magicalSet(props.field, writeValue);
		setValue(newValue);
	}

	let displayValue = props.opposite ? ! value : value;

	let thing = null;
	if (props.icon){
		thing = <Icon icon={props.icon} size={props.size ? props.size : "1x"} color={displayValue ? "" : "#999"} onClick={toggleCheckbox} />;
	}else{
		thing = <>
			<input type="checkbox" onChange={toggleCheckbox} checked={displayValue} disabled={props.disabled || false}/>
			<span className={"slider round " + (displayValue ? "slider-true" : "") + " " + (props.disabled? " disabled" : "") }></span>
		</>;
	}

	return (
    <div className={"field " + props.className} title={props.title ? props.title : ""}> 
		  {label}
		  <label className="toggle_switch">
			{thing}
		  </label>
	  </div>
  )
}
