import React, { Component} from 'react';


import moment from 'moment';

export default class Month extends Component {
	
	componentWillMount(){
	}

	handleClickedDay(day,date){
		
		if (this.props.onClickDay){
			return this.props.onClickDay(day,date);
		}	
		
		// we definately don't need these refs; 
		const ref = this.refs ? this.refs["dayComponent" + date] : null;
		if (ref != null && ref.handleClickedDay != null){
			ref.handleClickedDay();
		}
			
	}


	renderDay(day, eventsForDay){
		const children = [];

		let body = (<div></div>);
		
		if (this.props.renderDay != null){
			body = this.props.renderDay(this, day, eventsForDay);
		}
		if (this.props.DayComponent != null){
			body = React.createElement(this.props.DayComponent, 
					Object.assign({}, this.props, {onClick: this.props.onClickDay, refId: "dayComponent" + day.format('D'), day: day, eventsForDay: eventsForDay, key: day.format('D'), dayChildren: this.props.dayChildren}));
		}
	
		return (<td key={day.format('D')} onClick={()=>this.handleClickedDay(day.format('D'), day.format('YYYY-MM-DD'))}>
					<span className="dayOfMonth">{day.format('D')}</span> 
				{body}
				</td>);
	}
	render() {
		
		const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

		const headers = weekDays.map( (heading) =>  
			<th key={heading}>{heading}</th>		
		);
		
		const weeks = [];
	
		
		const startDate = moment(this.props.date).set({date: 1});
		
		let currentWeek = [];
		for(var x = 1; x<=startDate.day(); x++){
			currentWeek.push(<td key={-1 * x}></td>);
		}
		for (var x = 1; x <=startDate.daysInMonth(); x++){
			
			
			//var timestamp = moment(startDate).date(x).unix() * 1000;
			var timestamp = moment(startDate).date(x).format('YYYY-MM-DD'); //unix() * 1000;
			
			const eventsForDay = _.filter(this.props.data, {date: timestamp}); //_.filter(this.props.data, {date: timestamp});
			
			var day = this.renderDay(moment(startDate).date(x), eventsForDay);
			
			currentWeek.push(day);
			if (currentWeek.length == 7){
				weeks.push( <tr key={"week" + weeks.length}>{Object.assign([], currentWeek)}</tr> );
				currentWeek = [];
			}
			
		}
		weeks.push( <tr key={currentWeek.length}>{currentWeek}</tr> );

		return (
			<div className="calendar-outer">
			
					<h1 className="calendar">{startDate.format('MMMM YYYY')}</h1>
					<table className="monthly-view">
						<thead>
						<tr>
						{headers}
						</tr>
						</thead>
						<tbody>
						{weeks}
						</tbody>
					</table>
				
			</div>
		
		);
	}
}
