import React, {useState, useEffect, useRef} from 'react';
//import { Link } from 'react-router-dom';
import {  useNavBar } from '@opidcore/hooks/WTF';
import { Tabular, TabularColumn } from '@opidcore/components/Tabular';
// import ActionBar from '@opidcore/components/ActionBar';
// import Searchable from '@opidcore/components/Searchable';
import {Loading, Button} from '@opidcore/components';
import _ from 'lodash';
import { NiceCurrency  } from '../Nice.jsx';

export default function ListToGenerate(props){
	useNavBar("Receivables to Generate", []);
	const [data, _setData] = useState([]);
	const [status, setStatus] = useState(null);
	const [message, setMessage] = useState(null);
	
	const dataRef = useRef([]);
	const [ticky, setTicky] = useState(1);
	
	const setData = (data)=>{
		_setData(data);
		dataRef.current = data;
		setTicky( Math.random() );
	}
	
	useEffect( ()=>{
		APP.central.ARInvoice.flaggedForExport(0).then( (r)=>{
			if (_.isArray(r.result) ){
				setData(r.result);
				setStatus("Ready")
			}else{
				APP.alert( r.result );
			}
		});
		
		APP.getState("handleSocket", (thing)=>{			
			let found = false;
			if (thing.object && thing.object.id && thing.object.__type == "XeroLog"){
				const newData = dataRef.current.map( (row)=>{ 
					if (row.id == thing.object.id){
						found = true;
						return thing.object;
					}
					
					return row;
					});
				
				if (found == false){
					newData.push( thing );
				}
				
				setData(newData);
			}
		});
	}
	, []);
	
	const generate = ()=>{
		setStatus(null);
		setMessage("Generating invoices...");
		
		APP.central.Xero.status().then( (r)=>{ if ( r.result.state == "OK" ){
			
			
				APP.central.ARInvoice.flaggedForExport(1).then( (r)=>{
					if (_.isArray(r.result) ){
						APP.central.ScheduledTasks.startQueues();
						setData(r.result);
						setStatus("done");
					}else{
						APP.alert( r.result );
					}
				});	
				
			}else{
				APP.alert( "Please check the Xero connection." );
			} 
			}
		);
		
			
	}
	
	if (status == null){
		return <Loading message={ message || "Loading" }/>;
	}
	
	return <div key="list_invoices">
		
	    <Tabular data={data} ticky={ticky}>
	    	<TabularColumn data={(row)=>row.relatedId} title="Invoice ID"/>
        	<TabularColumn data={(row)=>row.description} title="Customer"/>
            <TabularColumn className="right-align" data={(row)=><NiceCurrency>{row.amount}</NiceCurrency>} title="Total"/>
            <TabularColumn data={(row)=>row.status + ' ' + (row.details != null ? row.details : '') } title="Status"/>
        </Tabular>
        	
        <Button onClick={generate}>Generate</Button>
	</div>;
}