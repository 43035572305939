import React, { useState, useContext, useEffect } from 'react';
import {Icon} from '@opidcore/components';
import { DataContext } from '@opidcore/components/Bound';

export function withXero(func){

	return APP.central.Xero.status().then( (status)=>{
		if (status.result.state == "OK"){
			func();
		}else{
			APP.instance.createModal("/ui/admin/xero");
		}
	});

}
export default function XeroLink(props){
	const bound = useContext(DataContext);
	const [status, setStatus] = useState("");
	const [xeroUUID, setXeroUUID] = useState(null);
	
	const refreshXero = (uuid)=>{
		if (uuid){
			setXeroUUID( uuid );
			setStatus("exists")
		}
	};
	
	const sync = (a)=>{
		const args = {};
		args.thing = bound.to;
		
		withXero( ()=>{ 
			APP.central.Xero.sync( args ).then( (r)=>{
				//console.log( "xero sync got back", r);
				if (r.result != null && r.result.xeroUUID){
					refreshXero(r.result.xeroUUID);
				}
			});
		});
	};
	
	useEffect( ()=>{
		refreshXero(bound.to.xeroUUID);
    bound.magicalState("xeroUUID", () => refreshXero(bound.to.xeroUUID)); 
	}, [bound]);
	
	let link = "https://go.xero.com/AccountsPayable/Edit.aspx?InvoiceID="+xeroUUID;
	if (props.type == "ARInvoice"){
		link = "https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID="+xeroUUID;
	}else if (bound.to.xeroType == 'credit'){
		link = "https://go.xero.com/AccountsPayable/ViewCreditNote.aspx?creditNoteID="+xeroUUID;
	}

	if (xeroUUID == null){
		link = null;
	}
	return <div className="field">
		<label>Xero Status</label>
		<div className="value">
				{ link ? <a href={link} target="_new" className="block blueish" style={{maxWidth: "12em"}}><Icon icon="file-invoice-dollar" size="2x" color="white"/> {status == "exists" ? "View Invoice" : status }</a> : status }
		</div>
	</div>
}

