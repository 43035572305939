import React, { useContext, useEffect, useState } from 'react';
import { InputText } from '@opidcore/components';
import * as _ from 'lodash';

import PropTypes from 'prop-types';
import { DataContext } from './Bound';

export default function InputDecimal(props) {
	const [decimalPlaces, setDecimalPlaces] = useState(props.places != undefined ? props.places : 2);
	const bound = useContext(DataContext);
	const [currentValue, setCurrentValue] = useState(0);


	useEffect(() => {
		if (bound.magicalGet(props.field)) {
			const providedDecimals = _.size((bound.magicalGet(props.field) + "").split('.')[1]);
			setDecimalPlaces(_.clamp(providedDecimals, decimalPlaces, props.maxPlaces == undefined ? null : props.maxPlaces));
		}
		bound.magicalState(props.field, _setCurrentValue);
	}, [bound]);

	useEffect(() => {
		const providedDecimals = _.size((currentValue + "").split(".")[1]);

		if (providedDecimals != decimalPlaces) {
			setDecimalPlaces(_.clamp(providedDecimals, props.places != undefined ? props.places : 2, props.maxPlaces == undefined ? null : props.maxPlaces));
		}
	}, [currentValue]);

	const _setCurrentValue = (val) => {
		if(val == null || val == ""){
			if(props.defaultValue != undefined){
				val = props.defaultValue + 0;
			}
		}
		setCurrentValue(val);
	}

	let numFormat = new Intl.NumberFormat('en-ca', { style: 'decimal', minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces });

	const formatter = {
		parse(a, b) {
			const newA = parseFloat(a.replaceAll(",", "").replaceAll("\D", ""));

			return newA;
		},

		format(a, b) {

			if (a.split) {
				const decimalPlacesTyped = _.size(a.split(".")[1]);
				if (props.maxPlaces && decimalPlacesTyped <= props.maxPlaces) {
					setDecimalPlaces(decimalPlacesTyped);
					numFormat = new Intl.NumberFormat('en-ca', { style: 'decimal', minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces });
				}
			}
			const newA = numFormat.format(a);

			return newA;
		}
	};

	const onFocus = (boundMagic, event) => {
		const target = event.target;

		if (event.nativeEvent.sourceCapabilities == null) {
			//keyboard event maybe
			event.target.select();
		} else {
			const mouseUpFunction = () => {
				if ((target.selectionEnd - target.selectionStart) == 0) {
					event.target.select();

				}

				target.removeEventListener("mouseup", mouseUpFunction);
			};

			target.addEventListener("mouseup", mouseUpFunction);
		}
	}

	var extraProps = {}

	if (props.boundUpdate) {
		extraProps.update = () => {
			props.update(bound)
		}
	}

	return <InputText {...props} {...extraProps} inputType="decimal" onFocus={onFocus} formatter={formatter} className={(props.className || "") + " decimal"} />
}

InputDecimal.propTypes = {
	places: PropTypes.number,
	maxPlaces: PropTypes.number,
	field: PropTypes.string,
	className: PropTypes.string,
	name: PropTypes.string,
	parent: PropTypes.string,
	defaultValue: PropTypes.number,
  children: PropTypes.any,
  title: PropTypes.string
}