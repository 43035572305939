import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { InputSelect, InputText } from '@opidcore/components';
import ActionBar from '@opidcore/components/ActionBar';
import BoundCommit from '@opidcore/components/BoundCommit';
import Icon from '@opidcore/components/Icon';
import Searchable from '@opidcore/components/Searchable';
import { TabularColumn, TabularDataSet } from '@opidcore/components/Tabular';
import { useData } from '@opidcore/hooks/WTF';


function CreateTemplateModal(props){
    let submitData = useRef({id: 0, __delta: {copy_from: props.existingTemplates.length == 0? "master_of_all_masters" : undefined}});
    
    const options = {};
    
    _.each(props.existingTemplates, (eT)=>{
        options[eT.theId] = eT.theId;
    });
    
    return <div>
        <BoundCommit initialValue={submitData.current} commit={APP.central.Template.saveChanges}>
            <InputText field="new_cid" name="Identity"/>
            <InputSelect field="copy_from" name="" options={options} showEmpty={props.existingTemplates.length > 1} disabled={props.existingTemplates.length == 0}/>
        </BoundCommit>
    </div>
}

export default function MasterTemplateList(props){
    const [data, dataSet] = useData("content_thing", {component: "DocumentTemplate"});
    
    const createNew = ()=>{
        const modal = <CreateTemplateModal existingTemplates={data.slice(0,1)} />;
        APP.instance.createModal(modal, {modal_name: "New Template"});
    };
    
    const createCopy = (audit)=>{
        const modal = <CreateTemplateModal existingTemplates={[audit]} />;
        APP.instance.createModal(modal, {modal_name: "Copy Template"});
    };
    
    return <div key="list_master_templates">
        <ActionBar title="Actions">
            <Searchable ds={dataSet} />
            <div className="action_button" onClick={createNew}>New</div>
        </ActionBar>
    
        {dataSet.loading ? "loading" : ""}
    
        <TabularDataSet dataSet={dataSet} filter={(row)=>row.options.type == "audit"} noData="No existing templates">
            <TabularColumn data={(row)=>row.cid} title="ID"/>
            <TabularColumn data={(row)=>JSON.stringify(row.options)} title="Options"/>
            <TabularColumn data={(row)=><Link to={"/content/" + row.children[0]}><Icon icon="caret-right" size="2x"/></Link>} />
            <TabularColumn data={(row)=><div className="action_button" onClick={()=>createCopy(row)}>Copy</div>} />
        </TabularDataSet>
    </div>;
}