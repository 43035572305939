//import './AddFriendInput.scss';

import React, { Component, PropTypes } from 'react';
import Bound from './Bound';
import BoundComponent from './BoundComponent';
import * as _ from 'lodash';

export default class GrowableList extends Component {
	
  static propTypes = {
  };

  constructor(props, context) {
    super(props, context);

    this.state = {adding: false};
    
  }
  
  componentWillReceiveProps(nextProps){
	  //this.forceUpdate();
  }
  
  shouldComponentUpdate(nextProps, nextState){
	  if (nextState.adding){
		return true;
	  }
	  
	  //this if should do the job of the above ifs, generally
	  if (this.props.list == null && nextProps.list != null) {
		  return true;
	  }
	  
	  if (Array.isArray(this.props.list) && this.props.list.length != nextProps.list.length){
		  return true;
	  }
	  
	  if (this.props.list && Object.keys(this.props.list).length != Object.keys(nextProps.list).length){
		  return true;
	  }
	  
	  if (nextProps.mergeState){
		  if (nextProps.mergeState[this.props.statePath] != null && nextProps.mergeState[this.props.statePath] == null){
			return true;  
		  }
		  
		  if (nextProps.mergeState[this.props.statePath] && nextProps.mergeState[this.props.statePath]){
			  if (this.props.mergeState[this.props.statePath] && this.props.mergeState[this.props.statePath].length != nextProps.mergeState[this.props.statePath].length){
				  return true;
			  }
		  }
	  }
	  
	  //When adding something with temporary id of -1, let it rerender after save without refreshing
	  if (_.last(this.props.list) != null && _.last(this.props.list).id != undefined && _.last(this.props.list).id <= 0) {
		  return true;
	  }
	  
	  //Added so that signature row refreshes when it goes from populated to empty
	  if(this.props.list != undefined && this.props.children != undefined && this.props.list.length != this.props.children.length){
		  return true;
	  }

	  return false;
  }
  componentDidUpdate(prevProps, prevState){
	  if(prevProps.list != undefined && this.props.list != undefined && prevProps.list.length != this.props.list.length || prevState.adding == true){
		  this.setState({adding: false});
	  }
  }
 
  render() {
    var obj = this.props.to;
    const TagName = this.props.tagName || "div";
    if (TagName != "none"){
    	return <TagName className="growableList"><h2>{this.props.listTitle}</h2>
		{this.mapAllItems()}</TagName>;
    }else{
    	return this.mapAllItems();
    }
  }
  
  handleClickedCancel(){
	  this.setState({adding: false});
  }
  
  handleClickedAddItem(){
	  if (this.props.onNewRecord) {
		  this.props.onNewRecord();
	  }else{
		  this.setState({adding: true});
	  }
  }
  
  mapAllItems(){  
	  let mergeState = {};
	  const ChildTagName = this.props.tagName || "li";
	  const OuterTagName = this.props.outerTagName || "ul";
	  
	  	if (this.props.mergeState){
		  mergeState = this.props.mergeState[this.props.statePath];
	  	}else{
	  	  mergeState = null;
	  	}
	  		//immediateQuestions
	  	
	  	  var listObjects = Array.isArray(this.props.list) ?  this.props.list
	  			   : _.values(this.props.list);
	  	  
	  	  var listItems = listObjects.map( (item, idx)=> {
	  		  
	  		  		var mergeItem = {};
	  		  		if (mergeState != null){
  		  				mergeItem = _.find(mergeState, (row) =>{ return row[this.props.statePathKey] == item[this.props.statePathKey] }); //mergeState[item[this.props.statePathKey]];
  		  				if(mergeItem == null && this.props.listKey != undefined){
  		  					mergeItem = _.find(mergeState, (a) =>{
  		  						if(a != undefined){
  		  							return a[this.props.statePathKey] == item[this.props.listKey];
  		  						}
  		  						return false;
  		  					});
  		  				}
	  		  		}
	  	  			var thisItem = {...item, ...mergeItem};
	  	  			
	  	  			if ( ChildTagName == "none" ){
	  	  				return this.mapAllChildren(thisItem, this.props.children, 1);
	  	  			}
	  	  			
  	  				return <ChildTagName key={item.id || item.key} id={item.id || item.key} className={this.props.childClassName}>
  	  					{this.mapAllChildren(thisItem, this.props.children, 1)}
  	  					{this.props.deleteItem? <button className="deleteButton btn btn-secondary" onClick={()=>this.props.deleteItem(thisItem)}>Delete</button> : ""}
  	  				</ChildTagName>
		  	  			
	  	  			}
	  			  );
	  	  
	  	  if (this.state.adding){  	   	  
	  		  listItems.push( 
	  			  <ChildTagName key="adding" className={this.props.childClassName}>{this.mapAllChildren(this.props.newRecord, this.props.children, 1, true)}</ChildTagName>	  
	  		  )
	  		  
	  	  }
	  	  let cancelOrAdd = null;
	  	 if(!this.props.hideButton){
	  		 if(this.state.adding){
	  			cancelOrAdd = <button className="growableAction" key="cancel" onClick={()=>this.handleClickedCancel()}>Cancel</button>;
	  		 } else {
	  			 if(this.props.newLabel == undefined){
	  				 cancelOrAdd = <span key="new" onClick={()=>this.handleClickedAddItem()} className="growableAction addNew glyphicon glyphicon-plus"/>;
	  			 } else {
  					 cancelOrAdd = <button className="growableAction btn btn-success" key="new" onClick={()=>this.handleClickedAddItem()}>{this.props.newLabel}</button>;
	  			 }
	  		 }
	  	 }
	  	 
	  	 if (this.props.outerTagName == "tbody" && !this.props.hideButton){
	  		cancelOrAdd = <tr className="add_row"><td>{cancelOrAdd}</td></tr>;
	  	 }
	  	 
	  	  return (<OuterTagName className={this.props.className}>
  	  			{listItems}
	  	  		{cancelOrAdd}
	  	  	</OuterTagName>);	  	  
	  }
  
  mapAllChildren(item, children, level, newRecord){
	  	  var that = this;
	  	 
		  return React.Children.map(children, function(child){
			  let newChild = null;
			  
			  let args = {};
			  
			  if (level == 1){
				  args.key = "item" + item.id;
			  }

			  if( child.type != null){
				  
				  if (child.type.prototype instanceof Bound){
					  args = {to: item, ref: "item" + item.id, parentList: that};
				  }
				  
				  if (child.props.className == "growableAction"){
					  args = {"data-id": item.id};
				  }
				  
				  if (child.type.name == "Flippable"){
					  args = {item: item, ref: "item" + item.id};
					  if(newRecord){
						  args.id = 0;
					  }
				  }else{
					  //ian said to eventually add ref to use
					  args.item = item;
				  }
				  
				  if (child.type.prototype instanceof BoundComponent){
					  args.seee = '?';
				  }
				  
			  }

			  return React.cloneElement(child, args, child.props ? that.mapAllChildren(item, child.props.children, level++, newRecord) : null);
			  
		  });
	  }
}
