import React, { useContext, useRef, useState }  from 'react';
import { useNavBar } from '@opidcore/hooks/WTF';
import { InputText, Bound, BoundDataContext }  from '@opidcore/components';
import Grid, { GridItem } from '@opidcore/components/Grid';
import File from '../../components/File'; 

function BoundValue(props){
	const ctx = useContext(BoundDataContext);

	return <span>{ctx.magicalGet(props.field)}</span>;
}

export default function DataMesser(){
	useNavBar("Data Stuff", []);
	const [] = useState(null);
	const query = useRef({});
	const results = useRef({});
	const [ticky, setTicky] = useState(0);
	const [dataPreview, setDataPreview] = useState([]);
	const [message, setMessage] = useState(null);

	const updateQuery = ()=>{
		/** APP.central.Zoho.testDataExcel().then( (r)=>{
			results.current = r.result;
			setMessage( _.filter( r.message, (m)=> m.indexOf("Query") >= 0 ).join(" ") );
			setDataPreview(results.current.rows);
			setTicky(Math.random());
		}); **/
		
		APP.central.Zoho.testData(query.current.sql).then( (r)=>{
			results.current = r.result;
			setMessage( _.filter( r.message, (m)=> m.indexOf("Query") >= 0 ).join(" ") );
			setDataPreview(results.current.rows);
			setTicky(Math.random());
		});
	};

	const doImport = ()=>{
		const a = results.current;
		// debugger;
		APP.central.Zoho.processData(results.current.uid);
	}

	const loadData = (file)=>{
		APP.central.Zoho.loadData(query.current.file, query.current.tableName);
		//APP.central.Zoho.loadDataExcel();
	}

	const columns = results.current.columns ? results.current.columns.map( (col)=>{
			return <GridItem title={col.label} key={col.key}>
				<BoundValue field={col.key}/>
			</GridItem>
	}) : [];

	const handleUpload = (f)=>{
		query.current.file = f;
	};
	
	return <div>
		<File onUpload={handleUpload}/>
		<Bound to={query.current}>
			<InputText name="Table Name" field="tableName"/>
			<a href="#" onClick={loadData}>load</a>


			<InputText field="sql"/> <a href="#" onClick={updateQuery}>execute</a>
			<a href="#" onClick={doImport}>import</a>
			<span>{message}</span>
		</Bound>
		<Grid data={dataPreview} ticky={ticky}>
			{columns}
		</Grid>
		
	</div>;
}

