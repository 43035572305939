import React, { useEffect, useState, useRef }  from 'react';
// import PropTypes from 'prop-types';
import { useParams } from "react-router-dom";
import moment from 'moment';
import {Tabular, TabularColumn} from '@opidcore/components/Tabular';
import { useData } from '@opidcore/hooks/WTF';
import { Icon,  Button } from '@opidcore/components';
//import { NiceSerivce, NiceSite  } from '../Nice.jsx';

function AddLinesToContract(props){
    const selectedContract = useRef({id: 0});
    const applyLines = ()=>{
        console.log("Adding " + _.keys(props.lines).length + " to contract #" + selectedContract.current);
        
        if(selectedContract.current == 0){
            APP.alert("invalid contract");
            return;
        }
        
        return props.addLinesToContractHelper(selectedContract.current, props.lines);
    }
    
    const options = [<option key="blank" value="0"></option>];
    
    _.forEach(props.contractData, (contract)=>{
        options.push(<option key={contract.id} value={contract.id}>{contract.customer? contract.customer.name : "Missing Client for contract #" + contract.friendlyId}</option>);
    });
    
    return <div key="add_lines_to_contract">
        <div>Which contract would you like to add these to?</div>
        <select onChange={(e)=>{selectedContract.current = e.currentTarget.value}}>{options}</select>
    
        <Button onClick={()=>applyLines()}>Apply</Button>
    </div>
}

export default function ManageContracts(props){
    let params = useParams();
    const modalIdRef = useRef(0);
    let [selectedLines, setSelectedLines] = useState({});
    let [contractData, contractDataSet] = useData(params.contractType + "_contract", {status: "active"});
    let [lineData, lineDataSet] = useData(params.contractType + "_contract_line", {clientContract: null});
    
    const captilizedTitle = params.contractType.charAt(0).toUpperCase() + params.contractType.slice(1);
    
    const openContract = (contract)=>{
        APP.instance.createModal(<span>Hello {contract.friendlyId}</span>);
    };
    
    const selectStuff = (event, contractLine)=>{
        const newSelectedLines = _.cloneDeep(selectedLines);
        if(event.currentTarget.checked == false){
            delete newSelectedLines[contractLine.id];
        } else {
            newSelectedLines[contractLine.id] = contractLine;
        }
        
        setSelectedLines(newSelectedLines);
    }
    
    const selectAll = (event)=>{
        if(event.currentTarget.checked == false){
            //remove all
            setSelectedLines({});
        } else {
            const newSelectedLines = _.cloneDeep(selectedLines);
            _.forEach(lineData, (contractLine)=>{
                newSelectedLines[contractLine.id] = contractLine;
            });
            
            setSelectedLines(newSelectedLines);
        }
    }
    
    const addLinesToContractHelper = (contractId, lines)=>{  
        contractData = _.map(contractData, (contractRow)=>{
            if(contractRow.id == parseInt(contractId)){
                contractRow.clientLines = contractRow.clientLines.concat(lines);
            }
            
            return contractRow;
        });
        
        contractDataSet.reset(contractData);

        if(modalIdRef.current){
            APP.instance.minimizeModal(modalIdRef.current);
        } else {
            APP.instance.clearModals();
        }
    }
    
    const addLinesToContract = ()=>{
        modalIdRef.current = APP.instance.createModal(<AddLinesToContract lines={selectedLines} contractData={contractData} addLinesToContractHelper={addLinesToContractHelper}/>, {className: "apply_contract_lines"});
    }
    
    return <div key={"manage_contracts_" + params.contractType} className={"manage_contracts_" + params.contractType}>
        <h2>Manage {captilizedTitle} Contracts</h2>
        <Tabular data={contractData}>
            <TabularColumn data={(r)=>r.friendlyId} title="ID"/>
            <TabularColumn data={(r)=>r.externalContractNumber} title="External Contract Number"/>
            <TabularColumn data={(r)=>r.customer? r.customer.name : ""} title="Client"/>
            <TabularColumn data={(r)=>r.status} title="Status"/>
            <TabularColumn data={(r)=>r.effectiveFrom? moment(r.effectiveFrom).format("YYYY-MM-DD") : ""} title="Effective From"/>
            <TabularColumn data={(r)=>r.effectiveTo? moment(r.effectiveTo).format("YYYY-MM-DD") : ""} title="Effective To"/>
            <TabularColumn data={(r)=>r.clientLines ? r.clientLines.length : 0} title="# Client Lines"/>
            <TabularColumn data={(a,r)=><Icon icon="edit" onClick={()=>openContract(r)}/>} title=""/>
        </Tabular>
        
        
        <h2>Orphaned Lines</h2>
        <Tabular data={lineData}>
            <TabularColumn data={(r)=><input type="checkbox" onChange={(e)=>selectStuff(e, r)}/>} title={<span><input type="checkbox" onChange={(e)=>selectAll(e)}/>Select All</span>}/>
            <TabularColumn data={(r)=>r.id} title="ID"/>
            <TabularColumn data={(r)=>r.site? r.site.name : ""} title="Site"/>
            <TabularColumn data={(r)=>r.service? r.service.description : ""} title="Service"/>
            <TabularColumn data={(r)=>r.status} title="status"/>
        </Tabular>
        <Button onClick={()=>addLinesToContract()}>Add ({_.keys(selectedLines).length})</Button>
    </div>
}