import React, { Component } from 'react';

import { InputText, InputSelect, Button, Bound, SaveToolbar, CoreForm, CentralForm } from '.';
import moment from 'moment';
import * as _ from 'lodash';

export class ClippedThing extends Component {
    constructor(props, ctx){
        super(props, ctx);
        
        this.state = { width: 0, height: 0 };
    }
    
    componentDidMount(){
        var a = this.refs.outer;
        this.setState({width: a.offsetWidth, height: a.offsetHeight });
    }
    
    render(){
        return <div ref="outer" className="scrollable-outer" style={{position: 'relative'}}>
            <div ref="inner" className="scrollable-inner" style={{left: -1 * (this.props.left - this.state.width/2), top: -1 * (this.props.top - this.state.height/2) }}>
                {this.props.children}  
            </div>
        </div>;
    }
    
}

export  class ImageThing extends Component {
    render(){
        return <div className="image-outer" style={{position: 'relative'}} onClick={this.props.onClick}>
            <img className="background-map" src={this.props.img}/>
            {this.props.children}  
        </div>;
    }
    
}

export class ImageIcon extends Component {
    render(){
        return <div className={"image-inner " + this.props.className}  style={{position: 'absolute', left: this.props.left, top: this.props.top}}>
            {this.props.children}  
        </div>;
    }
    
}

export default {
	Icon: ImageIcon,
	Image: ImageThing,
	Clipped: ClippedThing,
}