import React, { Component, PropTypes } from 'react';
import { Link } from 'react-router-dom';
import BoundComponent from './BoundComponent';

import * as _ from 'lodash';

export default class Button extends BoundComponent {

	constructor(props, context) {
		super(props, context);
	}

	render() {
		var extraProps = {};

		if (this.props.tabIndex) {
			extraProps.tabIndex = this.props.tabIndex;
		}

		if (this.props.style) {
			extraProps.style = this.props.style;
		}

		let iconClassName = "";

		if (_.startsWith(this.props.icon, "glyphicon")) {
			iconClassName += "pull-left glyphicon " + this.props.icon;
		}

		const btnType = this.props.type ? this.props.type : "big-button btn btn-lg";

		const button = <button {...extraProps} onClick={this.props.onClick} type="button" className={btnType + " " + (this.props.className || "btn-default")} title={this.props.title}>
			<span className={iconClassName} aria-hidden="true"/>
			{this.props.children}
		</button>;

		if (this.props.linkTo) {
			return <Link to={this.props.linkTo}>
				{button}
			</Link>;
		} else {
			return button;
		}
	}
}
