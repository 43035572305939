import React, { useEffect, useState }  from 'react';
import Util from '@opidcore/Util.js';
import RenderMeSoftly from '../RenderMeSoftly.jsx';
import templated from './Templated.jsx';

export function ListItem(props) {
  const [className,setClassName] = useState( props.content ? "" : "new-item" );
  const newItemText = "new item";

  const inner = templated(props.content || newItemText, {props: props}, (content)=>{
      if (content > "" && content != newItemText){
        props.handleInlineChange({content: content});
      }

      if (content > "" && className == "new-item"){
        setClassName("");
      }

      if (content == ""){
         //eventually delete this?
      }
    });

    const olType = props.olType == "checkmark" ? props.olType : "";
    const level = props.indentLevel ? "level-" + props.indentLevel : "";

  return <li key={props.rootStructure._position} className={className + " " + olType + " " + level}>{inner}{props.children}</li>
}
ListItem.getEditableProps = ({})=>{
      
    return [
        {niceName: "Content", prop: "content", type: "text" },
        {niceName: "Type Display", prop: "olType", options: ["","checkmark"], type: "text" },
        {niceName: "Level", prop: "indentLevel", options: ["","1","2"], type: "text" },                   
    ]
    
};


export default function List(props) {
  let editing = null;
	
  if( props.mode == "edit"){
	  var newest = props.NDT.find(props.root + "$" + (props.children ? props.children.length : 0));
          const rmsProps = {
              key: newest.theId,
              mode: props.mode,
              scope: props.scope,
              root: newest.theId,
              rootStructure: newest,
              NDT: props.NDT
          }
          
    	  editing = <RenderMeSoftly {...rmsProps} iCameFromList={true}/>;
  }

  const ListType = props.type == "ol" ? "ol" : "ul";
  
  var children = props.children;
  if (props.expr != null && props.expr.length > 0){
	  children = _.map( props.expr, (i, index)=>{ return <li key={index}>{i}</li> });
  } else if (props.datasetName != undefined && props.datasetAttribute != undefined){
      if(APP.NDT && APP.NDT.datasets[props.datasetName]){
          const rows = _.map(APP.NDT.datasets[props.datasetName].rawData, props.datasetAttribute);
          children = _.map( rows, (row, index)=>{ return <li key={index}>{row}</li> });
      }
  }
  const olType = props.olType == "checkmark" ? "none" : props.olType;
  const olTypeClass = props.olType == "checkmark" ? " checkmark" : "";
  return (<>
    <ListType className={(props.cssClass? props.cssClass: "") + olTypeClass}  type={olType}>
    	{children}
    	{editing}
     </ListType>
     </>
  );
}

List.getEditableProps = ()=>{
  	        
        return [
            {niceName: "Type", prop: "type", options: ["", "ol", "ul"], type: "text" },
            {niceName: "Type Display", prop: "olType", options: ["", "1","a","A","i","I","checkmark"], type: "text" },            
            {niceName: "Items", prop: "items", options: ["static", "data"], type: "text" },
			{niceName: "Data Expression", prop: "expr", type: "text", default: "", allowJS: true},
			{niceName: "Dataset", props: "datasetName", type: "text"},
			{niceName: "Dataset Attribute", props: "datasetAttribute", type: "text"},
			{niceName: "CSS Class", prop: "cssClass", type: "text", default : ""}
        ]
        
};
List.metaInfo = ()=>{
	return {
		inlineChildren: {
            type: "ListItem",
            dimensions: 1
        },
		rendersChildren: true,
		showInEditorQuickAdd: true
	};
};

