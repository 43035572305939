import React, { useState } from 'react';




export default function AutoComplete(props) {
	const name = props.name;
	
	const [options, setOptions] = useState([]);
	
	const keyup = (e)=>{
		props.central(e.currentTarget.value).then((r)=>{
			setOptions(r.result);
		});
	}
	
	return       <div className={"field " +  (props.className)} style={props.style}>
		<label>{name}</label>
	    <input type="text" onKeyUp={(e)=>keyup(e)}/>
	    <div className="options">
	    	{options.map((o)=><span key={o.id} onClick={(a)=>props.onSelect(o)}>{o.name}</span>)}
	    </div>
    </div>
}
