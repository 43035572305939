import React, { useState, useEffect, useContext } from 'react';
import { BoundDataContext, InputSelect } from '@opidcore/components';
import _ from 'lodash';

export default function StaffSelect(props){
    let [staffList, setStaffList] = useState([]);
    const field = props.field ? props.field : null;
    const bound = useContext(BoundDataContext);
    
    useEffect( ()=>{
        if (props.team) {
            APP.central.Staff.staffList(props.team).then( (r)=>{
                const options = [];
                const inactiveOptions = [];
                _.forEach(r.result, (staff)=>{
                    const option = {key: staff.id, value: staff.label};
                    if(staff.extra && staff.extra.active == false){
                        option.customClass = "inactive";
                        if(bound && bound.to && bound.to[field] && bound.to[field].id == staff.id) {
                          inactiveOptions.push(option);
                        } 
                    }else{
                        options.push(option);
                    }
                    
                });
    
                const sortedOptions = _.flatten([ _.sortBy(options, (o)=>o.value ), _.sortBy(inactiveOptions, (o)=>o.value ) ] );
                setStaffList(sortedOptions);
            });
        } else {
            APP.central.Staff.staffList().then( (r)=>{
                const options = [];
                const inactiveOptions = [];
                _.forEach(r.result, (staff)=>{
                    const option = {key: staff.id, value: staff.label};
                    if(staff.extra && staff.extra.active == false){
                        option.customClass = "inactive";
                        if(bound && bound.to && bound.to[field] && bound.to[field].id == staff.id) {
                          inactiveOptions.push(option);
                        } 
                    }else{
                        options.push(option);
                    }
                    
                });
    
                const sortedOptions = _.flatten([ _.sortBy(options, (o)=>o.value ), _.sortBy(inactiveOptions, (o)=>o.value ) ] );
                setStaffList(sortedOptions);
            });
        }    	
    }, [bound]);
    
    return <InputSelect className="staffSelect" {...props} options={staffList} sortBy={["active desc", "lastName asc"]}/>
}