import React, { useRef } from "react";
import { useData, useNavBar } from "@opidcore/hooks/WTF";
import { Icon, TabularDataSet, TabularColumn, Button, Pagination, Searchable, ActionBar, BoundCommit, InputText, UnlockToEdit } from "@opidcore/components";
import * as _ from "lodash";

export default function ServiceScheduleEdit(props) { 
  useNavBar("Edit Service Schedule", []);

  const [data, dataSet] = useData("service_schedule", { paginate: 100 });

  const editServiceSchedule = (serviceSchedule) => {
    APP.instance.createModal(<EditServiceSchedule serviceSchedule={serviceSchedule} /> );
  };

  const newServiceSchedule = () => {
    APP.instance.createModal(<EditServiceSchedule serviceSchedule={{ __new: true, __dataUID: dataSet.dataUID }} />);
  };

  return (
    <div className="full-size">
      <ActionBar title="Actions">
        <Searchable ds={dataSet} />
        <a className="btn btn-new" onClick={() => newServiceSchedule()}>
          New Service Schedule
        </a>
      </ActionBar>

      <div>
        <Pagination dataSet={dataSet} />
        <Button onClick={() => dataSet.download("xlsx")}>excel</Button>
      </div>

      {dataSet.loading ? "loading" : ""}

      <TabularDataSet dataSet={dataSet} defaultSort="id" defaultSortOrder="asc">
        <TabularColumn data={(row) => row.id} title="Service Schedule #" />
        <TabularColumn data={(row) => row.frequency} title="Frequency" />
        <TabularColumn data={(row) => row.monthlyFactor} title="Monthly Factor" />
        <TabularColumn data={(row) => row.weeklyFactor} title="Weekly Factor" />

        <TabularColumn
          data={(row) => {
            return <Icon icon="edit" onClick={() => editServiceSchedule(row)} size="2x" />;
          }}
        />
      </TabularDataSet>
    </div>
  );
}

function EditServiceSchedule(props) {
  const serviceScheduleRef = useRef({ ...props.serviceSchedule });

  return (
    <div>
      <BoundCommit
        initialValue={serviceScheduleRef.current}
        forceSaveProps={["serviceschedule"]}
        commit={APP.central.ServiceSchedule.saveChanges}
        className="basicForm"
      >
        <UnlockToEdit>
          <InputText field="id" name="#" />
        </UnlockToEdit>
        <InputText field="frequency" name="Frequency" />
        <InputText field="monthlyFactor" name="Monthly Factor" />
        <InputText field="weeklyFactor" name="Weekly Factor" />
      </BoundCommit>
    </div>
  );
}
