import React, { Component } from 'react';
import MetaTalker from './MetaTalker.jsx';
import templated from './Templated.jsx';

export default class SectionCounter extends MetaTalker {
    constructor(props){
        super(props);
        this.state = {val: null, subsectionsBeforeMe: 0};
    }
    
    static getEditableProps() {
        return [
            {niceName: "Section Name", prop:"reference", type:"text"}
        ]
    }
    
    static metaInfo(thing) {
        return {inContent: false};
    }
    
    getNewsubsectionsBeforeMe(){
        let subsectionsBeforeMe = 0;
        let theSubsection = this.props.subsectionOf;  
        let theRealId = this.props.rootStructure.injectTo;
        let parentSection = null;
        
        if(theSubsection){
            parentSection = APP.NDT.scope.sections[theSubsection];
            if(parentSection == undefined){
                if(this.props._loopIndex != undefined && theSubsection){
                    theSubsection = theSubsection + "-loop-" + this.props._loopIndex;
                    parentSection = APP.NDT.scope.sections[theSubsection];
                }
            }
        }
        
        if(parentSection != null){       
            let emptyBeforeMe = 0;
            _.forEach(parentSection.subsections, (subsection, index)=>{
                if(APP.NDT.getNode(subsection).element == null){
                    emptyBeforeMe++;
                }
                if(APP.NDT.getNode(subsection).element != null && subsection == theRealId){
                    subsectionsBeforeMe = index + 1 - emptyBeforeMe;
                }
            });
        }
        
        return subsectionsBeforeMe;
    }
    
    componentDidUpdate(prevProps, prevState, snapshot){
        if ( this.state.val == undefined || this.state.subsectionsBeforeMe != this.getNewsubsectionsBeforeMe() ){
            this.process();
        }
    }
    
    process(){
        let updatedSectionsScope = APP.NDT.scope.sections;
        let val = null;
        let parentSection = null;
        let subsectionsBeforeMe = 0;
        
        // we had to pull this out again?
        let theRealId = this.props.rootStructure.injectTo;
        let theSubsection = this.props.subsectionOf;        
        
        if(theSubsection){
            parentSection = APP.NDT.scope.sections[theSubsection];
            if(parentSection == undefined){
                if(this.props._loopIndex != undefined && theSubsection){
                    theSubsection = theSubsection + "-loop-" + this.props._loopIndex;
                    parentSection = APP.NDT.scope.sections[theSubsection];
                }
            }
            
            if(parentSection == undefined){
                console.log("We referenced something that isn't a section somehow. This is very bad.");
            }
        }
        
        if(parentSection != null){
            let parentNumber = parentSection.value;
            
            if(parentNumber.lastIndexOf(".0") > -1){
              //strip off the trailing .0 if it exists
                parentNumber = parentNumber.substring(0, parentNumber.lastIndexOf(".0"));
            }
            
            //then in NDT we need to add theRealId to parentSections subsections and create a hash element for theRealId
            if(updatedSectionsScope[theSubsection].subsections.indexOf(theRealId) < 0){
                updatedSectionsScope[theSubsection].subsections.push(theRealId);
            }
            
            let emptyBeforeMe = 0;
            _.forEach(parentSection.subsections, (subsection, index)=>{
                if(APP.NDT.getNode(subsection).element == null){
                    emptyBeforeMe++;
                }
                if(APP.NDT.getNode(subsection).element != null && subsection == theRealId){
                    subsectionsBeforeMe = index + 1 - emptyBeforeMe;
                }
            });
            
            val = parentNumber + "." + subsectionsBeforeMe;
        } else {
            let rootSections = 0;
            _.forEach(APP.NDT.scope.sections, (sVal, sKey)=>{
                if(sVal.value.lastIndexOf(".0") > -1){
                    rootSections++;
                }
            });
            
            val = (rootSections + 1) + "." + 0;
        }
        
        updatedSectionsScope[theRealId] = {value: val, content: this.props.rootStructure.options.content, subsections: [], backdoor: this.backdoor, injectedTo: APP.NDT.injected[this.getTheRealId()]};
        
        if (val != null && this.state.val != val){
            //update my local scope value
            this.props.announceScope(null, updatedSectionsScope, "sections");
            this.setState({val: val, subsectionsBeforeMe: subsectionsBeforeMe});
        }
    }
}