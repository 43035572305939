//import './AddFriendInput.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BoundComponent from './BoundComponent';
import * as _ from 'lodash';

export default class TextArea extends BoundComponent {

  static propTypes = {
    update: PropTypes.func,
    property: PropTypes.string,

  };

  constructor(props, context) {
	  super(props, context);
	  if(this.props.field == undefined || this.props.field === "passCrypt" || this.props.field === "confirmPassCrypt" ){
		  this.state = {
				  value: ""
		  };
	  } else {
		  if (this.props.obj instanceof Component && this.props.obj.state != null){
			  this.state = {
					  value: this.props.obj.state[this.props.field]
			  }
		  }else{
			  this.state = {
					  value: this.props.obj[this.props.field] //this.props.obj[this.props.property]
			  };
		  }
	  }
	  
	  this.handleChange = this.handleChange.bind(this);
	  if (props.update){
		  this.debouncedUpdate = _.debounce(props.update, 500);
	  }
  }
	handleBlur(e){
		this.props.update(this.props.obj, this.props.field, e.target.value);
	}
	
  handleChange(e) {
    if(this.props.field != undefined && this.props.field != "confirmPassCrypt"){
    	this.setState({ value: e.target.value });
    } else {
    	this.setState({ value: e.target.value });
    }
  }

  render() {
    var name = this.props.name? <label>{this.props.name}</label> : null;
    var inputType = "text";
    
    return (
      <div className={(this.props.className || "col-xs-12 col-md-12") }>
      {name}
      <textarea
        className="form-control"
        placeholder={this.props.placeholder}
        value={this.state.value == null? "" : this.state.value}
        onChange={this.handleChange}
        onBlur={(e)=>this.handleBlur(e)}
      	disabled={this.props.disabled? "disabled" : ""}/> 
      </div>
    );
  }
}
