import React, { Component } from 'react';

import { DataContext } from  '../../../OpidCore/public/js/components/Bound';

import * as _ from 'lodash';
import moment from 'moment';

export default class TimestampInput extends Component {
  static contextType = DataContext;	

	render(){
		var displayValue = this.context.magicalGet(this.props.field);
		var thetime = moment( displayValue );
		if (thetime.isValid()){
			displayValue = thetime.format(this.props.format || "hh:mma");
		}
		return <div className="field">
			<label>{this.props.name}</label>
			<div className="value">{displayValue}</div>
		</div>
		 
	}
}
