export const ADD_FRIEND = 'ADD_FRIEND';
export const STAR_FRIEND = 'STAR_FRIEND';
export const DELETE_FRIEND = 'DELETE_FRIEND';

export const UPDATE_MODEL = 'UPDATE_MODEL';

export const SAVE_MODEL = 'SAVE_MODEL';

export const REQUEST_DATA_FROM_SERVER = 'REQUEST_DATA_FROM_SERVER';

export const PROCESS_DATA_FROM_SERVER = 'PROCESS_DATA_FROM_SERVER';

export const PROCESSING_DATA = 'PROCESSING_DATA';

export const NEW_MODEL = 'NEW_MODEL';

export const PROCESSING_DATA_DONE = 'PROCESSING_DATA_DONE';

export const PROCESS_SAVE_RESULT_FROM_SERVER = 'PROCESS_SAVE_RESULT_FROM_SERVER';

export const CENTRAL_REFRESH_NEEDED = 'CENTRAL_REFRESH_NEEDED';

export const CENTRAL_REFRESH_DONE = 'CENTRAL_REFRESH_DONE';

export const CENTRAL_REFRESH_CLEAR = 'CENTRAL_REFRESH_CLEAR';