import React, { Component } from 'react';
import templated from './Templated.jsx';

export default class Diagram extends Component{
    constructor(props) {
        super(props);
    }
    
    static getEditableProps(){
        return [
            {niceName: "Title", prop: "title", type: "text", editInPlace: true}
        ]
        
    }
    
    injectNext(){
        const struct = this.props.NDT.find(this.props.root);
        
        if(this.props.title == undefined || this.props.title.trim().length <= 0){
            return undefined;
        }
        
        return {
            children: null,
            cid: struct.theId + "-diagram",
            component: "DiagramCounter",
            connections: [],
            connectors: null,
            next: null,
            options: null,
            returns: null,
            theId: struct.theId + "-diagram",
            type: "MetaTalker",
            uid: struct.uid + (APP.NDT.structure.length + 1)
        };
    }
    
    static staticInjectNext(props){
        const struct = APP.NDT.find(props.root);
        
        if(props.title == undefined || props.title.trim().length <= 0){
            return undefined;
        }
        
        return {
            children: null,
            cid: struct.theId + "-diagram",
            component: "DiagramCounter",
            connections: [],
            connectors: null,
            next: null,
            options: null,
            returns: null,
            theId: struct.theId + "-diagram",
            type: "MetaTalker",
            uid: struct.uid + (APP.NDT.structure.length + 1)
        };
    }
    
    render(){
        let newThis = {...this};
        if(newThis.props.scope && newThis.props.scope.diagram_number){
            newThis.props.scope.diagram_number = "Diagram " + newThis.props.scope.diagram_number + "";
        }
            
        var title = templated(this.props.title || "", newThis, (title)=>{this.props.handleInlineChange({content: title})});
        
        return <div>
            {this.props.children}
            <span className="diagram_title">{title}</span>
        </div>;
    }    
}