import React, { useEffect, useState } from "react";
import { Bound, Button, useMagic, LookupInputSelect} from "@opidcore/components";
import moment from "moment";

//import { useData } from '@opidcore/hooks/WTF';

import { SimpleMonthPicker } from "../../components";
//import { ReportTemplateLookupSelector } from "../Customer/EditClient";

export default function ExportInvoice() {
  const bound = useMagic({ period: moment().format("YYYY-MM") });
  const [customers, setCustomers] = useState([]);

  const [umbrella,setUmbrella] = useState(null);

  useEffect(() => {
    bound.magicalState('umbrella', setUmbrella);
  }, [bound]);


  useEffect(() => {
    if (umbrella > ''){
      APP.central.Customer.umbrellaClients(umbrella).then( (r)=>{
        setCustomers(r.result.rows);
      });
    }
  }, [umbrella]);


  const doExcel = (clientId) => {
    APP.central.Reports.exportTesting(bound.to.period, false, clientId, false).then((r) => {
      APP.socket.send(JSON.stringify(r));
    });
  };

  const doExcelPosted = (clientId) => {
    APP.central.Reports.exportTesting(bound.to.period, false, clientId, true).then((r) => {
      APP.socket.send(JSON.stringify(r));
    });
  }

  const doExcelInvoice = (clientId) => {
    APP.central.Reports.exportTesting(bound.to.period, true, clientId, false).then((r) => {
      APP.socket.send(JSON.stringify(r));
    });
  };

  const doExcelInvoicePosted = (clientId) => {
    APP.central.Reports.exportTesting(bound.to.period, true, clientId, true).then((r) => {
      APP.socket.send(JSON.stringify(r));
    });
  };

  return (
    <div>
      <h1>Export AR Invoices</h1>
      <Bound to={bound}>

        <LookupInputSelect what="lookup" field="umbrella" name="Umbrella" appendable={true} style={{width: "10%"}}/>

        <h3>Billing Period</h3>
        {/** <ReportTemplateLookupSelector label="Detail Level"/> **/}
        <div className="somethingsomething" style={{maxWidth: "300px"}}>
          <SimpleMonthPicker field="period" />
        </div>
        
        {_.map(customers, (c)=><div className="customerInvoice" key={c.id} style={{width: "100%", outline: "solid", padding: "1em 1em 1em 1em"}}>
                <strong>{c.name}</strong>
                <div style={{display: "flex", flex: "1"}}>
                    <Button style={{marginLeft: "10px", marginRight: "10px"}} onClick={()=>doExcel(c.id)}>Export Excel</Button>
                    <Button style={{marginLeft: "10px", marginRight: "10px"}} onClick={()=>doExcelPosted(c.id)}>Export Excel &#40;+Posted&#41;</Button>
                    <Button style={{marginLeft: "10px", marginRight: "10px"}} onClick={()=>doExcelInvoice(c.id)}>Post to Xero</Button>
                    <Button style={{marginLeft: "10px", marginRight: "10px"}} onClick={()=>doExcelInvoicePosted(c.id)}>Post to Xero &#40;+Posted&#41;</Button>
                </div>
            </div>
        )}

      </Bound>
    </div>
  );
}
