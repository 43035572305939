import React, { useEffect, useState } from 'react';
import { Bound, BoundCommit, FlexBreak, FlexRow, InputCheckbox, InputText } from '@opidcore/components';
import { Tabular, TabularColumn } from '@opidcore/components/Tabular';
import { useData, useNavBar } from '@opidcore/hooks/WTF';
import * as _ from 'lodash';


import LookupInputSelect from '@opidcore/components/LookupInputSelect';
import { FileLink, SelectClientSiteService, StatusBlock } from '../../components';

import { NiceNumber } from '../Nice.jsx';
 
function LineEdit(props){
	
	return <div>
		<h2>Line #{props.me+1} Details</h2>
		<BoundCommit initialValue={props.row} commit={props.save}>
	
		<LookupInputSelect what="lookup:lineStatusAR" field="sustainabilityStatus" name="Status"/>
		<InputText field="sustainabilityNotes" name="Notes"/>			
				
		<Bound to={props.row.itemAfter}>							
			<InputText field="reportMetricTonne" name="Metric Tons"/>
			<InputCheckbox field="reportExclude" name="Report Override"/>
				
			<LookupInputSelect what="lookup" field="activity" name="Activity"/>
			<SelectClientSiteService/>
			
			<InputText field="description" name="Description"/>
		</Bound>
		
		</BoundCommit>
	</div>
}

export default function EditSustainability(props){
    const nav = useNavBar("Sustainability Data");
    const [inv, setInv] = useState({});
    const [refresh, setRefresh] = useState(0);
    const [raw, setRaw] = useState("");
    const [data, dataSet] = useData("customer", parseInt(props.match.params.id));
    
    const clientId = parseInt(props.match.params.id);
    
    
    useEffect(()=>{
    	
    	APP.central.Sustainability.getData(clientId).then((r)=>{
    		
    		setInv(r.result);
    	});
    	
    }, [clientId]);
    
    const multiCheckUpdater = (obj, field, value)=>{
        debugger
        console.log(obj, " " + field + " " + value);
    };
    
    const combineAllAttachments = {yes: "Yes", no: "No"};
          
    const testInvoice = inv.lines ? inv.lines : [];
    /** const columns = [{title: 'description', col: 'description'},
    	{title: 'Quantity', col: 'quantity'},
    	{title: 'Total', col: 'lineTotal'}
    ]; **/
    /** <Grid data={testInvoice} columns={columns}/> **/
    
    const columns = [{title: 'Status', col: 'description'},
    	{title: 'Quantity', col: 'quantity'},
    	{title: 'Total', col: 'lineTotal'}
    ];
    
    const saveLine = (a,b,c,rowId)=>{
    	const id = a;
    	const changes = b;
    	const them = c;
    	const newInv = _.cloneDeep(inv);
    	APP.central.ARInvoice.prepLine(them).then((r)=>{
    			let rowIdx = -1;
    			if (rowId > 0){
    				rowIdx = _.findIndex( newInv.lines, (l)=>l.id == rowId);
    			}
    			if (r.result != null){
    				newInv.lines[rowIdx] = r.result;
    			}
    	    	setInv(newInv);
    	    	setRefresh(refresh+1);
    		});
    }
    
    const openLine = (a,r)=>{
    	
    	APP.instance.createModal(<LineEdit row={a} me={r} save={(a,b,c)=>saveLine(a,b,c,r)}/>);
    };
    
     const totalMT = inv.lines ? _.sum( inv.lines.map(l=>l.itemAfter ? parseFloat(l.itemAfter.reportMetricTonne) : 0) ) : 0;
     
    
    // const totalTax = inv.lines ? _.sum(_.flatten( inv.lines.map( (l)=>_.values(l.itemAfter.taxes) ) )) : 0;

    return <div key="edit_invoice" className="basicForm">

	<BoundCommit initialValue={inv} commit={APP.central.ARInvoice.saveChangesFull} toolbar={<StatusBlock status="Approve"/>}>
	
        	<FlexRow>
        		<StatusBlock status="Data Entry"/>
        	
        		<FileLink file="?" text="View Enviro Report"/>
            </FlexRow>
            	
            <FlexBreak/>
            <h2>Line Items</h2>
            
            <FlexBreak/>

    		<div className="block blueish" onClick={()=>openLine({itemAfter:{}}, inv.lines.length)}>Add Line</div>
    		
        	<Tabular data={inv.lines ? _.filter(inv.lines, (l)=>l.itemAfter != null) : []} loadedCount={refresh}>
        		<TabularColumn data={(a)=>a.sustainabilityStatus} title="Status"/>
        		<TabularColumn data={(a)=>(a.itemAfter && a.itemAfter.service ? a.itemAfter.service : '?') + " " + ( a.itemAfter ? a.itemAfter.description : "") } title="Site"/>
        		<TabularColumn data={(a)=>a.itemAfter && a.itemAfter.activity} title="Activity Type"/>
      			<TabularColumn data={(a)=>a.itemAfter && a.itemAfter.description} title="Description"/>

        		<TabularColumn data={(a)=>a.itemAfter && a.itemAfter.reportMetricTonne} title="MT"/>
        		<TabularColumn data={(a)=>a.itemAfter && a.itemAfter.reportExclude} title="MT?"/>
        		<TabularColumn data={(a)=>a.sustainabilityNotes} title="Notes"/>

        		<TabularColumn data={(a,r)=><div><a onClick={()=>openLine(a,a.id)}>edit line {a.id}</a></div>} title="..."/>
        	</Tabular>

        	<FlexRow>
        		<InputText type="" name="Notes" className=""/>
        		<table className="balancing">
        			<thead>
        				<tr>
        					<th>Total MT</th>
        				</tr>
        			</thead>
        			<tbody>
        				<tr>
        					<td><NiceNumber>{totalMT}</NiceNumber></td>
        				</tr>
        			</tbody>
        		</table>
           	</FlexRow>
           	
           
        </BoundCommit>
        
        <div className="clearStuff">
        
     	
    	</div>
		
		
        <pre>{raw}</pre>
    </div>;
}