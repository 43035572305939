import React, {  useEffect, useRef, useState } from 'react';
import _ from 'lodash';
//import { useLocation } from 'react-router';
import { useChromeBlockProvider } from '@opidcore/hooks/WTF';
import { Bound, Button, InputText } from '@opidcore/components';


const BarePDF = (props) => {
    const report = useRef(null);
    const [branding, setBranding] = useState('wsc');
    const [reportArgs, setReportArgs] = useState(null);
    const [gotArgs, setGotArgs] = useState(0);
    const chromeBlock = useChromeBlockProvider();

    const testArgs = useRef({});
    const match = props.match;
    const ReportElementType = window._THE_GLOBAL_TYPES[match.params.name];

    useEffect(()=>{
        document.body.classList.add("printing");
        chromeBlock.ready();

        if (window.localStorage.getItem("lastArgs") != null){
            testArgs.current = { json: window.localStorage.getItem("lastArgs") };
        }

        if (window.location.search.indexOf("run") >= 0){
            applyArgs();
        }

        APP.getState("reportArgs", setReportArgs);
	}, []);

    useEffect( ()=>{
        if (_.size(reportArgs) > 0){
            report.current = React.createElement( ReportElementType, reportArgs );
            setGotArgs(Date.now());
        }
    }, [reportArgs]);

    const applyArgs = ()=>{
        const json = testArgs.current.json;
        window.localStorage.setItem("lastArgs", json);
        const args = JSON.parse(json);
        setReportArgs(args);
    }

    return <div className="clean-and-bare">
        <div className={"pdf-report report-brand-" + branding }>
            {report.current}
            {gotArgs == 0 ? 
                <Bound to={testArgs.current}>
                    <InputText name="Raw Args (JSON)" field="json"/>
                    <Button onClick={applyArgs}>Apply</Button>
                </Bound>
                : null
            }
            <div className="noprint">{gotArgs}</div>
        </div>
    </div>
};

export default BarePDF;