import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { bindActionCreators } from 'redux';

var _ = require('lodash');



class SaveToolbar extends Component {
	  constructor(props, context) {
	        super(props, context);
	        this.state = {
	        };
	    }
	  
	save(){
	    var that = this;
		this.setState({'status': 'Saving'});
		var ret = this.props.handleSave(()=>{
		    that.setState({'status': 'Saved!'});
			setTimeout(()=>that.setState({'status': null}, ()=>{ APP.customize.saveAll(); }), 2500);
		});
		
	}
    render(){
    	var className = "";
    	
    	if (this.state.status == "Saving"){
    		className = "saving";
    	}
    	let cancelButton = null;
    	if(this.props.handleCancel){
    	    cancelButton = <button type="button" className="btn cancel" onClick={()=>this.props.handleCancel()}>Cancel</button>;
    	}
    	
        return  <div className={"saveToolbar " + className } >
        
            <button type="button" className="btn" onClick={()=>this.save()}>Save</button>
            <div className="status"><span>{this.state.status}</span></div>
            { this.props.message ? 
                    <h4>{this.props.message}</h4> :
                        null
            }
            {cancelButton}
            {this.props.children}
        </div>
    }
    
}

function mapStateToProps(state) {
    return {
      message: state.central.displayMessage
    };
  }

  function mapDispatchToProps(dispatch) {
      
    return {     
    };
  }
  
    export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(SaveToolbar);
