import React, { useContext, useEffect, useRef, useState } from 'react';
import { useData, useMagic } from '@opidcore/hooks/WTF';
import { Icon, Loading, BoundCommit, InputText, InputSelect, InputMultiCheckbox, LookupInputSelect, BoundDataContext, InputDecimal } from "@opidcore/components";
import _ from 'lodash';
import MyDatePicker from '@opidcore/components/DatePicker'; 

const PriceIncreaseEdit = ({id}) => {
    const boundMagic = useMagic({});
    
    const [clientContractOptions, setClientContractOptions] = useState({data: [], loading: false, clientId: 0});
    const [clientContractLines, setClientContractLines] = useState({data: [], loading: false, contractId: 0});
    const [vendorContractOptions, setVendorContractOptions] = useState({data: [], loading: false, vendorId: 0});
    const [vendorContractLines, setVendorContractLines] = useState({data: [], loading: false, contractId: 0});
    const [activityOptions, setActivityOptions] = useState([]);
    const [materialOptions, setMaterialOptions] = useState([]);
    const [activeType, setActiveType] = useState(null);
    const [activeRelatedId, setActiveRelatedId] = useState(null);
    const [priceIncreaseId, setPriceIncreaseId] = useState(boundMagic.magicalGet("id", 0));

    const stateRef = useRef({
        clientContractOptions: null,
        clientContractLines: null,
        vendorContractOptions: null,
        vendorContractLineOptions: null,
        activeType: null,
        activityOptions: null,
        materialOptions: null,
        serviceContractLine: null
    });

    useEffect(()=>{
        APP.central.ContractLineIncrease.fetch(id).then((r)=>{
            boundMagic.replaceTo(r.result);
        });
    }, []);

    useEffect(()=>{
        if(activeRelatedId != undefined){
            if(boundMagic.magicalGet("relatedType") == "ExcelUpload"){
                APP.central.Service.fetchServiceContractLine(activeRelatedId).then((r)=>{
                    if(r.result != undefined && r.result.service.customer != undefined && r.result.service.customer != ""){
                        stateRef.current.serviceContractLine = r.result;

                        boundMagic.magicalSet("client", r.result.service.customer);
                        boundMagic.magicalSet("site", r.result.service.site.id);
                        boundMagic.magicalSet("service", r.result.service);
                    }
                });
            }

            if(boundMagic.magicalGet("relatedType") == "ClientContract"){
                APP.central.ClientContract.fetch(boundMagic.magicalGet("relatedId")).then((r)=>{
                    boundMagic.magicalSet("client", r.result.customer.id);
                    boundMagic.magicalSet("clientContract", r.result.id);
                    boundMagic.magicalSet("clientContractLine", null);
                });
            } else if(boundMagic.magicalGet("relatedType") == "ClientContractLine"){
                APP.central.ClientContract.fetch2(boundMagic.magicalGet("relatedId")).then((r)=>{
                    boundMagic.magicalSet("client", r.result.service.customer);
                    boundMagic.magicalSet("clientContract", r.result.clientContractId);
                    boundMagic.magicalSet("clientContractLine", r.result.id);
                });
            }

            if(boundMagic.magicalGet("relatedType") == "VendorContract"){
                APP.central.VendorContract.fetch(boundMagic.magicalGet("relatedId")).then((r)=>{
                    boundMagic.magicalSet("vendor", r.result.vendor.id);
                    boundMagic.magicalSet("vendorContract", r.result.id);
                    boundMagic.magicalSet("vendorContractLine", null);
                });
            } else if(boundMagic.magicalGet("relatedType") == "VendorContractLine"){
                APP.central.ClientContract.fetch2(boundMagic.magicalGet("relatedId")).then((r)=>{
                    boundMagic.magicalSet("vendor", r.result.vendor);
                    boundMagic.magicalSet("vendorContract", r.result.vendorContractId);
                    boundMagic.magicalSet("vendorContractLine", r.result.id);
                });
            }
        }
    }, [activeRelatedId]);

    useEffect(()=>{
        if(clientContractOptions.loading == true){
            APP.central.ClientContract.getContractForClient(clientContractOptions.clientId).then((r)=>{
                setClientContractOptions({data: [r.result], loading: false, clientId: clientContractOptions.clientId});
            });
        }
    }, [clientContractOptions.loading]);

    useEffect(()=>{
        if(clientContractLines.loading == true){
            APP.central.ClientContract.fetchContractLines(clientContractLines.contractId).then((r)=>{
                setClientContractLines({data: r.result, loading: false, contractId: clientContractLines.contractId});
            })
        }
    }, [clientContractLines.loading]);

    useEffect(()=>{
        if(vendorContractOptions.loading == true){
            
            APP.central.VendorContract.getContractForVendor(vendorContractOptions.vendorId).then((r)=>{
                setVendorContractOptions({data: [r.result], loading: false, vendorId: vendorContractOptions.vendorId});
            });
        }
    }, [vendorContractOptions.loading]);

    useEffect(()=>{
        if(vendorContractLines.loading == true){
            APP.central.VendorContract.fetchContractLines(vendorContractLines.contractId).then((r)=>{
                setVendorContractLines({data: r.result, loading: false, contractId: vendorContractLines.contractId});
            })
        }
    }, [vendorContractLines.loading]);
    

    useEffect(()=>{
        stateRef.current = {
            clientContractOptions: clientContractOptions.data,
            clientContractLines: clientContractLines.data,
            vendorContractOptions: vendorContractOptions.data,
            vendorContractLineOptions: vendorContractLines.data,
            activeType: activeType,
            activityOptions: activityOptions,
            materialOptions: materialOptions,
            serviceContractLine: stateRef.current.serviceContractLine
        };
    }, [clientContractOptions, clientContractLines, vendorContractOptions, vendorContractLines, activeType, activityOptions, materialOptions]);

    useEffect(()=>{
        if(boundMagic != null){
            boundMagic.magicalState("id", (val)=>{
                setPriceIncreaseId(val == "" || val == undefined? 0 : parseInt(val));
            });

            boundMagic.magicalState("relatedType", (val)=>{
                setActiveType(val == ""? null : val);
            });

            boundMagic.magicalState("relatedId", (val)=>{
                setActiveRelatedId(val == ""? null : val);
            });

            boundMagic.magicalState("service", (val)=>{
                const serviceContractLine = stateRef.current.serviceContractLine;
                let newActivities = [];
                let newMaterials = [];

                if(val != undefined && val != ""){
                    if(serviceContractLine != undefined && serviceContractLine != "" && stateRef.current.activeType == "ExcelUpload"){
                        if(val != undefined && val.materialName != undefined){
                            newMaterials.push({key: val.materialName, label: val.materialName});
                        }
                        
                        _.forEach(serviceContractLine.items, (item)=> {
                            newActivities.push({key: item.activity, label: item.activity});
                        });

                        newMaterials =_.uniqBy(newMaterials, "key");
                        newActivities = _.uniqBy(newActivities, "key");
                    }
                }

                setMaterialOptions(newActivities);
                setActivityOptions(newMaterials);
            });

            boundMagic.magicalState("client", (val)=>{
                if(val != undefined && val != "" && val > 0){
                    setClientContractOptions({data: [], loading: true, clientId: val});
                } else {
                    setClientContractOptions({data: [], loading: false, clientId: val});
                }
            });

            boundMagic.magicalState("clientContract", (val)=>{
                let lines = null;
                let newActivities = [];
                let newMaterials = [];

                if(val != undefined && val != ""){
                    const cc = _.find(stateRef.current.clientContractOptions, {id: parseInt(val)});
                    if(cc != undefined){
                        lines = cc.lines;
                    }
                    
                    _.forEach(lines, (line)=>{
                        if(line.service != undefined && line.service.materialName != undefined){
                            newMaterials.push({key: line.service.materialName, label: line.service.materialName});
                        }
                        
                        _.forEach(line.items, (item)=> {
                            newActivities.push({key: item.activity, label: item.activity});
                        });
                    });
                }
                
                setMaterialOptions(_.uniqBy(newMaterials, "key"));
                setActivityOptions(_.uniqBy(newActivities, "key"));
                setClientContractLines({data: [], loading: true, contractId: val});
            });

            boundMagic.magicalState("clientContractLine", (val)=>{
                let newActivities = [];
                let newMaterials = [];

                if(val != undefined && val != ""){
                    const line = _.find(stateRef.current.clientContractLines, {id: parseInt(val)});
        
                    if(line != undefined){
                        _.forEach(line.items, (item)=> {
                            newActivities.push({key: item.activity, label: item.activity});
                        });

                        if(line.service != undefined && line.service.materialName != undefined){
                            newMaterials.push({key: line.service.materialName, label: line.service.materialName});
                        }
                    }
                }

                setMaterialOptions(_.uniqBy(newMaterials, "key"));
                setActivityOptions(_.uniqBy(newActivities, "key"));
            });

            boundMagic.magicalState("vendor", (val)=>{
                if(val != undefined && val != "" && val > 0){
                    setVendorContractOptions({data: [], loading: true, vendorId: val});
                } else {
                    setVendorContractOptions({data: [], loading: false, vendorId: val});
                }
            });

            boundMagic.magicalState("vendorContract", (val)=>{
                let lines = null;
                let newActivities = [];
                let newMaterials = [];

                if(val != undefined && val != "" && val > 0){
                    const vc = _.find(stateRef.current.vendorContractOptions, {id: parseInt(val)});
                    if(vc != undefined){
                        lines = vc.lines;
                    }

                    _.forEach(lines, (line)=>{
                        if(line.service != undefined && line.service.materialName != undefined){
                            newMaterials.push({key: line.service.materialName, label: line.service.materialName});
                        }
                        
                        _.forEach(line.items, (item)=> {
                            newActivities.push({key: item.activity, label: item.activity});
                        });
                    });
                }
                
                setMaterialOptions(_.uniqBy(newMaterials, "key"));
                setActivityOptions(_.uniqBy(newActivities, "key"));
                setVendorContractLines({data: [], loading: true, contractId: val});
            });

            boundMagic.magicalState("vendorContractLine", (val)=>{
                let newActivities = [];
                let newMaterials = [];

                if(val != undefined && val != ""){
                    const line = _.find(stateRef.current.vendorContractLineOptions, {id: parseInt(val)});
        
                    if(line != undefined){
                        _.forEach(line.items, (item)=> {
                            newActivities.push({key: item.activity, label: item.activity});
                        });

                        if(line.service != undefined && line.service.materialName != undefined){
                            newMaterials.push({key: line.service.materialName, label: line.service.materialName});
                        }
                    }
                }

                setMaterialOptions(_.uniqBy(newMaterials, "key"));
                setActivityOptions(_.uniqBy(newActivities, "key"));
            });
        }
    }, [boundMagic]);

    if(boundMagic.to == null){
        return <Loading />;
    }

    const resetBound = () => {
        if(priceIncreaseId == 0 || priceIncreaseId == undefined || priceIncreaseId == ""){
            boundMagic.magicalSet("relatedActivityMappingId", null);
        }

        boundMagic.magicalSet("relatedId", null);
        boundMagic.magicalSet("client", null);
        boundMagic.magicalSet("clientContract", null);
        boundMagic.magicalSet("clientContractLine", null);
        boundMagic.magicalSet("vendor", null);
        boundMagic.magicalSet("vendorContract", null);
        boundMagic.magicalSet("activities", null);
        boundMagic.magicalSet("materials", null);
        boundMagic.magicalSet("site", null);
        boundMagic.magicalSet("service", null);
        boundMagic.magicalSet("effectiveDate", null);
        boundMagic.magicalSet("notes", null);
        boundMagic.magicalSet("clientIncreaseType", null);
        boundMagic.magicalSet("clientIncreaseValue", null);
        boundMagic.magicalSet("vendorIncreaseType", null);
        boundMagic.magicalSet("vendorIncreaseValue", null);
        boundMagic.magicalSet("baselineIncreaseType", null);
        boundMagic.magicalSet("baselineIncreaseValue", null);
    }

    const typeOptions = _.map(boundMagic.magicalGet("increaseTypes"), (it) => {
        return {key: it, value: labelizer(it)};
    });

    const doSave = () => {
        const deltas = boundMagic.getAllDeltas();
        
        if(deltas != undefined && deltas.length > 0){
            delete deltas[0].delta["client"];
            delete deltas[0].delta["site"];
            delete deltas[0].delta["service"];
            delete deltas[0].delta["vendor"];
            delete deltas[0].delta["vendorContract"];
            delete deltas[0].delta["vendorContractLine"];
            delete deltas[0].delta["client"];
            delete deltas[0].delta["clientContract"];
            delete deltas[0].delta["clientContractLine"];
            
            APP.central.Bound.saveChanges(deltas).then(()=>{
                _GLOBAL_DATA_CACHE["price-increases"].fetch();                
            });
        }
    }

    const downloadFile = (fileId) => {
        if(fileId == undefined){
            APP.alert("No file found");
            return;
        }

        APP.central.ContractLineIncrease.downloadExcel(fileId).then((r) => {
			window.open("/file/download/" + r.result.storageIdentifier + "/" + r.result.filename);
		});
    }
    
    return <div className="price_increase_edit">
        <h2>Price Increase Edit</h2>        

        <BoundCommit to={boundMagic.to} commit={doSave} closeOnCommit={true} className="price_increase_edit_bound">
            <div className="chunk">
                <InputSelect field="relatedType" name="Applicable To" options={boundMagic.magicalGet("relatedTypes")} onChange={()=>resetBound()} disabled={priceIncreaseId > 0}/>
                {activeType == "ExcelUpload" ? <Icon icon="file-excel" size="2x" onClick={() => downloadFile(boundMagic.magicalGet("attachment"))} /> : null}
            </div>

            <div className="chunk">
                <MyDatePicker field="effectiveDate" name="Effective Date"/>
                {activeType == "ExcelUpload" ? <InputText field="relatedActivityMappingId" name="Activity Mapping Id" disabled={priceIncreaseId > 0}/> : null}
            </div>

            <div className="chunk">
                {activeType == "ExcelUpload" ? <ClientSiteService serviceField="relatedId"/> : null}
            </div>

            <div className="chunk">
                {activeType != undefined && activeType.indexOf("Client") < 0 ? null : <LookupInputSelect what="customer" field="client" name="Client" showEmpty={true} />}
                {activeType != undefined && activeType.indexOf("Client") < 0 ? null : clientContractOptions.loading == true? <Loading /> : <InputSelect field="clientContract" name="Client Contract" showEmpty={true} options={_.map(clientContractOptions.data, (c) => { return { key: c.id, value: c.friendlyId + " effective: " + c.effectiveFrom } })} />}
                {activeType != undefined && activeType.indexOf("Client") < 0 ? null : (clientContractOptions.loading == true || clientContractLines.loading == true) ? <Loading /> : <InputSelect field="clientContractLine" name="Client Contract Line" showEmpty={true} options={_.map(clientContractLines.data, (cl) => { return { key: cl.id, value: cl.site.name + ": " + cl.service.name } })} disabled={activeType == "ClientContract"} />}
    
                {activeType != undefined && activeType.indexOf("Vendor") < 0 ? null : <LookupInputSelect what="vendor" field="vendor" name="Vendor" showEmpty={true} />}
                {activeType != undefined && activeType.indexOf("Vendor") < 0 ? null : vendorContractOptions.loading == true? <Loading /> : <InputSelect field="vendorContract" name="Vendor Contract" showEmpty={true} options={_.map(vendorContractOptions.data, (c)=>{ return {key:c.id, value: c.friendlyId + " effective: " + c.effectiveFrom}})}/>}
                {activeType != undefined && activeType.indexOf("Vendor") < 0 ? null : vendorContractOptions.loading == true || vendorContractLines.loading == true? <Loading /> : <InputSelect field="vendorContractLine" name="Vendor Contract Line" showEmpty={true} options={_.map(vendorContractLines.data, (cl)=>{ return {key:cl.id, value: cl.site.name + ": " + cl.service.name}})} disabled={activeType == "VendorContract"}/>}
            </div>

            <div className="chunk">
                {activeType != undefined ? 
                   <div className="client-values">
                        <h4>Client Increase</h4>
                        <InputSelect field="clientIncreaseType" name="Type" options={typeOptions} />
                        <InputDecimal field="clientIncreaseValue" name="Value" />
                    </div> : null }
                
                {activeType != undefined && (activeType.indexOf("Vendor") > -1 || activeType == "ExcelUpload") ?
                    <div className="vendor-values">
                        <h4>Vendor Increase</h4>
                        <InputSelect field="vendorIncreaseType" name="Type" options={typeOptions}/>
                        <InputDecimal field="vendorIncreaseValue" name="Value"/>
                    </div> : null }

                {activeType != undefined ? 
                    <div className="baseline-values">
                        <h4>Baseline Increase</h4>
                        <InputSelect field="baselineIncreaseType" name="Type" options={typeOptions} />
                        <InputDecimal field="baselineIncreaseValue" name="Value" />
                    </div> :null }
            </div>

            <div className="chunk">
                <div>
                    <InputMultiCheckbox label="Activities" field="activities" optionsCollection={activityOptions} optionKey="key" storeAs="string" grouped={true}>
                        {(row) => {
                            return <div key={row.key + "|text"}>{row.label}</div>
                        }}
                    </InputMultiCheckbox>
                </div>
                <div>
                    <InputMultiCheckbox label="Materials" field="materials" optionsCollection={materialOptions} optionKey="key" storeAs="string" grouped={true}>
                        {(row) => {
                            return <div key={row.key + "|text"}>{row.label}</div>
                        }}
                    </InputMultiCheckbox>
                </div>
            </div>

            <div className="chunk">
                <InputText field="notes" name="Notes" className="notes" inputType="textarea"/>
            </div>
        </BoundCommit>        
    </div>;
}

const labelizer = (val) => {
    return _.map(val.replaceAll("_", " ").split(" "), (chunk) => {
        return chunk.substring(0, 1).toUpperCase() + (chunk.length > 1? chunk.substring(1, chunk.length) : "");
    }).join(" ");
}

const ClientSiteService = ({clientField = "client", siteField = "site", serviceField = "service"}) => {
    const boundMagic = useContext(BoundDataContext);
    const [clientOptions, clientOptionsSet] = useData("customer"); //, APP.central.Customer.list TO-DO: make this much quicker
    const [siteOptions, siteOptionsSet] = useData("site", {customer: boundMagic.magicalGet(clientField)});
    const [serviceOptions, serviceOptionsSet] = useData("service", {site: boundMagic.magicalGet(siteField)});

    const [loadingClients, setLoadingClients] = useState(true);
    const [loadingSites, setLoadingSites] = useState(true);
    const [loadingServices, setLoadingServices] = useState(true);

    const onChangeClient = (to, field, value, context) => {
        boundMagic.magicalSet(siteField, null);
        siteOptionsSet.fetch({options: {customer: value}});
    }

    const onChangeSite = (to, field, value, context) => {
        boundMagic.magicalSet(serviceField, null);
        serviceOptionsSet.fetch({options: {site: value}});
    }

    useEffect(()=>{
        if(clientOptionsSet.loading == false){
            setLoadingClients(false);
        } else {
            setLoadingClients(true);
        }
    }, [clientOptionsSet.loading]);

    useEffect(()=>{
        if(siteOptionsSet.loading == false){
            setLoadingSites(false);
        } else {
            setLoadingSites(true);
        }
    }, [siteOptionsSet.loading]);

    useEffect(()=>{
        if(serviceOptionsSet.loading == false){
            setLoadingServices(false);
        } else {
            setLoadingServices(true);
        }
    }, [serviceOptionsSet.loading]);

    return <>
        {loadingClients == true? <Loading /> : <InputSelect field={clientField} name="Client" options={_.map(clientOptions, (c)=>{ return {key: c.id, value: c.name}})} onChange={(to, field, value, context) => onChangeClient(to, field, value, context)}/>}
        {loadingSites == true? <Loading /> : <InputSelect field={siteField} name="Site" options={_.map(siteOptions, (s)=>{ return {key: s.id, value: s.name}})} onChange={(to, field, value, context) => onChangeSite(to, field, value, context)}/>}
        {loadingServices == true? <Loading /> : <InputSelect field={serviceField} name="Service" options={_.map(serviceOptions, (s)=>{ return {key: s.id, value: s.shortDescription}})} />}
    </>
}

export default PriceIncreaseEdit;