export default class ColourMapper {
    constructor(opts) {
        this.previous = {};
        this.usedColoursInSets = {};
    }
    
    bernoulli(){
        return Math.round(Math.random());
    }
    
    randomColour(){
        //http://bjlkeng.github.io/posts/sampling-from-a-normal-distribution/
        const sampleSize = 1000;
        
        let sampleDistributionSum = 0;
        
        for(var x=0; x<sampleSize; x++){
            sampleDistributionSum += this.bernoulli();
        }
        
        //randomNormalSample is a normal distribution sample around 0 with a standard deviation of 1 ~N(0,1)
        //tightness: how tight/similar I want the numbers to be. Larger number means less variation in colours
        //      **1 = -3.4 to +3.4 which is the standard range, 3.4 = -1 to +1, 6.8 = -0.5 to +0.5, etc.
        //shift: how far you want to blue shift a colour. 0 would start with red colours and 240 would start with blue colours
        
        //for a nice blue green distribution with minimal variation try tightness = 6.8 and shift = 100
        const tightness = 4;
        const shift = 200; 
        
        let randomNormalSample = 2.0 * Math.sqrt(sampleSize) * ((sampleDistributionSum / sampleSize) - 0.5);
        if(randomNormalSample < 0){
            randomNormalSample = randomNormalSample * -1;
        }
        
        randomNormalSample = Math.floor(360*(randomNormalSample/tightness)) + shift;
        
        console.log("sample: " + randomNormalSample);

        return "hsla(" + randomNormalSample + ",70%,50%,1)"; 
    }
    
    findOrGuess(key, colourSetName){
        if (this.previous[key] == null){
        	if(colourSetName != null){
        		//find which colours are unused and assign it to 'key'
        		const colourSet = APP.NDT.whatAreMyOptions(colourSetName);
        		if(key == null){
        			return null;
        		}
        		
        		if(colourSet != undefined){
	        		if(this.usedColoursInSets[colourSet.name] == undefined){
	        			//no colours in set assigned to use the first one
	        			this.previous[key] = colourSet.colours[0];
	        			this.usedColoursInSets[colourSet.name] = {};
	        			this.usedColoursInSets[colourSet.name][colourSet.colours[0]] = "used";
	        		} else {
	        			//find the first available colour in the colour set
		        		for(let i=0; i<colourSet.colours.length; i++){
		        			if(this.usedColoursInSets[colourSet.name][colourSet.colours[i]] == undefined){
		        				this.previous[key] = colourSet.colours[i];
		        				this.usedColoursInSets[colourSet.name][colourSet.colours[i]] = "used";
		        				break;
		        			}
		        		}
	        		}
        		}
        		
        		if(this.previous[key] == null){
        			//we used all the available colours so generate a random one
        			this.previous[key] = this.randomColour();
        		}
        	} else {
        		this.previous[key] = this.randomColour();
        	}
        }

        return this.find(key);
    }
    
    find(key){
        return this.previous[key];
    }
}