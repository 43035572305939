import React, { useEffect, useState, useContext, useRef }  from 'react';
import PropTypes from 'prop-types';
import { DataContext } from './Bound';
import * as _ from 'lodash';

/*
props:
    name
    central
    children
    className
    onSelect
*/

export default function InputUnboundAutoComplete(props) {
    const [optionsData, setOptionsData] = useState([]);
    const [value, setValue] = useState();
    const initialValue = useRef(null);
    
    const appStatePath = "autocomplete/" + props.field;
    
    const setFromAppState = ()=>{
    	const appState = APP.getState(appStatePath)
    	const item = appState && appState.find ? appState.find( (r)=>r.key == initialValue.current ) : null;
    	if (item){
    		setValue(item.displayValue);
    	}
    }
    
    const onChange = (e)=>{
        if(e.currentTarget.value.length > 0){
            if(props.central != undefined){        
                props.central(e.currentTarget.value, false, false).then((r)=>{
                    setOptionsData(r.result);
                });
            } else {
                console.log("Missing central function for auto complete of " + props.field);
            }
        }
        
        setValue(e.currentTarget.value);
    }
    
    const onSelect = (option)=>{
        let optionValue = null;
        if(isFinite(initialValue.current)){
            //the bound object uses id as it's value
            if(option.key){
                optionValue = option.key;
            } else {
                console.log("How did we get here. Something is very wrong");
            }
        } else {
            //we can assume the bound uses an object as it's value - or we don't care
            optionValue = option;
        }
        
        if(props.onSelect){
            props.onSelect(option);
        } else {
            console.log("Where did you plan on putting this value?")
        }
        
        setValue(option.displayValue);
        setOptionsData([]);
    }
    
    const optionsList = _.map(optionsData, (option, index)=>{
        let display = option;
        if(option.displayValue){
            display = option.displayValue;
        }
        
        if(props.children != undefined){
            display = props.children(option);
        }
        return <span key={option.id || index} onClick={(a)=>onSelect(option)}>
            {display}
        </span>
    });
    
    return <div key="input_auto_complete" className={"input_auto_complete field " + (props.className || "")}>
       {props.name? <label>{props.name}</label> : ""}
        <input type="text" onChange={(e)=>onChange(e)} value={value || ""}/>
        <div className="options">
            {optionsList}
        </div>
    </div>
}