import React, { useContext, useState } from 'react';
import { Bound, DataContext, InputDecimal, InputSelect } from '@opidcore/components';
import * as _ from 'lodash';
//import PropTypes from 'prop-types';

export default function FancyWeightInput(props) {
    const parentBound = useContext(DataContext);
    const [localBound, setLocalBound] = useState({metricTonne: parentBound.magicalGet("metricTonne"), weightUnits: "mt"})

    /*
    useEffect(() => {
        parentBound.magicalSet("tempMetricTonne", parentBound.magicalGet("metricTonne"))
        parentBound.magicalSet("weightUnits", "t")
    }, [])
    */

    const init = (bound) => {
        bound.magicalSet("weightUnits", "mt", {silent: true})
        setLocalBound(bound)
    }

    const recalculateAndUpdate = (bound) => {
        if (parentBound.magicalGet("line.estimateType") == "WSC Estimate" || parentBound.magicalGet("line.estimateType") == null || parentBound.magicalGet("line.estimateType") == "") {
            parentBound.magicalSet("line.estimateType", "Hauler Estimate")
            parentBound.magicalSet("line.reportExclude", true)
        }
        const currentWeight = bound.tempMetricTonne
        switch(bound.weightUnits) {
            case "mt": //just t
                parentBound.magicalSet("metricTonne", currentWeight)
                break
            case "lb": //lb to t
                parentBound.magicalSet("metricTonne", currentWeight / 2204.63)
                break
            case "kg": //kg to t
                parentBound.magicalSet("metricTonne", currentWeight / 1000)
                break
        }
        this.forceUpdate();
    }

    const recalculateAndUpdate2 = (bound) => {
        if (parentBound.magicalGet("line.estimateType") == "WSC Estimate" || parentBound.magicalGet("line.estimateType") == null || parentBound.magicalGet("line.estimateType") == "") {
            parentBound.magicalSet("line.estimateType", "Hauler Estimate")
            parentBound.magicalSet("line.reportExclude", true)
        }
        const currentWeight = bound.magicalGet("tempMetricTonne")
        switch(bound.magicalGet("weightUnits")) {
            case "mt": //just t
                parentBound.magicalSet("metricTonne", currentWeight)
                break
            case "lb": //lb to t
                parentBound.magicalSet("metricTonne", currentWeight / 2204.63)
                break
            case "kg": //kg to t
                parentBound.magicalSet("metricTonne", currentWeight / 1000)
                break
        }
    }


    return <div style={{display: "flex"}}>
        <Bound to={parentBound} init={(bound) => init(bound)}>
            <InputDecimal boundUpdate={true} field="tempMetricTonne" places={2} update={(bound) => recalculateAndUpdate2(bound)}/>
            <InputSelect field="weightUnits" options={["lb", "mt", "kg"]} defaultValue={"mt"} onChange={(bound) => recalculateAndUpdate(bound)} />
        </Bound>
    </div>
}