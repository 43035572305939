import React, { useEffect, useState, useRef } from "react";
import { Button, Tabular, TabularColumn } from "@opidcore/components";
import _ from "lodash";
import { ClientSiteSelector } from ".";
//import { NiceCurrency } from "../UI/Nice";
import { EditServicesWithTabs, UnitPrice } from "../UI/Service/EditService";

const LinesThing = ({ lines, groups, checkService, dataSet, onDoStuff }) => {    
    const [expanded, setExpanded] = useState({});
    const servicesSelected = useRef([]);
    const serviceCreatorRef = useRef({searchResult: undefined, sites: []});    

    const groupBys = {
        client: { labeller: (l) => "Client " + l.clientFriendlyId ? l.clientFriendlyId : l.site.customer, component: ClientHeader },
        site: { labeller: (l) => "Site " + (l.site == undefined? "No Site" : l.site.friendlyId), component: SiteHeader },
        service: { labeller: (l) => "Service " + (l.service && l.service.friendlyId ? l.service.friendlyId : "No Service"), component: ServiceHeader },
    }


    const toggleExpand = (newId) => {
        const nextExpanded = _.cloneDeep(expanded);
        if (nextExpanded[newId]) {
            delete nextExpanded[newId];
        } else {
            nextExpanded[newId] = true;
        }
        setExpanded(nextExpanded);
    }

    const handleCheckService = (e) => {
        e.stopPropagation();
        //const serviceId = e.currentTarget.getAttribute("data-service-id");
        const serviceId = e.currentTarget.getAttribute("data-service-real-id");

        if (e.currentTarget.checked == false) {
            _.pull(servicesSelected.current, serviceId);
        } else {
            servicesSelected.current.push(serviceId);
        }
    };

    const openEditor = () => {
      if(servicesSelected.current && servicesSelected.current.length == 0) {
        APP.alert("Please select at least one service");
        return;
      }

      const serviceData = _.map(servicesSelected.current, (servId) => {
        const service = _.find(lines, (l) => l.service.id == servId).service;
        return service;
      });

      //APP.redirect("/ui/services/editor/" + servicesSelected.current.join(","));
      APP.instance.createModal(
        <EditServicesWithTabs data={serviceData} serviceId={serviceData[0].friendlyId} />,
        { modal_name: "Services " + serviceData[0].friendlyId }
      );
    }

    const addService = () => {
        APP.instance.createModal(<ClientSiteSelector boundTo={serviceCreatorRef.current} onDoStuff={(stuff)=>onDoStuff(stuff)}/>, {modal_name: "Add Service"});
    }

    let showNothing = null;
    if (lines == null || lines.length == 0) {
        showNothing = <div>...</div>;
    }

    return <div>
        { showNothing != null ? showNothing : <LineThingGroup checkService={handleCheckService} lines={lines} toggleExpand={toggleExpand} expanded={expanded} level={0} groupBys={groupBys} groups={groups}>
            <LinesOverview checkService={checkService} />
        </LineThingGroup> }

        <Button style={{ marginRight:"30px", marginBottom:"20px"}} onClick={openEditor}>Edit Service Contracts</Button> 
        <Button onClick={addService}>Add Site</Button>
    </div>
}

const LineThingGroup = ({ toggleExpand, lines, groups, groupBys, expanded, level, children, checkService }) => {
    const group = groups[level];

    if (group == null) {

        const newChildren = React.Children.map(children, (child) => {
            return React.isValidElement(child) ?
                React.cloneElement(child, { lines: lines })
                : child;
        });


        return <div className="grouped-item-content" style={{ marginLeft: 3 * level + "em" }}>
            {newChildren}
        </div>
    }

    const grouped = _.groupBy(lines, groupBys[group].labeller);
    const HeaderComponent = groupBys[group].component;



    return <>
        {_.map(grouped, (childLines, label) => <span key={label}>
            <div onClick={() => toggleExpand(label)} className="grouped-item" style={{ marginLeft: 3 * level + "em" }}> <HeaderComponent checkService={checkService} label={label} lines={childLines} />  </div>
            {expanded[label] ? <LineThingGroup checkService={checkService} children={children} lines={childLines} toggleExpand={toggleExpand} expanded={expanded} level={level + 1} groups={groups} groupBys={groupBys} group={groups[level]} /> : null}
        </span>)
        }

    </>
}

const LinesOverview = ({ lines = [], checkService }) => {

  const [latestBilledLineItem, setLatestBilledLineItem] = useState(null);

  useEffect(() => {
    if (lines && lines[0] && lines[0].service && lines[0].service.id) {
      APP.central.Service.getLatestBilledLineItem(lines[0].service.id).then((r) => {
        setLatestBilledLineItem(r.result);
      });
    }
  }, [lines]);

  return (
    <Tabular data={_.filter(lines[0].items, (l) => l.activity > "")} className="activity-lines">
      <TabularColumn title="Client Activity" data={(r) => r.activity} />
      <TabularColumn title="Price" data={(r) => <UnitPrice item={{ unitPrice: r.unitPrice, unitPricePer: r.unitPricePer }} />} />
      <TabularColumn title="Unit" data={(r) => (r.unitPricePer == "cheese" ? "%" : r.unitPricePer)} />
      <TabularColumn title="Start" data={(r) => (r.periodFrom ? r.periodFrom : "-")} />
      <TabularColumn title="End" data={(r) => (r.periodTo ? r.periodTo : "-")} />
      <TabularColumn title="Billed Up To" data={(r) => (latestBilledLineItem ? latestBilledLineItem.periodTo ? latestBilledLineItem.periodTo : "-" : "loading...")} />
    </Tabular>
  );
}

function SiteHeader(props) {
    const site = props.lines[0].site;

    if(site == undefined){
        return <span key="no_site">No Site</span>
    }
    return <span key={site.id}> <strong>{props.label}</strong> - {site.name}, {site.address}, {site.city}, {site.province}, {site.postal_code} </span>
}


function ClientHeader(props) {
    const name = props.lines[0].clientName;

    return <span key={"client_" + name}> <strong>{props.label}</strong> - {name}</span>
}

function ServiceHeader(props) {
    const service = props.lines[0].service;
    if (service == null){
        return <span><em>No Services?</em></span>
    }

    return <span><input type="checkbox" onClick={props.checkService} data-service-id={service.friendlyId} data-service-real-id={service.id} /> <strong>{props.label}</strong> - {service.shortDescription}</span>

}

export default LinesThing;
export {LineThingGroup,  LinesOverview};