import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Bound, Button, Icon, InputSelect, Loading, PageHeading, Searchable, Tabular, TabularColumn, TabularDataSet } from "@opidcore/components";
import { CurrentModal } from '@opidcore/components/OpidApplication';
import { useData } from "@opidcore/hooks/WTF";
import _ from 'lodash';

import { NiceCurrency } from '../Nice.jsx';

const UmbrellaManagement = () => {
    const [umbrella, setUmbrella] = useState(null);
    const [umbrellaData, umbrellaDataSet] = useData("umbrella_attributes-singular", APP.central.Customer.fetchUmbrellaAttribute, { umbrella: umbrella });
    const [umbrellaNamesData, umbrellaNamesDataSet] = useData("umbrella_names", APP.central.Customer.umbrellaNames);
    const [umbrellaCostsData, umbrellaCostsDataSet] = useData("umbrella_costs-singular", APP.central.Customer.fetchUmbrellaCosts, { umbrella: umbrella });
    const [data, dataSet] = useData("umbrella_data-singular", APP.central.Customer.fetchUmbrellaManagement, { umbrella: umbrella });

    useEffect(()=>{
        dataSet.setQueryArgs({umbrella: umbrella});
        dataSet.fetch();

        umbrellaDataSet.setQueryArgs({umbrella: umbrella});
        umbrellaDataSet.fetch();

        umbrellaCostsDataSet.setQueryArgs({umbrella: umbrella});
        umbrellaCostsDataSet.fetch();
    }, [umbrella]);

    const openEditor = () =>{
        if(umbrella == null || umbrella == ""){
            APP.alert("Please select an umbrella before continuing.");
            return;
        }
        APP.instance.createModal(<EditTagsPanel umbrella={umbrella} umbrellaDataSet={umbrellaDataSet} siteDataSet={dataSet}/>, {modal_name: "Edit " + umbrella, className: "umbrella-tag-modal"});
    }

    let content = null;
    let financialContent = null;

    if(dataSet.loading){
        content = <Loading />;
    } else {
        content = <TabularDataSet dataSet={dataSet} className="umbrella-data" sticky={true}>
            <TabularColumn data={(row) => row.client_umbrella} title="Umbrella" />
            <TabularColumn data={(row) => row.client_name} title="Client" />
            <TabularColumn data={(row) => <Link to={"/ui/sites/edit/" + row.site_friendly_id} style={{color: "inherit"}}>{row.site_name}</Link>} title="Site" />
            {defineTagColumns(data)}
        </TabularDataSet>;
    }

    if(umbrella == null || umbrella == ""){
        financialContent = null;
    } else if(umbrellaCostsDataSet.loading){
        financialContent = <Loading />;
    } else {
        financialContent = <div className="umbrella-cost-data">
            <h4>{umbrella} ARs</h4>
            <TabularDataSet dataSet={umbrellaCostsDataSet} className="umbrella-cost-data-table" noData="No Financial Data">
                <TabularColumn data={(row) => row.billing_period} title="Billing Period" />
                <TabularColumn data={(row) => row.status} title="Status" />
                <TabularColumn data={(row) => <NiceCurrency>{row.total_cost}</NiceCurrency>} title="Total Cost" />
            </TabularDataSet>
        </div>;
    }

    return <div className="umbrella-management">
        <PageHeading>Umbrella Management</PageHeading>
        <Searchable ds={dataSet} />

        <Bound to={{umbrella: umbrella}} className="filters">
            <InputSelect className="edit-umbrella-filter-options" name="Umbrella" field="umbrella" options={_.map(umbrellaNamesData, (ud)=>{ return {key: ud.umbrella, value: ud.umbrella} })} onChange={(to, field, val)=>setUmbrella(val)}/>
            <Button className="edit-umbrella-filter-btn" onClick={()=>openEditor()}>Edit Umbrella <Icon icon="pencil" colour="white"/></Button>
        </Bound>

        <div className="page-content">
            {content}
            {financialContent}
        </div>
    </div>
};



const EditTagsPanel = ({umbrella, umbrellaDataSet, siteDataSet}) => {
    const currentModal = useContext(CurrentModal)
    const [ourData, setOurData] = useState([]);
    const [placeHolderValue, setPlaceHolderValue] = useState("");
    const [placeHolderType, setPlaceHolderType] = useState("");
    const defaultApplyText = <span className="apply-btn-text">Apply</span>;
    const [applyText, setApplyText] = useState(defaultApplyText);

    useEffect(()=>{
        setOurData(_.filter(umbrellaDataSet.rawData, (ud)=> ud.umbrella == umbrella));
    }, [umbrellaDataSet]);

    if(umbrella == undefined){
        return null;
    }

    const applyChanges = (e) => {
        e.stopPropagation();

        const siteIds = []
        _.forEach(siteDataSet.rawData, (r)=>{
            if(r.site_id != null && r.site_id != ""){
                siteIds.push(r.site_id);
            }
        });

        if(siteIds.length == 0){
            APP.alert("No sites to change.");
            return;
        }

        setApplyText(<span className="apply-btn-text">Applying <Loading colour="white" size="2x"/></span>);

        APP.central.Customer.applyUmbrellaTags({umbrellaData: ourData, umbrella: umbrella, siteIds: siteIds}).then((r)=>{
            if(r.result.length > 0){
                siteDataSet.fetch();
                umbrellaDataSet.fetch();
            }

            APP.instance.closeModal(currentModal.id);
            setApplyText(defaultApplyText);
        });
    }

    const addTag = (e) => {
        e.stopPropagation();

        if(placeHolderValue == null || placeHolderValue == ""){
            return;
        }

        if(placeHolderType == null || placeHolderType == ""){
            return;
        }

        const newData = [...ourData];
        const newRow = {umbrella: umbrella, attribute: placeHolderValue, attributeType: placeHolderType};
        newData.push(newRow);

        setOurData(newData);
        setPlaceHolderValue("");
        setPlaceHolderType("");
    }

    const removeValue = (e, row) => {
        e.stopPropagation();

        let used = false;

        const rowUsedIn = _.filter(siteDataSet.rawData, (site)=>{
            if(site.site_tags != undefined){
                _.forEach(JSON.parse(site.site_tags.value), (val, key)=>{
                    if(val != undefined && val != "" && key == row.attribute){
                        //found a used sample
                        used = true;
                    }
                });
            }
        })

        if(used){
            APP.alert("Unable to delete tag due to a site having a value assigned for the tag.");
            return;
        }

        const newData = _.without([...ourData], row);
        setOurData(newData);
    }

    const updatePlaceholderValue = (e) => {
        setPlaceHolderValue(e.currentTarget.value);
    }

    const updatePlaceholderType = (e) => {
        setPlaceHolderType(e.currentTarget.value);
    }

    const updateDropdownOptions = (row, rowIndex, values) => {
        row.attributeOptions = values;

        const newData = _.map(ourData, (od, idx)=>{
            if(idx == rowIndex){
                return row;
            }

            return od;
        });

        setOurData(newData);
    }

    return <div className="edit-umbrella-tags">
        <PageHeading>Edit {umbrella} Umbrella</PageHeading>

        <Tabular data={ourData}>
            <TabularColumn title="Attribute" data={(row)=>row.attribute} className="attribute-column"/>
            <TabularColumn title="Type" data={(row)=>row.attributeType} className="type-column"/>
            <TabularColumn title="Options" data={(row, idx)=>row.attributeType == "dropdown"? <DropdownValueEditor data={row} updateData={(values)=>updateDropdownOptions(row, idx, values)}/> : null} className="dropdown-column"/>
            <TabularColumn title="" data={(row)=><Icon icon="trash" onClick={(e)=>removeValue(e, row)}/>} className="action-column"/>
        </Tabular>

        <div className="edit-tags-add-new">
            <div className="field attribute-column">
                <input onClick={(e)=>e.stopPropagation()} onChange={(e)=>updatePlaceholderValue(e)} value={placeHolderValue}/>
            </div>
            <div className="field type-column">
                <select onClick={(e)=>e.stopPropagation()} onChange={(e)=>updatePlaceholderType(e)} value={placeHolderType} onKeyUp={(e)=>{ e.key == "Enter"? addTag(e) : null}}>
                    <option value=""></option>
                    <option value="text">Text</option>
                    <option value="dropdown">Dropdown</option>
                </select>
            </div>
            <div className="dropdown-column" />
               <Button onClick={(e)=>addTag(e)} className="add-btn"> Add</Button>
            </div>

        <Button className="apply-btn" onClick={(e)=>applyChanges(e)}>{applyText}</Button>
    </div>;
}

const DropdownValueEditor = ({data = {attributeOptions: []}, updateData = (values) => console.log("todo")}) => {
    const [values, setValues] = useState([]);
    const [placeHolderValue, setPlaceHolderValue] = useState("");

    useEffect(()=>{
        if(data.attributeOptions != undefined){
            setValues([...data.attributeOptions]);
        }
    }, [data]);

    useEffect(()=>{
        updateData(values);
    }, [values]);

    const updatePlaceholderValue = (e) => {
        setPlaceHolderValue(e.currentTarget.value);
    }

    const updateValues = (e) => {
        const newValues = [...values];
        newValues.push(placeHolderValue);

        setPlaceHolderValue("");
        setValues(newValues);
    }

    const removeValue = (e, value) => {
        e.stopPropagation();

        const newValues = _.without(values, value);
        setValues(newValues);
    }

    const valueElements = _.map(values, (v, idx)=>{
        return <div key={idx} className="drop-down-options-element" onClick={(e)=>removeValue(e, v)}>{v} <Icon icon="times" className="remove"/></div>;
    });

    return <div className="drop-down-options">
        <div className="drop-down-options-elements">
            {valueElements}
        </div>

        <div className="drop-down-options-add-new">
            <div className="field">
                <input onClick={(e)=>e.stopPropagation()} onChange={(e)=>updatePlaceholderValue(e)} value={placeHolderValue} onKeyUp={(e)=>{ e.key == "Enter"? updateValues(e) : null}}/>
            </div>
        </div>
    </div>;
}

const defineTagColumns = (data) => {
    if(data.length <= 0){
        return null;
    }

    const tags = {};

    _.forEach(data, (row)=>{
        if(row.site_tags != undefined){
            _.forEach(JSON.parse(row.site_tags.value), (val, key)=>{
                tags[key] = "seen";
            });
        }
    });

    return React.Children.map(_.keys(tags), (tag)=><TabularColumn data={(row)=>{
        if(row.site_tags == undefined){
            return null;
        }

        const st = JSON.parse(row.site_tags.value);
        if(st == undefined || st[tag] == undefined || st[tag].trim().length == 0){
            return null;
        }

        return st[tag];
    }} title={tag}/>);
}

export default UmbrellaManagement;