import React, { Component, useContext } from 'react';
var _ = require('lodash');

import Section from './Section.jsx';
import Fields from './Fields.jsx';
import { Bound } from '../../../OpidCore/public/js/components';
import { DataContext } from '../../../OpidCore/public/js/components/Bound.js';
import FieldEditor from './FieldEditor.jsx';

//import Editor, {EditorPanelDock, EditorPanel} from '../../../OpidCore/public/js/components/Content/Editor.jsx';

//import RenderMeSoftly  from '../../../OpidCore/public/js/components/Content/RenderMeSoftly.jsx';
import RenderMeSoftlyEditor  from '../../../OpidCore/public/js/components/Content/RenderMeSoftlyEditor.jsx';

import Icon from '@opidcore/components/Icon';

function LittleIcon(props){
	return <span title={props.title} onClick={props.onClick}><Icon icon={props.icon} size="sm"/></span>
}

function RowStatus(props){
	const data = useContext(DataContext);	
	
	
	return <div className="row-status">
		<span>{data && data.to && data.to.__modified ? <LittleIcon key="unsaved" title="Unsaved Changes" icon="asterisk"/>  : ""}</span>
		{ props.handleDelete ? <LittleIcon key="delete" title="Delete" onClick={props.handleDelete} icon="trash"/> : null }
	</div>;
}

function EmptyComponent(){
	return null;
}

class Validator extends Component {
	 constructor(props, context) {
        super(props, context);
        
        this.state = {
        };
    }

	static getDerivedStateFromProps(props, state){
		
		return {...props, ...state};
	}
	validate(magic, bound){
				var issues = [];

		_.each(this.props.fields, (f)=>{
			if (f.fieldOptions && f.fieldOptions.required){
				var match = f.fieldOptions.required.match("\\\\JS{(.*)\\\\}");
				
				if (match && match[1]){
					var me = magic.to;
					
					var issue = eval(match[1]);
					
					if (issue && (magic.magicalGet(f.fieldUid) || "").length == 0) {
						issues.push(f.fieldOptions.message);
					}
					
				}
			}
		});
		this.setState({issues: issues});
		if (issues.length > 0){
			bound.setState({"statusClass": "issue", statusMessages: issues})
		}else{
			bound.setState({"statusClass": null})
		}
	}

	render(){
		return this.props.children;
	}
}
export default class BasicView extends Component {
    constructor(props, context) {
        super(props, context);
        
        this.state = {
                editMode : false,
        };
          
    }

	update(a,field,value,d){
		console.log("we were asked to update the field " + field);
		if (APP.listeners[field]){
		    APP.listeners[field](a,field,value,d);
		}
	}
	
	fetchDataDefinitions(){
	    if(this.getDefinitions == null){            
            this.getDefinitions = this.props.central.Stuff.listDefinitions().then((res)=>{
                this.setState({dataDefinitions: res.result});
            });
        }
	}
	
	 componentDidUpdate(){
	        APP.customize.setCurrentForm( this.props.data, this.props.formDefinition.uid );
	        
	        this.fetchDataDefinitions();
	 }	    
    
    componentDidMount(){
        APP.customize.setCurrentForm( this.props.data, this.props.formDefinition.uid, this );
        this.fetchDataDefinitions();
    }
    
    componentWillUnmount(){
    	APP.customize.closingForm( this.props.data, this.props.formDefinition.uid, this );
    }
    
    _generateActions(){
    	const actions = {};
    	
    	actions.addRow = (section, defaults) => { return this.props.myUpdater("ADD_ROW", section, defaults) };
    	actions.set = (field, value) => { return this.props.myUpdater("SET", field, value) };
    	actions.save = () => { return this.props.myUpdater("SAVE") };

    	return actions;
   
    }
	
	static getDerivedStateFromProps(props, state){
		
		
		return null;
	} 
	


	
	
	handleAddRow(section){
		this.props.myUpdater("ADD_ROW", section); 
		
		return false;
	}
	
	handleDeleteRow(section, row){
	    let confirmed = confirm("Deleting. Are you sure?");
	    
	    if(confirmed){
	        this.props.myUpdater("DELETE_ROW", section, row); 
	    }
		return false;
	}
	
	handleValidate(bound,magic,keyPath,value){
		if (this.refs.validator){
			this.refs.validator.validate(magic,bound);
		}
		if (this.props.save){
			this.props.save();
		}
	}
	
    handleSave(callback){
        if (this.__handleSave == null){
            this.__handleSave = _.debounce( (c) => this._handleSave(c), 500 )
        }
        
        return this.__handleSave(callback);
    }
    
    _handleSave(callback){
        var saveObj = this.getSaveState();
        var s = _.values(saveObj.modifiedFields);
        return this.getCentral().saveFields({fields : s}).then((r)=>{
            console.log(r);
          //  callback && callback();
           // this.setState({__fetchRequired:true});
        });
    }
    
    getSaveState(){
        return {...this.state};
    }
    
    getCentral(){
        return this.props.central.Form;
    }
	
	handleFieldDefChanged(newDef){
	    var modifiedFields = {};
	    if(this.state.modifiedFields){
	        modifiedFields = this.state.modifiedFields;
	    }
	    modifiedFields[newDef.id] = newDef;
	    this.setState({modifiedFields : modifiedFields});
	}
	
    handleClickFieldEditor(field){
       
    	
    	var tempOptions = {};
    	var sections = _.map(this.props.formDefinition.sections, (s)=>{return s.uid});
    	
    	var editorProps = {onFieldDefinitionChanged: (newDef)=>this.handleFieldDefChanged(newDef),
    						dataDefinitions: this.state.dataDefinitions,
    						button: "apply",
    						sections: sections,
    						field: field
    	};
    	
        this.refs.editor.editors.push({id: field.id, field: field, editorComponent: FieldEditor, editorProps: editorProps, options: tempOptions});
        this.forceUpdate();
        //var content = <FieldEditor onFieldDefinitionChanged = {(newDef)=>this.handleFieldDefChanged(newDef)} dataDefinitions={this.state.dataDefinitions} button="apply" sections={sections} field={field}/>; 
      
    }
    
    closeEditor(editor){
    	//_.remove(this.editors, (e)=>e.id == editor.id);
    	this.forceUpdate();
    }

    editMode(){
        
        if(this.state.editMode){
            this.setState({editMode:false});
        }
        else if(this.state.editMode == false){
            this.setState({editMode:true});
        }
    } 
    newRecord(){
        
//        var form = _.find(nextProps.formDefinitions, {uid: nextProps.route.viewDefinition.formUid});
//        if (this.form != form){
//            this.form = form;
//            this.loadData();
//        }   
//        
        this.props.central.Stuff.createNewInstance( this.form.id ).then((r)=>{
        
            this.openRecord( r.result.id );
        });

    }
    selectUnits(e){
        var unit = e.target.value;
        this.setState({selectedUnit:unit});
    }
	
    updateContentStructure(struct){
    	
    	this.props.myUpdater("RMS", "this_does_not_matter", ()=>{ this.refs.editor.saveAllContent() });
    }
    
    saveActionThing(struct, newOptions, c){
        let newStruct = {...struct};
        _.forEach(newOptions, (value, key)=>{
            newStruct.options[key] = value;
        });
        
        const theNode = APP.NDT.getNode(newStruct.theId);
        if(theNode == null || theNode.props.updateContentStructure == undefined){
            //this shouldn't happen. Do we need to wrap and track so new elements get nodes?
            this.updateContentStructure(newStruct);
        } else {
            theNode.props.updateContentStructure(newStruct);   
        }
    }
    
    render(){
        
        var sectionShow = null;
        if(this.props.showSection != undefined){
            sectionShow = this.props.showSection;
        }
    
    	var form = this.props.formDefinition;
    	var sectionInOrder = _.sortBy(form.sections, function(f){ return f.order;});
    	
    	if(sectionShow  && sectionShow != null){
    	    sectionInOrder = _.filter(sectionInOrder, function(s){ return s.uid == sectionShow; });
    	}
    	
    	var newFields = this.state.modifiedFields;

    	if(newFields != null){
    	       var formFieldsUpdated = form.fields.map((f)=>{return newFields[f.id] || f});
    	        form.fields = formFieldsUpdated;
    	}


        var sections = _.map(sectionInOrder, (section)=>{  
			var body = null;
			let allowHeadings = true;
			if (section.type == "one-to-many"){
			    /*to-do: if(section.options && section.options.allowTRHeadings){
			        allowHeadings = section.options.allowTRHeadings;
			    }*/
			    
				var rows = [];
				let headings = [];
				

				if (this.props.data && this.props.data[section.uid]){
					_.each(this.props.data[section.uid], (r, index)=>{
						var fields = form.fields.filter(f=>f.section == section.uid);
						headings = headings.concat(fields);
						console.log("TODO -- @ian you need to figure this out.  @cody can't delete this console.log");

						let  extraStuff = null;

						if (window.APP.customize.getCustomSectionComponent(form.uid, section.uid) != null){
							extraStuff = window.APP.customize.getCustomSectionComponent(form.uid, section.uid);
						}else{
							//console.log("nothing for", form.uid, section.uid );
						}

						const ExtraCols = extraStuff && extraStuff.generateComponents ? extraStuff.generateComponents : EmptyComponent;
						

						rows.push(
						<Validator ref="validator" key={r._key} fields={fields}>
							<Bound ref="bound" to={r} key={r._key + Math.floor((100 * Math.random()))} tagName="tr" onChange={(a,b,c,d)=>this.handleValidate(a,b,c,d)}>	
								<Fields editor={this.refs.editor} data={this.props.data} viewType={this.props.viewType} basicView={this} form={form} central={this.props.central} section={section.uid} outerTag="td" update={(a,b,c,d)=>this.update(a,b,c,d)} fields={fields} rowNumber={index} />
								<ExtraCols form="form" mode="body"/>
								<td className="row-status"><RowStatus handleDelete={()=>this.handleDeleteRow(section.uid, r)}/></td>
							</Bound>
						</Validator>
						);
					
					});
				}
    
                if(headings.length > 0 && allowHeadings){
                    let uniqHeadingIds = {};
                    let uniqHeadings = [];
                    
                    _.forEach(headings, (heading)=>{
                        if(uniqHeadingIds[heading.id] == undefined){
                            uniqHeadingIds[heading.id] = "seen";
                            uniqHeadings.push(heading);
                        }
                    });
                    
					uniqHeadings = _.orderBy(uniqHeadings, 'order')

                    headings = _.map(uniqHeadings, (heading)=>{
                        return <th key={section.uid + "_heading_" + heading.label}>{heading.label}</th>;
                    });
                }
			

				let  extraStuff = null;

				if (window.APP.customize.getCustomSectionComponent(form.uid, section.uid) != null){
					extraStuff = window.APP.customize.getCustomSectionComponent(form.uid, section.uid);
				}else{
					//console.log("nothing for", form.uid, section.uid );
				}

				const ExtraCols = extraStuff && extraStuff.generateComponents ? extraStuff.generateComponents : EmptyComponent;


			
				body = <div className="one-to-many-body">

				<div className="add_row">
					<a className="button btn-primary btn" onClick={()=>this.handleAddRow(section.uid)}><Icon icon="plus-square" color="#fff"/> Add Row</a>
				</div>

				<table className={"form view_table " + (section.options ? section.options.className : "") }>
					<thead className="view_table_headings">
				        <tr>{headings}<ExtraCols form={form} mode="headings"/></tr>
					</thead>
					<tbody className={allowHeadings? "labels_off" : ""}>
						{rows}
					</tbody>
					<ExtraCols form={form} mode="footers"/>
				</table>
				
				
					

				
				</div>
			}else if (section.uid > ""){
			    
				body = <div className="section-outer"><Fields editor={this.refs.editor} form={form} data={this.props.data} viewType={this.props.viewType} central={this.props.central} section={section.uid} update={(a,b,c,d)=>this.update(a,b,c,d)} fields={ _.filter(form.fields, {section: section.uid})} /></div>
				
			}else{
				//body = <div>Patience...</div>
			}
			var debugme =  JSON.stringify(this.props.data);
			var sectionEdit = null;
			if(this.state.editMode){
			    var fields = _.filter(form.fields, {section: section.uid});
	            var fieldsButton = _.map(fields, (f, index)=>{
	                return <div key={f.fieldUid + "" + index} onClick={()=>this.handleClickFieldEditor(f)}> <Icon icon="edit"/>{f.fieldUid}</div>;
	            });
	            sectionEdit = <div>{fieldsButton}
                    <a className="button btn-primary btn" onClick={(e)=>this.handleSave(e)}>Save Field Changes</a>
                </div>

			}
                    

	        var buttonEditMode = null;
	        
	        if(this.props.allowEdit){
	            buttonEditMode = <div className="add_row"> <a className="button btn-primary btn" onClick={()=>this.editMode()}>Edit Mode</a> </div>;
	        }

			return <Section boundTo={this.props.data} key={section.uid} section={section}>
			    
				{body}
			    {buttonEditMode}
			    {sectionEdit}
			    <section id='modal'></section>
			</Section>
			});
			
        	// 		<FieldEditor onFieldDefinitionChanged = {(newDef)=>this.handleFieldDefChanged(newDef)} dataDefinitions={this.state.dataDefinitions} button="apply" sections={sections} field={field}/>;

        
			// var editor = <EditorPanelDock ref=add={()=>this.add()} editorComponent={FieldEditor} onStartEditing={(id)=>this.startEditing(id)} closeEditor={(id)=>this.closeEditor(id)} structure={this.props.tempStructure} editors={this.editors} hoverElements={this.state.hoverElements || []}/>;
        	var editor = <RenderMeSoftlyEditor saveAction={(a,b,c)=>this.saveActionThing(a,b,c)} onEdit={(mode)=>{this.setState({"editMode": mode})}} ref="editor" updateContentStructure={(s)=>this.updateContentStructure(s)} editTempStructure={this.editTempStructure} tempStructure={this.state.tempContentStructure} central={this.props.central}/>

			
			var title = null;
			var hidetitle = null;
			var rev = null;
	        if(this.props.hideTitle != undefined){
	            hidetitle = this.props.hideTitle;
	        }
	        
			if(hidetitle){
			   return <div className="sections">
					{sections}
				</div>
			}
			
			
			title = <h2>{form.title}</h2>;
			rev = <span className="revision">Rev: {form.revisionLevel != undefined && form.revisionLevel.trim().length > 0? form.revisionLevel : "-"}</span>;
			let className = "";
			if (form.options && form.options.className){
				className = form.options.className;
			}
			
			console.log("look at me");
		
			return <div id="login-body" className="text-center">
			<div className={"container " +  "form-" + form.uid + " " + className}>
				<div className="form-header">
			        {title}
				    {rev}
				</div>
				<div className="sections">
					{sections}
				</div>
				
				 {editor}

			</div>
		</div>
		
    }
}