import React, { Component, PropTypes } from 'react';
import nv from 'nvd3';
import * as d3 from "d3";

import * as _ from 'lodash';

export default class OpidGraphBase extends Component {
    fetchColours(dataSet){
        let labelArray = [], res = [], seen = {};
        
        _.forEach(dataSet, (el)=>{
           if(el.values != undefined){
               _.forEach(el.values, (val) =>{
                   if(val.label && seen[val.label] == undefined){
                       labelArray.push(val.label);
                       seen[val.label] = "seen";
                   }
               });
           } else if(el.label && seen[el.label] == undefined){
               labelArray.push(el.label);
               seen[el.label] = "seen";
           }
        });
        
        //find the colour that matches the key
        if(this.props.colourProvider && typeof this.props.colourProvider === "function"){
            try {
                res = this.props.colourProvider(labelArray);
            } catch(e) {
                console.log(e);
            }
        }
        
        if(res != undefined && labelArray != undefined && res.length == 0 && labelArray.length > 0){
            
            if(this.props.scope.colourSets != undefined){
                res = this.props.scope.colourSets["genericColourSet"];
                if(res.length < labelArray.length){
                    //add more random colours
                    _.forEach(labelArray.slice(res.length-1), (label)=>{
                        const currentColour = this.props.NDT.colourMapper.findOrGuess(label);
                        if(currentColour != undefined){
                            res.push(currentColour);
                        }
                    });
                    
                    this.props.announceScope("genericColourSet", res, "colourSets");
                }
            } else {
              //generate a new list of colours
                _.forEach(labelArray, (label)=>{
                    const currentColour = this.props.NDT.colourMapper.findOrGuess(label);
                    if(currentColour != undefined){
                        res.push(currentColour);
                    }
                });

                this.props.announceScope("genericColourSet", res, "colourSets");
            }
        }        
        
        return res;
    }
    
    render(){
        return null;
    }    
}