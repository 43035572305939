import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Grid } from '@opidcore/components';
import { GridItemGroup, GridItem } from '@opidcore/components/Grid';
import { InputText } from '@opidcore/components'
import { useData} from '@opidcore/hooks/WTF';
import BoundCommit from '@opidcore/components/BoundCommit';

export default function TestGrid(props){
    let columnsDisplayed = ["test", "Quantity Multiplier", "footnote"];
    
    const params = useParams();
    const [lineItemData, lineItemDataSet] = useData("client_contract_line", parseInt(params.id));
    const [lineItemDataState, setlineItemDataState] = useState({items: []});
    
    useEffect(()=>{
        if(lineItemData){
            setlineItemDataState(lineItemData);
        }
    }, [lineItemData]);
    
    const growFunction = ()=>{
        const newData = _.cloneDeep(lineItemDataState);
        newData.items.push({});
        setlineItemDataState(newData);
    }
    
    if(lineItemData == undefined){
        return <div key="lineItemGrid">Loading Data...</div>
    }
    
    return <div key="lineItemGrid">
        <BoundCommit initialValue={lineItemDataState} commit={APP.central.X}>
            <Grid data={lineItemDataState.items} columnsDisplayed={columnsDisplayed} growFunction={()=>growFunction()}>
                <GridItemGroup groupKey="test">
                    <GridItem><InputText field="unitCost"/></GridItem>
                    <InputText field="unitPrice"/>
                    <GridItem title="Quantity Multiplier"><InputText field="quantityMultiplier"/></GridItem>
                </GridItemGroup>
                <p>I Hope I am hidden eventually!</p>
                <GridItem title="testingwithspan"><span>I will live forever!</span></GridItem>
                <InputText field="quantity"/>
                <InputText field="footnote"/>
            </Grid>
        </BoundCommit>
    </div>;
}