import React, { useEffect, useRef, useState } from "react";
//import Searchable from "@opidcore/components/Searchable";
import { AccessControl, Bound, Button, Icon, InputAutoComplete, Step, Tabular, TabularColumn, Workflow } from "@opidcore/components";
import ActionBar from "@opidcore/components/ActionBar";
import { useNavBar } from "@opidcore/hooks/WTF";
import moment from "moment";
import { useHistory } from "react-router-dom";
import FullTextSearchable from "../../components/FullTextSearchable";
import { EditClientContractDetails } from "./EditClientContract";

export const CreateNewContract = () => {
  const [workflowContextState, setWorkflowContextState] = useState({ onStepFinish: undefined, onGoBack: undefined });
  const [data, setData] = useState({ friendlyId: 0 });
  const boundRef = useRef();
  const history = useHistory();

  const nextStep = () => {
    if (workflowContextState.onStepFinish != undefined) {
      workflowContextState.onStepFinish();
    }
  };

  useEffect(() => {
    nextStep();
  }, [data]);

  const openNewContract = (autoCompleteResult) => {
    APP.central.ClientContract.createContractForClient(autoCompleteResult.basis.id).then((r) => {
      setData(r.result);
    });
  };

  return (
    <div className="create-client-contract">
      <Workflow initialize={(context) => setWorkflowContextState(context)} initialStep={1}>
        <Step myStepNumber={1}>
          <div className="client-selector">
            <Bound to={boundRef.current}>
              <div className="field">
                <label>Select Client by typing...</label>
                <InputAutoComplete central={APP.central.Customer.autocomplete} field="client" onSelect={(value) => openNewContract(value)}>
                  {(customer) => <div className="customer_option">{customer.displayValue}</div>}
                </InputAutoComplete>
              </div>
            </Bound>
          </div>
        </Step>
        <Step myStepNumber={2}>
          <div className="contract-editor">
            <EditClientContractDetails data={data} dataSet={null} afterSave={() => history.push("/ui/clientcontract/edit/" + data.friendlyId)} />
          </div>
        </Step>
      </Workflow>
    </div>
  );
};

export default function ClientContractList(props) {
  useNavBar("Active Client Contracts", []);
  //const [data, dataSet] = useData("clientcontractlist", APP.central.ClientContract.listContracts);
  const [clientContracts, setClientContracts] = useState([]);
  const [fetchClientContracts, setFetchClientContracts] = useState(false);

  const dateFormat = "YYYY-MM-DD";

  //TODO Fix for new data set
  const excelExport = () => {
    /*
    APP.central.ClientContract.getDefaultExportLayout().then((r) => {
			APP.instance.createModal(<ExcelExportModal defaultLayout={r.result} dataSet={dataSet} model={"ClientContract"} />, { modal_name: "Excel Export" });
		});
    */
  };

  const newClientContract = () => {
    APP.instance.createModal(<CreateNewContract />);
  };

  return (
    <div className="full-size">
      <ActionBar title="Actions">
        {/*<div>
          <CesarFilter
            columns={[
              { column: "site_name" },
              { column: "client_name" },
              { column: "expiry_status", lookup: "clientContractExpiryStatus" },
              { column: "status", lookup: "clientContractStatus", default: "active" },
            ]}
            dataSet={dataSet}
          />
          <Searchable ds={dataSet} />
          </div>*/}
        <FullTextSearchable
          model={"ClientContract"}
          method={"reasonableList"}
          setData={setClientContracts}
          doFetchData={fetchClientContracts}
          setDoFetchData={setFetchClientContracts}
          maxRows={300}
          filterOptions={{
            columns: [
              { column: "site_name" },
              { column: "client_name" },
              { column: "expiry_status", lookup: "clientContractExpiryStatus" },
              { column: "status", lookup: "clientContractStatus", default: "active" },
            ],
          }}
        />

        <AccessControl group="Management">
          <Icon icon="file-excel" size="2x" title="Open export modal" onClick={() => excelExport()} />
        </AccessControl>

        <Button onClick={() => newClientContract()}><Icon color="white" icon="plus" /> {"\u00a0"}New Client Contract</Button>
      </ActionBar>

      {/*dataSet.loading ? <Loading /> : ""*/}

      <Tabular
        data={clientContracts}
        onClick={(row) => {
          APP.redirect("/ui/clientcontract/edit/" + row.friendly_id);
        }}
        pageSize={100}
        paginationLocation={"both"}
      >
        <TabularColumn data={(row) => row.friendly_id} title="#" sortCol="friendly_id" />
        <TabularColumn data={(row) => row.client_name} title="Client" sortCol="Customer" />
        <TabularColumn data={(row) => row.site_name} title="Site" sortCol="site_name" />

        <TabularColumn data={(row) => (row.effective_from ? moment(row.effective_from).format(dateFormat) : "-")} title="Effective From" sortCol="effective_from" />
        <TabularColumn data={(row) => (row.effective_to ? moment(row.effective_to).format(dateFormat) : "-")} title="Effective To" sortCol="effective_to" />
        <TabularColumn data={(row) => row.contract_term} title="Contract Term" sortCol="contract_term" />

        {/* <TabularColumn data={(row) => (row.internal_notes ? row.internal_notes : "-")} title="Internal Notes" sortCol="internal_notes" /> */}
        <TabularColumn data={(row) => (row.external_reference ? row.external_reference : "-")} title="External Reference" sortCol="external_reference" />
        <TabularColumn data={(row) => (row.invoice_timing ? row.invoice_timing : "-")} title="Invoice Timing" sortCol="invoice_timing" />

        <TabularColumn data={(row) => row.expiry_status} title="Expiry Status" sortCol="expiry_status" />
        <TabularColumn data={(row) => row.status} title="Historical Status" sortCol="status" />
      </Tabular>
    </div>
  );
}
