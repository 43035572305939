import React from 'react';
import _ from 'lodash';

// const NiceBox = ({ label, children = undefined, className="", title="" , serviceReqField = false}) => {
// 	return (
// 		<span>{ serviceReqField ? 
// 			<div className={"niceBox" + (className != null && className.length > 0 ? " " + className : "") } 
// 			     title={title != null && title.length > 0 ? title : label} style={{border: "2px solid red", borderRadius: "0.3rem"}}>
// 			<strong>{label}</strong>
// 			<div className="content">{children}
// 			</div>
// 			</div>:
// 			<div className={"niceBox" + (className != null && className.length > 0 ? " " + className : "") } title={title != null && title.length > 0 ? title : label}>
// 				<strong>{label}</strong>
// 				<div className="content">{children}  
// 				</div>
// 			</div>}
// 		</span>
// 	);
// }

const NiceBox = ({ label, children = undefined, className="", title="" }) => {
	return (
		<div className={"niceBox" + (className != null && className.length > 0 ? " " + className : "") } title={title != null && title.length > 0 ? title : label}>
			<strong>{label}</strong>
			<div className="content">{children}</div>
		</div>
	);
}

const NiceBoxContainer = ({ children = undefined, className = null }) => { 
	return <div className={"niceBox-container " + className}>
		{children}
	</div>
}

const NiceBoxSection = ({ children  = undefined }) =>{
	return <div className="niceBox-section-wrapper">
		{children}
	</div>
}

const NiceAddress = ({address = undefined, city = undefined, province = undefined, country = undefined, postalCode = undefined}) => {
	let formattedAddress = [];

	if(postalCode != undefined){
		formattedAddress.push(postalCode);
	}

	if(country != undefined){
		formattedAddress.push(country);
	}

	if(province != undefined){
		formattedAddress.push(province);
	}

	if(city != undefined){
		formattedAddress.push(city);
	}

	if(address != undefined){
		formattedAddress.push(address);
	}

	return <span className="niceBox-address">
		{formattedAddress.join(", ")}
	</span>
}

export { NiceBoxContainer, NiceBox, NiceBoxSection, NiceAddress };