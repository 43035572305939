import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var _ = require('lodash');

import { InputText, InputRMS, InputSelect, InputDataSelect, DatePicker, Calculated, Button, Bound, InputSelectFilter, InputDecimal, InputColour, LookupInputSelect } from '@opidcore/components';

import TimestampInput from '../components/TimestampInput.jsx';
import { DataContext } from '../../../OpidCore/public/js/components/Bound';
import moment from 'moment';
import Modal from '../components/Modal.js';
//import FieldEditor from './FieldEditor.jsx';
import FileBrowser from '../../../OpidCore/public/js/components/Content/Types/FileBrowser.jsx';

import { CustomizeContext } from '../../../OpidCore/public/js/Customization.jsx';
import Icon from '@opidcore/components/Icon';
import Functioner from '@opidcore/Functioner';



class FieldWithLabel extends Component {
	render() {
		return <div className="field">
			<label>{this.props.label}</label>
			{this.props.children}
		</div>;
	}
}

class ScaleReader extends Component {
	handleFocus() {
		_.remove(window.APP.reacts, (r) => { return r.constructor.name == "ScaleReader" });
		window.APP.reacts.push(this);
	}

	handleBlur() {
		_.remove(window.APP.reacts, (r) => { return r.constructor.name == "ScaleReader" });
	}

	thisHappened(a) {
		var matches = a.match("[0-9]{5}");
		if (matches != null) {
			var grams = parseInt(matches[0]);
			if (this.refs.me && this.refs.me.setValue) {
				this.refs.me.setValue(grams);
			}
		}
	}

	render() {
		var myField = this.props.field ? this.props.field : this.props.children.field;
		return <span field={myField} onBlur={() => this.handleBlur()} onFocus={() => this.handleFocus()}>{React.cloneElement(this.props.children, { ref: "me", field: myField })}</span>
	}

}

class ArrayOf extends Component {
	static contextType = DataContext;

	constructor(props, context) {
		super(props, context);
		var hasValues = (context.magicalGet(props.field) && context.magicalGet(props.field).length);
		var count = hasValues || 1;
		if (hasValues) {
			this.state = { startIndex: count, values: [], count: count };
		} else {
			this.state = { editing: 0, startIndex: 0, values: [], count: count };
		}

	}

	componentDidUpdate(prevProps, prevState) {
		var newCount = (this.context.magicalGet(this.props.field) && this.context.magicalGet(this.props.field).length) || 1;
		if (prevState.count != newCount) {
			this.setState({ count: newCount });
		}
	}

	handleAddArray() {

		this.setState({ count: this.state.count + 1, editing: this.state.count }, () => {

			var dom = ReactDOM.findDOMNode(this);
			var inputs = dom.getElementsByTagName("input");
			if (inputs.length >= 1) {
				var lastInput = inputs[inputs.length - 1];
				lastInput.value = "";
				lastInput.focus();
			}
		});
		return false;
	}

	deleteDetail(x) {
		const newDetails = _.without(this.context.to[this.props.field], this.context.to[this.props.field][x]);
		this.context.magicalSet(this.props.field, newDetails);
		this.setState({ count: this.state.count - 1 });
	}

	handleClick(x) {
		if (confirm("are you sure you want to change an existing entry?")) {
			this.setState({ editing: x });
		}
	}

	handleKeyUp(e) {
		if (e.key == "Enter") {
			this.handleAddArray()
		}
	}

	render() {

		var editors = [];

		for (var x = 0; x < this.state.count; x++) {
			// need to fix this and make it allow for "editing" 1 child at a time or all children at once.  Need remove true or make it an option
			if (this.state.editing == x || true) {
				var theField = this.props.children.props.field;
				if (theField == null) {
					console.log("you're going to have a bad time because you have an array not tied to a field.'")
				}
				var currentData = null;

				if (this.context.to && this.context.to.details) {
					currentData = this.context.to.details[x];
				}

				if (currentData == null) {
					//give us an empty hash to be able to edit things
					currentData = {};
				}

				const editor = React.cloneElement(this.props.children, { to: currentData, field: theField + "." + x, grossParent: this.context });
				let deleteButton = null;
				if (this.props.canDelete) {
					const theRealX = x;
					deleteButton = <Icon title={"Remove " + x} icon={"minus-circle"} className={"remove"} onClick={() => this.deleteDetail(theRealX)} />;
				}

				editors.push(<span className="array-item" key={"array" + x} onClick={(e) => console.log("wtf")} onKeyUp={(e) => this.handleKeyUp(e)}>{editor} {deleteButton}</span>);
			} else {
				var fieldUid = this.props.children.props.field;
				const fieldX = x;
				const editor = <span className="value"><Calculated valueFunction={(me) => { return me[fieldUid][fieldX] }} field={this.props.children.props.field + "." + x} /></span>
				editors.push(<span onClick={() => this.handleClick(fieldX)} className="array-item" key={"array" + x}>{editor}</span>);
			}
		}

		return <div className={"array-outer " + this.props.className}>
			{editors}
			<button onClick={() => this.handleAddArray()}>+</button>

		</div>

	}
}


export default class Fields extends Component {

	delete(p) {
		for (var i = 0; i < p.fields.length; i++) {
			window.APP.central.Form.deleteField(p.fields[i].id);
		}
	}

	render() {

		// <button onClick={() => this.delete(this.props)}>delete</button>
		return (
			<><CustomizeContext.Consumer>
				{customCtx => (
					<DataContext.Consumer>
						{dataCtx => (
							<InnerFields {...this.props} dataCtx={dataCtx} customCtx={customCtx} />
						)}
					</DataContext.Consumer>
				)}
			</CustomizeContext.Consumer></>
		);
	}
}

export class InnerFields extends Component {
	//static contextType = DataContext;
	//static contextType = CustomizeContext;

	constructor(props) {
		super(props);

		this.state = { Users: [] };
		this.dataContext = this.props.dataCtx;
		this.dataContext.attachInnerFields(this);
		this.customContext = this.props.customCtx;
	}

	componentDidMount() {



		var elements = _.map(this.props.fields, (field) => {
			var options = { ...field.field.options, ...field.fieldOptions };

			if (options.fetch == true || options.options == "fetch" || field.field.fieldType == "reference") {
				if (options.form == null) {
					options.form = -1;
				}
				const stateKey = "options-" + field.field.id;
				window.APP.cachedRequest(stateKey, () => { return this.props.central.Stuff.fieldOptions(field.field.id, options.form) },
					(res) => {
						try {

							var stateChange = {};
							stateChange[stateKey] = res.result.sort();
							this.setState(stateChange);
						} catch (ex) {
							debugger;
						}
					});


				window.APP.cachedRequest("users", () => { return this.props.central.User.list() },
					(res) => {
						var users = [];
						_.each((res.result), (r) => {
							users.push(r.fullName);
						})
						this.setState({ Users: users });
					});

			}


		});

	}

	updateReference(obj, field, id, options) {
		this.props.update(obj, field, id);
		var option = _.find(options, { id: id });
		if (option == null) {
			option = _.find(options, { id: parseInt(id) });
		}
		if (this.refs.reference) {
			this.refs.reference.buzzah(field + "_reference", option);
		}
		this.props.update(obj, field + "_reference", option);
	}


	openModal(v) {
		this.setState({ modalContents: v });
	}


	handleClickField(field) {

		var content = this.dataContext.magicalGet(field.fieldUid);
		var modal = new Modal();
		modal.openModal(

			<div>
				<h3>{field.label}</h3>
				<textarea defaultValue={content} className="the-content" />

				<a className="button" onClick={() => this.handleSaveField(field)}>Save</a>
			</div>
		)
	}

	handleClickIcon(field) {
		var content = this.dataContext.magicalGet(field.fieldUid);
		if (content) {
			this.dataContext.magicalSet(field.fieldUid, null);
		}
		else {
			if (field.fieldUid == "isRemainder") {
				//then remove the remainder from elsewhere
				let details = this.dataContext.me.props.grossParent.to.details.map((detail) => {
					if (detail.isRemainder && detail.isRemainder.trim().length > 0) {
						detail.isRemainder = null;
					}

					return detail;
				});

				this.dataContext.me.props.grossParent.magicalSet("details", details);
			}

			this.dataContext.magicalSet(field.fieldUid, "yes");
		}
	}

	handleSaveField(field) {
		var value = window.document.getElementsByClassName("the-content")[0].value;
		this.dataContext.magicalSet(field.fieldUid, value);
		this.props.basicView.closeModal();

	}

	getRemainder() {

		var sampleWeight = this.dataContext.magicalGet("sample_weight");
		var sum = 0;
		_.each(this.dataContext.to.details, function (r) { if (r.isRemainder != "yes" && Number.isInteger(parseInt(r.sample_weight))) { sum += parseInt(r.sample_weight) } return sum; });
		var remainderValue = Math.abs(sampleWeight - sum);

		return remainderValue;
	}


	handleEmbeddedFormChange(a, bound) {
		//probably going to have to set state here which gets sent to the server somehow
		var context = this.dataContext;
		var field = a.props.field;
		bound.me.props.grossParent.magicalSet(field, bound.to);
		_.forEach(this.customContext.get(this.props.form.uid, "field_change:sample_weight"), (theThing)=>{
			theThing(context);
		});
		
	}

	fieldChangeHook(a, b, c, d) {
		var context = this.dataContext;
		_.forEach(this.customContext.get(this.props.form.uid, "field_change:" + d), (theThing)=>{
			theThing(context, b, c, d);
		});
	}



	render() {

		var OuterTag = this.props.outerTag || "div";

		var fields = _.sortBy(this.props.fields, ['order']);

		var className = null;


		var elements = _.map(fields, (field) => {

			if (this.props.section.type != "one-to-many") {
				className = "field";


				if (field.fieldOptions && field.fieldOptions.panel) {
					className = "field";
				}

				if (this.props.viewType == "normal") {
					className = "form-group row normal";
				}
				else if (this.props.viewType == "embed") {
					className = "arrayOf";
				} else if (this.props.viewType == "compact") {
					className = "compact_field " + (this.props.rowNumber == 0 ? "first_row" : "");
				}
			} else {
				className = null;
			}

			var editingField = "unknown field type: " + field.field.fieldType;

			var label = field.label;

			if (field.fieldOptions && field.fieldOptions.noLabel) {
				label = "";
				//className = "mini";
			}

			if (field.fieldOptions && field.fieldOptions.class) {
				className = field.fieldOptions.class;
			}

			var saveZeros = false;
			if (field.fieldOptions && field.fieldOptions.saveZeros) {
				saveZeros = field.fieldOptions.saveZeros
			}

			var options = { ...field.field.options, ...field.fieldOptions };

			if (options.options == "fetch") {
				if (this.state["options-" + field.field.id]) {
					options.options = this.state["options-" + field.field.id];
				} else {
					options.options = [];
				}
			}

			if (field.field.fieldType == "index") {
				var t = this.dataContext.magicalGet("_key");
				var e = this.props;
				editingField = <h4>{t}</h4>
			}

			var formatter = this.customContext.get(this.props.form.uid, "formatter:" + field.fieldUid);
			if(formatter != undefined){
				formatter = formatter[0];
			}
			
			var updateFunc = this.props.update;
			var fieldChange = this.customContext.get(this.props.form.uid, "field_change:" + field.fieldUid);
			if (fieldChange != undefined && fieldChange.length > 0) {
				var uid = field.fieldUid;

				updateFunc = (a, b, c, d) => this.fieldChangeHook(a, b, c, field.fieldUid);
			}

			if (field.field.fieldType == "string") {
				editingField = <InputText className={className} update={updateFunc} name={label} field={field.fieldUid} inputType={field.field.fieldName == "text area" ? "textarea" : undefined} />
			}

			if (field.field.fieldType == "colour") {
				let defaultValue = undefined;
				if (field.fieldOptions != undefined && field.fieldOptions.default != undefined) {
					defaultValue = field.fieldOptions.default;
				} else if (field.field.options != undefined && field.field.options.default != undefined) {
					defaultValue = field.field.options.default;
				}
				editingField = <InputColour className={className} inputType="color" update={updateFunc} name={label} field={field.fieldUid} defaultValue={defaultValue} />
			}

			if (field.field.fieldType == "file") {
				editingField = <FileBrowser className={className} name={label} wtf={field} fileForm={this.props.form.uid} field={field.fieldUid} allowToBrowse={field.fieldOptions ? field.fieldOptions.allowToBrowse : false} />;
			}


			if (field.field.fieldType == "integer") {

				editingField = <InputText className={className} inputType="number" update={updateFunc} name={label} field={field.fieldUid} />
			}

			//"form_uid","update:sample_weight"
			if (field.field.fieldType == "decimal") {
				editingField = <InputDecimal places={3} inputType={"decimal"} saveZeros={saveZeros} className={className} ref="input" update={updateFunc} name={label} field={field.fieldUid} formatter={formatter} curForm={this.customContext.form ? this.customContext.form : null} />

			}

			if (field.field.fieldType == "date") {

				if (this.dataContext.magicalGet(field.fieldUid) == null) {
					this.dataContext.magicalSet(field.fieldUid, new moment().format("YYYY-MM-DD"), { silent: true });
				}

				editingField = <DatePicker className={className} format="YYYY-MM-DD" update={updateFunc} name={label} field={field.fieldUid} />
			}

			if (field.field.fieldType == "person" || field.field.fieldType == "user") {
				var users = this.state.Users;
				editingField = <InputSelect className={className} options={users} showEmpty={field.fieldOptions ? field.fieldOptions.showEmpty : false} update={updateFunc} name={label} field={field.fieldUid} />
			}

			if (field.field.fieldType == "select" || (field.fieldOptions && field.fieldOptions.options && field.fieldOptions.options.length > 0)) {
				editingField = <InputSelect className={className}  {...options} update={updateFunc} name={label} field={field.fieldUid} />
			}

			if (field.field.fieldType == "select" || (field.fieldOptions && field.fieldOptions.options && _.keys(field.fieldOptions.options).length > 0)) {
				editingField = <InputSelect className={className}  {...options} update={updateFunc} name={label} field={field.fieldUid} />
			}

			if (options.autocomplete) {
				editingField = <InputSelectFilter className={className}  {...options} update={updateFunc} name={label} field={field.fieldUid} />
			}


			if (field.field.fieldType == "reference") {

				if (field.fieldOptions && field.fieldOptions.showText) {
					editingField = <InputText className={className} update={updateFunc} name={label} field={field.fieldUid} />
				} else {
					var rawOptions = [];

					if (this.state["options-" + field.field.id]) {
						rawOptions = this.state["options-" + field.field.id];
						if (field.field.options == null) {
							console.log("you're going to have a bad time because you have no options label set for your drop down in " + label);
						} else {
							var l = field.field.options.label;

							if (field.fieldOptions && field.fieldOptions.dropDownFilter) {
								if (field.fieldOptions.dropDownFilter.indexOf("=>") > -1) {
									var filter = eval(field.fieldOptions.dropDownFilter);

									rawOptions = _.filter(rawOptions, filter);
								} else {
									var filter = this.props.data[field.fieldOptions.dropDownFilter];

									rawOptions = _.filter(rawOptions, function (r) {
										return r[field.fieldOptions.dropDownFilter] == filter;
									});
								}
							}
							if (field.field.options.labelFunc) {
								const func = new Functioner(field.field.options.labelFunc);

								options.options = _.fromPairs(rawOptions.map((r) => [r.id, func.execute(r)]));
							} else {
								options.options = _.fromPairs(rawOptions.map((r) => [r.id, eval("r" + "." + l)]));
							}

						}
					}

					if (this.dataContext.magicalGet(field.fieldUid) && (this.dataContext.magicalGet(field.fieldUid + "_reference") == null)) {

						var option = _.find(rawOptions, { id: this.dataContext.magicalGet(field.fieldUid) });
						if (option) {
							this.dataContext.magicalSet(field.fieldUid + "_reference", option, { silent: true });
						}

					}

					editingField = <InputSelect ref="reference" className={className}  {...options} update={(obj, theField, val) => { this.updateReference(obj, theField, val, rawOptions); updateFunc(obj, theField, val, rawOptions) }} showEmpty={true} name={label} field={field.fieldUid} />
				}
			}

			if (field.field.fieldType == "timestamp") {

				if (this.dataContext.magicalGet(field.fieldUid) == null) {
					this.dataContext.magicalSet(field.fieldUid, new moment().format("YYYY-MM-DD HH:mm:ss"), { silent: true });
				}

				editingField = <TimestampInput  {...field.fieldOptions} className={className} update={updateFunc} name={label} field={field.fieldUid} />
			}

			if (field.field.fieldType == "button") {
				const handleClick = eval(options.onClick);
				
				options.onClick = (e)=>handleClick(this.dataContext,e);

				editingField = <Button {...options}>{label}</Button>
			}

			if (field.field.fieldType == "formula") {
				editingField = <FieldWithLabel label={field.label}>
					<Calculated setField={true} field={field.fieldUid} formula={field.fieldOptions.formula} />
				</FieldWithLabel>
			}

			if (field.field.fieldType.indexOf("content") > -1) {
				let fieldOptions = {
					className: className,
					name: label,
					tabIndex: 0,
					onBlur: undefined,
					label: field.label,
					labelExtra: "",
					obj: undefined,
					update: updateFunc,
					field: field.fieldUid + "_rms",
					fieldUid: field.fieldUid,
					curForm: this.customContext.form ? this.customContext.form : null,
					editor: this.props.editor
				};

				let NDT = this.props.editor && this.props.editor.getNDT();
				if (NDT == null) {
					if (APP.NDT) {
						NDT = APP.NDT;
					}
				}

				if (NDT) {
					editingField = <InputRMS key={field.fieldUid} iamGod={NDT == null ? true : false} NDT={NDT} {...fieldOptions} />;
				} else {
					editingField = <span>what/when</span>;
				}
			}

			if (field.fieldOptions) {
				if (field.fieldOptions.readFromScale) {
					editingField = <ScaleReader field={field.fieldUid}>{editingField}</ScaleReader>
				}
				if (field.fieldOptions.width) {
					options.width = field.fieldOptions.width;
				}
			}

			if (field.fieldOptions && field.fieldOptions.embeddedForm) {

				var subForm = APP.findForm(field.fieldOptions.embeddedForm);
				//temporarily bind it to a local hash.
				//this defaulting to defaultSubsection will cause a problem.
				editingField = <Bound to={{}} onChange={(a, b, c, d) => this.handleEmbeddedFormChange(a, b, c, d)} field={field.fieldUid} embeddedForm={field.fieldOptions.embeddedForm}>
					<Fields className={field.fieldOptions.class} viewType={"embed"} basicView={this.props.basicView} form={subForm} central={this.props.central} section={subForm.defaultSection} outerTag="div" update={updateFunc} fields={_.filter(subForm.fields, { section: subForm.defaultSection })} />
				</Bound>
			}

			if (options.array) {
				editingField = <ArrayOf canDelete={true} className={options.array.class} field={field.fieldUid} min={options.array.min} max={options.array.max} >
					{editingField}
				</ArrayOf>
			}



			var style = {};
			if (options.style) {
				style = { ...options.style };
			}
			if (options.width) {
				style.width = options.width;
			}




			var upperLabel = null;
			var editMode = true;
			if (field.fieldOptions && field.fieldOptions.noLabel) {
				upperLabel = <label>{field.label}</label>
			}


			if (field.fieldOptions && field.fieldOptions.showIcon) {
				var value = this.dataContext.magicalGet(field.fieldUid);
				var iconClass = value > "" ? "has-value" : "add-value";
				editingField = <div onClick={() => this.handleClickField(field)}>
					<Icon title={value} icon={field.fieldOptions.showIcon} className={iconClass} />
				</div>
			}

			if (field.fieldOptions && field.fieldOptions.showIcon && field.fieldOptions.clickIcon) {
				var value = this.dataContext.magicalGet(field.fieldUid);
				var iconClass = (value > "" && value != "null") ? "has-value" : "add-value";
				editingField = <div onClick={() => this.handleClickIcon(field)}>
					<Icon title={value} icon={field.fieldOptions.showIcon} className={iconClass} />
				</div>
			}
			var modal = null;

			if (this.state.modalContents) {
				modal = <Modal closeModal={() => this.closeModal()}>
					{this.state.modalContents}
				</Modal>;
			}

			var statusClass = "";

			const opts = {};
			if (field.fieldOptions && field.fieldOptions.panel) {
				opts["data-panel"] = field.fieldOptions.panel;
			}

			if (field.fieldOptions && field.fieldOptions.wrapComponent) {
				const Comp = APP.NDT.lookupComponent(field.fieldOptions.wrapComponent);
				editingField = <Comp what={field.fieldUid}>{editingField}</Comp>
			}

			if (field.field.fieldType == "dataSelect") {
				const dataOptions = {...field.field.options, ...field.fieldOptions};
				debugger;
				//e.g. fieldOptions = {"data_source":"content_thing","filters":"{component: 'DocumentTemplate'}", "option_field": "cid"}
				editingField = <InputDataSelect className={className} dataOptions={dataOptions} update={updateFunc} name={label} field={field.fieldUid} />
			}

			if (field.field.fieldType == "LookupInputSelect") {
				const what = field.field.options.data_source;
				const otherOptions = field.fieldOptions ? field.fieldOptions : {};

				const theOptions = {...otherOptions, what: what};
				if (field.field.options.fetch){
					theOptions.fetch = eval(field.field.options.fetch);
					theOptions.bound = true;
				}

				if (field.field.options.display){
					theOptions.display = field.field.options.display;
				}

				//e.g. fieldOptions = {"data_source":"content_thing","filters":"{component: 'DocumentTemplate'}", "option_field": "cid"}
				editingField = <LookupInputSelect className={className} {...theOptions} store="object" update={updateFunc} name={label} field={field.fieldUid} />
			}

			return <OuterTag key={field.id} style={style} className={"outer-field" + " " + statusClass + " " + this.props.className} {...opts}>
				{upperLabel}
				{editingField}
			</OuterTag>

		});

		const elementsToReturn = [];
		var panels = {};

		_.each(elements, (e) => {
			if (e.props && e.props["data-panel"] != null) {
				const panelName = e.props["data-panel"];
				if (panels[e.props["data-panel"]] == null) {
					panels[panelName] = true;
					const childrenElements = elements.filter((ce) => ce.props["data-panel"] == panelName);

					elementsToReturn.push(<div className={"form-panel " + "form-panel-" + panelName}> <h2>{panelName}</h2> {childrenElements}</div>);
				}
			} else {
				elementsToReturn.push(e);
			}
		});

		return elementsToReturn;

	}
}

