import React, { Component } from 'react';
import Modal from './Modal.js';

export default class EditInModal extends Component{
    constructor(props) {
        super(props);
        
        this.state = {};
    }
    
    edit(row) {
        var modal = new Modal();
        
        var modalContent = this.props.children;
        
        if(this.props.editType && this.props.editType == "tenant"){

            modalContent = React.cloneElement(this.props.children[0], {id: row.id});
        }

        modal.openModal(modalContent);
       
    }


    openModal(v) {
        this.setState({modalContents: v});
    }
    
    
    render(){

        var modal = null;
        
        if (this.state.modalContents) {
            modal = <Modal closeModal={() => this.closeModal()} >
            {this.state.modalContents}
            </Modal>
        }
        
        return <button onClick={() => this.edit(this.props.rowData)}>Edit</button>
    }
}

